import React, { useEffect, useRef, useState } from 'react';
import Dropdown from 'react-overlays/Dropdown';

import { DropdownWrapperProps } from './types';

const DropdownWrapper: React.FC<DropdownWrapperProps> = (props) => {
  const {
    id,
    show,
    drop,
    alignEnd,
    onToggle,
    Toggle,
    toggleProps,
    Menu,
    menuProps,
    offset,
    className,
  } = props;

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const menuRef = useRef<HTMLDivElement>(null);

  const handleToggle = (nextShow: boolean, event?: React.SyntheticEvent | Event) => {
    if (onToggle) {
      onToggle(nextShow, event);
    }
    setIsOpen(nextShow);
  };

  useEffect(() => {
    if (menuRef && menuRef.current && isOpen) {
      menuRef.current.scrollIntoView({
        block: 'nearest',
      });
    }
  }, [isOpen]);

  return (
    <Dropdown
      show={show}
      onToggle={handleToggle}
      drop={drop}
      alignEnd={alignEnd}
    >
      <div style={{ position: 'relative', display: 'inline-block', cursor: 'pointer' }} className={className}>
        <Toggle {...toggleProps} id={id} />
        <div ref={menuRef} style={{ display: isOpen ? 'block' : 'none' }}>
          <Menu {...menuProps} offset={offset} />
        </div>
      </div>
    </Dropdown>
  );
};

export default DropdownWrapper;
