import { observer } from 'mobx-react-lite';
import React, { createContext, useEffect, useMemo, type PropsWithChildren } from 'react';
import { AppStore } from './AppStore';
import { BreakpointStore } from './BreakpointStore/BreakpointStore';
import { PageStore } from './PageStore/PageStore';
import { ApiGroupStore } from './ApiGroupStore/ApiGroupStore';
import { LogicBuilderStore } from './LogicBuilderStore/LogicBuilderStore';
import { PreviewStore } from './PreviewStore/PreviewStore';

export const StoreContext = createContext<{
  appStore: AppStore,
  breakpointStore: BreakpointStore;
  pageStore: PageStore;
  apiGroupStore: ApiGroupStore;
  logicBuilderStore: LogicBuilderStore;
  previewStore: PreviewStore;
}>(undefined as any); // eslint-disable-line @typescript-eslint/no-unsafe-argument

export const StoreProvider: React.FC<PropsWithChildren<unknown>> = observer(
  function StoreProvider({ children }) {
    const appStore = useMemo(() => new AppStore(), []);

    const breakpointStore = useMemo(() => new BreakpointStore(), []);

    const logicBuilderStore = useMemo(
      () => new LogicBuilderStore(),
      [],
    );

    const pageStore = useMemo(
      () => new PageStore(breakpointStore, logicBuilderStore),
      [breakpointStore, logicBuilderStore],
    );

    const apiGroupStore = useMemo(() => new ApiGroupStore(), []);

    const previewStore = useMemo(
      () => new PreviewStore(pageStore, breakpointStore),
      [pageStore, breakpointStore],
    );

    useEffect(() => {
      // Initialise app store
      if (appStore.isInitialised === undefined) {
        void appStore.initialise();
      }

      // Initialise breakpoint store
      if (breakpointStore.isInitialised === undefined) {
        void breakpointStore.initialise();
      }

      // Initialise page store
      if (pageStore.isInitialised === undefined) {
        void pageStore.initialise();
      }

      // Initialise apiGroup store
      if (apiGroupStore.isInitialised === undefined) {
        void apiGroupStore.initialise();
      }

      // Initialise logicBuilder store
      if (logicBuilderStore.isInitialised === undefined) {
        void logicBuilderStore.initialise();
      }
    }, [appStore, breakpointStore, pageStore, apiGroupStore, logicBuilderStore]);

    const stores = useMemo(
      () => ({ appStore, breakpointStore, pageStore, apiGroupStore, logicBuilderStore, previewStore }),
      [appStore, breakpointStore, pageStore, apiGroupStore, logicBuilderStore, previewStore],
    );

    const areStoresReady: boolean =
      !!appStore.isInitialised &&
      !!breakpointStore.isInitialised &&
      !!pageStore.isInitialised &&
      !!apiGroupStore.isInitialised &&
      !!logicBuilderStore.isInitialised;

    return areStoresReady ? (
      <StoreContext.Provider value={stores}>{children}</StoreContext.Provider>
    ) : (
      <>Loading Data...</>
    );
  },
);
