import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useExpandablePanel } from '../../../hooks';
import { StoreContext } from '../../../store/StoreProvider';
import { typedDeepMerge } from '../../../utils/objectUtils';
import { buildEnumDropdownButtonProps } from '../../atoms/DropdownButton/utils';
import type { SizePanelCombinedProps } from './types';
import { buildUnitTypeFieldWithDropdownProps } from '../../atoms/FieldWithDropdown/utils';
import { getStylePanelConfigurations } from '../../../store/PageStore/utils';

const usePresenter = (props: SizePanelCombinedProps): SizePanelCombinedProps => {
  const { t } = useTranslation();
  const { pageStore } = useContext(StoreContext);
  const expandableProps = useExpandablePanel(props, 'Expanded', true);
  const selectedElement = pageStore.selectedCombineElement;
  const panelConfig = getStylePanelConfigurations(selectedElement);

  return typedDeepMerge(expandableProps, {
    panelHeader: {
      text: {
        value: t('sizePanel.size'),
      },
    },
    rowLabel: {
      text: {
        value: t('sizePanel.width'),
      },
    },
    inputField: buildUnitTypeFieldWithDropdownProps(pageStore, selectedElement, 'width'),
    rowLabel1: {
      text: {
        value: t('sizePanel.height'),
      },
    },
    inputField1: buildUnitTypeFieldWithDropdownProps(pageStore, selectedElement, 'height'),
    textDivider: {
      text: {
        value: t('sizePanel.minMaxValues'),
      },
    },
    rowLabel2: {
      text: {
        value: t('sizePanel.minW'),
      },
    },
    inputField2: buildUnitTypeFieldWithDropdownProps(pageStore, selectedElement, 'minWidth'),
    rowLabel3: {
      text: {
        value: t('sizePanel.minH'),
      },
    },
    inputField3: buildUnitTypeFieldWithDropdownProps(pageStore, selectedElement, 'minHeight'),
    rowLabel4: {
      text: {
        value: t('sizePanel.maxW'),
      },
    },
    inputField4: buildUnitTypeFieldWithDropdownProps(pageStore, selectedElement, 'maxWidth'),
    rowLabel5: {
      text: {
        value: t('sizePanel.maxH'),
      },
    },
    inputField5: buildUnitTypeFieldWithDropdownProps(pageStore, selectedElement, 'maxHeight'),
    font: {
      text: {
        value: t('sizePanel.overflow'),
      },
    },
    dropdownButton: buildEnumDropdownButtonProps(pageStore, selectedElement, 'overflow', 'visible'),
    renderPanel: panelConfig?.Size,
  });
};

export default usePresenter;
