import { TextProps } from '../Text';

type MethodChipInternalProps = {
  text?: TextProps;
};

type MethodChipPropsValues = {
  'Delete': MethodChipInternalProps;
  'Get': MethodChipInternalProps;
  'Patch': MethodChipInternalProps;
  'Post': MethodChipInternalProps;
  'Put': MethodChipInternalProps;
};

const propValues: MethodChipPropsValues = {
  Delete: {
    text: {
      type: 'LabelLarge',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralHoverSelected',
    },
  },
  Get: {
    text: {
      type: 'LabelLarge',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralHoverSelected',
    },
  },
  Patch: {
    text: {
      type: 'LabelLarge',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralHoverSelected',
    },
  },
  Post: {
    text: {
      type: 'LabelLarge',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralHoverSelected',
    },
  },
  Put: {
    text: {
      type: 'LabelLarge',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralHoverSelected',
    },
  },
};

const getProps = (type: string): MethodChipInternalProps => {
  const values: MethodChipInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
