import React from 'react';
import cx from 'classnames';

import Text from '../Text';

import styles from './LogicSettingsHeader.module.scss';
import usePresenter from './LogicSettingsHeader.presenter';
import getProps from './LogicSettingsHeader.props';
import { LogicSettingsHeaderCombinedProps } from './types';

const LogicSettingsHeader: React.FC<LogicSettingsHeaderCombinedProps> = (props) => {
  const {
    className,
    classes,
    text,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.logicSettingsHeader;

  return (
    <div className={cx(currentStyle, className)}>
      <Text
        className={cx(styles.text, classes?.text)}
        {...internalProps.text}
        {...text}
      />
    </div>
  );
};

export default LogicSettingsHeader;
