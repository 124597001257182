import { ButtonProps } from '../../atoms/Button';
import { DividerProps } from '../../atoms/Divider';
import { CanvasResolutionSpecProps } from '../CanvasResolutionSpec';

type BreakpointMenuInternalProps = {
  desktopButton?: ButtonProps;
  divider?: DividerProps;
  tabletButton?: ButtonProps;
  divider1?: DividerProps;
  mobileButton?: ButtonProps;
  divider2?: DividerProps;
  canvasResolutionSpec?: CanvasResolutionSpecProps;
};

type BreakpointMenuPropsValues = {
  '': BreakpointMenuInternalProps;
};

const propValues: BreakpointMenuPropsValues = {
  '': {
    desktopButton: {
      type: 'Icon',
      size: 'L',
      state: 'Selected',
      style: 'PrimarySubdued',
    },
    divider: {
      type: 'Vertical',
      colour: 'Neutral1900',
    },
    tabletButton: {
      type: 'Icon',
      size: 'L',
      state: 'Default',
      style: 'PrimarySubdued',
    },
    divider1: {
      type: 'Vertical',
      colour: 'Neutral1900',
    },
    mobileButton: {
      type: 'Icon',
      size: 'L',
      state: 'Default',
      style: 'PrimarySubdued',
    },
    divider2: {
      type: 'Vertical',
      colour: 'Neutral1900',
    },
    canvasResolutionSpec: {
    },
  },
};

const getProps = (type: string): BreakpointMenuInternalProps => {
  const values: BreakpointMenuInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
