import { useDropdownToggle as originalUseDropdownToggle } from 'react-overlays';
import DropdownWrapper from './DropdownWrapper';
import { DropdownWrapperProps, ExportedDropdownProps } from './types';

export const useDropdownToggle = () => {
  return originalUseDropdownToggle();
};

export type { DropdownWrapperProps, ExportedDropdownProps };
export default DropdownWrapper;
