import React from 'react';
import cx from 'classnames';

import Button from '../Button';
import Text from '../Text';

import styles from './RowLabel.module.scss';
import usePresenter from './RowLabel.presenter';
import getProps from './RowLabel.props';
import { RowLabelCombinedProps } from './types';

const RowLabel: React.FC<RowLabelCombinedProps> = (props) => {
  const {
    state,
    style,
    type,
    className,
    classes,
    text,
    button,
  } = usePresenter(props);

  const variantName = `${state}${style}${type}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`rowLabel${variantName}`];

  let componentView;

  switch (variantName) {
    case 'SelectedVariableClear':
    case 'SelectedVariablePanel':
    case 'DefaultVariableClear':
    case 'DefaultVariablePanel': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <Text
            className={cx(styles.text, classes?.text)}
            {...internalProps.text}
            {...text}/>
          <Button
            className={cx(styles.button, classes?.button)}
            {...internalProps.button}
            {...button}/>
        </div>
      );
      break;
    }
    case 'DefaultDefaultClear':
    case 'DefaultDefaultPanel': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <Text
            className={cx(styles.text, classes?.text)}
            {...internalProps.text}
            {...text}/>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default RowLabel;
