import { SettingsRowProps } from '../SettingsRow';

type SettingsRowListInternalProps = {
  settingsRow?: SettingsRowProps;
};

type SettingsRowListPropsValues = {
  '': SettingsRowListInternalProps;
};

const propValues: SettingsRowListPropsValues = {
  '': {
    settingsRow: {
      type: 'Variable',
    },
  },
};

const getProps = (type: string): SettingsRowListInternalProps => {
  const values: SettingsRowListInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
