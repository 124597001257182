import { ButtonProps } from '../../atoms/Button';
import { TextProps } from '../../atoms/Text';
import { TableRowListProps } from '../../molecules/TableRowList';

type InputVariableTableInternalProps = {
  name?: TextProps;
  type?: TextProps;
  button?: ButtonProps;
  tableRowList?: TableRowListProps;
};

type InputVariableTablePropsValues = {
  'Empty': InputVariableTableInternalProps;
  'Filled': InputVariableTableInternalProps;
};

const propValues: InputVariableTablePropsValues = {
  Empty: {
    name: {
      type: 'LabelMedium',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralHoverSelected',
    },
    type: {
      type: 'LabelMedium',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralHoverSelected',
    },
    button: {
      type: 'IconText',
      size: 'M',
      state: 'Default',
      style: 'PrimarySubdued',
    },
  },
  Filled: {
    name: {
      type: 'LabelMedium',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralHoverSelected',
    },
    type: {
      type: 'LabelMedium',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralHoverSelected',
    },
    tableRowList: {
      state: 'InputVariable',
      type: 'Default',
    },
    button: {
      type: 'IconText',
      size: 'M',
      state: 'Default',
      style: 'PrimarySubdued',
    },
  },
};

const getProps = (type: string): InputVariableTableInternalProps => {
  const values: InputVariableTableInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
