import { useMemo } from 'react';
import { Constants } from '../../../constants';
import { ELEMENT_CONFIGURATIONS } from '../../../store/PageStore/config';
import type { ElementItemValueProps } from '../ElementItem';
import type { ElementItemListCombinedProps } from './types';

const usePresenter = (props: ElementItemListCombinedProps): ElementItemListCombinedProps => {
  const elementItems: ElementItemValueProps[] = useMemo(() => {
    const items: ElementItemValueProps[] = [];
    Object.values(ELEMENT_CONFIGURATIONS).forEach((elementConfiguration) => {
      const { type, getName, icon } = elementConfiguration;
      // Exclude Body element
      if (type !== Constants.ELEMENT_TYPES.BODY) {
        items.push({
          type,
          icon: {
            asset: icon,
          },
          text: {
            value: getName(),
          },
        });
      }
    });
    return items;
  }, []);

  return {
    ...props,
    elementItems,
  };
};

export default usePresenter;
