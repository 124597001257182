import { type ExportedModalProps } from '../../../modules/common/ModalWrapper';
import withModalWrapper from '../../../modules/common/ModalWrapper/withModalWrapper';
import InternalConfigureNavigationVariableMenu from './ConfigureNavigationVariableMenu';
import {
  ConfigureNavigationVariableMenuCombinedProps as InternalConfigureNavigationVariableMenuCombinedProps,
  ConfigureNavigationVariableMenuValueProps,
} from './types';

export type { ConfigureNavigationVariableMenuValueProps };
export type ConfigureNavigationVariableMenuCombinedProps = ExportedModalProps<InternalConfigureNavigationVariableMenuCombinedProps>;

const ConfigureNavigationVariableMenu = withModalWrapper(InternalConfigureNavigationVariableMenu);
export default ConfigureNavigationVariableMenu;
