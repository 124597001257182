import { TextProps } from '../../atoms/Text';
import { ExpandedSectionHeaderProps } from '../../molecules/ExpandedSectionHeader';
import { ResponseItemListProps } from '../../molecules/ResponseItemList';

type ResponseSectionInternalProps = {
  expandedSectionHeader?: ExpandedSectionHeaderProps;
  text?: TextProps;
  responseItemList?: ResponseItemListProps;
};

type ResponseSectionPropsValues = {
  'Collapsed': ResponseSectionInternalProps;
  'Expanded': ResponseSectionInternalProps;
};

const propValues: ResponseSectionPropsValues = {
  'Collapsed': {
    expandedSectionHeader: {
      state: 'Collapsed',
      style: 'Default',
    },
  },
  'Expanded': {
    expandedSectionHeader: {
      state: 'Expanded',
      style: 'Default',
    },
    text: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Regular400',
      colour: 'NeutralDefault',
    },
    responseItemList: {
    },
  },
};

const getProps = (type: string): ResponseSectionInternalProps => {
  const values: ResponseSectionInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
