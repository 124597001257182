import { ExpandedSectionHeaderProps } from '../../molecules/ExpandedSectionHeader';
import { LogicVariableTableProps } from '../LogicVariableTable';

type VariableSectionInternalProps = {
  expandedSectionHeader?: ExpandedSectionHeaderProps;
  logicVariableTable?: LogicVariableTableProps;
};

type VariableSectionPropsValues = {
  'Collapsed': VariableSectionInternalProps;
  'Expanded': VariableSectionInternalProps;
};

const propValues: VariableSectionPropsValues = {
  'Collapsed': {
    expandedSectionHeader: {
      state: 'Collapsed',
      style: 'Variable',
    },
  },
  'Expanded': {
    expandedSectionHeader: {
      state: 'Expanded',
      style: 'Variable',
    },
    logicVariableTable: {
      state: 'Filled',
    },
  },
};

const getProps = (type: string): VariableSectionInternalProps => {
  const values: VariableSectionInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
