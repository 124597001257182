import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate as useReactNavigate, To, useLocation } from 'react-router';
import { NavigateWrapperContext } from '../context/NavigateWrapperContext';

type BlockStateEnum = 'blocked' | 'unblocked' | 'proceeding';

type NavigationHelperObject = {
  blockStatus: BlockStateEnum,
  nextLocation: To,
};

const defaultNavigationHelperObject: NavigationHelperObject = {
  blockStatus: 'unblocked',
  nextLocation: '',
};

type NavigationWrapper = {
  proceed: () => void,
  navigate: (path: To) => void,
  setBlocker: () => void,
  removeBlocker: () => void,
};

export const useNavigateWrapper = (): NavigationWrapper => {
  const reactNavigate = useReactNavigate();
  const location = useLocation();
  const { setIsNavigationBlocked } = useContext(NavigateWrapperContext);

  const getItemFromSession = useCallback((key: string): NavigationHelperObject => {
    const item = sessionStorage.getItem(key);
    if (item) {
      return JSON.parse(item);
    }
    return defaultNavigationHelperObject;
  }, []);

  const setItemInSession = useCallback((key: string, value: Partial<NavigationHelperObject>) => {
    const currentNavigationHelperObject = getItemFromSession(key);
    sessionStorage.setItem(key, JSON.stringify({ ...currentNavigationHelperObject, ...value }));
  }, [getItemFromSession]);

  const removeBlocker = useCallback(() => {
    sessionStorage.removeItem('navigateWrapperObject');
    if (setIsNavigationBlocked) {
      setIsNavigationBlocked(false);
    }
  }, [setIsNavigationBlocked]);

  const navigate = useCallback((newPath: To): void => {
    const { blockStatus } = getItemFromSession('navigateWrapperObject');
    if (blockStatus === 'blocked' && location.pathname !== newPath) {
      if (setIsNavigationBlocked) {
        setIsNavigationBlocked(true);
      }
      setItemInSession('navigateWrapperObject', { nextLocation: newPath });
    } else {
      removeBlocker();
      reactNavigate(newPath);
    }
  }, [getItemFromSession, setIsNavigationBlocked, setItemInSession, location.pathname, reactNavigate, removeBlocker]);

  const proceed = useCallback(() => {
    const { nextLocation } = getItemFromSession('navigateWrapperObject');
    if (nextLocation) {
      removeBlocker();
      navigate(nextLocation);
    }
  }, [getItemFromSession, removeBlocker, navigate]);

  const setBlocker = useCallback(() => {
    setItemInSession('navigateWrapperObject', { blockStatus: 'blocked' });
  }, [setItemInSession]);

  return { proceed, navigate, setBlocker, removeBlocker };
};
