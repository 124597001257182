import { IconProps } from '../Icon';
import { ImageProps } from '../Image';
import { TextProps } from '../Text';

type DropdownItemInternalProps = {
  text?: TextProps;
  icon?: IconProps;
  label?: TextProps;
  image?: ImageProps;
  icon1?: IconProps;
};

type DropdownItemPropsValues = {
  'DefaultDefault': DropdownItemInternalProps;
  'DefaultIconText': DropdownItemInternalProps;
  'DefaultIconTextDescription': DropdownItemInternalProps;
  'DefaultImageText': DropdownItemInternalProps;
  'DefaultTextOnly': DropdownItemInternalProps;
  'SelectedDefault': DropdownItemInternalProps;
  'SelectedIconText': DropdownItemInternalProps;
};

const propValues: DropdownItemPropsValues = {
  DefaultDefault: {
    text: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Regular400',
      colour: 'NeutralDefault',
    },
  },
  DefaultIconText: {
    icon: {
      asset: 'Add',
      colour: 'NeturalHoverSelected',
    },
    text: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Regular400',
      colour: 'NeutralDefault',
    },
  },
  DefaultIconTextDescription: {
    icon: {
      asset: 'Page',
      colour: 'VariableSelected',
    },
    label: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralDefault',
    },
    text: {
      type: 'LabelExtraSmall',
      align: 'Left',
      style: 'Regular400',
      colour: 'NeutralDefault',
    },
  },
  DefaultImageText: {
    image: {
    },
    text: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Regular400',
      colour: 'NeutralDefault',
    },
  },
  DefaultTextOnly: {
    text: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Regular400',
      colour: 'NeutralDefault',
    },
  },
  SelectedDefault: {
    icon: {
      asset: 'Check',
      colour: 'NeturalHoverSelected',
    },
    text: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Regular400',
      colour: 'NeutralHoverSelected',
    },
  },
  SelectedIconText: {
    icon: {
      asset: 'Check',
      colour: 'NeturalHoverSelected',
    },
    icon1: {
      asset: 'Add',
      colour: 'NeturalHoverSelected',
    },
    text: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Regular400',
      colour: 'NeutralDefault',
    },
  },
};

const getProps = (type: string): DropdownItemInternalProps => {
  const values: DropdownItemInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
