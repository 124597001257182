import React from 'react';
import cx from 'classnames';

import Button from '../../atoms/Button';
import Icon from '../../atoms/Icon';
import Text from '../../atoms/Text';
import EndpointCardList from '../../molecules/EndpointCardList';

import styles from './EndpointCardBlock.module.scss';
import usePresenter from './EndpointCardBlock.presenter';
import getProps from './EndpointCardBlock.props';
import { EndpointCardBlockCombinedProps } from './types';

const EndpointCardBlock: React.FC<EndpointCardBlockCombinedProps> = (props) => {
  const {
    state,
    className,
    classes,
    icon,
    label,
    instructions,
    button,
    endpointCardList,
  } = usePresenter(props);

  const variantName = `${state}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`endpointCardBlock${variantName}`];

  let componentView;

  switch (variantName) {
    case 'Empty': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <div className={cx(styles.emptyStateContent, classes?.emptyStateContent)}>
            <Icon
              className={cx(styles.icon, classes?.icon)}
              {...internalProps.icon}
              {...icon}
            />
            <div className={cx(styles.textContent, classes?.textContent)}>
              <Text
                className={cx(styles.label, classes?.label)}
                {...internalProps.label}
                {...label}
              />
              <Text
                className={cx(styles.instructions, classes?.instructions)}
                {...internalProps.instructions}
                {...instructions}
              />
            </div>
          </div>
          <Button
            className={cx(styles.button, classes?.button)}
            {...internalProps.button}
            {...button}
          />
        </div>
      );
      break;
    }
    case 'Filled': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <EndpointCardList
            className={cx(styles.endpointCardList, classes?.endpointCardList)}
            {...internalProps.endpointCardList}
            {...endpointCardList}
          />
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default EndpointCardBlock;
