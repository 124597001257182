import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SetVariableMenuCombinedProps, SetVariableMenuStyleEnum } from './types';
import { typedDeepMerge } from '../../../utils/objectUtils';
import { VariableLayerItemCombinedProps } from '../../molecules/VariableLayerItem/types';
import { VariableReferenceType } from '../../../modules/variables/types';
import { mapScopeIcon } from '../../../store/PageStore/variableUtils';

const usePresenter = (props: SetVariableMenuCombinedProps): SetVariableMenuCombinedProps => {
  const { t } = useTranslation();
  const { closeModal, variables, selectedVariable, onClearClicked, onVariableSelected } = props;

  const [style, setStyle] = useState<SetVariableMenuStyleEnum>('Default');
  const [currentSelectedVariable, setCurrentSelectedVariable] = useState<VariableReferenceType>();

  useEffect(() => {
    setCurrentSelectedVariable(selectedVariable);
    setStyle(selectedVariable ? 'Removeable' : 'Default');
  }, [selectedVariable]);

  const handleOnSelectVariable = () => {
    if (currentSelectedVariable && onVariableSelected) {
      onVariableSelected(currentSelectedVariable);
    }
    if (closeModal) {
      closeModal();
    }
  };

  return {
    ...typedDeepMerge(props, {
      style,
      state: variables?.length ? 'Default' : 'Empty',
      menuHeader: {
        icon: {
          asset: 'Variable',
          colour: 'VariableSelected',
        },
        text: {
          value: t('functionBlock.setVariableMenu.header'),
        },
        button: {
          type: 'Icon',
          icon: {
            asset: 'Close',
          },
          onClick: closeModal,
        },
      },
      text: {
        value: t('functionBlock.setVariableMenu.emptyModalText'),
      },
      variableLayerItemList: {
        variableLayerItems: variables?.map((item): VariableLayerItemCombinedProps => {
          const isSelected = currentSelectedVariable?.id === item.id;
          return {
            icon: {
              asset: mapScopeIcon(item.scope),
              colour: 'VariableSelected',
            },
            name: {
              value: item.name,
            },
            variableType: {
              value: `/ ${item.type}`,
            },
            onClick: () => {
              setCurrentSelectedVariable(item);
            },
            state: isSelected ? 'Selected' : 'Default',
          };
        }),
      },
      button: {
        text: {
          value: t('functionBlock.setVariableMenu.remove'),
        },
        onClick: () => {
          if (onClearClicked) {
            onClearClicked();
          }
          if (closeModal) {
            closeModal();
          }
        },
      },
      primaryButton: {
        disabled: !currentSelectedVariable,
        text: {
          value: t('functionBlock.setVariableMenu.selectVariable'),
        },
        onClick: handleOnSelectVariable,
      },
      secondaryButton: {
        text: {
          value: t('functionBlock.setVariableMenu.close'),
        },
        onClick: closeModal,
      },
    }),
  };
};

export default usePresenter;
