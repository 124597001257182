import {
  action,
  computed,
  makeAutoObservable,
  observable,
  runInAction,
} from 'mobx';
import { getApplicationApi } from '../modules/applications/applications.repository';
import type { IApplication, LeftPanelType, RightPanelType } from './types';

export class AppStore {
  public constructor() {
    makeAutoObservable(this);
  }

  @observable
  public isInitialised: boolean | undefined = undefined;

  @action
  public initialise = async (): Promise<void> => {
    // console.log('Logger: Initialising app store');

    // Start initialisation
    this.isInitialised = false;

    // Fetch data from the back end
    const application: IApplication = await getApplicationApi();

    // Populate state
    runInAction(() => {
      this.setAppName(application.name);
      this.setLeftPanelType('Design');
      this.setRightPanelType('Style');
      this.setCanvasScale(1);
      this.setIsCanvasHovered(false);
    });

    // Complete initialisation
    runInAction(() => {
      this.isInitialised = true;
    });

    // console.log('Logger: App store is initialised');
  };

  @observable
  public appName = '';

  @action
  public setAppName = (appName: string) => {
    if (this.appName !== appName) {
      this.appName = appName;
    }
  };

  @observable
  public leftPanelType: LeftPanelType | undefined;

  @action
  public setLeftPanelType = (leftPanelType: LeftPanelType | undefined) => {
    if (this.leftPanelType !== leftPanelType) {
      this.leftPanelType = leftPanelType;
    }
  };

  public isLeftPanelType = (leftPanelType: LeftPanelType): boolean => {
    return this.leftPanelType === leftPanelType;
  };

  @observable
  public rightPanelType: RightPanelType = 'Style';

  @action
  public setRightPanelType = (rightPanelType: RightPanelType) => {
    if (this.rightPanelType !== rightPanelType) {
      this.rightPanelType = rightPanelType;
    }
  };

  public isRightPanelType = (rightPanelType: RightPanelType): boolean => {
    return this.rightPanelType === rightPanelType;
  };

  @observable
  public canvasScale = 1; // 0..1

  @action
  public setCanvasScale = (canvasScale: number): void => {
    if (this.canvasScale !== canvasScale) {
      this.canvasScale = canvasScale;
    }
  };

  @observable
  public canvasHeight = 0;

  @action
  public setCanvasHeight = (canvasHeight: number): void => {
    if (this.canvasHeight !== canvasHeight) {
      this.canvasHeight = canvasHeight;
    }
  };

  /**
   * Get current scale applied to canvas converted to percentage and rounded to the nearest integer
   */
  @computed
  public get canvasScalePercentage(): number {
    return Math.round(this.canvasScale * 100);
  }

  @observable
  public isCanvasHovered = false;

  @action
  public setIsCanvasHovered = (isCanvasHovered: boolean) => {
    if (this.isCanvasHovered !== isCanvasHovered) {
      this.isCanvasHovered = isCanvasHovered;
    }
  };
}
