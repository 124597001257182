import cx from 'classnames';
import { createRef, useEffect, useRef, useState } from 'react';
import { typedDeepMerge } from '../../../utils/objectUtils';
import customStyles from './Custom.module.scss';

import { ReplaceInputCombinedProps, ReplaceInputStateEnum } from './types';

const usePresenter = (props: ReplaceInputCombinedProps): ReplaceInputCombinedProps => {
  const [inputState, setInputState] = useState<ReplaceInputStateEnum>('Default');

  return {
    ...typedDeepMerge(props, {
      className: cx(props.className, customStyles.replaceInput),
      state: inputState,
      onClick: () => {
        setInputState('Selected');
      },
      inputField: {
        autofocus: true,
        className: customStyles.inputField,
        classes: {
          input: customStyles.input,
        },
        onBlur: () => setInputState('Default'),
      },
    }),
  };
};

export default usePresenter;
