import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useExpandablePanel } from '../../../hooks';
import { StoreContext } from '../../../store/StoreProvider';
import { typedDeepMerge } from '../../../utils/objectUtils';
import type { PaddingMarginsPanelCombinedProps } from './types';
import { getStylePanelConfigurations } from '../../../store/PageStore/utils';
import { pixelInputValidation, valueWithFallback } from '../../../utils/inputValidations';

const usePresenter = (props: PaddingMarginsPanelCombinedProps): PaddingMarginsPanelCombinedProps => {
  const { t } = useTranslation();
  const { pageStore } = useContext(StoreContext);
  const selectedElement = pageStore.selectedCombineElement;
  const expandableProps = useExpandablePanel(props, 'Expanded', true);
  const panelConfig = getStylePanelConfigurations(selectedElement);

  const padding = selectedElement?.styles.padding;
  const margin = selectedElement?.styles.margin;

  const handleUpdatePaddingMargin = async (
    type: 'margin' | 'padding',
    direction: 'top' | 'right' | 'bottom' | 'left',
    inputValue: string,
  ) => {
    const contentSpace = type === 'margin' ? margin : padding;
    if (selectedElement) {
      await pageStore.updateElement(selectedElement, {
        styles: {
          [type]: {
            ...contentSpace,
            [direction]: {
              value: +inputValue,
              unit: 'px',
            },
          },
        },
      });
    }
  };

  return typedDeepMerge(expandableProps, {
    panelHeader: {
      text: {
        value: t('paddingMarginsPanel.paddingAndMargins'),
      },
    },
    paddingMarginGrid: {
      marginTitle: {
        value: t('paddingMarginsPanel.mar'),
      },
      topMarginInput: {
        text: {
          value: valueWithFallback(margin?.top?.value, 0),
        },
        inputField: {
          inputType: 'number',
          textValue: valueWithFallback(margin?.top?.value, 0),
          onValidateText: (value) => pixelInputValidation(value, true),
          onSubmit: (value) => handleUpdatePaddingMargin('margin', 'top', value),
          button: {
            text: {
              value: margin?.top?.unit ?? t('units.px'),
            },
          },
        },
      },
      bottomMarginInput: {
        text: {
          value: valueWithFallback(margin?.bottom?.value, 0),
        },
        inputField: {
          inputType: 'number',
          textValue: valueWithFallback(margin?.bottom?.value, 0),
          onValidateText: (value) => pixelInputValidation(value, true),
          onSubmit: (value) => handleUpdatePaddingMargin('margin', 'bottom', value),
          button: {
            text: {
              value: margin?.bottom?.unit ?? t('units.px'),
            },
          },
        },
      },
      leftMarginInput: {
        text: {
          value: valueWithFallback(margin?.left?.value, 0),
        },
        inputField: {
          inputType: 'number',
          textValue: valueWithFallback(margin?.left?.value, 0),
          onValidateText: (value) => pixelInputValidation(value, true),
          onSubmit: (value) => handleUpdatePaddingMargin('margin', 'left', value),
          button: {
            text: {
              value: margin?.left?.unit ?? t('units.px'),
            },
          },
        },
      },
      rightMarginInput: {
        text: {
          value: valueWithFallback(margin?.right?.value, 0),
        },
        inputField: {
          inputType: 'number',
          textValue: valueWithFallback(margin?.right?.value, 0),
          onValidateText: (value) => pixelInputValidation(value, true),
          onSubmit: (value) => handleUpdatePaddingMargin('margin', 'right', value),
          button: {
            text: {
              value: margin?.right?.unit ?? t('units.px'),
            },
          },
        },
      },
      paddingTitle: {
        value: t('paddingMarginsPanel.pad'),
      },
      topPaddingInput: {
        text: {
          value: valueWithFallback(padding?.top?.value, 0),
        },
        inputField: {
          inputType: 'number',
          textValue: valueWithFallback(padding?.top?.value, 0),
          onValidateText: pixelInputValidation,
          onSubmit: (value) => handleUpdatePaddingMargin('padding', 'top', value),
          button: {
            text: {
              value: padding?.top?.unit ?? t('units.px'),
            },
          },
        },
      },
      bottomPaddingInput: {
        text: {
          value: valueWithFallback(padding?.bottom?.value, 0),
        },
        inputField: {
          inputType: 'number',
          textValue: valueWithFallback(padding?.bottom?.value, 0),
          onValidateText: pixelInputValidation,
          onSubmit: (value) => handleUpdatePaddingMargin('padding', 'bottom', value),
          button: {
            text: {
              value: padding?.bottom?.unit ?? t('units.px'),
            },
          },
        },
      },
      leftPaddingInput: {
        text: {
          value: valueWithFallback(padding?.left?.value, 0),
        },
        inputField: {
          inputType: 'number',
          textValue: valueWithFallback(padding?.left?.value, 0),
          onValidateText: pixelInputValidation,
          onSubmit: (value) => handleUpdatePaddingMargin('padding', 'left', value),
          button: {
            text: {
              value: padding?.left?.unit ?? t('units.px'),
            },
          },
        },
      },
      rightPaddingInput: {
        text: {
          value: valueWithFallback(padding?.right?.value, 0),
        },
        inputField: {
          inputType: 'number',
          textValue: valueWithFallback(padding?.right?.value, 0),
          onValidateText: pixelInputValidation,
          onSubmit: (value) => handleUpdatePaddingMargin('padding', 'right', value),
          button: {
            text: {
              value: padding?.right?.unit ?? t('units.px'),
            },
          },
        },
      },
    },
    renderPanel: panelConfig?.PaddingMargins,
  });
};

export default usePresenter;
