import { TFunction } from 'i18next';
import { VariableReferenceType } from '../../../modules/variables/types';
import { ApiGroup, ApiGroupEndpoint } from '../../../store/ApiGroupStore/types';
import { getInputPropTextValue } from '../../../store/PageStore/settingsUtils';
import { InputProp, IPage } from '../../../store/types';
import { ActionPanelCombinedProps, ActionPanelTypeEnum } from './types';

type CallApiInputs = {
  currentApiGroup?: ApiGroup,
  apiGroups?: ApiGroup[],
  onApiGroupSelected?: (option: string) => void,
  currentEndpoint?: ApiGroupEndpoint,
  endpoints?: ApiGroupEndpoint[],
  onEndpointSelected?: (option: string) => void,
};

type NavigateToInputs = {
  currentDestination?: string;
  destinations?: string[];
  onDestinationSelected?: (option: string) => void;
  currentUrl?: InputProp | null;
  onCurrentUrlUpdated?: (url: string) => void;
  onClearUrlVariableClicked?: () => void;
  openUrlVariableModal?: (selectedVariable?: VariableReferenceType) => void;
  currentPage?: IPage;
  pages?: IPage[];
  onPageSelected?: (option: string) => void;
  currentLaunch?: string;
  launchOptions?: string[];
  onLaunchOptionSelected?: (option: string) => void;
};

export type CombinedActionInputs = {
  callApi?: CallApiInputs;
  navigateTo?: NavigateToInputs;
};

const mapCallApiProps = (t: TFunction, options?: CallApiInputs): ActionPanelCombinedProps => {
  const {
    currentApiGroup,
    apiGroups,
    onApiGroupSelected,
    currentEndpoint,
    endpoints,
    onEndpointSelected,
  } = options || {};
  return {
    rowLabel1: {
      text: {
        value: 'Group',
      },
    },
    dropdownButton1: {
      toggleProps: {
        text: {
          value: currentApiGroup?.name || t('logicSettingsPanel.placeholders.selectApiGroup'),
        },
        colour: 'Default',
        style: 'Default',
        type: 'Default',
      },
      menuProps: {
        style: 'Default',
        dropdownItems: apiGroups?.map((apiGroup) => {
          return {
            text: {
              value: apiGroup.name,
            },
            type: 'Default',
            state: 'Default',
            dropdownItemValue: apiGroup.uuid,
          };
        }),
        onOptionSelected: onApiGroupSelected,
      },
    },
    rowLabel2: {
      text: {
        value: 'Call/function',
      },
    },
    dropdownButton2: {
      toggleProps: {
        text: {
          value: currentEndpoint?.name || t('logicSettingsPanel.placeholders.selectApiEndpoint'),
        },
        colour: 'Default',
        style: 'Default',
        type: 'Default',
      },
      menuProps: {
        style: 'Default',
        dropdownItems: endpoints?.map((endpoint) => {
          return {
            text: {
              value: endpoint.name,
            },
            type: 'Default',
            state: 'Default',
            dropdownItemValue: endpoint.uuid,
          };
        }),
        onOptionSelected: onEndpointSelected,
      },
    },
  };
};

const mapNavigateProps = (
  type: ActionPanelTypeEnum,
  t: TFunction,
  options?: NavigateToInputs,
): ActionPanelCombinedProps => {
  const {
    currentDestination,
    destinations,
    onDestinationSelected,
    currentUrl,
    onCurrentUrlUpdated,
    onClearUrlVariableClicked,
    openUrlVariableModal,
    currentPage,
    pages,
    onPageSelected,
    currentLaunch,
    launchOptions,
    onLaunchOptionSelected,
  } = options || {};
  const props: ActionPanelCombinedProps = {
    rowLabel1: {
      text: {
        value: 'Destination',
      },
    },
    dropdownButton1: {
      toggleProps: {
        text: {
          value: currentDestination,
        },
        colour: 'Default',
        style: 'Default',
        type: 'Default',
      },
      menuProps: {
        style: 'Default',
        dropdownItems: destinations?.map((destination) => {
          return {
            text: {
              value: destination,
            },
            type: 'Default',
            state: 'Default',
            dropdownItemValue: destination,
          };
        }),
        onOptionSelected: onDestinationSelected,
      },
    },
  };

  if (type === 'NagivatePage') {
    props.rowLabel2 = {
      text: {
        value: 'Page',
      },
    };
    props.dropdownButton2 = {
      toggleProps: {
        text: {
          value: currentPage?.name,
        },
        colour: 'Default',
        style: 'Default',
        type: 'Default',
      },
      menuProps: {
        style: 'Default',
        dropdownItems: pages?.map((page) => {
          return {
            text: {
              value: page.name,
            },
            type: 'Default',
            state: 'Default',
            dropdownItemValue: page.uuid,
          };
        }),
        onOptionSelected: onPageSelected,
      },
    };
  } else if (type === 'NavigateURL') {
    const setFromVariable = currentUrl?.source === 'variable';
    props.replaceRow = {
      type: 'VariableText',
      state: setFromVariable ? 'Uploaded' : 'Editable',
      style: 'Variable',
      rowLabel: {
        text: {
          value: 'URL',
        },
        button: {
          icon: {
            asset: 'Variable',
            colour: setFromVariable ? 'VariableSelected' : 'NeutralDefault',
          },
          onClick: () => {
            if (openUrlVariableModal) {
              openUrlVariableModal(setFromVariable ? currentUrl.value : undefined);
            }
          },
        },
      },
      propertyItem: {
        text: {
          value: setFromVariable ? `{${currentUrl?.value?.name}}` : '',
        },
        button: {
          type: 'Icon',
          icon: {
            asset: 'Close',
          },
          onClick: onClearUrlVariableClicked,
        },
      },
      inputField: {
        disabled: setFromVariable,
        inputType: 'text',
        textValue: getInputPropTextValue(currentUrl),
        onSubmit: onCurrentUrlUpdated,
      },
    };
  }

  const launchRowLabelKey: keyof ActionPanelCombinedProps = type === 'NavigateURL' ? 'rowLabel2' : 'rowLabel3';
  const launchDropdownKey: keyof ActionPanelCombinedProps = type === 'NavigateURL' ? 'dropdownButton2' : 'dropdownButton3';

  props[launchRowLabelKey] = {
    text: {
      value: 'Launch',
    },
  };
  props[launchDropdownKey] = {
    toggleProps: {
      text: {
        value: t(`logicSettingsPanel.launchTypes.${currentLaunch}`),
      },
      colour: 'Default',
      style: 'Default',
      type: 'Default',
    },
    menuProps: {
      style: 'Default',
      dropdownItems: launchOptions?.map((option) => {
        return {
          text: {
            value: t(`logicSettingsPanel.launchTypes.${option}`),
          },
          type: 'Default',
          state: 'Default',
          dropdownItemValue: option,
        };
      }),
      onOptionSelected: onLaunchOptionSelected,
    },
  };

  return props;
};

export const mapTypeDynamicProps = (
  type: ActionPanelTypeEnum,
  t: TFunction,
  inputs?: CombinedActionInputs,
): ActionPanelCombinedProps => {
  switch (type) {
    case 'CallAPI':
      return mapCallApiProps(t, inputs?.callApi);
    case 'NavigateDefault':
    case 'NagivatePage':
    case 'NavigateURL':
      return mapNavigateProps(type, t, inputs?.navigateTo);
    case 'UpdateVar':
      return {};
    default:
      return {};
  }
};
