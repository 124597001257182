import type { BodyElementProps } from './types';

const usePresenter = ({ element, style, ...otherProps }: BodyElementProps): BodyElementProps => {
  return {
    element,
    style: {
      ...style,
      height: 'fit-content',
    },
    ...otherProps,
  };
};

export default usePresenter;
