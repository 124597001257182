import { computed } from 'mobx';
import {
  useCallback, useContext, type BaseSyntheticEvent, type FocusEventHandler, type MouseEventHandler,
} from 'react';
import { StoreContext } from '../../../store/StoreProvider';
import type { IWithId } from '../../../store/types';

export interface IUseElementHoverable {
  onMouseOver: MouseEventHandler;
  onFocus: FocusEventHandler;
  onMouseOut: MouseEventHandler;
  onBlur: FocusEventHandler;
  isElementHovered: boolean;
}

export const useElementHoverable = (element: IWithId | undefined): IUseElementHoverable => {
  const { pageStore } = useContext(StoreContext);

  const genericHandler = useCallback((event: BaseSyntheticEvent, hoveredElement: IWithId | undefined) => {
    event.stopPropagation();
    pageStore.setHoveredElement(hoveredElement);
  }, [pageStore]);

  const onMouseOver: MouseEventHandler = useCallback(
    (event) => genericHandler(event, element),
    [genericHandler, element],
  );

  const onFocus: FocusEventHandler = useCallback(
    (event) => genericHandler(event, element),
    [genericHandler, element],
  );

  const onMouseOut: MouseEventHandler = useCallback(
    (event) => genericHandler(event, undefined),
    [genericHandler],
  );

  const onBlur: FocusEventHandler = useCallback(
    (event) => genericHandler(event, undefined),
    [genericHandler],
  );

  const isElementHovered: boolean = computed(() => pageStore.isElementHovered(element)).get();

  return {
    onMouseOver,
    onFocus,
    onMouseOut,
    onBlur,
    isElementHovered,
  };
};
