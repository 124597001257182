import React from 'react';
import cx from 'classnames';

import ReplaceRow from '../ReplaceRow';

import styles from './ReplaceRowList.module.scss';
import usePresenter from './ReplaceRowList.presenter';
import getProps from './ReplaceRowList.props';
import { ReplaceRowListCombinedProps } from './types';

const ReplaceRowList: React.FC<ReplaceRowListCombinedProps> = (props) => {
  const {
    className,
    classes,
    replaceRows,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.replaceRowList;

  const replaceRowViews = replaceRows?.map((replaceRow, index) => (
    <ReplaceRow
      key={index}
      className={cx(styles.replaceRow, classes?.replaceRow)}
      {...internalProps.replaceRow}
      {...replaceRow} />
  )) || [];

  return (
    <div className={cx(currentStyle, className)}>
      {replaceRowViews}
    </div>
  );
};

export default ReplaceRowList;
