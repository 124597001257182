import type { ApiResponseSchema } from '../store/ApiGroupStore/types';

export const LOGIC_BUILDER = {
  actionCardHeight: 80,
  actionCardWidth: 248,
  flowStartHeight: 44,
  flowStartWidth: 163,
  addActionBetweenHeight: 16,
  addActionBetweenWidth: 16,
  addActionHeight: 24,
  addActionWidth: 24,
  nodeSpace: 86,
  initialX: 20,
  initialY: 60,
  headerHeight: 65,
  topNavHeight: 48,
};

export const ROUTES = {
  baseRoute: '/',
  apiConnector: '/api-connector/',
  groupDetails: '/api-connector/:groupUuid',
  groupDetailRoutes: {
    base: '/',
    headers: '/headers',
    parameters: '/parameters',
  },
  preview: '/preview',
  endpoints: '/api-connector/:groupUuid/endpoints',
  endpointDetails: '/api-connector/:groupUuid/endpoints/:endpointUuid',
  testResponse: '/api-connector/:groupUuid/endpoints/:endpointUuid/testResponse',
} as const;

export const TEMP_API_RESPONSES: ApiResponseSchema = [
  {
    propName: 'title',
    propType: 'string',
    initialPropType: 'string',
    responsePath: '/title',
    isExpanded: false,
    level: 0,
    value: undefined,
  },
  {
    propName: 'description',
    propType: 'string',
    initialPropType: 'string',
    responsePath: '/description',
    isExpanded: false,
    level: 0,
    value: undefined,
  },
  {
    propName: 'count',
    propType: 'number',
    initialPropType: 'number',
    responsePath: '/count',
    isExpanded: false,
    level: 0,
    value: undefined,
  },
  {
    propName: 'boolean',
    propType: 'boolean',
    initialPropType: 'boolean',
    responsePath: '/boolean',
    isExpanded: false,
    level: 0,
    value: undefined,
  },
  {
    propName: 'collection',
    propType: 'collection',
    initialPropType: 'collection',
    responsePath: '/collection',
    isExpanded: false,
    level: 0,
    value: undefined,
  },
  {
    propName: 'object',
    propType: 'object',
    initialPropType: 'object',
    responsePath: '/object',
    isExpanded: false,
    level: 0,
    value: undefined,
  },
];
