import type { RefObject } from 'react';
import type { ModalPosition } from './types';

export const getCustomPositionForElementLeft = (
  elementRef: RefObject<HTMLDivElement | HTMLButtonElement | undefined>,
  xOffsetInPx = 0,
  yOffsetInPx = 0,
): ModalPosition | undefined => {
  const elementRect: DOMRect | undefined = elementRef?.current?.getBoundingClientRect();
  return elementRect
    ? {
      alignment: 'top-right',
      x: elementRect.left + xOffsetInPx,
      y: elementRect.top + yOffsetInPx,
    }
    : undefined;
};

export const getCustomPositionForElementRight = (
  elementRef: RefObject<HTMLDivElement>,
  xOffsetInPx = 0,
  yOffsetInPx = 0,
): ModalPosition | undefined => {
  const elementRect: DOMRect | undefined = elementRef.current?.getBoundingClientRect();
  return elementRect
    ? {
      alignment: 'top-left',
      x: elementRect.right + xOffsetInPx,
      y: elementRect.top + yOffsetInPx,
    }
    : undefined;
};
