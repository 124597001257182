import { InputFieldProps } from '../../atoms/InputField';
import { RowLabelProps } from '../../atoms/RowLabel';
import { PanelHeaderProps } from '../../molecules/PanelHeader';
import { ToggleItemListProps } from '../../molecules/ToggleItemList';

type CornersPanelInternalProps = {
  panelHeader?: PanelHeaderProps;
  radius?: RowLabelProps;
  inputField?: InputFieldProps;
  toggleItemList?: ToggleItemListProps;
  topLeft?: InputFieldProps;
  topRight?: InputFieldProps;
  bottomLeft?: InputFieldProps;
  bottomRight?: InputFieldProps;
};

type CornersPanelPropsValues = {
  'Collapsed': CornersPanelInternalProps;
  'ExpandedSeperate': CornersPanelInternalProps;
  'ExpandedUnion': CornersPanelInternalProps;
};

const propValues: CornersPanelPropsValues = {
  Collapsed: {
    panelHeader: {
      state: 'Collapsed',
      type: 'Default',
    },
  },
  ExpandedSeperate: {
    panelHeader: {
      state: 'Expanded',
      type: 'Default',
    },
    radius: {
      state: 'Default',
      style: 'Default',
      type: 'Panel',
    },
    inputField: {
      type: 'TextUnit',
      size: 'Medium',
      state: 'Default',
    },
    toggleItemList: {
      size: 'Small',
      type: 'Icon',
      style: 'Default',
    },
    topLeft: {
      type: 'IconText',
      size: 'Medium',
      state: 'Default',
    },
    topRight: {
      type: 'TextIcon',
      size: 'Medium',
      state: 'Default',
    },
    bottomLeft: {
      type: 'IconText',
      size: 'Medium',
      state: 'Default',
    },
    bottomRight: {
      type: 'TextIcon',
      size: 'Medium',
      state: 'Default',
    },
  },
  ExpandedUnion: {
    panelHeader: {
      state: 'Expanded',
      type: 'Default',
    },
    radius: {
      state: 'Default',
      style: 'Default',
      type: 'Panel',
    },
    inputField: {
      type: 'TextUnit',
      size: 'Medium',
      state: 'Default',
    },
    toggleItemList: {
      size: 'Small',
      type: 'Icon',
      style: 'Default',
    },
  },
};

const getProps = (type: string): CornersPanelInternalProps => {
  const values: CornersPanelInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
