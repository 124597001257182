import { observer } from 'mobx-react-lite';
import withModal from '../../../modules/common/ModalWrapper/context/withModal';
import SetVariableMenu from '../SetVariableMenu';
import InternalActionPanel from './ActionPanel';
import { ActionPanelProps, ActionPanelValueProps } from './types';

export type { ActionPanelProps, ActionPanelValueProps };
const [ActionPanel, SetVariableMenuContext] = withModal(observer(InternalActionPanel), SetVariableMenu);
export { SetVariableMenuContext };
export default ActionPanel;
