import React from 'react';
import cx from 'classnames';

import TableRow from '../TableRow';

import styles from './TableRowList.module.scss';
import usePresenter from './TableRowList.presenter';
import getProps from './TableRowList.props';
import { TableRowListCombinedProps } from './types';

const TableRowList: React.FC<TableRowListCombinedProps> = (props) => {
  const {
    state,
    type,
    className,
    classes,
    tableRows, // MANUAL OVERRIDE
  } = usePresenter(props);

  const variantName = `${state}${type}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`tableRowList${variantName}`];

  let componentView;

  switch (variantName) {
    case 'EditVariablesDefault':
    case 'InputVariableDefault':
    case 'KeyValueDefault':
    case 'KeyValueVariable':
    case 'TestVariableDefault': {
      const tableRowViews = tableRows?.map((tableRow, index) => (
        <TableRow
          key={index}
          className={cx(styles.tableRow, classes?.tableRow)}
          {...internalProps.tableRow}
          {...tableRow}
        />
      )) || [];

      componentView = (
        <div className={cx(currentStyle, className)}>
          {tableRowViews}
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default TableRowList;
