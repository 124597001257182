import React from 'react';
import cx from 'classnames';

import Button from '../../atoms/Button';
import DropdownButton from '../../atoms/DropdownButton';
import InputField from '../../atoms/InputField';
import RowLabel from '../../atoms/RowLabel';
import PanelHeader from '../../molecules/PanelHeader';
import PropertyItemList from '../../molecules/PropertyItemList';
import ToggleItemList from '../../molecules/ToggleItemList';

import styles from './StyleEffectsPanel.module.scss';
import usePresenter from './StyleEffectsPanel.presenter';
import getProps from './StyleEffectsPanel.props';
import type { StyleEffectsPanelCombinedProps } from './types';

const StyleEffectsPanel: React.FC<StyleEffectsPanelCombinedProps> = (props) => {
  const {
    state,
    className,
    classes,
    panelHeader,
    rowLabel,
    toggleItemList,
    rowLabel1,
    inputField,
    rowLabel2,
    button,
    rowLabel3,
    dropdownButton,
    propertyItemList,
    renderPanel, // MANUAL OVERRIDE
  } = usePresenter(props);

  // MANUAL OVERRIDE STARTS
  if (!renderPanel) {
    return null;
  }
  // MANUAL OVERRIDE ENDS

  const variantName = `${state}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`styleEffectsPanel${variantName}`];

  let componentView;

  switch (variantName) {
    case 'Collapsed': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <PanelHeader
            className={cx(styles.panelHeader, classes?.panelHeader)}
            {...internalProps.panelHeader}
            {...panelHeader}
          />
        </div>
      );
      break;
    }
    case 'Expanded': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <PanelHeader
            className={cx(styles.panelHeader, classes?.panelHeader)}
            {...internalProps.panelHeader}
            {...panelHeader}
          />
          <div className={cx(styles.panelContent, classes?.panelContent)}>
            <div className={cx(styles.visibleContent, classes?.visibleContent)}>
              <RowLabel
                className={cx(styles.rowLabel, classes?.rowLabel)}
                {...internalProps.rowLabel}
                {...rowLabel}
              />
              <ToggleItemList
                className={cx(styles.toggleItemList, classes?.toggleItemList)}
                {...internalProps.toggleItemList}
                {...toggleItemList}
              />
            </div>
            <div className={cx(styles.opacityContent, classes?.opacityContent)}>
              <RowLabel
                className={cx(styles.rowLabel1, classes?.rowLabel1)}
                {...internalProps.rowLabel1}
                {...rowLabel1}
              />
              <InputField
                className={cx(styles.inputField, classes?.inputField)}
                {...internalProps.inputField}
                {...inputField}
              />
            </div>
            <div className={cx(styles.shadowContent, classes?.shadowContent)}>
              <RowLabel
                className={cx(styles.rowLabel2, classes?.rowLabel2)}
                {...internalProps.rowLabel2}
                {...rowLabel2}
              />
              <Button
                className={cx(styles.button, classes?.button)}
                {...internalProps.button}
                {...button}
              />
            </div>
            <div className={cx(styles.cursorContent, classes?.cursorContent)}>
              <RowLabel
                className={cx(styles.rowLabel3, classes?.rowLabel3)}
                {...internalProps.rowLabel3}
                {...rowLabel3}
              />
              <DropdownButton
                className={cx(styles.dropdownButton, classes?.dropdownButton)}
                {...internalProps.dropdownButton}
                {...dropdownButton}
              />
            </div>
          </div>
        </div>
      );
      break;
    }
    case 'ExpandedShadows': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <PanelHeader
            className={cx(styles.panelHeader, classes?.panelHeader)}
            {...internalProps.panelHeader}
            {...panelHeader}
          />
          <div className={cx(styles.panelContent, classes?.panelContent)}>
            <div className={cx(styles.visibleContent, classes?.visibleContent)}>
              <RowLabel
                className={cx(styles.rowLabel, classes?.rowLabel)}
                {...internalProps.rowLabel}
                {...rowLabel}
              />
              <ToggleItemList
                className={cx(styles.toggleItemList, classes?.toggleItemList)}
                {...internalProps.toggleItemList}
                {...toggleItemList}
              />
            </div>
            <div className={cx(styles.opacityContent, classes?.opacityContent)}>
              <RowLabel
                className={cx(styles.rowLabel1, classes?.rowLabel1)}
                {...internalProps.rowLabel1}
                {...rowLabel1}
              />
              <InputField
                className={cx(styles.inputField, classes?.inputField)}
                {...internalProps.inputField}
                {...inputField}
              />
            </div>
            <div className={cx(styles.shadowContent, classes?.shadowContent)}>
              <div className={cx(styles.topContent, classes?.topContent)}>
                <RowLabel
                  className={cx(styles.rowLabel2, classes?.rowLabel2)}
                  {...internalProps.rowLabel2}
                  {...rowLabel2}
                />
                <Button
                  className={cx(styles.button, classes?.button)}
                  {...internalProps.button}
                  {...button}
                />
              </div>
              <div className={cx(styles.bottomContent, classes?.bottomContent)}>
                <PropertyItemList
                  className={cx(styles.propertyItemList, classes?.propertyItemList)}
                  {...internalProps.propertyItemList}
                  {...propertyItemList}
                />
              </div>
            </div>
            <div className={cx(styles.cursorContent, classes?.cursorContent)}>
              <RowLabel
                className={cx(styles.rowLabel3, classes?.rowLabel3)}
                {...internalProps.rowLabel3}
                {...rowLabel3}
              />
              <DropdownButton
                className={cx(styles.dropdownButton, classes?.dropdownButton)}
                {...internalProps.dropdownButton}
                {...dropdownButton}
              />
            </div>
          </div>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default StyleEffectsPanel;
