import { ButtonProps } from '../Button';
import { IconProps } from '../Icon';
import { TextProps } from '../Text';

type InputFieldInternalProps = {
  text?: TextProps;
  text1?: TextProps;
  icon?: IconProps;
  button?: ButtonProps;
};

type InputFieldPropsValues = {
  'CodeTextAreaMediumDefault': InputFieldInternalProps;
  'HexCodeMediumDefault': InputFieldInternalProps;
  'IconTextMediumDefault': InputFieldInternalProps;
  'TextAreaMediumDefault': InputFieldInternalProps;
  'TextAreaSmallDefault': InputFieldInternalProps;
  'TextMediumDefault': InputFieldInternalProps;
  'TextMediumError': InputFieldInternalProps;
  'TextMediumHighlighted': InputFieldInternalProps;
  'TextIconMediumDefault': InputFieldInternalProps;
  'TextUnitMediumDefault': InputFieldInternalProps;
  'TextUnitSmallSelected': InputFieldInternalProps;
};

const propValues: InputFieldPropsValues = {
  'CodeTextAreaMediumDefault': {
    text: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralDefault',
    },
  },
  'HexCodeMediumDefault': {
    text: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Regular400',
      colour: 'NeutralDefault',
    },
    text1: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Regular400',
      colour: 'NeutralDefault',
    },
  },
  'IconTextMediumDefault': {
    icon: {
      asset: 'Add',
      colour: 'NeturalHoverSelected',
    },
    text: {
      type: 'LabelSmall',
      align: 'Center',
      style: 'Regular400',
      colour: 'NeutralDefault',
    },
  },
  'TextAreaMediumDefault': {
    text: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Regular400',
      colour: 'NeutralDefault',
    },
  },
  'TextAreaSmallDefault': {
    text: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Regular400',
      colour: 'NeutralDefault',
    },
  },
  'TextMediumDefault': {
    text: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Regular400',
      colour: 'NeutralDefault',
    },
  },
  'TextMediumError': {
    text: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Regular400',
      colour: 'NeutralDefault',
    },
  },
  'TextMediumHighlighted': {
    text: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Regular400',
      colour: 'NeutralDefault',
    },
  },
  'TextIconMediumDefault': {
    text: {
      type: 'LabelSmall',
      align: 'Center',
      style: 'Regular400',
      colour: 'NeutralDefault',
    },
    icon: {
      asset: 'Add',
      colour: 'NeturalHoverSelected',
    },
  },
  'TextUnitMediumDefault': {
    text: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Regular400',
      colour: 'NeutralDefault',
    },
    button: {
      type: 'UnitButton',
      size: 'M',
      state: 'Default',
      style: 'PrimarySubdued',
    },
  },
  'TextUnitSmallSelected': {
    text: {
      type: 'LabelExtraSmall',
      align: 'Right',
      style: 'Regular400',
      colour: 'NeutralDefault',
    },
    button: {
      type: 'UnitButton',
      size: 'S',
      state: 'Default',
      style: 'PrimarySubdued',
    },
  },
};

const getProps = (type: string): InputFieldInternalProps => {
  const values: InputFieldInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
