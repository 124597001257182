import { InputFieldProps } from '../InputField';
import { TextProps } from '../Text';

type ReplaceInputInternalProps = {
  text?: TextProps;
  inputField?: InputFieldProps;
};

type ReplaceInputPropsValues = {
  'Default': ReplaceInputInternalProps;
  'Selected': ReplaceInputInternalProps;
};

const propValues: ReplaceInputPropsValues = {
  'Default': {
    text: {
      type: 'ExtraSmall',
      align: 'Center',
      style: 'Regular400',
      colour: 'NeutralDefault',
    },
  },
  'Selected': {
    inputField: {
      type: 'TextUnit',
      size: 'Small',
      state: 'Selected',
    },
  },
};

const getProps = (type: string): ReplaceInputInternalProps => {
  const values: ReplaceInputInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
