import { ButtonProps } from '../../atoms/Button';
import { PanelHeaderProps } from '../../molecules/PanelHeader';
import { VariableCardListProps } from '../../molecules/VariableCardList';

type PageVariablesPanelInternalProps = {
  panelHeader?: PanelHeaderProps;
  button?: ButtonProps;
  variableCardList?: VariableCardListProps;
};

type PageVariablesPanelPropsValues = {
  'Collapsed': PageVariablesPanelInternalProps;
  'Expanded': PageVariablesPanelInternalProps;
};

const propValues: PageVariablesPanelPropsValues = {
  Collapsed: {
    panelHeader: {
      state: 'Collapsed',
      type: 'Default',
    },
  },
  Expanded: {
    panelHeader: {
      state: 'Expanded',
      type: 'Default',
    },
    button: {
      type: 'IconText',
      size: 'M',
      state: 'Default',
      style: 'PrimarySubdued',
    },
    variableCardList: {
      size: 'Large',
    },
  },
};

const getProps = (type: string): PageVariablesPanelInternalProps => {
  const values: PageVariablesPanelInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
