import { ActionContainerProps } from '../../atoms/ActionContainer';
import { DividerProps } from '../../atoms/Divider';
import { LogicBuilderHeaderProps } from '../../molecules/LogicBuilderHeader';
import { LogicTabListProps } from '../../molecules/LogicTabList';

type LogicBuilderPanelInternalProps = {
  divider?: DividerProps;
  logicBuilderHeader?: LogicBuilderHeaderProps;
  divider1?: DividerProps;
  logicTabList?: LogicTabListProps;
  actionContainer?: ActionContainerProps;
};

type LogicBuilderPanelPropsValues = {
  'Collapsed': LogicBuilderPanelInternalProps;
  'Expanded': LogicBuilderPanelInternalProps;
};

const propValues: LogicBuilderPanelPropsValues = {
  'Collapsed': {
    divider: {
      type: 'Horizontal',
      colour: 'Neutral1900',
    },
    logicBuilderHeader: {
    },
    divider1: {
      type: 'Vertical',
      colour: 'Neutral1900',
    },
  },
  'Expanded': {
    divider: {
      type: 'Horizontal',
      colour: 'Neutral1900',
    },
    logicBuilderHeader: {
    },
    logicTabList: {
    },
    actionContainer: {
    },
    divider1: {
      type: 'Vertical',
      colour: 'Neutral1900',
    },
  },
};

const getProps = (type: string): LogicBuilderPanelInternalProps => {
  const values: LogicBuilderPanelInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
