import { useDrag } from '@use-gesture/react';
import type { ReactDOMAttributes } from '@use-gesture/react/dist/declarations/src/types';
import { useContext, useRef } from 'react';
import { ELEMENT_CONFIGURATIONS } from '../../../store/PageStore/config';
import { StoreContext } from '../../../store/StoreProvider';
import type {
  ElementType,
  IWithId,
  IWithStyles,
  IWithType,
} from '../../../store/types';

export interface IUseResizeable {
  isResizeable: boolean;
  resizeHandleProps: () => ReactDOMAttributes;
}

export const useElementResizeable = (element?: IWithId & IWithType<ElementType> & IWithStyles): IUseResizeable => {
  const { appStore: { canvasScale }, pageStore } = useContext(StoreContext);

  const isResizeable: boolean = element ? ELEMENT_CONFIGURATIONS[element.type].isResizeable : false;

  const resizeStateRef = useRef<{
    initialCssWidth?: number;
    initialCssHeight?: number;
    initialScaledWidthInPx?: number;
    initialScaledHeightInPx?: number;
    prevScaledWidthInPx?: number;
    prevScaledHeightInPx?: number;
  }>({});

  const resizeHandleProps = useDrag(
    async (state) => {
      const {
        dragging: isDragging = false,
        first: isFirstDrag = false,
        last: isLastDrag = false,
        movement: [mx, my],
        target,
        cancel,
      } = state;
      if (!element || !isResizeable) {
        cancel();
        return;
      }

      if (pageStore.isPageOrElementEditMode) {
        cancel();
        return;
      }

      const { parentElement: domElement } = target as HTMLElement;
      if (!domElement) {
        cancel();
        return;
      }

      const domElementRect: DOMRect = domElement.getBoundingClientRect();

      if (isFirstDrag) {
        resizeStateRef.current = {
          initialCssWidth: element.styles.width?.value,
          initialCssHeight: element.styles.height?.value,
          initialScaledWidthInPx: domElementRect.width,
          initialScaledHeightInPx: domElementRect.height,
        };
      } else {
        const {
          initialCssWidth,
          initialCssHeight,
          initialScaledWidthInPx,
          initialScaledHeightInPx,
          prevScaledWidthInPx,
          prevScaledHeightInPx,
        } = resizeStateRef.current;

        if (initialScaledWidthInPx !== undefined && initialScaledHeightInPx !== undefined) {
          let newCssWidth: number;
          let newCssHeight: number;

          // Restore previous width if element width has not changed since the previous tick
          // E.g. this may happen if element's children have fixed dimensions
          if (isLastDrag || prevScaledWidthInPx === domElementRect.width) {
            newCssWidth = domElementRect.width / canvasScale;
            resizeStateRef.current.prevScaledWidthInPx = undefined;
          } else {
            newCssWidth = Math.max(initialScaledWidthInPx + mx, 0) / canvasScale;
            resizeStateRef.current.prevScaledWidthInPx = domElementRect.width;
          }

          // Restore previous height if element height has not changed since the previous tick
          // E.g. this may happen if element's children have fixed dimensions
          if (isLastDrag || prevScaledHeightInPx === domElementRect.height) {
            newCssHeight = domElementRect.height / canvasScale;
            resizeStateRef.current.prevScaledHeightInPx = undefined;
          } else {
            newCssHeight = Math.max(initialScaledHeightInPx + my, 0) / canvasScale;
            resizeStateRef.current.prevScaledHeightInPx = domElementRect.height;
          }

          await pageStore.resizeElement(element, {
            initialCssWidth: initialCssWidth || 0,
            newCssWidth,
            initialCssHeight: initialCssHeight || 0,
            newCssHeight,
            shouldPersistToBackEnd: isLastDrag,
          });

          if (isLastDrag) {
            resizeStateRef.current = {};
          }
        }
      }

      // console.log('Resize', {
      //   isDragging,
      //   isFirstDrag,
      //   isLastDrag,
      //   movement: [mx, my],
      //   domElementRect,
      //   resizeStateRef: resizeStateRef.current,
      // });
    },
    { filterTaps: true },
  );

  return {
    isResizeable,
    resizeHandleProps,
  };
};
