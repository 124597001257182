import { computed } from 'mobx';
import { useContext } from 'react';
import { StoreContext } from '../../../store/StoreProvider';
import { NewPageUuid } from '../../../store/types';
import type { PageLabelItemCombinedProps } from '../PageLabelItem/types';
import type { PageLabelItemListCombinedProps } from './types';

const usePresenter = (props: PageLabelItemListCombinedProps): PageLabelItemListCombinedProps => {
  const { pageStore } = useContext(StoreContext);

  const pageLabelItems: PageLabelItemCombinedProps[] = computed(() => {
    const items: PageLabelItemCombinedProps[] = pageStore.sortedPages.map((page) => ({ pageUuid: page.uuid }));
    if (pageStore.isPageCreationMode) {
      items.push({ pageUuid: NewPageUuid });
    }
    return items;
  }).get();

  return {
    ...props,
    pageLabelItems,
  };
};

export default usePresenter;
