// MANUAL OVERRIDE STARTS
import { observer } from 'mobx-react-lite';
import React from 'react';
import type { ExportedModalProps } from '../../../modules/common/ModalWrapper';
import withModalWrapper from '../../../modules/common/ModalWrapper/withModalWrapper';
import InternalConfigureGroupMenu from './ConfigureGroupMenu';
import type { ConfigureGroupMenuCombinedProps as InternalConfigureGroupMenuCombinedProps, ConfigureGroupMenuProps, ConfigureGroupMenuValueProps } from './types';

export type { ConfigureGroupMenuProps, ConfigureGroupMenuValueProps };
export type ConfigureGroupMenuAndModalCombinedProps = ExportedModalProps<InternalConfigureGroupMenuCombinedProps>;

const ConfigureGroupMenu = withModalWrapper(observer(InternalConfigureGroupMenu));
export default ConfigureGroupMenu;
// MANUAL OVERRIDE ENDS
