import { TextProps } from '../../atoms/Text';
import { TableRowListProps } from '../../molecules/TableRowList';

type KeyValueTableFilledTextTextValueInternalProps = {
  name?: TextProps;
  type?: TextProps;
  value?: TextProps;
  tableRowList?: TableRowListProps;
};

type KeyValueTableFilledTextTextValuePropsValues = {
  '': KeyValueTableFilledTextTextValueInternalProps;
};

const propValues: KeyValueTableFilledTextTextValuePropsValues = {
  '': {
    name: {
      type: 'LabelMedium',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralHoverSelected',
    },
    type: {
      type: 'LabelMedium',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralHoverSelected',
    },
    value: {
      type: 'LabelMedium',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralHoverSelected',
    },
    tableRowList: {
      state: 'TestVariable',
      type: 'Default',
    },
  },
};

const getProps = (type: string): KeyValueTableFilledTextTextValueInternalProps => {
  const values: KeyValueTableFilledTextTextValueInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
