import {
  action,
  when,
  computed,
  makeAutoObservable,
  observable,
  runInAction,
} from 'mobx';
import {
  Action,
  FlowTypeEnum,
  Flow,
  UpdateAction,
  UpdateActionPayload,
} from '../../modules/actions/types';
import {
  getActionsApi,
  createActionApi,
  deleteActionApi,
  updateActionApi,
  replaceActionApi,
} from '../../modules/actions/actions.repository';
import { convertToDoubleLinked, findFirstFlowAction } from './utils';
import { matchesObjectSubset } from '../../lib/utils';
import { typedDeepMerge } from '../../utils/objectUtils';

export class LogicBuilderStore {
  // private readonly pageStore: PageStore;

  public constructor() {
    // this.pageStore = pageStore;
    makeAutoObservable(this);
  }

  @observable
  public isInitialised: boolean | undefined = undefined;

  @observable
  public actions: Action[] = [];

  @action
  public initialise = () => {
    // Start initialisation
    this.isInitialised = false;

    // Populate state
    runInAction(() => {
      this.setYOffset(0);
    });

    // Complete initialisation
    runInAction(() => {
      this.isInitialised = true;
    });
  };

  @observable
  private currentPageUuid: string | undefined;

  @observable
  private selectedElementId: string | undefined;

  @action
  public updateCurrentPage = async (pageUuid: string | undefined) => {
    if (pageUuid) {
      this.actions = await getActionsApi(pageUuid);
    } else {
      this.actions = [];
    }
    this.currentPageUuid = pageUuid;
  };

  @action
  public updateSelectedElementId = (elementId: string | undefined) => {
    this.selectedElementId = elementId;
  };

  @observable
  public yOffset = 0;

  @action
  public setYOffset = (yOffset: number) => {
    if (this.yOffset !== yOffset) {
      this.yOffset = yOffset;
    }
  };

  @observable
  public selectedFlowType: FlowTypeEnum = 'OnClick';

  @action
  public setSelectedFlowType = (flowType: FlowTypeEnum) => {
    if (this.selectedFlowType !== flowType) {
      this.selectedFlowType = flowType;
    }
  };

  @computed
  private get elementFlowActions(): Record<string, Record<string, Flow>> {
    // sort actions by element uuid and flow type
    const elementFlowActions: Record<string, Record<string, Flow>> = {};
    this.actions.forEach((item) => {
      const { uuid, elementUuid, flowType } = item;
      const elementFlows = elementFlowActions[elementUuid] || {};
      const flowActions = elementFlows[flowType] || [];
      flowActions[uuid] = item;
      elementFlows[flowType] = flowActions;
      elementFlowActions[elementUuid] = elementFlows;
    });
    return elementFlowActions;
  }

  @observable
  private selectedActionUuid: string | undefined;

  @action
  public setSelectedActionUuid = (selectedActionUuid: string | undefined) => {
    if (this.selectedActionUuid !== selectedActionUuid) {
      this.selectedActionUuid = selectedActionUuid;
    }
  };

  @computed
  public get selectedAction(): Action | undefined {
    if (!this.selectedElementId || !this.selectedActionUuid) {
      return undefined;
    }
    const elementFlows = this.elementFlowActions[this.selectedElementId] || {};
    const flow = elementFlows[this.selectedFlowType];
    if (flow) {
      return flow[this.selectedActionUuid];
    }
    return undefined;
  }

  @computed
  public get selectedFlow(): Flow | undefined {
    if (!this.selectedElementId) {
      return undefined;
    }
    const elementFlows = this.elementFlowActions[this.selectedElementId] || {};
    const flow = elementFlows[this.selectedFlowType];
    if (flow) {
      const doubleLinkedFlow = convertToDoubleLinked(flow);
      // check if we need to switch selected action if element or flow type changed
      if (this.selectedAction?.elementUuid !== this.selectedElementId ||
        this.selectedAction.flowType !== this.selectedFlowType) {
        const firstAction = findFirstFlowAction(doubleLinkedFlow);
        runInAction(() => {
          this.setSelectedActionUuid(firstAction?.uuid);
        });
      }
      return doubleLinkedFlow;
    }
    runInAction(() => {
      this.setSelectedActionUuid(undefined);
    });
    return undefined;
  }

  @action
  public addAction = async (prevActionUuid: string | undefined, nextActionUuid: string | undefined) => {
    if (!this.selectedElementId) {
      return;
    }
    if (this.currentPageUuid) {
      const newAction = await createActionApi({
        elementUuid: this.selectedElementId,
        actionType: undefined,
        flowType: this.selectedFlowType,
        metadata: undefined,
        prevActionUuid: prevActionUuid || undefined,
        pageUuid: this.currentPageUuid,
      });

      if (newAction) {
        runInAction(() => {
          this.actions = [...this.actions, newAction];
        });
        this.setSelectedActionUuid(newAction.uuid);
        // update the next action to link to the new action
        if (nextActionUuid) {
          const nextActionIndex = this.actions.findIndex((item) => item.uuid === nextActionUuid);
          if (nextActionIndex > -1) {
            const nextAction = this.actions[nextActionIndex];
            await this.updateAction(nextAction, { prevActionUuid: newAction.uuid });
          }
        }
      }
    }
  };

  @action
  public updateAction = async (updateAction: Action, options: UpdateAction) => {
    const { uuid: actionUuid } = updateAction;

    const prevActionIndex = this.actions.findIndex((item) => item.uuid === actionUuid);
    if (prevActionIndex === -1) {
      return;
    }

    const {
      pageUuid: prevPageUuid,
      actionType: prevActionType,
      metadata: prevMetadata,
      prevActionUuid: prevPrevActionUuid,
    } = this.actions[prevActionIndex];
    const { actionType, metadata, prevActionUuid, shouldMerge = true, shouldPersistToBackEnd = true } = options;

    const isMetadataChanged: boolean = !!metadata && !matchesObjectSubset(prevMetadata || {}, metadata);
    // Check if there is any change
    if ((!actionType || prevActionType === actionType) &&
      (!metadata || !isMetadataChanged) &&
      (!prevActionUuid || prevPrevActionUuid === prevActionUuid)) {
      return;
    }
    const updateActionPayload: UpdateActionPayload = {
      uuid: actionUuid,
      pageUuid: prevPageUuid,
    };
    // Optimistically update the front end, i.e. even before the data is persisted to the back end
    // Update action type
    if (actionType && prevActionType !== actionType) {
      this.actions[prevActionIndex] = { ...this.actions[prevActionIndex], actionType };
      updateActionPayload.actionType = actionType;
    }

    // Optimistically Update metadata
    if (metadata && isMetadataChanged) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      const localMetadata = shouldMerge && prevMetadata ? typedDeepMerge(prevMetadata, metadata) : metadata;
      this.actions[prevActionIndex] = { ...this.actions[prevActionIndex], metadata: localMetadata };
      updateActionPayload.metadata = metadata;
    }

    // Optimistically Update prevActionUuid
    if (prevActionUuid && prevPrevActionUuid !== prevActionUuid) {
      this.actions[prevActionIndex] = { ...this.actions[prevActionIndex], prevActionUuid };
      updateActionPayload.prevActionUuid = prevActionUuid;
    }

    if (shouldPersistToBackEnd) {
      try {
        if (shouldMerge) {
          await updateActionApi(updateActionPayload);
        } else {
          await replaceActionApi({
            ...updateAction,
            ...updateActionPayload,
          });
        }
      } catch {
        // Rollback the front end
        await this.updateAction(updateAction, {
          actionType: prevActionType,
          metadata: prevMetadata,
          // Do not merge, replace all CSS props and settings with previous values in full
          shouldMerge: false,
          // Do not persist the update to the back end, i.e. perform only the front end rollback
          shouldPersistToBackEnd: false,
        });
      }
    }
  };

  @action
  public deleteAction = async () => {
    if (this.selectedAction && this.currentPageUuid) {
      const { prevActionUuid, uuid } = this.selectedAction;
      const pageUuid = this.currentPageUuid;
      try {
        const actionUuidsToDelete: string[] = [];
        if (this.selectedFlow) {
          let nextUuid: string | undefined = uuid;
          while (nextUuid) {
            actionUuidsToDelete.push(nextUuid);
            const { nextActionUuid } = this.selectedFlow[nextUuid];
            nextUuid = nextActionUuid;
          }
        }
        const deletePromises = actionUuidsToDelete.map((deleteUuid) => deleteActionApi(pageUuid, deleteUuid));
        await Promise.all(deletePromises);

        runInAction(() => {
          this.actions = this.actions.filter((item) => actionUuidsToDelete.indexOf(item.uuid) === -1);
        });
      } catch (e) {
        return;
      }
      const newSelectedAction = this.actions.find((item) => item.uuid === prevActionUuid);
      this.setSelectedActionUuid(newSelectedAction?.uuid);
    }
  };
}
