import React from 'react';
import SelectFileWrapper from '../../../modules/common/SelectFileWrapper/SelectFileWrapper';
import { TriggerProps } from '../../../modules/common/SelectFileWrapper/types';
import { typedDeepMerge } from '../../../utils/objectUtils';
import InternalReplaceButton from './ReplaceButton';
import {
  ReplaceButtonProps,
  ReplaceButtonValueProps as InternalReplaceButtonValueProps,
  ReplaceButtonCombinedProps,
} from './types';

export type { ReplaceButtonProps };
export type ReplaceButtonValueProps = InternalReplaceButtonValueProps & TriggerProps;

const ReplaceButton: React.FC<ReplaceButtonProps & ReplaceButtonValueProps> = (props) => {
  const { state, actionType, onFileSelected, onFileRemoved, propertyItem } = props;
  // SelectFileWrapper is only used if the actionType is SelectImage
  if (actionType !== 'SelectImage') {
    return (
      <InternalReplaceButton {...props} />
    );
  }
  return (
    <SelectFileWrapper
      accept={['image/png', 'image/jpeg']}
      onFileSelected={onFileSelected}
      onFileRemoved={onFileRemoved}
      renderTrigger={({ currentFile, onPickFile, onClearFile }) => {
        const imageUploaded = state === 'Uploaded' || currentFile;
        const updatedProps: ReplaceButtonCombinedProps = typedDeepMerge(props, {
          type: imageUploaded ? 'Image' : 'Default',
          state: imageUploaded ? 'Uploaded' : 'Default',
          button: {
            onClick: onPickFile,
          },
          propertyItem: {
            icon: {
              asset: 'Image',
            },
            text: {
              value: currentFile?.name || propertyItem?.text?.value,
            },
            button: {
              icon: {
                asset: 'Close',
              },
              onClick: onClearFile,
            },
          },
        });
        return <InternalReplaceButton {...updatedProps} />;
      }}
    />
  );
};

export default ReplaceButton;
