import React from 'react';
import cx from 'classnames';

import Button from '../../atoms/Button';
import Icon from '../../atoms/Icon';
import Text from '../../atoms/Text';

import styles from './MenuHeader.module.scss';
import usePresenter from './MenuHeader.presenter';
import getProps from './MenuHeader.props';
import { MenuHeaderCombinedProps } from './types';

const MenuHeader: React.FC<MenuHeaderCombinedProps> = (props) => {
  const {
    type,
    className,
    classes,
    text,
    button,
    icon,
  } = usePresenter(props);

  const variantName = `${type}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`menuHeader${variantName}`];

  let componentView;

  switch (variantName) {
    case 'Default': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <Text
            className={cx(styles.text, classes?.text)}
            {...internalProps.text}
            {...text}
          />
          <Button
            className={cx(styles.button, classes?.button)}
            {...internalProps.button}
            {...button}
          />
        </div>
      );
      break;
    }
    case 'Variable': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <Icon
            className={cx(styles.icon, classes?.icon)}
            {...internalProps.icon}
            {...icon}
          />
          <Text
            className={cx(styles.text, classes?.text)}
            {...internalProps.text}
            {...text}
          />
          <Button
            className={cx(styles.button, classes?.button)}
            {...internalProps.button}
            {...button}
          />
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default MenuHeader;
