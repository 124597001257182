import React from 'react';
import cx from 'classnames';

import RowLabel from '../../atoms/RowLabel';
import ReplaceRow from '../../molecules/ReplaceRow';
import ToggleItemList from '../../molecules/ToggleItemList';

import styles from './SettingsRow.module.scss';
import usePresenter from './SettingsRow.presenter';
import getProps from './SettingsRow.props';
import { SettingsRowCombinedProps } from './types';

const SettingsRow: React.FC<SettingsRowCombinedProps> = (props) => {
  const {
    type,
    className,
    classes,
    rowLabel,
    toggleItemList,
    replaceRow,
  } = usePresenter(props);

  const variantName = `${type}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`settingsRow${variantName}`];

  let componentView;

  switch (variantName) {
    case 'Toggle': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <div className={cx(styles.toggleRow, classes?.toggleRow)}>
            <RowLabel
              className={cx(styles.rowLabel, classes?.rowLabel)}
              {...internalProps.rowLabel}
              {...rowLabel}/>
            <ToggleItemList
              className={cx(styles.toggleItemList, classes?.toggleItemList)}
              {...internalProps.toggleItemList}
              {...toggleItemList}/>
          </div>
        </div>
      );
      break;
    }
    case 'Variable': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <ReplaceRow
            className={cx(styles.replaceRow, classes?.replaceRow)}
            {...internalProps.replaceRow}
            {...replaceRow}/>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default SettingsRow;
