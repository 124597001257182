import { ButtonProps } from '../../atoms/Button';
import { SectionLabelProps } from '../../molecules/SectionLabel';
import { TextCardListProps } from '../../molecules/TextCardList';

type GroupsPanelInternalProps = {
  sectionLabel?: SectionLabelProps;
  button?: ButtonProps;
  textCardList?: TextCardListProps;
};

type GroupsPanelPropsValues = {
  '': GroupsPanelInternalProps;
};

const propValues: GroupsPanelPropsValues = {
  '': {
    sectionLabel: {
      type: 'MainSection',
      style: 'TextNoIcon',
    },
    button: {
      type: 'IconText',
      size: 'M',
      state: 'Default',
      style: 'PrimarySubdued',
    },
    textCardList: {
    },
  },
};

const getProps = (type: string): GroupsPanelInternalProps => {
  const values: GroupsPanelInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
