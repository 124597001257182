import { RowLabelProps } from '../../atoms/RowLabel';
import { ColourPreviewProps } from '../../atoms/ColourPreview';
import { InputFieldProps } from '../../atoms/InputField';

type ColourRowInternalProps = {
  colour?: RowLabelProps;
  colourPreview?: ColourPreviewProps;
  hexCodeField?: InputFieldProps;
  opacityField?: InputFieldProps;
};

type ColourRowPropsValues = {
  '': ColourRowInternalProps;
};

const propValues: ColourRowPropsValues = {
  '': {
    colour: {
      state: 'Default',
      style: 'Default',
      type: 'Panel',
    },
    colourPreview: {
    },
    hexCodeField: {
      type: 'HexCode',
      size: 'Medium',
      state: 'Default',
    },
    opacityField: {
      type: 'TextUnit',
      size: 'Medium',
      state: 'Default',
    },
  },
};

const getProps = (type: string): ColourRowInternalProps => {
  const values: ColourRowInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
