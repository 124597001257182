import { useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './VariableResponsePanel.module.scss';
import { TEMP_API_RESPONSES } from '../../../lib/constants';
import { ApiResponseVariable } from '../../../modules/variables/types';
import { createVariableApi, deleteVariableApi, updateVariableApi } from '../../../modules/variables/variables.repository';
import { ApiResponsePropMetadata, ApiResponseSchema } from '../../../store/ApiGroupStore/types';
import { resolveApiCallAction } from '../../../store/LogicBuilderStore/utils';
import { getCallApiActionVariables } from '../../../store/PageStore/variableUtils';
import { StoreContext } from '../../../store/StoreProvider';
import { VariableCardCombinedProps } from '../../molecules/VariableCard/types';
import { VariableResponsePanelCombinedProps, VariableResponsePanelStateEnum } from './types';
import useModal from '../../../modules/common/ModalWrapper/context/useModal';
import { ExpandedVariableResponsesContext } from '.';
import { excludePropTypeIgnore } from '../../../lib/utils';

const usePresenter = (props: VariableResponsePanelCombinedProps): VariableResponsePanelCombinedProps => {
  const {
    logicBuilderStore,
    pageStore,
    apiGroupStore,
  } = useContext(StoreContext);
  const { apiGroups } = apiGroupStore;
  const { currentPageVariables, appendVariable, updateVariable, removeVariable } = pageStore;
  const { selectedAction } = logicBuilderStore;
  const { t } = useTranslation();
  const [state, setState] = useState<VariableResponsePanelStateEnum>('Expanded');
  const handleCreateNewVariable = useCallback(async (prop: ApiResponsePropMetadata) => {
    const { propName, propType, responsePath } = prop;
    if (selectedAction && selectedAction.actionType === 'CallApi' && propType !== 'ignore') {
      const newVariable = await createVariableApi({
        name: propName,
        source: 'ApiResponse',
        scope: 'Page',
        type: propType,
        pageUuid: selectedAction.pageUuid,
        metadata: {
          actionUuid: selectedAction.uuid,
          apiGroupUuid: selectedAction.metadata?.apiGroupUuid || '',
          apiEndpointUuid: selectedAction.metadata?.apiEndpointUuid || '',
          responseName: propName,
          responsePath,
          initialValue: '',
        },
      });
      appendVariable(newVariable);
      return newVariable;
    }
    return undefined;
  }, [selectedAction, appendVariable]);
  const handleUpdateVariable = useCallback(async (variable: ApiResponseVariable) => {
    updateVariable(variable);
    const updatedVariable = await updateVariableApi({
      variableUuid: variable.uuid,
      name: variable.name,
      scope: variable.scope,
      metadata: variable.metadata,
    });
    updateVariable(updatedVariable);
  }, [updateVariable]);
  const handleDeleteVariable = useCallback(async (variable: ApiResponseVariable) => {
    await deleteVariableApi({ variableUuid: variable.uuid });
    removeVariable(variable);
  }, [removeVariable]);
  const { endpoint } = selectedAction?.actionType === 'CallApi' ? resolveApiCallAction(selectedAction, apiGroups) : { endpoint: undefined };
  // TODO: remove fallback data
  const apiResponseItems: ApiResponseSchema = excludePropTypeIgnore(endpoint?.responseSchema) || TEMP_API_RESPONSES;
  const actionCreatedVariables = useMemo(() => {
    return selectedAction?.actionType === 'CallApi' ? getCallApiActionVariables(currentPageVariables, selectedAction) : [];
  }, [selectedAction, currentPageVariables]);
  const createdVariablesMap = useMemo(() => actionCreatedVariables.reduce((map: Record<string, ApiResponseVariable>, item: ApiResponseVariable) => ({
    ...map,
    [item.metadata.responsePath]: item,
  }), {}), [actionCreatedVariables]);
  const checkIfVariableCreated = useCallback((prop: ApiResponsePropMetadata) => {
    return createdVariablesMap[prop.responsePath] !== undefined;
  }, [createdVariablesMap]);

  const { openModal } = useModal(
    ExpandedVariableResponsesContext,
    () => {
      return {
        variableSection: {
          logicVariableTable: {
            state: actionCreatedVariables.length ? 'Filled' : 'Empty',
            emptyStateInfo: {
              text: {
                value: t('variableResponsePanel.variables.empty'),
              },
            },
            variables: actionCreatedVariables,
            onItemUpdated: handleUpdateVariable,
            onItemDeleted: handleDeleteVariable,
          },
        },
        responseSection: {
          responseItemList: {
            apiResponseItems,
            responseItemType: 'ExpandedView',
            checkIfVariableCreated,
            handleCreateNewVariable,
          },
        },
      };
    },
  );

  const openVariableMenu = useCallback((variable?: ApiResponseVariable) => {
    openModal({
      backdropClassName: styles.backdrop,
    });
  }, [openModal]);

  if (selectedAction?.actionType !== 'CallApi') {
    return {
      renderPanel: false,
    };
  }
  const variableCards = actionCreatedVariables.map((variable): VariableCardCombinedProps => {
    return {
      size: 'Small',
      type: variable.scope as 'Global' | 'Page',
      variableName: {
        value: variable.name,
      },
      editIcon: {
        type: 'IconClear',
        size: 'M',
        state: 'Default',
        style: 'PrimarySubdued',
        icon: {
          asset: 'Edit',
          colour: 'NeutralDefault',
        },
        onClick: () => openVariableMenu(variable),
      },
    };
  });
  return {
    ...props,
    renderPanel: endpoint !== undefined,
    state,
    panelHeader: {
      button: {
        onClick: () => setState(state === 'Collapsed' ? 'Expanded' : 'Collapsed'),
      },
      text: {
        value: t('variableResponsePanel.title'),
      },
      button1: {
        icon: {
          asset: 'Expand',
        },
        onClick: () => openVariableMenu(),
      },
    },
    variablesContent: {
      state: actionCreatedVariables.length ? 'Filled' : 'Empty',
      heading: {
        value: t('variableResponsePanel.variables.title'),
      },
      emptyStateInfo: {
        text: {
          value: t('variableResponsePanel.variables.empty'),
        },
      },
      variableCardList: {
        variableCards,
      },
    },
    heading: {
      value: t('variableResponsePanel.responses.title'),
    },
    instructions: {
      value: t('variableResponsePanel.responses.label'),
    },
    responseItemList: {
      apiResponseItems,
      responseItemType: 'Panel',
      checkIfVariableCreated,
      handleCreateNewVariable: async (variableProps) => {
        const newVariable = await handleCreateNewVariable(variableProps);
        openVariableMenu(newVariable as ApiResponseVariable);
      },
    },
  };
};
export default usePresenter;
