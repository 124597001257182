import { TFunction } from 'i18next';
import {
  Element, IText, IImage, InputPropType, InputProp, ImageValueInputProp, SettingImageProp, InputPropSource,
} from '../types';
import { PageStore } from './PageStore';
import defaultImage from '../../resources/images/Image.png';
import { uploadFile } from '../../modules/assets/utils';
import { resolveVariable } from './variableUtils';
import { Variable } from '../../modules/variables/types';

const getInputProp = (prop: InputProp | undefined, name: string, displayName: string, type: InputPropType): InputProp => {
  const result = {
    ...prop,
    name,
    displayName,
    source: prop?.source || 'value',
    type,
    value: prop?.value,
  };
  return result as InputProp;
};

export const getElementInputProps = (selectedElement: Element | undefined, t: TFunction): InputProp[] | undefined => {
  switch (selectedElement?.type) {
    case 'Text':
      return [getInputProp(selectedElement.settings.value, 'value', t('settingsPanel.elements.text.displayName'), 'string')];
    case 'ButtonContainer':
      return [getInputProp(selectedElement.settings.state, 'state', t('settingsPanel.elements.button.displayName'), 'boolean')];
    case 'Image':
      return [getInputProp(selectedElement.settings.image, 'image', t('settingsPanel.elements.image.displayName'), 'image')];
    default:
      break;
  }
  return undefined;
};

export const getInputPropTextValue = (
  inputProp: InputProp | undefined | null,
  storedVariables: Variable[] = [],
  globalValues: Record<string, string> = {},
  pageValues: Record<string, string> = {},
  pageNavigationValues: Record<string, string> = {},
) => {
  if (inputProp?.source === 'variable') {
    const resolved = resolveVariable(inputProp, storedVariables, globalValues, pageValues, pageNavigationValues);
    return resolved?.value ? `${resolved?.value as string}` : `{${inputProp.value?.name}}`;
  } else {
    switch (inputProp?.type) {
      case 'boolean':
      case 'number':
        return `${inputProp.value}`;
      case 'string':
        return inputProp.value;
      default:
        return undefined;
    }
  }
};

export const getTextValue = (
  selectedElement: IText | undefined,
  storedVariables: Variable[] = [],
  globalValues: Record<string, string> = {},
  pageValues: Record<string, string> = {},
  pageNavigationValues: Record<string, string> = {},
) => {
  return getInputPropTextValue(
    selectedElement?.settings?.value,
    storedVariables,
    globalValues,
    pageValues,
    pageNavigationValues,
  ) || '[Missing Value]';
};

export const getImageUrl = (
  selectedElement: IImage | undefined,
  storedVariables: Variable[] = [],
  globalValues: Record<string, string> = {},
  pageValues: Record<string, string> = {},
  pageNavigationValues: Record<string, string> = {},
) => {
  const imageProp = selectedElement?.settings?.image;
  if (imageProp?.source === 'variable') {
    const resolved = resolveVariable(imageProp, storedVariables, globalValues, pageValues, pageNavigationValues);
    return resolved?.value ? `${resolved?.value as string}` : defaultImage;
  }
  return imageProp?.value?.imageUrl || defaultImage;
};

export const getImageAltText = (selectedElement: IImage | undefined) => {
  const imageProp = selectedElement?.settings?.image;
  if (imageProp?.source === 'variable') {
    return undefined;
  }
  return imageProp?.value?.altText;
};

export const updateInputPropValue = async (
  pageStore: PageStore,
  selectedElement: Element | undefined,
  inputProp: InputProp,
  source: InputPropSource,
  value: InputProp['value'],
) => {
  if (!selectedElement) {
    return;
  }
  // check if we need to update the value
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  if (selectedElement.settings[inputProp.name]?.value === value) {
    return;
  }

  await pageStore.updateElement(selectedElement, {
    settings: {
      [inputProp.name]: {
        ...inputProp,
        source,
        value: value || '',
      } as InputProp,
    },
  });
};

export const updateTextValue = async (
  pageStore: PageStore,
  selectedElement: IText | undefined,
  value: string | undefined,
) => {
  if (!selectedElement) {
    return;
  }
  // check if we need to update the value
  if (selectedElement.settings.value?.value === value) {
    return;
  }
  await pageStore.updateElement(selectedElement, {
    settings: {
      value: {
        name: 'value',
        displayName: 'Text Value',
        source: 'value',
        type: 'string',
        value: value || '',
      },
    },
  });
};

export const updateImageValue = async (
  pageStore: PageStore,
  selectedElement: IImage | undefined,
  file: File | null,
) => {
  if (!selectedElement) {
    return undefined;
  }

  let imageUrl: string | undefined;
  if (file) {
    imageUrl = await uploadFile(file, 'images');
  }

  const imageSettings: SettingImageProp | undefined = file ? {
    imageUrl: imageUrl || '',
    fileName: file.name,
  } : undefined;

  const imageInput: ImageValueInputProp = {
    name: 'image',
    displayName: 'Image Value',
    source: 'value',
    type: 'image',
    value: imageSettings,
  };
  await pageStore.updateElement(selectedElement, {
    settings: {
      image: imageInput,
    },
  });
  return imageUrl;
};
