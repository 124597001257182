import React from 'react';
import cx from 'classnames';

import Divider from '../../atoms/Divider';
import ElementItemList from '../../molecules/ElementItemList';
import SectionLabel from '../../molecules/SectionLabel';
import LayerLabelItemList from '../../molecules/LayerLabelItemList';
import PageLabelItemList from '../../molecules/PageLabelItemList';

import styles from './LeftPanel.module.scss';
import usePresenter from './LeftPanel.presenter';
import getProps from './LeftPanel.props';
import { LeftPanelCombinedProps } from './types';

const LeftPanel: React.FC<LeftPanelCombinedProps> = (props) => {
  const {
    state,
    className,
    classes,
    divider1,
    elementItemsSectionLabel,
    elementItemList,
    elementsSectionLabel,
    layerLabelItemList,
    pagesSectionLabel,
    pageLabelItemList,
  } = usePresenter(props);

  const variantName = `${state}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`leftPanel${variantName}`];

  // MANUAL OVERRIDE STARTS
  const componentView = (
    <>
      <div className={cx(currentStyle, className, classes?.elementPanelContainer)}>
        <Divider
          className={cx(styles.divider1, classes?.divider1)}
          {...internalProps.divider1}
          {...divider1}/>
        <SectionLabel
          className={cx(styles.elementItemsSectionLabel, classes?.elementItemsSectionLabel)}
          {...internalProps.elementItemsSectionLabel}
          {...elementItemsSectionLabel}/>
        <div className={cx(styles.elementContainer, classes?.elementContainer)}>
          <ElementItemList
            className={cx(styles.elementItemList, classes?.elementItemList)}
            {...internalProps.elementItemList}
            {...elementItemList}/>
        </div>
      </div>
      <div className={cx(currentStyle, className, classes?.layerPanelContainer)}>
        <Divider
          className={cx(styles.divider1, classes?.divider1)}
          {...internalProps.divider1}
          {...divider1}/>
        <SectionLabel
          className={cx(styles.elementsSectionLabel, classes?.elementsSectionLabel)}
          {...internalProps.elementsSectionLabel}
          {...elementsSectionLabel}/>
        <div className={cx(styles.labelListContainer, classes?.labelListContainer)}>
          <LayerLabelItemList
            className={cx(styles.layerLabelItemList, classes?.layerLabelItemList)}
            {...internalProps.layerLabelItemList}
            {...layerLabelItemList}/>
        </div>
      </div>
      <div className={cx(currentStyle, className, classes?.pagePanelContainer)}>
        <Divider
          className={cx(styles.divider1, classes?.divider1)}
          {...internalProps.divider1}
          {...divider1}/>
        <SectionLabel
          className={cx(styles.pagesSectionLabel, classes?.pagesSectionLabel)}
          {...internalProps.pagesSectionLabel}
          {...pagesSectionLabel}/>
        <div className={cx(styles.pageListContainer, classes?.pageListContainer)}>
          <PageLabelItemList
            className={cx(styles.pageLabelItemList, classes?.pageLabelItemList)}
            {...internalProps.pageLabelItemList}
            {...pageLabelItemList}/>
        </div>
      </div>
    </>
  );
  // MANUAL OVERRIDE ENDS

  return componentView;
};

export default LeftPanel;
