import React from 'react';
import cx from 'classnames';

import Button from '../../atoms/Button';
import EmptyStateInfo from '../../atoms/EmptyStateInfo';
import Text from '../../atoms/Text';
import CodeSpace from '../../molecules/CodeSpace';
import KeyValueTableFilledTextTextValue from '../../organisms/KeyValueTableFilledTextTextValue';

import styles from './TestVariableBlock.module.scss';
import usePresenter from './TestVariableBlock.presenter';
import getProps from './TestVariableBlock.props';
import { TestVariableBlockCombinedProps } from './types';

const TestVariableBlock: React.FC<TestVariableBlockCombinedProps> = (props) => {
  const {
    state,
    className,
    classes,
    text,
    emptyStateInfo,
    keyValueTableFilledTextTextValue,
    button,
    codeSpace,
  } = usePresenter(props);

  const variantName = `${state}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`testVariableBlock${variantName}`];

  let componentView;

  switch (variantName) {
    case 'Empty': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <div className={cx(styles.labelContent, classes?.labelContent)}>
            <Text
              className={cx(styles.text, classes?.text)}
              {...internalProps.text}
              {...text}/>
          </div>
          <EmptyStateInfo
            className={cx(styles.emptyStateInfo, classes?.emptyStateInfo)}
            {...internalProps.emptyStateInfo}
            {...emptyStateInfo}/>
        </div>
      );
      break;
    }
    case 'Filled': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <div className={cx(styles.labelContent, classes?.labelContent)}>
            <Text
              className={cx(styles.text, classes?.text)}
              {...internalProps.text}
              {...text}/>
          </div>
          <KeyValueTableFilledTextTextValue
            className={cx(styles.keyValueTableFilledTextTextValue, classes?.keyValueTableFilledTextTextValue)}
            {...internalProps.keyValueTableFilledTextTextValue}
            {...keyValueTableFilledTextTextValue}/>
        </div>
      );
      break;
    }
    case 'Collapsed':
    case 'Disabled': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <div className={cx(styles.headerContent, classes?.headerContent)}>
            <div className={cx(styles.labelContent, classes?.labelContent)}>
              <Text
                className={cx(styles.text, classes?.text)}
                {...internalProps.text}
                {...text}/>
            </div>
            <Button
              className={cx(styles.button, classes?.button)}
              {...internalProps.button}
              {...button}/>
          </div>
        </div>
      );
      break;
    }
    case 'Expanded': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <div className={cx(styles.headerContent, classes?.headerContent)}>
            <div className={cx(styles.labelContent, classes?.labelContent)}>
              <Text
                className={cx(styles.text, classes?.text)}
                {...internalProps.text}
                {...text}/>
            </div>
            <Button
              className={cx(styles.button, classes?.button)}
              {...internalProps.button}
              {...button}/>
          </div>
          <CodeSpace
            className={cx(styles.codeSpace, classes?.codeSpace)}
            {...internalProps.codeSpace}
            {...codeSpace}/>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default TestVariableBlock;
