import React from 'react';
import cx from 'classnames';

import ToggleItemList from '../../molecules/ToggleItemList';

import styles from './StyleSettingsToggle.module.scss';
import usePresenter from './StyleSettingsToggle.presenter';
import getProps from './StyleSettingsToggle.props';
import { StyleSettingsToggleCombinedProps } from './types';

const StyleSettingsToggle: React.FC<StyleSettingsToggleCombinedProps> = (props) => {
  const {
    className,
    classes,
    toggleItemList,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.styleSettingsToggle;

  return (
    <div className={cx(currentStyle, className)}>
      <ToggleItemList
        className={cx(styles.toggleItemList, classes?.toggleItemList)}
        {...internalProps.toggleItemList}
        {...toggleItemList}
      />
    </div>
  );
};

export default StyleSettingsToggle;
