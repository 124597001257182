import React from 'react';
import cx from 'classnames';

import SettingsRow from '../SettingsRow';

import styles from './SettingsRowList.module.scss';
import usePresenter from './SettingsRowList.presenter';
import getProps from './SettingsRowList.props';
import { SettingsRowListCombinedProps } from './types';

const SettingsRowList: React.FC<SettingsRowListCombinedProps> = (props) => {
  const {
    className,
    classes,
    settingsRows,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.settingsRowList;

  const settingsRowViews = settingsRows?.map((settingsRow, index) => (
    <SettingsRow
      key={index}
      className={cx(styles.settingsRow, classes?.settingsRow)}
      {...internalProps.settingsRow}
      {...settingsRow} />
  )) || [];

  return (
    <div className={cx(currentStyle, className)}>
      {settingsRowViews}
    </div>
  );
};

export default SettingsRowList;
