import React from 'react';
import cx from 'classnames';

import Button from '../../atoms/Button';
import Divider from '../../atoms/Divider';
import Text from '../../atoms/Text';
import MenuHeader from '../../molecules/MenuHeader';
import VariableLayerItemList from '../../molecules/VariableLayerItemList';

import styles from './SetVariableMenu.module.scss';
import usePresenter from './SetVariableMenu.presenter';
import getProps from './SetVariableMenu.props';
import { SetVariableMenuCombinedProps } from './types';

const SetVariableMenu: React.FC<SetVariableMenuCombinedProps> = (props) => {
  const {
    style,
    state,
    className,
    classes,
    menuHeader,
    divider,
    variableLayerItemList,
    secondaryButton,
    primaryButton,
    text,
    button,
  } = usePresenter(props);

  const variantName = `${style}${state}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`setVariableMenu${variantName}`];

  let componentView;

  switch (variantName) {
    case 'DefaultDefault': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <MenuHeader
            className={cx(styles.menuHeader, classes?.menuHeader)}
            {...internalProps.menuHeader}
            {...menuHeader}/>
          <Divider
            className={cx(styles.divider, classes?.divider)}
            {...internalProps.divider}
            {...divider}/>
          <div className={cx(styles.menuContent, classes?.menuContent)}>
            <div className={cx(styles.variableLayerContainer, classes?.variableLayerContainer)}>
              <VariableLayerItemList
                className={cx(styles.variableLayerItemList, classes?.variableLayerItemList)}
                {...internalProps.variableLayerItemList}
                {...variableLayerItemList}/>
            </div>
            <div className={cx(styles.buttonContent, classes?.buttonContent)}>
              <div className={cx(styles.buttonGroup, classes?.buttonGroup)}>
                <Button
                  className={cx(styles.secondaryButton, classes?.secondaryButton)}
                  {...internalProps.secondaryButton}
                  {...secondaryButton}/>
                <Button
                  className={cx(styles.primaryButton, classes?.primaryButton)}
                  {...internalProps.primaryButton}
                  {...primaryButton}/>
              </div>
            </div>
          </div>
        </div>
      );
      break;
    }
    case 'DefaultEmpty': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <MenuHeader
            className={cx(styles.menuHeader, classes?.menuHeader)}
            {...internalProps.menuHeader}
            {...menuHeader}/>
          <Divider
            className={cx(styles.divider, classes?.divider)}
            {...internalProps.divider}
            {...divider}/>
          <div className={cx(styles.menuContent, classes?.menuContent)}>
            <div className={cx(styles.variableLayerContainer, classes?.variableLayerContainer)}>
              <Text
                className={cx(styles.text, classes?.text)}
                {...internalProps.text}
                {...text}/>
            </div>
            <div className={cx(styles.buttonContent, classes?.buttonContent)}>
              <div className={cx(styles.buttonGroup, classes?.buttonGroup)}>
                <Button
                  className={cx(styles.secondaryButton, classes?.secondaryButton)}
                  {...internalProps.secondaryButton}
                  {...secondaryButton}/>
                <Button
                  className={cx(styles.primaryButton, classes?.primaryButton)}
                  {...internalProps.primaryButton}
                  {...primaryButton}/>
              </div>
            </div>
          </div>
        </div>
      );
      break;
    }
    case 'RemoveableDefault': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <MenuHeader
            className={cx(styles.menuHeader, classes?.menuHeader)}
            {...internalProps.menuHeader}
            {...menuHeader}/>
          <Divider
            className={cx(styles.divider, classes?.divider)}
            {...internalProps.divider}
            {...divider}/>
          <div className={cx(styles.menuContent, classes?.menuContent)}>
            <div className={cx(styles.variableLayerContainer, classes?.variableLayerContainer)}>
              <VariableLayerItemList
                className={cx(styles.variableLayerItemList, classes?.variableLayerItemList)}
                {...internalProps.variableLayerItemList}
                {...variableLayerItemList}/>
            </div>
            <div className={cx(styles.buttonContent, classes?.buttonContent)}>
              <Button
                className={cx(styles.button, classes?.button)}
                {...internalProps.button}
                {...button}/>
              <div className={cx(styles.buttonGroup, classes?.buttonGroup)}>
                <Button
                  className={cx(styles.secondaryButton, classes?.secondaryButton)}
                  {...internalProps.secondaryButton}
                  {...secondaryButton}/>
                <Button
                  className={cx(styles.primaryButton, classes?.primaryButton)}
                  {...internalProps.primaryButton}
                  {...primaryButton}/>
              </div>
            </div>
          </div>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default SetVariableMenu;
