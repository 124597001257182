import { Constants } from '../../../../constants';
import BodyElement from '../../elements/BodyElement';
import ButtonContainerElement from '../../elements/ButtonContainerElement';
import ContainerElement from '../../elements/ContainerElement';
import ImageElement from '../../elements/ImageElement';
import TextElement from '../../elements/TextElement';
import type { ElementViews } from './types';

export const ELEMENT_VIEWS: ElementViews = {
  [Constants.ELEMENT_TYPES.BODY]: BodyElement,
  [Constants.ELEMENT_TYPES.CONTAINER]: ContainerElement,
  [Constants.ELEMENT_TYPES.BUTTON_CONTAINER]: ButtonContainerElement,
  [Constants.ELEMENT_TYPES.TEXT]: TextElement,
  [Constants.ELEMENT_TYPES.IMAGE]: ImageElement,
};
