import { diff } from 'deep-object-diff';
import { ApiResponsePropMetadata, ApiResponseSchema } from '../store/ApiGroupStore/types';

export const nameToSlug = (name: string): string => name
  .trim() // trim
  .toLocaleLowerCase() // lowercase
  .replace(/[^a-z0-9 -]/g, '') // remove invalid chars;
  .replace(/\s+/g, '-') // collapse whitespace and replace by -
  .replace(/-+/g, '-'); // collapse dashes

export const matchesObjectSubset = <TType extends Record<string, unknown>>(fullSet: TType, subset: Partial<TType>): boolean => {
  const keys = Object.keys(subset);
  // eslint-disable-next-line no-restricted-syntax
  for (const key of keys) {
    if (fullSet[key] !== subset[key]) {
      return false;
    }
  }
  return true;
};

export const matchesObject = <TType extends Record<string, unknown>>(object1: TType | undefined, object2: TType | undefined): boolean => {
  if (!object1 || !object2) {
    return object1 === object2;
  }
  const delta = diff(object1, object2);
  return Object.keys(delta).length === 0;
};

export const excludePropTypeIgnore = (apiResponseSchema?: ApiResponseSchema): ApiResponseSchema | undefined => {
  if (!apiResponseSchema?.length) {
    return apiResponseSchema;
  }

  // Get a list of response paths for all ignored properties
  const ignoredPropResponsePaths: string[] = apiResponseSchema
    .filter(({ propType }: ApiResponsePropMetadata) => propType === 'ignore')
    .map(({ responsePath }: ApiResponsePropMetadata) => responsePath);

  // If there are no ignored properties then return the original API response schema as-is
  if (!ignoredPropResponsePaths.length) {
    return apiResponseSchema;
  }

  // Exclude all ignored properties and all their nested sub-properties
  const filteredApiResponseSchema: ApiResponseSchema = apiResponseSchema.filter(
    ({ responsePath }: ApiResponsePropMetadata) => ignoredPropResponsePaths.every((ignoredPropResponsePath: string) => responsePath !== ignoredPropResponsePath && !responsePath.startsWith(`${ignoredPropResponsePath}.`)),
  );

  return filteredApiResponseSchema;
};
