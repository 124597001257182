import {
  useCallback, useContext, useEffect, useMemo, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, matchPath, useLocation, useParams } from 'react-router';
import { Constants, NEW_GROUP_PLACEHOLDER_UUID } from '../../../constants';
import { useTabItemsState } from '../../../hooks';
import { ROUTES } from '../../../lib/constants';
import { GroupPageTabEnum } from '../../../lib/types';
import type { ModalPosition } from '../../../modules/common/ModalWrapper/types';
import { getCustomPositionForElementLeft, getCustomPositionForElementRight } from '../../../modules/common/ModalWrapper/utils';
import { NavigateWrapperContext } from '../../../modules/navigation/context/NavigateWrapperContext';
import { useNavigateWrapper } from '../../../modules/navigation/hooks/useNavigateWrapper';
import type { ApiGroup, KeyValuePair } from '../../../store/ApiGroupStore/types';
import { StoreContext } from '../../../store/StoreProvider';
import { isValidText } from '../../../utils/inputValidations';
import { typedDeepMerge } from '../../../utils/objectUtils';
import { MethodChipTypeEnum } from '../../atoms/MethodChip/types';
import type { EndpointCardValueProps } from '../../molecules/EndpointCard';
import type { TextCardCombinedProps } from '../../molecules/TextCard/types';
import styles from './GroupPage.module.scss';
import { GroupPageCombinedProps, GroupPageStateEnum, GroupPageTypeEnum } from './types';
import {
  capitalizeOnlyFirstLetter, getEmptyGroup, getEndpointUrl, getKeyValuePairsErrors, getTrimmedKeyValuePairs, OFFSET_LEFT_POSITION,
  OFFSET_TOP_POSITION,
} from './utils';

const usePresenter = (
  props: GroupPageCombinedProps,
): GroupPageCombinedProps => {
  const { t } = useTranslation();
  const { proceed, navigate, setBlocker, removeBlocker } = useNavigateWrapper();
  const location = useLocation();
  const { groupUuid } = useParams();
  const [newGroupName, setNewGroupName] = useState('');
  const [newGroupUrl, setNewGroupUrl] = useState('');
  const { apiGroupStore } = useContext(StoreContext);
  const { isNavigationBlocked } = useContext(NavigateWrapperContext);
  const [deleteModalState, setDeleteModalState] = useState(false);
  const [unsavedChangesModalState, setUnsavedChangesModalState] = useState(false);
  const [keyValuePairs, setKeyValuePairs] = useState<KeyValuePair[]>([]);
  const [isAnyKeyValuePairUpdated, setIsAnyKeyValuePairUpdated] = useState<boolean>(false);
  const [isErrorStateVisible, setIsErrorStateVisible] = useState(false);
  const itemRef = useRef<HTMLDivElement[]>([]);
  const currentRef = useRef<HTMLDivElement | null>(null);

  const hasKeyValuePairsError = useMemo<boolean>(() => {
    return getKeyValuePairsErrors(keyValuePairs);
  }, [keyValuePairs]);

  useEffect(() => {
    if (!hasKeyValuePairsError && isErrorStateVisible) {
      setIsErrorStateVisible(false);
    }
  }, [hasKeyValuePairsError, isErrorStateVisible]);

  const {
    getApiGroups, setApiGroup, removeNewlyAddedApiGroup,
    createApiGroup, selectedGroup, setSelectedApiGroup,
    deleteApiGroup, updateApiGroup, setSelectedEndpoint,
  } = apiGroupStore;
  const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);
  const tabsData = [{
    tab: GroupPageTabEnum.ENDPOINTS,
    label: t('groupPage.emptyEndpointsBlock.endpointsTabItem'),
    route: ROUTES.groupDetailRoutes.base,
  },
  {
    tab: GroupPageTabEnum.HEADERS,
    label: t('groupPage.emptyEndpointsBlock.headersTabItem'),
    route: ROUTES.groupDetailRoutes.headers,
  },
  {
    tab: GroupPageTabEnum.PARAMETERS,
    label: t('groupPage.emptyEndpointsBlock.parametersTabItem'),
    route: ROUTES.groupDetailRoutes.parameters,
  }];

  const onTabClick = (index: number) => {
    const tabData = tabsData[index];
    if (selectedGroup) {
      navigate(generatePath(`${ROUTES.groupDetails}${tabData.route}`, {
        groupUuid: selectedGroup.uuid,
      }));
    }
  };

  const { selectedTab, tabItemList, setSelectedTab } = useTabItemsState<GroupPageTabEnum>({
    tabsData,
    defaultTab: GroupPageTabEnum.ENDPOINTS,
    onClick: onTabClick,
  });

  // To select a tab based on pathname
  useEffect(() => {
    const { pathname } = location;
    if (matchPath(`${ROUTES.groupDetails}${ROUTES.groupDetailRoutes.headers}`, pathname)) {
      setSelectedTab(GroupPageTabEnum.HEADERS);
      return;
    }
    if (matchPath(`${ROUTES.groupDetails}${ROUTES.groupDetailRoutes.parameters}`, pathname)) {
      setSelectedTab(GroupPageTabEnum.PARAMETERS);
      return;
    }
    if (matchPath(ROUTES.groupDetails, pathname)) {
      setSelectedTab(GroupPageTabEnum.ENDPOINTS);
    }

    if (groupUuid && selectedGroup?.uuid !== groupUuid) {
      setSelectedApiGroup(groupUuid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, groupUuid, selectedGroup?.uuid]);

  const apiGroupPageContainerRef = useRef<HTMLDivElement>(null);
  const closeContextMenu = useCallback(() => setIsContextMenuOpen(false), []);
  const [contextMenuPosition, setContextMenuPosition] = useState<ModalPosition | undefined>();

  const openContextMenu = useCallback((index?: number) => {
    if (index !== undefined) {
      currentRef.current = itemRef.current[index];
      setContextMenuPosition(getCustomPositionForElementRight(currentRef, 20));
      currentRef.current.scrollIntoView({ block: 'nearest' });
    } else {
      setContextMenuPosition(getCustomPositionForElementLeft(apiGroupPageContainerRef, OFFSET_LEFT_POSITION, OFFSET_TOP_POSITION));
    }
    setIsContextMenuOpen(true);
  }, []);

  useEffect(() => {
    if (isNavigationBlocked) {
      if (hasKeyValuePairsError) {
        setIsErrorStateVisible(true);
        removeBlocker();
      } else {
        setIsErrorStateVisible(false);
        setUnsavedChangesModalState(true);
      }
    }
  }, [isNavigationBlocked, hasKeyValuePairsError, removeBlocker]);

  useEffect(() => {
    if (isAnyKeyValuePairUpdated) {
      setBlocker();
    } else {
      removeBlocker();
    }
  }, [isAnyKeyValuePairUpdated, setBlocker, removeBlocker]);

  useEffect(() => {
    if (selectedTab === GroupPageTabEnum.HEADERS) {
      setKeyValuePairs([...selectedGroup?.headers || []]);
    }

    if (selectedTab === GroupPageTabEnum.PARAMETERS) {
      setKeyValuePairs([...selectedGroup?.parameters || []]);
    }
  }, [selectedGroup?.headers, selectedGroup?.parameters, selectedTab]);

  const isGroupFormDirty = useMemo(() => {
    return !!selectedGroup && (selectedGroup.name !== newGroupName || selectedGroup.baseUrl !== newGroupUrl);
  }, [selectedGroup, newGroupName, newGroupUrl]);

  const getGroups = (): TextCardCombinedProps[] => {
    return getApiGroups()?.map((group: ApiGroup, index) => {
      // set initial api group from the list
      if (index === 0 && !selectedGroup && group.uuid !== NEW_GROUP_PLACEHOLDER_UUID) {
        setSelectedApiGroup(group.uuid);
        navigate(generatePath(ROUTES.groupDetails, { groupUuid: group.uuid }));
      }
      const isGroupSelected = group.uuid === selectedGroup?.uuid;
      return {
        states: (isContextMenuOpen && index === 0 && !newGroupName && !newGroupUrl) || isGroupSelected ? 'Selected' : 'Default',
        label: {
          value: group?.name,
        },
        ref: (el: HTMLDivElement) => { itemRef.current.splice(index, 1, el); },
        onClick: () => {
          if (!isNavigationBlocked && !isAnyKeyValuePairUpdated) {
            void setSelectedApiGroup(group.uuid);
          }
          closeContextMenu();
          navigate(generatePath(ROUTES.groupDetails, { groupUuid: group.uuid }));
        },
        text: {
          value: t('groupPage.groupsPanel.endpointLabel', {
            count: group?.endpoints?.length || 0,
          }),
        },
        button: {
          icon: {
            asset: 'MoreVert',
            colour: 'NeturalHoverSelected',
          },
          onClick: (event?: React.MouseEvent<HTMLElement>) => {
            event?.stopPropagation();
            void setSelectedApiGroup(group.uuid);
            setNewGroupName(group.name);
            setNewGroupUrl(group.baseUrl);
            openContextMenu(index);
            navigate(generatePath(ROUTES.groupDetails, { groupUuid: group.uuid }));
          },
        },
      };
    });
  };

  const getGroupState = (): GroupPageStateEnum => {
    return selectedGroup ? 'Default' : 'Empty';
  };

  const getTabsButtonText = (): string => {
    let buttonText: string;
    switch (selectedTab) {
      case GroupPageTabEnum.ENDPOINTS:
        buttonText = t('groupPage.emptyEndpointsBlock.buttonAddApiEndpoint');
        break;

      case GroupPageTabEnum.HEADERS:
        buttonText = t('groupPage.emptyEndpointsBlock.buttonSaveHeaders');
        break;

      case GroupPageTabEnum.PARAMETERS:
        buttonText = t('groupPage.emptyEndpointsBlock.buttonSaveParameters');
        break;

      default:
        buttonText = '';
        break;
    }

    return buttonText;
  };

  const getGroupType = (): GroupPageTypeEnum => {
    return selectedGroup ? 'Endpoints' : 'Groups';
  };

  const createNewGroup = () => {
    if (!isNavigationBlocked && !isAnyKeyValuePairUpdated) {
      setSelectedApiGroup('');
      setNewGroupName('');
      setNewGroupUrl('');
      openContextMenu();
      setSelectedTab(GroupPageTabEnum.ENDPOINTS);
      setApiGroup(getEmptyGroup(t('groupPage.groupsPanel.emptyGroupNameLabel')));
    }
    navigate(`${ROUTES.apiConnector}`);
  };

  const removeNewGroup = () => {
    closeContextMenu();
    removeNewlyAddedApiGroup();
  };

  const handleCloseConfigureMenu = () => {
    if (selectedGroup && isGroupFormDirty) {
      setUnsavedChangesModalState(true);
      return;
    }

    removeNewGroup();
  };

  const closeUnsavedModalWithContextualMenu = () => {
    closeContextMenu();
    setUnsavedChangesModalState(false);
  };

  const navigateToEndpoints = () => {
    if (selectedGroup) {
      navigate(generatePath(`${ROUTES.endpoints}`, {
        groupUuid: selectedGroup.uuid,
      }));
    }
  };

  const navigateToEndpointDetails = async (endpointUuid: string) => {
    if (selectedGroup) {
      await setSelectedEndpoint(groupUuid, endpointUuid);
      navigate(generatePath(`${ROUTES.endpointDetails}`, {
        groupUuid: selectedGroup.uuid,
        endpointUuid,
      }));
    }
  };

  const saveButtonClick = () => {
    if (hasKeyValuePairsError) {
      setIsErrorStateVisible(true);
      return;
    }
    if (selectedTab === GroupPageTabEnum.ENDPOINTS) {
      navigateToEndpoints();
      return;
    }

    if (selectedGroup) {
      if (selectedTab === GroupPageTabEnum.HEADERS) {
        void updateApiGroup(selectedGroup.uuid, {
          ...selectedGroup,
          // eslint-disable-next-line @typescript-eslint/no-shadow
          headers: getTrimmedKeyValuePairs(keyValuePairs),
        });
        setIsAnyKeyValuePairUpdated(false);
      }

      if (selectedTab === GroupPageTabEnum.PARAMETERS) {
        void updateApiGroup(selectedGroup.uuid, {
          ...selectedGroup,
          // eslint-disable-next-line @typescript-eslint/no-shadow
          parameters: getTrimmedKeyValuePairs(keyValuePairs),
        });
        setIsAnyKeyValuePairUpdated(false);
      }
    }
  };

  const [endpointCardList, setEndpointCardList] = useState<EndpointCardValueProps[]>([]);
  useEffect(() => {
    if (selectedGroup) {
      const getEndpointCardList = selectedGroup.endpoints ? selectedGroup.endpoints.map((endpoint) => ({
        onClick: () => navigateToEndpointDetails(endpoint.uuid),
        label: {
          value: endpoint.name,
        },
        path: {
          value: getEndpointUrl(selectedGroup.baseUrl, endpoint.path),
        },
        methodChip: {
          type: capitalizeOnlyFirstLetter(endpoint.httpMethod) as MethodChipTypeEnum,
          text: {
            value: endpoint.httpMethod,
          },
        },
      })) : [];
      setEndpointCardList(getEndpointCardList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGroup]);

  return {
    ...typedDeepMerge(props, {
      state: getGroupState(),
      type: getGroupType(),
      emptyGroupBlock: {
        icon: {
          asset: selectedGroup ? 'ApiEndpoint' : 'ApiGroup',
        },
        label: {
          value: t('groupPage.emptyGroupBlock.label'),
        },
        instructions: {
          value: selectedGroup ? '' : t('groupPage.emptyGroupBlock.instructions'),
        },
        button: {
          disabled: !!isContextMenuOpen,
          text: {
            value: t('groupPage.emptyGroupBlock.buttonText'),
          },
          onClick: createNewGroup,
        },
      },
      groupsPanel: {
        sectionLabel: {
          text: {
            value: t('groupPage.groupsPanel.sectionLabel'),
          },
        },
        button: {
          disabled: !!isContextMenuOpen,
          text: {
            value: t('groupPage.groupsPanel.buttonText'),
          },
          onClick: createNewGroup,
        },
        textCardList: {
          textCards: getGroups(),
        },
      },
      configureGroupMenu: {
        show: isContextMenuOpen,
        onHide: handleCloseConfigureMenu,
        customPosition: contextMenuPosition,
        modalProps: {
          state: selectedGroup === undefined ? 'Default' : 'Removeable',
          menuHeader: {
            text: {
              value: t('groupPage.groupContextMenu.headerText'),
            },
            button: {
              icon: {
                asset: 'Close',
              },
              onClick: handleCloseConfigureMenu,
            },
          },
          label: {
            text: {
              value: t('groupPage.groupContextMenu.name'),
            },
          },
          label1: {
            text: {
              value: t('groupPage.groupContextMenu.baseUrl'),
            },
          },
          secondaryButton: {
            text: {
              value: t('groupPage.groupContextMenu.secondaryButtonText'),
            },
            onClick: handleCloseConfigureMenu,
          },
          primaryButton: {
            disabled: !(newGroupName && newGroupUrl),
            text: {
              value: t('groupPage.groupContextMenu.primaryButtonText'),
            },
            onClick: () => {
              if (selectedGroup) {
                void updateApiGroup(selectedGroup.uuid, {
                  name: newGroupName,
                  baseUrl: newGroupUrl,
                });
              } else {
                void createApiGroup({ name: newGroupName, baseUrl: newGroupUrl });
              }
              closeContextMenu();
            },
          },
          inputField: {
            onValidateText: (text: string) => isValidText(text, [
              Constants.REGEX.SEQUENTIAL_BLANK_SPACES,
              Constants.REGEX.STRINGS_LENGTH_MORE_THAN_50_CHARACTERS,
              Constants.REGEX.WHITE_SPACES_ONLY,
            ]),
            onTextChanged(text: string) {
              setNewGroupName(text);
            },
            textValue: newGroupName,
          },
          inputField1: {
            onValidateText: (text: string) => isValidText(text, [
              Constants.REGEX.GROUP_URL_SPACE,
              Constants.REGEX.GROUP_URL_DOUBLE_DOT,
              Constants.REGEX.DOUBLE_SLASHES_NOT_FOLLOWED_BY_COLON,
            ]),
            onTextChanged(text: string) {
              setNewGroupUrl(text);
            },
            textValue: newGroupUrl,
          },
          button: {
            text: {
              value: t('groupPage.groupContextMenu.deleteButtonText'),
            },
            onClick: () => {
              setDeleteModalState(true);
            },
          },
        },
      },
      deleteGroupMenu: {
        show: deleteModalState,
        backdropClassName: styles.backdrop,
        modalProps: {
          state: 'Destructive',
          heading: {
            value: t('groupPage.groupDeleteMenu.heading'),
          },
          primaryButton: {
            icon: {
              asset: 'Close',
            },
            onClick: () => {
              setDeleteModalState(false);
            },
          },
          text: {
            value: t('groupPage.groupDeleteMenu.description'),
          },
          button: {
            text: {
              value: t('groupPage.groupDeleteMenu.primaryButtonText'),
            },
            onClick: () => {
              void deleteApiGroup(selectedGroup?.uuid || '');
              setDeleteModalState(false);
              closeContextMenu();
            },
          },
          secondaryButton: {
            text: {
              value: t('groupPage.groupDeleteMenu.secondaryButtonText'),
            },
            onClick: () => {
              setDeleteModalState(false);
            },
          },
        },
      },
      unsavedChangesModal: {
        show: unsavedChangesModalState,
        backdropClassName: styles.backdrop,
        modalProps: {
          state: 'Default',
          heading: {
            value: t('unsavedChangesModal.heading'),
          },
          primaryButton: {
            icon: {
              asset: 'Close',
            },
            onClick: () => {
              if (isNavigationBlocked) {
                removeBlocker();
              }
              setUnsavedChangesModalState(false);
            },
          },
          text: {
            value: t('unsavedChangesModal.description'),
          },
          primaryButton1: {
            text: {
              value: t('unsavedChangesModal.saveButton'),
            },
            onClick: () => {
              if (isNavigationBlocked) {
                saveButtonClick();
                setUnsavedChangesModalState(false);
                proceed();
              } else {
                void updateApiGroup(selectedGroup?.uuid || '', {
                  name: newGroupName,
                  baseUrl: newGroupUrl,
                });
                closeUnsavedModalWithContextualMenu();
              }
            },
          },
          secondaryButton: {
            text: {
              value: t('unsavedChangesModal.exitWithoutSavingButton'),
            },
            onClick: () => {
              closeUnsavedModalWithContextualMenu();
              if (isNavigationBlocked) {
                setIsAnyKeyValuePairUpdated(false);
                proceed();
              }
            },
          },
        },
      },
      endpointCardBlock: {
        label: {
          value: t('groupPage.endpointCardBlock.empty.label'),
        },
        instructions: {
          value: t('groupPage.endpointCardBlock.empty.instructions'),
        },
        button: {
          text: {
            value: t('groupPage.endpointCardBlock.empty.buttonText'),
          },
          onClick: navigateToEndpoints,
        },
        endpointCardList: {
          endpointCards: endpointCardList,
        },
        state: endpointCardList.length ? 'Filled' : 'Empty',
      },
      groupName: {
        value: selectedGroup?.name,
      },
      baseURL: {
        value: selectedGroup?.baseUrl,
      },
      tabItemList: {
        tabItems: tabItemList,
      },
      button: {
        disabled: selectedTab !== GroupPageTabEnum.ENDPOINTS && !isAnyKeyValuePairUpdated,
        text: {
          value: getTabsButtonText(),
        },
        onClick: () => saveButtonClick(),
      },
      headerBlock: {
        selectedTab,
        state: hasKeyValuePairsError && isErrorStateVisible ? 'Error' : 'Default',
        keyValueTable: {
          keyValuePairs,
          hasKeyValueError: hasKeyValuePairsError && isErrorStateVisible,
          onKeyValuePairsChange: (updatedKeyValuePairs: KeyValuePair[]) => {
            setIsAnyKeyValuePairUpdated(true);
            setKeyValuePairs(updatedKeyValuePairs);
          },
        },
      },
    }),
    apiGroupPageContainerRef,
  };
};

export default usePresenter;
