import React from 'react';
import cx from 'classnames';

import Button from '../../atoms/Button';
import Divider from '../../atoms/Divider';
import DropdownButton from '../../atoms/DropdownButton';
import InputField from '../../atoms/InputField';
import Label from '../../atoms/Label';
import AccordionHeader from '../../molecules/AccordionHeader';
import MenuHeader from '../../molecules/MenuHeader';
import ToggleItemList from '../../molecules/ToggleItemList';

import styles from './ConfigureNavigationVariableMenu.module.scss';
import usePresenter from './ConfigureNavigationVariableMenu.presenter';
import getProps from './ConfigureNavigationVariableMenu.props';
import { ConfigureNavigationVariableMenuCombinedProps } from './types';

const ConfigureNavigationVariableMenu: React.FC<ConfigureNavigationVariableMenuCombinedProps> = (props) => {
  const {
    state,
    style,
    className,
    classes,
    menuHeader,
    divider,
    label,
    inputField,
    label1,
    dropdownButton,
    accordionHeader,
    button,
    button1,
    button2,
    label2,
    toggleItemList,
    label3,
    inputField1,
    isPathParam,
  } = usePresenter(props);

  const variantName = `${state}${style}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`configureNavigationVariableMenu${variantName}`];

  let componentView;

  // MANUAL OVERRIDE start
  const aliasContainer = isPathParam ? (
    <div className={cx(styles.aliasContent, classes?.aliasContent)}>
      <Label
        className={cx(styles.label3, classes?.label3)}
        {...internalProps.label3}
        {...label3}/>
      <InputField
        className={cx(styles.inputField1, classes?.inputField1)}
        {...internalProps.inputField1}
        {...inputField1}/>
    </div>
  ) : undefined;
  // MANUAL OVERRIDE end

  switch (variantName) {
    case 'CollapsedDefault': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <MenuHeader
            className={cx(styles.menuHeader, classes?.menuHeader)}
            {...internalProps.menuHeader}
            {...menuHeader}/>
          <Divider
            className={cx(styles.divider, classes?.divider)}
            {...internalProps.divider}
            {...divider}/>
          <div className={cx(styles.menuContent, classes?.menuContent)}>
            <div className={cx(styles.nameContent, classes?.nameContent)}>
              <Label
                className={cx(styles.label, classes?.label)}
                {...internalProps.label}
                {...label}/>
              <InputField
                className={cx(styles.inputField, classes?.inputField)}
                {...internalProps.inputField}
                {...inputField}/>
            </div>
            <div className={cx(styles.typeContent, classes?.typeContent)}>
              <Label
                className={cx(styles.label1, classes?.label1)}
                {...internalProps.label1}
                {...label1}/>
              <DropdownButton
                className={cx(styles.dropdownButton, classes?.dropdownButton)}
                {...internalProps.dropdownButton}
                {...dropdownButton}/>
            </div>
            <AccordionHeader
              className={cx(styles.accordionHeader, classes?.accordionHeader)}
              {...internalProps.accordionHeader}
              {...accordionHeader}/>
            <div className={cx(styles.buttonContent, classes?.buttonContent)}>
              <div className={cx(styles.buttonGroup, classes?.buttonGroup)}>
                <Button
                  className={cx(styles.button, classes?.button)}
                  {...internalProps.button}
                  {...button}/>
                <Button
                  className={cx(styles.button1, classes?.button1)}
                  {...internalProps.button1}
                  {...button1}/>
              </div>
            </div>
          </div>
        </div>
      );
      break;
    }
    case 'CollapsedRemoveable': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <MenuHeader
            className={cx(styles.menuHeader, classes?.menuHeader)}
            {...internalProps.menuHeader}
            {...menuHeader}/>
          <Divider
            className={cx(styles.divider, classes?.divider)}
            {...internalProps.divider}
            {...divider}/>
          <div className={cx(styles.menuContent, classes?.menuContent)}>
            <div className={cx(styles.nameContent, classes?.nameContent)}>
              <Label
                className={cx(styles.label, classes?.label)}
                {...internalProps.label}
                {...label}/>
              <InputField
                className={cx(styles.inputField, classes?.inputField)}
                {...internalProps.inputField}
                {...inputField}/>
            </div>
            <div className={cx(styles.typeContent, classes?.typeContent)}>
              <Label
                className={cx(styles.label1, classes?.label1)}
                {...internalProps.label1}
                {...label1}/>
              <DropdownButton
                className={cx(styles.dropdownButton, classes?.dropdownButton)}
                {...internalProps.dropdownButton}
                {...dropdownButton}/>
            </div>
            <AccordionHeader
              className={cx(styles.accordionHeader, classes?.accordionHeader)}
              {...internalProps.accordionHeader}
              {...accordionHeader}/>
            <div className={cx(styles.buttonContent, classes?.buttonContent)}>
              <Button
                className={cx(styles.button, classes?.button)}
                {...internalProps.button}
                {...button}/>
              <div className={cx(styles.buttonGroup, classes?.buttonGroup)}>
                <Button
                  className={cx(styles.button1, classes?.button1)}
                  {...internalProps.button1}
                  {...button1}/>
                <Button
                  className={cx(styles.button2, classes?.button2)}
                  {...internalProps.button2}
                  {...button2}/>
              </div>
            </div>
          </div>
        </div>
      );
      break;
    }
    case 'ExpandedDefault': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <MenuHeader
            className={cx(styles.menuHeader, classes?.menuHeader)}
            {...internalProps.menuHeader}
            {...menuHeader}/>
          <Divider
            className={cx(styles.divider, classes?.divider)}
            {...internalProps.divider}
            {...divider}/>
          <div className={cx(styles.menuContent, classes?.menuContent)}>
            <div className={cx(styles.nameContent, classes?.nameContent)}>
              <Label
                className={cx(styles.label, classes?.label)}
                {...internalProps.label}
                {...label}/>
              <InputField
                className={cx(styles.inputField, classes?.inputField)}
                {...internalProps.inputField}
                {...inputField}/>
            </div>
            <div className={cx(styles.typeContent, classes?.typeContent)}>
              <Label
                className={cx(styles.label1, classes?.label1)}
                {...internalProps.label1}
                {...label1}/>
              <DropdownButton
                className={cx(styles.dropdownButton, classes?.dropdownButton)}
                {...internalProps.dropdownButton}
                {...dropdownButton}/>
            </div>
            <AccordionHeader
              className={cx(styles.accordionHeader, classes?.accordionHeader)}
              {...internalProps.accordionHeader}
              {...accordionHeader}/>
            <div className={cx(styles.pathParameterContent, classes?.pathParameterContent)}>
              <Label
                className={cx(styles.label2, classes?.label2)}
                {...internalProps.label2}
                {...label2}/>
              <ToggleItemList
                className={cx(styles.toggleItemList, classes?.toggleItemList)}
                {...internalProps.toggleItemList}
                {...toggleItemList}/>
            </div>
            {/* MANUAL OVERRIDE */}
            {aliasContainer}
            <div className={cx(styles.buttonContent, classes?.buttonContent)}>
              <div className={cx(styles.buttonGroup, classes?.buttonGroup)}>
                <Button
                  className={cx(styles.button, classes?.button)}
                  {...internalProps.button}
                  {...button}/>
                <Button
                  className={cx(styles.button1, classes?.button1)}
                  {...internalProps.button1}
                  {...button1}/>
              </div>
            </div>
          </div>
        </div>
      );
      break;
    }
    case 'ExpandedRemoveable': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <MenuHeader
            className={cx(styles.menuHeader, classes?.menuHeader)}
            {...internalProps.menuHeader}
            {...menuHeader}/>
          <Divider
            className={cx(styles.divider, classes?.divider)}
            {...internalProps.divider}
            {...divider}/>
          <div className={cx(styles.menuContent, classes?.menuContent)}>
            <div className={cx(styles.nameContent, classes?.nameContent)}>
              <Label
                className={cx(styles.label, classes?.label)}
                {...internalProps.label}
                {...label}/>
              <InputField
                className={cx(styles.inputField, classes?.inputField)}
                {...internalProps.inputField}
                {...inputField}/>
            </div>
            <div className={cx(styles.typeContent, classes?.typeContent)}>
              <Label
                className={cx(styles.label1, classes?.label1)}
                {...internalProps.label1}
                {...label1}/>
              <DropdownButton
                className={cx(styles.dropdownButton, classes?.dropdownButton)}
                {...internalProps.dropdownButton}
                {...dropdownButton}/>
            </div>
            <AccordionHeader
              className={cx(styles.accordionHeader, classes?.accordionHeader)}
              {...internalProps.accordionHeader}
              {...accordionHeader}/>
            <div className={cx(styles.pathParameterContent, classes?.pathParameterContent)}>
              <Label
                className={cx(styles.label2, classes?.label2)}
                {...internalProps.label2}
                {...label2}/>
              <ToggleItemList
                className={cx(styles.toggleItemList, classes?.toggleItemList)}
                {...internalProps.toggleItemList}
                {...toggleItemList}/>
            </div>
            {/* MANUAL OVERRIDE */}
            {aliasContainer}
            <div className={cx(styles.buttonContent, classes?.buttonContent)}>
              <Button
                className={cx(styles.button, classes?.button)}
                {...internalProps.button}
                {...button}/>
              <div className={cx(styles.buttonGroup, classes?.buttonGroup)}>
                <Button
                  className={cx(styles.button1, classes?.button1)}
                  {...internalProps.button1}
                  {...button1}/>
                <Button
                  className={cx(styles.button2, classes?.button2)}
                  {...internalProps.button2}
                  {...button2}/>
              </div>
            </div>
          </div>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default ConfigureNavigationVariableMenu;
