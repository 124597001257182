import React from 'react';
import cx from 'classnames';

import { Handle, Position } from 'reactflow';
import Button from '../Button';
import Divider from '../Divider';

import styles from './AddCardLine.module.scss';
import usePresenter from './AddCardLine.presenter';
import getProps from './AddCardLine.props';
import { AddCardLineCombinedProps } from './types';

const AddCardLine: React.FC<AddCardLineCombinedProps> = (props) => {
  const {
    data: {
      className,
      classes,
      divider,
      button,
      divider1,
    },
  } = usePresenter(props);

  const variantName = '';
  const internalProps = getProps(variantName);
  const currentStyle = styles[`addCardLine${variantName}`];

  let componentView;

  switch (variantName) {
    case '': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <Handle
            className={styles.hiddenHandler}
            type="source"
            position={Position.Right}
            isConnectable={false}
          />
          <Handle
            className={styles.hiddenHandler}
            type="target"
            position={Position.Left}
            isConnectable={false}
          />
          {/* MANUAL OVERRIDE */}
          {/* <Divider
            className={cx(styles.divider, classes?.divider)}
            {...internalProps.divider}
            {...divider}/> */}
          <Button
            className={cx(styles.button, classes?.button)}
            {...internalProps.button}
            {...button}/>
          {/* MANUAL OVERRIDE */}
          {/* <Divider
            className={cx(styles.divider1, classes?.divider1)}
            {...internalProps.divider1}
            {...divider1}/> */}
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default AddCardLine;
