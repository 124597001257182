import React from 'react';
import cx from 'classnames';

import Icon from '../Icon';
import Text from '../Text';

import styles from './DropdownButton.module.scss';
import usePresenter from './DropdownButton.presenter';
import getProps from './DropdownButton.props';
import { DropdownButtonCombinedProps } from './types';

const DropdownButton = React.forwardRef<HTMLButtonElement, DropdownButtonCombinedProps>((props, ref) => { // MANUAL OVERRIDE
  const {
    type,
    style,
    state,
    colour,
    className,
    classes,
    text,
    icon,
    icon1,
    icon2,
    onClick, // MANUAL OVERRIDE
    onBlur, // MANUAL OVERRIDE
    disabled, // MANUAL OVERRIDE
    ...otherProps // MANUAL OVERRIDE
  } = usePresenter(props);

  const variantName = `${type}${style}${state}${colour}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`dropdownButton${variantName}`];

  let componentView;

  switch (variantName) {
    case 'DefaultDefaultCollapsedDefault':
    case 'DefaultDefaultExpandedDefault':
    case 'DefaultHighlightedCollapsedDefault':
    case 'DefaultHighlightedExpandedDefault':
    case 'MethodDefaultCollapsedGreen':
    case 'MethodDefaultCollapsedPink':
    case 'MethodDefaultCollapsedPurple':
    case 'MethodDefaultCollapsedRed':
    case 'MethodDefaultCollapsedYellow':
    case 'DefaultDefaultDisabledDefault':
    case 'DefaultErrorCollapsedDefault':
    case 'DefaultErrorExpandedDefault':
    case 'MethodDefaultExpandedGreen':
    case 'MethodDefaultExpandedPink':
    case 'MethodDefaultExpandedPurple':
    case 'MethodDefaultExpandedRed':
    case 'MethodDefaultExpandedYellow':
    case 'SelectableRowDefaultCollapsedDefault':
    case 'SelectableRowDefaultExpandedDefault':
    case 'SelectableRowRowHoverCollapsedDefault': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <Text
            className={cx(styles.text, classes?.text)}
            {...internalProps.text}
            {...text}/>
          <Icon
            className={cx(styles.icon, classes?.icon)}
            {...internalProps.icon}
            {...icon}/>
        </div>
      );
      break;
    }
    case 'IconTextDefaultCollapsedDefault':
    case 'IconTextDefaultExpandedDefault': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <Icon
            className={cx(styles.icon, classes?.icon)}
            {...internalProps.icon}
            {...icon}/>
          <Text
            className={cx(styles.text, classes?.text)}
            {...internalProps.text}
            {...text}/>
          <Icon
            className={cx(styles.icon1, classes?.icon1)}
            {...internalProps.icon1}
            {...icon1}/>
        </div>
      );
      break;
    }
    case 'MenuIconDefaultCollapsedDefault':
    case 'MenuIconDefaultExpandedDefault': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <Icon
            className={cx(styles.icon, classes?.icon)}
            {...internalProps.icon}
            {...icon}
          />
        </div>
      );
      break;
    }
    case 'IconDefaultCollapsedDefault':
    case 'IconDefaultExpandedDefault':
    case 'IconRowHoverCollapsedDefault': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <Icon
            className={cx(styles.icon1, classes?.icon1)}
            {...internalProps.icon1}
            {...icon1}/>
          <Icon
            className={cx(styles.icon2, classes?.icon2)}
            {...internalProps.icon2}
            {...icon2}/>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return (
    // MANUAL OVERRIDE STARTS
    <button
      className={styles.dropdownButtonRoot}
      disabled={disabled}
      ref={ref}
      onClick={onClick}
      onBlur={onBlur}
      tabIndex={0}
      {...otherProps}
    >
      {componentView}
    </button>
    // MANUAL OVERRIDE ENDS
  );
});

export default DropdownButton;
