import { EmptyStateInfoProps } from '../../atoms/EmptyStateInfo';
import { TextProps } from '../../atoms/Text';
import { VariableCardListProps } from '../../molecules/VariableCardList';

type VariablesContentInternalProps = {
  heading?: TextProps;
  emptyStateInfo?: EmptyStateInfoProps;
  variableCardList?: VariableCardListProps;
};

type VariablesContentPropsValues = {
  'Empty': VariablesContentInternalProps;
  'Filled': VariablesContentInternalProps;
};

const propValues: VariablesContentPropsValues = {
  'Empty': {
    heading: {
      type: 'LabelMedium',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralHoverSelected',
    },
    emptyStateInfo: {
    },
  },
  'Filled': {
    heading: {
      type: 'LabelMedium',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralHoverSelected',
    },
    variableCardList: {
      size: 'Small',
    },
  },
};

const getProps = (type: string): VariablesContentInternalProps => {
  const values: VariablesContentInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
