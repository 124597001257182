import {
  CSSProperties, KeyboardEventHandler, useCallback, useContext, useEffect, useRef, useState,
} from 'react';
import { getTextValue, updateTextValue } from '../../../../store/PageStore/utils';
import { StoreContext } from '../../../../store/StoreProvider';
import { InputPropSource } from '../../../../store/types';
import type { InternalTextElementProps, TextElementProps } from './types';

const usePresenter = (props: TextElementProps): InternalTextElementProps => {
  const { pageStore } = useContext(StoreContext);

  const [inputValue, setInputValue] = useState<string | undefined>(getTextValue(props.element));
  const [inputSource, setInputSource] = useState<InputPropSource | undefined>(props.element.settings.value?.source);

  useEffect(() => {
    setInputValue(getTextValue(props.element));
  }, [props.element, props.element.settings.value?.source]);

  const handleSubmit = useCallback(async () => {
    if (inputSource === 'variable') {
      return;
    }
    await updateTextValue(pageStore, props.element, inputValue);
    pageStore.setEditingElement(undefined);
  }, [inputValue, inputSource, pageStore, props.element]);

  const handleCancel = useCallback(() => {
    setInputValue(getTextValue(props.element));
    pageStore.setEditingElement(undefined);
  }, [pageStore, props.element]);

  const textSpanRef = useRef<HTMLDivElement>(null);
  const textInputRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (props.isElementEdited) {
      textInputRef.current?.focus();
      textInputRef.current?.select();
    } else {
      void handleSubmit();
      textInputRef.current?.blur();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isElementEdited]);

  const spanStyle: CSSProperties = {
    color: props.isElementEdited ? 'transparent' : undefined,
    minHeight: props.style.lineHeight,
    borderTopLeftRadius: props.style.borderRadius || props.style.borderTopLeftRadius,
    borderTopRightRadius: props.style.borderRadius || props.style.borderTopRightRadius,
    borderBottomLeftRadius: props.style.borderRadius || props.style.borderBottomLeftRadius,
    borderBottomRightRadius: props.style.borderRadius || props.style.borderBottomRightRadius,
  };

  const textSpanRect = textSpanRef.current?.getBoundingClientRect();
  const scrollHeight = textInputRef.current?.scrollHeight;
  const inputStyle: CSSProperties = {
    fontFamily: props.style.fontFamily,
    fontWeight: props.style.fontWeight,
    fontSize: props.style.fontSize,
    letterSpacing: props.style.letterSpacing,
    lineHeight: props.style.lineHeight,
    color: props.style.color,
    textAlign: props.style.textAlign,
    textDecoration: props.style.textDecoration,
    fontStyle: props.style.fontStyle,
    width: '100%',
    height: textSpanRect ? `${scrollHeight}px` : undefined,
  };

  const handleClick = useCallback((e?: React.MouseEvent<HTMLElement>) => {
  }, []);

  const handleTextChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (inputSource === 'variable') {
      setInputSource('value');
    }
    setInputValue(e.target.value);
  }, [inputSource]);

  const handleKeydown: KeyboardEventHandler = useCallback((event) => {
    switch (event.key) {
      case 'Enter':
        void handleSubmit();
        // (event.target as HTMLInputElement).blur();
        break;
      case 'Escape':
        handleCancel();
        // (event.target as HTMLInputElement).blur();
        break;
      default:
        break;
    }
  }, [handleSubmit, handleCancel]);

  return {
    elementRef: props.elementRef,
    element: props.element,
    style: props.style,
    isElementEdited: props.isElementEdited,

    spanStyle,
    inputStyle,
    textSpanRef,
    textInputRef,

    textValue: inputValue,
    onClick: handleClick,
    onTextChanged: handleTextChange,
    onKeyDown: handleKeydown,
  };
};

export default usePresenter;
