import { TextProps } from '../Text';

type TextInputInternalProps = {
  text?: TextProps;
};

type TextInputPropsValues = {
  'DefaultExtraLarge': TextInputInternalProps;
  'DefaultMedium': TextInputInternalProps;
  'SelectedExtraLarge': TextInputInternalProps;
  'SelectedMedium': TextInputInternalProps;
};

const propValues: TextInputPropsValues = {
  DefaultExtraLarge: {
    text: {
      type: 'XXL',
      align: 'Left',
      style: 'Semibold600',
      colour: 'NeutralHoverSelected',
    },
  },
  DefaultMedium: {
    text: {
      type: 'LabelMedium',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralDefault',
    },
  },
  SelectedExtraLarge: {
    text: {
      type: 'XXL',
      align: 'Left',
      style: 'Semibold600',
      colour: 'NeutralHoverSelected',
    },
  },
  SelectedMedium: {
    text: {
      type: 'LabelMedium',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralHoverSelected',
    },
  },
};

const getProps = (type: string): TextInputInternalProps => {
  const values: TextInputInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
