import React from 'react';
import cx from 'classnames';
import Modal from 'react-overlays/Modal';
import styles from './ModalWrapper.module.scss';
import usePresenter from './ModalWrapper.presenter';
import type { ModalWrapperProps } from './types';

const ModalWrapper: React.FC<ModalWrapperProps> = (props) => {
  const {
    className,
    content,
    show,
    onHide,
    modalRef,
    renderBackdropComponent,
    cssProperties,
  } = usePresenter(props);

  return (
    <Modal
      show={show}
      onHide={onHide}
      className={cx(styles.modalWrapper, className)}
      style={cssProperties}
      renderBackdrop={renderBackdropComponent}
      // Specifies the element to which the modal is appended. It should usually be the document
      // body for the sake of assistive technologies
      container={document.body}
    >
      <div ref={modalRef}>
        {content}
      </div>
    </Modal>
  );
};

export default ModalWrapper;
