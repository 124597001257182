import cx from 'classnames';
import React, { type PropsWithChildren } from 'react';
import PreviewElementChildrenRenderer from '../../renderers/PreviewElementChildrenRenderer';
import styles from './PreviewBodyElement.module.scss';
import usePresenter from './PreviewBodyElement.presenter';
import type { PreviewBodyElementProps } from './types';

const PreviewBodyElement: React.FC<PropsWithChildren<PreviewBodyElementProps>> = ({ children, ...props }) => {
  const { element, style, className, ...otherProps } = usePresenter(props);

  return (
    <div
      className={cx(styles.previewBodyElement, className)}
      style={style}
      {...otherProps}
    >
      <PreviewElementChildrenRenderer parentElement={element} />
    </div>
  );
};

export default PreviewBodyElement;
