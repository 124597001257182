import { observer } from 'mobx-react-lite';
import withModal from '../../../modules/common/ModalWrapper/context/withModal';
import ConfigureNavigationVariableMenu from '../ConfigureNavigationVariableMenu';
import InternalPageVariablesPanel from './PageVariablesPanel';
import type { PageVariablesPanelProps, PageVariablesPanelValueProps } from './types';

export type { PageVariablesPanelProps, PageVariablesPanelValueProps };
const [PageVariablesPanel, ConfigureNavigationVariableMenuContext] = withModal(observer(InternalPageVariablesPanel), ConfigureNavigationVariableMenu); // MANUAL OVERRIDE
export { ConfigureNavigationVariableMenuContext };
export default PageVariablesPanel;
