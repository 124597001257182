import React from 'react';
import cx from 'classnames';
import { ReactFlowProvider } from 'reactflow';

import { animated } from '@react-spring/web';
import Divider from '../../atoms/Divider';
import LogicBuilderHeader from '../../molecules/LogicBuilderHeader';
import LogicTabList from '../../molecules/LogicTabList';

import styles from './LogicBuilderPanel.module.scss';
import usePresenter from './LogicBuilderPanel.presenter';
import getProps from './LogicBuilderPanel.props';
import { LogicBuilderPanelCombinedProps } from './types';
import ActionContainer from '../../atoms/ActionContainer';
import { Constants } from '../../../constants';

const LogicBuilderPanel: React.FC<LogicBuilderPanelCombinedProps> = (props) => {
  const {
    state,
    className,
    classes,
    divider,
    logicBuilderHeader,
    divider1,
    logicTabList,
    actionContainer,
    height, // MANUAL OVERRIDE
  } = usePresenter(props);

  const variantName = `${state}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`logicBuilderPanel${variantName}`];

  let componentView;
  let logicAreaContainer; // MANUAL OVERRIDE

  switch (variantName) {
    case 'Collapsed': {
      // MANUAL OVERRIDE
      break;
    }
    case 'Expanded': {
      // MANUAL OVERRIDE START
      logicAreaContainer = (
        <animated.div
          className={cx(styles.logicAreaContainer, classes?.logicAreaContainer)}
          style={{ height: height ? height.to((value: number) => {
            return value;
          }) : 0 }}
        >
          <LogicTabList
            className={cx(styles.logicTabList, classes?.logicTabList)}
            {...internalProps.logicTabList}
            {...logicTabList}
          />
          <ReactFlowProvider>
            <ActionContainer
              className={cx(styles.actionContainer, classes?.actionContainer)}
              {...internalProps.actionContainer}
              {...actionContainer}
            />
          </ReactFlowProvider>
        </animated.div>
      );
      // MANUAL OVERRIDE END
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return (
    <div className={cx(currentStyle, className)} id={Constants.ID.LOGIC_BUILDER_PANEL}>
      <Divider
        className={cx(styles.divider, classes?.divider)}
        {...internalProps.divider}
        {...divider}
      />
      <div className={cx(styles.logicBuilderContainer, classes?.logicBuilderContainer)}>
        <div className={cx(styles.logicBuilderContent, classes?.logicBuilderContent)}>
          <LogicBuilderHeader
            className={cx(styles.logicBuilderHeader, classes?.logicBuilderHeader)}
            {...internalProps.logicBuilderHeader}
            {...logicBuilderHeader}
          />
          {logicAreaContainer}
        </div>
        <Divider
          className={cx(styles.divider1, classes?.divider1)}
          {...internalProps.divider1}
          {...divider1}/>
      </div>
    </div>
  );
};

export default LogicBuilderPanel;
