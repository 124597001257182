import { mapStylesToPreviewCssProps } from '../../../../utils/util';
import type { PreviewButtonContainerElementProps } from './types';

const usePresenter = ({ element, style, ...otherProps }: PreviewButtonContainerElementProps): PreviewButtonContainerElementProps => {
  return {
    element,
    style: {
      ...mapStylesToPreviewCssProps(element.styles),
      ...style,
    },
    ...otherProps,
  };
};

export default usePresenter;
