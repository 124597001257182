import cx from 'classnames';
import { computed } from 'mobx';
import {
  useCallback, useContext, useEffect, useMemo, type FC,
} from 'react';
import { getImageAltText, getImageUrl, getTextValue } from '../../../../store/PageStore/settingsUtils';
import { getPreviewElement } from '../../../../store/PreviewStore/utils';
import { StoreContext } from '../../../../store/StoreProvider';
import type { Element } from '../../../../store/types';
import { useNavigateWrapper } from '../../../navigation/hooks/useNavigateWrapper';
import executeFlow from '../../utils/executeFlow';
import { ELEMENT_VIEWS } from './config';
import type { PreviewElementRendererProps, ElementViewProps, PreviewUtils } from './types';

const usePresenter = (props: PreviewElementRendererProps): PreviewElementRendererProps => {
  const { pageStore, previewStore, apiGroupStore } = useContext(StoreContext);

  const { id, className: initialClassName } = props;
  const element: Element | undefined = computed(() => getPreviewElement(id, previewStore)).get();

  let ElementView: FC<ElementViewProps<Element>> | undefined;
  if (element) {
    ElementView = ELEMENT_VIEWS[element.type] as FC<ElementViewProps<Element>>;
    if (!ElementView) {
      console.warn(`Element type '${element.type}' is not supported.`);
    }
  }

  const {
    isInitialised,
    currentGlobalVariables,
    currentPageVariables,
    currentNavigationVariables,
    getElementFlowActions,
    updateVariable,
  } = previewStore;
  const { pages, variables } = pageStore;
  const { apiGroups } = apiGroupStore;

  const { navigate } = useNavigateWrapper();

  useEffect(() => {
    if (!isInitialised) {
      return;
    }
    const onLoadFlow = getElementFlowActions(id, 'OnLoad');
    if (onLoadFlow && Object.keys(onLoadFlow).length) {
      void executeFlow(
        onLoadFlow,
        pages,
        apiGroups,
        variables,
        currentGlobalVariables,
        currentPageVariables,
        currentNavigationVariables,
        navigate,
        updateVariable,
      );
    }
  }, [
    id,
    isInitialised,
    getElementFlowActions,
    pages,
    apiGroups,
    variables,
    currentGlobalVariables,
    currentPageVariables,
    currentNavigationVariables,
    navigate,
    updateVariable,
  ]);

  const onClickHandler = useCallback(() => {
    if (!isInitialised) {
      return;
    }
    const onClickFlow = getElementFlowActions(id, 'OnClick');
    if (onClickFlow && Object.keys(onClickFlow).length) {
      void executeFlow(
        onClickFlow,
        pages,
        apiGroups,
        variables,
        currentGlobalVariables,
        currentPageVariables,
        currentNavigationVariables,
        navigate,
        updateVariable,
      );
    }
  }, [
    id,
    isInitialised,
    getElementFlowActions,
    pages,
    apiGroups,
    variables,
    currentGlobalVariables,
    currentPageVariables,
    currentNavigationVariables,
    navigate,
    updateVariable,
  ]);

  const previewUtils = useMemo((): PreviewUtils | undefined => {
    switch (element?.type) {
      case 'Text':
        return {
          getTextValue: (item) => getTextValue(
            item,
            variables,
            currentGlobalVariables,
            currentPageVariables,
            currentNavigationVariables,
          ),
        };
      case 'Image':
        return {
          getImageUrl: (item) => getImageUrl(
            item,
            variables,
            currentGlobalVariables,
            currentPageVariables,
            currentNavigationVariables,
          ),
          getImageAltText: (item) => getImageAltText(item),
        };
      default:
        return undefined;
    }
  }, [
    element,
    variables,
    currentGlobalVariables,
    currentPageVariables,
    currentNavigationVariables,
  ]);

  const className: string = cx(initialClassName, {});

  return {
    ...props,
    element,
    onClick: onClickHandler,
    settings: element?.settings, // listen for settings changes to re-render
    ElementView,
    className,
    utils: previewUtils,
  };
};

export default usePresenter;
