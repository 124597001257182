import React from 'react';
import cx from 'classnames';

import LogicSettingsHeader from '../../atoms/LogicSettingsHeader';
import SelectedContainer from '../../molecules/SelectedContainer';
import ActionPanel from '../ActionPanel';
import ValuesPanel from '../ValuesPanel';
import VariableResponsePanel from '../VariableResponsePanel';

import styles from './LogicSettingsPanel.module.scss';
import usePresenter from './LogicSettingsPanel.presenter';
import getProps from './LogicSettingsPanel.props';
import { LogicSettingsPanelCombinedProps } from './types';

const LogicSettingsPanel: React.FC<LogicSettingsPanelCombinedProps> = (props) => {
  const {
    className,
    classes,
    logicSettingsHeader,
    selectedContainer,
    actionPanel,
    valuesPanel,
    variableResponsePanel,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.logicSettingsPanel;

  return (
    <div className={cx(currentStyle, className)}>
      <div className={cx(styles.headerContent, classes?.headerContent)}>
        <LogicSettingsHeader
          className={cx(styles.logicSettingsHeader, classes?.logicSettingsHeader)}
          {...internalProps.logicSettingsHeader}
          {...logicSettingsHeader}/>
      </div>
      <SelectedContainer
        className={cx(styles.selectedContainer, classes?.selectedContainer)}
        {...internalProps.selectedContainer}
        {...selectedContainer}/>
      {/* MANUAL OVERRIDE START added container */}
      <div className={cx(styles.container, classes?.container)}>
        <ActionPanel
          className={cx(styles.actionPanel, classes?.actionPanel)}
          {...internalProps.actionPanel}
          {...actionPanel}/>
        <ValuesPanel
          className={cx(styles.valuesPanel, classes?.valuesPanel)}
          {...internalProps.valuesPanel}
          {...valuesPanel}/>
        <VariableResponsePanel
          className={cx(styles.variableResponsePanel, classes?.variableResponsePanel)}
          {...internalProps.variableResponsePanel}
          {...variableResponsePanel}/>
      </div>
      {/* MANUAL OVERRIDE END */}
    </div>
  );
};

export default LogicSettingsPanel;
