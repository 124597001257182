import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useExpandablePanel } from '../../../hooks';
import { getStylePanelConfigurations, updateAnchorOffsetUnit, updateAnchorOffsetValue, updateAnchorPoint } from '../../../store/PageStore/utils';
import { StoreContext } from '../../../store/StoreProvider';
import type { BackgroundSizeType, ColorType, FileType } from '../../../store/types';
import { typedDeepMerge } from '../../../utils/objectUtils';
import { getCustomPositionForElementLeft } from '../../../modules/common/ModalWrapper/utils';
import type { BackgroundPanelCombinedProps } from './types';
import { uploadFile } from '../../../modules/assets/utils';
import useModal from '../../../modules/common/ModalWrapper/context/useModal';
import { AddImageMenuContext } from '.';
import { AddImageMenuCombinedProps } from '../AddImageMenu/types';

const usePresenter = (props: BackgroundPanelCombinedProps): BackgroundPanelCombinedProps => {
  const { t } = useTranslation();
  const { pageStore } = useContext(StoreContext);
  const selectedElement = pageStore.selectedCombineElement;
  const expandableProps = useExpandablePanel(props, 'Expanded', true);
  const panelConfig = getStylePanelConfigurations(selectedElement);

  const onBackgroundImageUpdated = useCallback(async (file: File | null) => {
    if (!selectedElement) {
      return;
    }
    let fileUrl: string | undefined;
    if (file) {
      fileUrl = await uploadFile(file, 'images');
    }

    const backgroundImage: FileType | null = file ? {
      fileName: file.name,
      url: fileUrl || '',
    } : null;

    await pageStore.updateElement(selectedElement, {
      styles: {
        backgroundImage,
      },
    });
  }, [pageStore, selectedElement]);

  const updateValue = async (colour: ColorType) => {
    if (!selectedElement) {
      return;
    }
    await pageStore.updateElement(selectedElement, {
      styles: {
        backgroundColor: colour,
      },
    });
  };

  const onBackgroundSizeUpdated = useCallback(async (value: BackgroundSizeType) => {
    if (selectedElement) {
      await pageStore.updateElement(selectedElement, {
        styles: {
          backgroundSize: value,
        },
      });
    }
  }, [pageStore, selectedElement]);

  const onBackgroundVerticalAnchorPointUpdated = useCallback(async (option: string) => {
    await updateAnchorPoint(pageStore, selectedElement, 'backgroundVerticalAnchor', option);
  }, [pageStore, selectedElement]);

  const onBackgroundVerticalAnchorValueUpdated = useCallback(async (value: string) => {
    await updateAnchorOffsetValue(pageStore, selectedElement, 'backgroundVerticalAnchor', value);
  }, [pageStore, selectedElement]);

  const onBackgroundVerticalAnchorUnitUpdated = useCallback(async (option: string) => {
    await updateAnchorOffsetUnit(pageStore, selectedElement, 'backgroundVerticalAnchor', option);
  }, [pageStore, selectedElement]);

  const onBackgroundHorizontalAnchorPointUpdated = useCallback(async (option: string) => {
    await updateAnchorPoint(pageStore, selectedElement, 'backgroundHorizontalAnchor', option);
  }, [pageStore, selectedElement]);

  const onBackgroundHorizontalAnchorValueUpdated = useCallback(async (value: string) => {
    await updateAnchorOffsetValue(pageStore, selectedElement, 'backgroundHorizontalAnchor', value);
  }, [pageStore, selectedElement]);

  const onBackgroundHorizontalAnchorUnitUpdated = useCallback(async (option: string) => {
    await updateAnchorOffsetUnit(pageStore, selectedElement, 'backgroundHorizontalAnchor', option);
  }, [pageStore, selectedElement]);

  const { modalContainerRef, openModal } = useModal(
    AddImageMenuContext,
    (): AddImageMenuCombinedProps => {
      return {
        backgroundImage: selectedElement?.styles.backgroundImage,
        onBackgroundImageUpdated,

        backgroundSize: selectedElement?.styles.backgroundSize,
        onBackgroundSizeUpdated,

        backgroundVerticalAnchor: selectedElement?.styles.backgroundVerticalAnchor,
        onBackgroundVerticalAnchorPointUpdated,
        onBackgroundVerticalAnchorValueUpdated,
        onBackgroundVerticalAnchorUnitUpdated,

        backgroundHorizontalAnchor: selectedElement?.styles.backgroundHorizontalAnchor,
        onBackgroundHorizontalAnchorPointUpdated,
        onBackgroundHorizontalAnchorValueUpdated,
        onBackgroundHorizontalAnchorUnitUpdated,
      };
    },
    [pageStore, selectedElement],
  );

  const onAddImageButtonClicked = useCallback(() => {
    openModal({
      customPosition: getCustomPositionForElementLeft(modalContainerRef, 4),
    });
  }, [modalContainerRef, openModal]);

  return {
    ...typedDeepMerge(expandableProps, {
      renderPanel: panelConfig?.Background,
      panelHeader: {
        text: {
          value: t('backgroundPanel.background'),
        },
      },
      replaceRow: {
        rowLabel: {
          text: {
            value: t('backgroundPanel.image'),
          },
        },
        replaceButton: {
          actionType: 'Button',
          button: {
            type: 'IconText',
            icon: {
              asset: selectedElement?.styles.backgroundImage ? 'Edit' : 'Add',
            },
            text: {
              value: t(selectedElement?.styles.backgroundImage ? 'backgroundPanel.modifyImage' : 'backgroundPanel.addImage'),
            },
            onClick: onAddImageButtonClicked,
          },
        },
      },
      colourRow: {
        colour: {
          text: {
            value: t('borderPanel.colour'),
          },
        },
        colourValue: selectedElement?.styles.backgroundColor,
        defaultColourValue: '#ffffff',
        onColourUpdated: (colour: ColorType) => updateValue(colour),
      },
    }),
    addImageContainerRef: modalContainerRef,
  };
};

export default usePresenter;
