import { TextProps } from '../Text';

type LabelInternalProps = {
  text?: TextProps;
  text1?: TextProps;
};

type LabelPropsValues = {
  'Default': LabelInternalProps;
  'Required': LabelInternalProps;
};

const propValues: LabelPropsValues = {
  Default: {
    text: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Regular400',
      colour: 'NeutralDefault',
    },
  },
  Required: {
    text: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Regular400',
      colour: 'NeutralDefault',
    },
    text1: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Regular400',
      colour: 'NeutralDefault',
    },
  },
};

const getProps = (type: string): LabelInternalProps => {
  const values: LabelInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
