import React from 'react';
import cx from 'classnames';

import Button from '../../atoms/Button';
import Divider from '../../atoms/Divider';
import Text from '../../atoms/Text';

import styles from './TextModal.module.scss';
import usePresenter from './TextModal.presenter';
import getProps from './TextModal.props';
import { TextModalCombinedProps } from './types';

const TextModal: React.FC<TextModalCombinedProps> = (props) => {
  const {
    state,
    className,
    classes,
    heading,
    primaryButton,
    divider,
    text,
    primaryButton1,
    secondaryButton,
    button,
  } = usePresenter(props);

  const variantName = `${state}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`textModal${variantName}`];

  let componentView;

  switch (variantName) {
    case 'Default': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <div className={cx(styles.headingContent, classes?.headingContent)}>
            <Text
              className={cx(styles.heading, classes?.heading)}
              {...internalProps.heading}
              {...heading}
            />
            <Button
              className={cx(styles.primaryButton, classes?.primaryButton)}
              {...internalProps.primaryButton}
              {...primaryButton}
            />
          </div>
          <Divider
            className={cx(styles.divider, classes?.divider)}
            {...internalProps.divider}
            {...divider}
          />
          <Text
            className={cx(styles.text, classes?.text)}
            {...internalProps.text}
            {...text}
          />
          <div className={cx(styles.buttonContent, classes?.buttonContent)}>
            <Button
              className={cx(styles.primaryButton1, classes?.primaryButton1)}
              {...internalProps.primaryButton1}
              {...primaryButton1}
            />
            <Button
              className={cx(styles.secondaryButton, classes?.secondaryButton)}
              {...internalProps.secondaryButton}
              {...secondaryButton}
            />
          </div>
        </div>
      );
      break;
    }
    case 'Destructive': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <div className={cx(styles.headingContent, classes?.headingContent)}>
            <Text
              className={cx(styles.heading, classes?.heading)}
              {...internalProps.heading}
              {...heading}
            />
            <Button
              className={cx(styles.primaryButton, classes?.primaryButton)}
              {...internalProps.primaryButton}
              {...primaryButton}
            />
          </div>
          <Divider
            className={cx(styles.divider, classes?.divider)}
            {...internalProps.divider}
            {...divider}
          />
          <Text
            className={cx(styles.text, classes?.text)}
            {...internalProps.text}
            {...text}
          />
          <div className={cx(styles.buttonContent, classes?.buttonContent)}>
            <Button
              className={cx(styles.button, classes?.button)}
              {...internalProps.button}
              {...button}
            />
            <Button
              className={cx(styles.secondaryButton, classes?.secondaryButton)}
              {...internalProps.secondaryButton}
              {...secondaryButton}
            />
          </div>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default TextModal;
