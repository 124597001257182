import {
  Action, CallApiAction, Flow, NavigateToAction, NavigationDestination, NavigationLaunchType,
} from '../../modules/actions/types';
import { IPage } from '../types';
import { ApiGroup, ApiResponseSchema } from '../ApiGroupStore/types';
import { Variable, VariableReferenceType, VariableValueType } from '../../modules/variables/types';
import { TEMP_API_RESPONSES } from '../../lib/constants';
import { excludePropTypeIgnore } from '../../lib/utils';

export const findFirstFlowAction = (flow: Flow): Action | undefined => {
  const actions = Object.values(flow);
  if (actions.length === 0) {
    return undefined;
  }
  let currentFirstAction = actions[0];
  while (currentFirstAction) {
    const { prevActionUuid } = currentFirstAction;
    const prevAction = prevActionUuid ? flow[prevActionUuid] : undefined;
    // if prevAction is empty then exist loop
    if (!prevAction) {
      break;
    }
    currentFirstAction = prevAction;
  }
  return currentFirstAction;
};
export const resolveApiCallAction = (action: CallApiAction, apiGroups: ApiGroup[]) => {
  if (action.metadata) {
    const { apiGroupUuid, apiEndpointUuid } = action.metadata;
    const apiGroup = apiGroupUuid ? apiGroups.find((item) => item.uuid === apiGroupUuid) : undefined;
    const endpoint = apiEndpointUuid ? apiGroup?.endpoints?.find((item) => item.uuid === apiEndpointUuid) : undefined;
    return {
      apiGroup,
      endpoint,
    };
  }
  return {
    apiGroup: undefined,
    endpoint: undefined,
  };
};
export const resolveNavigateToAction = (action: NavigateToAction, pages: IPage[]) => {
  if (action.metadata) {
    const { destination, url, pageUuid, launchType } = action.metadata;
    const page = pages.find((item) => item.uuid === pageUuid);
    return {
      destination: destination || 'Page',
      url,
      page,
      launchType: launchType || 'same_tab',
    };
  }
  return {
    destination: 'page' as NavigationDestination,
    url: undefined,
    page: undefined,
    launchType: 'same_tab' as NavigationLaunchType,
  };
};
export const resolveAvailableVariables = (
  type: VariableValueType,
  variables: Variable[],
) => {
  const storedVariables = variables.map((item): VariableReferenceType => {
    return {
      id: item.uuid,
      scope: item.scope,
      name: item.name,
      type: item.type,
    };
  });
  return [
    ...storedVariables.sort((a, b) => (a.name > b.name ? 1 : -1)),
  ];
};
export const resolveAvailableActionVariables = (
  type: VariableValueType,
  selectedAction: Action | undefined,
  selectedFlow: Flow | undefined,
  apiGroups: ApiGroup[],
  variables: Variable[],
) => {
  const actionOutputs: VariableReferenceType[] = [];
  if (selectedFlow) {
    let currentAction = selectedAction;
    while (currentAction?.prevActionUuid) {
      const prevAction = selectedFlow[currentAction.prevActionUuid];
      switch (prevAction.actionType) {
        case 'CallApi': {
          const { endpoint } = resolveApiCallAction(prevAction, apiGroups);
          // TODO: remove fallback data
          const apiResponseItems: ApiResponseSchema = excludePropTypeIgnore(endpoint?.responseSchema) || TEMP_API_RESPONSES;
          apiResponseItems.forEach((item) => {
            actionOutputs.push({
              id: item.responsePath,
              scope: 'Flow',
              name: item.propName,
              type: item.propType,
            });
          });
          break;
        }
        default:
          break;
      }
      currentAction = prevAction;
    }
  }
  const storedVariables = variables.map((item): VariableReferenceType => {
    return {
      id: item.uuid,
      scope: item.scope,
      name: item.name,
      type: item.type,
    };
  });
  return [
    ...actionOutputs.sort((a, b) => (a.name > b.name ? 1 : -1)),
    ...storedVariables.sort((a, b) => (a.name > b.name ? 1 : -1)),
  ];
};
export const convertToDoubleLinked = (flow: Flow): Flow => {
  const updatedFlow = { ...flow };
  Object.keys(flow).forEach((actionUuid) => {
    const currAction = flow[actionUuid];
    if (currAction.prevActionUuid) {
      const prevAction = flow[currAction.prevActionUuid];
      updatedFlow[currAction.prevActionUuid] = {
        ...prevAction,
        nextActionUuid: actionUuid,
      };
    }
  });
  return updatedFlow;
};
