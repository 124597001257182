/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import cx from 'classnames';

import Button from '../../atoms/Button';
import Icon from '../../atoms/Icon';
import Text from '../../atoms/Text';
import TextInput from '../../atoms/TextInput';
import DropdownItemList from '../DropdownItemList';
import ModalWrapper from '../../../modules/common/ModalWrapper';

import styles from './PageLabelItem.module.scss';
import usePresenter from './PageLabelItem.presenter';
import getProps from './PageLabelItem.props';
import type { PageLabelItemCombinedProps } from './types';

const PageLabelItem: React.FC<PageLabelItemCombinedProps> = (props) => {
  const {
    style,
    state,
    type,
    className,
    classes,
    text,
    icon,
    textInput,
    button,
    // MANUAL OVERRIDE STARTS
    pageUuid,
    pageRef,
    onMouseOver,
    onFocus,
    onMouseOut,
    onBlur,
    onClick,
    isContextMenuOpen,
    closeContextMenu,
    contextMenuPosition,
    contextMenuItems,
    // MANUAL OVERRIDE ENDS
  } = usePresenter(props);

  // MANUAL OVERRIDE STARTS
  if (!pageUuid) {
    return null;
  }
  // MANUAL OVERRIDE ENDS

  const variantName = `${style}${state}${type}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`pageLabelItem${variantName}`];

  let componentView;

  switch (variantName) {
    case 'DefaultDefaultDefault':
    case 'DefaultSelectedDefault': {
      componentView = (
        // MANUAL OVERRIDE: Top div is added at the bottom
        <div className={cx(styles.content, classes?.content)}>
          <Text
            className={cx(styles.text, classes?.text)}
            {...internalProps.text}
            {...text}
          />
        </div>
      );
      break;
    }
    case 'DefaultDefaultHomepage':
    case 'DefaultSelectedHomepage': {
      componentView = (
        // MANUAL OVERRIDE: Top div is added at the bottom
        <>
          <Icon
            className={cx(styles.icon, classes?.icon)}
            {...internalProps.icon}
            {...icon}
          />
          <div className={cx(styles.content, classes?.content)}>
            <Text
              className={cx(styles.text, classes?.text)}
              {...internalProps.text}
              {...text}
            />
          </div>
        </>
      );
      break;
    }
    case 'DefaultEditableSelectedDefault': {
      componentView = (
        // MANUAL OVERRIDE: Top div is added at the bottom
        <div className={cx(styles.content, classes?.content)}>
          <TextInput
            className={cx(styles.textInput, classes?.textInput)}
            {...internalProps.textInput}
            {...textInput}
          />
        </div>
      );
      break;
    }
    case 'DefaultEditableSelectedHomepage': {
      componentView = (
        // MANUAL OVERRIDE: Top div is added at the bottom
        <>
          <Icon
            className={cx(styles.icon, classes?.icon)}
            {...internalProps.icon}
            {...icon}
          />
          <div className={cx(styles.content, classes?.content)}>
            <TextInput
              className={cx(styles.textInput, classes?.textInput)}
              {...internalProps.textInput}
              {...textInput}
            />
          </div>
        </>
      );
      break;
    }
    case 'ShowMenuDefaultDefault':
    case 'ShowMenuSelectedDefault': {
      componentView = (
        // MANUAL OVERRIDE: Top div is added at the bottom
        <>
          <div className={cx(styles.content, classes?.content)}>
            <Text
              className={cx(styles.text, classes?.text)}
              {...internalProps.text}
              {...text}
            />
          </div>
          <Button
            className={cx(styles.button, classes?.button)}
            {...internalProps.button}
            {...button}
          />
        </>
      );
      break;
    }
    case 'ShowMenuDefaultHomepage':
    case 'ShowMenuSelectedHomepage': {
      componentView = (
        // MANUAL OVERRIDE: Top div is added at the bottom
        <>
          <Icon
            className={cx(styles.icon, classes?.icon)}
            {...internalProps.icon}
            {...icon}
          />
          <div className={cx(styles.content, classes?.content)}>
            <Text
              className={cx(styles.text, classes?.text)}
              {...internalProps.text}
              {...text}
            />
          </div>
          <Button
            className={cx(styles.button, classes?.button)}
            {...internalProps.button}
            {...button}
          />
        </>
      );
      break;
    }
    case 'ShowMenuEditableSelectedDefault': {
      componentView = (
        // MANUAL OVERRIDE: Top div is added at the bottom
        <>
          <div className={cx(styles.content, classes?.content)}>
            <TextInput
              className={cx(styles.textInput, classes?.textInput)}
              {...internalProps.textInput}
              {...textInput}
            />
          </div>
          <Button
            className={cx(styles.button, classes?.button)}
            {...internalProps.button}
            {...button}
          />
        </>
      );
      break;
    }
    case 'ShowMenuEditableSelectedHomepage': {
      componentView = (
        // MANUAL OVERRIDE: Top div is added at the bottom
        <>
          <Icon
            className={cx(styles.icon, classes?.icon)}
            {...internalProps.icon}
            {...icon}
          />
          <div className={cx(styles.content, classes?.content)}>
            <TextInput
              className={cx(styles.textInput, classes?.textInput)}
              {...internalProps.textInput}
              {...textInput}
            />
          </div>
          <Button
            className={cx(styles.button, classes?.button)}
            {...internalProps.button}
            {...button}
          />
        </>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  // MANUAL OVERRIDE STARTS
  return (
    <>
      <div
        ref={pageRef}
        className={cx(currentStyle, className)}
        onMouseEnter={onMouseOver}
        onFocus={onFocus}
        onMouseLeave={onMouseOut}
        onBlur={onBlur}
        onClick={onClick}
      >
        {componentView}
      </div>
      {isContextMenuOpen && (
        <ModalWrapper
          show
          onHide={closeContextMenu}
          customPosition={contextMenuPosition}
          content={(
            <DropdownItemList
              {...internalProps.dropdownItemList}
              dropdownItems={contextMenuItems}
              classes={{ dropdownItem: styles.contextMenuItem }}
            />
          )}
        />
      )}
    </>
  );
  // MANUAL OVERRIDE ENDS
};

export default PageLabelItem;
