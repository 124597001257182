import { DropdownItemProps } from '../../atoms/DropdownItem';

type DropdownItemListInternalProps = {
  dropdownItem?: DropdownItemProps;
};

type DropdownItemListPropsValues = {
  'Checkmark': DropdownItemListInternalProps;
  'Default': DropdownItemListInternalProps;
  'Menu': DropdownItemListInternalProps;
};

const propValues: DropdownItemListPropsValues = {
  Checkmark: {
    dropdownItem: {
      state: 'Default',
      type: 'Default',
    },
  },
  Default: {
    dropdownItem: {
      state: 'Default',
      type: 'TextOnly',
    },
  },
  Menu: {
    dropdownItem: {
      state: 'Default',
      type: 'TextOnly',
    },
  },
};

const getProps = (type: string): DropdownItemListInternalProps => {
  const values: DropdownItemListInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
