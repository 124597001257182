import React from 'react';
import cx from 'classnames';

import RowLabel from '../../atoms/RowLabel';
import ColourPreview from '../../atoms/ColourPreview';
import InputField from '../../atoms/InputField';

import styles from './ColourRow.module.scss';
import usePresenter from './ColourRow.presenter';
import getProps from './ColourRow.props';
import { ColourRowCombinedProps } from './types';

const ColourRow: React.FC<ColourRowCombinedProps> = (props) => {
  const {
    className,
    classes,
    colour,
    colourPreview,
    hexCodeField,
    opacityField,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.colourRow;

  return (
    <div className={cx(currentStyle, className)}>
      <RowLabel
        className={cx(styles.colour, classes?.colour)}
        {...internalProps.colour}
        {...colour}
      />
      <ColourPreview
        className={cx(styles.colourPreview, classes?.colourPreview)}
        {...internalProps.colourPreview}
        {...colourPreview}
      />
      <InputField
        className={cx(styles.hexCodeField, classes?.hexCodeField)}
        {...internalProps.hexCodeField}
        {...hexCodeField}
      />
      <InputField
        className={cx(styles.opacityField, classes?.opacityField)}
        {...internalProps.opacityField}
        {...opacityField}
      />
    </div>
  );
};

export default ColourRow;
