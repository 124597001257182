import { ButtonProps } from '../../atoms/Button';
import { DividerProps } from '../../atoms/Divider';
import { InputFieldProps } from '../../atoms/InputField';
import { LabelProps } from '../../atoms/Label';
import { MenuHeaderProps } from '../../molecules/MenuHeader';

type ConfigureGroupMenuInternalProps = {
  menuHeader?: MenuHeaderProps;
  divider?: DividerProps;
  label?: LabelProps;
  inputField?: InputFieldProps;
  label1?: LabelProps;
  inputField1?: InputFieldProps;
  secondaryButton?: ButtonProps;
  primaryButton?: ButtonProps;
  button?: ButtonProps;
};

type ConfigureGroupMenuPropsValues = {
  'Default': ConfigureGroupMenuInternalProps;
  'Removeable': ConfigureGroupMenuInternalProps;
};

const propValues: ConfigureGroupMenuPropsValues = {
  Default: {
    menuHeader: {
      type: 'Default',
      size: 'M',
    },
    divider: {
      type: 'Horizontal',
      colour: 'Neutral1900',
    },
    label: {
      state: 'Required',
    },
    inputField: {
      type: 'Text',
      size: 'Medium',
      state: 'Default',
    },
    label1: {
      state: 'Required',
    },
    inputField1: {
      type: 'TextArea',
      size: 'Medium',
      state: 'Default',
    },
    secondaryButton: {
      type: 'Text',
      size: 'M',
      state: 'Default',
      style: 'Secondary',
    },
    primaryButton: {
      type: 'Text',
      size: 'M',
      state: 'Default',
      style: 'Primary',
    },
  },
  Removeable: {
    menuHeader: {
      type: 'Default',
      size: 'M',
    },
    divider: {
      type: 'Horizontal',
      colour: 'Neutral1900',
    },
    label: {
      state: 'Required',
    },
    inputField: {
      type: 'Text',
      size: 'Medium',
      state: 'Default',
    },
    label1: {
      state: 'Required',
    },
    inputField1: {
      type: 'TextArea',
      size: 'Medium',
      state: 'Default',
    },
    button: {
      type: 'Text',
      size: 'M',
      state: 'Default',
      style: 'Destructive',
    },
    secondaryButton: {
      type: 'Text',
      size: 'M',
      state: 'Default',
      style: 'Secondary',
    },
    primaryButton: {
      type: 'Text',
      size: 'M',
      state: 'Default',
      style: 'Primary',
    },
  },
};

const getProps = (type: string): ConfigureGroupMenuInternalProps => {
  const values: ConfigureGroupMenuInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
