import React, { createContext, useCallback, useMemo, useState } from 'react';
import { matchesObject } from '../../../../lib/utils';
import { BaseModalProps, ExportedModalProps, ModalPosition } from '../types';

type OpenModalProps = Pick<ExportedModalProps<BaseModalProps>, 'customPosition' | 'className' | 'backdropClassName'>;

export type InputModalProps<ModalPropsType extends BaseModalProps> = Omit<ModalPropsType, 'closeModal'> & {
  onClose?: () => void;
};

export type ModalContextValue<ModalPropsType extends BaseModalProps> = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateProps: (props?: ModalPropsType) => void;
  openModal: (props?: OpenModalProps) => void;
  closeModal: () => void;
};

export function createNewModalContext<ModalPropsType extends BaseModalProps>() {
  return createContext<ModalContextValue<ModalPropsType>>({
    updateProps: () => {},
    openModal: () => {},
    closeModal: () => {},
  });
}

type ProvideProps<ModalPropsType extends BaseModalProps> = {
  ModalContext: React.Context<ModalContextValue<ModalPropsType>>;
  children: (props: ExportedModalProps<ModalPropsType>) => JSX.Element;
};

export function ModalWrapperProvider <ModalPropsType extends BaseModalProps>(
  { ModalContext, children }: ProvideProps<ModalPropsType>,
) {
  const [modalProps, setModalProps] = useState<InputModalProps<ModalPropsType> | undefined>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [backdropClassName, setBackgroundClassName] = useState<string>();
  const [className, setClassName] = useState<string>();
  const [customPosition, setCustomPosition] = useState<ModalPosition>();

  const ContextProvider = useMemo(() => ModalContext.Provider, [ModalContext]);

  const updateProps = useCallback((newModalProps?: InputModalProps<ModalPropsType>) => {
    if (!matchesObject(modalProps, newModalProps)) {
      setModalProps(newModalProps);
    }
  }, [modalProps]);

  const openModal = useCallback((props?: OpenModalProps) => {
    const {
      backdropClassName: newBackdropClassName,
      className: newClassName,
      customPosition: newCustomPosition,
    } = props || {};

    setBackgroundClassName(newBackdropClassName);
    setClassName(newClassName);
    setCustomPosition(newCustomPosition);

    setShowModal(true);
  }, []);

  const closeModal = useCallback(() => {
    if (modalProps?.onClose) {
      modalProps.onClose();
    }
    setModalProps(undefined);
    setShowModal(false);
  }, [modalProps]);

  const contextValue: ModalContextValue<ModalPropsType> = useMemo(() => ({
    updateProps,
    openModal,
    closeModal,
  }), [updateProps, openModal, closeModal]);

  const renderedChildren = children({
    show: showModal,
    onHide: closeModal,
    backdropClassName,
    className,
    customPosition,
    modalProps: {
      ...modalProps,
      closeModal,
    } as ModalPropsType,
  });

  return (
    <ContextProvider value={contextValue} >
      {renderedChildren}
    </ContextProvider>
  );
}
