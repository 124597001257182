import React from 'react';
import cx from 'classnames';

import Button from '../../atoms/Button';
import Icon from '../../atoms/Icon';
import Text from '../../atoms/Text';

import styles from './VariableLayerItem.module.scss';
import usePresenter from './VariableLayerItem.presenter';
import getProps from './VariableLayerItem.props';
import { VariableLayerItemCombinedProps } from './types';

const VariableLayerItem: React.FC<VariableLayerItemCombinedProps> = (props) => {
  const {
    state,
    type,
    className,
    classes,
    button,
    icon,
    name,
    variableType,
    onClick,
  } = usePresenter(props);

  const variantName = `${state}${type}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`variableLayerItem${variantName}`];

  let componentView;

  switch (variantName) {
    case 'DefaultCollapsed':
    case 'DefaultExpanded':
    case 'SelectedCollapsed':
    case 'SelectedExpanded': {
      componentView = (
        <div onMouseDown={onClick} role="button" tabIndex={0} className={cx(currentStyle, className)}>
          <Button
            className={cx(styles.button, classes?.button)}
            {...internalProps.button}
            {...button} />
          <Icon
            className={cx(styles.icon, classes?.icon)}
            {...internalProps.icon}
            {...icon} />
          <Text
            className={cx(styles.name, classes?.name)}
            {...internalProps.name}
            {...name} />
          <Text
            className={cx(styles.variableType, classes?.variableType)}
            {...internalProps.variableType}
            {...variableType} />
        </div>
      );
      break;
    }
    case 'DefaultDefault':
    case 'SelectedDefault': {
      componentView = (
        <div onMouseDown={onClick} role="button" tabIndex={0} className={cx(currentStyle, className)}>
          <Icon
            className={cx(styles.icon, classes?.icon)}
            {...internalProps.icon}
            {...icon} />
          <Text
            className={cx(styles.name, classes?.name)}
            {...internalProps.name}
            {...name} />
          <Text
            className={cx(styles.variableType, classes?.variableType)}
            {...internalProps.variableType}
            {...variableType} />
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default VariableLayerItem;
