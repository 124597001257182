import { TextProps } from '../../atoms/Text';
import { ButtonProps } from '../../atoms/Button';
import { EmptyGroupBlockProps } from '../../blocks/EmptyGroupBlock';
import { HeaderBlockProps } from '../../blocks/HeaderBlock';
import { TabItemListProps } from '../../molecules/TabItemList';
import { GroupsPanelProps } from '../../organisms/GroupsPanel';
import { EndpointCardBlockProps } from '../../blocks/EndpointCardBlock';

type GroupPageInternalProps = {
  groupsPanel?: GroupsPanelProps;
  groupName?: TextProps;
  baseURL?: TextProps;
  tabItemList?: TabItemListProps;
  button?: ButtonProps;
  headerBlock?: HeaderBlockProps;
  groupParameters?: HeaderBlockProps; // MANUAL OVERRIDE
  emptyGroupBlock?: EmptyGroupBlockProps;
  endpointCardBlock?: EndpointCardBlockProps; // MANUAL OVERRIDE
};

type GroupPagePropsValues = {
  'DefaultEndpoints': GroupPageInternalProps;
  'EmptyGroups': GroupPageInternalProps;
};

const propValues: GroupPagePropsValues = {
  DefaultEndpoints: {
    groupsPanel: {
    },
    groupName: {
      type: 'XXL',
      align: 'Left',
      style: 'Semibold600',
      colour: 'NeutralHoverSelected',
    },
    baseURL: {
      type: 'LabelMedium',
      align: 'Left',
      style: 'Regular400',
      colour: 'NeutralDefault',
    },
    tabItemList: {
    },
    button: {
      type: 'Text',
      size: 'M',
      state: 'Default',
      style: 'Primary',
    },
    headerBlock: {
      state: 'Default',
    },
    // MANUAL OVERRIDE STARTS
    endpointCardBlock: {
      state: 'Empty',
    },
    // MANUAL OVERRIDE ENDS
  },
  EmptyGroups: {
    groupsPanel: {
    },
    emptyGroupBlock: {
      type: 'EmptyGroup', // MANUAL OVERRIDE
    },
  },
};

const getProps = (type: string): GroupPageInternalProps => {
  const values: GroupPageInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
