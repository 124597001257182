import React from 'react';
import cx from 'classnames';

import VariableCard from '../VariableCard';

import styles from './VariableCardList.module.scss';
import usePresenter from './VariableCardList.presenter';
import getProps from './VariableCardList.props';
import { VariableCardListCombinedProps } from './types';

const VariableCardList: React.FC<VariableCardListCombinedProps> = (props) => {
  const {
    size,
    className,
    classes,
    variableCards, // MANUAL OVERRIDE
  } = usePresenter(props);

  const variantName = `${size}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`variableCardList${variantName}`];

  let componentView;

  switch (variantName) {
    case 'Large':
    case 'Small': {
      const variableCardViews = variableCards?.map((variableCard, index) => (
        <VariableCard
          key={index}
          className={cx(styles.variableCard, classes?.variableCard)}
          {...internalProps.variableCard}
          {...variableCard}
        />
      )) || [];

      componentView = (
        <div className={cx(currentStyle, className)}>
          {variableCardViews}
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default VariableCardList;
