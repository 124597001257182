import React from 'react';
import cx from 'classnames';

import PropertyItem from '../PropertyItem';

import styles from './PropertyItemList.module.scss';
import usePresenter from './PropertyItemList.presenter';
import getProps from './PropertyItemList.props';
import { PropertyItemListCombinedProps } from './types';

const PropertyItemList: React.FC<PropertyItemListCombinedProps> = (props) => {
  const {
    className,
    classes,
    propertyItems,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.propertyItemList;

  const propertyItemViews = propertyItems?.map((propertyItem, index) => (
    <PropertyItem
      key={index}
      className={cx(styles.propertyItem, classes?.propertyItem)}
      {...internalProps.propertyItem}
      {...propertyItem}
    />
  )) || [];

  return (
    <div className={cx(currentStyle, className)}>
      {propertyItemViews}
    </div>
  );
};

export default PropertyItemList;
