import { v4 as uuid } from 'uuid';
import { Constants } from '../../constants';
import { i18n } from '../../modules/locale';
import en from '../../modules/locale/translations/en';
import type { PageStore } from './PageStore';
import type {
  CreateElementFunc, Element, IButtonContainer, IContainer, IStyles, IText, ElementConfigurations, ElementCreateFunctions, StylePanelConfigurations,
} from '../types';

// *****************************************************************************************************************************
// *****************************************************************************************************************************
// Default styles
// *****************************************************************************************************************************
// *****************************************************************************************************************************

const commonDefaultStyles: IStyles = {
  width: { unit: 'hug' },
  height: { unit: 'hug' },
  maxWidth: { unit: 'none' },
  maxHeight: { unit: 'none' },
  minWidth: { value: 0, unit: 'px' },
  minHeight: { value: 0, unit: 'px' },
  overflow: 'visible',
  // Padding & Margin
  padding: {
    top: { value: 0, unit: 'px' },
    right: { value: 0, unit: 'px' },
    bottom: { value: 0, unit: 'px' },
    left: { value: 0, unit: 'px' },
  },
  margin: {
    top: { value: 0, unit: 'px' },
    right: { value: 0, unit: 'px' },
    bottom: { value: 0, unit: 'px' },
    left: { value: 0, unit: 'px' },
  },
  // Layout
  direction: 'horizontal',
  alignment: {
    vertical: 'top',
    horizontal: 'left',
  },
  spacing: 'packed',
  gap: { value: 0, unit: 'px' },
  position: 'static',
  verticalAnchor: { anchorPoint: 'top', offset: { value: 0, unit: 'px' } },
  horizontalAnchor: { anchorPoint: 'left', offset: { value: 0, unit: 'px' } },
  // Background
  backgroundColor: { hexCode: '#000000', opacity: { value: 0, unit: '%' } },
  // Border
  borderWeight: { value: 0, unit: 'px' },
  borderStyle: 'solid',
  borderColor: { hexCode: '#000000', opacity: { value: 100, unit: '%' } },
  // Corner Radius
  cornerRadius: { value: 0, unit: 'px' },
  unifiedCornerRadius: true,
  // Effects & Styles
  visible: true,
  opacity: { value: 100, unit: '%' },
  cursor: 'auto',
};

const containerDefaultStyles: IStyles = {
  ...commonDefaultStyles,
  width: { unit: 'fill' },
  height: { unit: 'hug' },
  // Container default styles
};

const bodyDefaultStyles: IStyles = {
  ...commonDefaultStyles,
  // Body default styles
  width: { unit: 'fill' },
  height: { unit: 'fill' },
  // Layout
  direction: 'vertical',
};

const mainContainerDefaultStyles: IStyles = {
  ...commonDefaultStyles,
  width: { value: 100, unit: 'vw' },
  height: { value: 100, unit: 'vh' },
  overflow: 'scroll',
  // Layout
  direction: 'vertical',
};

const buttonContainerDefaultStyles: IStyles = {
  ...commonDefaultStyles,
  // Padding & Margin
  padding: {
    top: { value: 8, unit: 'px' },
    right: { value: 24, unit: 'px' },
    bottom: { value: 8, unit: 'px' },
    left: { value: 24, unit: 'px' },
  },
  // Layout
  direction: 'horizontal',
  alignment: {
    vertical: 'center',
    horizontal: 'center',
  },
  // background
  backgroundColor: {
    hexCode: '#1954ED',
    opacity: {
      value: 100,
      unit: '%',
    },
  },
};

const imageDefaultStyles: IStyles = {
  ...commonDefaultStyles,
  width: { unit: 'hug' },
  height: { unit: 'hug' },
  overflow: 'hidden',
};

const textDefaultStyles: IStyles = {
  ...commonDefaultStyles,
  width: { unit: 'fill' },
  overflow: 'hidden',
  // Typography
  font: 'inter',
  fontSize: { value: 16, unit: 'px' },
  letterSpacing: { value: 0, unit: 'px' },
  lineHeight: { value: 24, unit: 'px' },
  fontWeight: 'regular',
  fontColor: { hexCode: '#333333', opacity: { value: 100, unit: '%' } },
  fontItalic: false,
  fontStyle: 'none',
  fontAlign: 'left',
};

const buttonTextDefaultStyles: IStyles = {
  ...textDefaultStyles,
  width: { unit: 'hug' },
  height: { unit: 'hug' },
  fontColor: { hexCode: '#ffffff', opacity: { value: 100, unit: '%' } },
};

const baseStylePanelConfigurations: StylePanelConfigurations = {
  Size: true,
  PaddingMargins: true,
  Layout: true,
  LayoutSpacing: true,
  LayoutAlignment: true,
  Typography: true,
  Background: true,
  Border: true,
  Corners: true,
  StyleEffects: true,
};

const bodyStylePanelConfigurations: StylePanelConfigurations = {
  ...baseStylePanelConfigurations,
  Typography: false,
};

const containerStylePanelConfigurations: StylePanelConfigurations = {
  ...baseStylePanelConfigurations,
  Typography: false,
};

const buttonStylePanelConfigurations: StylePanelConfigurations = {
  ...baseStylePanelConfigurations,
  Typography: false,
};

const textStylePanelConfigurations: StylePanelConfigurations = {
  ...baseStylePanelConfigurations,
  LayoutSpacing: false,
  LayoutAlignment: false,
};

const imageStylePanelConfigurations: StylePanelConfigurations = {
  ...baseStylePanelConfigurations,
  Background: false,
  Typography: false,
  LayoutSpacing: false,
  LayoutAlignment: false,
};

// *****************************************************************************************************************************
// *****************************************************************************************************************************
// Element Configurations
// *****************************************************************************************************************************
// *****************************************************************************************************************************

export const ELEMENT_CONFIGURATIONS: ElementConfigurations = {
  [Constants.ELEMENT_TYPES.BODY]: {
    type: Constants.ELEMENT_TYPES.BODY,
    getName: () => i18n.t('elementNames.body') ?? en.elementNames.body,
    addIndexToName: false,
    icon: 'Body',
    defaultStyles: bodyDefaultStyles,
    getDefaultSettings: () => ({}),
    isResizeable: false,
    isDropAccepted: true,
    isEditable: false,
    stylePanelConfigurations: bodyStylePanelConfigurations,
  },
  [Constants.ELEMENT_TYPES.CONTAINER]: {
    type: Constants.ELEMENT_TYPES.CONTAINER,
    getName: () => i18n.t('elementNames.container') ?? en.elementNames.container,
    addIndexToName: true,
    icon: 'Container',
    defaultStyles: containerDefaultStyles,
    getDefaultSettings: () => ({}),
    customisations: {
      // Use custom styles when the first container (Main) is inserted into a body container
      mainContainer: {
        getCustomName: () => i18n.t('elementNames.mainContainer') ?? en.elementNames.mainContainer,
        customAddIndexToName: false,
        customStyles: mainContainerDefaultStyles,
      },
    },
    isResizeable: true,
    isDropAccepted: true,
    isEditable: false,
    stylePanelConfigurations: containerStylePanelConfigurations,
  },
  [Constants.ELEMENT_TYPES.BUTTON_CONTAINER]: {
    type: Constants.ELEMENT_TYPES.BUTTON_CONTAINER,
    getName: () => i18n.t('elementNames.buttonContainer') ?? en.elementNames.buttonContainer,
    addIndexToName: true,
    icon: 'Button',
    defaultStyles: buttonContainerDefaultStyles,
    getDefaultSettings: () => ({
      state: {
        name: 'state',
        displayName: i18n.t('settingsPanel.elements.button.displayName') ?? en.settingsPanel.elements.button.displayName,
        source: 'value',
        type: 'boolean',
        value: true,
      },
    }),
    isResizeable: true,
    isDropAccepted: true,
    isEditable: false,
    // Allow only text elements inside button containers
    allowedDropElementTypes: [Constants.ELEMENT_TYPES.TEXT, Constants.ELEMENT_TYPES.IMAGE],
    stylePanelConfigurations: buttonStylePanelConfigurations,
  },
  [Constants.ELEMENT_TYPES.TEXT]: {
    type: Constants.ELEMENT_TYPES.TEXT,
    getName: () => i18n.t('elementNames.text') ?? en.elementNames.text,
    addIndexToName: true,
    icon: 'Text',
    defaultStyles: textDefaultStyles,
    getDefaultSettings: () => ({
      value: {
        name: 'value',
        displayName: i18n.t('settingsPanel.elements.text.displayName') ?? en.settingsPanel.elements.text.displayName,
        source: 'value',
        type: 'string',
        value: i18n.t('elementValues.textValue') ?? en.elementValues.textValue,
      },
    }),
    customisations: {
      // Use custom styles when a text element is inserted into a button container
      buttonText: {
        getCustomName: () => i18n.t('elementNames.buttonText') ?? en.elementNames.buttonText,
        customAddIndexToName: true,
        customStyles: buttonTextDefaultStyles,
        getCustomSettings: () => ({
          value: {
            name: 'value',
            source: 'value',
            type: 'string',
            value: i18n.t('elementValues.buttonTextValue') ?? en.elementValues.buttonTextValue,
          },
        }),
      },
    },
    isResizeable: true,
    isDropAccepted: false,
    isEditable: true,
    stylePanelConfigurations: textStylePanelConfigurations,
  },
  [Constants.ELEMENT_TYPES.IMAGE]: {
    type: Constants.ELEMENT_TYPES.IMAGE,
    getName: () => i18n.t('elementNames.image') ?? en.elementNames.image,
    addIndexToName: true,
    icon: 'Image',
    defaultStyles: imageDefaultStyles,
    getDefaultSettings: () => ({
      image: {
        name: 'image',
        displayName: i18n.t('settingsPanel.elements.image.displayName') ?? en.settingsPanel.elements.image.displayName,
        source: 'value',
        type: 'image',
      },
    }),
    isResizeable: true,
    isDropAccepted: false,
    isEditable: false,
    stylePanelConfigurations: imageStylePanelConfigurations,
  },
};

// *****************************************************************************************************************************
// *****************************************************************************************************************************
// Create Element Functions
// *****************************************************************************************************************************
// *****************************************************************************************************************************

export const createStandardElement = async <TElement extends Element>(
  pageStore: PageStore,
  type: TElement['type'],
  parentId: TElement['parentId'],
  beforeChildIndex: number,
  customisations?: {
    customName?: string,
    customAddIndexToName?: boolean,
    customStyles?: IStyles,
    customSettings?: Partial<TElement['settings']>,
  },
): Promise<TElement['id']> => {
  if (type === Constants.ELEMENT_TYPES.BODY) {
    return Constants.ID.BODY;
  }

  const { getName, addIndexToName, defaultStyles, getDefaultSettings } = ELEMENT_CONFIGURATIONS[type];
  const { customName, customAddIndexToName, customStyles, customSettings } = customisations ?? {};

  const elementId: string = uuid();
  const name: string = customName ?? getName();
  const styles: IStyles = customStyles ? { ...defaultStyles, ...customStyles } : defaultStyles;
  const settings: Record<string, unknown> = customSettings ? { ...getDefaultSettings(), ...customSettings } : getDefaultSettings();

  await pageStore.createElement({
    id: elementId,
    type,
    name: (customAddIndexToName ?? addIndexToName) ? pageStore.generateElementNameWithIndex(name) : name,
    parentId,
    childIds: [],
    settings: settings as any, // eslint-disable-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment
    styles,
  }, beforeChildIndex);

  return elementId;
};

export const getCreateStandardElementFuncForType = <TElement extends Element>(type: TElement['type']): CreateElementFunc<TElement> => {
  return async (
    pageStore: PageStore,
    parentId: TElement['parentId'],
    beforeChildIndex: number,
    customisations?: {
      customName?: string,
      customAddIndexToName?: boolean,
      customSettings?: Partial<TElement['settings']>,
    },
  ): Promise<TElement['id']> => {
    return createStandardElement(pageStore, type, parentId, beforeChildIndex, customisations);
  };
};

export const createContainer = async (
  pageStore: PageStore,
  parentId: string,
  beforeChildIndex: number,
): Promise<string> => {
  let customName: string | undefined;
  let customAddIndexToName: boolean | undefined;
  let customStyles: IStyles | undefined;

  // Use custom styles when the first container (Main) is inserted into a body container
  if (pageStore.elements[parentId].type === Constants.ELEMENT_TYPES.BODY && !pageStore.elements[parentId].childIds.length) {
    const mainContainerCustomisations = ELEMENT_CONFIGURATIONS[Constants.ELEMENT_TYPES.CONTAINER]?.customisations?.mainContainer;
    if (mainContainerCustomisations) {
      customName = mainContainerCustomisations.getCustomName ? mainContainerCustomisations.getCustomName() : undefined;
      customAddIndexToName = mainContainerCustomisations.customAddIndexToName;
      customStyles = mainContainerCustomisations.customStyles;
    }
  }

  const containerId: string = await createStandardElement<IContainer>(
    pageStore,
    Constants.ELEMENT_TYPES.CONTAINER,
    parentId,
    beforeChildIndex,
    {
      customName,
      customAddIndexToName,
      customStyles,
    },
  );

  return containerId;
};

export const createText = async (
  pageStore: PageStore,
  parentId: string,
  beforeChildIndex: number,
): Promise<string> => {
  let customName: string | undefined;
  let customAddIndexToName: boolean | undefined;
  let customStyles: IStyles | undefined;
  let customSettings: Partial<IText['settings']> | undefined;

  // Use custom styles when a text element is inserted into a button container
  if (pageStore.elements[parentId].type === Constants.ELEMENT_TYPES.BUTTON_CONTAINER) {
    const buttonTextCustomisations = ELEMENT_CONFIGURATIONS[Constants.ELEMENT_TYPES.TEXT]?.customisations?.buttonText;
    if (buttonTextCustomisations) {
      customName = buttonTextCustomisations.getCustomName ? buttonTextCustomisations.getCustomName() : undefined;
      customAddIndexToName = buttonTextCustomisations.customAddIndexToName;
      customStyles = buttonTextCustomisations.customStyles;
      customSettings = buttonTextCustomisations.getCustomSettings ? buttonTextCustomisations.getCustomSettings() : undefined;
    }
  }

  const textId: string = await createStandardElement<IText>(
    pageStore,
    Constants.ELEMENT_TYPES.TEXT,
    parentId,
    beforeChildIndex,
    {
      customName,
      customAddIndexToName,
      customStyles,
      customSettings,
    },
  );

  return textId;
};

export const createButtonContainer = async (
  pageStore: PageStore,
  parentId: string,
  beforeChildIndex: number,
): Promise<string> => {
  const buttonContainerId: string = await createStandardElement<IButtonContainer>(
    pageStore,
    Constants.ELEMENT_TYPES.BUTTON_CONTAINER,
    parentId,
    beforeChildIndex,
  );

  // Insert text element into the newly created button container
  await createText(pageStore, buttonContainerId, 0);

  // Expand the newly created button container to show text element in the layers tree
  pageStore.toggleElement({ id: buttonContainerId });

  return buttonContainerId;
};

// *****************************************************************************************************************************
// *****************************************************************************************************************************
// Element Create Functions
// *****************************************************************************************************************************
// *****************************************************************************************************************************

export const ELEMENT_CREATE_FUNCTIONS: ElementCreateFunctions = {
  [Constants.ELEMENT_TYPES.BODY]: getCreateStandardElementFuncForType(Constants.ELEMENT_TYPES.BODY),
  [Constants.ELEMENT_TYPES.CONTAINER]: createContainer,
  [Constants.ELEMENT_TYPES.BUTTON_CONTAINER]: createButtonContainer,
  [Constants.ELEMENT_TYPES.TEXT]: createText,
  [Constants.ELEMENT_TYPES.IMAGE]: getCreateStandardElementFuncForType(Constants.ELEMENT_TYPES.IMAGE),
};
