/* eslint-disable @typescript-eslint/no-unsafe-argument */
import React from 'react';
import cx from 'classnames';

import LogicSettingsPanel from '../../organisms/LogicSettingsPanel';
import SettingsRowList from '../../organisms/SettingsRowList';
import StylePanel from '../../organisms/StylePanel';
import StyleSettingsHeader from '../../organisms/StyleSettingsHeader';

import styles from './StyleSettingsBlock.module.scss';
import usePresenter from './StyleSettingsBlock.presenter';
import getProps from './StyleSettingsBlock.props';
import { StyleSettingsBlockCombinedProps } from './types';
import SettingsPanel from '../../organisms/SettingsPanel';

const StyleSettingsBlock: React.FC<StyleSettingsBlockCombinedProps> = (props) => {
  const {
    state,
    className,
    classes,
    logicSettingsPanel,
    styleSettingsHeader,
    settingsPanel,
    stylePanel,
  } = usePresenter(props);

  const variantName = `${state}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`styleSettingsBlock${variantName}`];

  // MANUAL OVERRIDE START
  const componentView = (
    <>
      <div className={cx(currentStyle, className, classes?.logicPanelContainer)}>
        <LogicSettingsPanel
          className={cx(styles.logicSettingsPanel, classes?.logicSettingsPanel)}
          {...internalProps.logicSettingsPanel}
          {...logicSettingsPanel}/>
      </div>
      <div className={cx(currentStyle, className, classes?.settingPanelContainer)}>
        <StyleSettingsHeader
          className={cx(styles.styleSettingsHeader, classes?.styleSettingsHeader)}
          {...internalProps.styleSettingsHeader}
          {...styleSettingsHeader}/>
        <SettingsPanel
          className={cx(styles.settingsPanel, classes?.settingsPanel)}
          {...internalProps.settingsPanel}
          {...settingsPanel}/>
      </div>
      <div className={cx(currentStyle, className, classes?.stylePanelContainer)}>
        <StyleSettingsHeader
          className={cx(styles.styleSettingsHeader, classes?.styleSettingsHeader)}
          {...internalProps.styleSettingsHeader}
          {...styleSettingsHeader}/>
        <StylePanel
          className={cx(styles.stylePanel, classes?.stylePanel)}
          {...internalProps.stylePanel}
          {...stylePanel}/>
      </div>
    </>
  );
  // MANUAL OVERRIDE END

  return componentView;
};

export default StyleSettingsBlock;
