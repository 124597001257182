import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { typedDeepMerge } from '../../../utils/objectUtils';
import { FunctionBlockCombinedProps, FunctionBlockStateEnum, Methods, MethodsWithBody } from './types';
import { DropdownButtonColourEnum } from '../../atoms/DropdownButton/types';
import { methodColors } from '../../../constants';
import { useTabItemsState } from '../../../hooks';
import { ITabsData } from '../../../hooks/useTabItemsState';
import { validateGroupHeaderKeyInputEntry, validateGroupHeaderValueInputEntry } from '../../../utils/inputValidations';
import { errorTabState } from './constants';

const usePresenter = (props: FunctionBlockCombinedProps): FunctionBlockCombinedProps => {
  const { inputVariables,
    apiEndpointHeaders,
    setApiEndpointHeaders,
    apiEndpointParameters,
    setApiEndpointParameters,
    apiEndpointPath,
    setApiEndpointPath,
    apiEndpointMethod,
    setApiEndpointMethod,
    apiEndpointBody,
    setApiEndpointBody,
    hasHeadersError,
    hasParametersError } = props;
  const { t } = useTranslation();
  const [methodColor, setMethodColor] = useState<DropdownButtonColourEnum>('Green');
  const [tabs, setTabs] = useState<ITabsData<FunctionBlockStateEnum>[]>([]);

  const { selectedTab, tabItemList, setSelectedTab } = useTabItemsState<FunctionBlockStateEnum>({
    tabsData: tabs,
    defaultTab: 'Header',
  });

  const defaultTabs: ITabsData<FunctionBlockStateEnum>[] = useMemo(() => {
    const newTabs: ITabsData<FunctionBlockStateEnum>[] = [{
      tab: 'Header',
      label: t('functionBlock.headers'),
      ...(selectedTab !== 'Header' && hasHeadersError ? errorTabState : {}),
    },
    {
      tab: 'Parameter',
      label: t('functionBlock.parameters'),
      ...(selectedTab !== 'Parameter' && hasParametersError ? errorTabState : {}),
    }];
    setTabs(newTabs);
    return newTabs;
  }, [t, selectedTab, hasHeadersError, hasParametersError]);

  useEffect(() => {
    if (apiEndpointMethod) {
      setMethodColor(methodColors[apiEndpointMethod]);
      if (MethodsWithBody.includes(apiEndpointMethod)) {
        setTabs([
          ...defaultTabs,
          {
            tab: 'Body',
            label: t('functionBlock.body'),
          },
        ]);
        if (!apiEndpointBody && setApiEndpointBody) {
          setApiEndpointBody('{}');
        }
        return;
      }
    }

    setTabs(defaultTabs);
    setSelectedTab('Header');
    if (setApiEndpointBody) {
      setApiEndpointBody('{}');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiEndpointMethod, apiEndpointBody]);

  const isHeadersTab = selectedTab === 'Header';

  const onSelectedMethodChanged = (option: string) => {
    const method = option as Methods;
    if (setApiEndpointMethod) {
      setApiEndpointMethod(method);
    }
    setMethodColor(methodColors[method]);
  };

  const getDropdownItems = () => {
    return Object.keys(methodColors).map((method) => {
      return {
        text: {
          value: method,
        },
      };
    });
  };

  return typedDeepMerge(props, {
    state: selectedTab,
    text: {
      value: t('functionBlock.title'),
    },
    dropdownButton: {
      toggleProps: {
        type: 'Method',
        style: 'Default',
        colour: methodColor,
        text: {
          value: apiEndpointMethod,
        },
      },
      menuProps: {
        style: 'Default',
        dropdownItems: getDropdownItems(),
        onOptionSelected: onSelectedMethodChanged,
      },
    },
    tabItemList: {
      tabItems: tabItemList,
    },
    infoTestField: {
      label: {
        value: t('functionBlock.label'),
      },
      inputField: {
        textValue: apiEndpointPath,
        onTextChanged: setApiEndpointPath,
      },
    },
    dropdownButton1: {
      toggleProps: {
        type: 'Default',
        style: 'Default',
        colour: 'Default',
        state: 'Disabled',
        text: {
          value: t('functionBlock.bodyMethods.JSON'),
        },
        disabled: true,
      },
      menuProps: {
        style: 'Default',
        dropdownItems: [],
      },
    },
    codeSpace: {
      inputField: {
        textValue: apiEndpointBody,
        onTextChanged: setApiEndpointBody,
      },
      lineNumberList: {
        lineNumbers: [{
          text: {
            value: '1',
          },
        }],
      },
    },
    keyValueTable: {
      type: (isHeadersTab ? apiEndpointHeaders?.length : apiEndpointParameters?.length) ? 'Variable' : 'Default',
      keyValuePairs: isHeadersTab ? apiEndpointHeaders : apiEndpointParameters,
      hasKeyValueError: isHeadersTab ? hasHeadersError : hasParametersError,
      onKeyValuePairsChange: isHeadersTab ? setApiEndpointHeaders : setApiEndpointParameters,
      validateKeyInputEntry: isHeadersTab ? validateGroupHeaderKeyInputEntry : undefined,
      validateValueInputEntry: validateGroupHeaderValueInputEntry,
      keyLabel: {
        value: t('functionBlock.keyValueTable.keyLabel'),
      },
      valueLabel: {
        value: t('functionBlock.keyValueTable.valueLabel'),
      },
      button: {
        text: {
          value: isHeadersTab
            ? t('functionBlock.keyValueTable.addHeaders')
            : t('functionBlock.keyValueTable.addParameters'),
        },
      },
      inputVariables,
    },
  });
};

export default usePresenter;
