import React from 'react';
import cx from 'classnames';

import MainBlock from '../../blocks/MainBlock';
import StyleSettingsBlock from '../../blocks/StyleSettingsBlock';
import LogicBuilderPanel from '../../organisms/LogicBuilderPanel';

import styles from './UIBuilderPage.module.scss';
import usePresenter from './UIBuilderPage.presenter';
import getProps from './UIBuilderPage.props';
import { UIBuilderPageCombinedProps } from './types';

const UIBuilderPage: React.FC<UIBuilderPageCombinedProps> = (props) => {
  const {
    className,
    classes,
    mainBlock,
    logicBuilderPanel,
    styleSettingsBlock,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.uIBuilderPage;

  return (
    <div className={cx(currentStyle, className)}>
      <div className={cx(styles.mainContent, classes?.mainContent)}>
        <MainBlock
          className={cx(styles.mainBlock, classes?.mainBlock)}
          {...internalProps.mainBlock}
          {...mainBlock}/>
        <LogicBuilderPanel
          className={cx(styles.logicBuilderPanel, classes?.logicBuilderPanel)}
          {...internalProps.logicBuilderPanel}
          {...logicBuilderPanel}/>
      </div>
      <StyleSettingsBlock
        className={cx(styles.styleSettingsBlock, classes?.styleSettingsBlock)}
        {...internalProps.styleSettingsBlock}
        {...styleSettingsBlock}/>
    </div>
  );
};

export default UIBuilderPage;
