import React, {
  useCallback, useEffect, useMemo, useRef, useState, type CSSProperties,
} from 'react';
import cx from 'classnames';
import type { RenderModalBackdropProps } from 'react-overlays/cjs/Modal';
import styles from './ModalWrapper.module.scss';
import type { InternalModalWrapperProps, ModalWrapperProps } from './types';

const usePresenter = (props: ModalWrapperProps): InternalModalWrapperProps => {
  const { show, backdropClassName, customPosition } = props;

  const renderBackdropComponent = useCallback((renderModalBackdropProps: RenderModalBackdropProps) => {
    return <div {...renderModalBackdropProps} className={cx(styles.backdrop, backdropClassName)} />;
  }, [backdropClassName]);

  const modalRef: React.RefObject<HTMLDivElement> = useRef(null);
  const [cssProperties, setCssProperties] = useState<CSSProperties>({});

  useEffect(() => {
    if (show && modalRef.current) {
      let adjustedY = 0;
      if (customPosition) {
        const { height } = modalRef.current.getBoundingClientRect();
        const distanceToWindowBottomInPx: number = window.innerHeight - (customPosition.y + height);
        // Move the modal upwards if it doesn't fit at the bottom of the window
        if (distanceToWindowBottomInPx < 0) {
          // Y coordinate cannot be negative, otherwise the top of the modal will get hidden
          // distanceToWindowBottomInPx is negative here so use addition instead of subtraction
          adjustedY = Math.max(0, customPosition.y + distanceToWindowBottomInPx);
        } else {
          adjustedY = customPosition.y;
        }
      }
      switch (customPosition?.alignment) {
        case 'top-left': {
          setCssProperties({
            left: customPosition.x,
            top: adjustedY,
          });
          break;
        }
        case 'top-right': {
          setCssProperties({
            right: window.innerWidth - customPosition.x,
            top: adjustedY,
          });
          break;
        }
        default: {
          // By default position the modal in the centre of the screen
          setCssProperties({
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
          });
        }
      }
    }
  }, [show, customPosition]);

  return {
    ...props,
    modalRef,
    renderBackdropComponent,
    cssProperties,
  };
};

export default usePresenter;
