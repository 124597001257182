import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ExtendedTrans } from '../../../modules/locale/components/ExtendedTrans';
import { ExpandedVariableResponsesCombinedProps } from './types';
import styles from './ExpandedVariableResponses.module.scss';
import { typedDeepMerge } from '../../../utils/objectUtils';

const usePresenter = (props: ExpandedVariableResponsesCombinedProps): ExpandedVariableResponsesCombinedProps => {
  const { closeModal } = props;
  const { t } = useTranslation();
  const [isVariableSectionExpanded, setVariableSectionExpanded] = useState<boolean>(true);
  const [isResponsesSectionExpanded, setResponsesSectionExpanded] = useState<boolean>(true);

  const toggleVariableSection = () => {
    setVariableSectionExpanded(!isVariableSectionExpanded);
  };

  return {
    ...(typedDeepMerge(props, {
      menuHeader: {
        text: {
          value: t('ExpandedVariableResponses.header'),
        },
        button: {
          icon: {
            asset: 'Close',
          },
          onClick: closeModal,
        },
      },
      variableSection: {
        state: isVariableSectionExpanded ? 'Expanded' : 'Collapsed',
        expandedSectionHeader: {
          icon: {
            asset: 'Variable',
          },
          text: {
            value: t('ExpandedVariableResponses.variables'),
          },
          button: {
            icon: {
              asset: isVariableSectionExpanded ? 'ChevronDown' : 'ChevronRight',
            },
            onClick: toggleVariableSection,
          },
        },
        logicVariableTable: {
          scope: {
            value: t('ExpandedVariableResponses.table.scope'),
          },
          name: {
            value: t('ExpandedVariableResponses.table.name'),
          },
          response: {
            value: t('ExpandedVariableResponses.table.response'),
          },
          type: {
            value: t('ExpandedVariableResponses.table.type'),
          },
          defaultValue: {
            value: t('ExpandedVariableResponses.table.initialValue'),
          },
        },
      },
      responseSection: {
        state: isResponsesSectionExpanded ? 'Expanded' : 'Collapsed',
        expandedSectionHeader: {
          text: {
            value: t('ExpandedVariableResponses.responses'),
          },
          button: {
            icon: {
              asset: isResponsesSectionExpanded ? 'ChevronDown' : 'ChevronRight',
            },
            onClick: () => setResponsesSectionExpanded(!isResponsesSectionExpanded),
          },
        },
        text: {
          value: ExtendedTrans('ExpandedVariableResponses.responseText', {}, {
            span: <span className={styles.customText} />,
          }),
        },
      },
    })),
  };
};

export default usePresenter;
