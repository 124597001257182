import React from 'react';
import cx from 'classnames';

import styles from './Icon.module.scss';
import usePresenter from './Icon.presenter';
import getProps from './Icon.props';
import { IconCombinedProps } from './types';

import { ReactComponent as ActionIcon } from '../../../resources/icons/Action.svg';
import { ReactComponent as AddIcon } from '../../../resources/icons/Add.svg';
import { ReactComponent as AddHomeIcon } from '../../../resources/icons/AddHome.svg';
import { ReactComponent as AddVariableIcon } from '../../../resources/icons/AddVariable.svg';
import { ReactComponent as ApiEndpointIcon } from '../../../resources/icons/ApiEndpoint.svg';
import { ReactComponent as ApiGroupIcon } from '../../../resources/icons/ApiGroup.svg';
import { ReactComponent as ArrowDownIcon } from '../../../resources/icons/ArrowDown.svg';
import { ReactComponent as ArrowLeftIcon } from '../../../resources/icons/ArrowLeft.svg';
import { ReactComponent as ArrowOutwardsRightIcon } from '../../../resources/icons/ArrowOutwardsRight.svg';
import { ReactComponent as ArrowRightIcon } from '../../../resources/icons/ArrowRight.svg';
import { ReactComponent as ArrowUpIcon } from '../../../resources/icons/ArrowUp.svg';
import { ReactComponent as AutoCursorIcon } from '../../../resources/icons/AutoCursor.svg';
import { ReactComponent as BodyIcon } from '../../../resources/icons/Body.svg';
import { ReactComponent as BoldIcon } from '../../../resources/icons/Bold.svg';
import { ReactComponent as BorderIcon } from '../../../resources/icons/Border.svg';
import { ReactComponent as BottomLeftCornerIcon } from '../../../resources/icons/BottomLeftCorner.svg';
import { ReactComponent as BottomRightCornerIcon } from '../../../resources/icons/BottomRightCorner.svg';
import { ReactComponent as ButtonIcon } from '../../../resources/icons/Button.svg';
import { ReactComponent as CenterAlignIcon } from '../../../resources/icons/CenterAlign.svg';
import { ReactComponent as CheckIcon } from '../../../resources/icons/Check.svg';
import { ReactComponent as CheckmarkCircleIcon } from '../../../resources/icons/CheckmarkCircle.svg';
import { ReactComponent as ChevronDownIcon } from '../../../resources/icons/ChevronDown.svg';
import { ReactComponent as ChevronRightIcon } from '../../../resources/icons/ChevronRight.svg';
import { ReactComponent as ChevronUpIcon } from '../../../resources/icons/ChevronUp.svg';
import { ReactComponent as ClearFormatIcon } from '../../../resources/icons/ClearFormat.svg';
import { ReactComponent as CloseIcon } from '../../../resources/icons/Close.svg';
import { ReactComponent as ClosedHandIcon } from '../../../resources/icons/ClosedHand.svg';
import { ReactComponent as CollectionItemIcon } from '../../../resources/icons/CollectionItem.svg';
import { ReactComponent as CollectionListIcon } from '../../../resources/icons/CollectionList.svg';
import { ReactComponent as ContainerIcon } from '../../../resources/icons/Container.svg';
import { ReactComponent as CopyIcon } from '../../../resources/icons/Copy.svg';
import { ReactComponent as DashedBorderIcon } from '../../../resources/icons/DashedBorder.svg';
import { ReactComponent as EditIcon } from '../../../resources/icons/Edit.svg';
import { ReactComponent as ErrorIcon } from '../../../resources/icons/Error.svg';
import { ReactComponent as ErrorDotIcon } from '../../../resources/icons/ErrorDot.svg';
import { ReactComponent as ExpandIcon } from '../../../resources/icons/Expand.svg';
import { ReactComponent as FlowVariableIcon } from '../../../resources/icons/FlowVariable.svg';
import { ReactComponent as GlobalIcon } from '../../../resources/icons/Global.svg';
import { ReactComponent as HiddenIcon } from '../../../resources/icons/Hidden.svg';
import { ReactComponent as HomeIcon } from '../../../resources/icons/Home.svg';
import { ReactComponent as ImageIcon } from '../../../resources/icons/Image.svg';
import { ReactComponent as ItalicIcon } from '../../../resources/icons/Italic.svg';
import { ReactComponent as JustifyIcon } from '../../../resources/icons/Justify.svg';
import { ReactComponent as LaptopIcon } from '../../../resources/icons/Laptop.svg';
import { ReactComponent as LaptopDefaultIcon } from '../../../resources/icons/LaptopDefault.svg';
import { ReactComponent as LayersIcon } from '../../../resources/icons/Layers.svg';
import { ReactComponent as LeftAlignIcon } from '../../../resources/icons/LeftAlign.svg';
import { ReactComponent as LetterSpaceIcon } from '../../../resources/icons/LetterSpace.svg';
import { ReactComponent as LineHeightIcon } from '../../../resources/icons/LineHeight.svg';
import { ReactComponent as MobileIcon } from '../../../resources/icons/Mobile.svg';
import { ReactComponent as MoreVertIcon } from '../../../resources/icons/MoreVert.svg';
import { ReactComponent as NullIcon } from '../../../resources/icons/Null.svg';
import { ReactComponent as OpenHandIcon } from '../../../resources/icons/OpenHand.svg';
import { ReactComponent as OverlineIcon } from '../../../resources/icons/Overline.svg';
import { ReactComponent as PageIcon } from '../../../resources/icons/Page.svg';
import { ReactComponent as PointerIcon } from '../../../resources/icons/Pointer.svg';
import { ReactComponent as RedoIcon } from '../../../resources/icons/Redo.svg';
import { ReactComponent as RightAlignIcon } from '../../../resources/icons/RightAlign.svg';
import { ReactComponent as RomanIcon } from '../../../resources/icons/Roman.svg';
import { ReactComponent as SeperateCornersIcon } from '../../../resources/icons/SeperateCorners.svg';
import { ReactComponent as StrikethroughIcon } from '../../../resources/icons/Strikethrough.svg';
import { ReactComponent as TabletIcon } from '../../../resources/icons/Tablet.svg';
import { ReactComponent as TextIcon } from '../../../resources/icons/Text.svg';
import { ReactComponent as TopLeftCornerIcon } from '../../../resources/icons/TopLeftCorner.svg';
import { ReactComponent as TopRightCornerIcon } from '../../../resources/icons/TopRightCorner.svg';
import { ReactComponent as UnderlineIcon } from '../../../resources/icons/Underline.svg';
import { ReactComponent as UndoIcon } from '../../../resources/icons/Undo.svg';
import { ReactComponent as UnfoldMoreIcon } from '../../../resources/icons/UnfoldMore.svg';
import { ReactComponent as UnionCornersIcon } from '../../../resources/icons/UnionCorners.svg';
import { ReactComponent as VariableIcon } from '../../../resources/icons/Variable.svg';
import { ReactComponent as VisibilityIcon } from '../../../resources/icons/Visibility.svg';

const Icon: React.FC<IconCombinedProps> = (props) => {
  const {
    asset,
    colour,
    className,
    classes,
    assetAlt,
  } = usePresenter(props);

  const variantName = `${asset}${colour}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`icon${variantName}`];

  let componentView;

  switch (variantName) {
    case 'ActionNeturalHoverSelected': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <ActionIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'ErrorNeturalHoverSelected': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <ErrorIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'AddNeturalHoverSelected':
    case 'AddNeutralDefault': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <AddIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'AddHomeNeturalHoverSelected':
    case 'AddHomeNeutralDefault': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <AddHomeIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'AddVariableNeturalHoverSelected':
    case 'AddVariableNeutralDefault': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <AddVariableIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'ApiEndpointNeturalHoverSelected':
    case 'ApiEndpointNeutralDefault': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <ApiEndpointIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'ApiGroupNeturalHoverSelected':
    case 'ApiGroupNeutralDefault': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <ApiGroupIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'ArrowDownNeturalHoverSelected':
    case 'ArrowDownNeutralDefault': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <ArrowDownIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'ArrowLeftNeturalHoverSelected':
    case 'ArrowLeftNeutralDefault': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <ArrowLeftIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'ArrowOutwardsRightNeturalHoverSelected': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <ArrowOutwardsRightIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'ArrowRightNeturalHoverSelected':
    case 'ArrowRightNeutralDefault': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <ArrowRightIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'ArrowUpNeturalHoverSelected':
    case 'ArrowUpNeutralDefault': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <ArrowUpIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'BodyNeturalHoverSelected':
    case 'BodyNeutralDefault': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <BodyIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'BoldNeturalHoverSelected':
    case 'BoldNeutralDefault': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <BoldIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'BorderNeturalHoverSelected':
    case 'BorderNeutralDefault': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <BorderIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'BottomLeftCornerNeturalHoverSelected': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <BottomLeftCornerIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'BottomRightCornerNeturalHoverSelected': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <BottomRightCornerIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'ButtonNeturalHoverSelected':
    case 'ButtonNeutralDefault': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <ButtonIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'CenterAlignNeturalHoverSelected':
    case 'CenterAlignNeutralDefault': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <CenterAlignIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'CheckNeturalHoverSelected':
    case 'CheckNeutralDefault': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <CheckIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'ChevronDownNeturalHoverSelected':
    case 'ChevronDownNeutralDefault': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <ChevronDownIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'ChevronRightNeturalHoverSelected':
    case 'ChevronRightNeutralDefault': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <ChevronRightIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'ChevronUpNeturalHoverSelected':
    case 'ChevronUpNeutralDefault': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <ChevronUpIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'ClearFormatNeturalHoverSelected':
    case 'ClearFormatNeutralDefault': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <ClearFormatIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'CloseNeturalHoverSelected':
    case 'CloseNeutralDefault': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <CloseIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'ContainerNeturalHoverSelected':
    case 'ContainerNeutralDefault': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <ContainerIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'CopyNeturalHoverSelected':
    case 'CopyNeutralDefault': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <CopyIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'DashedBorderNeturalHoverSelected':
    case 'DashedBorderNeutralDefault': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <DashedBorderIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'EditNeturalHoverSelected':
    case 'EditNeutralDefault': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <EditIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'ExpandNeturalHoverSelected':
    case 'ExpandNeutralDefault': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <ExpandIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'GlobalVariableSelected': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <GlobalIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'HiddenNeturalHoverSelected':
    case 'HiddenNeutralDefault': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <HiddenIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'HomeNeturalHoverSelected':
    case 'HomeNeutralDefault': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <HomeIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'ImageNeturalHoverSelected':
    case 'ImageNeutralDefault': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <ImageIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'ItalicNeturalHoverSelected':
    case 'ItalicNeutralDefault': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <ItalicIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'JustifyNeturalHoverSelected':
    case 'JustifyNeutralDefault': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <JustifyIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'LayersNeturalHoverSelected':
    case 'LayersNeutralDefault': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <LayersIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'LeftAlignNeturalHoverSelected':
    case 'LeftAlignNeutralDefault': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <LeftAlignIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'LetterSpaceNeturalHoverSelected': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <LetterSpaceIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'LineHeightNeturalHoverSelected': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <LineHeightIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'MobileNeturalHoverSelected':
    case 'MobileNeutralDefault': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <MobileIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'MoreVertNeturalHoverSelected':
    case 'MoreVertNeutralDefault': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <MoreVertIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'NullNeturalHoverSelected':
    case 'NullNeutralDefault': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <NullIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'OverlineNeturalHoverSelected':
    case 'OverlineNeutralDefault': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <OverlineIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'PageVariableSelected': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <PageIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'RedoNeturalHoverSelected':
    case 'RedoNeutralDefault': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <RedoIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'RightAlignNeturalHoverSelected':
    case 'RightAlignNeutralDefault': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <RightAlignIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'RomanNeturalHoverSelected':
    case 'RomanNeutralDefault': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <RomanIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'SeperateCornersNeturalHoverSelected':
    case 'SeperateCornersNeutralDefault': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <SeperateCornersIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'StrikethroughNeturalHoverSelected':
    case 'StrikethroughNeutralDefault': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <StrikethroughIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'TabletNeturalHoverSelected':
    case 'TabletNeutralDefault': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <TabletIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'TextNeturalHoverSelected':
    case 'TextNeutralDefault': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <TextIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'TopLeftCornerNeturalHoverSelected': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <TopLeftCornerIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'TopRightCornerNeturalHoverSelected': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <TopRightCornerIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'UnderlineNeturalHoverSelected':
    case 'UnderlineNeutralDefault': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <UnderlineIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'UndoNeutralDefault': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <UndoIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'UnfoldMoreNeturalHoverSelected':
    case 'UnfoldMoreNeutralDefault': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <UnfoldMoreIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'UnionCornersNeturalHoverSelected':
    case 'UnionCornersNeutralDefault': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <UnionCornersIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'VisibilityNeturalHoverSelected':
    case 'VisibilityNeutralDefault': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <VisibilityIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'AutoCursorCursor': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <AutoCursorIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'CheckmarkCircleNeutralDefault': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <CheckmarkCircleIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'ClosedHandCursor': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <ClosedHandIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'OpenHandNeturalHoverSelected': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <OpenHandIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'PointerCursor': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <PointerIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'CollectionItemVariableSelected': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <CollectionItemIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'CollectionListNeturalHoverSelected':
    case 'CollectionListNeutralDefault': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <CollectionListIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'ErrorDotNeturalHoverSelected': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <ErrorDotIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'FlowVariableVariableSelected': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <FlowVariableIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'LaptopNeturalHoverSelected':
    case 'LaptopNeutralDefault': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <LaptopIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'LaptopDefaultNeturalHoverSelected':
    case 'LaptopDefaultNeutralDefault': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <LaptopDefaultIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'UndoNeturalHoverSelected': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <UndoIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    case 'VariableNeturalHoverSelected':
    case 'VariableNeutralDefault':
    case 'VariableVariableSelected': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <VariableIcon style={{ width: '100%', height: '100%' }}/>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default Icon;
