import React from 'react';
import cx from 'classnames';

import LineNumber from '../../atoms/LineNumber';

import styles from './LineNumberList.module.scss';
import usePresenter from './LineNumberList.presenter';
import getProps from './LineNumberList.props';
import { LineNumberListCombinedProps } from './types';

const LineNumberList: React.FC<LineNumberListCombinedProps> = (props) => {
  const {
    className,
    classes,
    lineNumbers,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.lineNumberList;

  const lineNumberViews = lineNumbers?.map((lineNumber, index) => (
    <LineNumber
      key={index}
      className={cx(styles.lineNumber, classes?.lineNumber)}
      {...internalProps.lineNumber}
      {...lineNumber}
    />
  )) || [];

  return (
    <div className={cx(currentStyle, className)}>
      {lineNumberViews}
    </div>
  );
};

export default LineNumberList;
