import cx from 'classnames';
import React from 'react';
import { Constants } from '../../../../constants';
import styles from './DropZone.module.scss';
import usePresenter from './DropZone.presenter';
import type { DropZoneCombinedProps } from './types';

const DropZone: React.FC<DropZoneCombinedProps> = (props) => {
  const {
    parentId,
    dropZoneIndex,
    variantName,
    className,
    classes,
    cssProps,
  } = usePresenter(props);

  const currentStyle: string = styles[`dropZone${variantName}`];

  return (
    <div
      className={cx(currentStyle, className)}
      style={cssProps}
      {...{ [Constants.ATTRIBUTES.ELEMENT_ID]: parentId }}
      {...{ [Constants.ATTRIBUTES.DROP_ZONE_ELEMENT_ID]: parentId }}
      {...{ [Constants.ATTRIBUTES.DROP_ZONE_INDEX]: dropZoneIndex }}
    >
      <div className={cx(styles.innerDropZone, classes?.innerDropZone)}> </div>
    </div>
  );
};

export default DropZone;
