import { VariableLayerItemProps } from '../VariableLayerItem';

type VariableLayerItemListInternalProps = {
  variableLayerItem?: VariableLayerItemProps;
};

type VariableLayerItemListPropsValues = {
  '': VariableLayerItemListInternalProps;
};

const propValues: VariableLayerItemListPropsValues = {
  '': {
    variableLayerItem: {
      state: 'Default',
      type: 'Default',
    },
  },
};

const getProps = (type: string): VariableLayerItemListInternalProps => {
  const values: VariableLayerItemListInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
