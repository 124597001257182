import { TextCardProps } from '../TextCard';

type TextCardListInternalProps = {
  textCard?: TextCardProps;
};

type TextCardListPropsValues = {
  '': TextCardListInternalProps;
};

const propValues: TextCardListPropsValues = {
  '': {
    textCard: {
      states: 'Default',
    },
  },
};

const getProps = (type: string): TextCardListInternalProps => {
  const values: TextCardListInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
