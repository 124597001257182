import React from 'react';
import cx from 'classnames';

import ColourRow from '../../molecules/ColourRow';
import PanelHeader from '../../molecules/PanelHeader';
import ReplaceRow from '../../molecules/ReplaceRow';

import styles from './BackgroundPanel.module.scss';
import usePresenter from './BackgroundPanel.presenter';
import getProps from './BackgroundPanel.props';
import type { BackgroundPanelCombinedProps } from './types';

const BackgroundPanel: React.FC<BackgroundPanelCombinedProps> = (props) => {
  const {
    state,
    className,
    classes,
    panelHeader,
    replaceRow,
    colourRow,
    // MANUAL OVERRIDE STARTS
    renderPanel,
    addImageContainerRef,
    // MANUAL OVERRIDE ENDS
  } = usePresenter(props);

  // MANUAL OVERRIDE STARTS
  if (!renderPanel) {
    return null;
  }
  // MANUAL OVERRIDE ENDS

  const variantName = `${state}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`backgroundPanel${variantName}`];

  let componentView;

  switch (variantName) {
    case 'Collapsed': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <PanelHeader
            className={cx(styles.panelHeader, classes?.panelHeader)}
            {...internalProps.panelHeader}
            {...panelHeader}
          />
        </div>
      );
      break;
    }
    case 'Expanded': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <PanelHeader
            className={cx(styles.panelHeader, classes?.panelHeader)}
            {...internalProps.panelHeader}
            {...panelHeader}
          />
          {/* // MANUAL OVERRIDE: Adding ref prop */}
          <div ref={addImageContainerRef} className={cx(styles.backgroundContent, classes?.backgroundContent)}>
            <ReplaceRow
              className={cx(styles.replaceRow, classes?.replaceRow)}
              {...internalProps.replaceRow}
              {...replaceRow}
            />
            <ColourRow
              className={cx(styles.colourRow, classes?.colourRow)}
              {...internalProps.colourRow}
              {...colourRow}
            />
          </div>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default BackgroundPanel;
