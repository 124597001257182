import React from 'react';
import cx from 'classnames';

import styles from './Text.module.scss';
import usePresenter from './Text.presenter';
import getProps from './Text.props';
import { TextCombinedProps } from './types';

const Text: React.FC<TextCombinedProps> = (props) => {
  const {
    type,
    align,
    style,
    colour,
    className,
    classes,
    value,
  } = usePresenter(props);

  const variantName = `${type}${align}${style}${colour}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`text${variantName}`];

  let componentView;

  switch (variantName) {
    case 'CodespaceMediumLeftMedium500NeutralDefault':
    case 'ExtraSmallCenterMedium500NeutralHoverSelected':
    case 'ExtraSmallCenterRegular400NeutralDefault':
    case 'ExtraSmallCenterRegular400NeutralHoverSelected':
    case 'ExtraSmallLeftRegular400ActionDefault':
    case 'LabelExtraSmallLeftItalic400NeutralDefault':
    case 'LabelExtraSmallLeftItalic400NeutralHoverSelected':
    case 'LabelExtraSmallLeftMedium500NeutralDefault':
    case 'LabelExtraSmallLeftMedium500NeutralHoverSelected':
    case 'LabelExtraSmallLeftRegular400NeutralDefault':
    case 'LabelExtraSmallLeftRegular400NeutralHoverSelected':
    case 'LabelExtraSmallRightRegular400NeutralDefault':
    case 'LabelExtraSmallRightRegular400NeutralHoverSelected':
    case 'LabelLargeCenterMedium500NeutralHoverSelected':
    case 'LabelLargeCenterSemibold600NeutralHoverSelected':
    case 'LabelLargeLeftMedium500NeutralDefault':
    case 'LabelLargeLeftMedium500NeutralHoverSelected':
    case 'LabelLargeLeftRegular400NeutralDefault':
    case 'LabelMediumCenterRegular400NeutralDefault':
    case 'LabelMediumCenterRegular400NeutralHoverSelected':
    case 'LabelMediumLeftMedium500NeutralDefault':
    case 'LabelMediumLeftMedium500NeutralHoverSelected':
    case 'LabelMediumLeftRegular400NeutralDefault':
    case 'LabelMediumLeftRegular400NeutralHoverSelected':
    case 'LabelMediumLeftSemibold600NeutralHoverSelected':
    case 'LabelMediumRightRegular400NeutralHoverSelected':
    case 'LabelSmallCenterMedium500DestructiveSelected':
    case 'LabelSmallCenterMedium500NeutralDefault':
    case 'LabelSmallCenterMedium500NeutralHoverSelected':
    case 'LabelSmallCenterMedium500VariableSelected':
    case 'LabelSmallCenterRegular400NeutralDefault':
    case 'LabelSmallCenterRegular400NeutralHoverSelected':
    case 'LabelSmallLeftItalic400NeutralDefault':
    case 'LabelSmallLeftItalic400NeutralHoverSelected':
    case 'LabelSmallLeftItalic500NeutralDefault':
    case 'LabelSmallLeftItalic500NeutralSubdued':
    case 'LabelSmallLeftMedium500DestructiveSelected':
    case 'LabelSmallLeftMedium500NeutralDefault':
    case 'LabelSmallLeftMedium500NeutralHoverSelected':
    case 'LabelSmallLeftMedium500VariableSelected':
    case 'LabelSmallLeftRegular400ActionDefault':
    case 'LabelSmallLeftRegular400NeutralDefault':
    case 'LabelSmallLeftRegular400NeutralHoverSelected':
    case 'LabelSmallLeftRegular400VariableSelected':
    case 'LabelSmallLeftSemibold600NeutralHoverSelected':
    case 'LabelSmallRightMedium500DestructiveSelected':
    case 'LabelSmallRightMedium500NeutralDefault':
    case 'LabelSmallRightMedium500SuccessSelected':
    case 'XXLLeftSemibold600NeutralHoverSelected': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <p className={cx(styles.value, classes?.value)}>
            {value}
          </p>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default Text;
