import React from 'react';
import usePresenter from './PreviewTextElement.presenter';
import type { PreviewTextElementProps } from './types';

const PreviewTextElement: React.FC<PreviewTextElementProps> = (props) => {
  const { element, style, utils, ...otherProps } = usePresenter(props);

  return (
    <div
      style={style}
      {...otherProps}
    >
      {utils?.getTextValue?.(element)}
    </div>
  );
};

export default PreviewTextElement;
