import React from 'react';
import { Constants } from '../../../../constants';
import styles from './ElementResizer.module.scss';
import usePresenter from './ElementResizer.presenter';
import type { ElementResizerProps } from './types';

const ElementResizer: React.FC<ElementResizerProps> = (props) => {
  const { isResizeable, resizeHandleProps } = usePresenter(props);

  return isResizeable && resizeHandleProps
    ? (
      <div
        className={styles.resizeHandle}
        {...{ [Constants.ATTRIBUTES.RESIZE_HANDLE]: true }}
        {...resizeHandleProps()}
      />
    )
    : null;
};

export default ElementResizer;
