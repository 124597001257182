import React from 'react';
import ModalWrapper from '.';
import { BaseModalProps, ExportedModalProps } from './types';

// HOC that applies the ModalWrapper to the modal component
export default function withModalWrapper<ModalPropsType extends BaseModalProps>(
  InternalModalComponent: React.FC<ModalPropsType>,
): React.FC<ExportedModalProps<ModalPropsType>> {
  const ModalWrapperInstance: React.FC<ExportedModalProps<ModalPropsType>> = (
    { show, onHide, customPosition, className, backdropClassName, modalProps },
  ) => {
    return (
      <ModalWrapper
        show={show}
        onHide={onHide}
        customPosition={customPosition}
        className={className}
        backdropClassName={backdropClassName}
        content={<InternalModalComponent {...modalProps as any} />}
      />
    );
  };
  return ModalWrapperInstance;
}
