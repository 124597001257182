import { ButtonProps } from '../Button';

type GrabIconButtonInternalProps = {
  button?: ButtonProps;
};

type GrabIconButtonPropsValues = {
  '': GrabIconButtonInternalProps;
};

const propValues: GrabIconButtonPropsValues = {
  '': {
    button: {
      type: 'IconClear',
      size: 'M',
      state: 'Default',
      style: 'PrimarySubdued',
    },
  },
};

const getProps = (type: string): GrabIconButtonInternalProps => {
  const values: GrabIconButtonInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
