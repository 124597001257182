import React from 'react';
import cx from 'classnames';

import Button from '../../atoms/Button';
import Divider from '../../atoms/Divider';
import InputField from '../../atoms/InputField';
import Label from '../../atoms/Label';
import MenuHeader from '../../molecules/MenuHeader';

import styles from './ConfigureGroupMenu.module.scss';
import usePresenter from './ConfigureGroupMenu.presenter';
import getProps from './ConfigureGroupMenu.props';
import { ConfigureGroupMenuCombinedProps } from './types';

const ConfigureGroupMenu: React.FC<ConfigureGroupMenuCombinedProps> = (props) => {
  const {
    state,
    className,
    classes,
    menuHeader,
    divider,
    label,
    inputField,
    label1,
    inputField1,
    secondaryButton,
    primaryButton,
    button,
  } = usePresenter(props);

  const variantName = `${state}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`configureGroupMenu${variantName}`];

  let componentView;

  switch (variantName) {
    case 'Default': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <MenuHeader
            className={cx(styles.menuHeader, classes?.menuHeader)}
            {...internalProps.menuHeader}
            {...menuHeader}
          />
          <Divider
            className={cx(styles.divider, classes?.divider)}
            {...internalProps.divider}
            {...divider}
          />
          <div className={cx(styles.menuContent, classes?.menuContent)}>
            <div className={cx(styles.nameContent, classes?.nameContent)}>
              <Label
                className={cx(styles.label, classes?.label)}
                {...internalProps.label}
                {...label}
              />
              <InputField
                className={cx(styles.inputField, classes?.inputField)}
                {...internalProps.inputField}
                {...inputField}
              />
            </div>
            <div className={cx(styles.baseURLContent, classes?.baseURLContent)}>
              <Label
                className={cx(styles.label1, classes?.label1)}
                {...internalProps.label1}
                {...label1}
              />
              <InputField
                className={cx(styles.inputField1, classes?.inputField1)}
                {...internalProps.inputField1}
                {...inputField1}
              />
            </div>
            <div className={cx(styles.buttonContent, classes?.buttonContent)}>
              <div className={cx(styles.buttonGroup, classes?.buttonGroup)}>
                <Button
                  className={cx(styles.secondaryButton, classes?.secondaryButton)}
                  {...internalProps.secondaryButton}
                  {...secondaryButton}
                />
                <Button
                  className={cx(styles.primaryButton, classes?.primaryButton)}
                  {...internalProps.primaryButton}
                  {...primaryButton}
                />
              </div>
            </div>
          </div>
        </div>
      );
      break;
    }
    case 'Removeable': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <MenuHeader
            className={cx(styles.menuHeader, classes?.menuHeader)}
            {...internalProps.menuHeader}
            {...menuHeader}
          />
          <Divider
            className={cx(styles.divider, classes?.divider)}
            {...internalProps.divider}
            {...divider}
          />
          <div className={cx(styles.menuContent, classes?.menuContent)}>
            <div className={cx(styles.nameContent, classes?.nameContent)}>
              <Label
                className={cx(styles.label, classes?.label)}
                {...internalProps.label}
                {...label}
              />
              <InputField
                className={cx(styles.inputField, classes?.inputField)}
                {...internalProps.inputField}
                {...inputField}
              />
            </div>
            <div className={cx(styles.baseURLContent, classes?.baseURLContent)}>
              <Label
                className={cx(styles.label1, classes?.label1)}
                {...internalProps.label1}
                {...label1}
              />
              <InputField
                className={cx(styles.inputField1, classes?.inputField1)}
                {...internalProps.inputField1}
                {...inputField1}
              />
            </div>
            <div className={cx(styles.buttonContent, classes?.buttonContent)}>
              <Button
                className={cx(styles.button, classes?.button)}
                {...internalProps.button}
                {...button}
              />
              <div className={cx(styles.buttonGroup, classes?.buttonGroup)}>
                <Button
                  className={cx(styles.secondaryButton, classes?.secondaryButton)}
                  {...internalProps.secondaryButton}
                  {...secondaryButton}
                />
                <Button
                  className={cx(styles.primaryButton, classes?.primaryButton)}
                  {...internalProps.primaryButton}
                  {...primaryButton}
                />
              </div>
            </div>
          </div>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default ConfigureGroupMenu;
