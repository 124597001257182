import React from 'react';
import cx from 'classnames';

import DropdownButton from '../../atoms/DropdownButton';
import EmptyStateInfo from '../../atoms/EmptyStateInfo';
import ReplaceButton from '../../atoms/ReplaceButton';
import RowLabel from '../../atoms/RowLabel';
import PanelHeader from '../../molecules/PanelHeader';
import ReplaceRow from '../../molecules/ReplaceRow';
import UpdateValue from '../../molecules/UpdateValue';

import styles from './ActionPanel.module.scss';
import usePresenter from './ActionPanel.presenter';
import getProps from './ActionPanel.props';
import { ActionPanelCombinedProps } from './types';

const ActionPanel: React.FC<ActionPanelCombinedProps> = (props) => {
  const {
    type,
    state,
    className,
    classes,
    panelHeader,
    rowLabel,
    dropdownButton,
    rowLabel1,
    dropdownButton1,
    rowLabel2,
    dropdownButton2,
    emptyStateInfo,
    rowLabel3,
    dropdownButton3,
    replaceRow,
    replaceButton,
    updateValue,
    setVariableMenuContainerRef, // MANUAL OVERRIDE
  } = usePresenter(props);

  const variantName = `${type}${state}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`actionPanel${variantName}`];

  let componentView;

  switch (variantName) {
    case 'CallAPIExpanded': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <PanelHeader
            className={cx(styles.panelHeader, classes?.panelHeader)}
            {...internalProps.panelHeader}
            {...panelHeader}/>
          <div className={cx(styles.panelContent, classes?.panelContent)}>
            <div className={cx(styles.actionContent, classes?.actionContent)}>
              <RowLabel
                className={cx(styles.rowLabel, classes?.rowLabel)}
                {...internalProps.rowLabel}
                {...rowLabel}/>
              <DropdownButton
                className={cx(styles.dropdownButton, classes?.dropdownButton)}
                {...internalProps.dropdownButton}
                {...dropdownButton}/>
            </div>
            <div className={cx(styles.groupContent, classes?.groupContent)}>
              <RowLabel
                className={cx(styles.rowLabel1, classes?.rowLabel1)}
                {...internalProps.rowLabel1}
                {...rowLabel1}/>
              <DropdownButton
                className={cx(styles.dropdownButton1, classes?.dropdownButton1)}
                {...internalProps.dropdownButton1}
                {...dropdownButton1}/>
            </div>
            <div className={cx(styles.callFunctionContent, classes?.callFunctionContent)}>
              <RowLabel
                className={cx(styles.rowLabel2, classes?.rowLabel2)}
                {...internalProps.rowLabel2}
                {...rowLabel2}/>
              <DropdownButton
                className={cx(styles.dropdownButton2, classes?.dropdownButton2)}
                {...internalProps.dropdownButton2}
                {...dropdownButton2}/>
            </div>
          </div>
        </div>
      );
      break;
    }
    case 'EmptyCollapsed': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <PanelHeader
            className={cx(styles.panelHeader, classes?.panelHeader)}
            {...internalProps.panelHeader}
            {...panelHeader}/>
        </div>
      );
      break;
    }
    case 'EmptyStateDefault': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <EmptyStateInfo
            className={cx(styles.emptyStateInfo, classes?.emptyStateInfo)}
            {...internalProps.emptyStateInfo}
            {...emptyStateInfo}/>
        </div>
      );
      break;
    }
    case 'NagivatePageExpanded': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <PanelHeader
            className={cx(styles.panelHeader, classes?.panelHeader)}
            {...internalProps.panelHeader}
            {...panelHeader}/>
          <div className={cx(styles.panelContent, classes?.panelContent)}>
            <div className={cx(styles.actionContent, classes?.actionContent)}>
              <RowLabel
                className={cx(styles.rowLabel, classes?.rowLabel)}
                {...internalProps.rowLabel}
                {...rowLabel}/>
              <DropdownButton
                className={cx(styles.dropdownButton, classes?.dropdownButton)}
                {...internalProps.dropdownButton}
                {...dropdownButton}/>
            </div>
            <div className={cx(styles.groupContent, classes?.groupContent)}>
              <RowLabel
                className={cx(styles.rowLabel1, classes?.rowLabel1)}
                {...internalProps.rowLabel1}
                {...rowLabel1}/>
              <DropdownButton
                className={cx(styles.dropdownButton1, classes?.dropdownButton1)}
                {...internalProps.dropdownButton1}
                {...dropdownButton1}/>
            </div>
            <div className={cx(styles.pageContent, classes?.pageContent)}>
              <RowLabel
                className={cx(styles.rowLabel2, classes?.rowLabel2)}
                {...internalProps.rowLabel2}
                {...rowLabel2}/>
              <DropdownButton
                className={cx(styles.dropdownButton2, classes?.dropdownButton2)}
                {...internalProps.dropdownButton2}
                {...dropdownButton2}/>
            </div>
            <div className={cx(styles.callFunctionContent, classes?.callFunctionContent)}>
              <RowLabel
                className={cx(styles.rowLabel3, classes?.rowLabel3)}
                {...internalProps.rowLabel3}
                {...rowLabel3}/>
              <DropdownButton
                className={cx(styles.dropdownButton3, classes?.dropdownButton3)}
                {...internalProps.dropdownButton3}
                {...dropdownButton3}/>
            </div>
          </div>
        </div>
      );
      break;
    }
    case 'NavigateDefaultExpanded': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <PanelHeader
            className={cx(styles.panelHeader, classes?.panelHeader)}
            {...internalProps.panelHeader}
            {...panelHeader}/>
          <div className={cx(styles.panelContent, classes?.panelContent)}>
            <div className={cx(styles.actionContent, classes?.actionContent)}>
              <RowLabel
                className={cx(styles.rowLabel, classes?.rowLabel)}
                {...internalProps.rowLabel}
                {...rowLabel}/>
              <DropdownButton
                className={cx(styles.dropdownButton, classes?.dropdownButton)}
                {...internalProps.dropdownButton}
                {...dropdownButton}/>
            </div>
            <div className={cx(styles.groupContent, classes?.groupContent)}>
              <RowLabel
                className={cx(styles.rowLabel1, classes?.rowLabel1)}
                {...internalProps.rowLabel1}
                {...rowLabel1}/>
              <DropdownButton
                className={cx(styles.dropdownButton1, classes?.dropdownButton1)}
                {...internalProps.dropdownButton1}
                {...dropdownButton1}/>
            </div>
          </div>
        </div>
      );
      break;
    }
    case 'NavigateURLExpanded': {
      componentView = (
        // MANUAL OVERRIDE add ref
        <div ref={setVariableMenuContainerRef} className={cx(currentStyle, className)}>
          <PanelHeader
            className={cx(styles.panelHeader, classes?.panelHeader)}
            {...internalProps.panelHeader}
            {...panelHeader}/>
          <div className={cx(styles.panelContent, classes?.panelContent)}>
            <div className={cx(styles.actionContent, classes?.actionContent)}>
              <RowLabel
                className={cx(styles.rowLabel, classes?.rowLabel)}
                {...internalProps.rowLabel}
                {...rowLabel}/>
              <DropdownButton
                className={cx(styles.dropdownButton, classes?.dropdownButton)}
                {...internalProps.dropdownButton}
                {...dropdownButton}/>
            </div>
            <div className={cx(styles.groupContent, classes?.groupContent)}>
              <RowLabel
                className={cx(styles.rowLabel1, classes?.rowLabel1)}
                {...internalProps.rowLabel1}
                {...rowLabel1}/>
              <DropdownButton
                className={cx(styles.dropdownButton1, classes?.dropdownButton1)}
                {...internalProps.dropdownButton1}
                {...dropdownButton1}/>
            </div>
            <ReplaceRow
              className={cx(styles.replaceRow, classes?.replaceRow)}
              {...internalProps.replaceRow}
              {...replaceRow}/>
            <div className={cx(styles.callFunctionContent, classes?.callFunctionContent)}>
              <RowLabel
                className={cx(styles.rowLabel2, classes?.rowLabel2)}
                {...internalProps.rowLabel2}
                {...rowLabel2}/>
              <DropdownButton
                className={cx(styles.dropdownButton2, classes?.dropdownButton2)}
                {...internalProps.dropdownButton2}
                {...dropdownButton2}/>
            </div>
          </div>
        </div>
      );
      break;
    }
    case 'UnselectedExpanded': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <PanelHeader
            className={cx(styles.panelHeader, classes?.panelHeader)}
            {...internalProps.panelHeader}
            {...panelHeader}/>
          <div className={cx(styles.panelContent, classes?.panelContent)}>
            <div className={cx(styles.actionContent, classes?.actionContent)}>
              <RowLabel
                className={cx(styles.rowLabel, classes?.rowLabel)}
                {...internalProps.rowLabel}
                {...rowLabel}/>
              <DropdownButton
                className={cx(styles.dropdownButton, classes?.dropdownButton)}
                {...internalProps.dropdownButton}
                {...dropdownButton}/>
            </div>
          </div>
        </div>
      );
      break;
    }
    case 'UpdateVarExpanded': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <PanelHeader
            className={cx(styles.panelHeader, classes?.panelHeader)}
            {...internalProps.panelHeader}
            {...panelHeader}/>
          <div className={cx(styles.panelContent, classes?.panelContent)}>
            <div className={cx(styles.actionContent, classes?.actionContent)}>
              <RowLabel
                className={cx(styles.rowLabel, classes?.rowLabel)}
                {...internalProps.rowLabel}
                {...rowLabel}/>
              <DropdownButton
                className={cx(styles.dropdownButton, classes?.dropdownButton)}
                {...internalProps.dropdownButton}
                {...dropdownButton}/>
            </div>
            <div className={cx(styles.groupContent, classes?.groupContent)}>
              <RowLabel
                className={cx(styles.rowLabel1, classes?.rowLabel1)}
                {...internalProps.rowLabel1}
                {...rowLabel1}/>
              <ReplaceButton
                className={cx(styles.replaceButton, classes?.replaceButton)}
                {...internalProps.replaceButton}
                {...replaceButton}/>
            </div>
            <div className={cx(styles.newValueContent, classes?.newValueContent)}>
              <div className={cx(styles.rectangle1, classes?.rectangle1)}/>
              <UpdateValue
                className={cx(styles.updateValue, classes?.updateValue)}
                {...internalProps.updateValue}
                {...updateValue}/>
            </div>
          </div>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default ActionPanel;
