import { ApiResponsePropMetadata, PropType } from '../../store/ApiGroupStore/types';

export const variableValueTypes = ['string', 'number', 'boolean', 'object', 'collection'] as const;
export type VariableValueType = typeof variableValueTypes[number];

export const variableSources = ['ApiResponse', 'PageNavigation', 'CreateVariable'] as const;
export type VariableSource = typeof variableSources[number];

export const variableScopes = ['Global', 'Page', 'Flow', 'CollectionItem'] as const;
export type VariableScope = typeof variableScopes[number];

export type BaseVariable<TSource extends VariableSource, TMetadata extends Record<string, any>> = {
  uuid: string;
  name: string;
  source: TSource;
  scope: VariableScope;
  pageUuid?: string;
  type: VariableValueType;
  metadata: TMetadata;
};

export type ApiResponseVariableMetadata = {
  actionUuid: string;
  apiGroupUuid: string;
  apiEndpointUuid: string;
  responseName: string;
  responsePath: string;
  initialValue: any;
};
export type ApiResponseVariable = BaseVariable<'ApiResponse', ApiResponseVariableMetadata>;

export type PageNavigationVariableMetadata = {
  isPathParam: boolean;
  alias: string;
};
export type PageNavigationVariable = BaseVariable<'PageNavigation', PageNavigationVariableMetadata>;

export type CreateVariableMetadata = {
  actionUuid: string;
};
export type CreateVariable = BaseVariable<'CreateVariable', CreateVariableMetadata>;

export type Variable = ApiResponseVariable | PageNavigationVariable | CreateVariable;

export type VariableReferenceType = {
  id: string;
  scope: VariableScope;
  name: string;
  type: string;
};

export type CreateVariablePayload = Pick<Variable, 'name' | 'source' | 'scope' | 'pageUuid' | 'type' | 'metadata'>;

export type UpdateVariablePayload = Partial<Pick<Variable, 'name' | 'source' | 'scope' | 'pageUuid' | 'type' | 'metadata'>> & {
  variableUuid: string;
};

export type DeleteVariablePayload = {
  variableUuid: string;
};
