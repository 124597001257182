import React from 'react';
import usePresenter from './PreviewLayout.presenter';
import styles from './PreviewLayout.module.scss';
import { PreviewLayoutCombinedProps } from './types';
import PreviewElementRenderer from '../../../modules/preview/renderers/PreviewElementRenderer';
import { Constants } from '../../../constants';

const PreviewLayout: React.FC<PreviewLayoutCombinedProps> = (props) => {
  const {
    className,
    previewRootRef, // MANUAL OVERRIDE
  } = usePresenter(props);

  return (
    <div ref={previewRootRef} className={styles.previewLayout}>
      <PreviewElementRenderer id={Constants.ID.BODY} />
    </div>
  );
};

export default PreviewLayout;
