type TextInternalProps = {
};

type TextPropsValues = {
  'CodespaceMediumLeftMedium500NeutralDefault': TextInternalProps;
  'ExtraSmallCenterMedium500NeutralHoverSelected': TextInternalProps;
  'ExtraSmallCenterRegular400NeutralDefault': TextInternalProps;
  'ExtraSmallCenterRegular400NeutralHoverSelected': TextInternalProps;
  'ExtraSmallLeftRegular400ActionDefault': TextInternalProps;
  'LabelExtraSmallLeftItalic400NeutralDefault': TextInternalProps;
  'LabelExtraSmallLeftItalic400NeutralHoverSelected': TextInternalProps;
  'LabelExtraSmallLeftMedium500NeutralDefault': TextInternalProps;
  'LabelExtraSmallLeftMedium500NeutralHoverSelected': TextInternalProps;
  'LabelExtraSmallLeftRegular400NeutralDefault': TextInternalProps;
  'LabelExtraSmallLeftRegular400NeutralHoverSelected': TextInternalProps;
  'LabelExtraSmallRightRegular400NeutralDefault': TextInternalProps;
  'LabelExtraSmallRightRegular400NeutralHoverSelected': TextInternalProps;
  'LabelLargeCenterMedium500NeutralHoverSelected': TextInternalProps;
  'LabelLargeCenterSemibold600NeutralHoverSelected': TextInternalProps;
  'LabelLargeLeftMedium500NeutralDefault': TextInternalProps;
  'LabelLargeLeftMedium500NeutralHoverSelected': TextInternalProps;
  'LabelLargeLeftRegular400NeutralDefault': TextInternalProps;
  'LabelMediumCenterRegular400NeutralDefault': TextInternalProps;
  'LabelMediumCenterRegular400NeutralHoverSelected': TextInternalProps;
  'LabelMediumLeftMedium500NeutralDefault': TextInternalProps;
  'LabelMediumLeftMedium500NeutralHoverSelected': TextInternalProps;
  'LabelMediumLeftRegular400NeutralDefault': TextInternalProps;
  'LabelMediumLeftRegular400NeutralHoverSelected': TextInternalProps;
  'LabelMediumLeftSemibold600NeutralHoverSelected': TextInternalProps;
  'LabelMediumRightRegular400NeutralHoverSelected': TextInternalProps;
  'LabelSmallCenterMedium500DestructiveSelected': TextInternalProps;
  'LabelSmallCenterMedium500NeutralDefault': TextInternalProps;
  'LabelSmallCenterMedium500NeutralHoverSelected': TextInternalProps;
  'LabelSmallCenterMedium500VariableSelected': TextInternalProps;
  'LabelSmallCenterRegular400NeutralDefault': TextInternalProps;
  'LabelSmallCenterRegular400NeutralHoverSelected': TextInternalProps;
  'LabelSmallLeftItalic400NeutralDefault': TextInternalProps;
  'LabelSmallLeftItalic400NeutralHoverSelected': TextInternalProps;
  'LabelSmallLeftItalic500NeutralDefault': TextInternalProps;
  'LabelSmallLeftItalic500NeutralSubdued': TextInternalProps;
  'LabelSmallLeftMedium500DestructiveSelected': TextInternalProps;
  'LabelSmallLeftMedium500NeutralDefault': TextInternalProps;
  'LabelSmallLeftMedium500NeutralHoverSelected': TextInternalProps;
  'LabelSmallLeftMedium500VariableSelected': TextInternalProps;
  'LabelSmallLeftRegular400ActionDefault': TextInternalProps;
  'LabelSmallLeftRegular400NeutralDefault': TextInternalProps;
  'LabelSmallLeftRegular400NeutralHoverSelected': TextInternalProps;
  'LabelSmallLeftRegular400VariableSelected': TextInternalProps;
  'LabelSmallLeftSemibold600NeutralHoverSelected': TextInternalProps;
  'LabelSmallRightMedium500DestructiveSelected': TextInternalProps;
  'LabelSmallRightMedium500NeutralDefault': TextInternalProps;
  'LabelSmallRightMedium500SuccessSelected': TextInternalProps;
  'XXLLeftSemibold600NeutralHoverSelected': TextInternalProps;
};

const propValues: TextPropsValues = {
  CodespaceMediumLeftMedium500NeutralDefault: {
  },
  ExtraSmallCenterMedium500NeutralHoverSelected: {
  },
  ExtraSmallCenterRegular400NeutralDefault: {
  },
  ExtraSmallCenterRegular400NeutralHoverSelected: {
  },
  ExtraSmallLeftRegular400ActionDefault: {
  },
  LabelExtraSmallLeftItalic400NeutralDefault: {
  },
  LabelExtraSmallLeftItalic400NeutralHoverSelected: {
  },
  LabelExtraSmallLeftMedium500NeutralDefault: {
  },
  LabelExtraSmallLeftMedium500NeutralHoverSelected: {
  },
  LabelExtraSmallLeftRegular400NeutralDefault: {
  },
  LabelExtraSmallLeftRegular400NeutralHoverSelected: {
  },
  LabelExtraSmallRightRegular400NeutralDefault: {
  },
  LabelExtraSmallRightRegular400NeutralHoverSelected: {
  },
  LabelLargeCenterMedium500NeutralHoverSelected: {
  },
  LabelLargeCenterSemibold600NeutralHoverSelected: {
  },
  LabelLargeLeftMedium500NeutralDefault: {
  },
  LabelLargeLeftMedium500NeutralHoverSelected: {
  },
  LabelLargeLeftRegular400NeutralDefault: {
  },
  LabelMediumCenterRegular400NeutralDefault: {
  },
  LabelMediumCenterRegular400NeutralHoverSelected: {
  },
  LabelMediumLeftMedium500NeutralDefault: {
  },
  LabelMediumLeftMedium500NeutralHoverSelected: {
  },
  LabelMediumLeftRegular400NeutralDefault: {
  },
  LabelMediumLeftRegular400NeutralHoverSelected: {
  },
  LabelMediumLeftSemibold600NeutralHoverSelected: {
  },
  LabelMediumRightRegular400NeutralHoverSelected: {
  },
  LabelSmallCenterMedium500DestructiveSelected: {
  },
  LabelSmallCenterMedium500NeutralDefault: {
  },
  LabelSmallCenterMedium500NeutralHoverSelected: {
  },
  LabelSmallCenterMedium500VariableSelected: {
  },
  LabelSmallCenterRegular400NeutralDefault: {
  },
  LabelSmallCenterRegular400NeutralHoverSelected: {
  },
  LabelSmallLeftItalic400NeutralDefault: {
  },
  LabelSmallLeftItalic400NeutralHoverSelected: {
  },
  LabelSmallLeftItalic500NeutralDefault: {
  },
  LabelSmallLeftItalic500NeutralSubdued: {
  },
  LabelSmallLeftMedium500DestructiveSelected: {
  },
  LabelSmallLeftMedium500NeutralDefault: {
  },
  LabelSmallLeftMedium500NeutralHoverSelected: {
  },
  LabelSmallLeftMedium500VariableSelected: {
  },
  LabelSmallLeftRegular400ActionDefault: {
  },
  LabelSmallLeftRegular400NeutralDefault: {
  },
  LabelSmallLeftRegular400NeutralHoverSelected: {
  },
  LabelSmallLeftRegular400VariableSelected: {
  },
  LabelSmallLeftSemibold600NeutralHoverSelected: {
  },
  LabelSmallRightMedium500DestructiveSelected: {
  },
  LabelSmallRightMedium500NeutralDefault: {
  },
  LabelSmallRightMedium500SuccessSelected: {
  },
  XXLLeftSemibold600NeutralHoverSelected: {
  },
};

const getProps = (type: string): TextInternalProps => {
  const values: TextInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
