import React from 'react';
import cx from 'classnames';

import Text from '../../atoms/Text';
import PanelHeader from '../../molecules/PanelHeader';
import ResponseItemList from '../../molecules/ResponseItemList';
import VariablesContent from '../VariablesContent';

import styles from './VariableResponsePanel.module.scss';
import usePresenter from './VariableResponsePanel.presenter';
import getProps from './VariableResponsePanel.props';
import { VariableResponsePanelCombinedProps } from './types';

const VariableResponsePanel: React.FC<VariableResponsePanelCombinedProps> = (props) => {
  const {
    state,
    className,
    classes,
    panelHeader,
    variablesContent,
    heading,
    instructions,
    responseItemList,
    // MANUAL OVERRIDE START
    renderPanel,
    // MANUAL OVERRIDE END
  } = usePresenter(props);

  // MANUAL OVERRIDE START
  if (!renderPanel) {
    return null;
  }
  // MANUAL OVERRIDE END

  const variantName = `${state}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`variableResponsePanel${variantName}`];

  let componentView;

  switch (variantName) {
    case 'Collapsed': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <PanelHeader
            className={cx(styles.panelHeader, classes?.panelHeader)}
            {...internalProps.panelHeader}
            {...panelHeader}/>
        </div>
      );
      break;
    }
    case 'Expanded': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <PanelHeader
            className={cx(styles.panelHeader, classes?.panelHeader)}
            {...internalProps.panelHeader}
            {...panelHeader}/>
          <div className={cx(styles.panelContent, classes?.panelContent)}>
            <VariablesContent
              className={cx(styles.variablesContent, classes?.variablesContent)}
              {...internalProps.variablesContent}
              {...variablesContent}/>
            <div className={cx(styles.responseContent, classes?.responseContent)}>
              <Text
                className={cx(styles.heading, classes?.heading)}
                {...internalProps.heading}
                {...heading}/>
              <Text
                className={cx(styles.instructions, classes?.instructions)}
                {...internalProps.instructions}
                {...instructions}/>
              <div className={cx(styles.responseContainer, classes?.responseContainer)}>
                <ResponseItemList
                  className={cx(styles.responseItemList, classes?.responseItemList)}
                  {...internalProps.responseItemList}
                  {...responseItemList}/>
              </div>
            </div>
          </div>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default VariableResponsePanel;
