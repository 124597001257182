import React from 'react';
import cx from 'classnames';

import PageLabelItem from '../PageLabelItem';

import styles from './PageLabelItemList.module.scss';
import usePresenter from './PageLabelItemList.presenter';
import getProps from './PageLabelItemList.props';
import type { PageLabelItemListCombinedProps } from './types';

const PageLabelItemList: React.FC<PageLabelItemListCombinedProps> = (props) => {
  const {
    className,
    classes,
    pageLabelItems,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.pageLabelItemList;

  const pageLabelItemViews = pageLabelItems?.map((pageLabelItem) => (
    <PageLabelItem
      key={pageLabelItem.pageUuid} // MANUAL OVERRIDE
      className={cx(styles.pageLabelItem, classes?.pageLabelItem)}
      {...internalProps.pageLabelItem}
      {...pageLabelItem}
    />
  )) || [];

  return (
    <div className={cx(currentStyle, className)}>
      {pageLabelItemViews}
    </div>
  );
};

export default PageLabelItemList;
