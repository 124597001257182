import { SeparateCornerRadiusType } from '../../../store/types';

const allEqual = (arr: number[]): boolean => arr.every((val: number) => val === arr[0]);

export const handleSameSeparateValues = (separateCorners?: SeparateCornerRadiusType): boolean => {
  const allCorners: number[] = [
    separateCorners?.topLeft?.value || 0,
    separateCorners?.topRight?.value || 0,
    separateCorners?.bottomLeft?.value || 0,
    separateCorners?.bottomRight?.value || 0,
  ];
  return allEqual(Object.values(allCorners));
};
