import React from 'react';
import cx from 'classnames';
import { Route, Routes } from 'react-router';

import Button from '../../atoms/Button';
import Text from '../../atoms/Text';
import EmptyGroupBlock from '../../blocks/EmptyGroupBlock';
import EndpointCardBlock from '../../blocks/EndpointCardBlock';
import HeaderBlock from '../../blocks/HeaderBlock';
import TabItemList from '../../molecules/TabItemList';
import ConfigureGroupMenu from '../../organisms/ConfigureGroupMenu';
import GroupsPanel from '../../organisms/GroupsPanel';
import TextModal from '../../organisms/TextModal';

import { ROUTES } from '../../../lib/constants';
import styles from './GroupPage.module.scss';
import usePresenter from './GroupPage.presenter';
import getProps from './GroupPage.props';
import type { GroupPageCombinedProps } from './types';

const GroupPage: React.FC<GroupPageCombinedProps> = (props) => {
  const {
    state,
    type,
    className,
    classes,
    groupsPanel,
    groupName,
    baseURL,
    tabItemList,
    button,
    headerBlock,
    emptyGroupBlock,
    // MANUAL OVERRIDE STARTS
    endpointCardBlock,
    apiGroupPageContainerRef,
    configureGroupMenu,
    deleteGroupMenu,
    unsavedChangesModal,
    // MANUAL OVERRIDE ENDS
  } = usePresenter(props);

  const variantName = `${state}${type}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`groupPage${variantName}`];

  let componentView;

  switch (variantName) {
    case 'DefaultEndpoints': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <div className={cx(styles.pageContent, classes?.pageContent)}>
            <div className={cx(styles.leftContent, classes?.leftContent)}>
              <GroupsPanel
                className={cx(styles.groupsPanel, classes?.groupsPanel)}
                {...internalProps.groupsPanel}
                {...groupsPanel}
              />
            </div>
            <div className={cx(styles.rightContent, classes?.rightContent)}>
              <div className={cx(styles.titleContent, classes?.titleContent)}>
                <Text
                  className={cx(styles.groupName, classes?.groupName)}
                  {...internalProps.groupName}
                  {...groupName}
                />
                <Text
                  className={cx(styles.baseURL, classes?.baseURL)}
                  {...internalProps.baseURL}
                  {...baseURL}
                />
              </div>
              <div className={cx(styles.groupContent, classes?.groupContent)}>
                <div className={cx(styles.navContent, classes?.navContent)}>
                  <TabItemList
                    className={cx(styles.tabItemList, classes?.tabItemList)}
                    {...internalProps.tabItemList}
                    {...tabItemList}
                  />
                  <Button
                    className={cx(styles.button, classes?.button)}
                    {...internalProps.button}
                    {...button}
                  />
                </div>
                {/* MANUAL OVERRIDE STARTS */}
                <Routes>
                  <Route
                    path={`${ROUTES.groupDetailRoutes.base}*`}
                    element={(
                      <HeaderBlock
                        className={cx(styles.headerBlock, classes?.headerBlock)}
                        {...internalProps.headerBlock}
                        {...headerBlock}
                      />
                    )}
                  />
                  <Route
                    path={ROUTES.groupDetailRoutes.base}
                    element={(
                      <EndpointCardBlock
                        className={cx(styles.headerBlock, classes?.headerBlock)}
                        {...internalProps.endpointCardBlock}
                        {...endpointCardBlock}
                      />
                    )}
                  />
                </Routes>
                {/* MANUAL OVERRIDE ENDS */}
              </div>
            </div>
          </div>
        </div>
      );
      break;
    }
    case 'EmptyGroups': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <div className={cx(styles.pageContent, classes?.pageContent)}>
            <div className={cx(styles.leftContent, classes?.leftContent)}>
              <GroupsPanel
                className={cx(styles.groupsPanel, classes?.groupsPanel)}
                {...internalProps.groupsPanel}
                {...groupsPanel}
              />
            </div>
            <div className={cx(styles.rightContent, classes?.rightContent)}>
              <div className={cx(styles.content, classes?.content)}>
                <div className={cx(styles.groupContent, classes?.groupContent)}>
                  <EmptyGroupBlock
                    className={cx(styles.emptyGroupBlock, classes?.emptyGroupBlock)}
                    {...internalProps.emptyGroupBlock}
                    {...emptyGroupBlock}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  // MANUAL OVERRIDE STARTS
  return (
    <>
      <div ref={apiGroupPageContainerRef} className={cx(currentStyle, className)}>
        {componentView}
      </div>

      <TextModal className={styles.textModal} {...deleteGroupMenu} />

      <TextModal className={styles.textModal} {...unsavedChangesModal} />
      <ConfigureGroupMenu {...configureGroupMenu} />
    </>
  );
  // MANUAL OVERRIDE ENDS
};

export default GroupPage;
