import { PageSettingsProps } from '../PageSettings';
import { PageVariablesPanelProps } from '../PageVariablesPanel';

type PageSettingsPanelInternalProps = {
  pageSettings?: PageSettingsProps;
  pageVariablesPanel?: PageVariablesPanelProps;
};

type PageSettingsPanelPropsValues = {
  '': PageSettingsPanelInternalProps;
};

const propValues: PageSettingsPanelPropsValues = {
  '': {
    pageSettings: {
    },
    pageVariablesPanel: {
      state: 'Expanded',
    },
  },
};

const getProps = (type: string): PageSettingsPanelInternalProps => {
  const values: PageSettingsPanelInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
