import React from 'react';
import cx from 'classnames';

import EmptyStateInfo from '../../atoms/EmptyStateInfo';
import Text from '../../atoms/Text';
import VariableCardList from '../../molecules/VariableCardList';

import styles from './VariablesContent.module.scss';
import usePresenter from './VariablesContent.presenter';
import getProps from './VariablesContent.props';
import { VariablesContentCombinedProps } from './types';

const VariablesContent: React.FC<VariablesContentCombinedProps> = (props) => {
  const {
    state,
    className,
    classes,
    heading,
    emptyStateInfo,
    variableCardList,
  } = usePresenter(props);

  const variantName = `${state}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`variablesContent${variantName}`];

  let componentView;

  switch (variantName) {
    case 'Empty': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <Text
            className={cx(styles.heading, classes?.heading)}
            {...internalProps.heading}
            {...heading}/>
          <EmptyStateInfo
            className={cx(styles.emptyStateInfo, classes?.emptyStateInfo)}
            {...internalProps.emptyStateInfo}
            {...emptyStateInfo}/>
        </div>
      );
      break;
    }
    case 'Filled': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <Text
            className={cx(styles.heading, classes?.heading)}
            {...internalProps.heading}
            {...heading}/>
          <VariableCardList
            className={cx(styles.variableCardList, classes?.variableCardList)}
            {...internalProps.variableCardList}
            {...variableCardList}/>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default VariablesContent;
