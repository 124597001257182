import { ButtonProps } from '../../atoms/Button';
import { IconProps } from '../../atoms/Icon';
import { TextProps } from '../../atoms/Text';

type VariableLayerItemInternalProps = {
  button?: ButtonProps;
  icon?: IconProps;
  name?: TextProps;
  variableType?: TextProps;
};

type VariableLayerItemPropsValues = {
  'DefaultCollapsed': VariableLayerItemInternalProps;
  'DefaultDefault': VariableLayerItemInternalProps;
  'DefaultExpanded': VariableLayerItemInternalProps;
  'SelectedCollapsed': VariableLayerItemInternalProps;
  'SelectedDefault': VariableLayerItemInternalProps;
  'SelectedExpanded': VariableLayerItemInternalProps;
};

const propValues: VariableLayerItemPropsValues = {
  'DefaultCollapsed': {
    button: {
      type: 'IconClear',
      size: 'S',
      state: 'Default',
      style: 'PrimarySubdued',
    },
    icon: {
      asset: 'Global',
      colour: 'VariableSelected',
    },
    name: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralDefault',
    },
    variableType: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Italic500',
      colour: 'NeutralSubdued',
    },
  },
  'DefaultDefault': {
    icon: {
      asset: 'Global',
      colour: 'VariableSelected',
    },
    name: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralDefault',
    },
    variableType: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Italic500',
      colour: 'NeutralSubdued',
    },
  },
  'DefaultExpanded': {
    button: {
      type: 'IconClear',
      size: 'S',
      state: 'Default',
      style: 'PrimarySubdued',
    },
    icon: {
      asset: 'Global',
      colour: 'VariableSelected',
    },
    name: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralDefault',
    },
    variableType: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Italic500',
      colour: 'NeutralSubdued',
    },
  },
  'SelectedCollapsed': {
    button: {
      type: 'IconClear',
      size: 'S',
      state: 'Default',
      style: 'PrimarySubdued',
    },
    icon: {
      asset: 'Global',
      colour: 'VariableSelected',
    },
    name: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralHoverSelected',
    },
    variableType: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Italic500',
      colour: 'NeutralDefault',
    },
  },
  'SelectedDefault': {
    icon: {
      asset: 'Global',
      colour: 'VariableSelected',
    },
    name: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralHoverSelected',
    },
    variableType: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Italic500',
      colour: 'NeutralDefault',
    },
  },
  'SelectedExpanded': {
    button: {
      type: 'IconClear',
      size: 'S',
      state: 'Default',
      style: 'PrimarySubdued',
    },
    icon: {
      asset: 'Global',
      colour: 'VariableSelected',
    },
    name: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralHoverSelected',
    },
    variableType: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Italic500',
      colour: 'NeutralDefault',
    },
  },
};

const getProps = (type: string): VariableLayerItemInternalProps => {
  const values: VariableLayerItemInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
