import { useCallback, useState } from 'react';
import type { PanelHeaderCombinedProps } from '../components/molecules/PanelHeader/types';
import { typedDeepMerge } from '../utils/objectUtils';

export type Props<TState extends ('Collapsed' | `Expanded${string}`)> = {
  state?: TState;
  panelHeader?: PanelHeaderCombinedProps;
};

export const useExpandablePanel = <TState extends ('Collapsed' | `Expanded${string}`), TProps extends Props<TState>>(
  props: TProps,
  expandedState: TProps['state'] & `Expanded${string}`,
  isPanelInitiallyExpanded = false,
): TProps => {
  const [isPanelExpanded, setIsPanelExpanded] = useState<boolean>(isPanelInitiallyExpanded);
  const togglePanel = useCallback(() => setIsPanelExpanded((prevIsPanelExpanded) => !prevIsPanelExpanded), []);

  return typedDeepMerge<TProps>(props, {
    state: isPanelExpanded ? expandedState : 'Collapsed',
    panelHeader: {
      button: {
        onClick: togglePanel,
      },
    },
  } as Partial<TProps>);
};
