import React from 'react';
import styles from './TextElement.module.scss';
import usePresenter from './TextElement.presenter';
import type { TextElementProps } from './types';

const TextElement: React.FC<TextElementProps> = (props) => {
  const internalProps = usePresenter(props);
  const {
    elementRef,
    element,
    style,
    spanStyle,
    inputStyle,
    isElementEdited,
    textSpanRef,
    textInputRef,

    textValue,
    onTextChanged,
    onKeyDown,
    onBlur,
    onClick,
    ...otherProps
  } = internalProps;

  let inputValueView;

  if (isElementEdited) {
    inputValueView = (
      <textarea
        ref={textInputRef}
        className={styles.input}
        style={inputStyle}
        value={textValue}
        wrap="hard"
        onChange={onTextChanged}
        onKeyDown={onKeyDown}
        onBlur={onBlur}
        onClick={onClick}/>
    );
  }

  return (
    <div
      ref={elementRef}
      className={styles.textElement}
      style={style}
      {...otherProps}
    >
      <div
        ref={textSpanRef}
        style={spanStyle}>
        {textValue}
      </div>
      {inputValueView}
    </div>
  );
};

export default TextElement;
