import cx from 'classnames';
import React from 'react';

import DropdownButton from '../../atoms/DropdownButton';
import FieldWithDropdown from '../../atoms/FieldWithDropdown';
import RowLabel from '../../atoms/RowLabel';
import TextDivider from '../../atoms/TextDivider';
import PanelHeader from '../../molecules/PanelHeader';

import styles from './SizePanel.module.scss';
import usePresenter from './SizePanel.presenter';
import getProps from './SizePanel.props';
import type { SizePanelCombinedProps } from './types';

const SizePanel: React.FC<SizePanelCombinedProps> = (props) => {
  const {
    state,
    className,
    classes,
    panelHeader,
    rowLabel,
    inputField,
    rowLabel1,
    inputField1,
    textDivider,
    rowLabel2,
    inputField2,
    rowLabel3,
    inputField3,
    rowLabel4,
    inputField4,
    rowLabel5,
    inputField5,
    font,
    dropdownButton,
    renderPanel, // MANUAL OVERRIDE
  } = usePresenter(props);

  // MANUAL OVERRIDE STARTS
  if (!renderPanel) {
    return null;
  }
  // MANUAL OVERRIDE ENDS

  const variantName = `${state}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`sizePanel${variantName}`];

  let componentView;

  switch (variantName) {
    case 'Collapsed': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <PanelHeader
            className={cx(styles.panelHeader, classes?.panelHeader)}
            {...internalProps.panelHeader}
            {...panelHeader}
          />
        </div>
      );
      break;
    }
    case 'Expanded': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <PanelHeader
            className={cx(styles.panelHeader, classes?.panelHeader)}
            {...internalProps.panelHeader}
            {...panelHeader}
          />
          <div className={cx(styles.content, classes?.content)}>
            <div className={cx(styles.widthHeightContent, classes?.widthHeightContent)}>
              <div className={cx(styles.width, classes?.width)}>
                <RowLabel
                  className={cx(styles.rowLabel, classes?.rowLabel)}
                  {...internalProps.rowLabel}
                  {...rowLabel}
                />
                <FieldWithDropdown // MANUAL OVERRIDE
                  className={cx(styles.inputField, classes?.inputField)}
                  {...internalProps.inputField}
                  {...inputField}
                />
              </div>
              <div className={cx(styles.height, classes?.height)}>
                <RowLabel
                  className={cx(styles.rowLabel1, classes?.rowLabel1)}
                  {...internalProps.rowLabel1}
                  {...rowLabel1}
                />
                <FieldWithDropdown // MANUAL OVERRIDE
                  className={cx(styles.inputField1, classes?.inputField1)}
                  {...internalProps.inputField1}
                  {...inputField1}
                />
              </div>
            </div>
            <TextDivider
              className={cx(styles.textDivider, classes?.textDivider)}
              {...internalProps.textDivider}
              {...textDivider}
            />
            <div className={cx(styles.minContent, classes?.minContent)}>
              <div className={cx(styles.minW, classes?.minW)}>
                <RowLabel
                  className={cx(styles.rowLabel2, classes?.rowLabel2)}
                  {...internalProps.rowLabel2}
                  {...rowLabel2}
                />
                <FieldWithDropdown // MANUAL OVERRIDE
                  className={cx(styles.inputField2, classes?.inputField2)}
                  {...internalProps.inputField2}
                  {...inputField2}
                />
              </div>
              <div className={cx(styles.minH, classes?.minH)}>
                <RowLabel
                  className={cx(styles.rowLabel3, classes?.rowLabel3)}
                  {...internalProps.rowLabel3}
                  {...rowLabel3}
                />
                <FieldWithDropdown // MANUAL OVERRIDE
                  className={cx(styles.inputField3, classes?.inputField3)}
                  {...internalProps.inputField3}
                  {...inputField3}
                />
              </div>
            </div>
            <div className={cx(styles.maxContent, classes?.maxContent)}>
              <div className={cx(styles.maxW, classes?.maxW)}>
                <RowLabel
                  className={cx(styles.rowLabel4, classes?.rowLabel4)}
                  {...internalProps.rowLabel4}
                  {...rowLabel4}
                />
                <FieldWithDropdown // MANUAL OVERRIDE
                  className={cx(styles.inputField4, classes?.inputField4)}
                  {...internalProps.inputField4}
                  {...inputField4}
                />
              </div>
              <div className={cx(styles.maxH, classes?.maxH)}>
                <RowLabel
                  className={cx(styles.rowLabel5, classes?.rowLabel5)}
                  {...internalProps.rowLabel5}
                  {...rowLabel5}
                />
                <FieldWithDropdown // MANUAL OVERRIDE
                  className={cx(styles.inputField5, classes?.inputField5)}
                  {...internalProps.inputField5}
                  {...inputField5}
                />
              </div>
            </div>
            <div className={cx(styles.overflowContent, classes?.overflowContent)}>
              <RowLabel
                className={cx(styles.font, classes?.font)}
                {...internalProps.font}
                {...font}
              />
              <DropdownButton
                className={cx(styles.dropdownButton, classes?.dropdownButton)}
                {...internalProps.dropdownButton}
                {...dropdownButton}
              />
            </div>
          </div>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default SizePanel;
