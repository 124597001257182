import React from 'react';
import cx from 'classnames';

import Text from '../Text';

import styles from './Chip.module.scss';
import usePresenter from './Chip.presenter';
import getProps from './Chip.props';
import { ChipCombinedProps } from './types';

const Chip: React.FC<ChipCombinedProps> = (props) => {
  const {
    className,
    classes,
    text,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.chip;

  return (
    <div className={cx(currentStyle, className)}>
      <Text
        className={cx(styles.text, classes?.text)}
        {...internalProps.text}
        {...text}
      />
    </div>
  );
};

export default Chip;
