import Default from './Default.svg';
import HPBC from './HPBC.svg';
import HSAB from './HSAB.svg';
import HSBB from './HSBB.svg';
import VPBC from './VPBC.svg';
import VSAC from './VSAC.svg';
import VSBC from './VSBC.svg';

export const BottomCenter = {
  Default,
  'horizontal-packed-bottom-center': HPBC,
  'horizontal-space-around-bottom-none': HSAB,
  'horizontal-space-between-bottom-none': HSBB,
  'vertical-packed-bottom-center': VPBC,
  'vertical-space-around-none-center': VSAC,
  'vertical-space-between-none-center': VSBC,
};
