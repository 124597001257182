import { ButtonProps } from '../../atoms/Button';
import { TextProps } from '../../atoms/Text';
import { TableRowListProps } from '../../molecules/TableRowList';

type KeyValueTableInternalProps = {
  keyLabel?: TextProps;
  valueLabel?: TextProps;
  button?: ButtonProps;
  tableRowList?: TableRowListProps;
};

type KeyValueTablePropsValues = {
  'EmptyDefault': KeyValueTableInternalProps;
  'FilledDefault': KeyValueTableInternalProps;
  'FilledVariable': KeyValueTableInternalProps;
};

const propValues: KeyValueTablePropsValues = {
  EmptyDefault: {
    keyLabel: {
      type: 'LabelMedium',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralHoverSelected',
    },
    valueLabel: {
      type: 'LabelMedium',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralHoverSelected',
    },
    button: {
      type: 'IconText',
      size: 'M',
      state: 'Default',
      style: 'PrimarySubdued',
    },
  },
  FilledDefault: {
    keyLabel: {
      type: 'LabelMedium',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralHoverSelected',
    },
    valueLabel: {
      type: 'LabelMedium',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralHoverSelected',
    },
    tableRowList: {
      state: 'KeyValue',
      type: 'Default',
    },
    button: {
      type: 'IconText',
      size: 'M',
      state: 'Default',
      style: 'PrimarySubdued',
    },
  },
  FilledVariable: {
    keyLabel: {
      type: 'LabelMedium',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralHoverSelected',
    },
    valueLabel: {
      type: 'LabelMedium',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralHoverSelected',
    },
    tableRowList: {
      state: 'KeyValue',
      type: 'Variable',
    },
    button: {
      type: 'IconText',
      size: 'M',
      state: 'Default',
      style: 'PrimarySubdued',
    },
  },
};

const getProps = (type: string): KeyValueTableInternalProps => {
  const values: KeyValueTableInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
