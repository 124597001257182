import React from 'react';
import cx from 'classnames';

import Text from '../../atoms/Text';
import TableRowList from '../../molecules/TableRowList';

import styles from './KeyValueTableFilledTextTextValue.module.scss';
import usePresenter from './KeyValueTableFilledTextTextValue.presenter';
import getProps from './KeyValueTableFilledTextTextValue.props';
import { KeyValueTableFilledTextTextValueCombinedProps } from './types';

const KeyValueTableFilledTextTextValue: React.FC<KeyValueTableFilledTextTextValueCombinedProps> = (props) => {
  const {
    className,
    classes,
    name,
    type,
    value,
    tableRowList,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.keyValueTableFilledTextTextValue;

  return (
    <div className={cx(currentStyle, className)}>
      <div className={cx(styles.headerContent, classes?.headerContent)}>
        <Text
          className={cx(styles.name, classes?.name)}
          {...internalProps.name}
          {...name}/>
        <Text
          className={cx(styles.type, classes?.type)}
          {...internalProps.type}
          {...type}/>
        <Text
          className={cx(styles.value, classes?.value)}
          {...internalProps.value}
          {...value}/>
      </div>
      <div className={cx(styles.tableContent, classes?.tableContent)}>
        <TableRowList
          className={cx(styles.tableRowList, classes?.tableRowList)}
          {...internalProps.tableRowList}
          {...tableRowList}/>
      </div>
    </div>
  );
};

export default KeyValueTableFilledTextTextValue;
