import { MainBlockProps } from '../../blocks/MainBlock';
import { StyleSettingsBlockProps } from '../../blocks/StyleSettingsBlock';
import { LogicBuilderPanelProps } from '../../organisms/LogicBuilderPanel';

type UIBuilderPageInternalProps = {
  mainBlock?: MainBlockProps;
  logicBuilderPanel?: LogicBuilderPanelProps;
  styleSettingsBlock?: StyleSettingsBlockProps;
};

type UIBuilderPagePropsValues = {
  '': UIBuilderPageInternalProps;
};

const propValues: UIBuilderPagePropsValues = {
  '': {
    mainBlock: {
      state: 'Expanded',
    },
    logicBuilderPanel: {
      state: 'Collapsed',
    },
    styleSettingsBlock: {
      state: 'StylePanel',
    },
  },
};

const getProps = (type: string): UIBuilderPageInternalProps => {
  const values: UIBuilderPageInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
