import { Constants } from '../../constants';
import axiosInstance from '../../lib/api/axios';
import { STUDIO_API_URL } from '../../lib/config';
import { Action, CreateActionPayload, ReplaceActionPayload, UpdateActionPayload } from './types';

const applicationId = Number(localStorage.getItem(Constants.HEADERS.APPLICATION_ID)) || 1;
const headers = { headers: { [Constants.HEADERS.APPLICATION_ID]: applicationId } };

export const getActionsApi = async (pageUuid: string): Promise<Action[]> => {
  const { data: actions } = await axiosInstance.get<Action[]>(`${STUDIO_API_URL}/pages/${pageUuid}/element-actions/`, headers);
  return actions;
};

export const createActionApi = async (payload: CreateActionPayload): Promise<Action | undefined> => {
  const { data: action } = await axiosInstance.post<Action | undefined>(
    `${STUDIO_API_URL}/pages/${payload.pageUuid}/element-actions/`,
    payload,
    headers,
  );
  return action;
};

export const updateActionApi = async (updateElementPayload: UpdateActionPayload): Promise<void> => {
  const { pageUuid, uuid, ...body } = updateElementPayload;
  await axiosInstance.patch(`${STUDIO_API_URL}/pages/${pageUuid}/element-actions/${uuid}`, body, headers);
};

export const replaceActionApi = async (replaceElementPayload: ReplaceActionPayload): Promise<void> => {
  const { pageUuid, uuid, ...body } = replaceElementPayload;
  await axiosInstance.put(`${STUDIO_API_URL}/pages/${pageUuid}/element-actions/${uuid}`, body, headers);
};

export const deleteActionApi = async (pageUuid: string, actionUuid: string): Promise<void> => {
  await axiosInstance.delete(`${STUDIO_API_URL}/pages/${pageUuid}/element-actions/${actionUuid}`, headers);
};
