import {
  action,
  computed,
  makeAutoObservable,
  observable,
  runInAction,
} from 'mobx';

import {
  BreakpointType,
  BreakpointUnit,
  DefaultBreakpoints,
  IBreakpoint,
} from './types';

export class BreakpointStore {
  public constructor() {
    makeAutoObservable(this);
  }

  @observable
  public isInitialised: boolean | undefined = undefined;

  @action
  public initialise = (): void => {
    // console.log('Logger: Initialising breakpoint store');

    // Start initialisation
    this.isInitialised = false;

    // Populate state
    runInAction(() => {
      this.currentBreakpoints = [
        DefaultBreakpoints.desktop,
        DefaultBreakpoints.tablet,
        DefaultBreakpoints.mobile,
      ];
    });

    // Complete initialisation
    runInAction(() => {
      this.isInitialised = true;
    });

    // console.log('Logger: Breakpoint store is initialised');
  };

  @observable
  private currentBreakpoints: IBreakpoint[] = [];

  @observable
  public breakpointUnit: BreakpointUnit = 'px';

  /** Get breakpoints sorted by width in ascending order */
  @computed
  public get breakpoints(): IBreakpoint[] {
    return this.currentBreakpoints
      .slice()
      .sort(({ width: width1 }, { width: width2 }) => width1 - width2);
  }

  @computed
  public get defaultBreakpoint(): IBreakpoint {
    const defaultBreakpoint: IBreakpoint | undefined = this.breakpoints.find((breakpoint) => breakpoint.isDefaultBreakpoint);
    if (!defaultBreakpoint) {
      throw new Error('Default breakpoint cannot be found');
    }
    return defaultBreakpoint;
  }

  @computed
  public get currentBreakpoint(): IBreakpoint {
    let currentBreakpoint: IBreakpoint | undefined = this.breakpoints.find((breakpoint) => breakpoint.isCurrentBreakpoint);
    if (!currentBreakpoint) {
      currentBreakpoint = this.defaultBreakpoint;
    }
    return currentBreakpoint;
  }

  @action
  public setCurrentBreakpoint = (newBreakpoint: BreakpointType): void => {
    if (this.currentBreakpoint.type !== newBreakpoint) {
      // Reset the current breakpoint
      this.currentBreakpoint.isCurrentBreakpoint = false;

      // Set new breakpoint to be current
      const newCurrentBreakpoint: IBreakpoint | undefined = this.breakpoints.find((breakpoint) => breakpoint.type === newBreakpoint);
      if (newCurrentBreakpoint) {
        newCurrentBreakpoint.isCurrentBreakpoint = true;
      }
    }
  };
}
