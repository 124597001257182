import { useContext } from 'react';
import { Constants } from '../../../constants';
import { getElement } from '../../../store/PageStore/utils';
import { StoreContext } from '../../../store/StoreProvider';
import type { LayerLabelItemListCombinedProps } from './types';

const usePresenter = (props: LayerLabelItemListCombinedProps): LayerLabelItemListCombinedProps => {
  const { pageStore } = useContext(StoreContext);
  const { elements } = pageStore;

  // If layerLabelItems is not provided then this is the root
  // In this case we render children of the Body container
  let { layerLabelItems } = props;
  if (!layerLabelItems && elements[Constants.ID.BODY]) {
    layerLabelItems = elements[Constants.ID.BODY].childIds.map((elementId) => ({ element: getElement(elementId, pageStore) }));
  }

  return {
    ...props,
    layerLabelItems,
  };
};

export default usePresenter;
