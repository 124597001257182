import React from 'react';
import styles from './ElementCornerSquares.module.scss';
import usePresenter from './ElementCornerSquares.presenter';
import type { ElementCornerSquaresProps } from './types';

const ElementCornerSquares: React.FC<ElementCornerSquaresProps> = (props) => {
  const {
    topLeftStyles,
    topRightStyles,
    bottomLeftStyles,
    bottomRightStyles,
  } = usePresenter(props);

  return (
    <>
      <div className={styles.elementCornerSquare} style={topLeftStyles} />
      <div className={styles.elementCornerSquare} style={topRightStyles} />
      <div className={styles.elementCornerSquare} style={bottomLeftStyles} />
      <div className={styles.elementCornerSquare} style={bottomRightStyles} />
    </>
  );
};

export default ElementCornerSquares;
