import {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import cx from 'classnames';

import customStyles from './Custom.module.scss';
import { StoreContext } from '../../../store/StoreProvider';
import type { CanvasCombinedProps } from './types';
import { useResizeObserver } from '../../../modules/canvas/hooks/useResizeObserver';

const usePresenter = (props: CanvasCombinedProps): CanvasCombinedProps => {
  const { appStore, breakpointStore } = useContext(StoreContext);

  const canvasRootRef = useRef<HTMLDivElement>(null);
  const canvasRef = useRef<HTMLDivElement>(null);

  const [canvasState, setCanvasState] = useState({
    canvasWidth: 0,
    canvasHeight: 0,
    canvasScale: 1,
  });

  const positionCanvas = useCallback(() => {
    if (canvasRootRef.current && canvasRef.current) {
      const {
        width: breakpointWidth,
        height: breakpointHeight,
      } = breakpointStore.currentBreakpoint;

      const canvasOuterBodyRect: DOMRect = canvasRootRef.current.getBoundingClientRect();
      const canvasMaxWidth = canvasOuterBodyRect.width - 32;
      const canvasMaxHeight = canvasOuterBodyRect.height;

      const canvasWidth: number = breakpointWidth;
      const aspectRatio = breakpointHeight / breakpointWidth; // 1024 / 1440
      const canvasHeight = canvasWidth * aspectRatio;
      const canvasScale = Math.min(canvasMaxWidth / breakpointWidth, 1);

      // Content within the canvas may be scaled down
      // But scrollHeight returns the real unscaled height of the content
      // const contentHeight: number = canvasRef.current.scrollHeight;

      // console.log({
      //   canvasMaxWidth,
      //   canvasMaxHeight,
      //   contentHeight,
      //   canvasWidth,
      //   canvasHeight,
      //   canvasScale,
      // });

      appStore.setCanvasScale(canvasScale);
      appStore.setCanvasHeight(canvasHeight);

      setCanvasState({
        canvasWidth,
        canvasHeight,
        canvasScale,
      });
    }
  }, [appStore, breakpointStore.currentBreakpoint]);

  useEffect(() => {
    positionCanvas();
  }, [positionCanvas]);

  useResizeObserver(canvasRootRef, positionCanvas);
  // useResizeObserver(canvasInnerBodyRef, positionCanvas);

  return {
    ...props,
    className: cx(props.className, customStyles.root),
    classes: {
      workspace: customStyles.workspace,
      canvas: customStyles.canvas,
      canvasContainer: customStyles.canvasContainer,
      // outerBody: customStyles.outerBody,
      // body: customStyles.body,
      // innerBody: customStyles.innerBody,
    },
    canvasRootRef,
    canvasRef,
    canvasWidth: canvasState.canvasWidth,
    canvasHeight: canvasState.canvasHeight,
    canvasScale: canvasState.canvasScale,
  };
};

export default usePresenter;
