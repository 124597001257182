import { DropdownButtonProps } from '../../atoms/DropdownButton';
import { InputFieldProps } from '../../atoms/InputField';
import { RowLabelProps } from '../../atoms/RowLabel';
import { AlignmentGridProps } from '../../molecules/AlignmentGrid';
import { PanelHeaderProps } from '../../molecules/PanelHeader';
import { ToggleItemListProps } from '../../molecules/ToggleItemList';

type LayoutPanelInternalProps = {
  panelHeader?: PanelHeaderProps;
  rowLabel?: RowLabelProps;
  dropdownButton?: DropdownButtonProps;
  rowLabel1?: RowLabelProps;
  toggleItemList?: ToggleItemListProps;
  rowLabel2?: RowLabelProps;
  inputField?: InputFieldProps;
  rowLabel3?: RowLabelProps;
  inputField1?: InputFieldProps;
  alignmentGrid?: AlignmentGridProps;
  rowLabel4?: RowLabelProps;
  toggleItemList1?: ToggleItemListProps;
  rowLabel5?: RowLabelProps;
  dropdownButton1?: DropdownButtonProps;
  dropdownButton2?: DropdownButtonProps;
  inputField2?: InputFieldProps;
  dropdownButton3?: DropdownButtonProps;
  inputField3?: InputFieldProps;
};

type LayoutPanelPropsValues = {
  'Collapsed': LayoutPanelInternalProps;
  'Expanded': LayoutPanelInternalProps;
};

const propValues: LayoutPanelPropsValues = {
  'Collapsed': {
    panelHeader: {
      state: 'Collapsed',
      type: 'Default',
    },
  },
  'Expanded': {
    panelHeader: {
      state: 'Expanded',
      type: 'Default',
    },
    rowLabel: {
      state: 'Default',
      style: 'Default',
      type: 'Panel',
    },
    dropdownButton: {
      type: 'Default',
      style: 'Default',
      state: 'Collapsed',
      colour: 'Default',
    },
    rowLabel1: {
      state: 'Default',
      style: 'Default',
      type: 'Panel',
    },
    toggleItemList: {
      size: 'Small',
      type: 'Icon',
      style: 'Default',
    },
    rowLabel2: {
      state: 'Default',
      style: 'Default',
      type: 'Panel',
    },
    inputField: {
      type: 'TextUnit',
      size: 'Medium',
      state: 'Default',
    },
    rowLabel3: {
      state: 'Default',
      style: 'Default',
      type: 'Panel',
    },
    inputField1: {
      type: 'TextUnit',
      size: 'Medium',
      state: 'Default',
    },
    alignmentGrid: {
    },
    rowLabel4: {
      state: 'Default',
      style: 'Default',
      type: 'Panel',
    },
    toggleItemList1: {
      size: 'Small',
      type: 'Text',
      style: 'Default',
    },
    rowLabel5: {
      state: 'Default',
      style: 'Default',
      type: 'Panel',
    },
    dropdownButton1: {
      type: 'Default',
      style: 'Default',
      state: 'Collapsed',
      colour: 'Default',
    },
    dropdownButton2: {
      type: 'Default',
      style: 'Default',
      state: 'Collapsed',
      colour: 'Default',
    },
    inputField2: {
      type: 'TextUnit',
      size: 'Medium',
      state: 'Default',
    },
    dropdownButton3: {
      type: 'Default',
      style: 'Default',
      state: 'Collapsed',
      colour: 'Default',
    },
    inputField3: {
      type: 'TextUnit',
      size: 'Medium',
      state: 'Default',
    },
  },
};

const getProps = (type: string): LayoutPanelInternalProps => {
  const values: LayoutPanelInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
