import { TestResponseItemProps } from '../TestResponseItem';

type TestResponseItemListInternalProps = {
  testResponseItem?: TestResponseItemProps;
};

type TestResponseItemListPropsValues = {
  '': TestResponseItemListInternalProps;
};

const propValues: TestResponseItemListPropsValues = {
  '': {
    testResponseItem: {
      type: 'Default',
    },
  },
};

const getProps = (type: string): TestResponseItemListInternalProps => {
  const values: TestResponseItemListInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
