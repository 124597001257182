import React from 'react';
import cx from 'classnames';

import Button from '../../atoms/Button';
import Icon from '../../atoms/Icon';
import Text from '../../atoms/Text';

import styles from './EmptyGroupBlock.module.scss';
import usePresenter from './EmptyGroupBlock.presenter';
import getProps from './EmptyGroupBlock.props';
import { EmptyGroupBlockCombinedProps } from './types';

const EmptyGroupBlock: React.FC<EmptyGroupBlockCombinedProps> = (props) => {
  const {
    type,
    className,
    classes,
    icon,
    label,
    instructions,
    button,
  } = usePresenter(props);

  const variantName = `${type}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`emptyGroupBlock${variantName}`];

  let componentView;

  switch (variantName) {
    case 'EmptyEndpoints':
    case 'EmptyGroup': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <div className={cx(styles.emptyStateContent, classes?.emptyStateContent)}>
            <Icon
              className={cx(styles.icon, classes?.icon)}
              {...internalProps.icon}
              {...icon}
            />
            <div className={cx(styles.textContent, classes?.textContent)}>
              <Text
                className={cx(styles.label, classes?.label)}
                {...internalProps.label}
                {...label}
              />
              <Text
                className={cx(styles.instructions, classes?.instructions)}
                {...internalProps.instructions}
                {...instructions}
              />
            </div>
          </div>
          <Button
            className={cx(styles.button, classes?.button)}
            {...internalProps.button}
            {...button}
          />
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default EmptyGroupBlock;
