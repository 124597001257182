import cx from 'classnames';
import React, { type PropsWithChildren } from 'react';
import styles from './BodyElement.module.scss';
import usePresenter from './BodyElement.presenter';
import type { BodyElementProps } from './types';

const BodyElement: React.FC<PropsWithChildren<BodyElementProps>> = ({ children, ...props }) => {
  const { elementRef, element, style, className, isElementEdited, ...otherProps } = usePresenter(props);

  return (
    <div
      ref={elementRef}
      className={cx(styles.bodyElement, className)}
      style={style}
      {...otherProps}
    >
      {children}
    </div>
  );
};

export default BodyElement;
