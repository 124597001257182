import React from 'react';
import cx from 'classnames';

import Button from '../../atoms/Button';
import EmptyStateInfo from '../../atoms/EmptyStateInfo';
import StatusChip from '../../atoms/StatusChip';
import Text from '../../atoms/Text';
import TestResponseItemList from '../../molecules/TestResponseItemList';

import styles from './ResponseStructureBlock.module.scss';
import usePresenter from './ResponseStructureBlock.presenter';
import getProps from './ResponseStructureBlock.props';
import { ResponseStructureBlockCombinedProps } from './types';

const ResponseStructureBlock: React.FC<ResponseStructureBlockCombinedProps> = (props) => {
  const {
    state,
    className,
    classes,
    text,
    button,
    emptyStateInfo,
    statusChip,
    testResponseItemList,
  } = usePresenter(props);

  const variantName = `${state}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`responseStructureBlock${variantName}`];

  let componentView;

  switch (variantName) {
    case 'Empty': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <div className={cx(styles.headerContent, classes?.headerContent)}>
            <div className={cx(styles.labelContent, classes?.labelContent)}>
              <Text
                className={cx(styles.text, classes?.text)}
                {...internalProps.text}
                {...text}/>
            </div>
            <div className={cx(styles.buttonContent, classes?.buttonContent)}>
              <Button
                className={cx(styles.button, classes?.button)}
                {...internalProps.button}
                {...button}/>
            </div>
          </div>
          <EmptyStateInfo
            className={cx(styles.emptyStateInfo, classes?.emptyStateInfo)}
            {...internalProps.emptyStateInfo}
            {...emptyStateInfo}/>
        </div>
      );
      break;
    }
    case 'Filled': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <div className={cx(styles.headerContent, classes?.headerContent)}>
            <div className={cx(styles.labelContent, classes?.labelContent)}>
              <Text
                className={cx(styles.text, classes?.text)}
                {...internalProps.text}
                {...text}/>
            </div>
            <div className={cx(styles.buttonContent, classes?.buttonContent)}>
              <StatusChip
                className={cx(styles.statusChip, classes?.statusChip)}
                {...internalProps.statusChip}
                {...statusChip}/>
              <Button
                className={cx(styles.button, classes?.button)}
                {...internalProps.button}
                {...button}/>
            </div>
          </div>
          <div className={cx(styles.testResponseContainer, classes?.testResponseContainer)}>
            <TestResponseItemList
              className={cx(styles.testResponseItemList, classes?.testResponseItemList)}
              {...internalProps.testResponseItemList}
              {...testResponseItemList}/>
          </div>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default ResponseStructureBlock;
