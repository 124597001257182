import { ButtonCombinedProps } from '../../atoms/Button/types';
import { DividerProps } from '../../atoms/Divider';
import { TextProps } from '../../atoms/Text';

type PanelHeaderInternalProps = {
  divider?: DividerProps;
  button?: ButtonCombinedProps; // MANUAL OVERRIDE;
  text?: TextProps;
  button1?: ButtonCombinedProps; // MANUAL OVERRIDE;
};

type PanelHeaderPropsValues = {
  'CollapsedDefault': PanelHeaderInternalProps;
  'CollapsedExpandable': PanelHeaderInternalProps;
  'DefaultTextOnly': PanelHeaderInternalProps;
  'ExpandedDefault': PanelHeaderInternalProps;
  'ExpandedExpandable': PanelHeaderInternalProps;
};

const propValues: PanelHeaderPropsValues = {
  'CollapsedDefault': {
    divider: {
      type: 'Horizontal',
      colour: 'Neutral1900',
    },
    button: {
      type: 'IconClear',
      size: 'M',
      state: 'Default',
      style: 'PrimarySubdued',
      // MANUAL OVERRIDE STARTS
      icon: {
        asset: 'ChevronRight',
        colour: 'NeutralDefault',
      },
      // MANUAL OVERRIDE ENDS
    },
    text: {
      type: 'LabelMedium',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralHoverSelected',
    },
  },
  'CollapsedExpandable': {
    divider: {
      type: 'Horizontal',
      colour: 'Neutral1900',
    },
    button: {
      type: 'IconClear',
      size: 'M',
      state: 'Default',
      style: 'PrimarySubdued',
      // MANUAL OVERRIDE STARTS
      icon: {
        asset: 'ChevronRight',
        colour: 'NeutralDefault',
      },
      // MANUAL OVERRIDE ENDS
    },
    text: {
      type: 'LabelMedium',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralHoverSelected',
    },
    button1: {
      type: 'IconRounded',
      size: 'M',
      state: 'Default',
      style: 'PrimarySubdued',
    },
  },
  'DefaultTextOnly': {
    divider: {
      type: 'Horizontal',
      colour: 'Neutral1900',
    },
    text: {
      type: 'LabelMedium',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralHoverSelected',
    },
  },
  'ExpandedDefault': {
    divider: {
      type: 'Horizontal',
      colour: 'Neutral1900',
    },
    button: {
      type: 'IconClear',
      size: 'M',
      state: 'Default',
      style: 'PrimarySubdued',
      // MANUAL OVERRIDE STARTS
      icon: {
        asset: 'ChevronDown',
        colour: 'NeutralDefault',
      },
      // MANUAL OVERRIDE ENDS
    },
    text: {
      type: 'LabelMedium',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralHoverSelected',
    },
  },
  'ExpandedExpandable': {
    divider: {
      type: 'Horizontal',
      colour: 'Neutral1900',
    },
    button: {
      type: 'IconClear',
      size: 'M',
      state: 'Default',
      style: 'PrimarySubdued',
      // MANUAL OVERRIDE STARTS
      icon: {
        asset: 'ChevronDown',
        colour: 'NeutralDefault',
      },
      // MANUAL OVERRIDE ENDS
    },
    text: {
      type: 'LabelMedium',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralHoverSelected',
    },
    button1: {
      type: 'IconRounded',
      size: 'M',
      state: 'Default',
      style: 'PrimarySubdued',
    },
  },
};

const getProps = (type: string): PanelHeaderInternalProps => {
  const values: PanelHeaderInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
