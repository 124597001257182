import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { nameToSlug } from '../../../lib/utils';
import { getPageNavigationVariables, mapVariablesToUrl } from '../../../store/PageStore/variableUtils';
import { StoreContext } from '../../../store/StoreProvider';
import { typedDeepMerge } from '../../../utils/objectUtils';
import type { PageSettingsCombinedProps } from './types';

const usePresenter = (props: PageSettingsCombinedProps): PageSettingsCombinedProps => {
  const { appStore, pageStore } = useContext(StoreContext);
  const { t } = useTranslation();

  const isHomePage = pageStore.currentPage ? pageStore.isHomePage(pageStore.currentPage.uuid) : false;
  const slugValue = nameToSlug(pageStore.currentPage?.slug || pageStore.currentPage?.name || '');

  const pageNavigationVariables = getPageNavigationVariables(pageStore.currentPageVariables, pageStore.currentPage?.uuid);
  const variableUrlValue = mapVariablesToUrl(pageNavigationVariables);

  return typedDeepMerge(props, {
    backHeader: {
      button: {
        icon: {
          asset: 'ArrowLeft',
        },
        onClick: () => appStore.setLeftPanelType('Pages'),
      },
      text: {
        value: t('pageSettings.pageSettings'),
      },
    },
    text: {
      value: t('pageSettings.name'),
    },
    inputField: {
      textValue: pageStore.currentPage?.name || '',
      onSubmit: (value) => {
        if (pageStore.currentPage) {
          void pageStore.updatePage(pageStore.currentPage, {
            name: value.trim(),
          });
        }
      },
    },
    button: {
      icon: {
        asset: isHomePage ? 'Home' : 'AddHome',
        colour: isHomePage ? 'NeturalHoverSelected' : 'NeutralDefault',
      },
      onClick: () => {
        if (pageStore.currentPage && !isHomePage) {
          void pageStore.updatePage(pageStore.currentPage, {
            isHomePage: true,
          });
        }
      },
    },
    text1: {
      value: t('pageSettings.slug'),
    },
    inputField1: {
      textValue: `/${slugValue}`,
      onValidateText: (value) => value === `/${nameToSlug(value)}`,
      onSubmit: (value) => {
        if (pageStore.currentPage) {
          void pageStore.updatePage(pageStore.currentPage, {
            slug: `/${nameToSlug(value)}`,
          });
        }
      },
    },
    text2: {
      value: t('pageSettings.path'),
    },
    inputField2: {
      type: 'TextArea',
      textValue: `https://app.spindl.io/${slugValue}${variableUrlValue}`,
      disabled: true,
    },
  });
};

export default usePresenter;
