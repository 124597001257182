import React from 'react';
import cx from 'classnames';

import Button from '../Button';
import PropertyItem from '../../molecules/PropertyItem';

import styles from './ReplaceButton.module.scss';
import usePresenter from './ReplaceButton.presenter';
import getProps from './ReplaceButton.props';
import { ReplaceButtonCombinedProps } from './types';

const ReplaceButton: React.FC<ReplaceButtonCombinedProps> = (props) => {
  const {
    state,
    type,
    className,
    classes,
    button,
    propertyItem,
  } = usePresenter(props);

  const variantName = `${state}${type}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`replaceButton${variantName}`];

  let componentView;

  switch (variantName) {
    case 'DefaultDefault':
    case 'DefaultTextOnly': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <Button
            className={cx(styles.button, classes?.button)}
            {...internalProps.button}
            {...button}/>
        </div>
      );
      break;
    }
    case 'UploadedImage':
    case 'UploadedUpdateVariable':
    case 'UploadedVariableImage': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <PropertyItem
            className={cx(styles.propertyItem, classes?.propertyItem)}
            {...internalProps.propertyItem}
            {...propertyItem}/>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default ReplaceButton;
