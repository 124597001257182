import { KeyValuePair } from '../../../store/ApiGroupStore/types';
import { DropdownButtonWrapperProps } from '../../atoms/DropdownButton';
import { TextValueProps } from '../../atoms/Text';
import { CodeSpaceValueProps } from '../../molecules/CodeSpace';
import { InfoTestFieldValueProps } from '../../molecules/InfoTestField';
import { TabItemListValueProps } from '../../molecules/TabItemList';
import { VariableInput } from '../../organisms/InputVariableTable/types';
import { KeyValueTableCombinedProps } from '../../organisms/KeyValueTable/types';

export type FunctionBlockClasses = {
  labelContent?: string;
  text?: string;
  content?: string;
  topContent?: string;
  dropdownButton?: string;
  infoTestField?: string;
  bottomContent?: string;
  tabItemList?: string;
  dropdownButton1?: string;
  codeSpace?: string;
  keyValueTable?: string;
};

export type FunctionBlockStateEnum = 'Body' | 'Header' | 'Parameter';

export type FunctionBlockValueProps = {
  text?: TextValueProps;
  infoTestField?: InfoTestFieldValueProps;
  tabItemList?: TabItemListValueProps;
  codeSpace?: CodeSpaceValueProps;
  // MANUAL OVERRIDE STARTS
  dropdownButton?: DropdownButtonWrapperProps;
  dropdownButton1?: DropdownButtonWrapperProps;
  keyValueTable?: KeyValueTableCombinedProps;
  inputVariables?: VariableInput[];
  apiEndpointHeaders?: KeyValuePair[];
  setApiEndpointHeaders?: (keyValuePairs: KeyValuePair[]) => void;
  apiEndpointParameters?: KeyValuePair[];
  setApiEndpointParameters?: (keyValuePairs: KeyValuePair[]) => void;
  apiEndpointPath?: string;
  setApiEndpointPath?: (path: string) => void;
  apiEndpointMethod?: Methods;
  setApiEndpointMethod?: (method: Methods) => void;
  apiEndpointBody?: string;
  setApiEndpointBody?: (path: string) => void;
  hasHeadersError?: boolean;
  hasParametersError?: boolean;
  // MANUAL OVERRIDE ENDS
};

export type FunctionBlockProps = {
  state?: FunctionBlockStateEnum;
  className?: string;
  classes?: FunctionBlockClasses;
};

// MANUAL OVERRIDE STARTS
export type Methods = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
export const MethodsWithBody: Methods[] = ['PATCH', 'POST', 'PUT'];
// MANUAL OVERRIDE ENDS

export type FunctionBlockCombinedProps = FunctionBlockValueProps & FunctionBlockProps;
