import { IconProps } from '../../atoms/Icon';
import { TextDividerProps } from '../../atoms/TextDivider';

type AccordionHeaderInternalProps = {
  textDivider?: TextDividerProps;
  icon?: IconProps;
};

type AccordionHeaderPropsValues = {
  'Collapsed': AccordionHeaderInternalProps;
  'Expanded': AccordionHeaderInternalProps;
};

const propValues: AccordionHeaderPropsValues = {
  Collapsed: {
    textDivider: {
    },
    icon: {
      asset: 'ChevronRight',
      colour: 'NeutralDefault',
    },
  },
  Expanded: {
    textDivider: {
    },
    icon: {
      asset: 'ChevronDown',
      colour: 'NeutralDefault',
    },
  },
};

const getProps = (type: string): AccordionHeaderInternalProps => {
  const values: AccordionHeaderInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
