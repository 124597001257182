import React from 'react';
import cx from 'classnames';

import Icon from '../Icon';
import Text from '../Text';

import styles from './ToggleItem.module.scss';
import usePresenter from './ToggleItem.presenter';
import getProps from './ToggleItem.props';
import { ToggleItemCombinedProps } from './types';

const ToggleItem: React.FC<ToggleItemCombinedProps> = (props) => {
  const {
    size,
    type,
    state,
    style,
    className,
    classes,
    disabled,
    buttonType,
    buttonForm,
    href,
    target,
    externalLink,
    onClick,
    text,
    icon,
    styleOverride, // MANUAL OVERRIDE
  } = usePresenter(props);

  const variantName = `${size}${type}${state}${style}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`toggleItem${variantName}`];

  let componentView;

  switch (variantName) {
    case 'LargeTextSelectedDefault':
    case 'LargeTextUnselectedDefault':
    case 'SmallIconTextUnselectedDefault':
    case 'SmallTextSelectedDefault':
    case 'SmallTextUnselectedDefault': {
      componentView = (
        <button
          disabled={disabled}
          type={buttonType}
          form={buttonForm}
          onClick={onClick}
          style={styleOverride} // MANUAL OVERRIDE
          className={cx(currentStyle, className)}
        >
          <Text
            className={cx(styles.text, classes?.text)}
            {...internalProps.text}
            {...text}
          />
        </button>
      );
      break;
    }
    case 'SmallIconSelectedContextMenu':
    case 'SmallIconSelectedDefault':
    case 'SmallIconUnselectedContextMenu':
    case 'SmallIconUnselectedDefault': {
      componentView = (
        <button
          disabled={disabled}
          type={buttonType}
          form={buttonForm}
          onClick={onClick}
          style={styleOverride} // MANUAL OVERRIDE
          className={cx(currentStyle, className)}
        >
          <Icon
            className={cx(styles.icon, classes?.icon)}
            {...internalProps.icon}
            {...icon}
          />
        </button>
      );
      break;
    }
    case 'SmallIconTextSelectedDefault': {
      componentView = (
        <button
          disabled={disabled}
          type={buttonType}
          form={buttonForm}
          onClick={onClick}
          style={styleOverride} // MANUAL OVERRIDE
          className={cx(currentStyle, className)}
        >
          <Icon
            className={cx(styles.icon, classes?.icon)}
            {...internalProps.icon}
            {...icon}
          />
          <Text
            className={cx(styles.text, classes?.text)}
            {...internalProps.text}
            {...text}
          />
        </button>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default ToggleItem;
