import { ChipProps } from '../../atoms/Chip';
import { RowLabelProps } from '../../atoms/RowLabel';

type SelectedContainerInternalProps = {
  rowLabel?: RowLabelProps;
  chip?: ChipProps;
};

type SelectedContainerPropsValues = {
  '': SelectedContainerInternalProps;
};

const propValues: SelectedContainerPropsValues = {
  '': {
    rowLabel: {
      state: 'Default',
      style: 'Default',
      type: 'Panel',
    },
    chip: {
    },
  },
};

const getProps = (type: string): SelectedContainerInternalProps => {
  const values: SelectedContainerInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
