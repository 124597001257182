import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { StoreContext } from '../../../store/StoreProvider';
import { typedDeepMerge } from '../../../utils/objectUtils';
import { ActionCardCombinedProps } from './types';

const usePresenter = (props: ActionCardCombinedProps): ActionCardCombinedProps => {
  const { logicBuilderStore } = useContext(StoreContext);
  const { deleteAction } = logicBuilderStore;
  const { t } = useTranslation();

  const handleActionCardOperation = async (op: string) => {
    switch (op) {
      case 'Delete Action':
        await deleteAction();
        break;
      default:
        return;
    }
  };

  return typedDeepMerge(props, {
    data: {
      dropdownButton: {
        toggleProps: {
          type: 'MenuIcon',
          style: 'Default',
          colour: 'Default',
        },
        menuProps: {
          style: 'Menu',
          clipMenu: false,
          dropdownItems: [
            {
              text: {
                value: t('actionCard.deleteAction'),
              },
            },
          ],
          onOptionSelected: (option) => {
            void handleActionCardOperation(option);
          },
        },
      },
    },
  });
};

export default usePresenter;
