import React, { Fragment } from 'react';
import DropZone from '../../utils/DropZone';
import ElementRenderer from '../ElementRenderer';
import usePresenter from './ElementChildrenRenderer.presenter';
import type { ElementChildrenRendererProps } from './types';

const ElementChildrenRenderer: React.FC<ElementChildrenRendererProps> = (props) => {
  const { parentId, parentRef, childIds } = usePresenter(props);

  return (
    <>
      <DropZone parentId={parentId} parentRef={parentRef} dropZoneIndex={0} />
      {childIds.map((childId: string, index: number) => (
        <Fragment key={childId}>
          <ElementRenderer id={childId} />
          <DropZone parentId={parentId} parentRef={parentRef} dropZoneIndex={index + 1} />
        </Fragment>
      ))}
    </>
  );
};

export default ElementChildrenRenderer;
