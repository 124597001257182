import React from 'react';
import cx from 'classnames';

import ResponseItem from '../ResponseItem';

import styles from './ResponseItemList.module.scss';
import usePresenter from './ResponseItemList.presenter';
import getProps from './ResponseItemList.props';
import { ResponseItemListCombinedProps } from './types';

const ResponseItemList: React.FC<ResponseItemListCombinedProps> = (props) => {
  const {
    className,
    classes,
    responseItems,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.responseItemList;

  const responseItemViews = responseItems?.map((responseItem, index) => (
    <ResponseItem
      key={index}
      className={cx(styles.responseItem, classes?.responseItem)}
      {...internalProps.responseItem}
      {...responseItem} />
  )) || [];

  return (
    <div className={cx(currentStyle, className)}>
      {responseItemViews}
    </div>
  );
};

export default ResponseItemList;
