import { ButtonProps } from '../../atoms/Button';

type AlignmentGridInternalProps = {
  button?: ButtonProps;
  button1?: ButtonProps;
  button2?: ButtonProps;
  button3?: ButtonProps;
  button4?: ButtonProps;
  button5?: ButtonProps;
  button6?: ButtonProps;
  button7?: ButtonProps;
  button8?: ButtonProps;
};

type AlignmentGridPropsValues = {
  '': AlignmentGridInternalProps;
};

const propValues: AlignmentGridPropsValues = {
  '': {
    button: {
      type: 'Image',
      size: 'M',
      state: 'Default',
      style: 'PrimarySubdued',
    },
    button1: {
      type: 'Image',
      size: 'M',
      state: 'Default',
      style: 'PrimarySubdued',
    },
    button2: {
      type: 'Image',
      size: 'M',
      state: 'Default',
      style: 'PrimarySubdued',
    },
    button3: {
      type: 'Image',
      size: 'M',
      state: 'Default',
      style: 'PrimarySubdued',
    },
    button4: {
      type: 'Image',
      size: 'M',
      state: 'Default',
      style: 'PrimarySubdued',
    },
    button5: {
      type: 'Image',
      size: 'M',
      state: 'Default',
      style: 'PrimarySubdued',
    },
    button6: {
      type: 'Image',
      size: 'M',
      state: 'Default',
      style: 'PrimarySubdued',
    },
    button7: {
      type: 'Image',
      size: 'M',
      state: 'Default',
      style: 'PrimarySubdued',
    },
    button8: {
      type: 'Image',
      size: 'M',
      state: 'Default',
      style: 'PrimarySubdued',
    },
  },
};

const getProps = (type: string): AlignmentGridInternalProps => {
  const values: AlignmentGridInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
