import { ButtonProps } from '../Button';

type AddActionInternalProps = {
  button?: ButtonProps;
};

type AddActionPropsValues = {
  '': AddActionInternalProps;
};

const propValues: AddActionPropsValues = {
  '': {
    button: {
      type: 'IconRounded',
      size: 'M',
      state: 'Default',
      style: 'Primary',
    },
  },
};

const getProps = (type: string): AddActionInternalProps => {
  const values: AddActionInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
