import { ButtonProps } from '../../atoms/Button';
import { DropdownButtonProps } from '../../atoms/DropdownButton';
import { IconProps } from '../../atoms/Icon';
import { TextProps } from '../../atoms/Text';
import { UpdateArrowProps } from '../../atoms/UpdateArrow';

type ActionCardInternalProps = {
  icon?: IconProps;
  text?: TextProps;
  dropdownButton?: DropdownButtonProps;
  text1?: TextProps;
  text2?: TextProps;
  button?: ButtonProps;
  updateArrow?: UpdateArrowProps;
};

type ActionCardPropsValues = {
  'DoubleLineDefault': ActionCardInternalProps;
  'DoubleLineSelected': ActionCardInternalProps;
  'EmptyDefault': ActionCardInternalProps;
  'EmptySelected': ActionCardInternalProps;
  'NavigateURLDefault': ActionCardInternalProps;
  'NavigateURLSelected': ActionCardInternalProps;
  'SingleLineDefault': ActionCardInternalProps;
  'SingleLineSelected': ActionCardInternalProps;
  'UpdateVarDefault': ActionCardInternalProps;
  'UpdateVarSelected': ActionCardInternalProps;
};

const propValues: ActionCardPropsValues = {
  'DoubleLineDefault': {
    icon: {
      asset: 'Action',
      colour: 'NeturalHoverSelected',
    },
    text: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Semibold600',
      colour: 'NeutralHoverSelected',
    },
    dropdownButton: {
      type: 'MenuIcon',
      style: 'Default',
      state: 'Collapsed',
      colour: 'Default',
    },
    text1: {
      type: 'LabelExtraSmall',
      align: 'Left',
      style: 'Regular400',
      colour: 'NeutralDefault',
    },
    text2: {
      type: 'LabelExtraSmall',
      align: 'Left',
      style: 'Regular400',
      colour: 'NeutralDefault',
    },
  },
  'DoubleLineSelected': {
    icon: {
      asset: 'Action',
      colour: 'NeturalHoverSelected',
    },
    text: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Semibold600',
      colour: 'NeutralHoverSelected',
    },
    dropdownButton: {
      type: 'MenuIcon',
      style: 'Default',
      state: 'Collapsed',
      colour: 'Default',
    },
    text1: {
      type: 'LabelExtraSmall',
      align: 'Left',
      style: 'Regular400',
      colour: 'NeutralDefault',
    },
    text2: {
      type: 'LabelExtraSmall',
      align: 'Left',
      style: 'Regular400',
      colour: 'NeutralDefault',
    },
  },
  'EmptyDefault': {
    icon: {
      asset: 'Action',
      colour: 'NeturalHoverSelected',
    },
    text: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Semibold600',
      colour: 'NeutralHoverSelected',
    },
    dropdownButton: {
      type: 'MenuIcon',
      style: 'Default',
      state: 'Collapsed',
      colour: 'Default',
    },
    text1: {
      type: 'LabelExtraSmall',
      align: 'Left',
      style: 'Italic400',
      colour: 'NeutralDefault',
    },
    text2: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Regular400',
      colour: 'NeutralDefault',
    },
  },
  'NavigateURLDefault': {
    icon: {
      asset: 'Action',
      colour: 'NeturalHoverSelected',
    },
    text: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Semibold600',
      colour: 'NeutralHoverSelected',
    },
    dropdownButton: {
      type: 'MenuIcon',
      style: 'Default',
      state: 'Collapsed',
      colour: 'Default',
    },
    button: {
      type: 'Icon',
      size: 'S',
      state: 'Default',
      style: 'PrimarySubdued',
    },
    text1: {
      type: 'LabelExtraSmall',
      align: 'Left',
      style: 'Regular400',
      colour: 'NeutralDefault',
    },
    text2: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Italic400',
      colour: 'NeutralDefault',
    },
  },
  'NavigateURLSelected': {
    icon: {
      asset: 'Action',
      colour: 'NeturalHoverSelected',
    },
    text: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Semibold600',
      colour: 'NeutralHoverSelected',
    },
    dropdownButton: {
      type: 'MenuIcon',
      style: 'Default',
      state: 'Collapsed',
      colour: 'Default',
    },
    button: {
      type: 'Icon',
      size: 'S',
      state: 'Default',
      style: 'PrimarySubdued',
    },
    text1: {
      type: 'LabelExtraSmall',
      align: 'Left',
      style: 'Regular400',
      colour: 'NeutralDefault',
    },
    text2: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Italic400',
      colour: 'NeutralDefault',
    },
  },
  'SingleLineDefault': {
    icon: {
      asset: 'Action',
      colour: 'NeturalHoverSelected',
    },
    text: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Semibold600',
      colour: 'NeutralHoverSelected',
    },
    dropdownButton: {
      type: 'MenuIcon',
      style: 'Default',
      state: 'Collapsed',
      colour: 'Default',
    },
    text1: {
      type: 'LabelExtraSmall',
      align: 'Left',
      style: 'Regular400',
      colour: 'NeutralDefault',
    },
    text2: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Regular400',
      colour: 'NeutralDefault',
    },
  },
  'SingleLineSelected': {
    icon: {
      asset: 'Action',
      colour: 'NeturalHoverSelected',
    },
    text: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Semibold600',
      colour: 'NeutralHoverSelected',
    },
    dropdownButton: {
      type: 'MenuIcon',
      style: 'Default',
      state: 'Collapsed',
      colour: 'Default',
    },
    text1: {
      type: 'LabelExtraSmall',
      align: 'Left',
      style: 'Regular400',
      colour: 'NeutralDefault',
    },
    text2: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Regular400',
      colour: 'NeutralDefault',
    },
  },
  'UpdateVarDefault': {
    icon: {
      asset: 'Action',
      colour: 'NeturalHoverSelected',
    },
    text: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Semibold600',
      colour: 'NeutralHoverSelected',
    },
    dropdownButton: {
      type: 'MenuIcon',
      style: 'Default',
      state: 'Collapsed',
      colour: 'Default',
    },
    updateArrow: {},
    text1: {
      type: 'LabelExtraSmall',
      align: 'Left',
      style: 'Regular400',
      colour: 'NeutralDefault',
    },
    text2: {
      type: 'ExtraSmall',
      align: 'Left',
      style: 'Regular400',
      colour: 'ActionDefault',
    },
  },
  'UpdateVarSelected': {
    icon: {
      asset: 'Action',
      colour: 'NeturalHoverSelected',
    },
    text: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Semibold600',
      colour: 'NeutralHoverSelected',
    },
    dropdownButton: {
      type: 'MenuIcon',
      style: 'Default',
      state: 'Collapsed',
      colour: 'Default',
    },
    updateArrow: {},
    text1: {
      type: 'LabelExtraSmall',
      align: 'Left',
      style: 'Regular400',
      colour: 'NeutralDefault',
    },
    text2: {
      type: 'ExtraSmall',
      align: 'Left',
      style: 'Regular400',
      colour: 'ActionDefault',
    },
  },
  'EmptySelected': {
    icon: {
      asset: 'Action',
      colour: 'NeturalHoverSelected',
    },
    text: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Semibold600',
      colour: 'NeutralHoverSelected',
    },
    button: {
      type: 'IconClear',
      size: 'S',
      state: 'Default',
      style: 'PrimarySubdued',
    },
    text1: {
      type: 'LabelExtraSmall',
      align: 'Left',
      style: 'Italic400',
      colour: 'NeutralDefault',
    },
    text2: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Regular400',
      colour: 'NeutralDefault',
    },
  },
};

const getProps = (type: string): ActionCardInternalProps => {
  const values: ActionCardInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
