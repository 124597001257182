import { VariableCardProps } from '../VariableCard';

type VariableCardListInternalProps = {
  variableCard?: VariableCardProps;
};

type VariableCardListPropsValues = {
  'Large': VariableCardListInternalProps;
  'Small': VariableCardListInternalProps;
};

const propValues: VariableCardListPropsValues = {
  Large: {
    variableCard: {
      size: 'Large',
      type: 'Default',
    },
  },
  Small: {
    variableCard: {
      size: 'Small',
      type: 'Page',
    },
  },
};

const getProps = (type: string): VariableCardListInternalProps => {
  const values: VariableCardListInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
