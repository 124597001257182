import React from 'react';
import cx from 'classnames';

import Text from '../Text';

import styles from './MethodChip.module.scss';
import usePresenter from './MethodChip.presenter';
import getProps from './MethodChip.props';
import { MethodChipCombinedProps } from './types';

const MethodChip: React.FC<MethodChipCombinedProps> = (props) => {
  const {
    type,
    className,
    classes,
    text,
  } = usePresenter(props);

  const variantName = `${type}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`methodChip${variantName}`];

  let componentView;

  switch (variantName) {
    case 'Delete':
    case 'Get':
    case 'Patch':
    case 'Post':
    case 'Put': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <Text
            className={cx(styles.text, classes?.text)}
            {...internalProps.text}
            {...text}
          />
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default MethodChip;
