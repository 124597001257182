import { PaddingMarginGridProps } from '../../molecules/PaddingMarginGrid';
import { PanelHeaderProps } from '../../molecules/PanelHeader';

type PaddingMarginsPanelInternalProps = {
  panelHeader?: PanelHeaderProps;
  paddingMarginGrid?: PaddingMarginGridProps;
};

type PaddingMarginsPanelPropsValues = {
  'Collapsed': PaddingMarginsPanelInternalProps;
  'Expanded': PaddingMarginsPanelInternalProps;
};

const propValues: PaddingMarginsPanelPropsValues = {
  Collapsed: {
    panelHeader: {
      state: 'Collapsed',
      type: 'Default',
    },
  },
  Expanded: {
    panelHeader: {
      state: 'Expanded',
      type: 'Default',
    },
    paddingMarginGrid: {
    },
  },
};

const getProps = (type: string): PaddingMarginsPanelInternalProps => {
  const values: PaddingMarginsPanelInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
