import React from 'react';
import cx from 'classnames';

import Button from '../Button';
import Icon from '../Icon';

import styles from './RowHoverCap.module.scss';
import usePresenter from './RowHoverCap.presenter';
import getProps from './RowHoverCap.props';
import { RowHoverCapCombinedProps } from './types';

const RowHoverCap: React.FC<RowHoverCapCombinedProps> = (props) => {
  const {
    type,
    className,
    classes,
    button,
    icon,
  } = usePresenter(props);

  const variantName = `${type}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`rowHoverCap${variantName}`];

  let componentView;

  switch (variantName) {
    case 'Clickable': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <Button
            className={cx(styles.button, classes?.button)}
            {...internalProps.button}
            {...button}/>
        </div>
      );
      break;
    }
    case 'NonClickable': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <Icon
            className={cx(styles.icon, classes?.icon)}
            {...internalProps.icon}
            {...icon}/>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default RowHoverCap;
