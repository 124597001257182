import { ToggleItemProps } from '../../atoms/ToggleItem';

type ToggleItemListInternalProps = {
  toggleItem?: ToggleItemProps;
};

type ToggleItemListPropsValues = {
  'LargeTextDefault': ToggleItemListInternalProps;
  'SmallIconDefault': ToggleItemListInternalProps;
  'SmallIconTextDefault': ToggleItemListInternalProps;
  'SmallTextDefault': ToggleItemListInternalProps;
};

const propValues: ToggleItemListPropsValues = {
  LargeTextDefault: {
    toggleItem: {
      size: 'Large',
      type: 'Text',
      state: 'Unselected',
      style: 'Default',
    },
  },
  SmallIconDefault: {
    toggleItem: {
      size: 'Small',
      type: 'Icon',
      state: 'Unselected',
      style: 'Default',
    },
  },
  SmallIconTextDefault: {
    toggleItem: {
      size: 'Small',
      type: 'IconText',
      state: 'Unselected',
      style: 'Default',
    },
  },
  SmallTextDefault: {
    toggleItem: {
      size: 'Small',
      type: 'Text',
      state: 'Unselected',
      style: 'Default',
    },
  },
};

const getProps = (type: string): ToggleItemListInternalProps => {
  const values: ToggleItemListInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
