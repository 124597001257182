import React from 'react';
import cx from 'classnames';

import Text from '../Text';

import styles from './TextDivider.module.scss';
import usePresenter from './TextDivider.presenter';
import getProps from './TextDivider.props';
import { TextDividerCombinedProps } from './types';

const TextDivider: React.FC<TextDividerCombinedProps> = (props) => {
  const {
    className,
    classes,
    text,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.textDivider;

  return (
    <div className={cx(currentStyle, className)}>
      <Text
        className={cx(styles.text, classes?.text)}
        {...internalProps.text}
        {...text}
      />
    </div>
  );
};

export default TextDivider;
