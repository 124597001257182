import { PanelHeaderProps } from '../../molecules/PanelHeader';
import { ReplaceRowListProps } from '../../molecules/ReplaceRowList';

type ValuesPanelInternalProps = {
  panelHeader?: PanelHeaderProps;
  replaceRowList?: ReplaceRowListProps;
};

type ValuesPanelPropsValues = {
  'Collapsed': ValuesPanelInternalProps;
  'Expanded': ValuesPanelInternalProps;
};

const propValues: ValuesPanelPropsValues = {
  'Collapsed': {
    panelHeader: {
      state: 'Collapsed',
      type: 'Default',
    },
  },
  'Expanded': {
    panelHeader: {
      state: 'Expanded',
      type: 'Default',
    },
    replaceRowList: {
    },
  },
};

const getProps = (type: string): ValuesPanelInternalProps => {
  const values: ValuesPanelInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
