import { useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfigureNavigationVariableMenuContext } from '.';
import { useExpandablePanel } from '../../../hooks';
import useModal from '../../../modules/common/ModalWrapper/context/useModal';
import { getCustomPositionForElementRight } from '../../../modules/common/ModalWrapper/utils';
import { PageNavigationVariable } from '../../../modules/variables/types';
import { createVariableApi, deleteVariableApi, updateVariableApi } from '../../../modules/variables/variables.repository';
import { getPageNavigationVariables } from '../../../store/PageStore/variableUtils';
import { StoreContext } from '../../../store/StoreProvider';
import { typedDeepMerge } from '../../../utils/objectUtils';
import { VariableCardValueProps } from '../../molecules/VariableCard';
import type { PageVariablesPanelCombinedProps } from './types';

const NEW_PAGE_VARIABLE_UUID = 'new-page-variable';

const usePresenter = (props: PageVariablesPanelCombinedProps): PageVariablesPanelCombinedProps => {
  const { pageStore } = useContext(StoreContext);
  const { t } = useTranslation();

  const { currentPageVariables, appendVariable, updateVariable, removeVariable, currentPage } = pageStore;

  const expandableProps = useExpandablePanel(props, 'Expanded', true);

  const pageNavigationVariables = useMemo(() => getPageNavigationVariables(
    currentPageVariables,
    currentPage?.uuid,
  ), [
    currentPageVariables,
    currentPage,
  ]);

  const [editingVariable, setEditingVariable] = useState<PageNavigationVariable>();
  const [variableChanged, setVariableChanged] = useState<boolean>(false);

  const applyLocalChanges = useCallback((variable?: PageNavigationVariable) => {
    if (variable) {
      updateVariable(variable);
      setVariableChanged(true);
    }
  }, [updateVariable]);

  const handleSaveVariable = useCallback(async (variable?: PageNavigationVariable) => {
    setEditingVariable(variable);
    if (variable) {
      const { uuid, ...rest } = variable;
      // if we are saving a new variable then call the create endpoint. otherwise call the update endpoint.
      if (uuid === NEW_PAGE_VARIABLE_UUID) {
        const updatedVariable = await createVariableApi(rest);
        removeVariable(variable); // remove placeholder
        appendVariable(updatedVariable); // append real variable
      } else {
        const updatedVariable = await updateVariableApi({
          variableUuid: uuid,
          ...rest,
        });
        updateVariable(updatedVariable);
      }
    }
  }, [updateVariable, appendVariable, removeVariable]);

  const handleDeleteVariable = useCallback(async (variable?: PageNavigationVariable) => {
    if (variable) {
      await deleteVariableApi({
        variableUuid: variable.uuid,
      });
      removeVariable(variable);
    }
  }, [removeVariable]);

  const handleCloseModal = useCallback(() => {
    if (editingVariable) {
      // if we are reseting new variable then simple remove the placeholder. otherwise set the initial value provided.
      if (editingVariable.uuid === NEW_PAGE_VARIABLE_UUID) {
        removeVariable(editingVariable);
      } else if (variableChanged) {
        updateVariable(editingVariable);
      }
    }
  }, [editingVariable, variableChanged, removeVariable, updateVariable]);

  const { modalContainerRef, openModal } = useModal(
    ConfigureNavigationVariableMenuContext,
    () => {
      return {
        style: editingVariable?.uuid === NEW_PAGE_VARIABLE_UUID ? 'Default' : 'Removeable',
        variable: editingVariable,
        applyLocalChanges,
        handleSaveVariable,
        handleDeleteVariable,
        onClose: handleCloseModal,
      };
    },
  );

  const openVariableModal = useCallback((variable: PageNavigationVariable) => {
    setEditingVariable(variable);
    setVariableChanged(false);
    openModal({
      customPosition: getCustomPositionForElementRight(modalContainerRef, 4),
    });
  }, [
    modalContainerRef,
    openModal,
  ]);

  const handleAddVariable = () => {
    const newPageVariable: PageNavigationVariable = {
      uuid: NEW_PAGE_VARIABLE_UUID,
      name: '',
      source: 'PageNavigation',
      scope: 'Page',
      pageUuid: currentPage?.uuid,
      type: 'string',
      metadata: {
        isPathParam: false,
        alias: '',
      },
    };
    appendVariable(newPageVariable);
    openVariableModal(newPageVariable);
  };

  const variableCards = useMemo(() => {
    return pageNavigationVariables
      .map((variable): VariableCardValueProps => {
        return {
          variableName: {
            value: `{${variable.name}}`,
          },
          text: {
            value: variable.type,
          },
          onClick: () => {
            openVariableModal(variable);
          },
        };
      });
  }, [pageNavigationVariables, openVariableModal]);

  return {
    ...typedDeepMerge(expandableProps, {
      panelHeader: {
        text: {
          value: t('pageVariablesPanel.variables'),
        },
      },
      button: {
        icon: {
          asset: 'AddVariable',
        },
        text: {
          value: t('pageVariablesPanel.addNewVariable'),
        },
        onClick: handleAddVariable,
      },
      variableCardList: {
        variableCards,
      },
    }),
    variablesContainerRef: modalContainerRef,
  };
};

export default usePresenter;
