import { ButtonProps } from '../../atoms/Button';
import { EmptyStateInfoProps } from '../../atoms/EmptyStateInfo';
import { TextProps } from '../../atoms/Text';
import { CodeSpaceProps } from '../../molecules/CodeSpace';
import { KeyValueTableFilledTextTextValueProps } from '../../organisms/KeyValueTableFilledTextTextValue';

type TestVariableBlockInternalProps = {
  text?: TextProps;
  emptyStateInfo?: EmptyStateInfoProps;
  keyValueTableFilledTextTextValue?: KeyValueTableFilledTextTextValueProps;
  button?: ButtonProps;
  codeSpace?: CodeSpaceProps;
};

type TestVariableBlockPropsValues = {
  'Empty': TestVariableBlockInternalProps;
  'Filled': TestVariableBlockInternalProps;
  'Collapsed': TestVariableBlockInternalProps;
  'Disabled': TestVariableBlockInternalProps;
  'Expanded': TestVariableBlockInternalProps;
};

const propValues: TestVariableBlockPropsValues = {
  'Empty': {
    text: {
      type: 'LabelMedium',
      align: 'Left',
      style: 'Semibold600',
      colour: 'NeutralHoverSelected',
    },
    emptyStateInfo: {
    },
  },
  'Filled': {
    text: {
      type: 'LabelMedium',
      align: 'Left',
      style: 'Semibold600',
      colour: 'NeutralHoverSelected',
    },
    keyValueTableFilledTextTextValue: {
    },
  },
  'Collapsed': {
    text: {
      type: 'LabelMedium',
      align: 'Left',
      style: 'Semibold600',
      colour: 'NeutralHoverSelected',
    },
    button: {
      type: 'IconClear',
      size: 'M',
      state: 'Selected',
      style: 'PrimarySubdued',
    },
  },
  'Disabled': {
    text: {
      type: 'LabelMedium',
      align: 'Left',
      style: 'Semibold600',
      colour: 'NeutralHoverSelected',
    },
    button: {
      type: 'IconClear',
      size: 'M',
      state: 'Selected',
      style: 'PrimarySubdued',
    },
  },
  'Expanded': {
    text: {
      type: 'LabelMedium',
      align: 'Left',
      style: 'Semibold600',
      colour: 'NeutralHoverSelected',
    },
    button: {
      type: 'IconClear',
      size: 'M',
      state: 'Selected',
      style: 'PrimarySubdued',
    },
    codeSpace: {
    },
  },
};

const getProps = (type: string): TestVariableBlockInternalProps => {
  const values: TestVariableBlockInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
