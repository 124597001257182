import cx from 'classnames';
import { computed } from 'mobx';
import { useContext, type FC } from 'react';
import { getElement } from '../../../../store/PageStore/utils';
import { StoreContext } from '../../../../store/StoreProvider';
import type { Element } from '../../../../store/types';
import { useElementDragState } from '../../hooks/useElementDragState';
import { useElementDropState } from '../../hooks/useElementDropState';
import { useElementHoverable } from '../../hooks/useElementHoverable';
import { useElementSelectable } from '../../hooks/useElementSelectable';
import { ELEMENT_VIEWS } from './config';
import styles from './ElementRenderer.module.scss';
import type { ElementRendererProps, ElementViewProps } from './types';

const usePresenter = (props: ElementRendererProps): ElementRendererProps => {
  const { pageStore } = useContext(StoreContext);

  const { id, className: initialClassName } = props;
  const element: Element | undefined = computed(() => getElement(id, pageStore)).get();
  const parent: Element | undefined = element ? computed(() => getElement(element.parentId, pageStore)).get() : undefined;

  let ElementView: FC<ElementViewProps<Element>> | undefined;
  if (element) {
    ElementView = ELEMENT_VIEWS[element.type] as FC<ElementViewProps<Element>>;
    if (!ElementView) {
      console.warn(`Element type '${element.type}' is not supported.`);
    }
  } else {
    console.warn(`Element id: ${id} does not exist in element tree.`);
  }

  const { elementSelectionState, ...selectHandlers } = useElementSelectable({ id, shouldAutoExpandAndScrollIntoView: true });

  const { isElementHovered, ...hoverHandlers } = useElementHoverable(element);

  const { isElementDragging } = useElementDragState(element);

  const { isDropAllowed, isDropForbidden } = useElementDropState(element);

  const className: string = cx(initialClassName, {
    [styles.selected]: elementSelectionState.isElementSelected,
    [styles.hovered]: (isElementHovered || isDropAllowed) && !isElementDragging,
    [styles.dragging]: isElementDragging,
    [styles.dropForbidden]: isDropForbidden,
  });

  return {
    ...props,
    element,
    parent,
    settings: element?.settings, // listen for settings changes to re-render
    ElementView,
    className,
    editing: pageStore.editingElement?.id === id,
    elementSelectionState,
    ...selectHandlers,
    isElementHovered,
    ...hoverHandlers,
  };
};

export default usePresenter;
