import cx from 'classnames';
import { IconCombinedProps } from '../Icon/types';
import customStyles from './Custom.module.scss';
import { ButtonCombinedProps } from './types';

const checkIconHoverStyle = (iconProps?: IconCombinedProps) => {
  switch (iconProps?.asset) {
    case 'Error':
    case 'ErrorDot':
    case 'Global':
    case 'Page':
    case 'Action':
    case 'AutoCursor':
    case 'Pointer':
    case 'OpenHand':
    case 'ClosedHand':
    case 'Variable':
      return false;
    default:
      return true;
  }
};

const checkVariableIconHoverStyle = (iconProps?: IconCombinedProps) => {
  return iconProps?.asset === 'Variable' && iconProps?.colour !== 'VariableSelected';
};

const usePresenter = (props: ButtonCombinedProps): ButtonCombinedProps => {
  let iconStyle: string | undefined;
  if (checkIconHoverStyle(props.icon)) {
    iconStyle = customStyles.icon;
  } else if (checkVariableIconHoverStyle(props.icon)) {
    iconStyle = customStyles.variableIcon;
  }
  return {
    ...props,
    className: cx(props.className, customStyles.button, customStyles[`button${props.style}`]),
    classes: {
      icon: iconStyle,
      text: customStyles.text,
    },
  };
};

export default usePresenter;
