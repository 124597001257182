import { Constants } from '../../constants';
import {
  AnchorProperty,
  borderStyleTypeValues,
  BreakpointElementsMap,
  cursorTypeValues,
  directionTypeValues,
  Element,
  EnumTypeProperty,
  fontAlignTypeValues,
  fontStyleTypeValues,
  fontTypeValues,
  fontWeightTypeValues,
  horizontalValues,
  IWithId,
  overflowTypeValues,
  PageElementsMap,
  PartialElement,
  positionTypeValues,
  spacingTypeValues,
  UnitTypeProperty,
  verticalValues,
} from '../types';
import { ELEMENT_CONFIGURATIONS } from './config';
import { PageStore } from './PageStore';

export * from './unitTypeUtils';
export * from './anchorUtils';
export * from './settingsUtils';
export * from './variableUtils';

export const getCombinedElement = (
  target: IWithId | undefined,
  elements: PageElementsMap,
  breakpointElements: PageElementsMap<PartialElement>,
): Element | undefined => {
  if (target) {
    const element = elements[target.id];
    const breakpointElement = breakpointElements[target.id];
    if (element && breakpointElement) {
      return {
        ...element,
        settings: {
          ...(element.settings as any),
          ...(breakpointElement.settings as any), // TODO re-enable
        },
        styles: {
          ...element.styles,
          ...breakpointElement.styles,
        },
      };
    }
    return element;
  }
  return undefined;
};

export const getElement = (id: string, pageStore: PageStore): Element | undefined => {
  return getCombinedElement({ id }, pageStore.elements, pageStore.breakpointElements);
};

export const getStylePanelConfigurations = (element?: Element) => {
  if (element) {
    return ELEMENT_CONFIGURATIONS[element.type].stylePanelConfigurations;
  }
  return undefined;
};

export const getUnitsBasedOnProperty = (property: UnitTypeProperty | AnchorProperty) => {
  switch (property) {
    case 'height':
    case 'width': {
      return Constants.DROPDOWN_OPTIONS.DIMENSION_UNITS;
    }
    case 'maxWidth':
    case 'maxHeight': {
      return Constants.DROPDOWN_OPTIONS.MAX_VALUE_UNITS;
    }
    case 'minWidth':
    case 'minHeight':
    case 'gap':
    case 'rowGap':
    case 'horizontalAnchor':
    case 'verticalAnchor':
    case 'backgroundHorizontalAnchor':
    case 'backgroundVerticalAnchor': {
      return Constants.DROPDOWN_OPTIONS.MIN_VALUE_UNITS;
    }
    default: {
      return [];
    }
  }
};

export const getEnumValuesBasedOnProperty = (property: EnumTypeProperty | AnchorProperty) => {
  switch (property) {
    case 'overflow':
      return overflowTypeValues;
    case 'spacing':
      return spacingTypeValues;
    case 'direction':
      return directionTypeValues;
    case 'position':
      return positionTypeValues;
    case 'font':
      return fontTypeValues;
    case 'fontWeight':
      return fontWeightTypeValues;
    case 'fontAlign':
      return fontAlignTypeValues;
    case 'fontStyle':
      return fontStyleTypeValues;
    case 'borderStyle':
      return borderStyleTypeValues;
    case 'cursor':
      return cursorTypeValues;
    case 'verticalAnchor':
    case 'backgroundVerticalAnchor':
      return verticalValues;
    case 'horizontalAnchor':
    case 'backgroundHorizontalAnchor':
      return horizontalValues;
    default: {
      return [];
    }
  }
};

export const getEnumValue = (
  selectedElement: Element | undefined,
  property: EnumTypeProperty,
) => {
  return selectedElement?.styles[property];
};

export const updateEnumValue = async (
  pageStore: PageStore,
  selectedElement: Element | undefined,
  property: EnumTypeProperty,
  value: string,
) => {
  if (!selectedElement) {
    return;
  }
  await pageStore.updateElement(selectedElement, {
    styles: {
      [property]: value,
    },
  });
  return;
};
