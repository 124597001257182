import React from 'react';
import cx from 'classnames';

import Button from '../../atoms/Button';
import Text from '../../atoms/Text';
import TableRowList from '../../molecules/TableRowList';

import styles from './InputVariableTable.module.scss';
import usePresenter from './InputVariableTable.presenter';
import getProps from './InputVariableTable.props';
import { InputVariableTableCombinedProps } from './types';

const InputVariableTable: React.FC<InputVariableTableCombinedProps> = (props) => {
  const {
    state,
    className,
    classes,
    name,
    type,
    button,
    tableRowList,
  } = usePresenter(props);

  const variantName = `${state}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`inputVariableTable${variantName}`];

  let componentView;

  switch (variantName) {
    case 'Empty': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <div className={cx(styles.headerContent, classes?.headerContent)}>
            <Text
              className={cx(styles.name, classes?.name)}
              {...internalProps.name}
              {...name}
            />
            <Text
              className={cx(styles.type, classes?.type)}
              {...internalProps.type}
              {...type}
            />
          </div>
          <div className={cx(styles.tableContent, classes?.tableContent)}>
            <Button
              className={cx(styles.button, classes?.button)}
              {...internalProps.button}
              {...button}
            />
          </div>
        </div>
      );
      break;
    }
    case 'Filled': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <div className={cx(styles.headerContent, classes?.headerContent)}>
            <Text
              className={cx(styles.name, classes?.name)}
              {...internalProps.name}
              {...name}
            />
            <Text
              className={cx(styles.type, classes?.type)}
              {...internalProps.type}
              {...type}
            />
          </div>
          <div className={cx(styles.tableContent, classes?.tableContent)}>
            <TableRowList
              className={cx(styles.tableRowList, classes?.tableRowList)}
              {...internalProps.tableRowList}
              {...tableRowList}
            />
            <Button
              className={cx(styles.button, classes?.button)}
              {...internalProps.button}
              {...button}
            />
          </div>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default InputVariableTable;
