import { ReplaceRowProps } from '../ReplaceRow';

type UpdateValueInternalProps = {
  replaceRow?: ReplaceRowProps;
};

type UpdateValuePropsValues = {
  '': UpdateValueInternalProps;
};

const propValues: UpdateValuePropsValues = {
  '': {
    replaceRow: {
      type: 'VariableButton',
      state: 'Default',
      style: 'VariableVertical',
    },
  },
};

const getProps = (type: string): UpdateValueInternalProps => {
  const values: UpdateValueInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
