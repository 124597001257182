import { ButtonProps } from '../../atoms/Button';
import { EmptyStateInfoProps } from '../../atoms/EmptyStateInfo';
import { StatusChipProps } from '../../atoms/StatusChip';
import { TextProps } from '../../atoms/Text';
import { TestResponseItemListProps } from '../../molecules/TestResponseItemList';

type ResponseStructureBlockInternalProps = {
  text?: TextProps;
  button?: ButtonProps;
  emptyStateInfo?: EmptyStateInfoProps;
  statusChip?: StatusChipProps;
  testResponseItemList?: TestResponseItemListProps;
};

type ResponseStructureBlockPropsValues = {
  'Empty': ResponseStructureBlockInternalProps;
  'Filled': ResponseStructureBlockInternalProps;
};

const propValues: ResponseStructureBlockPropsValues = {
  'Empty': {
    text: {
      type: 'LabelMedium',
      align: 'Left',
      style: 'Semibold600',
      colour: 'NeutralHoverSelected',
    },
    button: {
      type: 'Text',
      size: 'M',
      state: 'Default',
      style: 'Primary',
    },
    emptyStateInfo: {
    },
  },
  'Filled': {
    text: {
      type: 'LabelMedium',
      align: 'Left',
      style: 'Semibold600',
      colour: 'NeutralHoverSelected',
    },
    statusChip: {
      state: 'Default',
    },
    button: {
      type: 'Text',
      size: 'M',
      state: 'Default',
      style: 'Primary',
    },
    testResponseItemList: {
    },
  },
};

const getProps = (type: string): ResponseStructureBlockInternalProps => {
  const values: ResponseStructureBlockInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
