import type { TFunction } from 'i18next';
import { Constants } from '../../../constants';
import { ApiResponseVariable, VariableScope, variableScopes, VariableValueType } from '../../../modules/variables/types';
import { mapScopeIcon } from '../../../store/PageStore/variableUtils';
import { isValidText, numberValidation } from '../../../utils/inputValidations';
import type { DropdownItemCombinedProps } from '../../atoms/DropdownItem/types';
import { BooleanValueTypes, VariableTypes, type VariableInput } from '../../organisms/InputVariableTable/types';
import type { TableRowCombinedProps, TableRowTypeEnum } from '../TableRow/types';
import type { TableRowListValueProps } from './types';

// TODO: Move VariableInput type somewhere else, since it is used here a lot, but is located in an organism.

export const isInvalidVariableInputName = (variableInput: VariableInput): boolean => {
  return !variableInput.name.trim();
};

export const isInvalidVariableInputType = (variableInput: VariableInput): boolean => {
  return !variableInput.type;
};

export const buildInputVariableTableRowList = (
  t: TFunction,
  items?: VariableInput[],
  onItemsChange?: (items: VariableInput[]) => void,
  isErrorVisible?: boolean,
): TableRowListValueProps => {
  const onVariableTextChanged = (tableRowIndex: number, text: string) => {
    if (items && onItemsChange) {
      const newInputs = [...items];
      newInputs[tableRowIndex].name = text;
      onItemsChange(newInputs);
    }
  };

  const onVariableTypeChanged = (tableRowIndex: number, value: string) => {
    if (items && onItemsChange) {
      const newInputs = [...items];
      newInputs[tableRowIndex].type = VariableTypes[value.toUpperCase()] as VariableTypes;
      onItemsChange(newInputs);
    }
  };

  const onRemoveRow = (index: number) => {
    if (items && onItemsChange) {
      const newInputs = [...items];
      newInputs.splice(index, 1);
      onItemsChange(newInputs);
    }
  };

  const dropDownItems = Object.values(VariableTypes).map((variableType): DropdownItemCombinedProps => {
    return {
      text: {
        value: variableType,
      },
    };
  });

  const tableRows = items?.map((item, index): TableRowCombinedProps => {
    return {
      style: 'InputVariable',
      variableNameInput: {
        textValue: item.name,
        onValidateText: (text) => isValidText(text, [Constants.REGEX.NOT_ALPHANUMERIC_DASH_AND_UNDERSCORE]),
        onTextChanged: (text) => onVariableTextChanged(index, text),
        state: isErrorVisible && isInvalidVariableInputName(item) ? 'Error' : 'Default',
      },
      button: {
        icon: {
          asset: 'Close',
        },
        onClick: () => onRemoveRow(index),
      },
      dropdownButton: {
        toggleProps: {
          type: 'Default',
          style: isErrorVisible && isInvalidVariableInputType(item) ? 'Error' : 'Default',
          colour: 'Default',
          text: {
            value: item.type ?? t('inputVariableTable.selectType'),
          },
        },
        menuProps: {
          style: 'Default',
          dropdownItems: dropDownItems,
          onOptionSelected: (option: string) => onVariableTypeChanged(index, option),
        },
      },
    };
  }) || [];

  return {
    tableRows,
  };
};

const mapVariableTypeToRowType = (variableType: VariableValueType): TableRowTypeEnum => {
  switch (variableType) {
    case 'boolean':
      return 'Boolean';
    case 'object':
    case 'collection':
      return 'CollectionObject';
    default:
      return 'Default';
  }
};

export const buildApiVariableTableRowList = (
  t: TFunction,
  items?: ApiResponseVariable[],
  onItemUpdated?: (item: ApiResponseVariable) => void,
  onItemDeleted?: (item: ApiResponseVariable) => void,
): TableRowListValueProps => {
  const onVariableNameChanged = (tableRowIndex: number, text: string) => {
    if (items && onItemUpdated) {
      const item = items[tableRowIndex];
      onItemUpdated({
        ...item,
        name: text,
      });
    }
  };

  const onVariableInitialValueChanged = (tableRowIndex: number, text: string) => {
    if (items && onItemUpdated) {
      const item = items[tableRowIndex];
      onItemUpdated({
        ...item,
        metadata: {
          ...item.metadata,
          initialValue: text,
        },
      });
    }
  };

  const onVariableScopeChanged = (tableRowIndex: number, value: string) => {
    if (items && onItemUpdated) {
      const item = items[tableRowIndex];
      onItemUpdated({
        ...item,
        scope: value as VariableScope,
      });
    }
  };

  const onRemoveRow = (index: number) => {
    if (items && onItemDeleted) {
      onItemDeleted(items[index]);
    }
  };

  const tableRows = items?.map((item, index): TableRowCombinedProps => {
    const rowType = mapVariableTypeToRowType(item.type);

    const dropDownItems = Object.values(variableScopes)
      .filter((scope) => scope === 'Global' || scope === 'Page')
      .map((variableScope): DropdownItemCombinedProps => {
        return {
          type: 'IconTextDescription',
          icon: {
            asset: variableScope === 'Flow' ? 'Variable' : variableScope,
          },
          label: {
            value: variableScope,
          },
          text: {
            value: t(`ExpandedVariableResponses.table.description.${variableScope}`),
          },
        };
      });

    const booleanDropDownItems = Object.values(['true', 'false']).map((value): DropdownItemCombinedProps => {
      return {
        state: item.metadata.initialValue === value ? 'Selected' : 'Default',
        text: {
          value,
        },
      };
    });

    return {
      style: 'EditVariables',
      type: rowType,
      dropdownButton: {
        toggleProps: {
          type: 'Icon',
          style: 'Default',
          colour: 'Default',
          icon1: {
            asset: mapScopeIcon(item.scope),
            colour: 'VariableSelected',
          },
        },
        menuProps: {
          style: 'Default',
          clipMenu: false,
          dropdownItems: dropDownItems,
          onOptionSelected: (option: string) => onVariableScopeChanged(index, option),
        },
      },
      nameInput: {
        textValue: item.name,
        onValidateText: (text) => isValidText(text, [Constants.REGEX.NOT_ALPHANUMERIC_DASH_AND_UNDERSCORE]),
        onSubmit: (text) => onVariableNameChanged(index, text),
      },
      response: {
        value: item.metadata.responseName,
      },
      valueType: {
        value: item.type,
      },
      valueInput: {
        disabled: rowType !== 'Default',
        inputType: item.type === 'number' ? 'number' : 'text',
        onValidateText: item.type === 'number' ? numberValidation : undefined,
        textValue: rowType === 'Default' ? item.metadata.initialValue : '[]',
        onSubmit: (text) => onVariableInitialValueChanged(index, text),
      },
      dropdownButton1: {
        toggleProps: {
          type: 'Default',
          style: 'Default',
          colour: 'Default',
          text: {
            value: item.metadata.initialValue || 'Select initial value...',
          },
        },
        menuProps: {
          style: 'Checkmark',
          dropdownItems: booleanDropDownItems,
          onOptionSelected: (option) => onVariableInitialValueChanged(index, option),
        },
      },
      button: {
        icon: {
          asset: 'Close',
        },
        onClick: () => onRemoveRow(index),
      },
    };
  }) || [];

  return {
    tableRows,
  };
};

export const buildInputVariablesTestResponse = (
  t: TFunction,
  inputVariables: VariableInput[],
  onInputVariablesChanged: (inputVariables: VariableInput[]) => void,
): TableRowListValueProps => {
  const onVariableTextChanged = (tableRowIndex: number, text: string) => {
    const newInputs = [...inputVariables];
    newInputs[tableRowIndex].value = text;
    onInputVariablesChanged(newInputs);
  };

  const onVariableBooleanValueChanged = (tableRowIndex: number, value: string) => {
    const newInputs = [...inputVariables];
    newInputs[tableRowIndex].value = BooleanValueTypes[value.toUpperCase()] as BooleanValueTypes;
    onInputVariablesChanged(newInputs);
  };

  const dropDownItems = Object.values(BooleanValueTypes).map((booleanValueType): DropdownItemCombinedProps => {
    return {
      text: {
        value: booleanValueType,
      },
    };
  });

  const tableRows = inputVariables.map((inputVariable, index): TableRowCombinedProps => {
    return {
      style: 'TestResponse',
      type: inputVariable.type !== 'boolean' ? 'Default' : 'Boolean',
      dropdownButton: {
        toggleProps: {
          type: 'Default',
          style: 'Default',
          colour: 'Default',
          text: {
            value: inputVariable.value ?? t('testResponsePage.testVariableBlock.selectAValue'),
          },
        },
        menuProps: {
          style: 'Default',
          dropdownItems: dropDownItems,
          onOptionSelected: (option: string) => onVariableBooleanValueChanged(index, option),
        },
      },
      variableName: {
        value: inputVariable.name,
      },
      variableType: {
        value: inputVariable.type,
      },
      inputField: {
        textValue: inputVariable?.value ? String(inputVariable.value) : '',
        onValidateText: (text) => (inputVariable.type === 'number' ? numberValidation(text) : isValidText(text, [])),
        onTextChanged: (text) => onVariableTextChanged(index, text),
      },
    };
  });

  return {
    tableRows,
  };
};
