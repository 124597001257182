import { Constants } from '../../../constants';
import { useLogicBuilderDraggable } from '../../../hooks/useLogicBuilderDraggable';
import { GrabIconButtonCombinedProps } from './types';
import { LOGIC_BUILDER } from '../../../lib/constants';

const usePresenter = (props: GrabIconButtonCombinedProps): GrabIconButtonCombinedProps => {
  const logicBuilderPanel = document.getElementById(Constants.ID.LOGIC_BUILDER_PANEL);
  const topNav = document.getElementById(Constants.ID.TOP_NAV);
  const topNavHeight = topNav ? topNav.offsetHeight : LOGIC_BUILDER.topNavHeight;
  const logicBuilderPanelHeight = logicBuilderPanel ? logicBuilderPanel.offsetHeight : LOGIC_BUILDER.headerHeight;
  const { dragHandleProps } = useLogicBuilderDraggable(
    0,
    (topNavHeight + logicBuilderPanelHeight) - window.innerHeight,
    logicBuilderPanelHeight - (window.innerHeight / 2),
  );
  return {
    ...props,
    dragHandleProps,
  };
};

export default usePresenter;
