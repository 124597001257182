import React from 'react';
import cx from 'classnames';

import PageSettings from '../PageSettings';
import PageVariablesPanel from '../PageVariablesPanel';

import styles from './PageSettingsPanel.module.scss';
import usePresenter from './PageSettingsPanel.presenter';
import getProps from './PageSettingsPanel.props';
import { PageSettingsPanelCombinedProps } from './types';

const PageSettingsPanel: React.FC<PageSettingsPanelCombinedProps> = (props) => {
  const {
    className,
    classes,
    pageSettings,
    pageVariablesPanel,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.pageSettingsPanel;

  return (
    <div className={cx(currentStyle, className)}>
      <PageSettings
        className={cx(styles.pageSettings, classes?.pageSettings)}
        {...internalProps.pageSettings}
        {...pageSettings}
      />
      <PageVariablesPanel
        className={cx(styles.pageVariablesPanel, classes?.pageVariablesPanel)}
        {...internalProps.pageVariablesPanel}
        {...pageVariablesPanel}
      />
    </div>
  );
};

export default PageSettingsPanel;
