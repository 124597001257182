import { ButtonValueProps } from '../../atoms/Button';
import { TextValueProps } from '../../atoms/Text';
import { TableRowListValueProps } from '../../molecules/TableRowList';

export type InputVariableTableClasses = {
  headerContent?: string;
  name?: string;
  type?: string;
  tableContent?: string;
  button?: string;
  tableRowList?: string;
};

export type InputVariableTableStateEnum = 'Empty' | 'Filled';

export type InputVariableTableValueProps = {
  name?: TextValueProps;
  type?: TextValueProps;
  button?: ButtonValueProps;
  tableRowList?: TableRowListValueProps;
  onItemsChange?: (items: VariableInput[]) => void; // MANUAL OVERRIDE
  inputVariables?: VariableInput[]; // MANUAL OVERRIDE
  isErrorVisible?: boolean; // MANUAL OVERRIDE
};

export type InputVariableTableProps = {
  state?: InputVariableTableStateEnum;
  className?: string;
  classes?: InputVariableTableClasses;
};

// MANUAL OVERRIDE STARTS
export enum VariableTypes {
  STRING = 'string',
  NUMBER = 'number',
  BOOLEAN = 'boolean',
}

export enum BooleanValueTypes {
  TRUE = 'TRUE',
  FALSE = 'FALSE',
}
export type VariableInput = {
  name: string,
  type?: VariableTypes,
  value?:string | number | boolean,
};
// MANUAL OVERRIDE ENDS

export type InputVariableTableCombinedProps = InputVariableTableValueProps & InputVariableTableProps;
