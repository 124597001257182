import { InputFieldProps } from '../../atoms/InputField';
import { TextProps } from '../../atoms/Text';

type InfoTestFieldInternalProps = {
  label?: TextProps;
  inputField?: InputFieldProps;
  text?: TextProps;
};

type InfoTestFieldPropsValues = {
  '': InfoTestFieldInternalProps;
};

const propValues: InfoTestFieldPropsValues = {
  '': {
    label: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Regular400',
      colour: 'NeutralDefault',
    },
    inputField: {
      type: 'Text',
      size: 'Medium',
      state: 'Default',
    },
    text: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Regular400',
      colour: 'NeutralDefault',
    },
  },
};

const getProps = (type: string): InfoTestFieldInternalProps => {
  const values: InfoTestFieldInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
