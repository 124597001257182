import { useContext } from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import customStyles from './Custom.module.scss';
import { useIsLeftPanelType } from '../../../hooks';

import { StoreContext } from '../../../store/StoreProvider';
import { typedDeepMerge } from '../../../utils/objectUtils';

import type { TopNavCombinedProps } from './types';
import { nameToSlug } from '../../../lib/utils';
import { buildTargetPageUrl, getPageNavigationVariables, mapVariablesToPathQuery } from '../../../store/PageStore/variableUtils';

const usePresenter = (props: TopNavCombinedProps): TopNavCombinedProps => {
  const { appStore, pageStore, breakpointStore } = useContext(StoreContext);
  const { t } = useTranslation();

  const { currentBreakpoint } = breakpointStore;

  const handlePreview = () => {
    if (pageStore && pageStore.currentPage) {
      const { url, params } = buildTargetPageUrl(
        pageStore.currentPage,
        pageStore.currentPageVariables,
      );
      window.open(`${url}${params}`, '_blank');
    }
  };
  return typedDeepMerge(props, {
    style: 'Default',
    className: cx(props.className, customStyles.topNav),
    classes: {
      spindlLogo: customStyles.spindlLogo,
      leftContent: customStyles.leftContent,
      button: customStyles.button,
      rightContent: customStyles.rightContent,
      publish: customStyles.publish,
    },
    text: {
      value: appStore.appName,
    },
    button: {
      label: {
        value: t('topNav.page'),
      },
      pageName: {
        value: pageStore.currentPage?.name ?? t('topNav.creatingNewPage'),
      },
    },
    breakpointMenu: {
      desktopButton: {
        state: currentBreakpoint.type === 'desktop' ? 'Selected' : 'Default',
        icon: {
          asset: 'LaptopDefault',
        },
        onClick: () => breakpointStore.setCurrentBreakpoint('desktop'),
      },
      tabletButton: {
        state: currentBreakpoint.type === 'tablet' ? 'Selected' : 'Default',
        icon: {
          asset: 'Tablet',
        },
        onClick: () => breakpointStore.setCurrentBreakpoint('tablet'),
      },
      mobileButton: {
        state: currentBreakpoint.type === 'mobile' ? 'Selected' : 'Default',
        icon: {
          asset: 'Mobile',
        },
        onClick: () => breakpointStore.setCurrentBreakpoint('mobile'),
      },
    },
    saveStatus: {
      value: t('topNav.autoSaveIsOn'),
    },
    undo: {
      icon: {
        asset: 'Undo',
      },
    },
    redo: {
      icon: {
        asset: 'Redo',
      },
    },
    preview: {
      onClick: () => handlePreview(),
      icon: {
        asset: 'Visibility',
      },
    },
    publish: {
      text: {
        value: t('topNav.publish'),
      },
    },
  });
};

export default usePresenter;
