// MANUAL OVERRIDE STARTS
import { observer } from 'mobx-react-lite';
import { type ExportedModalProps } from '../../../modules/common/ModalWrapper';
import InternalExpandedVariableResponses from './ExpandedVariableResponses';
import type { ExpandedVariableResponsesCombinedProps as InternalExpandedVariableResponsesCombinedProps, ExpandedVariableResponsesProps } from './types';
import withModalWrapper from '../../../modules/common/ModalWrapper/withModalWrapper';

export type { ExpandedVariableResponsesProps };
export type ExpandedVariableResponsesCombinedProps = ExportedModalProps<InternalExpandedVariableResponsesCombinedProps>;

const ExpandedVariableResponses = withModalWrapper(observer(InternalExpandedVariableResponses));
export default ExpandedVariableResponses;
// MANUAL OVERRIDE ENDS
