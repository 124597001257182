import cx from 'classnames';
import { computed } from 'mobx';
import {
  RefObject, useContext, useEffect, useMemo, useState,
} from 'react';
import { Constants } from '../../../../constants';
import { getElement } from '../../../../store/PageStore/utils';
import { StoreContext } from '../../../../store/StoreProvider';
import { useElementDropState } from '../../hooks/useElementDropState';
import styles from './DropZone.module.scss';
import type { DropZoneCombinedProps, VariantName } from './types';

const doCalculations = (
  elementRef: RefObject<HTMLDivElement> | undefined,
  dropZoneIndex: number,
  variantName: string,
  setPosition: (value: [number, number]) => void,
  scale: number,
) => {
  if (elementRef?.current) {
    const filteredChildren: Element[] = [];
    for (let i = 0; i < elementRef.current.children.length; i++) {
      const child = elementRef.current.children[i];
      if (!child.attributes[Constants.ATTRIBUTES.DROP_ZONE_INDEX]) {
        filteredChildren.push(child);
      }
    }
    const parentRect = elementRef.current.getBoundingClientRect();
    for (let i = 0; i < filteredChildren.length; i++) {
      const child = filteredChildren[i];
      const childRect = child.getBoundingClientRect();
      if (i + 1 === dropZoneIndex) {
        const y = (childRect.bottom - parentRect.y) / scale;
        const x = (childRect.right - parentRect.x) / scale - 4;
        if (variantName === 'Horizontal') {
          setPosition([0, y]);
        } else {
          setPosition([x, 0]);
        }
        break;
      }
    }
  }
};

const usePresenter = (props: DropZoneCombinedProps): DropZoneCombinedProps => {
  const { appStore, breakpointStore, pageStore } = useContext(StoreContext);
  const {
    parentId,
    parentRef,
    dropZoneIndex,
    variantName: initialVariantName,
    className: initialClassName,
  } = props;

  const parentElement = computed(() => getElement(parentId, pageStore)!).get();

  const variantName: VariantName = computed(() => initialVariantName ?? (parentElement.styles?.direction === 'vertical' ? 'Horizontal' : 'Vertical')).get();

  const { isDropAllowed } = useElementDropState(parentElement);

  const isActiveDropZoneIndex: boolean = computed(() => (isDropAllowed ? pageStore.isActiveDropZoneIndex(dropZoneIndex) : false)).get();

  const [position, setPosition] = useState<[number, number]>([0, 0]);

  useEffect(() => {
    setTimeout(() => {
      doCalculations(parentRef, dropZoneIndex, variantName, setPosition, appStore.canvasScale);
    }, 250);
  }, [
    parentRef,
    dropZoneIndex,
    variantName,
    parentElement.settings,
    parentElement.styles,
    appStore.canvasScale,
    breakpointStore.currentBreakpoint,
  ]);

  const className: string = cx(initialClassName, {
    [styles.hovered]: isDropAllowed && isActiveDropZoneIndex,
  });

  return {
    ...props,
    className,
    variantName,
    cssProps: parentRef ? {
      left: position[0],
      top: position[1],
    } : undefined,
  };
};

export default usePresenter;
