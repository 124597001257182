import { InputFieldProps } from '../InputField';

type FieldWithDropdownInternalProps = {
  inputField?: InputFieldProps;
};

type FieldWithDropdownPropsValues = {
  '': FieldWithDropdownInternalProps;
};

const propValues: FieldWithDropdownPropsValues = {
  '': {
    inputField: {
      type: 'TextUnit',
      size: 'Medium',
      state: 'Default',
    },
  },
};

const getProps = (type: string): FieldWithDropdownInternalProps => {
  const values: FieldWithDropdownInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
