import { TextProps } from '../../atoms/Text';

type DuoToneTextDefaultInternalProps = {
  lightText?: TextProps;
  darkText?: TextProps;
};

type DuoToneTextDefaultPropsValues = {
  '': DuoToneTextDefaultInternalProps;
};

const propValues: DuoToneTextDefaultPropsValues = {
  '': {
    lightText: {
      type: 'LabelSmall',
      align: 'Center',
      style: 'Medium500',
      colour: 'NeutralHoverSelected',
    },
    darkText: {
      type: 'LabelSmall',
      align: 'Center',
      style: 'Medium500',
      colour: 'NeutralDefault',
    },
  },
};

const getProps = (type: string): DuoToneTextDefaultInternalProps => {
  const values: DuoToneTextDefaultInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
