import { InputProp, InputPropsMap } from '../../store/types';

export const availableActionTypes = ['CallApi', 'NavigateTo'] as const;
export const actionTypes = ['CallApi', 'NavigateTo', 'CreateVariable', 'UpdateVariable'] as const;
export type ActionTypeEnum = typeof actionTypes[number];

export const flowTypes = ['OnClick', 'OnLoad'] as const;
export type FlowTypeEnum = typeof flowTypes[number];

export type BaseAction<ActionType extends ActionTypeEnum, Metadata extends Record<string, any>> = {
  uuid: string;
  pageUuid: string;
  elementUuid: string;
  actionType?: ActionType;
  flowType: FlowTypeEnum;
  metadata?: Metadata;
  prevActionUuid?: string;
  nextActionUuid?: string;
  createdAt: Date;
  updatedAt: Date;
};

export type CallApiMetadata = {
  apiGroupUuid?: string;
  apiEndpointUuid?: string;
  variableInputs?: InputPropsMap;
};
export type CallApiAction = BaseAction<'CallApi', CallApiMetadata>;

export const navigationDestinations = ['URL', 'Page'] as const;
export type NavigationDestination = typeof navigationDestinations[number];
export const navigationLaunchTypes = ['same_tab', 'new_tab'] as const;
export type NavigationLaunchType = typeof navigationLaunchTypes[number];
export type NavigateToMetadata = {
  destination?: NavigationDestination;
  url?: InputProp | null | undefined;
  pageUuid?: string;
  launchType?: NavigationLaunchType;
  variableInputs?: InputPropsMap;
};
export type NavigateToAction = BaseAction<'NavigateTo', NavigateToMetadata>;

export type CreateVariableMetadata = {};
export type CreateVariableAction = BaseAction<'CreateVariable', CreateVariableMetadata>;

export type UpdateVariableMetadata = {};
export type UpdateVariableAction = BaseAction<'UpdateVariable', UpdateVariableMetadata>;

export type Action = CallApiAction | NavigateToAction | CreateVariableAction | UpdateVariableAction;
export type UpdateAction = Partial<Pick<Action, 'actionType' | 'metadata' | 'prevActionUuid'>> & {
  shouldMerge?: boolean;
  shouldPersistToBackEnd?: boolean;
};

export type Flow = Record<string, Action>;

export type UpdateActionPayload = Pick<Action, 'uuid' | 'pageUuid' | 'actionType' | 'metadata' | 'prevActionUuid'>;

export type ReplaceActionPayload = Omit<Action,
  'createdAt' |
  'updatedAt'
>;

export type CreateActionPayload = Omit<Action,
  'uuid' |
  'createdAt' |
  'updatedAt' |
  'nextActionUuid'
>;
