import { useDropdownMenu } from 'react-overlays';
import cx from 'classnames';

import styles from './DropdownItemList.module.scss';
import { DropdownItemListCombinedProps } from './types';

const usePresenter = (props: DropdownItemListCombinedProps): DropdownItemListCombinedProps => {
  const { dropdownItems, clipMenu, ...otherProps } = props;
  const [menuProps, { toggle }] = useDropdownMenu();
  const { style, ...otherMenuProps } = menuProps;

  const items = props.dropdownItems?.map((item) => {
    return {
      ...item,
      onClick: async (event: React.MouseEvent) => {
        event.preventDefault();
        if (toggle) {
          toggle(false);
        }
        if (item.onClick) {
          await item.onClick(event);
        }
      },
    };
  });

  return {
    ...otherProps,
    className: clipMenu === false ? props.className : cx(props.className, styles.clipDropdownItemList),
    dropdownItems: items,
    ...otherMenuProps,
  };
};

export default usePresenter;
