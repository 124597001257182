import { ButtonProps } from '../../atoms/Button';
import { TextProps } from '../../atoms/Text';

type SectionLabelInternalProps = {
  text?: TextProps;
  button?: ButtonProps;
};

type SectionLabelPropsValues = {
  'MainSectionTextIconRight': SectionLabelInternalProps;
  'MainSectionTextNoIcon': SectionLabelInternalProps;
  'SubsectionTextIconLeft': SectionLabelInternalProps;
  'SubsectionTextIconRight': SectionLabelInternalProps;
  'SubsectionTextNoIcon': SectionLabelInternalProps;
};

const propValues: SectionLabelPropsValues = {
  MainSectionTextIconRight: {
    text: {
      type: 'LabelLarge',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralHoverSelected',
    },
    button: {
      type: 'Icon',
      size: 'L',
      state: 'Default',
      style: 'PrimarySubdued',
    },
  },
  MainSectionTextNoIcon: {
    text: {
      type: 'LabelLarge',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralHoverSelected',
    },
  },
  SubsectionTextIconLeft: {
    button: {
      type: 'Icon',
      size: 'M',
      state: 'Default',
      style: 'PrimarySubdued',
    },
    text: {
      type: 'LabelMedium',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralDefault',
    },
  },
  SubsectionTextIconRight: {
    text: {
      type: 'LabelMedium',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralDefault',
    },
    button: {
      type: 'Icon',
      size: 'M',
      state: 'Default',
      style: 'PrimarySubdued',
    },
  },
  SubsectionTextNoIcon: {
    text: {
      type: 'LabelMedium',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralDefault',
    },
  },
};

const getProps = (type: string): SectionLabelInternalProps => {
  const values: SectionLabelInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
