import React from 'react';
import cx from 'classnames';

import styles from './SettingsPanel.module.scss';
import usePresenter from './SettingsPanel.presenter';
import getProps from './SettingsPanel.props';
import { SettingsPanelCombinedProps } from './types';
import SettingsRowList from '../SettingsRowList';

const SettingsPanel: React.FC<SettingsPanelCombinedProps> = (props) => {
  const {
    className,
    classes,
    settingsRowList,
    // MANUAL OVERRIDE STARTS
    addImageContainerRef,
    // MANUAL OVERRIDE ENDS
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.settingsPanel;

  return (
    // MANUAL OVERRIDE: Adding ref prop
    <div ref={addImageContainerRef} className={cx(currentStyle, className)}>
      <SettingsRowList
        className={cx(styles.settingsRowList, classes?.settingsRowList)}
        {...internalProps.settingsRowList}
        {...settingsRowList}/>
    </div>
  );
};

export default SettingsPanel;
