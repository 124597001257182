import { DuoToneTextDefaultProps } from '../DuoToneTextDefault';

type CanvasResolutionSpecInternalProps = {
  breakpointScreenSize?: DuoToneTextDefaultProps;
  scale?: DuoToneTextDefaultProps;
};

type CanvasResolutionSpecPropsValues = {
  '': CanvasResolutionSpecInternalProps;
};

const propValues: CanvasResolutionSpecPropsValues = {
  '': {
    breakpointScreenSize: {
    },
    scale: {
    },
  },
};

const getProps = (type: string): CanvasResolutionSpecInternalProps => {
  const values: CanvasResolutionSpecInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
