import { PageLabelItemProps } from '../PageLabelItem';

type PageLabelItemListInternalProps = {
  pageLabelItem?: PageLabelItemProps;
};

type PageLabelItemListPropsValues = {
  '': PageLabelItemListInternalProps;
};

const propValues: PageLabelItemListPropsValues = {
  '': {
    pageLabelItem: {
      style: 'Default',
      state: 'Default',
      type: 'Default',
    },
  },
};

const getProps = (type: string): PageLabelItemListInternalProps => {
  const values: PageLabelItemListInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
