import { Constants } from '../../../../constants';
import { IStyles, Element } from '../../../../store/types';

export const mapPristineOverrides = (element: Element): IStyles => {
  if (!element || !element.styles) {
    return {};
  }
  const pristineStyleOverrides: IStyles = {};
  if (!element.childIds.length) {
    if (element.styles.height?.unit === 'hug') {
      pristineStyleOverrides.height = {
        value: Constants.LIMITS.EMPTY_CONTAINER_MIN_HEIGHT_IN_PX,
        unit: 'px',
      };
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (!element.styles.borderWeight || element.styles.borderWeight.value === 0) {
      pristineStyleOverrides.borderWeight = {
        unit: 'px',
        value: 1,
      };
      pristineStyleOverrides.borderStyle = 'dashed';
      pristineStyleOverrides.borderColor = {
        opacity: {
          unit: '%',
          value: 100,
        },
        hexCode: '#737373',
      };
    }
  }
  return {
    ...element.styles,
    ...pristineStyleOverrides,
  };
};
