import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useExpandablePanel } from '../../../hooks';
import { typedDeepMerge } from '../../../utils/objectUtils';
import type { CornersPanelCombinedProps, CornersPanelStateEnum } from './types';
import { StoreContext } from '../../../store/StoreProvider';
import { handleSameSeparateValues } from './utils';
import { getStylePanelConfigurations } from '../../../store/PageStore/utils';
import { pixelInputValidation, valueWithFallback } from '../../../utils/inputValidations';

const usePresenter = (props: CornersPanelCombinedProps): CornersPanelCombinedProps => {
  const { t } = useTranslation();
  const { pageStore } = useContext(StoreContext);
  const selectedElement = pageStore.selectedCombineElement;
  const [expandedState, setExpandedState] = useState<CornersPanelStateEnum & `Expanded${string}`>('ExpandedUnion');

  const expandableProps = useExpandablePanel(props, expandedState, true);
  const panelConfig = getStylePanelConfigurations(selectedElement);

  const unifiedCornerRadius = selectedElement?.styles?.unifiedCornerRadius;
  const unifiedCorner = selectedElement?.styles?.cornerRadius;
  const separateCorners = selectedElement?.styles?.separateCornerRadius;

  useEffect(() => {
    if (selectedElement) {
      if (selectedElement.styles.unifiedCornerRadius) {
        setExpandedState('ExpandedUnion');
      } else {
        setExpandedState('ExpandedSeperate');
      }
    }
  }, [selectedElement]);

  const handleUpdateUnifiedRadius = async (value: string): Promise<void> => {
    if (selectedElement) {
      await pageStore.updateElement(selectedElement, {
        styles: {
          cornerRadius: {
            value: Number(value),
            unit: 'px',
          },
        },
      });
    }
  };

  const handleUpdateSeparateRadius = async (
    corner: 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight',
    value: string,
  ): Promise<void> => {
    if (selectedElement) {
      await pageStore.updateElement(selectedElement, {
        styles: {
          separateCornerRadius: {
            ...separateCorners,
            [corner]: { value: Number(value), unit: 'px' },
          },
        },
      });
    }
  };

  const handleUnifiedCornerRadius = async (value: string) => {
    if (selectedElement) {
      const isUnifiedCornerRadius = value === 'yes';
      await pageStore.updateElement(selectedElement, {
        styles: {
          unifiedCornerRadius: isUnifiedCornerRadius,
        },
      });
      if (isUnifiedCornerRadius) {
        setExpandedState('ExpandedUnion');
        const isSameValue = handleSameSeparateValues(separateCorners);
        await pageStore.updateElement(selectedElement, {
          styles: {
            cornerRadius: {
              value: isSameValue && separateCorners?.bottomLeft ? separateCorners.bottomLeft.value : 0,
              unit: 'px',
            },
          },
        });
      } else {
        setExpandedState('ExpandedSeperate');
        await pageStore.updateElement(selectedElement, {
          styles: {
            separateCornerRadius: {
              topLeft: { value: unifiedCorner?.value || 0, unit: 'px' },
              topRight: { value: unifiedCorner?.value || 0, unit: 'px' },
              bottomLeft: { value: unifiedCorner?.value || 0, unit: 'px' },
              bottomRight: { value: unifiedCorner?.value || 0, unit: 'px' },
            },
          },
        });
      }
    }
  };

  return typedDeepMerge(expandableProps, {
    panelHeader: {
      text: {
        value: t('cornersPanel.corners'),
      },
    },
    radius: {
      text: {
        value: t('cornersPanel.radius'),
      },
    },
    inputField: {
      textValue: expandedState === 'ExpandedUnion' ? valueWithFallback(unifiedCorner?.value, 0) : '--',
      inputType: 'number',
      onValidateText: expandedState === 'ExpandedUnion' ? pixelInputValidation : (value) => value === '--',
      onSubmit: (value) => handleUpdateUnifiedRadius(value),
      button: {
        text: {
          value: t('units.px'),
        },
      },
    },
    toggleItemList: {
      currentValue: unifiedCornerRadius ? 'yes' : 'no',
      toggleOptions: [
        {
          value: 'yes',
          icon: 'UnionCorners',
        },
        {
          value: 'no',
          icon: 'SeperateCorners',
        },
      ],
      onOptionSelected: (option) => {
        void handleUnifiedCornerRadius(option);
      },
    },
    topLeft: {
      textValue: valueWithFallback(separateCorners?.topLeft?.value, 0),
      icon: {
        asset: 'TopLeftCorner',
      },
      inputType: 'number',
      onValidateText: pixelInputValidation,
      onSubmit: (value) => handleUpdateSeparateRadius('topLeft', value),
    },
    topRight: {
      // TODO: handle border-radius css value breakdown
      textValue: valueWithFallback(separateCorners?.topRight?.value, 0),
      icon: {
        asset: 'TopRightCorner',
      },
      inputType: 'number',
      onValidateText: pixelInputValidation,
      onSubmit: (value) => handleUpdateSeparateRadius('topRight', value),
    },
    bottomLeft: {
      // TODO: handle border-radius css value breakdown
      textValue: valueWithFallback(separateCorners?.bottomLeft?.value, 0),
      icon: {
        asset: 'BottomLeftCorner',
      },
      inputType: 'number',
      onValidateText: pixelInputValidation,
      onSubmit: (value) => handleUpdateSeparateRadius('bottomLeft', value),
    },
    bottomRight: {
      // TODO: handle border-radius css value breakdown
      textValue: valueWithFallback(separateCorners?.bottomRight?.value, 0),
      icon: {
        asset: 'BottomRightCorner',
      },
      inputType: 'number',
      onValidateText: pixelInputValidation,
      onSubmit: (value) => handleUpdateSeparateRadius('bottomRight', value),
    },
    renderPanel: panelConfig?.Corners,
  });
};

export default usePresenter;
