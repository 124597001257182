import { useEffect } from 'react';
import cx from 'classnames';
import customStyles from './Custom.module.scss';
import { useDropdownToggle } from '../../../modules/common/DropdownWrapper';
import { DropdownButtonCombinedProps } from './types';

const usePresenter = (props: DropdownButtonCombinedProps): DropdownButtonCombinedProps => {
  const { state, type, style, colour } = props;
  const [toggleProps, { show, toggle }] = useDropdownToggle();

  useEffect(() => {
    // close dropdown when option value changes
    if (show) {
      toggle(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.text?.value]);

  const onDropdownBlur = (e: React.FocusEvent<HTMLDivElement>) => {
    // close dropdown
    if (show && (!e.relatedTarget || !e.relatedTarget.className.includes('dropdownItem'))) {
      toggle(false);
    }
  };

  return {
    ...props,
    ...{
      ...toggleProps,
      onClick: (event?: React.MouseEvent<HTMLElement>) => {
        if (event) {
          toggleProps.onClick(event);
        }
      },
      onBlur: onDropdownBlur,
    },
    state: show ? 'Expanded' : state || 'Collapsed',
    className: cx(
      props.className,
      customStyles[`dropdownButton${type}${style}${show ? 'Expanded' : state || 'Collapsed'}${colour}`],
    ),
  };
};

export default usePresenter;
