import { ButtonProps } from '../../atoms/Button';
import { IconProps } from '../../atoms/Icon';
import { TextProps } from '../../atoms/Text';

type MenuHeaderInternalProps = {
  text?: TextProps;
  button?: ButtonProps;
  icon?: IconProps;
};

type MenuHeaderPropsValues = {
  'Default': MenuHeaderInternalProps;
  'Variable': MenuHeaderInternalProps;
};

const propValues: MenuHeaderPropsValues = {
  Default: {
    text: {
      type: 'LabelMedium',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralHoverSelected',
    },
    button: {
      type: 'Icon',
      size: 'M',
      state: 'Default',
      style: 'PrimarySubdued',
    },
  },
  Variable: {
    icon: {
      asset: 'Variable',
      colour: 'VariableSelected',
    },
    text: {
      type: 'LabelMedium',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralHoverSelected',
    },
    button: {
      type: 'Icon',
      size: 'M',
      state: 'Default',
      style: 'PrimarySubdued',
    },
  },
};

const getProps = (type: string): MenuHeaderInternalProps => {
  const values: MenuHeaderInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
