import { TextProps } from '../Text';

type EmptyStateInfoInternalProps = {
  text?: TextProps;
};

type EmptyStateInfoPropsValues = {
  '': EmptyStateInfoInternalProps;
};

const propValues: EmptyStateInfoPropsValues = {
  '': {
    text: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Italic400',
      colour: 'NeutralHoverSelected',
    },
  },
};

const getProps = (type: string): EmptyStateInfoInternalProps => {
  const values: EmptyStateInfoInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
