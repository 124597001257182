import { NEW_GROUP_PLACEHOLDER_UUID } from '../../../constants';
import { GroupPageTabEnum } from '../../../lib/types';
import { ApiGroup, KeyValuePair } from '../../../store/ApiGroupStore/types';
import { ErrorKeyValueWithIndex } from './types';

export const OFFSET_TOP_POSITION = 50;
export const OFFSET_LEFT_POSITION = 550;

export const getEmptyGroup = (groupName: string): ApiGroup => ({
  uuid: NEW_GROUP_PLACEHOLDER_UUID,
  name: groupName,
  baseUrl: '',
  endpoints: [],
  headers: [],
  parameters: [],
});

export const getEndpointUrl = (base: string, path: string): string => {
  if (path[0] === '/') {
    return `${base}${path}`;
  }
  return `${base}/${path}`;
};

export const capitalizeOnlyFirstLetter = (value: string): string => {
  return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
};

export const getKeyValuePairsErrors = (keyValuePairs: KeyValuePair[]): boolean => {
  return keyValuePairs.some((keyValuePair) => {
    return keyValuePair.key.trim() === '' || keyValuePair.value.trim() === '';
  });
};

export const getTrimmedKeyValuePairs = (keyValuePairs: KeyValuePair[]): KeyValuePair[] => {
  return keyValuePairs.map((keyValuePair) => {
    return {
      key: keyValuePair.key.trim(),
      value: keyValuePair.value.trim(),
    };
  });
};
