import { DividerProps } from '../../atoms/Divider';
import { MenuHeaderProps } from '../../molecules/MenuHeader';
import { ResponseSectionProps } from '../../organisms/ResponseSection';
import { VariableSectionProps } from '../../organisms/VariableSection';

type ExpandedVariableResponsesInternalProps = {
  menuHeader?: MenuHeaderProps;
  divider?: DividerProps;
  variableSection?: VariableSectionProps;
  responseSection?: ResponseSectionProps;
};

type ExpandedVariableResponsesPropsValues = {
  '': ExpandedVariableResponsesInternalProps;
};

const propValues: ExpandedVariableResponsesPropsValues = {
  '': {
    menuHeader: {
      type: 'Default',
      size: 'M',
    },
    divider: {
      type: 'Horizontal',
      colour: 'Neutral1900',
    },
    variableSection: {
      state: 'Expanded',
    },
    responseSection: {
      state: 'Expanded',
    },
  },
};

const getProps = (type: string): ExpandedVariableResponsesInternalProps => {
  const values: ExpandedVariableResponsesInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
