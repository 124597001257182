import React from 'react';

import DropdownItemList from '../../molecules/DropdownItemList';
import { DropdownItemListCombinedProps } from '../../molecules/DropdownItemList/types';
import DropdownWrapper, { ExportedDropdownProps } from '../../../modules/common/DropdownWrapper';
import InternalFieldWithDropdown from './FieldWithDropdown';
import { FieldWithDropdownProps, FieldWithDropdownValueProps as InternalFieldWithDropdownValueProps } from './types';

export type { FieldWithDropdownProps, InternalFieldWithDropdownValueProps };

// MANUAL OVERRIDE
export type CombinedDropDownValueProps = ExportedDropdownProps<
InternalFieldWithDropdownValueProps,
DropdownItemListCombinedProps
> | Record<string, unknown> & {
  className?: string,
};

// MANUAL OVERRIDE
const FieldWithDropdown: React.FC<CombinedDropDownValueProps> = ({
  toggleProps,
  menuProps,
  className,
}) => (
  <DropdownWrapper
    Toggle={InternalFieldWithDropdown}
    Menu={DropdownItemList}
    toggleProps={toggleProps}
    menuProps={menuProps}
    className={className}
    alignEnd
  />
);

export default FieldWithDropdown;
