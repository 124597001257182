import { ButtonProps } from '../../atoms/Button';
import { InputFieldProps } from '../../atoms/InputField';
import { TextProps } from '../../atoms/Text';
import { BackHeaderProps } from '../../molecules/BackHeader';

type PageSettingsInternalProps = {
  backHeader?: BackHeaderProps;
  text?: TextProps;
  inputField?: InputFieldProps;
  button?: ButtonProps;
  text1?: TextProps;
  inputField1?: InputFieldProps;
  text2?: TextProps;
  inputField2?: InputFieldProps;
};

type PageSettingsPropsValues = {
  '': PageSettingsInternalProps;
};

const propValues: PageSettingsPropsValues = {
  '': {
    backHeader: {
    },
    text: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralDefault',
    },
    inputField: {
      type: 'Text',
      size: 'Medium',
      state: 'Default',
    },
    button: {
      type: 'IconRounded',
      size: 'M',
      state: 'Default',
      style: 'PrimarySubdued',
    },
    text1: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralDefault',
    },
    inputField1: {
      type: 'Text',
      size: 'Medium',
      state: 'Default',
    },
    text2: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralDefault',
    },
    inputField2: {
      type: 'TextArea',
      size: 'Medium',
      state: 'Default',
    },
  },
};

const getProps = (type: string): PageSettingsInternalProps => {
  const values: PageSettingsInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
