import { Element, UnitTypeProperty, UnitType, Unit } from '../types';
import { capitalize } from '../../utils/stringUtils';
import { PageStore } from './PageStore';
import { Constants } from '../../constants';

export const isHugFillOrNone = (unit?: string) => !unit || ['hug', 'fill', 'none'].includes(unit);

export const toFixedIfNeeded = (value: number, decimalCases: number): string => {
  if (value % 1 !== 0) {
    return value.toFixed(decimalCases);
  } else {
    return value.toString();
  }
};

export const handleUnitTypeValue = ({ unit, value }: UnitType) => {
  return isHugFillOrNone(unit)
    ? capitalize(unit || 'none')
    : toFixedIfNeeded(
      (value || 0),
      Constants.DROPDOWN_OPTIONS.DECIMAL_DIGITS[unit || 'px'] as number,
    );
};

export const unitChangeDefaultValue = (from: UnitType, to: Unit): number => {
  if (['hug', 'fill'].includes(to)) {
    return 0;
  }
  if (['vh', 'vw', '%', 'px'].includes(from.unit)) {
    return from.value || 0;
  }
  if (['px', '%'].includes(to)) {
    if (['hug', 'fill', 'none'].includes(from.unit)) {
      return 100;
    }
  }
  if (['vh', 'vw'].includes(to)) {
    if (['hug', 'fill', 'none'].includes(from.unit)) {
      return 10;
    }
  }
  return 0;
};

export const defaultUnitType: UnitType = {
  value: 0,
  unit: 'px',
};

export const getUnitTypeValue = (
  selectedElement: Element | undefined,
  property: UnitTypeProperty,
) => {
  const unit = selectedElement?.styles[property] as UnitType || defaultUnitType;
  return handleUnitTypeValue(unit);
};

export const getUnitTypeUnit = (
  selectedElement: Element | undefined,
  property: UnitTypeProperty,
) => {
  const { unit } = selectedElement?.styles[property] as UnitType || defaultUnitType;
  return unit;
};

export const updateUnitTypeValue = async (
  pageStore: PageStore,
  selectedElement: Element | undefined,
  property: UnitTypeProperty,
  value: string,
  defaultUnit?: UnitType,
) => {
  if (!selectedElement) {
    return;
  }
  await pageStore.updateElement(selectedElement, {
    styles: {
      [property]: {
        value: +value,
        unit: (selectedElement.styles[property] as UnitType)?.unit || (defaultUnit || 'px'),
      },
    },
  });
};

export const updateUnitTypeUnit = async (
  pageStore: PageStore,
  selectedElement: Element | undefined,
  property: UnitTypeProperty,
  unit: string,
) => {
  if (!selectedElement) {
    return;
  }
  await pageStore.updateElement(selectedElement, {
    styles: {
      [property]: {
        value: unitChangeDefaultValue(
          selectedElement.styles[property] as UnitType,
          unit.toLowerCase() as Unit,
        ),
        unit: unit.toLowerCase(),
      },
    },
  });
};
