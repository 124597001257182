import { ButtonProps } from '../../atoms/Button';
import { IconProps } from '../../atoms/Icon';
import { TextProps } from '../../atoms/Text';

type PropertyItemInternalProps = {
  icon?: IconProps;
  text?: TextProps;
  button?: ButtonProps;
};

type PropertyItemPropsValues = {
  'DefaultImage': PropertyItemInternalProps;
  'DefaultText': PropertyItemInternalProps;
  'VariableImage': PropertyItemInternalProps;
  'VariableText': PropertyItemInternalProps;
};

const propValues: PropertyItemPropsValues = {
  DefaultImage: {
    icon: {
      asset: 'Image',
      colour: 'NeutralDefault',
    },
    text: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Regular400',
      colour: 'NeutralDefault',
    },
    button: {
      type: 'IconDark',
      size: 'S',
      state: 'Default',
      style: 'PrimarySubdued',
    },
  },
  DefaultText: {
    text: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Regular400',
      colour: 'NeutralDefault',
    },
    button: {
      type: 'IconDark',
      size: 'S',
      state: 'Default',
      style: 'PrimarySubdued',
    },
  },
  VariableImage: {
    icon: {
      asset: 'Image',
      colour: 'NeutralDefault',
    },
    text: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Medium500',
      colour: 'VariableSelected',
    },
    button: {
      type: 'IconDark',
      size: 'S',
      state: 'Default',
      style: 'PrimarySubdued',
    },
  },
  VariableText: {
    text: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Medium500',
      colour: 'VariableSelected',
    },
    button: {
      type: 'IconDark',
      size: 'S',
      state: 'Default',
      style: 'PrimarySubdued',
    },
  },
};

const getProps = (type: string): PropertyItemInternalProps => {
  const values: PropertyItemInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
