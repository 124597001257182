import { ButtonProps } from '../Button';
import { IconProps } from '../Icon';

type RowHoverCapInternalProps = {
  button?: ButtonProps;
  icon?: IconProps;
};

type RowHoverCapPropsValues = {
  'Clickable': RowHoverCapInternalProps;
  'NonClickable': RowHoverCapInternalProps;
};

const propValues: RowHoverCapPropsValues = {
  'Clickable': {
    button: {
      type: 'IconClear',
      size: 'S',
      state: 'Default',
      style: 'PrimarySubdued',
    },
  },
  'NonClickable': {
    icon: {
      asset: 'CheckmarkCircle',
      colour: 'NeutralDefault',
    },
  },
};

const getProps = (type: string): RowHoverCapInternalProps => {
  const values: RowHoverCapInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
