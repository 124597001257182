import React from 'react';
import cx from 'classnames';

import Icon from '../Icon';
import Text from '../Text';
import Underline from '../Underline';

import styles from './TabItem.module.scss';
import usePresenter from './TabItem.presenter';
import getProps from './TabItem.props';
import { TabItemCombinedProps } from './types';

const TabItem: React.FC<TabItemCombinedProps> = (props) => {
  const {
    state,
    className,
    classes,
    disabled,
    buttonType,
    buttonForm,
    href,
    target,
    externalLink,
    onClick,
    text,
    icon,
    underline,
  } = usePresenter(props);

  const variantName = `${state}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`tabItem${variantName}`];

  let componentView;

  switch (variantName) {
    case 'Default': {
      componentView = (
        <button
          disabled={disabled}
          type={buttonType}
          form={buttonForm}
          onClick={onClick}
          className={cx(currentStyle, className)}
        >
          <Text
            className={cx(styles.text, classes?.text)}
            {...internalProps.text}
            {...text}
          />
        </button>
      );
      break;
    }
    case 'Error': {
      componentView = (
        <button
          disabled={disabled}
          type={buttonType}
          form={buttonForm}
          onClick={onClick}
          className={cx(currentStyle, className)}
        >
          <Text
            className={cx(styles.text, classes?.text)}
            {...internalProps.text}
            {...text}
          />
          <Icon
            className={cx(styles.icon, classes?.icon)}
            {...internalProps.icon}
            {...icon}
          />
        </button>
      );
      break;
    }
    case 'Selected': {
      componentView = (
        <button
          disabled={disabled}
          type={buttonType}
          form={buttonForm}
          onClick={onClick}
          className={cx(currentStyle, className)}
        >
          <Text
            className={cx(styles.text, classes?.text)}
            {...internalProps.text}
            {...text}
          />
          <Underline
            className={cx(styles.underline, classes?.underline)}
            {...internalProps.underline}
            {...underline}
          />
        </button>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default TabItem;
