import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SetVariableMenuContext } from '.';
import {
  ActionTypeEnum,
  availableActionTypes,
  CallApiMetadata,
  NavigateToMetadata,
  NavigationDestination,
  navigationDestinations,
  NavigationLaunchType,
  navigationLaunchTypes,
} from '../../../modules/actions/types';
import useModal from '../../../modules/common/ModalWrapper/context/useModal';
import { getCustomPositionForElementLeft } from '../../../modules/common/ModalWrapper/utils';
import { VariableReferenceType } from '../../../modules/variables/types';
import { ApiGroup, ApiGroupEndpoint } from '../../../store/ApiGroupStore/types';
import { resolveApiCallAction, resolveAvailableActionVariables, resolveNavigateToAction } from '../../../store/LogicBuilderStore/utils';
import { StoreContext } from '../../../store/StoreProvider';
import { InputProp, IPage } from '../../../store/types';
import { typedDeepMerge } from '../../../utils/objectUtils';
import { ActionPanelCombinedProps, ActionPanelStateEnum, ActionPanelTypeEnum } from './types';
import { CombinedActionInputs, mapTypeDynamicProps } from './utils';

const usePresenter = (props: ActionPanelCombinedProps): ActionPanelCombinedProps => {
  const {
    pageStore,
    apiGroupStore,
    logicBuilderStore,
  } = useContext(StoreContext);

  const { apiGroups } = apiGroupStore;
  const { pages, currentPageVariables } = pageStore;
  const { selectedFlow, selectedAction, updateAction } = logicBuilderStore;

  const { t } = useTranslation();

  const [type, setType] = useState<ActionPanelTypeEnum>('Unselected');
  const [state, setState] = useState<ActionPanelStateEnum>('Expanded');
  const [currentAction, setCurrentAction] = useState<ActionTypeEnum | undefined>();

  useEffect(() => {
    if (selectedAction) {
      switch (selectedAction.actionType) {
        case 'CallApi':
          setType('CallAPI');
          break;
        case 'NavigateTo':
          if (selectedAction.metadata?.destination === 'URL') {
            setType('NavigateURL');
          } else {
            setType('NagivatePage');
          }
          break;
        case 'UpdateVariable':
          setType('UpdateVar');
          break;
        default:
          setType('Unselected');
          break;
      }
    } else {
      setType('EmptyState');
    }
    setCurrentAction(selectedAction?.actionType);
  }, [selectedFlow, selectedAction]);

  const onActionSelected = useCallback(async (option: string) => {
    const newAction = option as ActionTypeEnum;
    let newMetadata: Record<string, any> | undefined;
    switch (newAction) {
      case 'CallApi':
        setType('CallAPI');
        break;
      case 'NavigateTo': {
        setType('NagivatePage');
        const metadata: NavigateToMetadata = {
          destination: 'Page',
          launchType: 'same_tab',
        };
        newMetadata = metadata;
        break;
      }
      case 'UpdateVariable':
        setType('UpdateVar');
        break;
      default:
        break;
    }
    setCurrentAction(newAction);

    if (selectedAction) {
      await updateAction(selectedAction, {
        actionType: newAction,
        metadata: newMetadata,
        shouldMerge: false,
      });
    }
  }, [selectedAction, updateAction]);

  /// ///////////////////////////////////////////////////////////////////////////////////////////////////
  // API call action
  /// ///////////////////////////////////////////////////////////////////////////////////////////////////

  const [currentApiGroup, setCurrentApiGroup] = useState<ApiGroup>();
  const [currentEndpoint, setCurrentEndpoint] = useState<ApiGroupEndpoint>();

  useEffect(() => {
    if (selectedAction?.actionType === 'CallApi') {
      const { apiGroup, endpoint } = resolveApiCallAction(selectedAction, apiGroups);
      setCurrentApiGroup(apiGroup);
      setCurrentEndpoint(endpoint);
    } else {
      setCurrentApiGroup(undefined);
      setCurrentEndpoint(undefined);
    }
  }, [selectedAction, apiGroups]);

  const onApiGroupSelected = useCallback(async (apiGroupUuid: string) => {
    const apiGroup = apiGroups.find((item) => item.uuid === apiGroupUuid);
    setCurrentApiGroup(apiGroup);
    setCurrentEndpoint(undefined);

    if (selectedAction) {
      const metadata: CallApiMetadata = {
        apiGroupUuid: apiGroup?.uuid,
      };
      await updateAction(selectedAction, { metadata, shouldMerge: false });
    }
  }, [selectedAction, apiGroups, updateAction]);

  const onEndpointSelected = useCallback(async (apiEndpointUuid: string) => {
    const endpoint = currentApiGroup?.endpoints?.find((item) => item.uuid === apiEndpointUuid);
    setCurrentEndpoint(endpoint);
    if (selectedAction) {
      const metadata: CallApiMetadata = {
        apiGroupUuid: currentApiGroup?.uuid,
        apiEndpointUuid: endpoint?.uuid,
      };
      await updateAction(selectedAction, { metadata, shouldMerge: false });
    }
  }, [selectedAction, currentApiGroup, updateAction]);

  /// ///////////////////////////////////////////////////////////////////////////////////////////////////
  // Navigate to action
  /// ///////////////////////////////////////////////////////////////////////////////////////////////////

  const [currentDestination, setCurrentDestination] = useState<NavigationDestination>('Page');
  const [currentUrl, setCurrentUrl] = useState<InputProp | null>();
  const [currentPage, setCurrentPage] = useState<IPage>();
  const [currentLaunch, setCurrentLaunch] = useState<NavigationLaunchType>('same_tab');

  useEffect(() => {
    if (selectedAction?.actionType === 'NavigateTo') {
      const { destination, url, page, launchType } = resolveNavigateToAction(selectedAction, pages);
      setCurrentDestination(destination);
      setCurrentUrl(url);
      setCurrentPage(page);
      setCurrentLaunch(launchType);
    } else {
      setCurrentDestination('Page');
      setCurrentPage(undefined);
      setCurrentLaunch('same_tab');
    }
  }, [selectedAction, pages]);

  const onDestinationSelected = useCallback(async (destination: string) => {
    setCurrentDestination(destination as NavigationDestination);
    switch (destination as NavigationDestination) {
      case 'Page':
        setType('NagivatePage');
        break;
      case 'URL':
        setType('NavigateURL');
        break;
      default:
        break;
    }

    if (selectedAction) {
      const metadata: NavigateToMetadata = {
        destination: destination as NavigationDestination,
        launchType: currentLaunch,
      };
      await updateAction(selectedAction, { metadata, shouldMerge: false });
    }
  }, [currentLaunch, selectedAction, updateAction]);

  const onCurrentUrlUpdated = useCallback(async (url: string) => {
    const newUrl: InputProp = {
      name: 'url',
      source: 'value',
      type: 'string',
      value: url,
    };
    setCurrentUrl(newUrl);
    if (selectedAction) {
      const metadata: NavigateToMetadata = {
        url: newUrl,
      };
      await updateAction(selectedAction, { metadata });
    }
  }, [selectedAction, updateAction]);

  const handleSetUrlFromVariable = useCallback(async (variable: VariableReferenceType) => {
    const newUrl: InputProp = {
      name: 'url',
      source: 'variable',
      type: 'string',
      value: variable,
    };
    setCurrentUrl(newUrl);
    if (selectedAction) {
      const metadata: NavigateToMetadata = {
        url: newUrl,
      };
      await updateAction(selectedAction, { metadata });
    }
  }, [selectedAction, updateAction]);

  const handleClearUrlVariable = useCallback(async () => {
    const newUrl: InputProp = {
      name: 'url',
      source: 'value',
      type: 'string',
      value: '',
    };
    setCurrentUrl(newUrl);
    if (selectedAction) {
      const metadata: NavigateToMetadata = {
        url: newUrl,
      };
      await updateAction(selectedAction, { metadata });
    }
  }, [selectedAction, updateAction]);

  const [selectedVariable, setSelectedVariable] = useState<VariableReferenceType>();

  const { modalContainerRef, openModal } = useModal(
    SetVariableMenuContext,
    () => {
      return {
        variables: resolveAvailableActionVariables('string', selectedAction, selectedFlow, apiGroups, currentPageVariables),
        selectedVariable,
        onClearClicked: handleClearUrlVariable,
        onVariableSelected: handleSetUrlFromVariable,
      };
    },
  );

  const openUrlVariableModal = (currentVariable?: VariableReferenceType) => {
    setSelectedVariable(currentVariable);
    openModal({
      customPosition: getCustomPositionForElementLeft(modalContainerRef, 4),
    });
  };

  const onPageSelected = useCallback(async (pageUuid: string) => {
    const page = pages.find((item) => item.uuid === pageUuid);
    setCurrentPage(page);
    if (selectedAction) {
      const metadata: NavigateToMetadata = {
        destination: currentDestination,
        launchType: currentLaunch,
        pageUuid,
      };
      await updateAction(selectedAction, { metadata, shouldMerge: false });
    }
  }, [currentDestination, currentLaunch, pages, selectedAction, updateAction]);

  const onLaunchOptionSelected = useCallback(async (launchOption: string) => {
    setCurrentLaunch(launchOption as NavigationLaunchType);
    if (selectedAction) {
      const metadata: NavigateToMetadata = {
        launchType: launchOption as NavigationLaunchType,
      };
      await updateAction(selectedAction, { metadata });
    }
  }, [selectedAction, updateAction]);

  const actionInputs: CombinedActionInputs = {
    callApi: {
      currentApiGroup,
      apiGroups,
      onApiGroupSelected,
      currentEndpoint,
      endpoints: currentApiGroup?.endpoints,
      onEndpointSelected,
    },
    navigateTo: {
      currentDestination,
      destinations: [...navigationDestinations],
      onDestinationSelected,
      currentUrl,
      onCurrentUrlUpdated,
      onClearUrlVariableClicked: handleClearUrlVariable,
      openUrlVariableModal,
      currentPage,
      pages,
      onPageSelected,
      currentLaunch,
      launchOptions: [...navigationLaunchTypes],
      onLaunchOptionSelected,
    },
  };

  return {
    ...typedDeepMerge(props, {
      emptyStateInfo: {
        text: {
          value: t('logicSettingsPanel.emptyStateInfo'),
        },
      },
      panelHeader: {
        button: {
          onClick: () => setState(state === 'Collapsed' ? 'Expanded' : 'Collapsed'),
        },
        text: {
          value: t('logicSettingsPanel.action'),
        },
      },
      rowLabel: {
        text: {
          value: t('logicSettingsPanel.action'),
        },
      },
      dropdownButton: {
        toggleProps: {
          text: {
            value: currentAction
              ? t(`logicSettingsPanel.actionTypes.${currentAction}`)
              : t('logicSettingsPanel.placeholders.selectAction'),
          },
          colour: 'Default',
          style: 'Default',
          type: 'Default',
        },
        menuProps: {
          style: 'Default',
          dropdownItems: availableActionTypes.map((action) => {
            return {
              text: {
                value: t(`logicSettingsPanel.actionTypes.${action}`),
              },
              type: 'Default',
              state: 'Default',
              dropdownItemValue: action,
            };
          }),
          onOptionSelected: onActionSelected,
        },
      },
      ...mapTypeDynamicProps(type, t, actionInputs),
      type: state === 'Collapsed' ? 'Empty' : type,
      state: type === 'EmptyState' ? 'Default' : state,
    }),
    setVariableMenuContainerRef: modalContainerRef,
  };
};

export default usePresenter;
