import { useSpring } from '@react-spring/web';
import { useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { StoreContext } from '../../../store/StoreProvider';
import { LogicBuilderPanelCombinedProps } from './types';
import { LogicTabCombinedProps } from '../../atoms/LogicTab/types';
import { nodeTypes, convertToNodesAndEdges } from './utils';
import { FlowTypeEnum, flowTypes } from '../../../modules/actions/types';

const usePresenter = (props: LogicBuilderPanelCombinedProps): LogicBuilderPanelCombinedProps => {
  const { t } = useTranslation();
  const {
    pageStore,
    apiGroupStore,
    logicBuilderStore,
  } = useContext(StoreContext);
  const selectedElement = pageStore.selectedCombineElement;
  const { apiGroups } = apiGroupStore;
  const { pages, currentPageVariables } = pageStore;
  const {
    yOffset,
    selectedFlowType: flowType,
    setSelectedFlowType,
    selectedAction,
    selectedFlow,
    setSelectedActionUuid,
    addAction,
  } = logicBuilderStore;

  const [{ height }, heightState] = useSpring(() => ({
    height: yOffset,
  }));

  const { nodes, edges } = useMemo(() => convertToNodesAndEdges(
    selectedFlow,
    selectedAction,
    setSelectedActionUuid,
    addAction,
    t,
    apiGroups,
    pages,
    currentPageVariables,
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [
    selectedFlow,
    selectedAction,
    selectedAction?.actionType,
    selectedAction?.metadata,
    setSelectedActionUuid,
    addAction,
    t,
    apiGroups,
    pages,
    currentPageVariables,
  ]);

  const onFlowTypeClicked = (type: FlowTypeEnum) => () => {
    if (selectedElement) {
      setSelectedFlowType(type);
    }
  };

  const logicTabs: LogicTabCombinedProps[] = flowTypes.map((currentType): LogicTabCombinedProps => {
    return {
      state: flowType === currentType ? 'Selected' : 'Default',
      text: {
        value: t(`logicBuilderPanel.${currentType}`),
        colour: 'NeutralHoverSelected',
      },
      onClick: onFlowTypeClicked(currentType),
    };
  });

  useEffect(() => {
    heightState.start({
      height: yOffset,
      immediate: true,
    });
  }, [heightState, yOffset]);

  return {
    ...props,
    height,
    state: yOffset > 0 ? 'Expanded' : 'Collapsed',
    logicTabList: {
      logicTabs,
    },
    actionContainer: {
      nodeTypes,
      nodes,
      edges,
    },
  };
};

export default usePresenter;
