import React from 'react';
import cx from 'classnames';

import InputField from '../../atoms/InputField';
import RowLabel from '../../atoms/RowLabel';
import PanelHeader from '../../molecules/PanelHeader';
import ToggleItemList from '../../molecules/ToggleItemList';

import styles from './CornersPanel.module.scss';
import usePresenter from './CornersPanel.presenter';
import getProps from './CornersPanel.props';
import type { CornersPanelCombinedProps } from './types';

const CornersPanel: React.FC<CornersPanelCombinedProps> = (props) => {
  const {
    state,
    className,
    classes,
    panelHeader,
    radius,
    inputField,
    toggleItemList,
    topLeft,
    topRight,
    bottomLeft,
    bottomRight,
    renderPanel, // MANUAL OVERRIDE
  } = usePresenter(props);

  // MANUAL OVERRIDE STARTS
  if (!renderPanel) {
    return null;
  }
  // MANUAL OVERRIDE ENDS

  const variantName = `${state}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`cornersPanel${variantName}`];

  let componentView;

  switch (variantName) {
    case 'Collapsed': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <PanelHeader
            className={cx(styles.panelHeader, classes?.panelHeader)}
            {...internalProps.panelHeader}
            {...panelHeader}
          />
        </div>
      );
      break;
    }
    case 'ExpandedSeperate': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <PanelHeader
            className={cx(styles.panelHeader, classes?.panelHeader)}
            {...internalProps.panelHeader}
            {...panelHeader}
          />
          <div className={cx(styles.cornersContent, classes?.cornersContent)}>
            <div className={cx(styles.radiusContent, classes?.radiusContent)}>
              <RowLabel
                className={cx(styles.radius, classes?.radius)}
                {...internalProps.radius}
                {...radius}
              />
              <InputField
                className={cx(styles.inputField, classes?.inputField)}
                {...internalProps.inputField}
                {...inputField}
              />
              <ToggleItemList
                className={cx(styles.toggleItemList, classes?.toggleItemList)}
                {...internalProps.toggleItemList}
                {...toggleItemList}
              />
            </div>
            <div className={cx(styles.seperateCornerContent, classes?.seperateCornerContent)}>
              <div className={cx(styles.topContent, classes?.topContent)}>
                <InputField
                  className={cx(styles.topLeft, classes?.topLeft)}
                  {...internalProps.topLeft}
                  {...topLeft}
                />
                <InputField
                  className={cx(styles.topRight, classes?.topRight)}
                  {...internalProps.topRight}
                  {...topRight}
                />
              </div>
              <div className={cx(styles.bottomContent, classes?.bottomContent)}>
                <InputField
                  className={cx(styles.bottomLeft, classes?.bottomLeft)}
                  {...internalProps.bottomLeft}
                  {...bottomLeft}
                />
                <InputField
                  className={cx(styles.bottomRight, classes?.bottomRight)}
                  {...internalProps.bottomRight}
                  {...bottomRight}
                />
              </div>
            </div>
          </div>
        </div>
      );
      break;
    }
    case 'ExpandedUnion': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <PanelHeader
            className={cx(styles.panelHeader, classes?.panelHeader)}
            {...internalProps.panelHeader}
            {...panelHeader}
          />
          <div className={cx(styles.cornersContent, classes?.cornersContent)}>
            <div className={cx(styles.radiusContent, classes?.radiusContent)}>
              <RowLabel
                className={cx(styles.radius, classes?.radius)}
                {...internalProps.radius}
                {...radius}
              />
              <InputField
                className={cx(styles.inputField, classes?.inputField)}
                {...internalProps.inputField}
                {...inputField}
              />
              <ToggleItemList
                className={cx(styles.toggleItemList, classes?.toggleItemList)}
                {...internalProps.toggleItemList}
                {...toggleItemList}
              />
            </div>
          </div>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default CornersPanel;
