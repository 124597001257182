import React from 'react';
import cx from 'classnames';

import ExpandedSectionHeader from '../../molecules/ExpandedSectionHeader';
import LogicVariableTable from '../LogicVariableTable';

import styles from './VariableSection.module.scss';
import usePresenter from './VariableSection.presenter';
import getProps from './VariableSection.props';
import { VariableSectionCombinedProps } from './types';

const VariableSection: React.FC<VariableSectionCombinedProps> = (props) => {
  const {
    state,
    className,
    classes,
    expandedSectionHeader,
    logicVariableTable,
  } = usePresenter(props);

  const variantName = `${state}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`variableSection${variantName}`];

  let componentView;

  switch (variantName) {
    case 'Collapsed': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <ExpandedSectionHeader
            className={cx(styles.expandedSectionHeader, classes?.expandedSectionHeader)}
            {...internalProps.expandedSectionHeader}
            {...expandedSectionHeader}/>
        </div>
      );
      break;
    }
    case 'Expanded': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <ExpandedSectionHeader
            className={cx(styles.expandedSectionHeader, classes?.expandedSectionHeader)}
            {...internalProps.expandedSectionHeader}
            {...expandedSectionHeader}/>
          <LogicVariableTable
            className={cx(styles.logicVariableTable, classes?.logicVariableTable)}
            {...internalProps.logicVariableTable}
            {...logicVariableTable}/>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default VariableSection;
