import React from 'react';
import cx from 'classnames';

import PanelHeader from '../../molecules/PanelHeader';
import ReplaceRowList from '../../molecules/ReplaceRowList';

import styles from './ValuesPanel.module.scss';
import usePresenter from './ValuesPanel.presenter';
import getProps from './ValuesPanel.props';
import { ValuesPanelCombinedProps } from './types';
import SetVariableMenu from '../SetVariableMenu';

const ValuesPanel: React.FC<ValuesPanelCombinedProps> = (props) => {
  const {
    state,
    className,
    classes,
    panelHeader,
    replaceRowList,
    // MANUAL OVERRIDE START
    renderPanel,
    setVariableMenuContainerRef,
    // MANUAL OVERRIDE END
  } = usePresenter(props);

  // MANUAL OVERRIDE START
  if (!renderPanel) {
    return null;
  }
  // MANUAL OVERRIDE END

  const variantName = `${state}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`valuesPanel${variantName}`];

  let componentView;

  switch (variantName) {
    case 'Collapsed': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <PanelHeader
            className={cx(styles.panelHeader, classes?.panelHeader)}
            {...internalProps.panelHeader}
            {...panelHeader}/>
        </div>
      );
      break;
    }
    case 'Expanded': {
      componentView = (
        // MANUAL OVERRIDE: Adding ref prop
        <div ref={setVariableMenuContainerRef} className={cx(currentStyle, className)}>
          <PanelHeader
            className={cx(styles.panelHeader, classes?.panelHeader)}
            {...internalProps.panelHeader}
            {...panelHeader}/>
          <div className={cx(styles.panelContent, classes?.panelContent)}>
            <ReplaceRowList
              className={cx(styles.replaceRowList, classes?.replaceRowList)}
              {...internalProps.replaceRowList}
              {...replaceRowList}/>
          </div>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default ValuesPanel;
