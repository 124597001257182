import { CSSProperties } from 'react';
import {
  CanvasDimensions,
  ColorType,
  DirectionType,
  HorizontalAnchor,
  IStyles,
  UnitType,
  VerticalAnchor,
} from '../store/types';
import { percentageToHex } from './cssUtils';

/**
 * All dependencies for mapping functions go here
 */

export const mapUnitTypeToCssProperty = (unitType: UnitType): string => {
  if (!unitType.value) {
    return `${unitType.unit}`;
  }
  return `${unitType.value || 0}${unitType.unit}`;
};

export const mapCssUnit = (dimension: UnitType | undefined, canvasSize?: CanvasDimensions, canvasCheat?: boolean): string => {
  if (!dimension) {
    return '0px';
  }
  if (dimension.value === undefined) {
    return `${dimension.unit}`;
  }
  if (canvasCheat) {
    if (dimension.unit === 'vw') {
      const cheatValue = ((dimension.value || 0) / 100) * (canvasSize?.width || 0);
      return `${cheatValue}px`;
    } else if (dimension.unit === 'vh') {
      const cheatValue = ((dimension.value || 0) / 100) * (canvasSize?.height || 0);
      return `${cheatValue}px`;
    }
  }
  return `${dimension.value}${dimension.unit}`;
};

export const mapColor = (color: ColorType): string => {
  return `${color.hexCode}${percentageToHex(color.opacity.value)}`;
};

const mapAlignmentToCssValue = (alignment: string | null): string => {
  switch (alignment) {
    case 'center':
      return 'center';
    case 'right':
    case 'bottom':
      return 'flex-end';
    case 'left':
    case 'top':
      return 'flex-start';
    default:
      return '';
  }
};

/**
 * All mapping function go here in alphabetic order
 */

export const mapAlignments = (styles: IStyles): CSSProperties => {
  const cssProperties: CSSProperties = {};
  const { direction, alignment, spacing } = styles;
  let alignItems: string;
  let justifyContent: string;
  if (direction && alignment && spacing) {
    const oppositeDirection: DirectionType = direction === 'vertical' ? 'horizontal' : 'vertical';
    if (spacing === 'packed') {
      alignItems = mapAlignmentToCssValue(`${alignment[oppositeDirection] as string}`);
      justifyContent = mapAlignmentToCssValue(`${alignment[direction] as string}`);
    } else {
      justifyContent = spacing;
      alignItems = mapAlignmentToCssValue(`${alignment[oppositeDirection] as string}`);
    }
    cssProperties.alignItems = alignItems;
    cssProperties.justifyContent = justifyContent;
  }
  return cssProperties;
};

export const convertAnchor = (
  anchor: HorizontalAnchor | VerticalAnchor,
  centerAnchor: 'top' | 'left',
  canvasSize?: CanvasDimensions,
  canvasCheat?: boolean,
) => {
  switch (anchor?.anchorPoint) {
    case 'top':
    case 'bottom':
    case 'left':
    case 'right':
      return `${anchor.anchorPoint} ${mapCssUnit(anchor.offset, canvasSize, canvasCheat)}`;
    case 'center':
      return `${centerAnchor} calc(50% + ${mapCssUnit(anchor.offset, canvasSize, canvasCheat)})`;
    default:
      return '';
  }
};

export const mapBackground = (
  styles: IStyles,
  canvasSize?: CanvasDimensions,
  canvasCheat?: boolean,
): CSSProperties => {
  const cssProperties: CSSProperties = {};
  const { backgroundColor, backgroundImage, backgroundSize, backgroundVerticalAnchor, backgroundHorizontalAnchor } = styles;
  if (backgroundColor) {
    cssProperties.backgroundColor = mapColor(backgroundColor);
  }
  if (backgroundImage) {
    cssProperties.backgroundImage = `url(${backgroundImage.url})`;
  }
  cssProperties.backgroundRepeat = 'no-repeat';
  cssProperties.backgroundSize = backgroundSize || 'cover';

  const verticalPosition = backgroundVerticalAnchor ? convertAnchor(backgroundVerticalAnchor, 'top', canvasSize, canvasCheat) : '';
  const horizontalPosition = backgroundHorizontalAnchor ? convertAnchor(backgroundHorizontalAnchor, 'left', canvasSize, canvasCheat) : '';
  cssProperties.backgroundPosition = `${verticalPosition} ${horizontalPosition}`.trim();

  return cssProperties;
};

export const mapObject = (
  styles: IStyles,
  canvasSize?: CanvasDimensions,
  canvasCheat?: boolean,
): CSSProperties => {
  const cssProperties: CSSProperties = {};
  const { objectFit, objectVerticalAnchor, objectHorizontalAnchor } = styles;

  cssProperties.objectFit = objectFit || 'cover';

  const verticalPosition = objectVerticalAnchor ? convertAnchor(objectVerticalAnchor, 'top', canvasSize, canvasCheat) : '';
  const horizontalPosition = objectHorizontalAnchor ? convertAnchor(objectHorizontalAnchor, 'left', canvasSize, canvasCheat) : '';
  cssProperties.objectPosition = `${verticalPosition} ${horizontalPosition}`.trim();

  return cssProperties;
};

export const mapBorder = (styles: IStyles): CSSProperties => {
  const cssProperties: CSSProperties = {};
  if (styles.borderWeight) {
    cssProperties.borderWidth = mapCssUnit(styles.borderWeight);
  }
  if (styles.borderStyle) {
    cssProperties.borderStyle = styles.borderStyle;
  }
  if (styles.borderColor) {
    cssProperties.borderColor = mapColor(styles.borderColor);
  }
  return cssProperties;
};

export const mapFlexDirection = (styles: IStyles): CSSProperties => {
  const cssProperties: CSSProperties = {};
  if (styles.direction) {
    cssProperties.flexDirection = styles.direction === 'vertical' ? 'column' : 'row';
  }
  if (styles.wrap) {
    cssProperties.flexWrap = 'wrap';
  }
  return cssProperties;
};

export const mapGap = (styles: IStyles, canvasSize: CanvasDimensions, canvasCheat: boolean): CSSProperties => {
  const cssProperties: CSSProperties = {};
  const gap = mapCssUnit(styles.gap, canvasSize, canvasCheat);
  const rowGap = mapCssUnit(styles.rowGap, canvasSize, canvasCheat);
  if (styles.wrap) {
    cssProperties.gap = `${rowGap || 0} ${gap}`;
  } else {
    cssProperties.gap = gap;
  }
  return cssProperties;
};

export const mapOverflowDimensions = (styles: IStyles): CSSProperties => {
  const cssProperties: CSSProperties = {};
  if (styles.overflow) {
    if (styles.overflow === 'scroll') {
      cssProperties.overflow = 'auto';
    } else {
      cssProperties.overflow = styles.overflow;
    }
  }
  return cssProperties;
};

export const mapPaddingMarginToCssProps = (styles: IStyles): CSSProperties => {
  const cssProperties: CSSProperties = {};
  const { margin, padding } = styles;
  if (margin) {
    const { top, right, bottom, left } = margin;
    cssProperties.margin = `${mapCssUnit(top)} ${mapCssUnit(right)} ${mapCssUnit(bottom)} ${mapCssUnit(left)}`;
  }
  if (padding) {
    const { top, right, bottom, left } = padding;
    cssProperties.padding = `${mapCssUnit(top)} ${mapCssUnit(right)} ${mapCssUnit(bottom)} ${mapCssUnit(left)}`;
  }
  return cssProperties;
};

export const mapSizeDimensions = (
  styles: IStyles,
  canvasSize: CanvasDimensions,
  canvasCheat: boolean,
): CSSProperties => {
  const cssProperties: CSSProperties = {};
  /* eslint-disable @typescript-eslint/restrict-template-expressions */
  if (styles.maxHeight) {
    if (styles.maxHeight.unit === 'none') {
      cssProperties.maxHeight = 'unset';
    } else if (styles.maxHeight.value !== undefined) {
      cssProperties.maxHeight = mapCssUnit(styles.maxHeight, canvasSize, canvasCheat);
    }
  }
  if (styles.maxWidth) {
    if (styles.maxWidth.unit === 'none') {
      cssProperties.maxWidth = 'unset';
    } else if (styles.maxWidth.value !== undefined) {
      cssProperties.maxWidth = mapCssUnit(styles.maxWidth, canvasSize, canvasCheat);
    }
  }
  if (styles.minHeight?.value !== undefined) {
    cssProperties.minHeight = mapCssUnit(styles.minHeight, canvasSize, canvasCheat);
  }
  if (styles.minWidth?.value !== undefined) {
    cssProperties.minWidth = mapCssUnit(styles.minWidth, canvasSize, canvasCheat);
  }
  if (styles.width) {
    if (styles.width.unit === 'fill') {
      cssProperties.width = '100%';
    } else if (styles.width.unit === 'hug') {
      cssProperties.width = 'max-content';
      cssProperties.flex = '0 0 auto';
    } else {
      cssProperties.width = mapCssUnit(styles.width, canvasSize, canvasCheat);
      cssProperties.flex = '0 0 auto';
    }
  }
  if (styles.height) {
    if (styles.height.unit === 'fill') {
      cssProperties.height = '100%';
    } else if (styles.height.unit === 'hug') {
      cssProperties.height = 'max-content';
    } else {
      cssProperties.height = mapCssUnit(styles.height, canvasSize, canvasCheat);
    }
  }
  /* eslint-disable @typescript-eslint/restrict-template-expressions */
  return cssProperties;
};

export const mapTextSettings = (styles: IStyles): CSSProperties => {
  const cssProperties: CSSProperties = {};
  if (styles.font) {
    cssProperties.fontFamily = styles.font;
  }
  switch (styles.fontWeight) {
    case 'thin':
      cssProperties.fontWeight = 100;
      break;
    case 'extra-light':
      cssProperties.fontWeight = 200;
      break;
    case 'light':
      cssProperties.fontWeight = 300;
      break;
    case 'medium':
      cssProperties.fontWeight = 500;
      break;
    case 'semi-bold':
      cssProperties.fontWeight = 600;
      break;
    case 'bold':
      cssProperties.fontWeight = 700;
      break;
    case 'extra-bold':
      cssProperties.fontWeight = 800;
      break;
    case 'black':
      cssProperties.fontWeight = 900;
      break;
    default:
      cssProperties.fontWeight = 'normal';
      break;
  }
  if (styles.fontSize) {
    cssProperties.fontSize = styles.fontSize.value;
  }
  if (styles.letterSpacing) {
    cssProperties.letterSpacing = styles.letterSpacing.value;
  }
  if (styles.lineHeight) {
    cssProperties.lineHeight = mapCssUnit(styles.lineHeight);
  }
  if (styles.fontColor) {
    cssProperties.color = mapColor(styles.fontColor);
  }
  cssProperties.fontStyle = styles.fontItalic ? 'italic' : 'normal';
  if (styles.fontAlign) {
    cssProperties.textAlign = styles.fontAlign as CSSProperties['textAlign'];
  }
  if (styles.fontStyle) {
    cssProperties.textDecoration = styles.fontStyle;
  }
  return cssProperties;
};

export const mapBorderCssProps = (styles: IStyles): CSSProperties => {
  const cssProperties: CSSProperties = {};
  if (styles.borderColor) {
    cssProperties.borderColor = `${styles.borderColor.hexCode}${percentageToHex(styles.borderColor.opacity.value)}`;
  }
  if (styles.borderStyle) {
    cssProperties.borderStyle = styles.borderStyle;
  }
  if (styles.borderWeight) {
    cssProperties.borderWidth = mapCssUnit(styles.borderWeight);
  }
  return cssProperties;
};
export const mapCornerRadiusToCssProps = (styles: IStyles): CSSProperties => {
  const cssProperties: CSSProperties = {};
  const { cornerRadius,
    unifiedCornerRadius,
    separateCornerRadius } = styles;
  if (unifiedCornerRadius) {
    cssProperties.borderRadius = mapCssUnit(cornerRadius);
  } else if (separateCornerRadius) {
    if (separateCornerRadius.topLeft) {
      cssProperties.borderTopLeftRadius = mapCssUnit(separateCornerRadius.topLeft);
    }
    if (separateCornerRadius.topRight) {
      cssProperties.borderTopRightRadius = mapCssUnit(separateCornerRadius.topRight);
    }
    if (separateCornerRadius.bottomLeft) {
      cssProperties.borderBottomLeftRadius = mapCssUnit(separateCornerRadius.bottomLeft);
    }
    if (separateCornerRadius.bottomRight) {
      cssProperties.borderBottomRightRadius = mapCssUnit(separateCornerRadius.bottomRight);
    }
  }
  return cssProperties;
};

export const mapOpacityToCssProps = (styles: IStyles): CSSProperties => {
  const cssProperties: CSSProperties = {};
  const { opacity } = styles;
  if (opacity) {
    cssProperties.opacity = opacity.value / 100;
  }
  return cssProperties;
};

export const mapVisibilityToCssProps = (styles: IStyles): CSSProperties => {
  const cssProperties: CSSProperties = {};
  const { visible } = styles;
  if (String(visible) === 'false') {
    cssProperties.display = 'none';
  }
  return cssProperties;
};

export const mapPositionToCssProps = (styles: IStyles, canvasSize: CanvasDimensions, canvasCheat: boolean): CSSProperties => {
  const cssProperties: CSSProperties = {};
  const notStatic = styles.position !== 'static';
  if (styles.position) {
    cssProperties.position = styles.position;
  }
  if (notStatic && styles.verticalAnchor) {
    switch (styles.verticalAnchor.anchorPoint) {
      case 'center':
        cssProperties.top = `calc(50% + ${mapCssUnit(styles.verticalAnchor.offset, canvasSize, canvasCheat)})`;
        break;
      case 'bottom':
        cssProperties.bottom = mapCssUnit(styles.verticalAnchor.offset, canvasSize, canvasCheat);
        break;
      default:
        cssProperties.top = mapCssUnit(styles.verticalAnchor.offset, canvasSize, canvasCheat);
        break;
    }
  }
  if (notStatic && styles.horizontalAnchor) {
    switch (styles.horizontalAnchor.anchorPoint) {
      case 'center':
        cssProperties.left = `calc(50% + ${mapCssUnit(styles.horizontalAnchor.offset)})`;
        break;
      case 'right':
        cssProperties.right = mapCssUnit(styles.horizontalAnchor.offset);
        break;
      default:
        cssProperties.left = mapCssUnit(styles.horizontalAnchor.offset);
        break;
    }
  }
  return cssProperties;
};

// Calls all the mapping functions declared above
export const mapStylesToCssProps = (
  styles: IStyles,
  canvasSize: CanvasDimensions = { width: 0, height: 0 },
  canvasCheat = true,
): CSSProperties => {
  if (!styles) {
    return {};
  }
  // Return CSS common props
  const cssProperties: CSSProperties = {
    ...mapVisibilityToCssProps(styles),
    ...mapAlignments(styles),
    ...mapBackground(styles, canvasSize, canvasCheat),
    ...mapObject(styles, canvasSize, canvasCheat),
    ...mapBorder(styles),
    ...mapFlexDirection(styles),
    ...mapGap(styles, canvasSize, canvasCheat),
    ...mapPositionToCssProps(styles, canvasSize, canvasCheat),
    ...mapOverflowDimensions(styles),
    ...mapPaddingMarginToCssProps(styles),
    ...mapBorderCssProps(styles),
    ...mapCornerRadiusToCssProps(styles),
    ...mapSizeDimensions(styles, canvasSize, canvasCheat),
    ...mapTextSettings(styles),
    ...mapOpacityToCssProps(styles),
  };
  return cssProperties;
};

export const mapCursor = (styles: IStyles): CSSProperties => {
  const cssProperties: CSSProperties = {};
  const { cursor } = styles;
  if (cursor) {
    cssProperties.cursor = cursor;
  }
  return cssProperties;
};

export const mapStylesToPreviewCssProps = (
  styles: IStyles,
) => ({
  ...mapStylesToCssProps(styles, undefined, false),
  ...mapCursor(styles),
});
