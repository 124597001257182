import axiosInstance from '../../lib/api/axios';
import { STUDIO_API_URL } from '../../lib/config';
import {
  CompleteMultipartUploadPayload,
  CompleteMultipartUploadResponse,
  CreateMultipartUploadPayload,
  CreateMultipartUploadResponse,
} from './types';

export const createMultipartUpload = async (
  payload: CreateMultipartUploadPayload,
): Promise<CreateMultipartUploadResponse> => {
  const { mediaType } = payload;
  const { data } = await axiosInstance.post<CreateMultipartUploadResponse>(
    `${STUDIO_API_URL}/assets/${mediaType}/createUpload`,
    payload,
  );
  return data;
};

export const completeMultipartUpload = async (
  payload: CompleteMultipartUploadPayload,
): Promise<CompleteMultipartUploadResponse> => {
  const { mediaType } = payload;
  const { data } = await axiosInstance.post<CompleteMultipartUploadResponse>(
    `${STUDIO_API_URL}/assets/${mediaType}/completeUpload`,
    payload,
  );
  return data;
};
