import { computed } from 'mobx';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { getElement } from '../../../store/PageStore/utils';
import { StoreContext } from '../../../store/StoreProvider';
import { typedDeepMerge } from '../../../utils/objectUtils';
import type { SelectedContainerCombinedProps } from './types';

const usePresenter = (props: SelectedContainerCombinedProps): SelectedContainerCombinedProps => {
  const { pageStore } = useContext(StoreContext);
  const { t } = useTranslation();

  const selectedElementName: string | undefined = computed(() => (pageStore.selectedElement
    ? getElement(pageStore.selectedElement.id, pageStore)?.name
    : undefined
  )).get();

  return typedDeepMerge(props, {
    rowLabel: {
      text: {
        value: t('selectedContainer.selected'),
      },
    },
    chip: {
      text: {
        value: selectedElementName,
      },
    },
  });
};

export default usePresenter;
