import { Node, Edge } from 'reactflow';
import { Constants } from '../constants';
import { VariableReferenceType } from '../modules/variables/types';
import type { PageStore } from './PageStore/PageStore';

const units = [...Constants.DROPDOWN_OPTIONS.DIMENSION_UNITS, 'none'] as const;
export type Unit = typeof units[number];

export const horizontalValues = ['left', 'center', 'right'] as const;
export type HorizontalType = typeof horizontalValues[number];

export const verticalValues = ['top', 'center', 'bottom'] as const;
export type VerticalType = typeof verticalValues[number];

export interface UnitType {
  value?: number;
  unit: Unit;
}

export type VerticalAnchor = {
  anchorPoint: VerticalType;
  offset: UnitType;
};

export type HorizontalAnchor = {
  anchorPoint: HorizontalType;
  offset: UnitType;
};

export interface PercentageUnitType extends UnitType {
  value: number;
  unit: '%';
}

export interface PixelUnitType extends UnitType {
  value: number;
  unit: 'px';
}

export type ColorType = {
  hexCode: string;
  opacity: PercentageUnitType;
};

export type AlignmentType = {
  horizontal: HorizontalType | null;
  vertical: VerticalType | null;
};

export type PaddingMarginType = {
  top: PixelUnitType;
  right: PixelUnitType;
  bottom: PixelUnitType;
  left: PixelUnitType;
};

export type FileType = {
  url: string;
  fileName: string;
};

export const directionTypeValues = ['horizontal', 'vertical'] as const;
export type DirectionType = typeof directionTypeValues[number];

export const spacingTypeValues = ['packed', 'space-around', 'space-between'] as const;
export type SpacingType = typeof spacingTypeValues[number];

export const positionTypeValues = ['static', 'relative', 'absolute', 'sticky', 'fixed'] as const;
export type PositionType = typeof positionTypeValues[number];

export const overflowTypeValues = ['visible', 'hidden', 'scroll'] as const;
export type OverflowType = typeof overflowTypeValues[number];

export const backgroundSizeValues = ['cover', 'contain'] as const;
export type BackgroundSizeType = typeof backgroundSizeValues[number];

export const borderStyleTypeValues = ['solid', 'dashed'] as const;
export type BorderStyleType = typeof borderStyleTypeValues[number];

export const cursorTypeValues = ['auto', 'grab', 'pointer'] as const;
export type CursorType = typeof cursorTypeValues[number];

export const fontTypeValues = ['inter', 'arial'] as const; // TODO: update values when more fonts are added
export type FontType = typeof fontTypeValues[number];

export const fontWeightTypeValues = ['thin', 'extra-light', 'light', 'regular', 'medium', 'semi-bold', 'bold', 'extra-bold', 'black'] as const;
export type FontWeightType = typeof fontWeightTypeValues[number];

export const fontAlignTypeValues = ['left', 'center', 'right', 'justify'] as const;
export type FontAlignType = typeof fontAlignTypeValues[number];

export const fontStyleTypeValues = ['none', 'line-through', 'underline', 'overline'] as const;
export type FontStyleType = typeof fontStyleTypeValues[number];

export type SeparateCornerRadiusType = {
  topLeft?: PixelUnitType;
  topRight?: PixelUnitType;
  bottomLeft?: PixelUnitType;
  bottomRight?: PixelUnitType;
};

export const unitTypeProperties = [
  // Size field properties
  'width',
  'height',
  'maxWidth',
  'maxHeight',
  'minWidth',
  'minHeight',
  // Layout panel properties
  'gap',
  'rowGap',
  // Typography
  'fontSize',
  'letterSpacing',
  'lineHeight',
  // Border
  'borderWeight',
  // Corner
  'cornerRadius',
  // Effects & Styles
  'opacity',
] as const;

export type UnitTypeProperty = typeof unitTypeProperties[number];

export const anchorTypeProperties = [
  // Layout panel properties
  'verticalAnchor',
  'horizontalAnchor',
  // Background
  'backgroundVerticalAnchor',
  'backgroundHorizontalAnchor',
  // Object
  'objectVerticalAnchor',
  'objectHorizontalAnchor',
] as const;

export type AnchorProperty = typeof anchorTypeProperties[number];

export const enumTypeProperties = [
  // Size field properties
  'overflow',
  // Layout panel properties
  'spacing',
  'direction',
  'position',
  // Typography
  'font',
  'fontWeight',
  'fontAlign',
  'fontStyle',
  // Background
  'backgroundSize',
  // Border
  'borderStyle',
  // Effects & Styles
  'cursor',
] as const;

export type EnumTypeProperty = typeof enumTypeProperties[number];

export interface IStyles {
  // Size field properties
  width?: UnitType;
  height?: UnitType;
  maxWidth?: UnitType;
  maxHeight?: UnitType;
  minWidth?: UnitType;
  minHeight?: UnitType;
  overflow?: OverflowType;

  // Padding & Margin
  padding?: PaddingMarginType;
  margin?: PaddingMarginType;

  // Layout panel properties
  spacing?: SpacingType;
  direction?: DirectionType;
  wrap?: boolean;
  alignment?: AlignmentType;
  gap?: UnitType;
  rowGap?: UnitType;
  position?: PositionType;
  horizontalAnchor?: HorizontalAnchor;
  verticalAnchor?: VerticalAnchor;

  // Typography
  font?: FontType;
  fontWeight?: FontWeightType;
  fontSize?: PixelUnitType;
  letterSpacing?: UnitType;
  lineHeight?: UnitType;
  fontColor?: ColorType;
  fontAlign?: FontAlignType;
  fontItalic?: boolean;
  fontStyle?: FontStyleType;

  // Background
  backgroundImage?: FileType | null;
  backgroundColor?: ColorType;
  backgroundSize?: BackgroundSizeType;
  backgroundVerticalAnchor?: VerticalAnchor;
  backgroundHorizontalAnchor?: HorizontalAnchor;

  // Object
  objectFit?: BackgroundSizeType;
  objectVerticalAnchor?: VerticalAnchor;
  objectHorizontalAnchor?: HorizontalAnchor;

  // Border
  borderWeight?: PixelUnitType;
  borderStyle?: BorderStyleType;
  borderColor?: ColorType;

  // Corner
  cornerRadius?: PixelUnitType;
  unifiedCornerRadius?: boolean;
  separateCornerRadius?: SeparateCornerRadiusType;

  // Effects & Styles
  visible?: boolean;
  opacity?: PercentageUnitType;
  cursor?: CursorType;
}

export interface IWithStyles {
  styles: IStyles;
}

export interface IBaseElement<TType extends string, TSettings extends InputPropsMap = InputPropsMap>
  extends IWithId,
  IWithType<TType>,
  IWithName,
  IWithParentId,
  IWithChildIds,
  IWithStyles,
  IWithSettings<TSettings> {
  isEdited?: boolean;
  isExpanded?: boolean;
}

export type MapStylesFunc<TType extends string, TReturnType> = (element: IBaseElement<TType>) => TReturnType;

export interface IApplication {
  id: number;
  name: string;
}

export type LeftPanelType = 'Design' | 'Layers' | 'Pages' | 'PageSettings';

export const rightPanelTypeValues = ['Style', 'Settings', 'LogicSettings'] as const;
export type RightPanelType = typeof rightPanelTypeValues[number];

export const stylePanelTypes = [
  'Size',
  'PaddingMargins',
  'Layout',
  'LayoutSpacing',
  'LayoutAlignment',
  'Typography',
  'Background',
  'Border',
  'Corners',
  'StyleEffects',
] as const;
export type StylePanelType = typeof stylePanelTypes[number];
export type IBasePage = {
  uuid: string;
  name: string;
  slug?: string;
};

export type PageElementsMap<ElementType = Element> = Record<string, ElementType>;
export type BreakpointElementsMap = Record<string, PageElementsMap<PartialElement>>;

export type IPage = IBasePage & {
  isHomePage: boolean;
  createdBy: string;
  createdAt: Date;
  isDraft?: boolean;
  lockedBy?: string;
  publishedBy?: string;
  publishedAt?: Date;
  elements?: PageElementsMap;
  breakpointElements?: BreakpointElementsMap;
};

export type IUpdatePage = Partial<Pick<IPage, 'name' | 'slug' | 'isHomePage' | 'isDraft' | 'lockedBy'>>;

export type ICreatePagePayload = Pick<IPage, 'name' | 'isHomePage'>;

export interface IUpdatePagePayload extends Partial<Pick<IPage, 'name' | 'slug' | 'isHomePage' | 'isDraft' | 'lockedBy'>> {
  page: IPage;
}

export interface IDeletePagePayload {
  page: IPage;
}

export interface IWithId {
  id: string;
}

export interface IWithType<TType extends string> {
  type: TType;
}

export interface IWithName {
  name: string;
}

export interface IWithParentId {
  parentId: string;
}

export interface IWithChildIds {
  childIds: string[];
}

export type SettingImageProp = {
  fileName: string;
  imageUrl: string;
  altText?: string;
};

export const inputPropTypes = ['string', 'number', 'boolean', 'image', 'object', 'collection'] as const;
export type InputPropType = typeof inputPropTypes[number];

export type InputPropSource = 'value' | 'variable';

type BaseInputProp<TSource extends InputPropSource, TType extends InputPropType, ValueType> = {
  name: string;
  displayName?: string;
  source: TSource;
  type: TType;
  value?: ValueType;
};
export type StringValueInputProp = BaseInputProp<'value', 'string', string>;
export type NumberValueInputProp = BaseInputProp<'value', 'number', number>;
export type BooleanValueInputProp = BaseInputProp<'value', 'boolean', boolean>;
export type ImageValueInputProp = BaseInputProp<'value', 'image', SettingImageProp>;
export type ObjectInputProp = BaseInputProp<'value', 'object', object>;
export type CollectionInputProp = BaseInputProp<'value', 'collection', object[]>;
// RealValueInputProp represents props that have actual set values that are not references
export type RealValueInputProp =
  StringValueInputProp |
  NumberValueInputProp |
  BooleanValueInputProp |
  ImageValueInputProp |
  ObjectInputProp |
  CollectionInputProp;
// VariableInputProp represents props that reference variables and need to be resolved.
export type VariableInputProp = BaseInputProp<'variable', InputPropType, VariableReferenceType>;
export type InputProp = RealValueInputProp | VariableInputProp;

export type InputPropsMap = {
  [key: string]: InputProp | null | undefined;
};
export interface IWithSettings<TSettings extends InputPropsMap> {
  settings: TSettings;
}
const buttonSettingsStateKey = 'state' as const;
type IButtonContainerSettings = {
  [buttonSettingsStateKey]?: BooleanValueInputProp | VariableInputProp;
};

const textSettingsValueKey = 'value' as const;
type ITextSettings = {
  [textSettingsValueKey]?: StringValueInputProp | VariableInputProp;
};

const imageSettingsImageKey = 'image' as const;
type IImageSettings = {
  [imageSettingsImageKey]?: ImageValueInputProp | VariableInputProp;
};

export interface IBody
  extends IBaseElement<typeof Constants.ELEMENT_TYPES.BODY> {
  id: typeof Constants.ID.BODY;
  name: typeof Constants.ID.BODY;
  parentId: '';
}

export type IContainer = IBaseElement<typeof Constants.ELEMENT_TYPES.CONTAINER>;

export type IButtonContainer = IBaseElement<typeof Constants.ELEMENT_TYPES.BUTTON_CONTAINER, IButtonContainerSettings>;

export type IText = IBaseElement<typeof Constants.ELEMENT_TYPES.TEXT, ITextSettings>;

export type IImage = IBaseElement<typeof Constants.ELEMENT_TYPES.IMAGE, IImageSettings>;

export type Element = IBody | IContainer | IButtonContainer | IText | IImage;

export type PartialElement = Pick<Element, 'id' | 'settings' | 'styles'>;

export type ElementType = Element['type'];

// A subset of IconAssetEnum
export type ElementIcon = 'Body' | 'Container' | 'Button' | 'Text' | 'Image';

export const NewPageUuid = 'newPageUuid' as const;

export interface ICreateElementPayload<TElement extends Element> {
  pageUuid: string;
  elementId: TElement['id'];
  type: TElement['type'];
  name: TElement['name'],
  parent: {
    parentId: TElement['parentId'];
    beforeChildIndex: number;
  },
  styles: TElement['styles'],
  settings: TElement['settings'],
}

export interface IGetElementsPayload {
  pageUuid: string;
}

export interface IUpdateElementPayload<TElement extends Element> {
  pageUuid: string;
  breakpoint: string;
  elementId: TElement['id'];
  type: TElement['type'];
  name?: TElement['name'],
  parent?: {
    parentId: TElement['parentId'];
    beforeChildIndex: number;
  },
  settings?: Partial<TElement['settings']>,
  styles?: Partial<TElement['styles']>,
}

export interface IDeleteElementPayload {
  pageUuid: string;
  elementId: string;
}

export type CreateElementFunc<TElement extends Element> = (
  pageStore: PageStore,
  parentId: TElement['parentId'],
  beforeChildIndex: number,
  customisations?: {
    customName?: string,
    customAddIndexToName?: boolean,
    customSettings?: Partial<TElement['settings']>
  }) => Promise<TElement['id']>;

export interface IElementConfiguration<TElement extends Element> {
  type: TElement['type'];
  getName: () => string;
  addIndexToName: boolean;
  icon: ElementIcon;
  defaultStyles: IStyles;
  getDefaultSettings: () => TElement['settings'],
  customisations?: Record<string, {
    getCustomName?: () => string;
    customAddIndexToName?: boolean;
    customStyles?: IStyles;
    getCustomSettings?: () => Partial<TElement['settings']>;
  }>,
  isResizeable: boolean;
  isDropAccepted: boolean;
  isEditable: boolean;
  allowedDropElementTypes?: ElementType[],
  stylePanelConfigurations: StylePanelConfigurations;
}

export type StylePanelConfigurations = {
  [E in StylePanelType]: boolean;
};

export type ElementConfigurations = {
  [E in Element as E['type']]: IElementConfiguration<E>;
};

export type ElementCreateFunctions = {
  [E in Element as E['type']]: CreateElementFunc<E>;
};

export interface IDraggingElement extends IWithType<ElementType> {
  id?: string;
}

export interface ISelectedElement extends IWithId {
  shouldAutoExpandAndScrollIntoView: boolean;
}

export interface IElementSelectionState {
  isElementSelected: boolean;
  shouldAutoExpandAndScrollIntoView?: ISelectedElement['shouldAutoExpandAndScrollIntoView'];
}

export interface IDragHoveredElement extends IWithId {
  shouldAutoExpandAndScrollIntoView: boolean;
}

export interface IElementDragHoveredState {
  isElementDragHovered: boolean;
  shouldAutoExpandAndScrollIntoView?: IDragHoveredElement['shouldAutoExpandAndScrollIntoView'];
}

export type ElementDragState = 'not-dragging' | 'dragging-over-drop-zone' | 'dragging-over-no-drop-zone' | 'dragging-over-forbidden-drop-zone';
export type ElementDropState = 'not-drag-hovered' | 'drop-allowed' | 'drop-forbidden';

export type CanvasDimensions = {
  width: number,
  height: number,
};

export type NodesAndEdges = {
  nodes: Node[],
  edges: Edge[],
};
