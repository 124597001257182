import cx from 'classnames';
import React from 'react';

import InputField from '../InputField';
import { InputFieldCombinedProps } from '../InputField/types';
import styles from './FieldWithDropdown.module.scss';
import usePresenter from './FieldWithDropdown.presenter';
import getProps from './FieldWithDropdown.props';
import type { FieldWithDropdownCombinedProps } from './types';

const FieldWithDropdown = React.forwardRef<HTMLButtonElement, FieldWithDropdownCombinedProps>((props, ref) => {
  const {
    inputField,
    classes,
    ...otherProps // MANUAL OVERRIDE
  } = usePresenter(props);

  const currentStyle = styles.fieldWithDropdown;
  const internalProps = getProps('');

  const combinedInputProps: InputFieldCombinedProps = {
    ...inputField,
    button: {
      ...inputField?.button,
      ...{ ref, ...otherProps },
    },
  };

  return (
    <InputField
      className={cx(styles.inputField, classes?.inputField)}
      {...internalProps.inputField}
      {...combinedInputProps}
    />
  );
});

export default FieldWithDropdown;
