import { TextProps } from '../Text';

type StatusChipInternalProps = {
  text?: TextProps;
};

type StatusChipPropsValues = {
  'Default': StatusChipInternalProps;
  'Error': StatusChipInternalProps;
  'Success': StatusChipInternalProps;
};

const propValues: StatusChipPropsValues = {
  'Default': {
    text: {
      type: 'LabelSmall',
      align: 'Center',
      style: 'Medium500',
      colour: 'NeutralDefault',
    },
  },
  'Error': {
    text: {
      type: 'LabelSmall',
      align: 'Center',
      style: 'Medium500',
      colour: 'DestructiveSelected',
    },
  },
  'Success': {
    text: {
      type: 'LabelSmall',
      align: 'Center',
      style: 'Medium500',
      colour: 'VariableSelected',
    },
  },
};

const getProps = (type: string): StatusChipInternalProps => {
  const values: StatusChipInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
