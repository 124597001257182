import React from 'react';
import cx from 'classnames';

import Button from '../../atoms/Button';
import DropdownButton from '../../atoms/DropdownButton';
import InputField from '../../atoms/InputField';
import ReplaceButton from '../../atoms/ReplaceButton';
import RowLabel from '../../atoms/RowLabel';
import PropertyItem from '../PropertyItem';

import styles from './ReplaceRow.module.scss';
import usePresenter from './ReplaceRow.presenter';
import getProps from './ReplaceRow.props';
import { ReplaceRowCombinedProps } from './types';

const ReplaceRow: React.FC<ReplaceRowCombinedProps> = (props) => {
  const {
    type,
    state,
    style,
    className,
    classes,
    rowLabel,
    replaceButton,
    button,
    inputField,
    propertyItem,
    dropdownButton,
  } = usePresenter(props);

  const variantName = `${type}${state}${style}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`replaceRow${variantName}`];

  let componentView;

  switch (variantName) {
    case 'ImageDefaultDefault':
    case 'ImageDefaultVariable':
    case 'VariableButtonDefaultVariableVertical':
    case 'VariableImageSelectedVariable':
    case 'VariableImageUploadedVariable': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <RowLabel
            className={cx(styles.rowLabel, classes?.rowLabel)}
            {...internalProps.rowLabel}
            {...rowLabel}/>
          <ReplaceButton
            className={cx(styles.replaceButton, classes?.replaceButton)}
            {...internalProps.replaceButton}
            {...replaceButton}/>
        </div>
      );
      break;
    }
    case 'VariableButtonDefaultVariable':
    case 'VariableButtonEditableVariable':
    case 'VariableButtonSelectedVariable': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <Button
            className={cx(styles.button, classes?.button)}
            {...internalProps.button}
            {...button}/>
          <InputField
            className={cx(styles.inputField, classes?.inputField)}
            {...internalProps.inputField}
            {...inputField}/>
        </div>
      );
      break;
    }
    case 'VariableButtonUploadedVariable': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <Button
            className={cx(styles.button, classes?.button)}
            {...internalProps.button}
            {...button}/>
          <PropertyItem
            className={cx(styles.propertyItem, classes?.propertyItem)}
            {...internalProps.propertyItem}
            {...propertyItem}/>
        </div>
      );
      break;
    }
    case 'VariableDropdownDefaultVariableVertical': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <RowLabel
            className={cx(styles.rowLabel, classes?.rowLabel)}
            {...internalProps.rowLabel}
            {...rowLabel}/>
          <DropdownButton
            className={cx(styles.dropdownButton, classes?.dropdownButton)}
            {...internalProps.dropdownButton}
            {...dropdownButton}/>
        </div>
      );
      break;
    }
    case 'VariableTextDefaultVariable':
    case 'VariableTextDefaultVariableVertical':
    case 'VariableTextEditableVariable':
    case 'VariableTextEditableVariableVertical':
    case 'VariableTextSelectedVariable':
    case 'VariableTextSelectedVariableVertical': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <RowLabel
            className={cx(styles.rowLabel, classes?.rowLabel)}
            {...internalProps.rowLabel}
            {...rowLabel}/>
          <InputField
            className={cx(styles.inputField, classes?.inputField)}
            {...internalProps.inputField}
            {...inputField}/>
        </div>
      );
      break;
    }
    case 'VariableTextUploadedVariable':
    case 'VariableTextUploadedVariableVertical': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <RowLabel
            className={cx(styles.rowLabel, classes?.rowLabel)}
            {...internalProps.rowLabel}
            {...rowLabel}/>
          <PropertyItem
            className={cx(styles.propertyItem, classes?.propertyItem)}
            {...internalProps.propertyItem}
            {...propertyItem}/>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default ReplaceRow;
