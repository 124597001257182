import { ButtonProps } from '../../atoms/Button';
import { TextInputProps } from '../../atoms/TextInput';
import { ResponseStructureBlockProps } from '../../blocks/ResponseStructureBlock';
import { TestVariableBlockProps } from '../../blocks/TestVariableBlock';
import { BreadcrumbItemListProps } from '../../molecules/BreadcrumbItemList';

type TestResponsePageInternalProps = {
  breadcrumbItemList?: BreadcrumbItemListProps;
  textInput?: TextInputProps;
  button?: ButtonProps;
  testVariableBlock?: TestVariableBlockProps;
  responseStructureBlock?: ResponseStructureBlockProps;
  testVariableBlock1?: TestVariableBlockProps;
};

type TestResponsePagePropsValues = {
  '': TestResponsePageInternalProps;
};

const propValues: TestResponsePagePropsValues = {
  '': {
    breadcrumbItemList: {
    },
    textInput: {
      state: 'Default',
      size: 'ExtraLarge',
    },
    button: {
      type: 'Text',
      size: 'M',
      state: 'Default',
      style: 'Primary',
    },
    testVariableBlock: {
      state: 'Filled',
    },
    responseStructureBlock: {
      state: 'Filled',
    },
    testVariableBlock1: {
      state: 'Expanded',
    },
  },
};

const getProps = (type: string): TestResponsePageInternalProps => {
  const values: TestResponsePageInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
