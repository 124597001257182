import { Node, Edge } from 'reactflow';
import { TFunction } from 'i18next';
import { LOGIC_BUILDER } from '../../../lib/constants';
import { nameToSlug } from '../../../lib/utils';
import { Action, Flow } from '../../../modules/actions/types';
import { ApiGroup } from '../../../store/ApiGroupStore/types';
import { resolveApiCallAction, resolveNavigateToAction, findFirstFlowAction } from '../../../store/LogicBuilderStore/utils';
import { getCallApiActionVariables } from '../../../store/PageStore/variableUtils';
import { IPage, NodesAndEdges } from '../../../store/types';
import AddAction from '../../atoms/AddAction';
import FlowStartCard from '../../atoms/FlowStartCard';
import ActionCard, { ActionCardValueProps, ActionCardProps } from '../../molecules/ActionCard';
import { Variable } from '../../../modules/variables/types';
import { getInputPropTextValue } from '../../../store/PageStore/settingsUtils';
import AddCardLine from '../../atoms/AddCardLine';

export const nodeTypes = {
  flowStart: FlowStartCard,
  addActionBetween: AddCardLine,
  actionCard: ActionCard,
  addAction: AddAction,
};

const buildActionCardProps = (
  currAction: Action,
  isSelected: boolean,
  setSelectedActionUuid: (newActionUuid: string) => void,
  t: TFunction,
  apiGroups: ApiGroup[],
  pages: IPage[],
  variables: Variable[],
): ActionCardValueProps & ActionCardProps => {
  const newSelectedAction = currAction;
  const props: ActionCardValueProps & ActionCardProps = {
    style: 'SingleLine',
    state: isSelected ? 'Selected' : 'Default',
    onClick: (e) => {
      e?.preventDefault();
      setSelectedActionUuid(newSelectedAction.uuid);
    },
    text: {
      value: currAction.actionType ? t(`logicSettingsPanel.actionTypes.${currAction.actionType}`) : t('actionCard.action'),
    },
    text1: {
      value: t('actionCard.singleLineText'),
    },
  };

  switch (currAction.actionType) {
    case 'CallApi': {
      const { endpoint } = resolveApiCallAction(currAction, apiGroups);
      const actionCreatedVariables = getCallApiActionVariables(variables, currAction);

      props.text1 = {
        value: endpoint?.name,
      };
      if (actionCreatedVariables.length) {
        props.style = 'DoubleLine';
        props.text2 = {
          value: `Created Variables: ${actionCreatedVariables.length}`,
        };
      }

      // TODO add variables created
      break;
    }
    case 'NavigateTo': {
      const { destination, url, page, launchType } = resolveNavigateToAction(currAction, pages);
      if (destination === 'Page') {
        props.style = launchType === 'new_tab' ? 'NavigateURL' : 'SingleLine';
        props.text1 = {
          value: nameToSlug(page?.slug || page?.name || ''),
        };
      } else {
        props.style = launchType === 'new_tab' ? 'NavigateURL' : 'SingleLine';
        props.text1 = {
          value: getInputPropTextValue(url) || t('actionCard.singleLineText'),
        };
      }
      if (launchType === 'new_tab') {
        props.button = {
          icon: {
            asset: 'ArrowOutwardsRight',
            colour: 'NeturalHoverSelected',
          },
        };
      }
      break;
    }
    case 'UpdateVariable': {
      break;
    }
    default: {
      break;
    }
  }

  return props;
};

const calculateY = (nodeHeight: number) => LOGIC_BUILDER.initialY - nodeHeight / 2;

export const convertToNodesAndEdges = (
  selectedFlow: Flow | undefined,
  selectedAction: Action | undefined,
  setSelectedActionUuid: (newActionUuid: string) => void,
  addAction: (prevActionUuid: string | undefined, nextActionUuid: string | undefined) => Promise<void>,
  t: TFunction,
  apiGroups: ApiGroup[],
  pages: IPage[],
  variables: Variable[],
): NodesAndEdges => {
  const nodes: Node[] = [];
  const inBetweenNodes: Node[] = [];
  const edges: Edge[] = [];

  // add flow start to head
  nodes.push({
    id: 'node-start',
    type: 'flowStart',
    position: {
      x: LOGIC_BUILDER.initialX,
      y: calculateY(LOGIC_BUILDER.flowStartHeight),
    },
    data: {
      text: {
        value: t('logicBuilderPanel.start').toUpperCase(),
      },
    },
  });
  // update next x position
  let nextX = LOGIC_BUILDER.initialX + LOGIC_BUILDER.flowStartWidth + LOGIC_BUILDER.nodeSpace;

  let lastActionUuid: string | undefined;
  if (selectedFlow) {
    let currAction = findFirstFlowAction(selectedFlow);
    while (currAction) {
      const { uuid, prevActionUuid, nextActionUuid } = currAction;

      const betweenNode: Node = {
        id: `before-${uuid}`,
        type: 'addActionBetween',
        position: {
          x: nextX - LOGIC_BUILDER.nodeSpace + (LOGIC_BUILDER.nodeSpace / 2) - (LOGIC_BUILDER.addActionBetweenWidth),
          y: calculateY(LOGIC_BUILDER.addActionBetweenHeight),
        },
        data: {
          button: {
            onClick: async () => {
              await addAction(prevActionUuid, uuid);
            },
          },
        },
      };
      inBetweenNodes.push(betweenNode);

      const node: Node = {
        id: uuid,
        type: 'actionCard',
        position: {
          x: nextX,
          y: calculateY(LOGIC_BUILDER.actionCardHeight),
        },
        data: buildActionCardProps(currAction, selectedAction?.uuid === uuid, setSelectedActionUuid, t, apiGroups, pages, variables),
      };
      nodes.push(node);
      currAction = nextActionUuid ? selectedFlow[nextActionUuid] : undefined;

      // update next x position
      nextX += LOGIC_BUILDER.actionCardWidth + LOGIC_BUILDER.nodeSpace;
      lastActionUuid = uuid;
    }
  }

  // add add action to tail
  nodes.push({
    id: 'node-add',
    type: 'addAction',
    position: {
      x: nextX,
      y: calculateY(LOGIC_BUILDER.addActionHeight),
    },
    data: {
      button: {
        onClick: async () => {
          await addAction(lastActionUuid, undefined);
        },
      },
    },
  });

  if (nodes && nodes.length > 1) {
    for (let i = 1; i < nodes.length; ++i) {
      const source = nodes[i - 1];
      const target = nodes[i];
      edges.push({
        id: `${source.id}-${target.id}`,
        source: source.id,
        target: target.id,
        type: 'straight',
        focusable: false,
      });
    }
  }
  return { nodes: [...nodes, ...inBetweenNodes], edges };
};
