import React from 'react';
import cx from 'classnames';

import Button from '../../atoms/Button';
import DropdownButton from '../../atoms/DropdownButton';
import InputField from '../../atoms/InputField';
import Text from '../../atoms/Text';
import ReplaceRow from '../ReplaceRow';

import styles from './TableRow.module.scss';
import usePresenter from './TableRow.presenter';
import getProps from './TableRow.props';
import { TableRowCombinedProps } from './types';

const TableRow: React.FC<TableRowCombinedProps> = (props) => {
  const {
    type,
    style,
    state,
    className,
    classes,
    dropdownButton,
    nameInput,
    response,
    valueType,
    dropdownButton1,
    button,
    variableNameInput,
    defaultValue,
    variableName,
    variableType,
    valueInput,
    keyInput,
    inputField,
    variableValueInput,
  } = usePresenter(props);

  const variantName = `${type}${style}${state}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`tableRow${variantName}`];

  let componentView;
  switch (variantName) {
    case 'BooleanEditVariablesDefault':
    case 'BooleanEditVariablesselected': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <div className={cx(styles.scopeName, classes?.scopeName)}>
            <DropdownButton
              className={cx(styles.dropdownButton, classes?.dropdownButton)}
              {...internalProps.dropdownButton}
              {...dropdownButton}
            />
            <InputField
              className={cx(styles.nameInput, classes?.nameInput)}
              {...internalProps.nameInput}
              {...nameInput}
            />
          </div>
          <Text
            className={cx(styles.response, classes?.response)}
            {...internalProps.response}
            {...response}
          />
          <Text
            className={cx(styles.valueType, classes?.valueType)}
            {...internalProps.valueType}
            {...valueType}
          />
          <DropdownButton
            className={cx(styles.dropdownButton1, classes?.dropdownButton1)}
            {...internalProps.dropdownButton1}
            {...dropdownButton1}
          />
          <Button
            className={cx(styles.button, classes?.button)}
            {...internalProps.button}
            {...button}
          />
        </div>
      );
      break;
    }
    case 'BooleanInputVariableDefault': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <InputField
            className={cx(styles.variableNameInput, classes?.variableNameInput)}
            {...internalProps.variableNameInput}
            {...variableNameInput}
          />
          <DropdownButton
            className={cx(styles.dropdownButton, classes?.dropdownButton)}
            {...internalProps.dropdownButton}
            {...dropdownButton}
          />
          <DropdownButton
            className={cx(styles.defaultValue, classes?.defaultValue)}
            {...internalProps.defaultValue}
            {...defaultValue}
          />
          <Button
            className={cx(styles.button, classes?.button)}
            {...internalProps.button}
            {...button}
          />
        </div>
      );
      break;
    }
    case 'BooleanTestResponseDefault': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <Text
            className={cx(styles.variableName, classes?.variableName)}
            {...internalProps.variableName}
            {...variableName}
          />
          <Text
            className={cx(styles.variableType, classes?.variableType)}
            {...internalProps.variableType}
            {...variableType}
          />
          <DropdownButton
            className={cx(styles.dropdownButton, classes?.dropdownButton)}
            {...internalProps.dropdownButton}
            {...dropdownButton}
          />
        </div>
      );
      break;
    }
    case 'CollectionObjectEditVariablesDefault':
    case 'DefaultEditVariablesDefault':
    case 'DefaultEditVariablesselected': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <div className={cx(styles.scopeName, classes?.scopeName)}>
            <DropdownButton
              className={cx(styles.dropdownButton, classes?.dropdownButton)}
              {...internalProps.dropdownButton}
              {...dropdownButton}
            />
            <InputField
              className={cx(styles.nameInput, classes?.nameInput)}
              {...internalProps.nameInput}
              {...nameInput}
            />
          </div>
          <Text
            className={cx(styles.response, classes?.response)}
            {...internalProps.response}
            {...response}
          />
          <Text
            className={cx(styles.valueType, classes?.valueType)}
            {...internalProps.valueType}
            {...valueType}
          />
          <InputField
            className={cx(styles.valueInput, classes?.valueInput)}
            {...internalProps.valueInput}
            {...valueInput}
          />
          <Button
            className={cx(styles.button, classes?.button)}
            {...internalProps.button}
            {...button}
          />
        </div>
      );
      break;
    }
    case 'DefaultInputVariableDefault': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <InputField
            className={cx(styles.variableNameInput, classes?.variableNameInput)}
            {...internalProps.variableNameInput}
            {...variableNameInput}
          />
          <DropdownButton
            className={cx(styles.dropdownButton, classes?.dropdownButton)}
            {...internalProps.dropdownButton}
            {...dropdownButton}
          />
          <Button
            className={cx(styles.button, classes?.button)}
            {...internalProps.button}
            {...button}
          />
        </div>
      );
      break;
    }
    case 'DefaultKeyValueDefault': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <InputField
            className={cx(styles.keyInput, classes?.keyInput)}
            {...internalProps.keyInput}
            {...keyInput}
          />
          <InputField
            className={cx(styles.valueInput, classes?.valueInput)}
            {...internalProps.valueInput}
            {...valueInput}
          />
          <Button
            className={cx(styles.button, classes?.button)}
            {...internalProps.button}
            {...button}
          />
        </div>
      );
      break;
    }
    case 'DefaultTestResponseDefault': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <Text
            className={cx(styles.variableName, classes?.variableName)}
            {...internalProps.variableName}
            {...variableName}
          />
          <Text
            className={cx(styles.variableType, classes?.variableType)}
            {...internalProps.variableType}
            {...variableType}
          />
          <InputField
            className={cx(styles.inputField, classes?.inputField)}
            {...internalProps.inputField}
            {...inputField}
          />
        </div>
      );
      break;
    }
    case 'VariableKeyValueDefault': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <InputField
            className={cx(styles.keyInput, classes?.keyInput)}
            {...internalProps.keyInput}
            {...keyInput}
          />
          <ReplaceRow
            className={cx(styles.variableValueInput, classes?.variableValueInput)}
            {...internalProps.variableValueInput}
            {...variableValueInput}
          />
          <Button
            className={cx(styles.button, classes?.button)}
            {...internalProps.button}
            {...button}
          />
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default TableRow;
