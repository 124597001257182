import cx from 'classnames';
import React from 'react';
import { Constants } from '../../../../constants';
import styles from './PreviewElementRenderer.module.scss';
import usePresenter from './PreviewElementRenderer.presenter';
import type { PreviewElementRendererProps } from './types';

const PreviewElementRenderer: React.FC<PreviewElementRendererProps> = (props) => {
  const {
    element,
    onClick,
    ElementView,
    className,
    utils,
  } = usePresenter(props);

  return element && ElementView
    ? (
      <ElementView
        element={element}
        onClick={onClick}
        utils={utils}
        className={cx(styles.previewElementRenderer, className)}
        {...{ [Constants.ATTRIBUTES.ELEMENT_ID]: element.id }}
        {...{ [Constants.ATTRIBUTES.ELEMENT_TYPE]: element.type }}
      />
    )
    : null;
};

export default PreviewElementRenderer;
