type UnderlineInternalProps = {
};

type UnderlinePropsValues = {
  '': UnderlineInternalProps;
};

const propValues: UnderlinePropsValues = {
  '': {
  },
};

const getProps = (type: string): UnderlineInternalProps => {
  const values: UnderlineInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
