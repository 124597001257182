import { IconProps } from '../../atoms/Icon';
import { TextProps } from '../../atoms/Text';

type ElementItemInternalProps = {
  icon?: IconProps;
  text?: TextProps;
};

type ElementItemPropsValues = {
  '': ElementItemInternalProps;
};

const propValues: ElementItemPropsValues = {
  '': {
    icon: {
      asset: 'Container',
      colour: 'NeutralDefault',
    },
    text: {
      type: 'LabelSmall',
      align: 'Center',
      style: 'Medium500',
      colour: 'NeutralDefault',
    },
  },
};

const getProps = (type: string): ElementItemInternalProps => {
  const values: ElementItemInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
