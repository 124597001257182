import { Constants } from '../../constants';
import axiosInstance from '../../lib/api/axios';
import { STUDIO_API_URL } from '../../lib/config';
import type {
  BreakpointElementsMap,
  Element,
  ICreateElementPayload,
  ICreatePagePayload,
  IDeleteElementPayload,
  IDeletePagePayload,
  IGetElementsPayload,
  IPage,
  IUpdateElementPayload,
  IUpdatePagePayload,
  PageElementsMap,
} from '../../store/types';

const applicationId = Number(localStorage.getItem(Constants.HEADERS.APPLICATION_ID)) || 1;
const headers = { headers: { [Constants.HEADERS.APPLICATION_ID]: applicationId } };

export const createPageApi = async (createPagePayload: ICreatePagePayload): Promise<IPage> => {
  const { data: createdPage } = await axiosInstance.post<IPage>(`${STUDIO_API_URL}/pages`, createPagePayload, headers);
  return createdPage;
};

export const getPagesApi = async (): Promise<IPage[]> => {
  const { data: pages } = await axiosInstance.get<IPage[]>(`${STUDIO_API_URL}/pages`, headers);
  return pages;
};

export const updatePageApi = async (updatePagePayload: IUpdatePagePayload): Promise<IPage> => {
  const { page, ...body } = updatePagePayload;
  const { data: updatedPage } = await axiosInstance.patch<IPage>(`${STUDIO_API_URL}/pages/${page.uuid}`, body, headers);
  return updatedPage;
};

export const deletePageApi = async (deletePagePayload: IDeletePagePayload): Promise<void> => {
  const { page } = deletePagePayload;
  await axiosInstance.delete(`${STUDIO_API_URL}/pages/${page.uuid}`, headers);
};

export const createElementApi = async <TElement extends Element>(createElementPayload: ICreateElementPayload<TElement>): Promise<void> => {
  const { pageUuid, elementId, ...body } = createElementPayload;
  await axiosInstance.post(`${STUDIO_API_URL}/pages/${pageUuid}/elements`, { id: elementId, ...body }, headers);
};

type PageElements = {
  elements: PageElementsMap;
  breakpointElements?: BreakpointElementsMap;
};

export const getElementsApi = async (getElementsPayload: IGetElementsPayload): Promise<PageElements> => {
  const { pageUuid } = getElementsPayload;
  const { data } = await axiosInstance.get<PageElements>(`${STUDIO_API_URL}/pages/${pageUuid}/elements`, headers);
  return data;
};

export const updateElementApi = async <TElement extends Element>(updateElementPayload: IUpdateElementPayload<TElement>): Promise<void> => {
  const { pageUuid, elementId, ...body } = updateElementPayload;
  await axiosInstance.patch(`${STUDIO_API_URL}/pages/${pageUuid}/elements/${elementId}`, body, headers);
};

export const deleteElementApi = async (deleteElementPayload: IDeleteElementPayload): Promise<void> => {
  const { pageUuid, elementId } = deleteElementPayload;
  await axiosInstance.delete(`${STUDIO_API_URL}/pages/${pageUuid}/elements/${elementId}`, headers);
};
