import { DividerProps } from '../../atoms/Divider';
import { DropdownButtonProps } from '../../atoms/DropdownButton';
import { TextProps } from '../../atoms/Text';
import { InputFieldProps } from '../../atoms/InputField';
import { ReplaceButtonProps } from '../../atoms/ReplaceButton';
import { MenuHeaderProps } from '../../molecules/MenuHeader';
import { ToggleItemListProps } from '../../molecules/ToggleItemList';
import { FieldWithDropdownProps } from '../../atoms/FieldWithDropdown';

type AddImageMenuInternalProps = {
  menuHeader?: MenuHeaderProps;
  divider?: DividerProps;
  imageLabel?: TextProps;
  replaceButton?: ReplaceButtonProps;
  sizeLabel?: TextProps;
  toggleItemList?: ToggleItemListProps;
  positionLabel?: TextProps;
  dropdownButton?: DropdownButtonProps;
  inputField?: FieldWithDropdownProps;
  dropdownButton1?: DropdownButtonProps;
  inputField1?: FieldWithDropdownProps;
};

type AddImageMenuPropsValues = {
  'Default': AddImageMenuInternalProps;
};

const propValues: AddImageMenuPropsValues = {
  Default: {
    menuHeader: {
      type: 'Default',
    },
    divider: {
      type: 'Horizontal',
      colour: 'Neutral1900',
    },
    imageLabel: {
      type: 'LabelMedium',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralDefault',
    },
    replaceButton: {
      state: 'Default',
      type: 'Default',
    },
    sizeLabel: {
      type: 'LabelMedium',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralDefault',
    },
    toggleItemList: {
      size: 'Small',
      type: 'Text',
      style: 'Default',
    },
    positionLabel: {
      type: 'LabelMedium',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralDefault',
    },
    dropdownButton: {
      type: 'Default',
      style: 'Default',
      state: 'Collapsed',
    },
    inputField: {
    },
    dropdownButton1: {
      type: 'Default',
      style: 'Default',
      state: 'Collapsed',
    },
    inputField1: {
    },
  },
};

const getProps = (type: string): AddImageMenuInternalProps => {
  const values: AddImageMenuInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
