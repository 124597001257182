import Default from './Default.svg';
import HPCR from './HPCR.svg';
import HSAC from './HSAC.svg';
import HSBC from './HSBC.svg';
import VPCR from './VPCR.svg';
import VSAR from './VSAR.svg';
import VSBR from './VSBR.svg';

export const CenterRight = {
  Default,
  'horizontal-packed-center-right': HPCR,
  'horizontal-space-around-center-none': HSAC,
  'horizontal-space-between-center-none': HSBC,
  'vertical-packed-center-right': VPCR,
  'vertical-space-around-none-right': VSAR,
  'vertical-space-between-none-right': VSBR,
};
