import React from 'react';
import cx from 'classnames';

import DropdownButton from '../../atoms/DropdownButton';
import RowLabel from '../../atoms/RowLabel';
import Icon from '../../atoms/Icon';
import FieldWithDropdown from '../../atoms/FieldWithDropdown';
import ColourRow from '../../molecules/ColourRow';
import ToggleItemList from '../../molecules/ToggleItemList';
import PanelHeader from '../../molecules/PanelHeader';

import styles from './TypographyPanel.module.scss';
import usePresenter from './TypographyPanel.presenter';
import getProps from './TypographyPanel.props';
import { TypographyPanelCombinedProps } from './types';

const TypographyPanel: React.FC<TypographyPanelCombinedProps> = (props) => {
  const {
    state,
    className,
    classes,
    panelHeader,
    font,
    dropdownButton,
    weight,
    weightDropdown,
    size,
    sizeField,
    spacing,
    letterSpace,
    letterSpaceField,
    lineHeight,
    lineHeightField,
    colourRow,
    style,
    lineToggle,
    style1,
    italicsToggle,
    lineToggle1,
    renderPanel, // MANUAL OVERRIDE
  } = usePresenter(props);

  // MANUAL OVERRIDE STARTS
  if (!renderPanel) {
    return null;
  }
  // MANUAL OVERRIDE ENDS

  const variantName = `${state}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`typographyPanel${variantName}`];

  let componentView;

  switch (variantName) {
    case 'Collapsed': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <PanelHeader
            className={cx(styles.panelHeader, classes?.panelHeader)}
            {...internalProps.panelHeader}
            {...panelHeader}
          />
        </div>
      );
      break;
    }
    case 'Expanded': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <PanelHeader
            className={cx(styles.panelHeader, classes?.panelHeader)}
            {...internalProps.panelHeader}
            {...panelHeader}
          />
          <div className={cx(styles.typographyContent, classes?.typographyContent)}>
            <div className={cx(styles.fontContent, classes?.fontContent)}>
              <RowLabel
                className={cx(styles.font, classes?.font)}
                {...internalProps.font}
                {...font}
              />
              <DropdownButton
                className={cx(styles.dropdownButton, classes?.dropdownButton)}
                {...internalProps.dropdownButton}
                {...dropdownButton}
              />
            </div>
            <div className={cx(styles.weightSizeContent, classes?.weightSizeContent)}>
              <RowLabel
                className={cx(styles.weight, classes?.weight)}
                {...internalProps.weight}
                {...weight}
              />
              <DropdownButton
                className={cx(styles.weightDropdown, classes?.weightDropdown)}
                {...internalProps.weightDropdown}
                {...weightDropdown}
              />
              <RowLabel
                className={cx(styles.size, classes?.size)}
                {...internalProps.size}
                {...size}
              />
              <FieldWithDropdown
                className={cx(styles.sizeField, classes?.sizeField)}
                {...internalProps.sizeField}
                {...sizeField}
              />
            </div>
            <div className={cx(styles.spacingContent, classes?.spacingContent)}>
              <RowLabel
                className={cx(styles.spacing, classes?.spacing)}
                {...internalProps.spacing}
                {...spacing}
              />
              <Icon
                className={cx(styles.letterSpace, classes?.letterSpace)}
                {...internalProps.letterSpace}
                {...letterSpace}
              />
              <FieldWithDropdown
                className={cx(styles.letterSpaceField, classes?.letterSpaceField)}
                {...internalProps.letterSpaceField}
                {...letterSpaceField}
              />
              <Icon
                className={cx(styles.lineHeight, classes?.lineHeight)}
                {...internalProps.lineHeight}
                {...lineHeight}
              />
              <FieldWithDropdown
                className={cx(styles.lineHeightField, classes?.lineHeightField)}
                {...internalProps.lineHeightField}
                {...lineHeightField}
              />
            </div>
            <ColourRow
              className={cx(styles.colourRow, classes?.colourRow)}
              {...internalProps.colourRow}
              {...colourRow}
            />
            <div className={cx(styles.alignContent, classes?.alignContent)}>
              <RowLabel
                className={cx(styles.style, classes?.style)}
                {...internalProps.style}
                {...style}
              />
              <ToggleItemList
                className={cx(styles.lineToggle, classes?.lineToggle)}
                {...internalProps.lineToggle}
                {...lineToggle}
              />
            </div>
            <div className={cx(styles.styleContent, classes?.styleContent)}>
              <RowLabel
                className={cx(styles.style1, classes?.style1)}
                {...internalProps.style1}
                {...style1}
              />
              <ToggleItemList
                className={cx(styles.italicsToggle, classes?.italicsToggle)}
                {...internalProps.italicsToggle}
                {...italicsToggle}
              />
              <ToggleItemList
                className={cx(styles.lineToggle1, classes?.lineToggle1)}
                {...internalProps.lineToggle1}
                {...lineToggle1}
              />
            </div>
          </div>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default TypographyPanel;
