import React from 'react';
import cx from 'classnames';

import Text from '../../atoms/Text';
import MethodChip from '../../atoms/MethodChip';

import styles from './EndpointCard.module.scss';
import usePresenter from './EndpointCard.presenter';
import getProps from './EndpointCard.props';
import { EndpointCardCombinedProps } from './types';

const EndpointCard: React.FC<EndpointCardCombinedProps> = (props) => {
  const {
    className,
    classes,
    methodChip,
    label,
    path,
    onClick,
  } = usePresenter(props);

  const variantName = '';
  const internalProps = getProps(variantName);
  const currentStyle = styles[`endpointCard${variantName}`];

  let componentView;

  switch (variantName) {
    case '': {
      componentView = (
        <div onMouseDown={onClick} role="button" tabIndex={0} className={cx(currentStyle, className)}>
          <MethodChip
            className={cx(styles.methodChip, classes?.methodChip)}
            {...internalProps.methodChip}
            {...methodChip}
          />
          <div className={cx(styles.endpointContent, classes?.endpointContent)}>
            <Text
              className={cx(styles.label, classes?.label)}
              {...internalProps.label}
              {...label}
            />
            <Text
              className={cx(styles.path, classes?.path)}
              {...internalProps.path}
              {...path}
            />
          </div>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default EndpointCard;
