import React from 'react';
import cx from 'classnames';

import Chip from '../../atoms/Chip';
import RowLabel from '../../atoms/RowLabel';

import styles from './SelectedContainer.module.scss';
import usePresenter from './SelectedContainer.presenter';
import getProps from './SelectedContainer.props';
import { SelectedContainerCombinedProps } from './types';

const SelectedContainer: React.FC<SelectedContainerCombinedProps> = (props) => {
  const {
    className,
    classes,
    rowLabel,
    chip,
  } = usePresenter(props);

  // MANUAL OVERRIDE STARTS
  if (!chip?.text?.value) {
    return null;
  }
  // MANUAL OVERRIDE ENDS

  const internalProps = getProps('');
  const currentStyle = styles.selectedContainer;

  return (
    <div className={cx(currentStyle, className)}>
      <div className={cx(styles.content, classes?.content)}>
        <RowLabel
          className={cx(styles.rowLabel, classes?.rowLabel)}
          {...internalProps.rowLabel}
          {...rowLabel}
        />
        <Chip
          className={cx(styles.chip, classes?.chip)}
          {...internalProps.chip}
          {...chip}
        />
      </div>
    </div>
  );
};

export default SelectedContainer;
