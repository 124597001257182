import { useTranslation } from 'react-i18next';
import { getEnumValuesBasedOnProperty, getUnitsBasedOnProperty } from '../../../store/PageStore/utils';
import { BackgroundSizeType, backgroundSizeValues, Unit } from '../../../store/types';
import { unitValueValidation, valueWithFallback } from '../../../utils/inputValidations';
import { typedDeepMerge } from '../../../utils/objectUtils';
import { capitalize } from '../../../utils/stringUtils';
import { buildDropdownButtonProps } from '../../atoms/DropdownButton/utils';
import { buildFieldWithDropdownProps } from '../../atoms/FieldWithDropdown/utils';
import type { AddImageMenuCombinedProps } from './types';

const usePresenter = (props: AddImageMenuCombinedProps): AddImageMenuCombinedProps => {
  const {
    closeModal,
    backgroundImage,
    onBackgroundImageUpdated,
    backgroundSize,
    onBackgroundSizeUpdated,
    backgroundVerticalAnchor,
    onBackgroundVerticalAnchorPointUpdated,
    onBackgroundVerticalAnchorValueUpdated,
    onBackgroundVerticalAnchorUnitUpdated,
    backgroundHorizontalAnchor,
    onBackgroundHorizontalAnchorPointUpdated,
    onBackgroundHorizontalAnchorValueUpdated,
    onBackgroundHorizontalAnchorUnitUpdated,
  } = props;

  const { t } = useTranslation();

  return {
    ...typedDeepMerge(props, {
      state: 'Default',
      menuHeader: {
        text: {
          value: t('addImageMenu.addImage'),
        },
        button: {
          icon: {
            asset: 'Close',
          },
          onClick: closeModal,
        },
      },
      imageLabel: {
        value: t('addImageMenu.image'),
      },
      replaceButton: {
        actionType: 'SelectImage',
        state: backgroundImage ? 'Uploaded' : 'Default',
        button: {
          text: {
            value: t('addImageMenu.uploadImage'),
          },
          icon: {
            asset: 'Add',
          },
        },
        propertyItem: {
          text: {
            value: backgroundImage ? backgroundImage.fileName : undefined,
          },
        },
        onFileSelected: (file) => onBackgroundImageUpdated?.(file),
        onFileRemoved: () => onBackgroundImageUpdated?.(null),
      },
      sizeLabel: {
        value: t('addImageMenu.size'),
      },
      toggleItemList: {
        currentValue: backgroundSize || 'cover',
        toggleOptions: backgroundSizeValues.map((backgroundSizeValue) => ({ value: backgroundSizeValue })),
        onOptionSelected: (option) => {
          if (onBackgroundSizeUpdated) {
            onBackgroundSizeUpdated(option as BackgroundSizeType);
          }
        },
      },
      positionLabel: {
        value: t('addImageMenu.position'),
      },
      dropdownButton: buildDropdownButtonProps(
        backgroundVerticalAnchor?.anchorPoint,
        'top',
        getEnumValuesBasedOnProperty('backgroundVerticalAnchor'),
        (option) => capitalize(option),
        (option) => onBackgroundVerticalAnchorPointUpdated?.(option.toLowerCase()),
      ),
      inputField: buildFieldWithDropdownProps(
        valueWithFallback(backgroundVerticalAnchor?.offset.value, 0),
        'number',
        backgroundVerticalAnchor?.offset.unit || 'px',
        getUnitsBasedOnProperty('backgroundVerticalAnchor'),
        (value) => onBackgroundVerticalAnchorValueUpdated?.(value),
        (option) => onBackgroundVerticalAnchorUnitUpdated?.(option),
        (value) => value,
        (newValue, dropdownValue) => unitValueValidation(newValue, dropdownValue as Unit, true),
      ),

      dropdownButton1: buildDropdownButtonProps(
        backgroundHorizontalAnchor?.anchorPoint,
        'left',
        getEnumValuesBasedOnProperty('backgroundHorizontalAnchor'),
        (option) => capitalize(option),
        (option) => onBackgroundHorizontalAnchorPointUpdated?.(option.toLowerCase()),
      ),
      inputField1: buildFieldWithDropdownProps(
        valueWithFallback(backgroundHorizontalAnchor?.offset.value, 0),
        'number',
        backgroundHorizontalAnchor?.offset.unit || 'px',
        getUnitsBasedOnProperty('backgroundHorizontalAnchor'),
        (value) => onBackgroundHorizontalAnchorValueUpdated?.(value),
        (option) => onBackgroundHorizontalAnchorUnitUpdated?.(option.toLowerCase()),
        (value) => value,
        (newValue, dropdownValue) => unitValueValidation(newValue, dropdownValue as Unit, true),
      ),
    }),
  };
};

export default usePresenter;
