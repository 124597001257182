import cx from 'classnames';
import customStyles from './Custom.module.scss';
import { LineNumberListCombinedProps } from './types';

const usePresenter = (props: LineNumberListCombinedProps): LineNumberListCombinedProps => {
  return {
    ...props,
    className: cx(
      props.className,
      customStyles.lineNumberList,
    ),
    classes: {
      ...props.classes,
      lineNumber: cx(props.classes?.lineNumber, customStyles.lineNumber),
    },
  };
};

export default usePresenter;
