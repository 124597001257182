import React from 'react';
import cx from 'classnames';

import styles from './TextInput.module.scss';
import usePresenter from './TextInput.presenter';
import getProps from './TextInput.props';
import type { TextInputCombinedProps } from './types';

const TextInput: React.FC<TextInputCombinedProps> = (props) => {
  const {
    textInputRef, // MANUAL OVERRIDE
    textInputType = 'text', // MANUAL OVERRIDE
    state,
    size,
    className,
    classes,
    textValue,
    textPlaceholder,
    onTextChanged,
    onKeyDown, // MANUAL OVERRIDE
    onBlur, // MANUAL OVERRIDE
    isValidTextInput, // MANUAL OVERRIDE
    disabled, // MANUAL OVERRIDE
  } = usePresenter(props);

  const variantName = `${state}${size || 'Medium'}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`textInput${variantName}`];

  // MANUAL OVERRIDE STARTS
  // const [inputValue, setInputValue] = React.useState<string>('');

  // React.useEffect(() => {
  //   setInputValue(textValue || '');
  // }, [textValue]);
  // MANUAL OVERRIDE ENDS

  const onInputChanged = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    // setInputValue(event.target.value); // MANUAL OVERRIDE

    // MANUAL OVERRIDE STARTS
    const isValidEntry = isValidTextInput ? isValidTextInput(event.target.value) : true;

    if (onTextChanged && isValidEntry) {
      onTextChanged(event.target.value);
    }
    // MANUAL OVERRIDE ENDS
  };
  let componentView;

  switch (variantName) {
    case 'DefaultMedium':
    case 'DefaultExtraLarge':
    case 'SelectedExtraLarge':
    case 'SelectedMedium': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <input
            ref={textInputRef} // MANUAL OVERRIDE
            type={textInputType} // MANUAL OVERRIDE
            className={cx(styles.input, classes?.input)}
            placeholder={textPlaceholder}
            value={textValue} // MANUAL OVERRIDE
            onChange={onInputChanged}
            onKeyDown={onKeyDown} // MANUAL OVERRIDE
            onBlur={onBlur} // MANUAL OVERRIDE
            disabled={disabled} // MANUAL OVERRIDE
            // MANUAL OVERRIDE STARTS
            // For some reason when input has text selected and you click within the selected text for the first time
            // It doesn't set the cursor at the position of the click.
            // As a hack, we deselect text in onMouseDown event (which fires before onClick event).
            // TODO: Investigate the root cause and remove the following hack.
            // To reproduce, edit page name and then click once within the selected text.
            onMouseDown={(event) => {
              try {
                // Deselect any selected text
                const target = event.target as HTMLInputElement;
                if (target.selectionStart !== target.selectionEnd) {
                  target.setSelectionRange(null, null);
                }
              } catch {
                // Chrome throws if input type is not text, search, URL, tel or password
                // See https://developer.mozilla.org/en-US/docs/Web/API/HTMLInputElement/setSelectionRange
              }
            }}
            onClick={(event) => {
              event.stopPropagation();
            }}
          // MANUAL OVERRIDE ENDS
          />
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default TextInput;
