export const capitalize = (value: string): string => {
  return value.charAt(0).toUpperCase() + value.substr(1);
};

export const convertToTitleCase = (value: string): string => {
  if (!value) {
    return '';
  }
  return value
    .replace(/([^A-Z])([A-Z])/g, '$1 $2')
    .replace(/[_\-]+/g, ' ') // eslint-disable-line no-useless-escape
    .toLowerCase()
    .replace(/(^\w|\b\w)/g, (m) => { return m.toUpperCase(); })
    .replace(/\s+/g, ' ')
    .replace(/^\s+|\s+$/, '');
};

export const convertToKebabCase = (value: string): string => {
  if (!value) {
    return '';
  }
  return value
    .replace(/([a-z])([A-Z])/g, '$1-$2')
    .replace(/[\s_]+/g, '-')
    .toLowerCase();
};
