import { To } from 'react-router';
import { ApiGroup, ApiResponsePropMetadata } from '../../../store/ApiGroupStore/types';
import { findFirstFlowAction, resolveApiCallAction, resolveNavigateToAction } from '../../../store/LogicBuilderStore/utils';
import {
  buildTargetPageUrl,
  getCallApiActionVariables,
  getPageNavigationVariables,
  mapPageVariablesToReferences,
  mapVariableInputsToReferences,
  mapVariablesToPathQuery,
  resolveVariable,
  resolveVariables,
} from '../../../store/PageStore/variableUtils';
import { InputProp, IPage } from '../../../store/types';
import {
  Action, CallApiAction, Flow, NavigateToAction, NavigationLaunchType,
} from '../../actions/types';
import { getApiEndpointResponse } from '../../apiGroups/apiGroups.repository';
import { Variable, VariableScope } from '../../variables/types';
import { nameToSlug } from '../../../lib/utils';
import { VariableInput } from '../../../components/organisms/InputVariableTable/types';
import { parseRawResponse } from '../../../components/pages/TestResponsePage/utils';

const executeApiCallAction = async (
  action: CallApiAction,
  apiGroups: ApiGroup[],
  variables: Variable[],
  globalValues: Record<string, string>,
  pageValues: Record<string, string>,
  pageNavigationValues: Record<string, string>,
  actionOutputs: Record<string, ApiResponsePropMetadata>,
  updateVariable: (name: string, value: string, scope: VariableScope) => void,
): Promise<Record<string, ApiResponsePropMetadata>> => {
  const { apiGroup, endpoint } = resolveApiCallAction(action, apiGroups);
  const actionCreatedVariables = getCallApiActionVariables(variables, action);
  if (apiGroup && endpoint) {
    const resolvedInputVariables = resolveVariables(
      mapVariableInputsToReferences(endpoint.inputVariables),
      action.metadata?.variableInputs,
      variables,
      globalValues,
      pageValues,
      pageNavigationValues,
      actionOutputs,
    ).map((item): VariableInput => {
      return {
        name: item.name,
        value: `${item.value as string}`,
      };
    });
    try {
      const response = await getApiEndpointResponse(apiGroup, endpoint, resolvedInputVariables);
      const apiResponseMap: Record<string, ApiResponsePropMetadata> = parseRawResponse(
        response.rawResponse,
      ).reduce((prev, item) => {
        return {
          ...prev,
          [item.responsePath]: item,
        };
      }, {});

      actionCreatedVariables.forEach((variable) => {
        const { name, scope, metadata } = variable;
        const apiValue = apiResponseMap[metadata.responsePath];
        updateVariable(name, `${apiValue?.value as string}`, scope);
      });

      return apiResponseMap;
    } catch {
      // noop
    }
  }
  return {};
};

const navigateToUrl = (
  url: InputProp,
  launchType: NavigationLaunchType,
  variables: Variable[],
  globalValues: Record<string, string>,
  pageValues: Record<string, string>,
  pageNavigationValues: Record<string, string>,
  actionOutputs: Record<string, ApiResponsePropMetadata>,
) => {
  const resolvedUrl = resolveVariable(
    url,
    variables,
    globalValues,
    pageValues,
    pageNavigationValues,
    actionOutputs,
  );
  if (resolvedUrl) {
    if (launchType === 'same_tab') {
      window.location.href = resolvedUrl.value as string;
    } else {
      window.open(resolvedUrl.value as string, '_blank');
    }
  }
};

const navigateToPage = (
  action: NavigateToAction,
  targetPage: IPage,
  launchType: NavigationLaunchType,
  variables: Variable[],
  globalValues: Record<string, string>,
  pageValues: Record<string, string>,
  pageNavigationValues: Record<string, string>,
  actionOutputs: Record<string, ApiResponsePropMetadata>,
  navigate: (to: To) => void,
) => {
  const { url, params } = buildTargetPageUrl(
    targetPage,
    variables,
    action.metadata?.variableInputs,
    globalValues,
    pageValues,
    pageNavigationValues,
    actionOutputs,
  );

  if (launchType === 'same_tab') {
    navigate({
      pathname: url,
      search: params,
    });
  } else {
    window.open(`${url}${params}`, '_blank');
  }
};

const executeNavigateAction = (
  action: NavigateToAction,
  pages: IPage[],
  variables: Variable[],
  globalValues: Record<string, string>,
  pageValues: Record<string, string>,
  pageNavigationValues: Record<string, string>,
  actionOutputs: Record<string, ApiResponsePropMetadata>,
  navigate: (to: To) => void,
) => {
  const { destination, url, page, launchType } = resolveNavigateToAction(action, pages);

  if (destination === 'URL' && url) {
    navigateToUrl(url, launchType, variables, globalValues, pageValues, pageNavigationValues, actionOutputs);
  } else if (destination === 'Page' && page) {
    navigateToPage(action, page, launchType, variables, globalValues, pageValues, pageNavigationValues, actionOutputs, navigate);
  }
};

const executeAction = async (
  action: Action,
  pages: IPage[],
  apiGroups: ApiGroup[],
  variables: Variable[],
  globalValues: Record<string, string>,
  pageValues: Record<string, string>,
  pageNavigationValues: Record<string, string>,
  actionOutputs: Record<string, ApiResponsePropMetadata>,
  navigate: (to: To) => void,
  updateVariable: (name: string, value: string, scope: VariableScope) => void,
): Promise<Record<string, ApiResponsePropMetadata>> => {
  switch (action.actionType) {
    case 'CallApi':
      return executeApiCallAction(
        action,
        apiGroups,
        variables,
        globalValues,
        pageValues,
        pageNavigationValues,
        actionOutputs,
        updateVariable,
      );
    case 'NavigateTo':
      executeNavigateAction(
        action,
        pages,
        variables,
        globalValues,
        pageValues,
        pageNavigationValues,
        actionOutputs,
        navigate,
      );
      break;
    default:
      break;
  }
  return {};
};

const executeFlow = async (
  flow: Flow,
  pages: IPage[],
  apiGroups: ApiGroup[],
  variables: Variable[],
  globalValues: Record<string, string>,
  pageValues: Record<string, string>,
  navigationVariables: Record<string, string>,
  navigate: (to: To) => void,
  updateVariable: (name: string, value: string, scope: VariableScope) => void,
) => {
  let nextAction = findFirstFlowAction(flow);
  let actionOutputs: Record<string, ApiResponsePropMetadata> = {};
  while (nextAction) {
    const { nextActionUuid } = nextAction;
    // eslint-disable-next-line no-await-in-loop
    const outputs = await executeAction(
      nextAction,
      pages,
      apiGroups,
      variables,
      globalValues,
      pageValues,
      navigationVariables,
      actionOutputs,
      navigate,
      updateVariable,
    );
    actionOutputs = {
      ...actionOutputs,
      ...outputs,
    };
    nextAction = nextActionUuid ? flow[nextActionUuid] : undefined;
  }
};

export default executeFlow;
