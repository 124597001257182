import { Constants } from '../../constants';
import type { IApplication } from '../../store/types';
import { delay } from '../../utils/promiseUtils';

export const getApplicationApi = async (): Promise<IApplication> => {
  // TODO: Replace with real API request
  await delay(200);
  return {
    id: Number(localStorage.getItem(Constants.HEADERS.APPLICATION_ID)) || 1,
    name: 'Coolest App Ever',
  };
};
