import type { IconProps } from '../../atoms/Icon';
import type { LeftPanelProps } from '../LeftPanel';
import type { CanvasProps } from '../../organisms/Canvas';
import type { PageSettingsPanelProps } from '../../organisms/PageSettingsPanel';

type MainBlockInternalProps = {
  canvas?: CanvasProps;
  leftPanel?: LeftPanelProps;
  pageSettingsPanel?: PageSettingsPanelProps; // MANUAL OVERRIDE
  dragHandleIcon?: IconProps; // MANUAL OVERRIDE
};

type MainBlockPropsValues = {
  'Collapsed': MainBlockInternalProps;
  'Expanded': MainBlockInternalProps;
};

const propValues: MainBlockPropsValues = {
  Collapsed: {
    canvas: {
    },
    // MANUAL OVERRIDE STARTS
    dragHandleIcon: {
      asset: 'Body',
      colour: 'NeutralDefault',
    },
    // MANUAL OVERRIDE ENDS
  },
  Expanded: {
    canvas: {
    },
    leftPanel: {
    },
    // MANUAL OVERRIDE STARTS
    pageSettingsPanel: {
    },
    dragHandleIcon: {
      asset: 'Body',
      colour: 'NeutralDefault',
    },
    // MANUAL OVERRIDE ENDS
  },
};

const getProps = (type: string): MainBlockInternalProps => {
  const values: MainBlockInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
