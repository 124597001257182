import { IconProps } from '../Icon';
import { TextProps } from '../Text';

type ToggleItemInternalProps = {
  text?: TextProps;
  icon?: IconProps;
};

type ToggleItemPropsValues = {
  'LargeTextSelectedDefault': ToggleItemInternalProps;
  'LargeTextUnselectedDefault': ToggleItemInternalProps;
  'SmallIconSelectedContextMenu': ToggleItemInternalProps;
  'SmallIconSelectedDefault': ToggleItemInternalProps;
  'SmallIconUnselectedContextMenu': ToggleItemInternalProps;
  'SmallIconUnselectedDefault': ToggleItemInternalProps;
  'SmallIconTextSelectedDefault': ToggleItemInternalProps;
  'SmallIconTextUnselectedDefault': ToggleItemInternalProps;
  'SmallTextSelectedDefault': ToggleItemInternalProps;
  'SmallTextUnselectedDefault': ToggleItemInternalProps;
};

const propValues: ToggleItemPropsValues = {
  LargeTextSelectedDefault: {
    text: {
      type: 'LabelMedium',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralHoverSelected',
    },
  },
  LargeTextUnselectedDefault: {
    text: {
      type: 'LabelMedium',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralDefault',
    },
  },
  SmallIconSelectedContextMenu: {
    icon: {
      asset: 'ArrowDown',
      colour: 'NeturalHoverSelected',
    },
  },
  SmallIconSelectedDefault: {
    icon: {
      asset: 'ArrowDown',
      colour: 'NeturalHoverSelected',
    },
  },
  SmallIconUnselectedContextMenu: {
    icon: {
      asset: 'ArrowDown',
      colour: 'NeutralDefault',
    },
  },
  SmallIconUnselectedDefault: {
    icon: {
      asset: 'ArrowDown',
      colour: 'NeutralDefault',
    },
  },
  SmallIconTextSelectedDefault: {
    icon: {
      asset: 'ArrowDown',
      colour: 'NeturalHoverSelected',
    },
    text: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Regular400',
      colour: 'NeutralHoverSelected',
    },
  },
  SmallIconTextUnselectedDefault: {
    text: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Regular400',
      colour: 'NeutralDefault',
    },
  },
  SmallTextSelectedDefault: {
    text: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Regular400',
      colour: 'NeutralHoverSelected',
    },
  },
  SmallTextUnselectedDefault: {
    text: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Regular400',
      colour: 'NeutralDefault',
    },
  },
};

const getProps = (type: string): ToggleItemInternalProps => {
  const values: ToggleItemInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
