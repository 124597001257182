import type { PreviewBodyElementProps } from './types';
import { mapStylesToPreviewCssProps } from '../../../../utils/util';

const usePresenter = ({ element, style, ...otherProps }: PreviewBodyElementProps): PreviewBodyElementProps => {
  return {
    element,
    style: {
      ...mapStylesToPreviewCssProps(element.styles),
      ...style,
      height: 'fit-content',
    },
    ...otherProps,
  };
};

export default usePresenter;
