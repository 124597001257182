import { ColourRowProps } from '../../molecules/ColourRow';
import { PanelHeaderProps } from '../../molecules/PanelHeader';
import { ReplaceRowProps } from '../../molecules/ReplaceRow';

type BackgroundPanelInternalProps = {
  panelHeader?: PanelHeaderProps;
  replaceRow?: ReplaceRowProps;
  colourRow?: ColourRowProps;
};

type BackgroundPanelPropsValues = {
  'Collapsed': BackgroundPanelInternalProps;
  'Expanded': BackgroundPanelInternalProps;
};

const propValues: BackgroundPanelPropsValues = {
  Collapsed: {
    panelHeader: {
      state: 'Collapsed',
      type: 'Default',
    },
  },
  Expanded: {
    panelHeader: {
      state: 'Expanded',
      type: 'Default',
    },
    replaceRow: {
      type: 'Image',
      state: 'Default',
      style: 'Default',
    },
    colourRow: {
    },
  },
};

const getProps = (type: string): BackgroundPanelInternalProps => {
  const values: BackgroundPanelInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
