import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useExpandablePanel } from '../../../hooks';
import { typedDeepMerge } from '../../../utils/objectUtils';
import type { TypographyPanelCombinedProps } from './types';
import { ColorType } from '../../../store/types';
import { StoreContext } from '../../../store/StoreProvider';
import { buildUnitTypeFieldWithDropdownProps } from '../../atoms/FieldWithDropdown/utils';
import { buildEnumDropdownButtonProps } from '../../atoms/DropdownButton/utils';
import { getStylePanelConfigurations, updateEnumValue } from '../../../store/PageStore/utils';

const usePresenter = (props: TypographyPanelCombinedProps): TypographyPanelCombinedProps => {
  const { t } = useTranslation();
  const { pageStore } = useContext(StoreContext);
  const selectedElement = pageStore.selectedCombineElement;
  const expandableProps = useExpandablePanel(props, 'Expanded', true);
  const panelConfig = getStylePanelConfigurations(selectedElement);

  const fontColor = selectedElement?.styles?.fontColor;
  const fontAlign = selectedElement?.styles?.fontAlign;
  const fontItalic = selectedElement?.styles?.fontItalic;
  const fontStyle = selectedElement?.styles?.fontStyle;

  const updateFontColor = async (colour: ColorType) => {
    if (!selectedElement) {
      return;
    }
    await pageStore.updateElement(selectedElement, {
      styles: {
        fontColor: colour,
      },
    });
  };

  const updateFontItalic = async (value: boolean) => {
    if (!selectedElement) {
      return;
    }
    await pageStore.updateElement(selectedElement, {
      styles: {
        fontItalic: value,
      },
    });
  };

  return typedDeepMerge(expandableProps, {
    panelHeader: {
      text: {
        value: t('typographyPanel.typography'),
      },
    },
    font: {
      text: {
        value: t('typographyPanel.font'),
      },
    },
    dropdownButton: buildEnumDropdownButtonProps(pageStore, selectedElement, 'font', 'inter'),
    weight: {
      text: {
        value: t('typographyPanel.weight'),
      },
    },
    weightDropdown: buildEnumDropdownButtonProps(pageStore, selectedElement, 'fontWeight', 'regular'),
    size: {
      text: {
        value: t('typographyPanel.size'),
      },
    },
    sizeField: buildUnitTypeFieldWithDropdownProps(pageStore, selectedElement, 'fontSize'),
    spacing: {
      text: {
        value: t('typographyPanel.spacing'),
      },
    },
    letterSpace: {
      asset: 'LetterSpace',
    },
    letterSpaceField: buildUnitTypeFieldWithDropdownProps(pageStore, selectedElement, 'letterSpacing'),
    lineHeight: {
      asset: 'LineHeight',
    },
    lineHeightField: buildUnitTypeFieldWithDropdownProps(pageStore, selectedElement, 'lineHeight'),
    colourRow: {
      colour: {
        text: {
          value: t('typographyPanel.colour'),
        },
      },
      colourValue: fontColor,
      defaultColourValue: '#000000',
      onColourUpdated: (colour) => updateFontColor(colour),
    },
    style: {
      text: {
        value: t('typographyPanel.align'),
      },
    },
    lineToggle: {
      currentValue: fontAlign,
      toggleOptions: [
        {
          value: 'left',
          icon: 'LeftAlign',
        },
        {
          value: 'center',
          icon: 'CenterAlign',
        },
        {
          value: 'right',
          icon: 'RightAlign',
        },
        {
          value: 'justify',
          icon: 'Justify',
        },
      ],
      onOptionSelected: (option) => updateEnumValue(pageStore, selectedElement, 'fontAlign', option),
    },
    style1: {
      text: {
        value: t('typographyPanel.style'),
      },
    },
    italicsToggle: {
      currentValue: fontItalic ? 'italic' : 'roman',
      toggleOptions: [
        {
          value: 'roman',
          icon: 'Roman',
        },
        {
          value: 'italic',
          icon: 'Italic',
        },
      ],
      onOptionSelected: (option) => updateFontItalic(option === 'italic'),
    },
    lineToggle1: {
      currentValue: fontStyle,
      toggleOptions: [
        {
          value: 'none',
          icon: 'Null',
        },
        {
          value: 'line-through',
          icon: 'Strikethrough',
        },
        {
          value: 'underline',
          icon: 'Underline',
        },
        {
          value: 'overline',
          icon: 'Overline',
        },
      ],
      onOptionSelected: (option) => updateEnumValue(pageStore, selectedElement, 'fontStyle', option),
    },
    renderPanel: panelConfig?.Typography,
  });
};

export default usePresenter;
