import { Constants } from '../constants';

export const pxToRem = (px: number): string => {
  return `${px / 16}rem`;
};

export const convertUnit = (
  valueInPx: number,
  newUnit: string,
  parentLengthInPx: number,
): number => {
  switch (newUnit.toLowerCase()) {
    case 'rem': {
      return valueInPx / 16;
    }
    case '%': {
      return (valueInPx / parentLengthInPx) * 100;
    }
    case 'vw': {
      const vw = Math.max(document.documentElement.clientWidth, window.innerWidth);
      return (valueInPx / vw) * 100;
    }
    case 'vh': {
      const vh = Math.max(document.documentElement.clientHeight, window.innerHeight);
      return (valueInPx / vh) * 100;
    }
    default: {
      return valueInPx;
    }
  }
  return valueInPx;
};

export type HexCodeValue = {
  colorCode?: string,
  opacity?: string,
};
const defaultHexColorValue: HexCodeValue = {
  colorCode: '000000',
};
// TODO: improve compatibility
export const getHexColorValue = (value?: string): HexCodeValue => {
  try {
    if (value && typeof value === 'string') {
      const colorCodeString = value.substring(1, 7);
      const colorCode: string | undefined = colorCodeString.match(Constants.REGEX.VALID_HEXADECIMAL) ? colorCodeString : undefined;
      let opacity;
      if (colorCode) {
        // has color, should take default value ff or take opacity value after the color code
        opacity = value.substring(7, value.length) || 'ff';
      }
      return {
        colorCode,
        opacity,
      };
    }
    return defaultHexColorValue;
  } catch {
    return defaultHexColorValue;
  }
};
export const percentageToHex = (percentage: number): string => {
  const value = (Math.round(percentage * Constants.SYSTEM.COLOUR_CONVERSION_COEFFICIENT)).toString(16);
  return value.length === 1 ? `0${value}` : value;
};
export const hexToPercentage = (hex: string): number => {
  const value = Math.round(parseInt(`${hex || 0}`, 16) / Constants.SYSTEM.COLOUR_CONVERSION_COEFFICIENT);
  return Math.min(100, value);
};
