// MANUAL OVERRIDE STARTS
import { type ExportedModalProps } from '../../../modules/common/ModalWrapper';
import withModalWrapper from '../../../modules/common/ModalWrapper/withModalWrapper';
import InternalTextModal from './TextModal';
import type { TextModalCombinedProps as InternalTextModalCombinedProps, TextModalProps } from './types';

export type { TextModalProps };
export type TextModalCombinedProps = ExportedModalProps<InternalTextModalCombinedProps>;

const TextModal = withModalWrapper(InternalTextModal);
export default TextModal;
// MANUAL OVERRIDE ENDS
