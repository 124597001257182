import { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AddImageMenuContext, SetVariableMenuContext } from '.';
import useModal from '../../../modules/common/ModalWrapper/context/useModal';
import { getCustomPositionForElementLeft } from '../../../modules/common/ModalWrapper/utils';
import { VariableReferenceType } from '../../../modules/variables/types';
import { resolveAvailableVariables } from '../../../store/LogicBuilderStore/utils';
import { updateAnchorOffsetUnit, updateAnchorOffsetValue, updateAnchorPoint } from '../../../store/PageStore/anchorUtils';
import { getElementInputProps, updateImageValue, updateInputPropValue } from '../../../store/PageStore/settingsUtils';
import { StoreContext } from '../../../store/StoreProvider';
import {
  BackgroundSizeType,
  BooleanValueInputProp,
  FileType,
  IImage,
  ImageValueInputProp,
  InputProp,
  NumberValueInputProp,
  StringValueInputProp,
} from '../../../store/types';
import { typedDeepMerge } from '../../../utils/objectUtils';
import { capitalize } from '../../../utils/stringUtils';
import { RowLabelValueProps } from '../../atoms/RowLabel';
import { SettingsRowCombinedProps } from '../SettingsRow';
import type { SettingsPanelCombinedProps } from './types';

const usePresenter = (props: SettingsPanelCombinedProps): SettingsPanelCombinedProps => {
  const { pageStore } = useContext(StoreContext);
  const { t } = useTranslation();
  const selectedElement = pageStore.selectedCombineElement;
  const elementInputProps = getElementInputProps(selectedElement, t);

  const [editingInputProp, setEditingInputProp] = useState<InputProp>();

  const [imageFileType, setImageFileType] = useState<FileType>();

  const onBackgroundImageUpdated = useCallback(async (file: File | null) => {
    const fileUrl = await updateImageValue(pageStore, selectedElement as IImage, file);
    const image: FileType | undefined = file ? {
      fileName: file.name,
      url: fileUrl || '',
    } : undefined;
    setImageFileType(image);
  }, [pageStore, selectedElement]);

  const onBackgroundSizeUpdated = useCallback(async (value: BackgroundSizeType) => {
    if (selectedElement) {
      await pageStore.updateElement(selectedElement, {
        styles: {
          objectFit: value,
        },
      });
    }
  }, [pageStore, selectedElement]);

  const onBackgroundVerticalAnchorPointUpdated = useCallback(async (option: string) => {
    await updateAnchorPoint(pageStore, selectedElement, 'objectVerticalAnchor', option);
  }, [pageStore, selectedElement]);

  const onBackgroundVerticalAnchorValueUpdated = useCallback(async (value: string) => {
    await updateAnchorOffsetValue(pageStore, selectedElement, 'objectVerticalAnchor', value);
  }, [pageStore, selectedElement]);

  const onBackgroundVerticalAnchorUnitUpdated = useCallback(async (option: string) => {
    await updateAnchorOffsetUnit(pageStore, selectedElement, 'objectVerticalAnchor', option);
  }, [pageStore, selectedElement]);

  const onBackgroundHorizontalAnchorPointUpdated = useCallback(async (option: string) => {
    await updateAnchorPoint(pageStore, selectedElement, 'objectHorizontalAnchor', option);
  }, [pageStore, selectedElement]);

  const onBackgroundHorizontalAnchorValueUpdated = useCallback(async (value: string) => {
    await updateAnchorOffsetValue(pageStore, selectedElement, 'objectHorizontalAnchor', value);
  }, [pageStore, selectedElement]);

  const onBackgroundHorizontalAnchorUnitUpdated = useCallback(async (option: string) => {
    await updateAnchorOffsetUnit(pageStore, selectedElement, 'objectHorizontalAnchor', option);
  }, [pageStore, selectedElement]);

  const { modalContainerRef, openModal: openImageModal } = useModal(
    AddImageMenuContext,
    () => {
      return {
        backgroundImage: imageFileType,
        onBackgroundImageUpdated,

        backgroundSize: selectedElement?.styles.objectFit,
        onBackgroundSizeUpdated,

        backgroundVerticalAnchor: selectedElement?.styles.objectVerticalAnchor,
        onBackgroundVerticalAnchorPointUpdated,
        onBackgroundVerticalAnchorValueUpdated,
        onBackgroundVerticalAnchorUnitUpdated,

        backgroundHorizontalAnchor: selectedElement?.styles.objectHorizontalAnchor,
        onBackgroundHorizontalAnchorPointUpdated,
        onBackgroundHorizontalAnchorValueUpdated,
        onBackgroundHorizontalAnchorUnitUpdated,
      };
    },
    [selectedElement?.styles],
  );

  const onAddImageButtonClicked = useCallback((fileType?: FileType) => {
    setImageFileType(fileType);
    openImageModal({
      customPosition: getCustomPositionForElementLeft(modalContainerRef, -5),
    });
  }, [modalContainerRef, openImageModal]);

  const handleSetInputPropFromVariable = useCallback(async (variable: VariableReferenceType) => {
    if (editingInputProp) {
      await updateInputPropValue(pageStore, selectedElement, editingInputProp, 'variable', variable);
    }
  }, [pageStore, selectedElement, editingInputProp]);

  const handleClearInputPropVariable = useCallback(async (inputProp?: InputProp) => {
    if (inputProp) {
      if (inputProp.type === 'image') {
        await updateInputPropValue(pageStore, selectedElement, inputProp, 'value', {
          imageUrl: '',
          fileName: '',
        });
      } else {
        await updateInputPropValue(pageStore, selectedElement, inputProp, 'value', t('settingsPanel.elements.text.displayName') || '');
      }
    }
  }, [pageStore, selectedElement, t]);

  const handleClearEditingInputPropVariable = useCallback(async () => {
    await handleClearInputPropVariable(editingInputProp);
  }, [editingInputProp, handleClearInputPropVariable]);

  const { openModal: openVariablesModal } = useModal(
    SetVariableMenuContext,
    () => {
      return {
        variables: resolveAvailableVariables('string', pageStore.currentPageVariables),
        selectedVariable: editingInputProp?.source === 'variable' ? editingInputProp.value : undefined,
        onClearClicked: handleClearEditingInputPropVariable,
        onVariableSelected: handleSetInputPropFromVariable,
      };
    },
  );

  const openVariableModal = (inputProp: InputProp) => {
    setEditingInputProp(inputProp);
    openVariablesModal({
      customPosition: getCustomPositionForElementLeft(modalContainerRef, -5),
    });
  };

  let settingsRows: SettingsRowCombinedProps[] | undefined;
  if (elementInputProps) {
    settingsRows = elementInputProps.map((inputProp): SettingsRowCombinedProps => {
      const setFromVariable = inputProp.source === 'variable';
      const rowLabel: RowLabelValueProps = {
        text: {
          value: `${inputProp.displayName || capitalize(inputProp.name)} | ${inputProp.type}`,
        },
        button: {
          icon: {
            asset: 'Variable',
            colour: setFromVariable ? 'VariableSelected' : 'NeutralDefault',
          },
          onClick: () => openVariableModal(inputProp),
        },
      };

      const settingsRowProps: SettingsRowCombinedProps = {
        type: 'Variable',
        rowLabel,
        replaceRow: {
          type: 'VariableText',
          state: setFromVariable ? 'Uploaded' : 'Editable',
          style: 'VariableVertical',
          rowLabel,
          propertyItem: {
            text: {
              value: setFromVariable ? `{${inputProp?.value?.name}}` : '',
            },
            button: {
              type: 'Icon',
              icon: {
                asset: 'Close',
              },
              onClick: () => handleClearInputPropVariable(inputProp),
            },
          },
        },
      };

      switch (inputProp?.type) {
        case 'string': {
          const prop = inputProp as StringValueInputProp;
          settingsRowProps.replaceRow = typedDeepMerge(settingsRowProps.replaceRow!, {
            inputField: {
              disabled: true,
              inputType: 'text',
              textValue: prop.value,
            },
          });
          break;
        }
        case 'number': {
          const prop = inputProp as NumberValueInputProp;
          settingsRowProps.replaceRow = typedDeepMerge(settingsRowProps.replaceRow!, {
            inputField: {
              disabled: true,
              inputType: 'number',
              textValue: prop.value,
            },
          });
          break;
        }
        case 'boolean': {
          const prop = inputProp as BooleanValueInputProp;
          settingsRowProps.type = 'Toggle';
          settingsRowProps.toggleItemList = {
            currentValue: t(`settingsPanel.boolean.${prop.value ? 'enable' : 'disable'}`) || undefined,
            toggleOptions: [
              {
                value: t('settingsPanel.boolean.enable'),
              },
              {
                value: t('settingsPanel.boolean.disable'),
              },
            ],
            onOptionSelected: (option) => {},
          };
          break;
        }
        case 'image': {
          const prop = inputProp as ImageValueInputProp;
          settingsRowProps.replaceRow = typedDeepMerge(settingsRowProps.replaceRow!, {
            type: setFromVariable ? 'VariableText' : 'VariableButton',
            state: setFromVariable ? 'Uploaded' : 'Default',
            replaceButton: {
              actionType: 'Button',
              button: {
                icon: {
                  asset: prop.value?.imageUrl ? 'Edit' : 'Add',
                },
                text: {
                  value: t(`settingsPanel.image.${prop.value?.imageUrl ? 'editImage' : 'addImage'}`),
                },
                onClick: () => onAddImageButtonClicked(prop.value ? {
                  fileName: prop.value?.fileName,
                  url: prop.value?.imageUrl,
                } : undefined),
              },
            },
          });
          break;
        }
        case 'collection': {
          break;
        }
        default:
          break;
      }
      return settingsRowProps;
    });
  }

  return {
    ...props,
    settingsRowList: {
      settingsRows,
    },
    addImageContainerRef: modalContainerRef,
  };
};

export default usePresenter;
