import {
  action, computed, makeAutoObservable, observable, runInAction,
} from 'mobx';
import { DEFAULT_ELEMENTS } from '../../constants';
import { nameToSlug } from '../../lib/utils';
import { getActionsApi } from '../../modules/actions/actions.repository';
import { Action, Flow, FlowTypeEnum } from '../../modules/actions/types';
import { getElementsApi } from '../../modules/pages/pages.repository';
import { Variable, VariableScope } from '../../modules/variables/types';
import { BreakpointStore } from '../BreakpointStore/BreakpointStore';
import { IBreakpoint } from '../BreakpointStore/types';
import { convertToDoubleLinked } from '../LogicBuilderStore/utils';
import { PageStore } from '../PageStore/PageStore';
import { IPage, PageElementsMap, PartialElement } from '../types';

export class PreviewStore {
  private readonly pageStore: PageStore;

  private readonly breakpointStore: BreakpointStore;

  public constructor(pageStore: PageStore, breakpointStore: BreakpointStore) {
    this.pageStore = pageStore;
    this.breakpointStore = breakpointStore;

    makeAutoObservable(this);
  }

  @observable
  public isInitialised = false;

  @observable
  public currentPreviewPage: IPage | undefined;

  @computed
  public get currentPreviewPageVariables(): Variable[] {
    return this.pageStore.variables.filter(({ scope, pageUuid }) => {
      if (scope === 'Global') {
        return true;
      } else if (scope === 'Page' && this.currentPreviewPage?.uuid === pageUuid) {
        return true;
      }
      return false;
    });
  }

  @computed
  public get previewElements(): PageElementsMap {
    return this.currentPreviewPage?.elements ?? DEFAULT_ELEMENTS;
  }

  @observable
  public previewBreakpointName = 'desktop';

  @action
  public updatePreviewBreakpoint = (windowSize: number) => {
    let matchingBreakpoint: IBreakpoint = this.breakpointStore.breakpoints[0];
    this.breakpointStore.breakpoints.forEach((breakpoint) => {
      if (windowSize >= breakpoint.width) {
        matchingBreakpoint = breakpoint;
      }
    });
    if (matchingBreakpoint) {
      this.previewBreakpointName = matchingBreakpoint.type === 'custom' ? `custom-${matchingBreakpoint.width}` : matchingBreakpoint.type;
    }
  };

  @computed
  public get previewBreakpointElements(): PageElementsMap<PartialElement> {
    const breakpointElements = this.currentPreviewPage?.breakpointElements;
    if (breakpointElements && !breakpointElements[this.previewBreakpointName]) {
      breakpointElements[this.previewBreakpointName] = {};
    }
    return breakpointElements ? breakpointElements[this.previewBreakpointName] : {};
  }

  @observable
  public currentGlobalVariables: Record<string, string> = {};

  @observable
  public currentPageVariables: Record<string, string> = {};

  @observable
  public currentNavigationVariables: Record<string, string> = {};

  @action
  public updateVariable = (name: string, value: string, scope: VariableScope) => {
    if (scope === 'Global') {
      this.currentGlobalVariables[name] = value;
    } else if (scope === 'Page') {
      this.currentPageVariables[name] = value;
    }
  };

  @observable
  public actions: Action[] = [];

  @action
  public setAndLoadCurrentPreviewPage = async (pageSlug: string, navigationVariables: Record<string, string>): Promise<void> => {
    if (nameToSlug(this.currentPreviewPage?.slug || this.currentPreviewPage?.name || '') === pageSlug) {
      return;
    }
    const currentPage: IPage | undefined = this.pageStore.getPageBySlug(pageSlug);

    this.isInitialised = false;
    if (currentPage) {
      this.currentPreviewPage = currentPage;
      this.currentPageVariables = {};
      this.currentNavigationVariables = navigationVariables;
      await this.loadCurrentPreviewPageElements();
      this.actions = await getActionsApi(currentPage.uuid);
    } else {
      this.actions = [];
    }
    this.isInitialised = true;
  };

  @computed
  private get elementFlowActions(): Record<string, Record<string, Flow>> {
    // sort actions by element uuid and flow type
    const elementFlowActions: Record<string, Record<string, Flow>> = {};
    this.actions.forEach((item) => {
      const { uuid, elementUuid, flowType } = item;
      const elementFlows = elementFlowActions[elementUuid] || {};
      const flowActions = elementFlows[flowType] || [];
      flowActions[uuid] = item;
      elementFlows[flowType] = flowActions;
      elementFlowActions[elementUuid] = elementFlows;
    });
    // convert all the flows to double linked flows
    Object.keys(elementFlowActions).forEach((elementUuid) => {
      const elementFlows = elementFlowActions[elementUuid];
      Object.keys(elementFlows).forEach((flowType) => {
        const flowActions = elementFlows[flowType];
        elementFlows[flowType] = convertToDoubleLinked(flowActions);
      });
      elementFlowActions[elementUuid] = elementFlows;
    });
    return elementFlowActions;
  }

  public getElementFlowActions = (elementUuid: string | undefined, flowType: FlowTypeEnum): Flow | undefined => {
    if (!elementUuid) {
      return undefined;
    }
    const elementFlows = this.elementFlowActions[elementUuid] || {};
    const flowActions = elementFlows[flowType];
    return flowActions;
  };

  @action
  public loadCurrentPreviewPageElements = async (): Promise<void> => {
    if (this.currentPreviewPage && !this.currentPreviewPage.elements) {
      // Fetch data from the back end
      const { elements, breakpointElements } = await getElementsApi({ pageUuid: this.currentPreviewPage.uuid });

      runInAction(() => {
        if (this.currentPreviewPage) {
          this.currentPreviewPage.elements = elements;
          this.currentPreviewPage.breakpointElements = breakpointElements;
        }
      });
    }
  };
}
