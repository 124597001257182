import { useCallback, useState, type FocusEventHandler, type MouseEventHandler } from 'react';

export interface IUseHoverable {
  onMouseOver: MouseEventHandler;
  onFocus: FocusEventHandler;
  onMouseOut: MouseEventHandler;
  onBlur: FocusEventHandler;
  isHovered: boolean;
}

export const useHoverable = (): IUseHoverable => {
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const onMouseOver: MouseEventHandler = useCallback(() => setIsHovered(true), []);
  const onFocus: FocusEventHandler = useCallback(() => setIsHovered(true), []);
  const onMouseOut: MouseEventHandler = useCallback(() => setIsHovered(false), []);
  const onBlur: FocusEventHandler = useCallback(() => setIsHovered(false), []);

  return {
    onMouseOver,
    onFocus,
    onMouseOut,
    onBlur,
    isHovered,
  };
};
