import React from 'react';
import cx from 'classnames';

import ReplaceRow from '../ReplaceRow';

import styles from './UpdateValue.module.scss';
import usePresenter from './UpdateValue.presenter';
import getProps from './UpdateValue.props';
import { UpdateValueCombinedProps } from './types';

const UpdateValue: React.FC<UpdateValueCombinedProps> = (props) => {
  const {
    className,
    classes,
    replaceRow,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.updateValue;

  return (
    <div className={cx(currentStyle, className)}>
      <ReplaceRow
        className={cx(styles.replaceRow, classes?.replaceRow)}
        {...internalProps.replaceRow}
        {...replaceRow}/>
    </div>
  );
};

export default UpdateValue;
