import { DropdownButtonProps } from '../../atoms/DropdownButton';
import { FieldWithDropdownProps } from '../../atoms/FieldWithDropdown';
import { RowLabelProps } from '../../atoms/RowLabel';
import { TextDividerProps } from '../../atoms/TextDivider';
import { PanelHeaderProps } from '../../molecules/PanelHeader';

type SizePanelInternalProps = {
  panelHeader?: PanelHeaderProps;
  rowLabel?: RowLabelProps;
  inputField?: FieldWithDropdownProps;
  rowLabel1?: RowLabelProps;
  inputField1?: FieldWithDropdownProps;
  textDivider?: TextDividerProps;
  rowLabel2?: RowLabelProps;
  inputField2?: FieldWithDropdownProps;
  rowLabel3?: RowLabelProps;
  inputField3?: FieldWithDropdownProps;
  rowLabel4?: RowLabelProps;
  inputField4?: FieldWithDropdownProps;
  rowLabel5?: RowLabelProps;
  inputField5?: FieldWithDropdownProps;
  font?: RowLabelProps;
  dropdownButton?: DropdownButtonProps;
};

type SizePanelPropsValues = {
  'Collapsed': SizePanelInternalProps;
  'Expanded': SizePanelInternalProps;
};

const propValues: SizePanelPropsValues = {
  'Collapsed': {
    panelHeader: {
      state: 'Collapsed',
      type: 'Default',
    },
  },
  'Expanded': {
    panelHeader: {
      state: 'Expanded',
      type: 'Default',
    },
    rowLabel: {
      state: 'Default',
      style: 'Default',
      type: 'Panel',
    },
    inputField: {
    },
    rowLabel1: {
      state: 'Default',
      style: 'Default',
      type: 'Panel',
    },
    inputField1: {
    },
    textDivider: {
    },
    rowLabel2: {
      state: 'Default',
      style: 'Default',
      type: 'Panel',
    },
    inputField2: {
    },
    rowLabel3: {
      state: 'Default',
      style: 'Default',
      type: 'Panel',
    },
    inputField3: {
    },
    rowLabel4: {
      state: 'Default',
      style: 'Default',
      type: 'Panel',
    },
    inputField4: {
    },
    rowLabel5: {
      state: 'Default',
      style: 'Default',
      type: 'Panel',
    },
    inputField5: {
    },
    font: {
      state: 'Default',
      style: 'Default',
      type: 'Panel',
    },
    dropdownButton: {
      type: 'Default',
      style: 'Default',
      state: 'Collapsed',
      colour: 'Default',
    },
  },
};

const getProps = (type: string): SizePanelInternalProps => {
  const values: SizePanelInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
