import React from 'react';
import cx from 'classnames';

import Text from '../Text';

import styles from './Label.module.scss';
import usePresenter from './Label.presenter';
import getProps from './Label.props';
import { LabelCombinedProps } from './types';

const Label: React.FC<LabelCombinedProps> = (props) => {
  const {
    state,
    className,
    classes,
    text,
    text1,
  } = usePresenter(props);

  const variantName = `${state}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`label${variantName}`];

  let componentView;

  switch (variantName) {
    case 'Default': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <Text
            className={cx(styles.text, classes?.text)}
            {...internalProps.text}
            {...text}
          />
        </div>
      );
      break;
    }
    case 'Required': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <Text
            className={cx(styles.text, classes?.text)}
            {...internalProps.text}
            {...text}
          />
          <Text
            className={cx(styles.text1, classes?.text1)}
            {...internalProps.text1}
            {...text1}
          />
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default Label;
