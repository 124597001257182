import Default from './Default.svg';
import HPTL from './HPTL.svg';
import HSAT from './HSAT.svg';
import HSBT from './HSBT.svg';
import VPTL from './VPTL.svg';
import VSAL from './VSAL.svg';
import VSBL from './VSBL.svg';

export const TopLeft = {
  Default,
  'horizontal-space-around-top-none': HSAT,
  'horizontal-packed-top-left': HPTL,
  'horizontal-space-between-top-none': HSBT,
  'vertical-packed-top-left': VPTL,
  'vertical-space-around-none-left': VSAL,
  'vertical-space-between-none-left': VSBL,
};
