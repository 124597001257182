import { useCallback, useContext, useEffect } from 'react';
import { StoreContext } from '../../../store/StoreProvider';
import { UIBuilderPageCombinedProps } from './types';

const usePresenter = (props: UIBuilderPageCombinedProps): UIBuilderPageCombinedProps => {
  const { pageStore } = useContext(StoreContext);

  const onKeyDown = useCallback(async (event: KeyboardEvent): Promise<void> => {
    // Delete selected element on Backspace/Delete key presses
    if (['Backspace', 'Delete'].includes(event.key) &&
      !pageStore.isPageOrElementEditMode &&
      pageStore.selectedElement &&
      document.activeElement?.tagName !== 'INPUT'
    ) {
      // Delete selected element without any confirmation
      await pageStore.deleteElement(pageStore.selectedElement);
      return;
    }
    // Cancel drag on Backspace/Delete/Escape key presses
    if (['Backspace', 'Delete', 'Escape'].includes(event.key) && pageStore.draggingElement) {
      pageStore.setIsDragCancelled(true);
      return;
    }
  }, [pageStore]);

  useEffect(() => {
    // TODO: Check if there is a more optimal way of handling key presses rather than with a global handler
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    document.addEventListener('keydown', onKeyDown);
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    return () => document.removeEventListener('keydown', onKeyDown);
  }, [onKeyDown]);

  return props;
};

export default usePresenter;
