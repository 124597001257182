import React from 'react';
import InternalDropdownButton from './DropdownButton';
import {
  DropdownButtonProps,
  DropdownButtonValueProps,
} from './types';
import styles from './DropdownButton.module.scss';
import DropdownItemList from '../../molecules/DropdownItemList';
import { DropdownItemListCombinedProps } from '../../molecules/DropdownItemList/types';
import DropdownWrapper, { ExportedDropdownProps } from '../../../modules/common/DropdownWrapper';

export type { DropdownButtonProps, DropdownButtonValueProps };
export type DropdownButtonWrapperProps = ExportedDropdownProps<
DropdownButtonValueProps & DropdownButtonProps,
DropdownItemListCombinedProps
>;
const DropdownButton: React.FC<DropdownButtonWrapperProps> = ({ id, toggleProps, menuProps, className, onToggle }) => (
  <DropdownWrapper
    id={id}
    Toggle={InternalDropdownButton}
    Menu={DropdownItemList}
    toggleProps={toggleProps}
    menuProps={menuProps}
    className={`${className} ${styles.select}`}
    onToggle={onToggle}
    alignEnd
  />
);
export default DropdownButton;
