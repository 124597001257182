import { ButtonProps } from '../../atoms/Button';
import { IconProps } from '../../atoms/Icon';
import { TextProps } from '../../atoms/Text';
import { TextInputProps } from '../../atoms/TextInput';

type LayerLabelItemInternalProps = {
  button?: ButtonProps;
  icon?: IconProps;
  text?: TextProps;
  icon1?: IconProps;
  textInput?: TextInputProps;
};

type LayerLabelItemPropsValues = {
  'CollapsedDefaultDefault': LayerLabelItemInternalProps;
  'CollapsedDefaultHidden': LayerLabelItemInternalProps;
  'CollapsedEditableSelectedDefault': LayerLabelItemInternalProps;
  'CollapsedEditableSelectedHidden': LayerLabelItemInternalProps;
  'CollapsedSelectedDefault': LayerLabelItemInternalProps;
  'CollapsedSelectedHidden': LayerLabelItemInternalProps;
  'DefaultDefaultDefault': LayerLabelItemInternalProps;
  'DefaultDefaultHidden': LayerLabelItemInternalProps;
  'DefaultEditableSelectedDefault': LayerLabelItemInternalProps;
  'DefaultEditableSelectedHidden': LayerLabelItemInternalProps;
  'DefaultSelectedDefault': LayerLabelItemInternalProps;
  'DefaultSelectedHidden': LayerLabelItemInternalProps;
  'ExpandedDefaultDefault': LayerLabelItemInternalProps;
  'ExpandedDefaultHidden': LayerLabelItemInternalProps;
  'ExpandedEditableSelectedDefault': LayerLabelItemInternalProps;
  'ExpandedEditableSelectedHidden': LayerLabelItemInternalProps;
  'ExpandedSelectedDefault': LayerLabelItemInternalProps;
  'ExpandedSelectedHidden': LayerLabelItemInternalProps;
};

const propValues: LayerLabelItemPropsValues = {
  CollapsedDefaultDefault: {
    button: {
      type: 'IconClear',
      size: 'S',
      state: 'Default',
      style: 'PrimarySubdued',
    },
    icon: {
      asset: 'Body',
      colour: 'NeutralDefault',
    },
    text: {
      type: 'LabelMedium',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralDefault',
    },
  },
  CollapsedDefaultHidden: {
    button: {
      type: 'IconClear',
      size: 'S',
      state: 'Default',
      style: 'PrimarySubdued',
    },
    icon: {
      asset: 'Body',
      colour: 'NeutralDefault',
    },
    text: {
      type: 'LabelMedium',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralDefault',
    },
    icon1: {
      asset: 'Hidden',
      colour: 'NeutralDefault',
    },
  },
  CollapsedEditableSelectedDefault: {
    button: {
      type: 'IconClear',
      size: 'S',
      state: 'Default',
      style: 'PrimarySubdued',
    },
    icon: {
      asset: 'Body',
      colour: 'NeturalHoverSelected',
    },
    textInput: {
      state: 'Default',
    },
  },
  CollapsedEditableSelectedHidden: {
    button: {
      type: 'IconClear',
      size: 'S',
      state: 'Default',
      style: 'PrimarySubdued',
    },
    icon: {
      asset: 'Body',
      colour: 'NeturalHoverSelected',
    },
    textInput: {
      state: 'Default',
    },
    icon1: {
      asset: 'Hidden',
      colour: 'NeturalHoverSelected',
    },
  },
  CollapsedSelectedDefault: {
    button: {
      type: 'IconClear',
      size: 'S',
      state: 'Default',
      style: 'PrimarySubdued',
    },
    icon: {
      asset: 'Body',
      colour: 'NeturalHoverSelected',
    },
    text: {
      type: 'LabelMedium',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralHoverSelected',
    },
  },
  CollapsedSelectedHidden: {
    button: {
      type: 'IconClear',
      size: 'S',
      state: 'Default',
      style: 'PrimarySubdued',
    },
    icon: {
      asset: 'Body',
      colour: 'NeturalHoverSelected',
    },
    text: {
      type: 'LabelMedium',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralHoverSelected',
    },
    icon1: {
      asset: 'Hidden',
      colour: 'NeturalHoverSelected',
    },
  },
  DefaultDefaultDefault: {
    icon: {
      asset: 'Body',
      colour: 'NeutralDefault',
    },
    text: {
      type: 'LabelMedium',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralDefault',
    },
  },
  DefaultDefaultHidden: {
    icon: {
      asset: 'Body',
      colour: 'NeutralDefault',
    },
    text: {
      type: 'LabelMedium',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralDefault',
    },
    icon1: {
      asset: 'Hidden',
      colour: 'NeutralDefault',
    },
  },
  DefaultEditableSelectedDefault: {
    icon: {
      asset: 'Body',
      colour: 'NeturalHoverSelected',
    },
    textInput: {
      state: 'Default',
    },
  },
  DefaultEditableSelectedHidden: {
    icon: {
      asset: 'Body',
      colour: 'NeturalHoverSelected',
    },
    textInput: {
      state: 'Default',
    },
    icon1: {
      asset: 'Hidden',
      colour: 'NeturalHoverSelected',
    },
  },
  DefaultSelectedDefault: {
    icon: {
      asset: 'Body',
      colour: 'NeturalHoverSelected',
    },
    text: {
      type: 'LabelMedium',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralHoverSelected',
    },
  },
  DefaultSelectedHidden: {
    icon: {
      asset: 'Body',
      colour: 'NeturalHoverSelected',
    },
    text: {
      type: 'LabelMedium',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralHoverSelected',
    },
    icon1: {
      asset: 'Hidden',
      colour: 'NeturalHoverSelected',
    },
  },
  ExpandedDefaultDefault: {
    button: {
      type: 'IconClear',
      size: 'S',
      state: 'Default',
      style: 'PrimarySubdued',
    },
    icon: {
      asset: 'Body',
      colour: 'NeutralDefault',
    },
    text: {
      type: 'LabelMedium',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralDefault',
    },
  },
  ExpandedDefaultHidden: {
    button: {
      type: 'IconClear',
      size: 'S',
      state: 'Default',
      style: 'PrimarySubdued',
    },
    icon: {
      asset: 'Body',
      colour: 'NeutralDefault',
    },
    text: {
      type: 'LabelMedium',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralDefault',
    },
    icon1: {
      asset: 'Hidden',
      colour: 'NeutralDefault',
    },
  },
  ExpandedEditableSelectedDefault: {
    button: {
      type: 'IconClear',
      size: 'S',
      state: 'Default',
      style: 'PrimarySubdued',
    },
    icon: {
      asset: 'Body',
      colour: 'NeturalHoverSelected',
    },
    textInput: {
      state: 'Default',
    },
  },
  ExpandedEditableSelectedHidden: {
    button: {
      type: 'IconClear',
      size: 'S',
      state: 'Default',
      style: 'PrimarySubdued',
    },
    icon: {
      asset: 'Body',
      colour: 'NeturalHoverSelected',
    },
    textInput: {
      state: 'Default',
    },
    icon1: {
      asset: 'Hidden',
      colour: 'NeturalHoverSelected',
    },
  },
  ExpandedSelectedDefault: {
    button: {
      type: 'IconClear',
      size: 'S',
      state: 'Default',
      style: 'PrimarySubdued',
    },
    icon: {
      asset: 'Body',
      colour: 'NeturalHoverSelected',
    },
    text: {
      type: 'LabelMedium',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralHoverSelected',
    },
  },
  ExpandedSelectedHidden: {
    button: {
      type: 'IconClear',
      size: 'S',
      state: 'Default',
      style: 'PrimarySubdued',
    },
    icon: {
      asset: 'Body',
      colour: 'NeturalHoverSelected',
    },
    text: {
      type: 'LabelMedium',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralHoverSelected',
    },
    icon1: {
      asset: 'Hidden',
      colour: 'NeturalHoverSelected',
    },
  },
};

const getProps = (type: string): LayerLabelItemInternalProps => {
  const values: LayerLabelItemInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
