import { Dispatch, SetStateAction, useState } from 'react';
import { TabItemCombinedProps, TabItemStateEnum } from '../components/atoms/TabItem/types';
import { IconValueProps } from '../components/atoms/Icon';

export interface IUseTabItemsState<T> {
  selectedTab: T;
  tabItemList: TabItemCombinedProps[];
  setSelectedTab: Dispatch<SetStateAction<T>>
}

export type ITabsData<T> = {
  tab: T,
  label: string,
  icon?: IconValueProps,
  error?: boolean,
};

type UseTabItemsStatePayload<T> = {
  tabsData: ITabsData<T>[],
  defaultTab: T,
  onClick?: (index: number) => void
};

export const useTabItemsState = <T>(
  { tabsData, defaultTab, onClick }: UseTabItemsStatePayload<T>): IUseTabItemsState<T> => {
  const [selectedTab, setSelectedTab] = useState<T>(defaultTab);

  const getTabState = (tabData: ITabsData<T>): TabItemStateEnum => {
    if (tabData.tab === selectedTab) {
      return 'Selected';
    } else if (tabData.error) {
      return 'Error';
    } else {
      return 'Default';
    }
  };

  const onSelectTab = (tabData: ITabsData<T>, index: number) => {
    if (onClick) {
      onClick(index);
    } else {
      setSelectedTab(tabData.tab);
    }
  };

  const tabItemList = tabsData.map((tabData, index): TabItemCombinedProps => {
    return {
      state: getTabState(tabData),
      text: {
        value: tabData.label,
      },
      onClick: () => {
        onSelectTab(tabData, index);
      },
      icon: tabData.icon,
    };
  });

  return { selectedTab, tabItemList, setSelectedTab };
};
