import { IconAssetEnum } from '../../components/atoms/Icon/types';
import { VariableInput } from '../../components/organisms/InputVariableTable/types';
import { nameToSlug } from '../../lib/utils';
import { CallApiAction } from '../../modules/actions/types';
import {
  ApiResponseVariable, PageNavigationVariable, Variable, VariableReferenceType, VariableScope,
} from '../../modules/variables/types';
import { ApiResponsePropMetadata } from '../ApiGroupStore/types';
import {
  InputProp, RealValueInputProp, InputPropsMap, InputPropType, IPage,
} from '../types';

export const getPageNavigationVariables = (variables: Variable[], pageUuid?: string) => {
  return variables
    .filter((variable) => variable.source === 'PageNavigation' &&
      variable.pageUuid === pageUuid) as PageNavigationVariable[];
};

export const getCallApiActionVariables = (variables: Variable[], action?: CallApiAction) => {
  return variables
    .filter((variable) => variable.source === 'ApiResponse' &&
      variable.metadata.actionUuid === action?.uuid) as ApiResponseVariable[];
};

export const mapVariableInputsToReferences = (inputVariables?: VariableInput[]) => {
  return inputVariables?.map((variable): VariableReferenceType => {
    return {
      id: variable.name,
      scope: 'Global',
      name: variable.name,
      type: variable.type || 'string',
    };
  }) || [];
};

export const mapPageVariablesToReferences = (pageVariables?: PageNavigationVariable[]) => {
  return pageVariables?.map((variable): VariableReferenceType => {
    return {
      id: variable.uuid,
      scope: variable.scope,
      name: variable.name,
      type: variable.type || 'string',
    };
  }) || [];
};

const resolveStoredVariable = (
  reference: VariableReferenceType | undefined,
  storedVariables: Variable[],
  globalValues: Record<string, string>,
  pageValues: Record<string, string>,
  pageNavigationValues: Record<string, string>,
) => {
  if (reference) {
    const referencedVariable = storedVariables.find((variable) => variable.uuid === reference.id);
    switch (referencedVariable?.source) {
      case 'CreateVariable':
        break;
      case 'ApiResponse': {
        if (referencedVariable.scope === 'Global') {
          return globalValues[reference.name] || referencedVariable.metadata.initialValue;
        } else if (referencedVariable.scope === 'Page') {
          return pageValues[reference.name] || referencedVariable.metadata.initialValue;
        }
        break;
      }
      case 'PageNavigation':
        return pageNavigationValues[reference.name];
      default:
        break;
    }
  }
  return undefined;
};

const resolveFlowVariable = (
  reference: VariableReferenceType | undefined,
  actionOutputs: Record<string, ApiResponsePropMetadata>,
) => {
  if (reference) {
    const referencedOutput = actionOutputs[reference.id];
    return referencedOutput ? `${referencedOutput.value as string}` : undefined;
  }
  return undefined;
};

const resolveVariableReference = (
  reference: VariableReferenceType | undefined,
  storedVariables: Variable[],
  globalValues: Record<string, string>,
  pageValues: Record<string, string>,
  pageNavigationValues: Record<string, string>,
  actionOutputs: Record<string, ApiResponsePropMetadata>,
) => {
  switch (reference?.scope) {
    case 'Global':
    case 'Page':
      return resolveStoredVariable(reference, storedVariables, globalValues, pageValues, pageNavigationValues);
    case 'Flow':
      return resolveFlowVariable(reference, actionOutputs);
    case 'CollectionItem':
      break;
    default:
      break;
  }
  return undefined;
};

export const resolveVariable = (
  variableInput: InputProp | null | undefined,
  storedVariables: Variable[],
  globalValues: Record<string, string>,
  pageValues: Record<string, string>,
  pageNavigationValues: Record<string, string>,
  actionOutputs: Record<string, ApiResponsePropMetadata> = {},
): RealValueInputProp | undefined => {
  if (variableInput?.source === 'variable') {
    return {
      ...variableInput,
      source: 'value',
      value: resolveVariableReference(
        variableInput.value,
        storedVariables,
        globalValues,
        pageValues,
        pageNavigationValues,
        actionOutputs,
      ),
    } as RealValueInputProp;
  } else {
    return {
      ...variableInput,
      value: variableInput?.value || undefined,
    } as RealValueInputProp;
  }
};

export const resolveVariables = (
  variables: VariableReferenceType[],
  variableInputs: InputPropsMap | undefined,
  storedVariables: Variable[],
  globalValues: Record<string, string>,
  pageValues: Record<string, string>,
  pageNavigationValues: Record<string, string>,
  actionOutputs: Record<string, ApiResponsePropMetadata> = {},
) => {
  const values = variables.map((variable): RealValueInputProp => {
    const value = resolveVariable(
      variableInputs ? variableInputs[variable.name] : undefined,
      storedVariables,
      globalValues,
      pageValues,
      pageNavigationValues,
      actionOutputs,
    );
    return value || {
      name: variable.name,
      type: variable.type as InputPropType,
      source: 'value',
      value: undefined,
    };
  });
  return values;
};

export const mapScopeIcon = (scope: VariableScope): IconAssetEnum => {
  switch (scope) {
    case 'Flow':
      return 'FlowVariable';
    default:
      return scope;
  }
};

export const parseVariablesFromPath = (pagePath: string, variables: PageNavigationVariable[]) => {
  const [pageSlug, ...pathSegments] = pagePath.split('/');

  const pathVariables: Record<string, string> = {};
  let pathSegmentIndex = 0;
  variables.forEach((variable) => {
    if (variable.metadata.isPathParam) {
      if (variable.metadata.alias) {
        const value = pathSegments[pathSegmentIndex + 1];
        pathVariables[variable.name] = value;
        pathSegmentIndex += 2;
      } else {
        const value = pathSegments[pathSegmentIndex];
        pathVariables[variable.name] = value;
        pathSegmentIndex += 1;
      }
    }
  });

  return pathVariables;
};

export const mapVariablesToPathQuery = (variables: PageNavigationVariable[]) => {
  if (variables.length === 0) {
    return {};
  }
  const pathSegments: string[] = [];
  const queryParams: string[] = [];

  variables.forEach((variable) => {
    if (variable.metadata.isPathParam) {
      if (variable.metadata.alias) {
        pathSegments.push(variable.metadata.alias);
      }
      pathSegments.push(`{{${variable.name}}}`);
    } else {
      queryParams.push(`${variable.name}={{${variable.name}}}`);
    }
  });

  return {
    pathSegments,
    queryParams,
  };
};

export const buildTargetPageUrl = (
  targetPage: IPage | undefined,
  variables: Variable[],
  variableInputs: InputPropsMap = {},
  globalValues: Record<string, string> = {},
  pageValues: Record<string, string> = {},
  pageNavigationValues: Record<string, string> = {},
  actionOutputs: Record<string, ApiResponsePropMetadata> = {},
) => {
  const slugValue = nameToSlug(targetPage?.slug || targetPage?.name || '');
  const pageNavigationVariables = getPageNavigationVariables(variables, targetPage?.uuid);
  const { pathSegments, queryParams } = mapVariablesToPathQuery(pageNavigationVariables);
  let path = pathSegments ? `/${pathSegments.join('/')}` : '';
  let params = queryParams ? `?${queryParams.join('&')}` : '';

  const resolvedPageVariables = resolveVariables(
    mapPageVariablesToReferences(pageNavigationVariables),
    variableInputs,
    variables,
    globalValues,
    pageValues,
    pageNavigationValues,
    actionOutputs,
  );

  resolvedPageVariables.forEach(({ name, type, value }) => {
    switch (type) {
      case 'boolean':
      case 'number':
      case 'string':
        path = path.replace(`{{${name}}}`, `${value || ''}`);
        params = params.replace(`{{${name}}}`, `${value || ''}`);
        break;
      default:
        break;
    }
  });

  return {
    url: `/preview/${slugValue}${path}`,
    params,
  };
};

export const mapVariablesToUrl = (variables: PageNavigationVariable[]) => {
  if (variables.length === 0) {
    return '';
  }
  const { pathSegments, queryParams } = mapVariablesToPathQuery(variables);

  let url = '';
  if (pathSegments?.length) {
    url = `${url}/${pathSegments.join('/')}`;
  }

  if (queryParams?.length) {
    url = `${url}?${queryParams.join('&')}`;
  }

  return url;
};
