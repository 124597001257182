import { comparer, computed } from 'mobx';
import { useCallback, useContext, type MouseEventHandler } from 'react';
import { StoreContext } from '../../../store/StoreProvider';
import type { IElementSelectionState, ISelectedElement } from '../../../store/types';

export interface IUseSelectable {
  onClick: MouseEventHandler;
  elementSelectionState: IElementSelectionState;
}

export const useElementSelectable = (element: ISelectedElement | undefined): IUseSelectable => {
  const { pageStore } = useContext(StoreContext);

  const onClick: MouseEventHandler = useCallback((event) => {
    event.stopPropagation();
    if (pageStore.selectedElement?.id === element?.id) {
      pageStore.setEditingElement(element);
    } else {
      pageStore.setSelectedElement(element);
    }
  }, [element, pageStore]);

  const elementSelectionState: IElementSelectionState = computed(
    () => pageStore.getElementSelectionState(element),
    // Use MobX structural comparer because we get an object, not a single primitive value.
    // This is to prevent unnecessary re-rendering.
    { equals: comparer.structural },
  ).get();

  return {
    onClick,
    elementSelectionState,
  };
};
