import {
  useCallback,
  useContext,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { SetVariableMenuContext } from '.';
import useModal from '../../../modules/common/ModalWrapper/context/useModal';
import { getCustomPositionForElementLeft } from '../../../modules/common/ModalWrapper/utils';
import { VariableReferenceType, VariableValueType } from '../../../modules/variables/types';
import { resolveAvailableActionVariables } from '../../../store/LogicBuilderStore/utils';
import { StoreContext } from '../../../store/StoreProvider';
import { InputProp, InputPropsMap, VariableInputProp } from '../../../store/types';
import { ValuesPanelCombinedProps, ValuesPanelStateEnum } from './types';
import { mapInputVariablesProps, shouldRender } from './utils';

const usePresenter = (props: ValuesPanelCombinedProps): ValuesPanelCombinedProps => {
  const {
    pageStore,
    apiGroupStore,
    logicBuilderStore,
  } = useContext(StoreContext);
  const { apiGroups } = apiGroupStore;
  const { pages, currentPageVariables, variables } = pageStore;
  const { selectedFlow, selectedAction, updateAction } = logicBuilderStore;

  const { t } = useTranslation();
  const [state, setState] = useState<ValuesPanelStateEnum>('Expanded');

  const [editingInputVariable, setEditingInputVariable] = useState<VariableReferenceType>();
  const [selectedVariable, setSelectedVariable] = useState<VariableInputProp>();

  const handleUpdateActionInput = (inputVariable: VariableReferenceType, value: string) => {
    if (selectedAction) {
      switch (selectedAction.actionType) {
        case 'NavigateTo':
        case 'CallApi': {
          const variableInputs: InputPropsMap = {
            [inputVariable.name]: {
              name: inputVariable.name,
              source: 'value',
              type: inputVariable.type as VariableValueType,
              value,
            } as InputProp,
          };
          void updateAction(selectedAction, {
            metadata: {
              variableInputs,
            },
          });
          break;
        }
        default:
          break;
      }
    }
  };

  const handleUpdateActionInputFromVariable = useCallback(async (variable?: VariableReferenceType) => {
    if (selectedAction && editingInputVariable) {
      switch (selectedAction.actionType) {
        case 'NavigateTo':
        case 'CallApi': {
          const variableInputs: InputPropsMap = {
            [editingInputVariable.name]: {
              name: editingInputVariable.name,
              source: 'variable',
              type: editingInputVariable.type as VariableValueType,
              value: variable,
            },
          };
          await updateAction(selectedAction, {
            metadata: {
              variableInputs,
            },
          });
          break;
        }
        default:
          break;
      }
    }
  }, [selectedAction, editingInputVariable, updateAction]);

  const handleRemoveActionInputVariable = useCallback(async (variable?: VariableReferenceType) => {
    if (selectedAction && variable) {
      switch (selectedAction.actionType) {
        case 'NavigateTo':
        case 'CallApi': {
          const variableInputs: InputPropsMap = {
            [variable.name]: null,
          };
          await updateAction(selectedAction, {
            metadata: {
              variableInputs,
            },
          });
          break;
        }
        default:
          break;
      }
    }
  }, [selectedAction, updateAction]);

  const handleClearActionInputVariable = useCallback(async () => {
    await handleRemoveActionInputVariable(editingInputVariable);
  }, [editingInputVariable, handleRemoveActionInputVariable]);

  const { modalContainerRef, openModal } = useModal(
    SetVariableMenuContext,
    () => {
      return {
        variables: resolveAvailableActionVariables(
          editingInputVariable?.type as VariableValueType,
          selectedAction,
          selectedFlow,
          apiGroups,
          currentPageVariables,
        ),
        selectedVariable: selectedVariable?.value,
        onClearClicked: handleClearActionInputVariable,
        onVariableSelected: handleUpdateActionInputFromVariable,
      };
    },
  );

  const handleSetFromVariableClicked = (inputVariable: VariableReferenceType, variableValue?: VariableInputProp) => {
    setEditingInputVariable(inputVariable);
    setSelectedVariable(variableValue);
    openModal({
      customPosition: getCustomPositionForElementLeft(modalContainerRef, 4),
    });
  };

  const renderPanel = shouldRender(selectedAction, apiGroups, pages, variables);
  return {
    ...props,
    state,
    renderPanel,
    panelHeader: {
      button: {
        onClick: () => setState(state === 'Collapsed' ? 'Expanded' : 'Collapsed'),
      },
      text: {
        value: t('valuesPanel.title'),
      },
      button1: {
        icon: {
          asset: 'Expand',
        },
      },
    },
    replaceRowList: {
      replaceRows: renderPanel ? mapInputVariablesProps(
        selectedAction,
        apiGroups,
        pages,
        variables,
        t,
        handleUpdateActionInput,
        handleSetFromVariableClicked,
        handleRemoveActionInputVariable,
      ) : undefined,
    },
    setVariableMenuContainerRef: modalContainerRef,
  };
};

export default usePresenter;
