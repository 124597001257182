import { TextProps } from '../Text';

type LogicSettingsHeaderInternalProps = {
  text?: TextProps;
};

type LogicSettingsHeaderPropsValues = {
  '': LogicSettingsHeaderInternalProps;
};

const propValues: LogicSettingsHeaderPropsValues = {
  '': {
    text: {
      type: 'LabelLarge',
      align: 'Center',
      style: 'Medium500',
      colour: 'NeutralHoverSelected',
    },
  },
};

const getProps = (type: string): LogicSettingsHeaderInternalProps => {
  const values: LogicSettingsHeaderInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
