import React from 'react';
import styles from './ImageElement.module.scss';
import usePresenter from './ImageElement.presenter';
import type { ImageElementProps } from './types';

const ImageElement: React.FC<ImageElementProps> = (props) => {
  const {
    elementRef, element, style, imageStyle, isElementEdited, imageUrl, altText, ...otherProps
  } = usePresenter(props);

  return (
    <div
      ref={elementRef}
      className={styles.imageElement}
      style={style}
      {...otherProps}
    >
      <img
        src={imageUrl}
        alt={altText}
        style={imageStyle}
        draggable={false}
        className={styles.img}
      />
    </div>
  );
};

export default ImageElement;
