type ImageInternalProps = {
};

type ImagePropsValues = {
  '': ImageInternalProps;
};

const propValues: ImagePropsValues = {
  '': {
  },
};

const getProps = (type: string): ImageInternalProps => {
  const values: ImageInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
