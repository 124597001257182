import React from 'react';
import cx from 'classnames';
import { Handle, Position } from 'reactflow';

import Button from '../../atoms/Button';
import DropdownButton from '../../atoms/DropdownButton';
import Icon from '../../atoms/Icon';
import Text from '../../atoms/Text';
import UpdateArrow from '../../atoms/UpdateArrow';

import styles from './ActionCard.module.scss';
import usePresenter from './ActionCard.presenter';
import getProps from './ActionCard.props';
import { ActionCardCombinedProps } from './types';

const ActionCard: React.FC<ActionCardCombinedProps> = (props) => {
  const {
    data: {
      style,
      state,
      className,
      classes,
      icon,
      text,
      dropdownButton,
      text1,
      text2,
      button,
      updateArrow,
      onClick,
    },
  } = usePresenter(props);

  const variantName = `${style}${state}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`actionCard${variantName}`];

  let componentView;

  switch (variantName) {
    case 'DoubleLineDefault':
    case 'DoubleLineSelected':
    case 'EmptyDefault':
    case 'EmptySelected':
    case 'SingleLineDefault':
    case 'SingleLineSelected': {
      componentView = (
        // MANUAL OVERRIDE and disabled eslint errors of accessibility
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <div className={cx(currentStyle, className)} onClick={onClick}>
          <Handle
            className={styles.hiddenHandler}
            type="source"
            position={Position.Right}
            isConnectable={false}
          />
          <Handle
            className={styles.hiddenHandler}
            type="target"
            position={Position.Left}
            isConnectable={false}
          />
          <div className={cx(styles.topContent, classes?.topContent)}>
            <div className={cx(styles.labelContent, classes?.labelContent)}>
              <Icon
                className={cx(styles.icon, classes?.icon)}
                {...internalProps.icon}
                {...icon}/>
              <Text
                className={cx(styles.text, classes?.text)}
                {...internalProps.text}
                {...text}/>
            </div>
            <DropdownButton
              className={cx(styles.dropdownButton, classes?.dropdownButton)}
              {...internalProps.dropdownButton}
              {...dropdownButton}/>
          </div>
          <div className={cx(styles.middleContent, classes?.middleContent)}>
            <Text
              className={cx(styles.text1, classes?.text1)}
              {...internalProps.text1}
              {...text1}/>
          </div>
          <div className={cx(styles.bottomContent, classes?.bottomContent)}>
            <Text
              className={cx(styles.text2, classes?.text2)}
              {...internalProps.text2}
              {...text2}/>
          </div>
        </div>
      );
      break;
    }
    case 'NavigateURLDefault':
    case 'NavigateURLSelected': {
      componentView = (
        // MANUAL OVERRIDE and disabled eslint errors of accessibility
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <div className={cx(currentStyle, className)} onClick={onClick}>
          <Handle
            className={styles.hiddenHandler}
            type="source"
            position={Position.Right}
            isConnectable={false}
          />
          <Handle
            className={styles.hiddenHandler}
            type="target"
            position={Position.Left}
            isConnectable={false}
          />
          <div className={cx(styles.topContent, classes?.topContent)}>
            <div className={cx(styles.labelContent, classes?.labelContent)}>
              <Icon
                className={cx(styles.icon, classes?.icon)}
                {...internalProps.icon}
                {...icon}/>
              <Text
                className={cx(styles.text, classes?.text)}
                {...internalProps.text}
                {...text}/>
            </div>
            <DropdownButton
              className={cx(styles.dropdownButton, classes?.dropdownButton)}
              {...internalProps.dropdownButton}
              {...dropdownButton}/>
          </div>
          <div className={cx(styles.middleContent, classes?.middleContent)}>
            <div className={cx(styles.uRLContent, classes?.uRLContent)}>
              <Button
                className={cx(styles.button, classes?.button)}
                {...internalProps.button}
                {...button}/>
              <Text
                className={cx(styles.text1, classes?.text1)}
                {...internalProps.text1}
                {...text1}/>
            </div>
          </div>
          <div className={cx(styles.bottomContent, classes?.bottomContent)}>
            <Text
              className={cx(styles.text2, classes?.text2)}
              {...internalProps.text2}
              {...text2}/>
          </div>
        </div>
      );
      break;
    }
    case 'UpdateVarDefault':
    case 'UpdateVarSelected': {
      componentView = (
        // MANUAL OVERRIDE and disabled eslint errors of accessibility
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <div className={cx(currentStyle, className)} onClick={onClick}>
          <Handle
            className={styles.hiddenHandler}
            type="source"
            position={Position.Right}
            isConnectable={false}
          />
          <Handle
            className={styles.hiddenHandler}
            type="target"
            position={Position.Left}
            isConnectable={false}
          />
          <div className={cx(styles.topContent, classes?.topContent)}>
            <div className={cx(styles.labelContent, classes?.labelContent)}>
              <Icon
                className={cx(styles.icon, classes?.icon)}
                {...internalProps.icon}
                {...icon}/>
              <Text
                className={cx(styles.text, classes?.text)}
                {...internalProps.text}
                {...text}/>
            </div>
            <DropdownButton
              className={cx(styles.dropdownButton, classes?.dropdownButton)}
              {...internalProps.dropdownButton}
              {...dropdownButton}/>
          </div>
          <div className={cx(styles.updateContent, classes?.updateContent)}>
            <div className={cx(styles.arrowContent, classes?.arrowContent)}>
              <UpdateArrow
                className={cx(styles.updateArrow, classes?.updateArrow)}
                {...internalProps.updateArrow}
                {...updateArrow}/>
            </div>
            <div className={cx(styles.metadataContent, classes?.metadataContent)}>
              <div className={cx(styles.middleContent, classes?.middleContent)}>
                <Text
                  className={cx(styles.text1, classes?.text1)}
                  {...internalProps.text1}
                  {...text1}/>
              </div>
              <div className={cx(styles.bottomContent, classes?.bottomContent)}>
                <Text
                  className={cx(styles.text2, classes?.text2)}
                  {...internalProps.text2}
                  {...text2}/>
              </div>
            </div>
          </div>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default ActionCard;
