import Default from './Default.svg';
import HPCL from './HPCL.svg';
import HSAC from './HSAC.svg';
import HSBC from './HSBC.svg';
import VPCL from './VPCL.svg';
import VSAL from './VSAL.svg';
import VSBL from './VSBL.svg';

export const CenterLeft = {
  Default,
  'horizontal-packed-center-left': HPCL,
  'horizontal-space-around-center-none': HSAC,
  'horizontal-space-between-center-none': HSBC,
  'vertical-packed-center-left': VPCL,
  'vertical-space-around-none-left': VSAL,
  'vertical-space-between-none-left': VSBL,
};
