import { ButtonProps } from '../../atoms/Button';
import { DropdownButtonProps } from '../../atoms/DropdownButton';
import { InputFieldProps } from '../../atoms/InputField';
import { TextProps } from '../../atoms/Text';
import { ReplaceRowProps } from '../ReplaceRow';

type TableRowInternalProps = {
  dropdownButton?: DropdownButtonProps;
  nameInput?: InputFieldProps;
  response?: TextProps;
  valueType?: TextProps;
  dropdownButton1?: DropdownButtonProps;
  button?: ButtonProps;
  variableNameInput?: InputFieldProps;
  defaultValue?: DropdownButtonProps;
  variableName?: TextProps;
  variableType?: TextProps;
  valueInput?: InputFieldProps;
  keyInput?: InputFieldProps;
  inputField?: InputFieldProps;
  variableValueInput?: ReplaceRowProps;
};

type TableRowPropsValues = {
  BooleanEditVariablesDefault: TableRowInternalProps;
  BooleanEditVariablesselected: TableRowInternalProps;
  BooleanInputVariableDefault: TableRowInternalProps;
  BooleanTestResponseDefault: TableRowInternalProps;
  CollectionObjectEditVariablesDefault: TableRowInternalProps;
  DefaultEditVariablesDefault: TableRowInternalProps;
  DefaultEditVariablesselected: TableRowInternalProps;
  DefaultInputVariableDefault: TableRowInternalProps;
  DefaultKeyValueDefault: TableRowInternalProps;
  DefaultTestResponseDefault: TableRowInternalProps;
  VariableKeyValueDefault: TableRowInternalProps;
};

const propValues: TableRowPropsValues = {
  BooleanEditVariablesDefault: {
    dropdownButton: {
      type: 'Icon',
      style: 'Default',
      state: 'Collapsed',
      colour: 'Default',
    },
    nameInput: {
      type: 'Text',
      size: 'Medium',
      state: 'Default',
    },
    response: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Regular400',
      colour: 'NeutralDefault',
    },
    valueType: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Regular400',
      colour: 'NeutralDefault',
    },
    dropdownButton1: {
      type: 'SelectableRow',
      style: 'Default',
      state: 'Collapsed',
      colour: 'Default',
    },
    button: {
      type: 'IconClear',
      size: 'S',
      state: 'Default',
      style: 'PrimarySubdued',
    },
  },
  BooleanEditVariablesselected: {
    dropdownButton: {
      type: 'Icon',
      style: 'RowHover',
      state: 'Collapsed',
      colour: 'Default',
    },
    nameInput: {
      type: 'Text',
      size: 'Medium',
      state: 'Default',
    },
    response: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Regular400',
      colour: 'NeutralHoverSelected',
    },
    valueType: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Regular400',
      colour: 'NeutralHoverSelected',
    },
    dropdownButton1: {
      type: 'SelectableRow',
      style: 'RowHover',
      state: 'Collapsed',
      colour: 'Default',
    },
    button: {
      type: 'IconClear',
      size: 'S',
      state: 'Default',
      style: 'PrimarySubdued',
    },
  },
  BooleanInputVariableDefault: {
    variableNameInput: {
      type: 'Text',
      size: 'Medium',
      state: 'Default',
    },
    dropdownButton: {
      type: 'Default',
      style: 'Default',
      state: 'Collapsed',
      colour: 'Default',
    },
    defaultValue: {
      type: 'Default',
      style: 'Default',
      state: 'Collapsed',
      colour: 'Default',
    },
    button: {
      type: 'IconClear',
      size: 'S',
      state: 'Default',
      style: 'PrimarySubdued',
    },
  },
  BooleanTestResponseDefault: {
    variableName: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralDefault',
    },
    variableType: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralDefault',
    },
    dropdownButton: {
      type: 'Default',
      style: 'Default',
      state: 'Collapsed',
      colour: 'Default',
    },
  },
  CollectionObjectEditVariablesDefault: {
    dropdownButton: {
      type: 'Icon',
      style: 'Default',
      state: 'Collapsed',
      colour: 'Default',
    },
    nameInput: {
      type: 'Text',
      size: 'Medium',
      state: 'Default',
    },
    response: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Regular400',
      colour: 'NeutralDefault',
    },
    valueType: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Regular400',
      colour: 'NeutralDefault',
    },
    valueInput: {
      type: 'Text',
      size: 'Medium',
      state: 'Default',
    },
    button: {
      type: 'IconClear',
      size: 'S',
      state: 'Default',
      style: 'PrimarySubdued',
    },
  },
  DefaultEditVariablesDefault: {
    dropdownButton: {
      type: 'Icon',
      style: 'Default',
      state: 'Collapsed',
      colour: 'Default',
    },
    nameInput: {
      type: 'Text',
      size: 'Medium',
      state: 'Default',
    },
    response: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Regular400',
      colour: 'NeutralDefault',
    },
    valueType: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Regular400',
      colour: 'NeutralDefault',
    },
    valueInput: {
      type: 'Text',
      size: 'Medium',
      state: 'Default',
    },
    button: {
      type: 'IconClear',
      size: 'S',
      state: 'Default',
      style: 'PrimarySubdued',
    },
  },
  DefaultEditVariablesselected: {
    dropdownButton: {
      type: 'Icon',
      style: 'Default',
      state: 'Collapsed',
      colour: 'Default',
    },
    nameInput: {
      type: 'Text',
      size: 'Medium',
      state: 'Default',
    },
    response: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Regular400',
      colour: 'NeutralHoverSelected',
    },
    valueType: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Regular400',
      colour: 'NeutralHoverSelected',
    },
    valueInput: {
      type: 'Text',
      size: 'Medium',
      state: 'Default',
    },
    button: {
      type: 'IconClear',
      size: 'S',
      state: 'Default',
      style: 'PrimarySubdued',
    },
  },
  DefaultInputVariableDefault: {
    variableNameInput: {
      type: 'Text',
      size: 'Medium',
      state: 'Default',
    },
    dropdownButton: {
      type: 'Default',
      style: 'Default',
      state: 'Collapsed',
      colour: 'Default',
    },
    button: {
      type: 'IconClear',
      size: 'S',
      state: 'Default',
      style: 'PrimarySubdued',
    },
  },
  DefaultKeyValueDefault: {
    keyInput: {
      type: 'Text',
      size: 'Medium',
      state: 'Default',
    },
    valueInput: {
      type: 'Text',
      size: 'Medium',
      state: 'Default',
    },
    button: {
      type: 'IconClear',
      size: 'S',
      state: 'Default',
      style: 'PrimarySubdued',
    },
  },
  DefaultTestResponseDefault: {
    variableName: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralDefault',
    },
    variableType: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralDefault',
    },
    inputField: {
      type: 'Text',
      size: 'Medium',
      state: 'Default',
    },
  },
  VariableKeyValueDefault: {
    keyInput: {
      type: 'Text',
      size: 'Medium',
      state: 'Default',
    },
    variableValueInput: {
      type: 'VariableButton',
      state: 'Editable',
      style: 'Variable',
    },
    button: {
      type: 'IconClear',
      size: 'S',
      state: 'Default',
      style: 'PrimarySubdued',
    },
  },
};

const getProps = (type: string): TableRowInternalProps => {
  const values: TableRowInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
