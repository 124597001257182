import { observer } from 'mobx-react-lite';
import withModal from '../../../modules/common/ModalWrapper/context/withModal';
import AddImageMenu from '../AddImageMenu';
import InternalBackgroundPanel from './BackgroundPanel';
import type { BackgroundPanelProps, BackgroundPanelValueProps } from './types';

export type { BackgroundPanelProps, BackgroundPanelValueProps };
const [BackgroundPanel, AddImageMenuContext] = withModal(observer(InternalBackgroundPanel), AddImageMenu); // MANUAL OVERRIDE
export { AddImageMenuContext };
export default BackgroundPanel;
