import { ApiGroupEndpoint } from '../../../store/ApiGroupStore/types';
import { Methods } from '../../blocks/FunctionBlock/types';
import { isInvalidVariableInputType, isInvalidVariableInputName } from '../../molecules/TableRowList/utils';
import { VariableInput } from '../../organisms/InputVariableTable/types';
import { getKeyValuePairsErrors } from '../GroupPage/utils';
import { EndpointErrorState, EndpointPageStateEnum } from './types';

export const getDefaultEndpoint = (apiNumber: number): ApiGroupEndpoint => ({
  uuid: '',
  name: `API ${apiNumber + 1}`,
  inputVariables: [],
  path: '/',
  headers: [],
  parameters: [],
  httpMethod: 'GET' as Methods,
  body: '{}',
});

export const getVariableInputErrors = (inputs: VariableInput[]): boolean => {
  return inputs.some((input) => isInvalidVariableInputName(input) || isInvalidVariableInputType(input));
};

export const getEndpointErrorState = (endpoint: ApiGroupEndpoint, isVisible: boolean): EndpointErrorState => {
  const hasInputError = endpoint.inputVariables ? getVariableInputErrors(endpoint.inputVariables) : false;
  const hasParameterError = endpoint.parameters ? getKeyValuePairsErrors(endpoint.parameters) : false;
  const hasHeadersError = endpoint.headers ? getKeyValuePairsErrors(endpoint.headers) : false;
  const hasError = hasInputError || hasParameterError || hasHeadersError;
  const endpointState: EndpointPageStateEnum = hasError && isVisible ? 'Error' : 'Default';
  return { hasInputError, hasParameterError, hasHeadersError, hasError, endpointState };
};
