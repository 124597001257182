import { TextProps } from '../Text';

type TextDividerInternalProps = {
  text?: TextProps;
};

type TextDividerPropsValues = {
  '': TextDividerInternalProps;
};

const propValues: TextDividerPropsValues = {
  '': {
    text: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Regular400',
      colour: 'NeutralHoverSelected',
    },
  },
};

const getProps = (type: string): TextDividerInternalProps => {
  const values: TextDividerInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
