import { computed } from 'mobx';
import { useContext } from 'react';
import { StoreContext } from '../../../store/StoreProvider';
import type { ElementDragState, IDraggingElement } from '../../../store/types';

export interface IUseElementDragState {
  elementDragState: ElementDragState;
  isElementDragging: boolean;
}

export const useElementDragState = (element: IDraggingElement | undefined): IUseElementDragState => {
  const { pageStore } = useContext(StoreContext);
  const elementDragState: ElementDragState = computed(() => (element ? pageStore.getElementDragState(element) : 'not-dragging')).get();
  const isElementDragging: boolean = elementDragState !== 'not-dragging';
  return { elementDragState, isElementDragging };
};
