import cx from 'classnames';
import { computed } from 'mobx';
import { useContext, useEffect, useRef } from 'react';
import { useIsLeftPanelType } from '../../../hooks';
import { useDraggable } from '../../../modules/canvas/hooks/useDraggable';
import { StoreContext } from '../../../store/StoreProvider';
import { typedDeepMerge } from '../../../utils/objectUtils';
import styles from './MainBlock.module.scss';
import type { MainBlockCombinedProps } from './types';

const usePresenter = (props: MainBlockCombinedProps): MainBlockCombinedProps => {
  const { className: initialClassName } = props;

  const {
    mainBlockRef,
    isDragging,
    dragHandleX,
    dragHandleY,
    dragHandleProps,
    dragHandleIconAsset,
    cancelDrag,
  } = useDraggable();

  const { appStore, pageStore } = useContext(StoreContext);
  const { isCanvasHovered } = appStore;
  const isElementsPanelOpen: boolean = useIsLeftPanelType('Design');
  const prevIsDragging = useRef<boolean>(false);
  const wasElementsPanelOpenWhenDragStarted = useRef<boolean>(false);
  const isDropForbidden: boolean = computed(() => pageStore.getDragState() === 'dragging-over-forbidden-drop-zone').get();

  // Switch from Elements to PagesAndLayers panel when we are dragging over canvas
  // Switch back to PagesAndLayers panel when drag ends
  useEffect(() => {
    if (isDragging && isCanvasHovered && isElementsPanelOpen) {
      appStore.setLeftPanelType('Layers');
    } else if (prevIsDragging.current && !isDragging && wasElementsPanelOpenWhenDragStarted.current) {
      appStore.setLeftPanelType('Design');
    }
  }, [appStore, isCanvasHovered, isDragging, isElementsPanelOpen]);

  useEffect(() => {
    if (!prevIsDragging.current && isDragging) {
      wasElementsPanelOpenWhenDragStarted.current = isElementsPanelOpen;
    }
  }, [isDragging, isElementsPanelOpen]);

  // This useEffect must be after useEffects for panel switching and wasElementsPanelOpenWhenDragStarted
  useEffect(() => {
    prevIsDragging.current = isDragging;
  }, [isDragging]);

  // Check if the user has cancelled the drag
  useEffect(() => {
    if (pageStore.isDragCancelled) {
      cancelDrag();
      pageStore.setIsDragCancelled(false);
    }
  }, [pageStore.isDragCancelled, pageStore, cancelDrag]);

  const className: string = cx(initialClassName, {
    [styles.elementDragging]: isDragging,
    [styles.elementDropForbidden]: isDropForbidden,
  });

  const dragHandleClassName: string = cx({
    [styles.dragHandleDragging]: isDragging,
    [styles.dragHandleTransparent]: !isElementsPanelOpen,
  });

  return {
    ...typedDeepMerge(props, {
      classes: {
        dragHandle: dragHandleClassName,
      },
      ...(dragHandleIconAsset ? { dragHandleIcon: { asset: dragHandleIconAsset, colour: 'NeturalHoverSelected' } } : {}),
    }),
    className,
    mainBlockRef,
    dragHandleX,
    dragHandleY,
    dragHandleProps,
  };
};

export default usePresenter;
