import { DropdownButtonProps } from '../../atoms/DropdownButton';
import { TextProps } from '../../atoms/Text';
import { CodeSpaceProps } from '../../molecules/CodeSpace';
import { InfoTestFieldProps } from '../../molecules/InfoTestField';
import { TabItemListProps } from '../../molecules/TabItemList';
import { KeyValueTableProps } from '../../organisms/KeyValueTable';

type FunctionBlockInternalProps = {
  text?: TextProps;
  dropdownButton?: DropdownButtonProps;
  infoTestField?: InfoTestFieldProps;
  tabItemList?: TabItemListProps;
  dropdownButton1?: DropdownButtonProps;
  codeSpace?: CodeSpaceProps;
  keyValueTable?: KeyValueTableProps;
};

type FunctionBlockPropsValues = {
  'Body': FunctionBlockInternalProps;
  'Header': FunctionBlockInternalProps;
  'Parameter': FunctionBlockInternalProps;
};

const propValues: FunctionBlockPropsValues = {
  Body: {
    text: {
      type: 'LabelMedium',
      align: 'Left',
      style: 'Semibold600',
      colour: 'NeutralHoverSelected',
    },
    dropdownButton: {
      type: 'Method',
      style: 'Default',
      state: 'Collapsed',
      colour: 'Yellow',
    },
    infoTestField: {
    },
    tabItemList: {
    },
    dropdownButton1: {
      type: 'Default',
      style: 'Default',
      state: 'Disabled', // MANUAL OVERRIDE
      colour: 'Default',
    },
    codeSpace: {
    },
  },
  Header: {
    text: {
      type: 'LabelMedium',
      align: 'Left',
      style: 'Semibold600',
      colour: 'NeutralHoverSelected',
    },
    dropdownButton: {
      type: 'Method',
      style: 'Default',
      state: 'Collapsed',
      colour: 'Green',
    },
    infoTestField: {
    },
    tabItemList: {
    },
    keyValueTable: {
      state: 'Empty',
      type: 'Default',
    },
  },
  Parameter: {
    text: {
      type: 'LabelMedium',
      align: 'Left',
      style: 'Semibold600',
      colour: 'NeutralHoverSelected',
    },
    dropdownButton: {
      type: 'Method',
      style: 'Default',
      state: 'Collapsed',
      colour: 'Green',
    },
    infoTestField: {
    },
    tabItemList: {
    },
    keyValueTable: {
      state: 'Empty',
      type: 'Default',
    },
  },
};

const getProps = (type: string): FunctionBlockInternalProps => {
  const values: FunctionBlockInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
