import { useMemo } from 'react';
import { capitalize } from '../../../utils/stringUtils';
import { ToggleItemCombinedProps } from '../../atoms/ToggleItem/types';
import { ToggleItemListCombinedProps } from './types';

const usePresenter = (props: ToggleItemListCombinedProps): ToggleItemListCombinedProps => {
  const { currentValue, toggleOptions, onOptionSelected } = props;
  const toggleItems: ToggleItemCombinedProps[] = useMemo(() => {
    const itemWidth = 100 / (toggleOptions?.length || 1);
    return toggleOptions?.map((item): ToggleItemCombinedProps => {
      return {
        styleOverride: {
          width: `${itemWidth}%`,
          padding: 'unset',
        },
        text: {
          value: capitalize(item.value),
        },
        icon: {
          asset: item.icon,
        },
        state: currentValue === item.value ? 'Selected' : 'Unselected',
        onClick: (event: React.MouseEvent) => {
          event.preventDefault();
          if (onOptionSelected && (currentValue !== item.value)) {
            onOptionSelected(item.value);
          }
        },
      };
    }) ?? [];
  }, [toggleOptions, currentValue, onOptionSelected]);

  return {
    ...props,
    toggleItems,
  };
};

export default usePresenter;
