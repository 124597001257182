import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageNavigationVariable, VariableValueType, variableValueTypes } from '../../../modules/variables/types';
import { typedDeepMerge } from '../../../utils/objectUtils';
import { ButtonValueProps } from '../../atoms/Button';
import { ConfigureNavigationVariableMenuCombinedProps } from './types';

const usePresenter = (props: ConfigureNavigationVariableMenuCombinedProps): ConfigureNavigationVariableMenuCombinedProps => {
  const {
    variable,
    style,
    applyLocalChanges,
    handleSaveVariable,
    handleDeleteVariable,
    closeModal,
  } = props;
  const { t } = useTranslation();
  const [settingsOpen, setSettingsOpen] = useState<boolean>(false);
  const [editVariable, setEditVariable] = useState<PageNavigationVariable>();

  useEffect(() => {
    setEditVariable(variable);
  }, [variable]);

  useEffect(() => {
    if (applyLocalChanges && editVariable && variable) {
      if (editVariable.name !== variable.name ||
        editVariable.type !== variable.type ||
        editVariable.metadata.isPathParam !== variable.metadata.isPathParam ||
        editVariable.metadata.alias !== variable.metadata.alias) {
        applyLocalChanges(editVariable);
      }
    }
  }, [variable, editVariable, applyLocalChanges]);

  const saveButtonProps: ButtonValueProps = {
    text: {
      value: 'Save',
    },
    onClick: () => {
      if (handleSaveVariable) {
        handleSaveVariable(editVariable);
      }
      if (closeModal) {
        closeModal();
      }
    },
  };
  const closeButtonProps: ButtonValueProps = {
    text: {
      value: 'Close',
    },
    onClick: closeModal,
  };
  const deleteButtonProps: ButtonValueProps = {
    text: {
      value: 'Delete',
    },
    onClick: () => {
      if (handleDeleteVariable) {
        handleDeleteVariable(editVariable);
      }
      if (closeModal) {
        closeModal();
      }
    },
  };

  return {
    ...typedDeepMerge(props, {
      state: settingsOpen ? 'Expanded' : 'Collapsed',
      menuHeader: {
        text: {
          value: 'Configure Variable',
        },
        button: {
          icon: {
            asset: 'Close',
          },
          onClick: closeModal,
        },
      },
      label: {
        text: {
          value: 'Name',
        },
        text1: {
          value: '*',
        },
      },
      inputField: {
        textValue: editVariable?.name,
        onSubmit: (value) => {
          if (editVariable) {
            setEditVariable({
              ...editVariable,
              name: value,
            });
          }
        },
      },
      label1: {
        text: {
          value: 'Type',
        },
        text1: {
          value: '*',
        },
      },
      dropdownButton: {
        toggleProps: {
          type: 'Default',
          style: 'Default',
          colour: 'Default',
          text: {
            value: editVariable?.type
              ? editVariable?.type
              : 'Please select a type...',
          },
        },
        menuProps: {
          style: 'Menu',
          dropdownItems: variableValueTypes.map((valueType) => {
            return {
              type: 'Default',
              state: 'Default',
              text: {
                value: valueType,
              },
            };
          }),
          onOptionSelected: (option) => {
            if (editVariable) {
              setEditVariable({
                ...editVariable,
                type: option as VariableValueType,
              });
            }
          },
        },
      },
      accordionHeader: {
        textDivider: {
          text: {
            value: 'Advanced Settings',
          },
        },
        onClick: () => setSettingsOpen((prev) => !prev),
      },
      label2: {
        text: {
          value: 'Path Parameter',
        },
      },
      toggleItemList: {
        currentValue: editVariable?.metadata.isPathParam ? 'Yes' : 'No',
        toggleOptions: [{ value: 'Yes' }, { value: 'No' }],
        onOptionSelected: (option) => {
          if (editVariable) {
            setEditVariable({
              ...editVariable,
              metadata: {
                ...editVariable.metadata,
                isPathParam: option === 'Yes',
              },
            });
          }
        },
      },
      isPathParam: editVariable?.metadata.isPathParam,
      label3: {
        text: {
          value: 'Alias',
        },
      },
      inputField1: {
        textValue: editVariable?.metadata.alias,
        onSubmit: (value) => {
          if (editVariable) {
            setEditVariable({
              ...editVariable,
              metadata: {
                ...editVariable.metadata,
                alias: value,
              },
            });
          }
        },
      },
      button: style === 'Removeable' ? deleteButtonProps : closeButtonProps,
      button1: style === 'Removeable' ? closeButtonProps : saveButtonProps,
      button2: style === 'Removeable' ? saveButtonProps : undefined,
    }),
  };
};

export default usePresenter;
