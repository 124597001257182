import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { LeftPanelCombinedProps, LeftPanelStateEnum } from './types';
import { StoreContext } from '../../../store/StoreProvider';
import { NewPageUuid } from '../../../store/types';
import { typedDeepMerge } from '../../../utils/objectUtils';
import customStyles from './Custom.module.scss';

const usePresenter = (props: LeftPanelCombinedProps): LeftPanelCombinedProps => {
  const { appStore, pageStore } = useContext(StoreContext);

  const panelType = appStore.leftPanelType;

  const { t } = useTranslation();

  let state: LeftPanelStateEnum | undefined;
  switch (panelType) {
    case 'Design':
      state = 'AddElementsPanel';
      break;
    case 'Layers':
      state = 'LayersPanel';
      break;
    case 'Pages':
      state = 'PagesPanel';
      break;
    default:
      state = 'AddElementsPanel';
  }

  return typedDeepMerge(props, {
    state,
    classes: {
      elementPanelContainer: appStore.isLeftPanelType('Design') ? undefined : customStyles.panelHidden,
      layerPanelContainer: appStore.isLeftPanelType('Layers') ? undefined : customStyles.panelHidden,
      pagePanelContainer: appStore.isLeftPanelType('Pages') ? undefined : customStyles.panelHidden,
    },
    // When this panel is closed, it remains mounted but invisible to the user
    // So that the panel state could be preserved, e.g. scroll positions, etc.
    elementItemsSectionLabel: {
      text: {
        value: t('leftPanel.elements'),
      },
    },
    elementsSectionLabel: {
      text: {
        value: t('leftPanel.elements'),
      },
    },
    pagesSectionLabel: {
      text: {
        value: t('leftPanel.pages'),
      },
      button: {
        icon: {
          asset: 'Add',
        },
        onClick: () => pageStore.setPageUuidWithEditedName(NewPageUuid),
      },
    },
  });
};

export default usePresenter;
