import React from 'react';
import cx from 'classnames';

import DropdownButton from '../../atoms/DropdownButton';
import RowLabel from '../../atoms/RowLabel';
import AlignmentGrid from '../../molecules/AlignmentGrid';
import PanelHeader from '../../molecules/PanelHeader';
import ToggleItemList from '../../molecules/ToggleItemList';

import styles from './LayoutPanel.module.scss';
import usePresenter from './LayoutPanel.presenter';
import getProps from './LayoutPanel.props';
import type { LayoutPanelCombinedProps } from './types';
import FieldWithDropdown from '../../atoms/FieldWithDropdown';

const LayoutPanel: React.FC<LayoutPanelCombinedProps> = (props) => {
  const {
    state,
    className,
    classes,
    panelHeader,
    rowLabel,
    dropdownButton,
    rowLabel1,
    toggleItemList,
    rowLabel2,
    inputField,
    rowLabel3,
    inputField1,
    alignmentGrid,
    rowLabel4,
    toggleItemList1,
    rowLabel5,
    dropdownButton1,
    dropdownButton2,
    inputField2,
    dropdownButton3,
    inputField3,
  } = usePresenter(props);

  const variantName = `${state}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`layoutPanel${variantName}`];

  let componentView;

  switch (variantName) {
    case 'Collapsed': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <PanelHeader
            className={cx(styles.panelHeader, classes?.panelHeader)}
            {...internalProps.panelHeader}
            {...panelHeader}/>
        </div>
      );
      break;
    }
    case 'Expanded': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <PanelHeader
            className={cx(styles.panelHeader, classes?.panelHeader)}
            {...internalProps.panelHeader}
            {...panelHeader}/>
          <div className={cx(styles.layoutContent, classes?.layoutContent)}>
            <div className={cx(styles.spacingContent, classes?.spacingContent)}>
              <RowLabel
                className={cx(styles.rowLabel, classes?.rowLabel)}
                {...internalProps.rowLabel}
                {...rowLabel}/>
              <DropdownButton
                className={cx(styles.dropdownButton, classes?.dropdownButton)}
                {...internalProps.dropdownButton}
                {...dropdownButton}/>
            </div>
            <div className={cx(styles.centreContent, classes?.centreContent)}>
              <div className={cx(styles.alignmentContent, classes?.alignmentContent)}>
                <div className={cx(styles.leftContent, classes?.leftContent)}>
                  <div className={cx(styles.direction, classes?.direction)}>
                    <RowLabel
                      className={cx(styles.rowLabel1, classes?.rowLabel1)}
                      {...internalProps.rowLabel1}
                      {...rowLabel1}/>
                    <ToggleItemList
                      className={cx(styles.toggleItemList, classes?.toggleItemList)}
                      {...internalProps.toggleItemList}
                      {...toggleItemList}/>
                  </div>
                  <div className={cx(styles.gap, classes?.gap)}>
                    <RowLabel
                      className={cx(styles.rowLabel2, classes?.rowLabel2)}
                      {...internalProps.rowLabel2}
                      {...rowLabel2}/>
                    {/* MANUAL OVERRIDE */}
                    <FieldWithDropdown
                      className={cx(styles.inputField, classes?.inputField)}
                      {...internalProps.inputField}
                      {...inputField}/>
                  </div>
                  <div className={cx(styles.gap1, classes?.gap1)}>
                    <RowLabel
                      className={cx(styles.rowLabel3, classes?.rowLabel3)}
                      {...internalProps.rowLabel3}
                      {...rowLabel3}/>
                    {/* MANUAL OVERRIDE */}
                    <FieldWithDropdown
                      className={cx(styles.inputField1, classes?.inputField1)}
                      {...internalProps.inputField1}
                      {...inputField1}/>
                  </div>
                </div>
                <AlignmentGrid
                  className={cx(styles.alignmentGrid, classes?.alignmentGrid)}
                  {...internalProps.alignmentGrid}
                  {...alignmentGrid}/>
              </div>
              <div className={cx(styles.wrap, classes?.wrap)}>
                <RowLabel
                  className={cx(styles.rowLabel4, classes?.rowLabel4)}
                  {...internalProps.rowLabel4}
                  {...rowLabel4}/>
                <ToggleItemList
                  className={cx(styles.toggleItemList1, classes?.toggleItemList1)}
                  {...internalProps.toggleItemList1}
                  {...toggleItemList1}/>
              </div>
            </div>
            <div className={cx(styles.positionContent, classes?.positionContent)}>
              <div className={cx(styles.positiom, classes?.positiom)}>
                <RowLabel
                  className={cx(styles.rowLabel5, classes?.rowLabel5)}
                  {...internalProps.rowLabel5}
                  {...rowLabel5}/>
                <DropdownButton
                  className={cx(styles.dropdownButton1, classes?.dropdownButton1)}
                  {...internalProps.dropdownButton1}
                  {...dropdownButton1}/>
              </div>
              <div className={cx(styles.positionAlignment, classes?.positionAlignment)}>
                <div className={cx(styles.leftContent1, classes?.leftContent1)}>
                  <div className={cx(styles.horizontalPosition, classes?.horizontalPosition)}>
                    <DropdownButton
                      className={cx(styles.dropdownButton2, classes?.dropdownButton2)}
                      {...internalProps.dropdownButton2}
                      {...dropdownButton2}/>
                    {/* MANUAL OVERRIDE */}
                    <FieldWithDropdown
                      className={cx(styles.inputField2, classes?.inputField2)}
                      {...internalProps.inputField2}
                      {...inputField2}/>
                  </div>
                  <div className={cx(styles.verticalPosition, classes?.verticalPosition)}>
                    <DropdownButton
                      className={cx(styles.dropdownButton3, classes?.dropdownButton3)}
                      {...internalProps.dropdownButton3}
                      {...dropdownButton3}/>
                    {/* MANUAL OVERRIDE */}
                    <FieldWithDropdown
                      className={cx(styles.inputField3, classes?.inputField3)}
                      {...internalProps.inputField3}
                      {...inputField3}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default LayoutPanel;
