import React from 'react';
import cx from 'classnames';

import Divider from '../../atoms/Divider';
import MenuHeader from '../../molecules/MenuHeader';
import ResponseSection from '../../organisms/ResponseSection';
import VariableSection from '../../organisms/VariableSection';

import styles from './ExpandedVariableResponses.module.scss';
import usePresenter from './ExpandedVariableResponses.presenter';
import getProps from './ExpandedVariableResponses.props';
import { ExpandedVariableResponsesCombinedProps } from './types';

const ExpandedVariableResponses: React.FC<ExpandedVariableResponsesCombinedProps> = (props) => {
  const {
    className,
    classes,
    menuHeader,
    divider,
    variableSection,
    responseSection,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.expandedVariableResponses;

  return (
    <div className={cx(currentStyle, className)}>
      <div className={cx(styles.headerContent, classes?.headerContent)}>
        <MenuHeader
          className={cx(styles.menuHeader, classes?.menuHeader)}
          {...internalProps.menuHeader}
          {...menuHeader}/>
        <Divider
          className={cx(styles.divider, classes?.divider)}
          {...internalProps.divider}
          {...divider}/>
      </div>
      <div className={cx(styles.expandedViewContent, classes?.expandedViewContent)}>
        <VariableSection
          className={cx(styles.variableSection, classes?.variableSection)}
          {...internalProps.variableSection}
          {...variableSection}/>
        <ResponseSection
          className={cx(styles.responseSection, classes?.responseSection)}
          {...internalProps.responseSection}
          {...responseSection}/>
      </div>
    </div>
  );
};

export default ExpandedVariableResponses;
