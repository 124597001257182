import React, { Fragment } from 'react';
import usePresenter from './PreviewElementChildrenRenderer.presenter';
import type { PreviewElementChildrenRendererProps } from './types';
import PreviewElementRenderer from '../PreviewElementRenderer';

const PreviewElementChildrenRenderer: React.FC<PreviewElementChildrenRendererProps> = (props) => {
  const { parentElement } = usePresenter(props);
  const { childIds } = parentElement;

  return (
    <>
      {childIds.map((childId: string, index: number) => (
        <Fragment key={childId}>
          <PreviewElementRenderer id={childId} />
        </Fragment>
      ))}
    </>
  );
};

export default PreviewElementChildrenRenderer;
