import { DividerProps } from '../../atoms/Divider';
import { ElementItemListProps } from '../../molecules/ElementItemList';
import { SectionLabelProps } from '../../molecules/SectionLabel';
import { LayerLabelItemListProps } from '../../molecules/LayerLabelItemList';
import { PageLabelItemListProps } from '../../molecules/PageLabelItemList';

type LeftPanelInternalProps = {
  divider1?: DividerProps;
  elementItemsSectionLabel?: SectionLabelProps;
  elementItemList?: ElementItemListProps;
  elementsSectionLabel?: SectionLabelProps;
  layerLabelItemList?: LayerLabelItemListProps;
  pagesSectionLabel?: SectionLabelProps;
  pageLabelItemList?: PageLabelItemListProps;
};

type LeftPanelPropsValues = {
  'AddElementsPanel': LeftPanelInternalProps;
  'LayersPanel': LeftPanelInternalProps;
  'PagesPanel': LeftPanelInternalProps;
};

const propValues: LeftPanelPropsValues = {
  'AddElementsPanel': {
    divider1: {
      type: 'Horizontal',
      colour: 'Neutral1900',
    },
    elementItemsSectionLabel: {
      type: 'Subsection',
      style: 'TextNoIcon',
    },
    elementItemList: {
    },
    elementsSectionLabel: {
      type: 'Subsection',
      style: 'TextNoIcon',
    },
    layerLabelItemList: {
    },
    pagesSectionLabel: {
      type: 'Subsection',
      style: 'TextIconRight',
    },
    pageLabelItemList: {
    },
  },
  'LayersPanel': {
    divider1: {
      type: 'Horizontal',
      colour: 'Neutral1900',
    },
    elementItemsSectionLabel: {
      type: 'Subsection',
      style: 'TextNoIcon',
    },
    elementItemList: {
    },
    elementsSectionLabel: {
      type: 'Subsection',
      style: 'TextNoIcon',
    },
    layerLabelItemList: {
    },
    pagesSectionLabel: {
      type: 'Subsection',
      style: 'TextIconRight',
    },
    pageLabelItemList: {
    },
  },
  'PagesPanel': {
    divider1: {
      type: 'Horizontal',
      colour: 'Neutral1900',
    },
    elementItemsSectionLabel: {
      type: 'Subsection',
      style: 'TextNoIcon',
    },
    elementItemList: {
    },
    elementsSectionLabel: {
      type: 'Subsection',
      style: 'TextNoIcon',
    },
    layerLabelItemList: {
    },
    pagesSectionLabel: {
      type: 'Subsection',
      style: 'TextIconRight',
    },
    pageLabelItemList: {
    },
  },
};

const getProps = (type: string): LeftPanelInternalProps => {
  const values: LeftPanelInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
