import React from 'react';
import cx from 'classnames';

import Button from '../../atoms/Button';
import TextInput from '../../atoms/TextInput';
import ResponseStructureBlock from '../../blocks/ResponseStructureBlock';
import TestVariableBlock from '../../blocks/TestVariableBlock';
import BreadcrumbItemList from '../../molecules/BreadcrumbItemList';

import styles from './TestResponsePage.module.scss';
import usePresenter from './TestResponsePage.presenter';
import getProps from './TestResponsePage.props';
import { TestResponsePageCombinedProps } from './types';

const TestResponsePage: React.FC<TestResponsePageCombinedProps> = (props) => {
  const {
    className,
    classes,
    breadcrumbItemList,
    textInput,
    button,
    testVariableBlock,
    responseStructureBlock,
    testVariableBlock1,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.testResponsePage;

  return (
    <div className={cx(currentStyle, className)}>
      <div className={cx(styles.pageContent, classes?.pageContent)}>
        <div className={cx(styles.rightContent, classes?.rightContent)}>
          <div className={cx(styles.topContent, classes?.topContent)}>
            <div className={cx(styles.breadcrumbContent, classes?.breadcrumbContent)}>
              <BreadcrumbItemList
                className={cx(styles.breadcrumbItemList, classes?.breadcrumbItemList)}
                {...internalProps.breadcrumbItemList}
                {...breadcrumbItemList} />
            </div>
          </div>
          <div className={cx(styles.bottomContent, classes?.bottomContent)}>
            <div className={cx(styles.headerContent, classes?.headerContent)}>
              <div className={cx(styles.content, classes?.content)}>
                <TextInput
                  className={cx(styles.textInput, classes?.textInput)}
                  {...internalProps.textInput}
                  {...textInput} />
                <Button
                  className={cx(styles.button, classes?.button)}
                  {...internalProps.button}
                  {...button} />
              </div>
              <div className={cx(styles.errorContent, classes?.errorContent)} />
            </div>
            <div className={cx(styles.testResponseContent, classes?.testResponseContent)}>
              <TestVariableBlock
                className={cx(styles.testVariableBlock, classes?.testVariableBlock)}
                {...internalProps.testVariableBlock}
                {...testVariableBlock} />
              <ResponseStructureBlock
                className={cx(styles.responseStructureBlock, classes?.responseStructureBlock)}
                {...internalProps.responseStructureBlock}
                {...responseStructureBlock} />
              <TestVariableBlock
                className={cx(styles.testVariableBlock1, classes?.testVariableBlock1)}
                {...internalProps.testVariableBlock1}
                {...testVariableBlock1} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestResponsePage;
