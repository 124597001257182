import React from 'react';
import cx from 'classnames';

import DropdownButton from '../../atoms/DropdownButton';
import Text from '../../atoms/Text';
import CodeSpace from '../../molecules/CodeSpace';
import InfoTestField from '../../molecules/InfoTestField';
import TabItemList from '../../molecules/TabItemList';
import KeyValueTable from '../../organisms/KeyValueTable';

import styles from './FunctionBlock.module.scss';
import usePresenter from './FunctionBlock.presenter';
import getProps from './FunctionBlock.props';
import { FunctionBlockCombinedProps } from './types';

const FunctionBlock: React.FC<FunctionBlockCombinedProps> = (props) => {
  const {
    state,
    className,
    classes,
    text,
    dropdownButton,
    infoTestField,
    tabItemList,
    dropdownButton1,
    codeSpace,
    keyValueTable,
  } = usePresenter(props);

  const variantName = `${state}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`functionBlock${variantName}`];

  let componentView;

  switch (variantName) {
    case 'Body': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <div className={cx(styles.labelContent, classes?.labelContent)}>
            <Text
              className={cx(styles.text, classes?.text)}
              {...internalProps.text}
              {...text}
            />
          </div>
          <div className={cx(styles.content, classes?.content)}>
            <div className={cx(styles.topContent, classes?.topContent)}>
              <DropdownButton
                className={cx(styles.dropdownButton, classes?.dropdownButton)}
                {...internalProps.dropdownButton}
                {...dropdownButton}
              />
              <InfoTestField
                className={cx(styles.infoTestField, classes?.infoTestField)}
                {...internalProps.infoTestField}
                {...infoTestField}
              />
            </div>
            <div className={cx(styles.bottomContent, classes?.bottomContent)}>
              <TabItemList
                className={cx(styles.tabItemList, classes?.tabItemList)}
                {...internalProps.tabItemList}
                {...tabItemList}
              />
              <DropdownButton
                className={cx(styles.dropdownButton1, classes?.dropdownButton1)}
                {...internalProps.dropdownButton1}
                {...dropdownButton1}
              />
              <CodeSpace
                className={cx(styles.codeSpace, classes?.codeSpace)}
                {...internalProps.codeSpace}
                {...codeSpace}
              />
            </div>
          </div>
        </div>
      );
      break;
    }
    case 'Header':
    case 'Parameter': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <div className={cx(styles.labelContent, classes?.labelContent)}>
            <Text
              className={cx(styles.text, classes?.text)}
              {...internalProps.text}
              {...text}
            />
          </div>
          <div className={cx(styles.content, classes?.content)}>
            <div className={cx(styles.topContent, classes?.topContent)}>
              <DropdownButton
                className={cx(styles.dropdownButton, classes?.dropdownButton)}
                {...internalProps.dropdownButton}
                {...dropdownButton}
              />
              <InfoTestField
                className={cx(styles.infoTestField, classes?.infoTestField)}
                {...internalProps.infoTestField}
                {...infoTestField}
              />
            </div>
            <div className={cx(styles.bottomContent, classes?.bottomContent)}>
              <TabItemList
                className={cx(styles.tabItemList, classes?.tabItemList)}
                {...internalProps.tabItemList}
                {...tabItemList}
              />
              <KeyValueTable
                className={cx(styles.keyValueTable, classes?.keyValueTable)}
                {...internalProps.keyValueTable}
                {...keyValueTable}
              />
            </div>
          </div>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default FunctionBlock;
