import { ButtonProps } from '../../atoms/Button';
import { IconProps } from '../../atoms/Icon';
import { TextProps } from '../../atoms/Text';

type ExpandedSectionHeaderInternalProps = {
  text?: TextProps;
  button?: ButtonProps;
  icon?: IconProps;
};

type ExpandedSectionHeaderPropsValues = {
  'CollapsedDefault': ExpandedSectionHeaderInternalProps;
  'CollapsedVariable': ExpandedSectionHeaderInternalProps;
  'ExpandedDefault': ExpandedSectionHeaderInternalProps;
  'ExpandedVariable': ExpandedSectionHeaderInternalProps;
};

const propValues: ExpandedSectionHeaderPropsValues = {
  'CollapsedDefault': {
    text: {
      type: 'LabelMedium',
      align: 'Left',
      style: 'Semibold600',
      colour: 'NeutralHoverSelected',
    },
    button: {
      type: 'IconClear',
      size: 'S',
      state: 'Default',
      style: 'PrimarySubdued',
    },
  },
  'CollapsedVariable': {
    icon: {
      asset: 'Variable',
      colour: 'VariableSelected',
    },
    text: {
      type: 'LabelMedium',
      align: 'Left',
      style: 'Semibold600',
      colour: 'NeutralHoverSelected',
    },
    button: {
      type: 'IconClear',
      size: 'S',
      state: 'Default',
      style: 'PrimarySubdued',
    },
  },
  'ExpandedDefault': {
    text: {
      type: 'LabelMedium',
      align: 'Left',
      style: 'Semibold600',
      colour: 'NeutralHoverSelected',
    },
    button: {
      type: 'IconClear',
      size: 'S',
      state: 'Default',
      style: 'PrimarySubdued',
    },
  },
  'ExpandedVariable': {
    icon: {
      asset: 'Variable',
      colour: 'VariableSelected',
    },
    text: {
      type: 'LabelMedium',
      align: 'Left',
      style: 'Semibold600',
      colour: 'NeutralHoverSelected',
    },
    button: {
      type: 'IconClear',
      size: 'S',
      state: 'Default',
      style: 'PrimarySubdued',
    },
  },
};

const getProps = (type: string): ExpandedSectionHeaderInternalProps => {
  const values: ExpandedSectionHeaderInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
