import React from 'react';
import cx from 'classnames';

import Text from '../../atoms/Text';
import TabItemList from '../../molecules/TabItemList';
import InputVariableTable from '../../organisms/InputVariableTable';

import styles from './InputBlock.module.scss';
import usePresenter from './InputBlock.presenter';
import getProps from './InputBlock.props';
import { InputBlockCombinedProps } from './types';

const InputBlock: React.FC<InputBlockCombinedProps> = (props) => {
  const {
    className,
    classes,
    text,
    tabItemList,
    inputVariableTable,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.inputBlock;

  return (
    <div className={cx(currentStyle, className)}>
      <div className={cx(styles.labelContent, classes?.labelContent)}>
        <Text
          className={cx(styles.text, classes?.text)}
          {...internalProps.text}
          {...text}
        />
      </div>
      <div className={cx(styles.content, classes?.content)}>
        <TabItemList
          className={cx(styles.tabItemList, classes?.tabItemList)}
          {...internalProps.tabItemList}
          {...tabItemList}
        />
        <InputVariableTable
          className={cx(styles.inputVariableTable, classes?.inputVariableTable)}
          {...internalProps.inputVariableTable}
          {...inputVariableTable}
        />
      </div>
    </div>
  );
};

export default InputBlock;
