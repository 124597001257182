import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { KeyValuePair } from '../../../store/ApiGroupStore/types';
import { typedDeepMerge } from '../../../utils/objectUtils';
import { TableRowValueProps } from '../../molecules/TableRow';

import { KeyValueTableCombinedProps } from './types';
import { ModalPosition } from '../../../modules/common/ModalWrapper/types';
import { getCustomPositionForElementLeft } from '../../../modules/common/ModalWrapper/utils';
import { VariableInput, VariableTypes } from '../InputVariableTable/types';
import { VariableReferenceType } from '../../../modules/variables/types';
import { Constants } from '../../../constants';

const usePresenter = (props: KeyValueTableCombinedProps): KeyValueTableCombinedProps => {
  const {
    onKeyValuePairsChange,
    keyValuePairs,
    validateKeyInputEntry,
    validateValueInputEntry,
    hasKeyValueError,
    inputVariables,
  } = props;
  const { t } = useTranslation();
  const itemRef = useRef<HTMLButtonElement[]>([]);
  const currentRef = useRef<HTMLButtonElement>();
  const [isContextMenuOpen, setIsContextMenuOpen] = useState<boolean>(false);
  const [contextMenuPosition, setContextMenuPosition] = useState<ModalPosition | undefined>();
  const [selectedVariable, setSelectedVariable] = useState<VariableReferenceType>();
  const [currentIndex, setCurrentIndex] = useState<number | undefined>();

  const closeSetVariableMenu = useCallback(() => {
    setIsContextMenuOpen(false);
    setSelectedVariable(undefined);
    setCurrentIndex(undefined);
  }, []);

  const addKeyValuePair = () => {
    const updatedKeyValues = [...keyValuePairs || [], { key: '', value: '' }];
    if (onKeyValuePairsChange) {
      onKeyValuePairsChange(updatedKeyValues);
    }
  };

  const updateKeyValuePair = (keyValueIndex: number, payload: Partial<KeyValuePair>) => {
    const updatedKeyValues = [...keyValuePairs || []];
    updatedKeyValues[keyValueIndex] = {
      ...updatedKeyValues[keyValueIndex],
      ...payload,
    };
    if (onKeyValuePairsChange) {
      onKeyValuePairsChange(updatedKeyValues);
    }
  };

  const updateKeyValuePairFromVariable = (keyValueIndex: number, payload: Partial<KeyValuePair>, variable?: VariableInput) => {
    const updatedKeyValues = [...keyValuePairs || []];
    updatedKeyValues[keyValueIndex] = {
      ...updatedKeyValues[keyValueIndex],
      ...payload,
      inputVariable: variable,
    };

    if (onKeyValuePairsChange) {
      onKeyValuePairsChange(updatedKeyValues);
    }
  };

  const removeKeyValuePair = (keyValuePairIndex: number) => {
    const updatedKeyValuePairs = [...keyValuePairs || []];
    updatedKeyValuePairs.splice(keyValuePairIndex, 1);
    if (onKeyValuePairsChange) {
      onKeyValuePairsChange(updatedKeyValuePairs);
    }
  };

  const removeValue = (keyValuePairIndex: number) => {
    const updatedKeyValues = [...keyValuePairs || []];
    const updatedCurrentKeyValuePair = updatedKeyValues[keyValuePairIndex];
    delete updatedCurrentKeyValuePair.inputVariable;
    updatedKeyValues.splice(keyValuePairIndex, 1, { ...updatedCurrentKeyValuePair, value: '' });
    if (onKeyValuePairsChange) {
      onKeyValuePairsChange(updatedKeyValues);
    }
  };

  const handleRemove = () => {
    if (currentIndex !== undefined) {
      updateKeyValuePairFromVariable(currentIndex, { value: '' });
      closeSetVariableMenu();
    }
  };

  const handleSelectVariable = (variable: VariableReferenceType) => {
    if (currentIndex !== undefined) {
      updateKeyValuePairFromVariable(currentIndex, { value: '' }, {
        name: variable.name,
        type: variable.type as VariableTypes,
      });
      closeSetVariableMenu();
    }
  };

  useEffect(() => {
    if (keyValuePairs?.length) {
      keyValuePairs.forEach((keyValuePair, index) => {
        const isValueFound = inputVariables?.filter((variable) => variable.name === keyValuePair.inputVariable?.name);
        if ((!isValueFound || !isValueFound.length) && keyValuePair.inputVariable) {
          removeValue(index);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputVariables?.length, keyValuePairs]);

  const hasErrorState = (value: string) => {
    return !value.trim() && hasKeyValueError;
  };

  const openSetVariableMenu = useCallback((index: number, inputVariable?: VariableInput) => {
    setCurrentIndex(index);
    if (inputVariable) {
      setSelectedVariable({
        id: inputVariable.name,
        scope: 'Global',
        name: inputVariable.name,
        type: inputVariable.type || '',
      });
    }
    currentRef.current = itemRef.current[index];
    setContextMenuPosition(getCustomPositionForElementLeft(currentRef));
    setIsContextMenuOpen(true);
  }, []);

  return {
    ...typedDeepMerge(props, {
      state: keyValuePairs?.length ? 'Filled' : 'Empty',
      button: {
        onClick: addKeyValuePair,
      },
      tableRowList: {
        tableRows: keyValuePairs?.map((keyValuePair: KeyValuePair, index: number) => {
          return {
            style: 'KeyValue',
            keyInput: {
              state: hasErrorState(keyValuePair.key) ? 'Error' : 'Default',
              textValue: keyValuePair.key,
              onValidateText: validateKeyInputEntry,
              onTextChanged: (text: string) => {
                updateKeyValuePair(index, { key: text });
              },
            },
            valueInput: {
              state: hasErrorState(keyValuePair.value) ? 'Error' : 'Default',
              textValue: keyValuePair.value,
              onValidateText: validateValueInputEntry,
              onTextChanged: (text: string) => {
                updateKeyValuePair(index, { value: text });
              },
              maxLength: Constants.LIMITS.KEY_VALUE_INPUT_MAX_LENGTH,
            },
            button: {
              icon: {
                asset: 'Close',
              },
              onClick: () => {
                removeKeyValuePair(index);
              },
            },
            variableValueInput: {
              state: keyValuePair.inputVariable ? 'Uploaded' : 'Editable',
              style: 'Variable',
              type: 'VariableButton',
              button: {
                type: 'Icon',
                onClick: () => { openSetVariableMenu(index, keyValuePair.inputVariable); },
                icon: {
                  asset: 'Variable',
                  colour: keyValuePair.inputVariable || (isContextMenuOpen && currentIndex === index) ? 'VariableSelected' : 'NeutralDefault',
                },
                ref: (el: HTMLButtonElement) => { itemRef.current.splice(index, 1, el); },
              },
              inputField: {
                disabled: keyValuePair.inputVariable,
                state: hasErrorState(keyValuePair.value) ? 'Error' : 'Default',
                textValue: keyValuePair.value,
                onValidateText: validateValueInputEntry,
                onTextChanged: (text: string) => {
                  updateKeyValuePair(index, { value: text });
                },
              },
              propertyItem: {
                text: {
                  value: `{${keyValuePair.inputVariable?.name}}`,
                },
                button: {
                  type: 'Icon',
                  icon: {
                    asset: 'Close',
                  },
                  onClick: () => {
                    updateKeyValuePairFromVariable(index, { value: '' });
                  },
                },
              },
            },
          } as TableRowValueProps;
        }),
      },
      setVariableMenuModalProps: {
        show: isContextMenuOpen,
        customPosition: contextMenuPosition,
        onHide: closeSetVariableMenu,
        modalProps: {
          closeModal: closeSetVariableMenu,
          variables: inputVariables?.map((item): VariableReferenceType => {
            return {
              id: item.name,
              scope: 'Global',
              name: item.name,
              type: item.type || '',
            };
          }),
          selectedVariable,
          onClearClicked: handleRemove,
          onVariableSelected: handleSelectVariable,
        },
      },
    }),
  };
};

export default usePresenter;
