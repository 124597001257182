import { TextProps } from '../Text';

type LogicTabInternalProps = {
  text?: TextProps;
};

type LogicTabPropsValues = {
  'Default': LogicTabInternalProps;
  'Selected': LogicTabInternalProps;
};

const propValues: LogicTabPropsValues = {
  Default: {
    text: {
      type: 'LabelMedium',
      align: 'Left',
      style: 'Regular400',
      colour: 'NeutralHoverSelected',
    },
  },
  Selected: {
    text: {
      type: 'LabelMedium',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralHoverSelected',
    },
  },
};

const getProps = (type: string): LogicTabInternalProps => {
  const values: LogicTabInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
