import React from 'react';
import cx from 'classnames';

import DuoToneTextDefault from '../DuoToneTextDefault';

import styles from './CanvasResolutionSpec.module.scss';
import usePresenter from './CanvasResolutionSpec.presenter';
import getProps from './CanvasResolutionSpec.props';
import { CanvasResolutionSpecCombinedProps } from './types';

const CanvasResolutionSpec: React.FC<CanvasResolutionSpecCombinedProps> = (props) => {
  const {
    className,
    classes,
    breakpointScreenSize,
    scale,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.canvasResolutionSpec;

  return (
    <div className={cx(currentStyle, className)}>
      <DuoToneTextDefault
        className={cx(styles.breakpointScreenSize, classes?.breakpointScreenSize)}
        {...internalProps.breakpointScreenSize}
        {...breakpointScreenSize}
      />
      <DuoToneTextDefault
        className={cx(styles.scale, classes?.scale)}
        {...internalProps.scale}
        {...scale}
      />
    </div>
  );
};

export default CanvasResolutionSpec;
