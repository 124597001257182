import { BreadcrumbItemProps } from '../../atoms/BreadcrumbItem';

type BreadcrumbItemListInternalProps = {
  breadcrumbItem?: BreadcrumbItemProps;
};

type BreadcrumbItemListPropsValues = {
  '': BreadcrumbItemListInternalProps;
};

const propValues: BreadcrumbItemListPropsValues = {
  '': {
    breadcrumbItem: {
      state: 'Selected',
    },
  },
};

const getProps = (type: string): BreadcrumbItemListInternalProps => {
  const values: BreadcrumbItemListInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
