import cx from 'classnames';
import customStyles from './Custom.module.scss';
import type { TableRowCombinedProps } from './types';

const usePresenter = (props: TableRowCombinedProps): TableRowCombinedProps => {
  const { type, style, state } = props;
  return {
    ...props,
    className: cx(
      props.className,
      customStyles[`tableRow${type}${style}${state}`],
    ),
    classes: {
      ...props.classes,
      variableName: cx(props.classes?.variableName, customStyles.variableName),
      variableValueInput: cx(props.classes?.variableValueInput, customStyles.variableValueInput),
    },
  };
};

export default usePresenter;
