import React from 'react';
import cx from 'classnames';
import { Handle, Position } from 'reactflow';

import styles from './AddAction.module.scss';
import usePresenter from './AddAction.presenter';
import getProps from './AddAction.props';
import { AddActionCombinedProps } from './types';
import Button from '../Button';

const AddAction: React.FC<AddActionCombinedProps> = (props) => {
  const {
    data: {
      className,
      classes,
      button,
    },
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.addAction;

  return (
    <div className={cx(currentStyle, className)}>
      <Handle
        className={styles.hiddenHandler}
        type="target"
        position={Position.Left}
        isConnectable={false}
      />
      <Button
        className={cx(styles.button, classes?.button)}
        {...internalProps.button}
        {...button}
      />
    </div>
  );
};

export default AddAction;
