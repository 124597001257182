import axios from 'axios';
import { completeMultipartUpload, createMultipartUpload } from './assets.repository';
import { CompletedPart, MediaUploadType } from './types';

export const PART_SIZE = 1024 * 1024 * 200; // 200MB

export const uploadFile = async (
  file: File,
  mediaType: MediaUploadType,
): Promise<string> => {
  const { size, name, type } = file;
  const {
    uuid, key, signedPartUrls, uploadId,
  } = await createMultipartUpload({
    fileMimeType: type,
    fileSize: size,
    fileName: name,
    parts: Math.ceil(size / PART_SIZE),
    mediaType,
  });

  const keys = Object.keys(signedPartUrls);
  const completedParts: CompletedPart[] = [];

  for (let i = 0; i < keys.length; i += 1) {
    const index = Number(keys[i]);
    const start = index * PART_SIZE;
    const end = (index + 1) * PART_SIZE;
    const blob = index < keys.length
      ? file.slice(start, end)
      : file.slice(start);

    // send upload parts request to s3 bucket and use ETag from response for completeMultipartUpload
    const part = await axios.put(signedPartUrls[index], blob); // eslint-disable-line no-await-in-loop
    completedParts.push({ ETag: part.headers.etag as string, PartNumber: i + 1 });
  }

  const { completeUrl } = await completeMultipartUpload({
    uuid,
    key,
    completedParts,
    uploadId,
    mediaType,
  });

  return completeUrl;
};
