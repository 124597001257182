import { InputFieldProps } from '../../atoms/InputField';
import { LineNumberListProps } from '../LineNumberList';

type CodeSpaceInternalProps = {
  lineNumberList?: LineNumberListProps;
  inputField?: InputFieldProps;
};

type CodeSpacePropsValues = {
  '': CodeSpaceInternalProps;
};

const propValues: CodeSpacePropsValues = {
  '': {
    lineNumberList: {
    },
    inputField: {
      type: 'CodeTextArea',
      size: 'Medium',
      state: 'Default',
    },
  },
};

const getProps = (type: string): CodeSpaceInternalProps => {
  const values: CodeSpaceInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
