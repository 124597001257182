import React from 'react';
import usePresenter from './PreviewImageElement.presenter';
import type { PreviewImageElementProps } from './types';

const PreviewImageElement: React.FC<PreviewImageElementProps> = (props) => {
  const { element, style, utils, className, ...otherProps } = usePresenter(props);

  return (
    // <div
    //   className={cx(styles.PreviewImageElement, className)}
    //   style={style}
    //   {...otherProps}
    // >
    //   <img
    //     src={element.settings.imageUrl}
    //     alt={element.settings.altText}
    //     className={styles.img}
    //   />
    // </div>
    <img
      src={utils?.getImageUrl?.(element)}
      alt={utils?.getImageAltText?.(element)}
      style={style}
      {...otherProps}
    />
  );
};

export default PreviewImageElement;
