import { DropdownButtonProps } from '../../atoms/DropdownButton';
import { EmptyStateInfoProps } from '../../atoms/EmptyStateInfo';
import { ReplaceButtonProps } from '../../atoms/ReplaceButton';
import { RowLabelProps } from '../../atoms/RowLabel';
import { PanelHeaderProps } from '../../molecules/PanelHeader';
import { ReplaceRowProps } from '../../molecules/ReplaceRow';
import { UpdateValueProps } from '../../molecules/UpdateValue';

type ActionPanelInternalProps = {
  panelHeader?: PanelHeaderProps;
  rowLabel?: RowLabelProps;
  dropdownButton?: DropdownButtonProps;
  rowLabel1?: RowLabelProps;
  dropdownButton1?: DropdownButtonProps;
  rowLabel2?: RowLabelProps;
  dropdownButton2?: DropdownButtonProps;
  emptyStateInfo?: EmptyStateInfoProps;
  rowLabel3?: RowLabelProps;
  dropdownButton3?: DropdownButtonProps;
  replaceRow?: ReplaceRowProps;
  replaceButton?: ReplaceButtonProps;
  updateValue?: UpdateValueProps;
};

type ActionPanelPropsValues = {
  'CallAPIExpanded': ActionPanelInternalProps;
  'EmptyCollapsed': ActionPanelInternalProps;
  'EmptyStateDefault': ActionPanelInternalProps;
  'NagivatePageExpanded': ActionPanelInternalProps;
  'NavigateDefaultExpanded': ActionPanelInternalProps;
  'NavigateURLExpanded': ActionPanelInternalProps;
  'UnselectedExpanded': ActionPanelInternalProps;
  'UpdateVarExpanded': ActionPanelInternalProps;
};

const propValues: ActionPanelPropsValues = {
  'CallAPIExpanded': {
    panelHeader: {
      state: 'Expanded',
      type: 'Default',
    },
    rowLabel: {
      state: 'Default',
      style: 'Default',
      type: 'Panel',
    },
    dropdownButton: {
      type: 'Default',
      style: 'Default',
      state: 'Collapsed',
      colour: 'Default',
    },
    rowLabel1: {
      state: 'Default',
      style: 'Default',
      type: 'Panel',
    },
    dropdownButton1: {
      type: 'Default',
      style: 'Default',
      state: 'Collapsed',
      colour: 'Default',
    },
    rowLabel2: {
      state: 'Default',
      style: 'Default',
      type: 'Panel',
    },
    dropdownButton2: {
      type: 'Default',
      style: 'Default',
      state: 'Collapsed',
      colour: 'Default',
    },
  },
  'EmptyCollapsed': {
    panelHeader: {
      state: 'Collapsed',
      type: 'Default',
    },
  },
  'EmptyStateDefault': {
    emptyStateInfo: {
    },
  },
  'NagivatePageExpanded': {
    panelHeader: {
      state: 'Expanded',
      type: 'Default',
    },
    rowLabel: {
      state: 'Default',
      style: 'Default',
      type: 'Panel',
    },
    dropdownButton: {
      type: 'Default',
      style: 'Default',
      state: 'Collapsed',
      colour: 'Default',
    },
    rowLabel1: {
      state: 'Default',
      style: 'Default',
      type: 'Panel',
    },
    dropdownButton1: {
      type: 'Default',
      style: 'Default',
      state: 'Collapsed',
      colour: 'Default',
    },
    rowLabel2: {
      state: 'Default',
      style: 'Default',
      type: 'Panel',
    },
    dropdownButton2: {
      type: 'Default',
      style: 'Default',
      state: 'Collapsed',
      colour: 'Default',
    },
    rowLabel3: {
      state: 'Default',
      style: 'Default',
      type: 'Panel',
    },
    dropdownButton3: {
      type: 'Default',
      style: 'Default',
      state: 'Collapsed',
      colour: 'Default',
    },
  },
  'NavigateDefaultExpanded': {
    panelHeader: {
      state: 'Expanded',
      type: 'Default',
    },
    rowLabel: {
      state: 'Default',
      style: 'Default',
      type: 'Panel',
    },
    dropdownButton: {
      type: 'Default',
      style: 'Default',
      state: 'Collapsed',
      colour: 'Default',
    },
    rowLabel1: {
      state: 'Default',
      style: 'Default',
      type: 'Panel',
    },
    dropdownButton1: {
      type: 'Default',
      style: 'Default',
      state: 'Collapsed',
      colour: 'Default',
    },
  },
  'NavigateURLExpanded': {
    panelHeader: {
      state: 'Expanded',
      type: 'Default',
    },
    rowLabel: {
      state: 'Default',
      style: 'Default',
      type: 'Panel',
    },
    dropdownButton: {
      type: 'Default',
      style: 'Default',
      state: 'Collapsed',
      colour: 'Default',
    },
    rowLabel1: {
      state: 'Default',
      style: 'Default',
      type: 'Panel',
    },
    dropdownButton1: {
      type: 'Default',
      style: 'Default',
      state: 'Collapsed',
      colour: 'Default',
    },
    replaceRow: {
      type: 'VariableText',
      state: 'Editable',
      style: 'Variable',
    },
    rowLabel2: {
      state: 'Default',
      style: 'Default',
      type: 'Panel',
    },
    dropdownButton2: {
      type: 'Default',
      style: 'Default',
      state: 'Collapsed',
      colour: 'Default',
    },
  },
  'UnselectedExpanded': {
    panelHeader: {
      state: 'Expanded',
      type: 'Default',
    },
    rowLabel: {
      state: 'Default',
      style: 'Default',
      type: 'Panel',
    },
    dropdownButton: {
      type: 'Default',
      style: 'Default',
      state: 'Collapsed',
      colour: 'Default',
    },
  },
  'UpdateVarExpanded': {
    panelHeader: {
      state: 'Expanded',
      type: 'Default',
    },
    rowLabel: {
      state: 'Default',
      style: 'Default',
      type: 'Panel',
    },
    dropdownButton: {
      type: 'Default',
      style: 'Default',
      state: 'Collapsed',
      colour: 'Default',
    },
    rowLabel1: {
      state: 'Default',
      style: 'Default',
      type: 'Panel',
    },
    replaceButton: {
      state: 'Default',
      type: 'TextOnly',
    },
    updateValue: {
    },
  },
};

const getProps = (type: string): ActionPanelInternalProps => {
  const values: ActionPanelInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
