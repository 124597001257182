import { DividerProps } from '../../atoms/Divider';
import { SelectedContainerProps } from '../../molecules/SelectedContainer';
import { StyleSettingsToggleProps } from '../StyleSettingsToggle';

type StyleSettingsHeaderInternalProps = {
  styleSettingsToggle?: StyleSettingsToggleProps;
  selectedContainer?: SelectedContainerProps;
  divider?: DividerProps;
};

type StyleSettingsHeaderPropsValues = {
  '': StyleSettingsHeaderInternalProps;
};

const propValues: StyleSettingsHeaderPropsValues = {
  '': {
    styleSettingsToggle: {
    },
    selectedContainer: {
    },
    divider: {
      type: 'Horizontal',
      colour: 'Neutral1900',
    },
  },
};

const getProps = (type: string): StyleSettingsHeaderInternalProps => {
  const values: StyleSettingsHeaderInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
