import React from 'react';
import cx from 'classnames';

import TextCard from '../TextCard';

import styles from './TextCardList.module.scss';
import usePresenter from './TextCardList.presenter';
import getProps from './TextCardList.props';
import { TextCardListCombinedProps } from './types';

const TextCardList: React.FC<TextCardListCombinedProps> = (props) => {
  const {
    className,
    classes,
    textCards,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.textCardList;

  const textCardViews = textCards?.map((textCard, index) => (
    <TextCard
      key={index}
      className={cx(styles.textCard, classes?.textCard)}
      {...internalProps.textCard}
      {...textCard}
    />
  )) || [];

  return (
    <div className={cx(currentStyle, className)}>
      {textCardViews}
    </div>
  );
};

export default TextCardList;
