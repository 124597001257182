import React from 'react';
import PreviewElementChildrenRenderer from '../../renderers/PreviewElementChildrenRenderer';
import usePresenter from './PreviewContainerElement.presenter';
import type { PreviewContainerElementProps } from './types';

const PreviewContainerElement: React.FC<PreviewContainerElementProps> = (props) => {
  const { element, style, ...otherProps } = usePresenter(props);

  return (
    <div
      style={style}
      {...otherProps}
    >
      <PreviewElementChildrenRenderer parentElement={element} />
    </div>
  );
};

export default PreviewContainerElement;
