import React from 'react';
import cx from 'classnames';

import Button from '../../atoms/Button';
import SectionLabel from '../../molecules/SectionLabel';
import TextCardList from '../../molecules/TextCardList';

import styles from './GroupsPanel.module.scss';
import usePresenter from './GroupsPanel.presenter';
import getProps from './GroupsPanel.props';
import { GroupsPanelCombinedProps } from './types';

const GroupsPanel: React.FC<GroupsPanelCombinedProps> = (props) => {
  const {
    className,
    classes,
    sectionLabel,
    button,
    textCardList,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.groupsPanel;

  return (
    <div className={cx(currentStyle, className)}>
      <SectionLabel
        className={cx(styles.sectionLabel, classes?.sectionLabel)}
        {...internalProps.sectionLabel}
        {...sectionLabel}
      />
      <div className={cx(styles.groupContent, classes?.groupContent)}>
        <Button
          className={cx(styles.button, classes?.button)}
          {...internalProps.button}
          {...button}
        />
        <TextCardList
          className={cx(styles.textCardList, classes?.textCardList)}
          {...internalProps.textCardList}
          {...textCardList}
        />
      </div>
    </div>
  );
};

export default GroupsPanel;
