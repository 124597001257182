import { DividerProps } from '../../atoms/Divider';
import { ButtonProps } from '../../atoms/Button';
import { TextProps } from '../../atoms/Text';
import { SpindlLogoProps } from '../../atoms/SpindlLogo';
import { BreakpointMenuProps } from '../../molecules/BreakpointMenu';

type TopNavInternalProps = {
  spindlLogo?: SpindlLogoProps;
  divider?: DividerProps;
  text?: TextProps;
  divider1?: DividerProps;
  button?: ButtonProps;
  divider11?: DividerProps;
  breakpointMenu?: BreakpointMenuProps;
  divider2?: DividerProps;
  saveStatus?: TextProps;
  undo?: ButtonProps;
  redo?: ButtonProps;
  divider3?: DividerProps;
  preview?: ButtonProps;
  divider4?: DividerProps;
  publish?: ButtonProps;
  bottomDivider?: DividerProps;
};

type TopNavPropsValues = {
  'Default': TopNavInternalProps;
  'LogoAppName': TopNavInternalProps;
};

const propValues: TopNavPropsValues = {
  'Default': {
    spindlLogo: {
    },
    divider: {
      type: 'Vertical',
      colour: 'Neutral1900',
    },
    text: {
      type: 'LabelSmall',
      align: 'Center',
      style: 'Medium500',
      colour: 'NeutralDefault',
    },
    divider1: {
      type: 'Vertical',
      colour: 'Neutral1900',
    },
    button: {
      type: 'PageName',
      size: 'M',
      state: 'Default',
      style: 'PrimarySubdued',
    },
    divider11: {
      type: 'Vertical',
      colour: 'Neutral1900',
    },
    breakpointMenu: {
    },
    divider2: {
      type: 'Vertical',
      colour: 'Neutral1900',
    },
    saveStatus: {
      type: 'LabelSmall',
      align: 'Center',
      style: 'Medium500',
      colour: 'NeutralDefault',
    },
    undo: {
      type: 'Icon',
      size: 'L',
      state: 'Default',
      style: 'PrimarySubdued',
    },
    redo: {
      type: 'Icon',
      size: 'L',
      state: 'Default',
      style: 'PrimarySubdued',
    },
    divider3: {
      type: 'Vertical',
      colour: 'Neutral1900',
    },
    preview: {
      type: 'Icon',
      size: 'L',
      state: 'Default',
      style: 'PrimarySubdued',
    },
    divider4: {
      type: 'Vertical',
      colour: 'Neutral1900',
    },
    publish: {
      type: 'Text',
      size: 'M',
      state: 'Default',
      style: 'PrimarySubdued',
    },
    bottomDivider: {
      type: 'Horizontal',
      colour: 'Neutral1900',
    },
  },
  'LogoAppName': {
    spindlLogo: {
    },
    divider: {
      type: 'Vertical',
      colour: 'Neutral1900',
    },
    text: {
      type: 'LabelSmall',
      align: 'Center',
      style: 'Medium500',
      colour: 'NeutralDefault',
    },
    divider1: {
      type: 'Vertical',
      colour: 'Neutral1900',
    },
    bottomDivider: {
      type: 'Horizontal',
      colour: 'Neutral1900',
    },
  },
};

const getProps = (type: string): TopNavInternalProps => {
  const values: TopNavInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
