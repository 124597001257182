import React from 'react';
import cx from 'classnames';

import Button from '../../atoms/Button';
import DropdownButton from '../../atoms/DropdownButton';
import Text from '../../atoms/Text';

import styles from './TestResponseItem.module.scss';
import usePresenter from './TestResponseItem.presenter';
import getProps from './TestResponseItem.props';
import { TestResponseItemCombinedProps } from './types';

const TestResponseItem: React.FC<TestResponseItemCombinedProps> = (props) => {
  const {
    type,
    className,
    classes,
    button,
    name,
    dropdownButton,
    customCssOverrides,
  } = usePresenter(props);

  const variantName = `${type}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`testResponseItem${variantName}`];

  let componentView;

  switch (variantName) {
    case 'Collapsed':
    case 'Expanded': {
      componentView = (
        // MANUAL OVERRIDE
        <div className={cx(currentStyle, className)} style={customCssOverrides}>
          <Button
            className={cx(styles.button, classes?.button)}
            {...internalProps.button}
            {...button}/>
          <div className={cx(styles.textContent, classes?.textContent)}>
            <Text
              className={cx(styles.name, classes?.name)}
              {...internalProps.name}
              {...name}/>
          </div>
          <div className={cx(styles.buttonContent, classes?.buttonContent)}>
            <DropdownButton
              className={cx(styles.dropdownButton, classes?.dropdownButton)}
              {...internalProps.dropdownButton}
              {...dropdownButton}/>
          </div>
        </div>
      );
      break;
    }
    case 'Default': {
      componentView = (
        // MANUAL OVERRIDE
        <div className={cx(currentStyle, className)} style={customCssOverrides}>
          <div className={cx(styles.textContent, classes?.textContent)}>
            <Text
              className={cx(styles.name, classes?.name)}
              {...internalProps.name}
              {...name}/>
          </div>
          <div className={cx(styles.buttonContent, classes?.buttonContent)}>
            <DropdownButton
              className={cx(styles.dropdownButton, classes?.dropdownButton)}
              {...internalProps.dropdownButton}
              {...dropdownButton}/>
          </div>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default TestResponseItem;
