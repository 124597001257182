import { Constants, DEFAULT_OPACITY } from '../constants';
import { Unit } from '../store/types';

export const valueWithFallback = (
  value: string | number | undefined,
  fallback: string | number,
) => {
  return (value === undefined || value === '') ? `${fallback}` : `${value}`;
};

export const isValidText = (text: string, invalidExpressions: RegExp[]): boolean => {
  let isValid = true;
  for (let i = 0; i < invalidExpressions.length; i++) {
    isValid = !text.match(invalidExpressions[i]);
    if (!isValid) break;
  }
  return isValid;
};

// for catching invalid or negative number, e.g. -13 or 4i
const POSITIVE_NUMBER = /[^0-9.]/g;
// for catching invalid number
const NEGATIVE_NUMBER = /[^0-9.-]/g;
// for catching invalid negative number, e.g. 232-32
const FIRST_MINUS = /^[-0-9]\d*-/g;
// for catching any dots on real, like 0.1, 0..1 or 0.1.3
const ANY_DOTS = /([.]{1,})/g;
// for catching second dot on real, like 0..1 or 0.1.3
const MULTIPLE_DOTS = /([.]{2,})|(\..*\.)/g;

// to catch any invalid hexadecimal characters, such as 1g
const INVALID_HEXADECIMAL = /[^0-9a-fA-F]/;

// to catch any - sign after decimal, such as 1.-8, 1.8-
const MINUS_SIGN_AFTER_DOT = /(?:\.-(?=\D|$)|\d+-)/g;

export const numberValidation = (value: string): boolean => {
  return isValidText(value, [NEGATIVE_NUMBER, FIRST_MINUS, MULTIPLE_DOTS, MINUS_SIGN_AFTER_DOT]);
};

export const unitValueValidation = (value: string, unit: Unit, allowNegative?: boolean): boolean => {
  const numberRegexes = allowNegative ? [NEGATIVE_NUMBER, FIRST_MINUS] : [POSITIVE_NUMBER];
  switch (unit) {
    case 'px': {
      return isValidText(value, [...numberRegexes, ANY_DOTS]);
    }
    case 'vh':
    case 'vw':
    case '%': {
      return isValidText(value, [...numberRegexes, MULTIPLE_DOTS]);
    }
    case 'fill':
    case 'hug':
    case 'none':
      return value === unit;
    default:
      break;
  }
  return false;
};

export const pixelInputValidation = (value: string, allowNegative?: boolean): boolean => {
  return unitValueValidation(value, 'px', allowNegative);
};

export const hexCodeInputValidation = (value: string): boolean => {
  if (value.length > 6 || !isValidText(value, [INVALID_HEXADECIMAL])) {
    return false;
  }
  return true;
};

export const opacityInputValidation = (value: string): boolean => {
  if (!unitValueValidation(value, '%')) {
    return false;
  }
  const opacityNum = value === '.' ? 0 : Number(value);
  // Prevent a non-numeric inputs, empty string will be treated as 0
  // Negatives are not allowed, so dashes will not be accepted
  // if (Number.isNaN(opacityNum)) {
  //   return false;
  // }
  if (opacityNum < DEFAULT_OPACITY.min || opacityNum > DEFAULT_OPACITY.max) {
    return false;
  }
  return true;
};

export const validateGroupHeaderKeyInputEntry = (text: string) => (
  isValidText(text, [Constants.REGEX.NOT_ALPHANUMERIC_DASH_AND_UNDERSCORE] as RegExp[])
);

export const validateGroupHeaderValueInputEntry = (text: string) => (
  isValidText(text, [
    Constants.REGEX.HEADERS_ALLOWED_CHARACTERS,
    Constants.REGEX.HEADER_VALUE_LENGTH_NOT_MORE_THAN_2000_CHARACTERS,
  ] as RegExp[])
);
