import { RowLabelProps } from '../../atoms/RowLabel';
import { ReplaceRowProps } from '../../molecules/ReplaceRow';
import { ToggleItemListProps } from '../../molecules/ToggleItemList';

type SettingsRowInternalProps = {
  rowLabel?: RowLabelProps;
  toggleItemList?: ToggleItemListProps;
  replaceRow?: ReplaceRowProps;
};

type SettingsRowPropsValues = {
  'Toggle': SettingsRowInternalProps;
  'Variable': SettingsRowInternalProps;
};

const propValues: SettingsRowPropsValues = {
  'Toggle': {
    rowLabel: {
      state: 'Default',
      style: 'Default',
      type: 'Panel',
    },
    toggleItemList: {
      size: 'Small',
      type: 'Text',
      style: 'Default',
    },
  },
  'Variable': {
    replaceRow: {
      type: 'VariableText',
      state: 'Editable',
      style: 'VariableVertical',
    },
  },
};

const getProps = (type: string): SettingsRowInternalProps => {
  const values: SettingsRowInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
