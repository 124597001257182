import { LogicSettingsPanelProps } from '../../organisms/LogicSettingsPanel';
import { SettingsPanelProps } from '../../organisms/SettingsPanel';
import { StylePanelProps } from '../../organisms/StylePanel';
import { StyleSettingsHeaderProps } from '../../organisms/StyleSettingsHeader';

type StyleSettingsBlockInternalProps = {
  logicSettingsPanel?: LogicSettingsPanelProps;
  styleSettingsHeader?: StyleSettingsHeaderProps;
  settingsPanel?: SettingsPanelProps;
  stylePanel?: StylePanelProps;
};

type StyleSettingsBlockPropsValues = {
  'LogicSettingsPanel': StyleSettingsBlockInternalProps;
  'SettingsPanel': StyleSettingsBlockInternalProps;
  'StylePanel': StyleSettingsBlockInternalProps;
};

const propValues: StyleSettingsBlockPropsValues = {
  'LogicSettingsPanel': {
    logicSettingsPanel: {
    },
  },
  'SettingsPanel': {
    styleSettingsHeader: {
    },
    settingsPanel: {
    },
  },
  'StylePanel': {
    styleSettingsHeader: {
    },
    stylePanel: {
    },
  },
};

const getProps = (type: string): StyleSettingsBlockInternalProps => {
  const values: StyleSettingsBlockInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
