import React from 'react';
import cx from 'classnames';

import Button from '../../atoms/Button';
import Icon from '../../atoms/Icon';
import Text from '../../atoms/Text';

import styles from './ExpandedSectionHeader.module.scss';
import usePresenter from './ExpandedSectionHeader.presenter';
import getProps from './ExpandedSectionHeader.props';
import { ExpandedSectionHeaderCombinedProps } from './types';

const ExpandedSectionHeader: React.FC<ExpandedSectionHeaderCombinedProps> = (props) => {
  const {
    state,
    style,
    className,
    classes,
    text,
    button,
    icon,
  } = usePresenter(props);

  const variantName = `${state}${style}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`expandedSectionHeader${variantName}`];

  let componentView;

  switch (variantName) {
    case 'CollapsedDefault':
    case 'ExpandedDefault': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <Text
            className={cx(styles.text, classes?.text)}
            {...internalProps.text}
            {...text}/>
          <Button
            className={cx(styles.button, classes?.button)}
            {...internalProps.button}
            {...button}/>
        </div>
      );
      break;
    }
    case 'CollapsedVariable':
    case 'ExpandedVariable': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <div className={cx(styles.headingContent, classes?.headingContent)}>
            <Icon
              className={cx(styles.icon, classes?.icon)}
              {...internalProps.icon}
              {...icon}/>
            <Text
              className={cx(styles.text, classes?.text)}
              {...internalProps.text}
              {...text}/>
          </div>
          <Button
            className={cx(styles.button, classes?.button)}
            {...internalProps.button}
            {...button}/>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default ExpandedSectionHeader;
