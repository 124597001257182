import Default from './Default.svg';
import HPCC from './HPCC.svg';
import HSAC from './HSAC.svg';
import HSBC from './HSBC.svg';
import VPCC from './VPCC.svg';
import VSAC from './VSAC.svg';
import VSBC from './VSBC.svg';

export const CenterCenter = {
  Default,
  'horizontal-packed-center-center': HPCC,
  'horizontal-space-around-center-none': HSAC,
  'horizontal-space-between-center-none': HSBC,
  'vertical-packed-center-center': VPCC,
  'vertical-space-around-none-center': VSAC,
  'vertical-space-between-none-center': VSBC,
};
