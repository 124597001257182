import React from 'react';
import cx from 'classnames';

import Button from '../../atoms/Button';

import styles from './AlignmentGrid.module.scss';
import usePresenter from './AlignmentGrid.presenter';
import getProps from './AlignmentGrid.props';
import { AlignmentGridCombinedProps } from './types';

const AlignmentGrid: React.FC<AlignmentGridCombinedProps> = (props) => {
  const {
    className,
    classes,
    button,
    button1,
    button2,
    button3,
    button4,
    button5,
    button6,
    button7,
    button8,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.alignmentGrid;

  return (
    <div className={cx(currentStyle, className)}>
      <div className={cx(styles.topContent, classes?.topContent)}>
        <Button
          className={cx(styles.button, classes?.button)}
          {...internalProps.button}
          {...button}
        />
        <Button
          className={cx(styles.button1, classes?.button1)}
          {...internalProps.button1}
          {...button1}
        />
        <Button
          className={cx(styles.button2, classes?.button2)}
          {...internalProps.button2}
          {...button2}
        />
      </div>
      <div className={cx(styles.middleContent, classes?.middleContent)}>
        <Button
          className={cx(styles.button3, classes?.button3)}
          {...internalProps.button3}
          {...button3}
        />
        <Button
          className={cx(styles.button4, classes?.button4)}
          {...internalProps.button4}
          {...button4}
        />
        <Button
          className={cx(styles.button5, classes?.button5)}
          {...internalProps.button5}
          {...button5}
        />
      </div>
      <div className={cx(styles.bottomContent, classes?.bottomContent)}>
        <Button
          className={cx(styles.button6, classes?.button6)}
          {...internalProps.button6}
          {...button6}
        />
        <Button
          className={cx(styles.button7, classes?.button7)}
          {...internalProps.button7}
          {...button7}
        />
        <Button
          className={cx(styles.button8, classes?.button8)}
          {...internalProps.button8}
          {...button8}
        />
      </div>
    </div>
  );
};

export default AlignmentGrid;
