import React from 'react';
import cx from 'classnames';

import styles from './Underline.module.scss';
import usePresenter from './Underline.presenter';
import getProps from './Underline.props';
import { UnderlineCombinedProps } from './types';

const Underline: React.FC<UnderlineCombinedProps> = (props) => {
  const {
    className,
    classes,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.underline;

  return (
    <div className={cx(currentStyle, className)} />
  );
};

export default Underline;
