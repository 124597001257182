import React from 'react';
import cx from 'classnames';
import { Handle, Position } from 'reactflow';

import Text from '../Text';

import styles from './FlowStartCard.module.scss';
import usePresenter from './FlowStartCard.presenter';
import getProps from './FlowStartCard.props';
import { FlowStartCardCombinedProps } from './types';

const FlowStartCard: React.FC<FlowStartCardCombinedProps> = (props) => {
  const {
    data: {
      className,
      classes,
      text,
    },
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.flowStartCard;

  return (
    <div className={cx(currentStyle, className)}>
      <Handle
        className={styles.hiddenHandler}
        type="source"
        position={Position.Right}
        isConnectable={false}
      />
      <Text
        className={cx(styles.text, classes?.text)}
        {...internalProps.text}
        {...text}
      />
    </div>
  );
};

export default FlowStartCard;
