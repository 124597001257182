import React, { useContext, useEffect, useRef } from 'react';
import { BaseModalProps } from '../types';
import { InputModalProps, ModalContextValue } from './ModalContext';

export default function useModal<ModalPropsType extends BaseModalProps>(
  ModalContext: React.Context<ModalContextValue<ModalPropsType>>,
  // eslint-disable-next-line @typescript-eslint/ban-types
  getProps?: Function | (() => InputModalProps<ModalPropsType>),
  deps?: readonly any[],
) {
  const modalContainerRef = useRef<HTMLDivElement>(null);
  const { updateProps, openModal } = useContext(ModalContext);

  useEffect(() => {
    const newProps: ModalPropsType | undefined = getProps ? getProps() : undefined;
    updateProps(newProps);
    // console.log(newProps);
  }, [getProps, updateProps, deps]);

  return {
    modalContainerRef,
    openModal,
  };
}
