import type { ButtonCombinedProps } from '../../atoms/Button/types';
import type { IconProps } from '../../atoms/Icon';
import type { TextProps } from '../../atoms/Text';
import type { TextInputProps } from '../../atoms/TextInput';
import type { DropdownItemListCombinedProps } from '../DropdownItemList/types';

type PageLabelItemInternalProps = {
  text?: TextProps;
  icon?: IconProps;
  textInput?: TextInputProps;
  button?: ButtonCombinedProps; // MANUAL OVERRIDE
  dropdownItemList?: DropdownItemListCombinedProps; // MANUAL OVERRIDE
};

type PageLabelItemPropsValues = {
  'DefaultDefaultDefault': PageLabelItemInternalProps;
  'DefaultDefaultHomepage': PageLabelItemInternalProps;
  'DefaultEditableSelectedDefault': PageLabelItemInternalProps;
  'DefaultEditableSelectedHomepage': PageLabelItemInternalProps;
  'DefaultSelectedDefault': PageLabelItemInternalProps;
  'DefaultSelectedHomepage': PageLabelItemInternalProps;
  'ShowMenuDefaultDefault': PageLabelItemInternalProps;
  'ShowMenuDefaultHomepage': PageLabelItemInternalProps;
  'ShowMenuEditableSelectedDefault': PageLabelItemInternalProps;
  'ShowMenuEditableSelectedHomepage': PageLabelItemInternalProps;
  'ShowMenuSelectedDefault': PageLabelItemInternalProps;
  'ShowMenuSelectedHomepage': PageLabelItemInternalProps;
};

const propValues: PageLabelItemPropsValues = {
  DefaultDefaultDefault: {
    text: {
      type: 'LabelMedium',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralDefault',
    },
  },
  DefaultDefaultHomepage: {
    icon: {
      asset: 'Home',
      colour: 'NeutralDefault',
    },
    text: {
      type: 'LabelMedium',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralDefault',
    },
  },
  DefaultEditableSelectedDefault: {
    textInput: {
      state: 'Default',
    },
  },
  DefaultEditableSelectedHomepage: {
    icon: {
      asset: 'Home',
      colour: 'NeutralDefault',
    },
    textInput: {
      state: 'Default',
    },
  },
  DefaultSelectedDefault: {
    text: {
      type: 'LabelMedium',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralHoverSelected',
    },
  },
  DefaultSelectedHomepage: {
    icon: {
      asset: 'Home',
      colour: 'NeutralDefault',
    },
    text: {
      type: 'LabelMedium',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralHoverSelected',
    },
  },
  ShowMenuDefaultDefault: {
    text: {
      type: 'LabelMedium',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralDefault',
    },
    button: {
      type: 'IconClear',
      size: 'S',
      state: 'Default',
      style: 'PrimarySubdued',
    },
    // MANUAL OVERRIDE STARTS
    dropdownItemList: {
      style: 'Default',
    },
    // MANUAL OVERRIDE ENDS
  },
  ShowMenuDefaultHomepage: {
    icon: {
      asset: 'Home',
      colour: 'NeutralDefault',
    },
    text: {
      type: 'LabelMedium',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralDefault',
    },
    button: {
      type: 'IconClear',
      size: 'S',
      state: 'Default',
      style: 'PrimarySubdued',
    },
    // MANUAL OVERRIDE STARTS
    dropdownItemList: {
      style: 'Default',
    },
    // MANUAL OVERRIDE ENDS
  },
  ShowMenuEditableSelectedDefault: {
    textInput: {
      state: 'Default',
    },
    button: {
      type: 'IconClear',
      size: 'S',
      state: 'Default',
      style: 'PrimarySubdued',
    },
    // MANUAL OVERRIDE STARTS
    dropdownItemList: {
      style: 'Default',
    },
    // MANUAL OVERRIDE ENDS
  },
  ShowMenuEditableSelectedHomepage: {
    icon: {
      asset: 'Home',
      colour: 'NeutralDefault',
    },
    textInput: {
      state: 'Default',
    },
    button: {
      type: 'IconClear',
      size: 'S',
      state: 'Default',
      style: 'PrimarySubdued',
    },
    // MANUAL OVERRIDE STARTS
    dropdownItemList: {
      style: 'Default',
    },
    // MANUAL OVERRIDE ENDS
  },
  ShowMenuSelectedDefault: {
    text: {
      type: 'LabelMedium',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralHoverSelected',
    },
    button: {
      type: 'IconClear',
      size: 'S',
      state: 'Default',
      style: 'PrimarySubdued',
    },
    // MANUAL OVERRIDE STARTS
    dropdownItemList: {
      style: 'Default',
    },
    // MANUAL OVERRIDE ENDS
  },
  ShowMenuSelectedHomepage: {
    icon: {
      asset: 'Home',
      colour: 'NeutralDefault',
    },
    text: {
      type: 'LabelMedium',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralHoverSelected',
    },
    button: {
      type: 'IconClear',
      size: 'S',
      state: 'Default',
      style: 'PrimarySubdued',
    },
    // MANUAL OVERRIDE STARTS
    dropdownItemList: {
      style: 'Default',
    },
    // MANUAL OVERRIDE ENDS
  },
};

const getProps = (type: string): PageLabelItemInternalProps => {
  const values: PageLabelItemInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
