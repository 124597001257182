import { TextProps } from '../../atoms/Text';
import { EmptyStateInfoProps } from '../../atoms/EmptyStateInfo';
import { TableRowListProps } from '../../molecules/TableRowList';

type LogicVariableTableInternalProps = {
  emptyStateInfo?: EmptyStateInfoProps;
  scope?: TextProps;
  name?: TextProps;
  response?: TextProps;
  type?: TextProps;
  defaultValue?: TextProps;
  tableRowList?: TableRowListProps;
};

type LogicVariableTablePropsValues = {
  'Empty': LogicVariableTableInternalProps;
  'Filled': LogicVariableTableInternalProps;
};

const propValues: LogicVariableTablePropsValues = {
  'Empty': {
    emptyStateInfo: {
    },
  },
  'Filled': {
    scope: {
      type: 'LabelMedium',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralHoverSelected',
    },
    name: {
      type: 'LabelMedium',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralHoverSelected',
    },
    response: {
      type: 'LabelMedium',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralHoverSelected',
    },
    type: {
      type: 'LabelMedium',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralHoverSelected',
    },
    defaultValue: {
      type: 'LabelMedium',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralHoverSelected',
    },
    tableRowList: {
      state: 'EditVariables',
      type: 'Default',
    },
  },
};

const getProps = (type: string): LogicVariableTableInternalProps => {
  const values: LogicVariableTableInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
