import React from 'react';
import cx from 'classnames';

import Button from '../Button';

import styles from './GrabIconButton.module.scss';
import usePresenter from './GrabIconButton.presenter';
import getProps from './GrabIconButton.props';
import { GrabIconButtonCombinedProps } from './types';

const GrabIconButton: React.FC<GrabIconButtonCombinedProps> = (props) => {
  const {
    className,
    classes,
    button,
    dragHandleProps, // MANUAL OVERRIDE
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.grabIconButton;

  return (
    <div
      className={cx(currentStyle, className)}
      {...dragHandleProps?.()} // MANUAL OVERRIDE
    >
      <Button
        className={cx(styles.button, classes?.button)}
        {...internalProps.button}
        {...button}
      />
    </div>
  );
};

export default GrabIconButton;
