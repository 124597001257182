import React from 'react';
import cx from 'classnames';

import Button from '../Button';
import Icon from '../Icon';

import styles from './InputField.module.scss';
import usePresenter from './InputField.presenter';
import getProps from './InputField.props';
import type { InputFieldCombinedProps } from './types';

const InputField: React.FC<InputFieldCombinedProps> = (props) => {
  const {
    type,
    size,
    state,
    className,
    classes,
    textValue,
    textPlaceholder,
    onTextChanged,
    onValidateText,
    onKeyDown,
    onBlur,
    onClick,
    inputType,
    maxLength,
    disabled,
    icon,
    button,
    inputFieldRef, // MANUAL CHANGE
  } = usePresenter(props);
  const variantName = `${type}${size}${state}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`inputField${variantName}`];

  const [inputValue, setInputValue] = React.useState<string | number>('');

  React.useEffect(() => {
    if (inputType === 'number') {
      setInputValue(`${textValue || 0}`);
    } else {
      setInputValue(textValue || '');
    }
  }, [textValue, inputType]);

  const onInputChanged = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const validEntry = onValidateText ? onValidateText(event.target.value) : true;
    if (validEntry) {
      setInputValue(event.target.value);
      if (onTextChanged) {
        onTextChanged(event.target.value);
      }
    }
  };

  let componentView;

  switch (variantName) {
    case 'CodeTextAreaMediumDefault':
    case 'TextAreaMediumDefault':
    case 'TextAreaSmallDefault': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <textarea
            className={cx(styles.textarea, classes?.textarea)}
            placeholder={textPlaceholder}
            value={inputValue}
            onChange={onInputChanged}
            onKeyDown={onKeyDown}
            onBlur={onBlur}
            onClick={onClick}
            disabled={disabled}
            maxLength={maxLength}/>
        </div>
      );
      break;
    }
    case 'TextMediumDefault':
    case 'TextMediumError':
    case 'TextMediumHighlighted': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <input
            ref={inputFieldRef} // MANUAL CHANGE
            className={cx(styles.input, classes?.input)}
            placeholder={textPlaceholder}
            value={inputValue}
            onChange={onInputChanged}
            onKeyDown={onKeyDown}
            onBlur={onBlur}
            onClick={onClick}
            disabled={disabled}
            type={inputType}
            maxLength={maxLength}/>
        </div>
      );
      break;
    }
    case 'HexCodeMediumDefault': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          {/* MANUAL OVERRIDE */}
          <div className={cx(styles.hash, classes?.hash)}>#</div>
          <div className={cx(styles.inputField, classes?.inputField)}>
            <input
              ref={inputFieldRef} // MANUAL CHANGE
              className={cx(styles.input, classes?.input)}
              placeholder={textPlaceholder}
              value={inputValue}
              onChange={onInputChanged}
              onKeyDown={onKeyDown}
              onBlur={onBlur}
              onClick={onClick}
              disabled={disabled}
              type={inputType}
              maxLength={maxLength}/>
          </div>
        </div>
      );
      break;
    }
    case 'IconTextMediumDefault': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <Icon
            className={cx(styles.icon, classes?.icon)}
            {...internalProps.icon}
            {...icon}/>
          <input
            ref={inputFieldRef} // MANUAL CHANGE
            className={cx(styles.input, classes?.input)}
            placeholder={textPlaceholder}
            value={inputValue}
            onChange={onInputChanged}
            onKeyDown={onKeyDown}
            onBlur={onBlur}
            onClick={onClick}
            disabled={disabled}
            type={inputType}
            maxLength={maxLength}/>
        </div>
      );
      break;
    }
    case 'TextIconMediumDefault': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <input
            ref={inputFieldRef} // MANUAL CHANGE
            className={cx(styles.input, classes?.input)}
            placeholder={textPlaceholder}
            value={inputValue}
            onChange={onInputChanged}
            onKeyDown={onKeyDown}
            onBlur={onBlur}
            onClick={onClick}
            disabled={disabled}
            type={inputType}
            maxLength={maxLength}/>
          <Icon
            className={cx(styles.icon, classes?.icon)}
            {...internalProps.icon}
            {...icon}/>
        </div>
      );
      break;
    }
    case 'TextUnitMediumDefault':
    case 'TextUnitSmallSelected': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <input
            ref={inputFieldRef} // MANUAL CHANGE
            className={cx(styles.input, classes?.input)}
            placeholder={textPlaceholder}
            value={inputValue}
            onChange={onInputChanged}
            onKeyDown={onKeyDown}
            onBlur={onBlur}
            onClick={onClick}
            disabled={disabled}
            type={inputType}
            maxLength={maxLength}/>
          <Button
            className={cx(styles.button, classes?.button)}
            {...internalProps.button}
            {...button}/>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default InputField;
