import Default from './Default.svg';
import HPBL from './HPBL.svg';
import HSAB from './HSAB.svg';
import HSBB from './HSBB.svg';
import VPBL from './VPBL.svg';
import VSAL from './VSAL.svg';
import VSBL from './VSBL.svg';

export const BottomLeft = {
  Default,
  'horizontal-packed-bottom-left': HPBL,
  'horizontal-space-around-bottom-none': HSAB,
  'horizontal-space-between-bottom-none': HSBB,
  'vertical-packed-bottom-left': VPBL,
  'vertical-space-around-none-left': VSAL,
  'vertical-space-between-none-left': VSBL,
};
