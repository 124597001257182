import { TextProps } from '../../atoms/Text';
import { TabItemListProps } from '../../molecules/TabItemList';
import { InputVariableTableProps } from '../../organisms/InputVariableTable';

type InputBlockInternalProps = {
  text?: TextProps;
  tabItemList?: TabItemListProps;
  inputVariableTable?: InputVariableTableProps;
};

type InputBlockPropsValues = {
  '': InputBlockInternalProps;
};

const propValues: InputBlockPropsValues = {
  '': {
    text: {
      type: 'LabelMedium',
      align: 'Left',
      style: 'Semibold600',
      colour: 'NeutralHoverSelected',
    },
    tabItemList: {
    },
    inputVariableTable: {
      state: 'Empty',
    },
  },
};

const getProps = (type: string): InputBlockInternalProps => {
  const values: InputBlockInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
