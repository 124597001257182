import { useTranslation } from 'react-i18next';
import { buildApiVariableTableRowList } from '../../molecules/TableRowList/utils';
import { LogicVariableTableCombinedProps } from './types';

const usePresenter = (props: LogicVariableTableCombinedProps): LogicVariableTableCombinedProps => {
  const { variables, onItemUpdated, onItemDeleted } = props;
  const { t } = useTranslation();
  return {
    ...props,
    tableRowList: buildApiVariableTableRowList(t, variables, onItemUpdated, onItemDeleted),
  };
};

export default usePresenter;
