export type BreakpointUnit = 'px' | 'rem';

export const defaultBreakPointKeys = ['mobile', 'tablet', 'desktop'] as const;
export type BreakpointIcon = typeof defaultBreakPointKeys[number];
export type BreakpointType = BreakpointIcon | 'custom';

export interface IBreakpoint {
  type: BreakpointType;
  width: number;
  height: number;
  icon: BreakpointIcon;
  isDefaultBreakpoint: boolean;
  isCurrentBreakpoint?: boolean;
}

export interface IBreakpointDetails {
  breakpoints: IBreakpoint[];
  breakpointUnit: BreakpointUnit;
}

export const DefaultBreakpoints: Record<BreakpointIcon, IBreakpoint> = {
  mobile: {
    type: 'mobile',
    width: 360,
    height: 640,
    icon: 'mobile',
    isDefaultBreakpoint: false,
  },
  tablet: {
    type: 'tablet',
    width: 768,
    height: 1024,
    icon: 'tablet',
    isDefaultBreakpoint: false,
  },
  desktop: {
    type: 'desktop',
    width: 1440,
    height: 1024,
    icon: 'desktop',
    isDefaultBreakpoint: true,
  },
};
