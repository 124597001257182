import { SettingsRowListProps } from '../SettingsRowList';

type SettingsPanelInternalProps = {
  settingsRowList?: SettingsRowListProps;
};

type SettingsPanelPropsValues = {
  '': SettingsPanelInternalProps;
};

const propValues: SettingsPanelPropsValues = {
  '': {
    settingsRowList: {
    },
  },
};

const getProps = (type: string): SettingsPanelInternalProps => {
  const values: SettingsPanelInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
