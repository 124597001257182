import React from 'react';
import cx from 'classnames';

import Divider from '../../atoms/Divider';
import DropdownButton from '../../atoms/DropdownButton';
import FieldWithDropdown from '../../atoms/FieldWithDropdown';
import ReplaceButton from '../../atoms/ReplaceButton';
import Text from '../../atoms/Text';
import MenuHeader from '../../molecules/MenuHeader';
import ToggleItemList from '../../molecules/ToggleItemList';

import styles from './AddImageMenu.module.scss';
import usePresenter from './AddImageMenu.presenter';
import getProps from './AddImageMenu.props';
import type { AddImageMenuCombinedProps } from './types';

const AddImageMenu: React.FC<AddImageMenuCombinedProps> = (props) => {
  const {
    state,
    className,
    classes,
    menuHeader,
    divider,
    imageLabel,
    replaceButton,
    sizeLabel,
    toggleItemList,
    positionLabel,
    dropdownButton,
    inputField,
    dropdownButton1,
    inputField1,
  } = usePresenter(props);

  const variantName = `${state}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`addImageMenu${variantName}`];

  let componentView;

  switch (variantName) {
    case 'Default': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <MenuHeader
            className={cx(styles.menuHeader, classes?.menuHeader)}
            {...internalProps.menuHeader}
            {...menuHeader}
          />
          <Divider
            className={cx(styles.divider, classes?.divider)}
            {...internalProps.divider}
            {...divider}
          />
          <div className={cx(styles.menuContent, classes?.menuContent)}>
            <div className={cx(styles.imageContent, classes?.imageContent)}>
              <Text
                className={cx(styles.imageLabel, classes?.imageLabel)}
                {...internalProps.imageLabel}
                {...imageLabel}
              />
              <ReplaceButton
                className={cx(styles.replaceButton, classes?.replaceButton)}
                {...internalProps.replaceButton}
                {...replaceButton}
              />
            </div>
            <div className={cx(styles.sizeContent, classes?.sizeContent)}>
              <Text
                className={cx(styles.sizeLabel, classes?.sizeLabel)}
                {...internalProps.sizeLabel}
                {...sizeLabel}
              />
              <ToggleItemList
                className={cx(styles.toggleItemList, classes?.toggleItemList)}
                {...internalProps.toggleItemList}
                {...toggleItemList}
              />
            </div>
            <div className={cx(styles.positionContent, classes?.positionContent)}>
              <Text
                className={cx(styles.positionLabel, classes?.positionLabel)}
                {...internalProps.positionLabel}
                {...positionLabel}
              />
              <div className={cx(styles.positionContent1, classes?.positionContent1)}>
                <div className={cx(styles.horizontalPosition, classes?.horizontalPosition)}>
                  <DropdownButton
                    className={cx(styles.dropdownButton, classes?.dropdownButton)}
                    {...internalProps.dropdownButton}
                    {...dropdownButton}
                  />
                  <FieldWithDropdown // MANUAL OVERRIDE
                    className={cx(styles.inputField, classes?.inputField)}
                    {...internalProps.inputField}
                    {...inputField}
                  />
                </div>
                <div className={cx(styles.verticalPosition, classes?.verticalPosition)}>
                  <DropdownButton
                    className={cx(styles.dropdownButton1, classes?.dropdownButton1)}
                    {...internalProps.dropdownButton1}
                    {...dropdownButton1}
                  />
                  <FieldWithDropdown // MANUAL OVERRIDE
                    className={cx(styles.inputField1, classes?.inputField1)}
                    {...internalProps.inputField1}
                    {...inputField1}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default AddImageMenu;
