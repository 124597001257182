import React from 'react';
import cx from 'classnames';

import BreadcrumbItem from '../../atoms/BreadcrumbItem';

import styles from './BreadcrumbItemList.module.scss';
import usePresenter from './BreadcrumbItemList.presenter';
import getProps from './BreadcrumbItemList.props';
import { BreadcrumbItemListCombinedProps } from './types';

const BreadcrumbItemList: React.FC<BreadcrumbItemListCombinedProps> = (props) => {
  const {
    className,
    classes,
    breadcrumbItems,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.breadcrumbItemList;

  const breadcrumbItemViews = breadcrumbItems?.map((breadcrumbItem, index) => (
    <BreadcrumbItem
      key={index}
      className={cx(styles.breadcrumbItem, classes?.breadcrumbItem)}
      {...internalProps.breadcrumbItem}
      {...breadcrumbItem}
    />
  )) || [];

  return (
    <div className={cx(currentStyle, className)}>
      {breadcrumbItemViews}
    </div>
  );
};

export default BreadcrumbItemList;
