import React from 'react';
import cx from 'classnames';

import LogicTab from '../../atoms/LogicTab';

import styles from './LogicTabList.module.scss';
import usePresenter from './LogicTabList.presenter';
import getProps from './LogicTabList.props';
import { LogicTabListCombinedProps } from './types';

const LogicTabList: React.FC<LogicTabListCombinedProps> = (props) => {
  const {
    className,
    classes,
    logicTabs,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.logicTabList;

  const logicTabViews = logicTabs?.map((logicTab, index) => (
    <LogicTab
      key={index}
      className={cx(styles.logicTab, classes?.logicTab)}
      {...internalProps.logicTab}
      {...logicTab}
    />
  )) || [];

  return (
    <div className={cx(currentStyle, className)}>
      {logicTabViews}
    </div>
  );
};

export default LogicTabList;
