import { IconProps } from '../Icon';
import { TextProps } from '../Text';

type ErrorBarInternalProps = {
  icon?: IconProps;
  text?: TextProps;
};

type ErrorBarPropsValues = {
  '': ErrorBarInternalProps;
};

const propValues: ErrorBarPropsValues = {
  '': {
    icon: {
      asset: 'Error',
      colour: 'NeturalHoverSelected',
    },
    text: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralHoverSelected',
    },
  },
};

const getProps = (type: string): ErrorBarInternalProps => {
  const values: ErrorBarInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
