import { comparer, computed } from 'mobx';
import { CSSProperties, useContext } from 'react';
import { StoreContext } from '../../../store/StoreProvider';
import { CanvasDimensions, IStyles } from '../../../store/types';
import { mapStylesToCssProps } from '../../../utils/util';

export const useCssProps = (
  styles: IStyles,
): CSSProperties => {
  const { breakpointStore, appStore } = useContext(StoreContext);

  const cssProps = computed(
    () => {
      const canvasDimensions: CanvasDimensions = {
        width: breakpointStore.currentBreakpoint.width,
        height: appStore.canvasHeight,
      };
      return mapStylesToCssProps(styles, canvasDimensions);
    },
    // Use MobX structural comparer because we get an object, not a single primitive value.
    // This is to prevent unnecessary re-rendering.
    { equals: comparer.structural },
  ).get();

  return cssProps;
};
