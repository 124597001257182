import { ReplaceRowProps } from '../ReplaceRow';

type ReplaceRowListInternalProps = {
  replaceRow?: ReplaceRowProps;
};

type ReplaceRowListPropsValues = {
  '': ReplaceRowListInternalProps;
};

const propValues: ReplaceRowListPropsValues = {
  '': {
    replaceRow: {
      type: 'VariableText',
      state: 'Editable',
      style: 'VariableVertical',
    },
  },
};

const getProps = (type: string): ReplaceRowListInternalProps => {
  const values: ReplaceRowListInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
