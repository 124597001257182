import { observer } from 'mobx-react-lite';
import InternalVariableResponsePanel from './VariableResponsePanel';
import { VariableResponsePanelProps, VariableResponsePanelValueProps } from './types';
import withModal from '../../../modules/common/ModalWrapper/context/withModal';
import ExpandedVariableResponses from '../../blocks/ExpandedVariableResponses';

export type { VariableResponsePanelProps, VariableResponsePanelValueProps };
const [VariableResponsePanel, ExpandedVariableResponsesContext] = withModal(observer(InternalVariableResponsePanel), ExpandedVariableResponses);
export { ExpandedVariableResponsesContext };
export default VariableResponsePanel;
