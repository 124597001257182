import { mapStylesToPreviewCssProps } from '../../../../utils/util';
import type { PreviewTextElementProps } from './types';

const usePresenter = ({ element, style, ...otherProps }: PreviewTextElementProps): PreviewTextElementProps => {
  return {
    element,
    style: {
      ...mapStylesToPreviewCssProps(element.styles),
      ...style,
    },
    ...otherProps,
  };
};

export default usePresenter;
