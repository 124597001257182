import React from 'react';
import cx from 'classnames';

import GrabIconButton from '../../atoms/GrabIconButton';
import Text from '../../atoms/Text';

import styles from './LogicBuilderHeader.module.scss';
import usePresenter from './LogicBuilderHeader.presenter';
import getProps from './LogicBuilderHeader.props';
import { LogicBuilderHeaderCombinedProps } from './types';

const LogicBuilderHeader: React.FC<LogicBuilderHeaderCombinedProps> = (props) => {
  const {
    className,
    classes,
    text,
    grabIconButton,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.logicBuilderHeader;

  return (
    <div className={cx(currentStyle, className)}>
      <Text
        className={cx(styles.text, classes?.text)}
        {...internalProps.text}
        {...text}
      />
      <GrabIconButton
        className={cx(styles.grabIconButton, classes?.grabIconButton)}
        {...internalProps.grabIconButton}
        {...grabIconButton}
      />
    </div>
  );
};

export default LogicBuilderHeader;
