import { useTranslation } from 'react-i18next';
import { typedDeepMerge } from '../../../utils/objectUtils';
import { buildInputVariableTableRowList } from '../../molecules/TableRowList/utils';
import { InputVariableTableCombinedProps } from './types';

const usePresenter = (props: InputVariableTableCombinedProps): InputVariableTableCombinedProps => {
  const { onItemsChange, inputVariables, isErrorVisible } = props;
  const { t } = useTranslation();

  const onAddNewRow = () => {
    if (inputVariables && onItemsChange) {
      onItemsChange([...inputVariables, { name: '', type: undefined }]);
    }
  };

  return typedDeepMerge(props, {
    tableRowList: buildInputVariableTableRowList(t, inputVariables, onItemsChange, isErrorVisible),
    button: {
      onClick: onAddNewRow,
    },
  });
};

export default usePresenter;
