/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import cx from 'classnames';

import Button from '../../atoms/Button';
import Icon from '../../atoms/Icon';
import Text from '../../atoms/Text';
import TextInput from '../../atoms/TextInput';
import DropZone from '../../../modules/canvas/utils/DropZone';
import LayerLabelItemList from '../LayerLabelItemList';

import { Constants } from '../../../constants';
import styles from './LayerLabelItem.module.scss';
import usePresenter from './LayerLabelItem.presenter';
import getProps from './LayerLabelItem.props';
import type { LayerLabelItemCombinedProps } from './types';

const LayerLabelItem: React.FC<LayerLabelItemCombinedProps> = (props) => {
  const {
    type,
    state,
    style,
    className,
    classes,
    button,
    icon,
    text,
    icon1,
    textInput,
    // MANUAL OVERRIDE STARTS
    element,
    elementRef,
    elementIndexInsideParent = 0,
    childLayerLabelItemList,
    level = 0,
    paddingLeft,
    onMouseOver,
    onFocus,
    onMouseOut,
    onBlur,
    onClick,
    // MANUAL OVERRIDE ENDS
  } = usePresenter(props);

  // MANUAL OVERRIDE STARTS
  if (!element) {
    return null;
  }
  // MANUAL OVERRIDE ENDS

  const variantName = `${type}${state}${style}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`layerLabelItem${variantName}`];

  // MANUAL OVERRIDE STARTS
  // Render a drop zone above the first child of the parent
  const topDropZone: JSX.Element | null = element.parentId !== Constants.ID.BODY && elementIndexInsideParent === 0
    ? <DropZone parentId={element.parentId} dropZoneIndex={elementIndexInsideParent} variantName="Horizontal" />
    : null;

  // Render a drop zone below each child of the parent
  const bottomDropZone1: JSX.Element | null = element.parentId !== Constants.ID.BODY
    ? <DropZone parentId={element.parentId} dropZoneIndex={elementIndexInsideParent + 1} variantName="Horizontal" className={styles.dropZoneParentBottom} />
    : null;

  // If element is not expanded, i.e. children are hidden, then render a drop zone below
  const bottomDropZone2: JSX.Element | null = type !== 'Expanded'
    ? <DropZone parentId={element.id} dropZoneIndex={0} variantName="Horizontal" className={styles.dropZoneParentBottomInner} />
    : null;
  // MANUAL OVERRIDE ENDS

  let componentView;

  switch (variantName) {
    case 'CollapsedDefaultDefault':
    case 'CollapsedSelectedDefault':
    case 'ExpandedDefaultDefault':
    case 'ExpandedSelectedDefault': {
      componentView = (
        // MANUAL OVERRIDE: Top div is added at the bottom of the file
        <>
          <Button
            className={cx(styles.button, classes?.button)}
            {...internalProps.button}
            {...button}
          />
          {/* // MANUAL OVERRIDE: Adding contentWrapper with drop zones */}
          <div ref={elementRef} className={cx(styles.contentWrapper)}>
            {topDropZone}
            <div className={cx(styles.content, classes?.content)}>
              <Icon
                className={cx(styles.icon, classes?.icon)}
                {...internalProps.icon}
                {...icon}
              />
              <Text
                className={cx(styles.text, classes?.text)}
                {...internalProps.text}
                {...text}
              />
            </div>
            {bottomDropZone1}
            {bottomDropZone2}
          </div>
        </>
      );
      break;
    }
    case 'CollapsedDefaultHidden':
    case 'CollapsedSelectedHidden':
    case 'ExpandedDefaultHidden':
    case 'ExpandedSelectedHidden': {
      componentView = (
        // MANUAL OVERRIDE: Top div is added at the bottom of the file
        <>
          <Button
            className={cx(styles.button, classes?.button)}
            {...internalProps.button}
            {...button}
          />
          {/* // MANUAL OVERRIDE: Adding contentWrapper with drop zones */}
          <div ref={elementRef} className={cx(styles.contentWrapper)}>
            {topDropZone}
            <div className={cx(styles.content, classes?.content)}>
              <Icon
                className={cx(styles.icon, classes?.icon)}
                {...internalProps.icon}
                {...icon}
              />
              <Text
                className={cx(styles.text, classes?.text)}
                {...internalProps.text}
                {...text}
              />
              <Icon
                className={cx(styles.icon1, classes?.icon1)}
                {...internalProps.icon1}
                {...icon1}
              />
            </div>
            {bottomDropZone1}
            {bottomDropZone2}
          </div>
        </>
      );
      break;
    }
    case 'CollapsedEditableSelectedDefault':
    case 'ExpandedEditableSelectedDefault': {
      componentView = (
        // MANUAL OVERRIDE: Top div is added at the bottom of the file
        <>
          <Button
            className={cx(styles.button, classes?.button)}
            {...internalProps.button}
            {...button}
          />
          {/* // MANUAL OVERRIDE: Adding contentWrapper with drop zones */}
          <div ref={elementRef} className={cx(styles.contentWrapper)}>
            {topDropZone}
            <div className={cx(styles.content, classes?.content)}>
              <Icon
                className={cx(styles.icon, classes?.icon)}
                {...internalProps.icon}
                {...icon}
              />
              <TextInput
                className={cx(styles.textInput, classes?.textInput)}
                {...internalProps.textInput}
                {...textInput}
              />
            </div>
            {bottomDropZone1}
            {bottomDropZone2}
          </div>
        </>
      );
      break;
    }
    case 'CollapsedEditableSelectedHidden':
    case 'ExpandedEditableSelectedHidden': {
      componentView = (
        // MANUAL OVERRIDE: Top div is added at the bottom of the file
        <>
          <Button
            className={cx(styles.button, classes?.button)}
            {...internalProps.button}
            {...button}
          />
          {/* // MANUAL OVERRIDE: Adding contentWrapper with drop zones */}
          <div ref={elementRef} className={cx(styles.contentWrapper)}>
            {topDropZone}
            <div className={cx(styles.content, classes?.content)}>
              <Icon
                className={cx(styles.icon, classes?.icon)}
                {...internalProps.icon}
                {...icon}
              />
              <TextInput
                className={cx(styles.textInput, classes?.textInput)}
                {...internalProps.textInput}
                {...textInput}
              />
              <Icon
                className={cx(styles.icon1, classes?.icon1)}
                {...internalProps.icon1}
                {...icon1}
              />
            </div>
            {bottomDropZone1}
            {bottomDropZone2}
          </div>
        </>
      );
      break;
    }
    case 'DefaultDefaultDefault':
    case 'DefaultSelectedDefault': {
      componentView = (
        // MANUAL OVERRIDE: Top div is added at the bottom of the file
        // MANUAL OVERRIDE: Adding contentWrapper with drop zones
        <div ref={elementRef} className={cx(styles.contentWrapper)}>
          {topDropZone}
          <div className={cx(styles.content, classes?.content)}>
            <Icon
              className={cx(styles.icon, classes?.icon)}
              {...internalProps.icon}
              {...icon}
            />
            <Text
              className={cx(styles.text, classes?.text)}
              {...internalProps.text}
              {...text}
            />
          </div>
          {bottomDropZone1}
          {bottomDropZone2}
        </div>
      );
      break;
    }
    case 'DefaultDefaultHidden':
    case 'DefaultSelectedHidden': {
      componentView = (
        // MANUAL OVERRIDE: Top div is added at the bottom of the file
        // MANUAL OVERRIDE: Adding contentWrapper with drop zones
        <div ref={elementRef} className={cx(styles.contentWrapper)}>
          {topDropZone}
          <div className={cx(styles.content, classes?.content)}>
            <Icon
              className={cx(styles.icon, classes?.icon)}
              {...internalProps.icon}
              {...icon}
            />
            <Text
              className={cx(styles.text, classes?.text)}
              {...internalProps.text}
              {...text}
            />
            <Icon
              className={cx(styles.icon1, classes?.icon1)}
              {...internalProps.icon1}
              {...icon1}
            />
          </div>
          {bottomDropZone1}
          {bottomDropZone2}
        </div>
      );
      break;
    }
    case 'DefaultEditableSelectedDefault': {
      componentView = (
        // MANUAL OVERRIDE: Top div is added at the bottom of the file
        // MANUAL OVERRIDE: Adding contentWrapper with drop zones
        <div ref={elementRef} className={cx(styles.contentWrapper)}>
          {topDropZone}
          <div className={cx(styles.content, classes?.content)}>
            <Icon
              className={cx(styles.icon, classes?.icon)}
              {...internalProps.icon}
              {...icon}
            />
            <TextInput
              className={cx(styles.textInput, classes?.textInput)}
              {...internalProps.textInput}
              {...textInput}
            />
          </div>
          {bottomDropZone1}
          {bottomDropZone2}
        </div>
      );
      break;
    }
    case 'DefaultEditableSelectedHidden': {
      componentView = (
        // MANUAL OVERRIDE: Top div is added at the bottom of the file
        // MANUAL OVERRIDE: Adding contentWrapper with drop zones
        <div ref={elementRef} className={cx(styles.contentWrapper)}>
          {topDropZone}
          <div className={cx(styles.content, classes?.content)}>
            <Icon
              className={cx(styles.icon, classes?.icon)}
              {...internalProps.icon}
              {...icon}
            />
            <TextInput
              className={cx(styles.textInput, classes?.textInput)}
              {...internalProps.textInput}
              {...textInput}
            />
            <Icon
              className={cx(styles.icon1, classes?.icon1)}
              {...internalProps.icon1}
              {...icon1}
            />
          </div>
          {bottomDropZone1}
          {bottomDropZone2}
        </div>
      );
      break;
    }
    default:
      componentView = '�'; // MANUAL OVERRIDE
      break;
  }

  // MANUAL OVERRIDE STARTS
  return (
    <>
      <div
        className={cx(currentStyle, className)}
        style={{ paddingLeft }}
        onMouseOver={onMouseOver}
        onFocus={onFocus}
        onMouseOut={onMouseOut}
        onBlur={onBlur}
        onClick={onClick}
        {...{ [Constants.ATTRIBUTES.ELEMENT_ID]: element.id }}
        {...{ [Constants.ATTRIBUTES.ELEMENT_TYPE]: element.type }}
      >
        {componentView}
      </div>
      {(childLayerLabelItemList?.layerLabelItems?.length ?? 0) > 0 && (
        <LayerLabelItemList
          className={cx(styles.layerLabelItemList)}
          {...childLayerLabelItemList}
          level={level + 1}
        />
      )}
    </>
  );
  // MANUAL OVERRIDE ENDS
};

export default LayerLabelItem;
