import React from 'react';
import cx from 'classnames';

import Divider from '../../atoms/Divider';
import Button from '../../atoms/Button';
import Text from '../../atoms/Text';
import SpindlLogo from '../../atoms/SpindlLogo';
import BreakpointMenu from '../../molecules/BreakpointMenu';

import styles from './TopNav.module.scss';
import usePresenter from './TopNav.presenter';
import getProps from './TopNav.props';
import { TopNavCombinedProps } from './types';
import { Constants } from '../../../constants';

const TopNav: React.FC<TopNavCombinedProps> = (props) => {
  const {
    style,
    className,
    classes,
    spindlLogo,
    divider,
    text,
    divider1,
    button,
    divider11,
    breakpointMenu,
    divider2,
    saveStatus,
    undo,
    redo,
    divider3,
    preview,
    divider4,
    publish,
    bottomDivider,
  } = usePresenter(props);

  const variantName = `${style}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`topNav${variantName}`];

  let componentView;

  switch (variantName) {
    case 'Default': {
      componentView = (
        <div className={cx(currentStyle, className)} id={Constants.ID.TOP_NAV}>
          <div className={cx(styles.content, classes?.content)}>
            <div className={cx(styles.leftContent, classes?.leftContent)}>
              <div className={cx(styles.logoContainer, classes?.logoContainer)}>
                <SpindlLogo
                  className={cx(styles.spindlLogo, classes?.spindlLogo)}
                  {...internalProps.spindlLogo}
                  {...spindlLogo}/>
              </div>
              <Divider
                className={cx(styles.divider, classes?.divider)}
                {...internalProps.divider}
                {...divider}/>
              <div className={cx(styles.appName, classes?.appName)}>
                <Text
                  className={cx(styles.text, classes?.text)}
                  {...internalProps.text}
                  {...text}/>
              </div>
              <Divider
                className={cx(styles.divider1, classes?.divider1)}
                {...internalProps.divider1}
                {...divider1}/>
              <Button
                className={cx(styles.button, classes?.button)}
                {...internalProps.button}
                {...button}/>
              <Divider
                className={cx(styles.divider1, classes?.divider1)}
                {...internalProps.divider1}
                {...divider1}/>
            </div>
            <div className={cx(styles.rightContent, classes?.rightContent)}>
              <Divider
                className={cx(styles.divider11, classes?.divider11)}
                {...internalProps.divider11}
                {...divider11}/>
              <BreakpointMenu
                className={cx(styles.breakpointMenu, classes?.breakpointMenu)}
                {...internalProps.breakpointMenu}
                {...breakpointMenu}/>
              <Divider
                className={cx(styles.divider2, classes?.divider2)}
                {...internalProps.divider2}
                {...divider2}/>
              <div className={cx(styles.saveStatusIndicator, classes?.saveStatusIndicator)}>
                <Text
                  className={cx(styles.saveStatus, classes?.saveStatus)}
                  {...internalProps.saveStatus}
                  {...saveStatus}/>
              </div>
              <Divider
                className={cx(styles.divider2, classes?.divider2)}
                {...internalProps.divider2}
                {...divider2}/>
              <Button
                className={cx(styles.undo, classes?.undo)}
                {...internalProps.undo}
                {...undo}/>
              <Button
                className={cx(styles.redo, classes?.redo)}
                {...internalProps.redo}
                {...redo}/>
              <Divider
                className={cx(styles.divider3, classes?.divider3)}
                {...internalProps.divider3}
                {...divider3}/>
              <Button
                className={cx(styles.preview, classes?.preview)}
                {...internalProps.preview}
                {...preview}/>
              <Divider
                className={cx(styles.divider4, classes?.divider4)}
                {...internalProps.divider4}
                {...divider4}/>
              <Button
                className={cx(styles.publish, classes?.publish)}
                {...internalProps.publish}
                {...publish}/>
            </div>
          </div>
          <Divider
            className={cx(styles.bottomDivider, classes?.bottomDivider)}
            {...internalProps.bottomDivider}
            {...bottomDivider}/>
        </div>
      );
      break;
    }
    case 'LogoAppName': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <div className={cx(styles.content, classes?.content)}>
            <div className={cx(styles.leftContent, classes?.leftContent)}>
              <div className={cx(styles.logoContainer, classes?.logoContainer)}>
                <SpindlLogo
                  className={cx(styles.spindlLogo, classes?.spindlLogo)}
                  {...internalProps.spindlLogo}
                  {...spindlLogo}/>
              </div>
              <Divider
                className={cx(styles.divider, classes?.divider)}
                {...internalProps.divider}
                {...divider}/>
              <div className={cx(styles.appName, classes?.appName)}>
                <Text
                  className={cx(styles.text, classes?.text)}
                  {...internalProps.text}
                  {...text}/>
              </div>
              <Divider
                className={cx(styles.divider1, classes?.divider1)}
                {...internalProps.divider1}
                {...divider1}/>
            </div>
          </div>
          <Divider
            className={cx(styles.bottomDivider, classes?.bottomDivider)}
            {...internalProps.bottomDivider}
            {...bottomDivider}/>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default TopNav;
