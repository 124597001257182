import { useEffect, type RefObject } from 'react';

export const useResizeObserver = (
  target: RefObject<HTMLElement> | HTMLElement | null,
  onResize: ResizeObserverCallback,
  options?: ResizeObserverOptions,
): void => {
  useEffect(() => {
    const targetElement: HTMLElement | null = target && 'current' in target ? target.current : target;
    let resizeObserver: ResizeObserver | undefined;

    if (targetElement) {
      resizeObserver = new ResizeObserver(onResize);
      resizeObserver.observe(targetElement, options);
    }

    return () => {
      if (targetElement && resizeObserver) {
        resizeObserver.unobserve(targetElement);
        resizeObserver.disconnect();
        resizeObserver = undefined;
      }
    };
  }, [target, onResize, options]);
};
