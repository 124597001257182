import { ButtonProps } from '../Button';
import { TextProps } from '../Text';

type RowLabelInternalProps = {
  text?: TextProps;
  button?: ButtonProps;
};

type RowLabelPropsValues = {
  'DefaultDefaultClear': RowLabelInternalProps;
  'DefaultDefaultPanel': RowLabelInternalProps;
  'DefaultVariableClear': RowLabelInternalProps;
  'DefaultVariablePanel': RowLabelInternalProps;
  'SelectedVariableClear': RowLabelInternalProps;
  'SelectedVariablePanel': RowLabelInternalProps;
};

const propValues: RowLabelPropsValues = {
  'DefaultDefaultClear': {
    text: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Regular400',
      colour: 'NeutralDefault',
    },
  },
  'DefaultDefaultPanel': {
    text: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Regular400',
      colour: 'NeutralDefault',
    },
  },
  'DefaultVariableClear': {
    text: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Regular400',
      colour: 'NeutralDefault',
    },
    button: {
      type: 'IconClear',
      size: 'S',
      state: 'Default',
      style: 'PrimarySubdued',
    },
  },
  'DefaultVariablePanel': {
    text: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Regular400',
      colour: 'NeutralDefault',
    },
    button: {
      type: 'IconClear',
      size: 'S',
      state: 'Default',
      style: 'PrimarySubdued',
    },
  },
  'SelectedVariableClear': {
    text: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Regular400',
      colour: 'NeutralDefault',
    },
    button: {
      type: 'IconClear',
      size: 'S',
      state: 'Selected',
      style: 'PrimarySubdued',
    },
  },
  'SelectedVariablePanel': {
    text: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Regular400',
      colour: 'NeutralDefault',
    },
    button: {
      type: 'IconClear',
      size: 'S',
      state: 'Selected',
      style: 'PrimarySubdued',
    },
  },
};

const getProps = (type: string): RowLabelInternalProps => {
  const values: RowLabelInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
