import cx from 'classnames';
import { VariableLayerItemCombinedProps } from './types';
import customStyles from './Custom.module.scss';

const usePresenter = (props: VariableLayerItemCombinedProps): VariableLayerItemCombinedProps => {
  const { type, state } = props;
  return {
    ...props,
    className: cx(
      props.className,
      customStyles[`variableLayerItem${state}${type}`],
    ),
    classes: {
      ...props.classes,
      variableType: cx(props.classes?.variableType, customStyles.variableType),
    },
  };
};

export default usePresenter;
