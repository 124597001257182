import { ButtonProps } from '../../atoms/Button';
import { IconProps } from '../../atoms/Icon';
import { TextProps } from '../../atoms/Text';
import { RowHoverCapProps } from '../../atoms/RowHoverCap';

type ResponseItemInternalProps = {
  button?: ButtonProps;
  name?: TextProps;
  responseType?: TextProps;
  button1?: ButtonProps;
  icon?: IconProps;
  rowHoverCap?: RowHoverCapProps;
};

type ResponseItemPropsValues = {
  'CollapsedExpandedViewAdd': ResponseItemInternalProps;
  'CollapsedExpandedViewAdded': ResponseItemInternalProps;
  'CollapsedExpandedViewDefault': ResponseItemInternalProps;
  'CollapsedPanelAdd': ResponseItemInternalProps;
  'CollapsedPanelDefault': ResponseItemInternalProps;
  'DefaultExpandedViewAdd': ResponseItemInternalProps;
  'DefaultExpandedViewAdded': ResponseItemInternalProps;
  'DefaultExpandedViewDefault': ResponseItemInternalProps;
  'DefaultPanelAdd': ResponseItemInternalProps;
  'DefaultPanelAdded': ResponseItemInternalProps; // MANUAL OVERRIDE
  'DefaultPanelDefault': ResponseItemInternalProps;
  'ExpandedExpandedViewAdd': ResponseItemInternalProps;
  'ExpandedExpandedViewAdded': ResponseItemInternalProps;
  'ExpandedExpandedViewDefault': ResponseItemInternalProps;
  'ExpandedPanelAdd': ResponseItemInternalProps;
  'ExpandedPanelDefault': ResponseItemInternalProps;
};

const propValues: ResponseItemPropsValues = {
  'CollapsedExpandedViewAdd': {
    button: {
      type: 'IconClear',
      size: 'S',
      state: 'Default',
      style: 'PrimarySubdued',
    },
    name: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralHoverSelected',
    },
    responseType: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Italic500',
      colour: 'NeutralSubdued',
    },
    button1: {
      type: 'IconClear',
      size: 'S',
      state: 'Default',
      style: 'PrimarySubdued',
    },
  },
  'CollapsedExpandedViewAdded': {
    button: {
      type: 'IconClear',
      size: 'S',
      state: 'Default',
      style: 'PrimarySubdued',
    },
    name: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralHoverSelected',
    },
    responseType: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Italic500',
      colour: 'NeutralSubdued',
    },
    icon: {
      asset: 'CheckmarkCircle',
      colour: 'NeutralDefault',
    },
  },
  'CollapsedExpandedViewDefault': {
    button: {
      type: 'IconClear',
      size: 'S',
      state: 'Default',
      style: 'PrimarySubdued',
    },
    name: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralDefault',
    },
    responseType: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Italic500',
      colour: 'NeutralSubdued',
    },
  },
  'CollapsedPanelAdd': {
    button: {
      type: 'IconClear',
      size: 'S',
      state: 'Default',
      style: 'PrimarySubdued',
    },
    name: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralHoverSelected',
    },
    responseType: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Italic500',
      colour: 'NeutralSubdued',
    },
    rowHoverCap: {
      type: 'Clickable',
    },
  },
  'CollapsedPanelDefault': {
    button: {
      type: 'IconClear',
      size: 'S',
      state: 'Default',
      style: 'PrimarySubdued',
    },
    name: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralDefault',
    },
    responseType: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Italic500',
      colour: 'NeutralSubdued',
    },
  },
  'DefaultExpandedViewAdd': {
    name: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralHoverSelected',
    },
    responseType: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Italic500',
      colour: 'NeutralSubdued',
    },
    button: {
      type: 'IconClear',
      size: 'S',
      state: 'Default',
      style: 'PrimarySubdued',
    },
  },
  'DefaultExpandedViewAdded': {
    name: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralHoverSelected',
    },
    responseType: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Italic500',
      colour: 'NeutralSubdued',
    },
    icon: {
      asset: 'CheckmarkCircle',
      colour: 'NeutralDefault',
    },
  },
  'DefaultExpandedViewDefault': {
    name: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralDefault',
    },
    responseType: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Italic500',
      colour: 'NeutralSubdued',
    },
  },
  'DefaultPanelAdd': {
    name: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralHoverSelected',
    },
    responseType: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Italic500',
      colour: 'NeutralSubdued',
    },
    rowHoverCap: {
      type: 'Clickable',
    },
  },
  // MANUAL OVERRIDE START
  'DefaultPanelAdded': {
    name: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralHoverSelected',
    },
    responseType: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Italic500',
      colour: 'NeutralSubdued',
    },
    rowHoverCap: {
      type: 'NonClickable',
    },
  },
  // MANUAL OVERRIDE END
  'DefaultPanelDefault': {
    name: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralDefault',
    },
    responseType: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Italic500',
      colour: 'NeutralSubdued',
    },
  },
  'ExpandedExpandedViewAdd': {
    button: {
      type: 'IconClear',
      size: 'S',
      state: 'Default',
      style: 'PrimarySubdued',
    },
    name: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralHoverSelected',
    },
    responseType: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Italic500',
      colour: 'NeutralSubdued',
    },
    button1: {
      type: 'IconClear',
      size: 'S',
      state: 'Default',
      style: 'PrimarySubdued',
    },
  },
  'ExpandedExpandedViewAdded': {
    button: {
      type: 'IconClear',
      size: 'S',
      state: 'Default',
      style: 'PrimarySubdued',
    },
    name: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralHoverSelected',
    },
    responseType: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Italic500',
      colour: 'NeutralSubdued',
    },
    icon: {
      asset: 'CheckmarkCircle',
      colour: 'NeutralDefault',
    },
  },
  'ExpandedExpandedViewDefault': {
    button: {
      type: 'IconClear',
      size: 'S',
      state: 'Default',
      style: 'PrimarySubdued',
    },
    name: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralDefault',
    },
    responseType: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Italic500',
      colour: 'NeutralSubdued',
    },
  },
  'ExpandedPanelAdd': {
    button: {
      type: 'IconClear',
      size: 'S',
      state: 'Default',
      style: 'PrimarySubdued',
    },
    name: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralHoverSelected',
    },
    responseType: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Italic500',
      colour: 'NeutralSubdued',
    },
    rowHoverCap: {
      type: 'Clickable',
    },
  },
  'ExpandedPanelDefault': {
    button: {
      type: 'IconClear',
      size: 'S',
      state: 'Default',
      style: 'PrimarySubdued',
    },
    name: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralDefault',
    },
    responseType: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Italic500',
      colour: 'NeutralSubdued',
    },
  },
};

const getProps = (type: string): ResponseItemInternalProps => {
  const values: ResponseItemInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
