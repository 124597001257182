import React from 'react';
import cx from 'classnames';

import BackgroundPanel from '../BackgroundPanel';
import BorderPanel from '../BorderPanel';
import CornersPanel from '../CornersPanel';
import LayoutPanel from '../LayoutPanel';
import PaddingMarginsPanel from '../PaddingMarginsPanel';
import SizePanel from '../SizePanel';
import StyleEffectsPanel from '../StyleEffectsPanel';
import TypographyPanel from '../TypographyPanel';

import styles from './StylePanel.module.scss';
import usePresenter from './StylePanel.presenter';
import getProps from './StylePanel.props';
import { StylePanelCombinedProps } from './types';

const StylePanel: React.FC<StylePanelCombinedProps> = (props) => {
  const {
    className,
    classes,
    sizePanel,
    paddingMarginsPanel,
    layoutPanel,
    typographyPanel,
    backgroundPanel,
    borderPanel,
    cornersPanel,
    styleEffectsPanel,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.stylePanel;

  return (
    <div className={cx(currentStyle, className)}>
      <SizePanel
        className={cx(styles.sizePanel, classes?.sizePanel)}
        {...internalProps.sizePanel}
        {...sizePanel}/>
      <PaddingMarginsPanel
        className={cx(styles.paddingMarginsPanel, classes?.paddingMarginsPanel)}
        {...internalProps.paddingMarginsPanel}
        {...paddingMarginsPanel}
      />
      <LayoutPanel
        className={cx(styles.layoutPanel, classes?.layoutPanel)}
        {...internalProps.layoutPanel}
        {...layoutPanel}/>
      <TypographyPanel
        className={cx(styles.typographyPanel, classes?.typographyPanel)}
        {...internalProps.typographyPanel}
        {...typographyPanel}/>
      <BackgroundPanel
        className={cx(styles.backgroundPanel, classes?.backgroundPanel)}
        {...internalProps.backgroundPanel}
        {...backgroundPanel}/>
      <BorderPanel
        className={cx(styles.borderPanel, classes?.borderPanel)}
        {...internalProps.borderPanel}
        {...borderPanel}/>
      <CornersPanel
        className={cx(styles.cornersPanel, classes?.cornersPanel)}
        {...internalProps.cornersPanel}
        {...cornersPanel}/>
      <StyleEffectsPanel
        className={cx(styles.styleEffectsPanel, classes?.styleEffectsPanel)}
        {...internalProps.styleEffectsPanel}
        {...styleEffectsPanel}/>
    </div>
  );
};

export default StylePanel;
