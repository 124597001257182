import React from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import styles from './App.module.scss';
import MainLayout from './components/layouts/MainLayout';
import PreviewLayout from './components/layouts/PreviewLayout';
import { ROUTES } from './lib/constants';
import { LocaleProvider } from './modules/locale';
import { StoreProvider } from './store/StoreProvider';
import { NavigateWrapperProvider } from './modules/navigation/context/NavigateWrapperContext';

export const router = createBrowserRouter([
  {
    path: `${ROUTES.preview}/*`,
    element: <PreviewLayout />,
  },
  {
    path: `${ROUTES.baseRoute}*`,
    element: (
      <NavigateWrapperProvider>
        <MainLayout />
      </NavigateWrapperProvider>),
  },
]);
export const App: React.FC = () => {
  return (
    <div className={styles.app}>
      <LocaleProvider>
        <StoreProvider>
          <RouterProvider
            router={router}
          />
        </StoreProvider>
      </LocaleProvider>
    </div>
  );
};
