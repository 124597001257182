import { ButtonProps } from '../../atoms/Button';
import { ErrorBarProps } from '../../atoms/ErrorBar';
import { TextInputProps } from '../../atoms/TextInput';
import { FunctionBlockProps } from '../../blocks/FunctionBlock';
import { InputBlockProps } from '../../blocks/InputBlock';
import { BreadcrumbItemListProps } from '../../molecules/BreadcrumbItemList';

type EndpointPageInternalProps = {
  breadcrumbItemList?: BreadcrumbItemListProps;
  textInput?: TextInputProps;
  button?: ButtonProps;
  button1?: ButtonProps;
  inputBlock?: InputBlockProps;
  functionBlock?: FunctionBlockProps;
  button2?: ButtonProps;
  errorBar?: ErrorBarProps;
};

type EndpointPagePropsValues = {
  'Default': EndpointPageInternalProps;
  'Error': EndpointPageInternalProps;
};

const propValues: EndpointPagePropsValues = {
  Default: {
    breadcrumbItemList: {
    },
    textInput: {
      state: 'Default',
      size: 'ExtraLarge',
    },
    button: {
      type: 'Text',
      size: 'M',
      state: 'Default',
      style: 'Primary',
    },
    button1: {
      type: 'Text',
      size: 'M',
      state: 'Default',
      style: 'Primary',
    },
    inputBlock: {
    },
    functionBlock: {
      state: 'Parameter',
    },
    button2: {
      type: 'Text',
      size: 'M',
      state: 'Default',
      style: 'Destructive',
    },
  },
  Error: {
    breadcrumbItemList: {
    },
    textInput: {
      state: 'Default',
      size: 'ExtraLarge',
    },
    button: {
      type: 'Text',
      size: 'M',
      state: 'Default',
      style: 'Primary',
    },
    button1: {
      type: 'Text',
      size: 'M',
      state: 'Default',
      style: 'Primary',
    },
    errorBar: {
    },
    inputBlock: {
    },
    functionBlock: {
      state: 'Parameter',
    },
    button2: {
      type: 'Text',
      size: 'M',
      state: 'Default',
      style: 'Destructive',
    },
  },
};

const getProps = (type: string): EndpointPageInternalProps => {
  const values: EndpointPageInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
