import { LeftPanelProps } from '../../organisms/LeftPanel';
import { StyleSettingsToggleProps } from '../../organisms/StyleSettingsToggle';

type LeftPanelInternalProps = {
  styleSettingsToggle?: StyleSettingsToggleProps;
  leftPanel?: LeftPanelProps;
};

type LeftPanelPropsValues = {
  '': LeftPanelInternalProps;
};

const propValues: LeftPanelPropsValues = {
  '': {
    styleSettingsToggle: {
    },
    leftPanel: {
      state: 'AddElementsPanel',
    },
  },
};

const getProps = (type: string): LeftPanelInternalProps => {
  const values: LeftPanelInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
