import { LogicSettingsHeaderProps } from '../../atoms/LogicSettingsHeader';
import { SelectedContainerProps } from '../../molecules/SelectedContainer';
import { ActionPanelProps } from '../ActionPanel';
import { ValuesPanelProps } from '../ValuesPanel';
import { VariableResponsePanelProps } from '../VariableResponsePanel';

type LogicSettingsPanelInternalProps = {
  logicSettingsHeader?: LogicSettingsHeaderProps;
  selectedContainer?: SelectedContainerProps;
  actionPanel?: ActionPanelProps;
  valuesPanel?: ValuesPanelProps;
  variableResponsePanel?: VariableResponsePanelProps;
};

type LogicSettingsPanelPropsValues = {
  '': LogicSettingsPanelInternalProps;
};

const propValues: LogicSettingsPanelPropsValues = {
  '': {
    logicSettingsHeader: {
    },
    selectedContainer: {
    },
    actionPanel: {
      type: 'EmptyState',
      state: 'Default',
    },
    valuesPanel: {
      state: 'Expanded',
    },
    variableResponsePanel: {
      state: 'Expanded',
    },
  },
};

const getProps = (type: string): LogicSettingsPanelInternalProps => {
  const values: LogicSettingsPanelInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
