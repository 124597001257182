import React from 'react';
import cx from 'classnames';

import TabItem from '../../atoms/TabItem';

import styles from './TabItemList.module.scss';
import usePresenter from './TabItemList.presenter';
import getProps from './TabItemList.props';
import { TabItemListCombinedProps } from './types';

const TabItemList: React.FC<TabItemListCombinedProps> = (props) => {
  const {
    className,
    classes,
    tabItems,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.tabItemList;

  const tabItemViews = tabItems?.map((tabItem, index) => (
    <TabItem
      key={index}
      className={cx(styles.tabItem, classes?.tabItem)}
      {...internalProps.tabItem}
      {...tabItem}
    />
  )) || [];

  return (
    <div className={cx(currentStyle, className)}>
      {tabItemViews}
    </div>
  );
};

export default TabItemList;
