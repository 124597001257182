import { Constants } from '../../../constants';
import { hexCodeInputValidation, isValidText, opacityInputValidation, valueWithFallback } from '../../../utils/inputValidations';
import { ColourRowCombinedProps } from './types';

const usePresenter = (props: ColourRowCombinedProps): ColourRowCombinedProps => {
  const { colourValue, defaultColourValue, onColourUpdated } = props;

  const updateColor = (value: string) => {
    if (onColourUpdated) {
      onColourUpdated({
        hexCode: `#${value}`,
        opacity: {
          unit: '%',
          value: colourValue?.opacity?.value || 0,
        },
      });
    }
  };

  const updateOpacity = (value: string) => {
    if (onColourUpdated) {
      onColourUpdated({
        hexCode: colourValue?.hexCode || defaultColourValue || '',
        opacity: {
          unit: '%',
          value: Number(value) || 0,
        },
      });
    }
  };

  return {
    ...props,
    colourPreview: {
      colorCode: colourValue?.hexCode || defaultColourValue,
    },
    hexCodeField: {
      textValue: valueWithFallback(colourValue?.hexCode, defaultColourValue || '000000').replace('#', ''),
      onValidateText: hexCodeInputValidation,
      onSubmit: (value) => updateColor(value),
    },
    opacityField: {
      textValue: valueWithFallback(colourValue?.opacity.value, 0),
      inputType: 'number',
      button: {
        text: {
          value: '%',
        },
      },
      onValidateText: opacityInputValidation,
      onSubmit: (value) => updateOpacity(value),
    },
  };
};

export default usePresenter;
