type UpdateArrowInternalProps = {
};

type UpdateArrowPropsValues = {
  '': UpdateArrowInternalProps;
};

const propValues: UpdateArrowPropsValues = {
  '': {
  },
};

const getProps = (type: string): UpdateArrowInternalProps => {
  const values: UpdateArrowInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
