import { TextProps } from '../Text';

type LineNumberInternalProps = {
  text?: TextProps;
};

type LineNumberPropsValues = {
  '': LineNumberInternalProps;
};

const propValues: LineNumberPropsValues = {
  '': {
    text: {
      type: 'LabelMedium',
      align: 'Right',
      style: 'Regular400',
      colour: 'NeutralHoverSelected',
    },
  },
};

const getProps = (type: string): LineNumberInternalProps => {
  const values: LineNumberInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
