import { observer } from 'mobx-react-lite';
import withModal from '../../../modules/common/ModalWrapper/context/withModal';
import AddImageMenu from '../AddImageMenu';
import SetVariableMenu from '../SetVariableMenu';
import InternalSettingsPanel from './SettingsPanel';
import type { SettingsPanelProps, SettingsPanelValueProps } from './types';

export type { SettingsPanelProps, SettingsPanelValueProps };
const [SettingsPanelWithVaraibleMenu, SetVariableMenuContext] = withModal(observer(InternalSettingsPanel), SetVariableMenu);
const [SettingsPanel, AddImageMenuContext] = withModal(SettingsPanelWithVaraibleMenu, AddImageMenu); // MANUAL OVERRIDE
export {
  SetVariableMenuContext,
  AddImageMenuContext,
};
export default SettingsPanel;
