/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import cx from 'classnames';

import Icon from '../Icon';
import Image from '../Image';
import Text from '../Text';

import styles from './DropdownItem.module.scss';
import usePresenter from './DropdownItem.presenter';
import getProps from './DropdownItem.props';
import { DropdownItemCombinedProps } from './types';

const DropdownItem: React.FC<DropdownItemCombinedProps> = (props) => {
  const {
    state,
    type,
    className,
    classes,
    text,
    icon,
    image,
    icon1,
    label,
    onClick, // MANUAL OVERRIDE
  } = usePresenter(props);

  const variantName = `${state}${type}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`dropdownItem${variantName}`];

  let componentView;

  switch (variantName) {
    case 'DefaultDefault':
    case 'DefaultTextOnly': {
      componentView = (
        // MANUAL OVERRIDE: Adding onClick and change to button
        <button
          className={cx(currentStyle, className)}
          onClick={(onClick)}
        >
          <Text
            className={cx(styles.text, classes?.text)}
            {...internalProps.text}
            {...text}
          />
        </button>
      );
      break;
    }
    case 'DefaultIconTextDescription': {
      componentView = (
        // MANUAL OVERRIDE: Adding onClick and change to button
        <button
          className={cx(currentStyle, className)}
          onClick={(onClick)}
        >
          <div className={cx(styles.iconContent, classes?.iconContent)}>
            <Icon
              className={cx(styles.icon, classes?.icon)}
              {...internalProps.icon}
              {...icon}/>
          </div>
          <div className={cx(styles.textContent, classes?.textContent)}>
            <Text
              className={cx(styles.label, classes?.label)}
              {...internalProps.label}
              {...label}/>
            <Text
              className={cx(styles.text, classes?.text)}
              {...internalProps.text}
              {...text}/>
          </div>
        </button>
      );
      break;
    }
    case 'DefaultIconText':
    case 'SelectedDefault': {
      componentView = (
        // MANUAL OVERRIDE: Adding onClick and change to button
        <button
          className={cx(currentStyle, className)}
          onClick={(onClick)}
        >
          <Icon
            className={cx(styles.icon, classes?.icon)}
            {...internalProps.icon}
            {...icon}
          />
          <Text
            className={cx(styles.text, classes?.text)}
            {...internalProps.text}
            {...text}
          />
        </button>
      );
      break;
    }
    case 'DefaultImageText': {
      componentView = (
        // MANUAL OVERRIDE: Adding onClick and change to button
        <button
          className={cx(currentStyle, className)}
          onClick={(onClick)}
        >
          <Image
            className={cx(styles.image, classes?.image)}
            {...internalProps.image}
            {...image}
          />
          <Text
            className={cx(styles.text, classes?.text)}
            {...internalProps.text}
            {...text}
          />
        </button>
      );
      break;
    }
    case 'SelectedIconText': {
      componentView = (
        // MANUAL OVERRIDE: Adding onClick and change to button
        <button
          className={cx(currentStyle, className)}
          onClick={(onClick)}
        >
          <Icon
            className={cx(styles.icon, classes?.icon)}
            {...internalProps.icon}
            {...icon}
          />
          <Icon
            className={cx(styles.icon1, classes?.icon1)}
            {...internalProps.icon1}
            {...icon1}
          />
          <Text
            className={cx(styles.text, classes?.text)}
            {...internalProps.text}
            {...text}
          />
        </button>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default DropdownItem;
