import { IconProps } from '../Icon';
import { ImageProps } from '../Image';
import { TextProps } from '../Text';

type ButtonInternalProps = {
  icon?: IconProps;
  text?: TextProps;
  image?: ImageProps;
  label?: TextProps;
  pageName?: TextProps;
};

type ButtonPropsValues = {
  'IconClearMDefaultPrimarySubdued': ButtonInternalProps;
  'IconClearMSelectedPrimarySubdued': ButtonInternalProps;
  'IconClearSDefaultPrimarySubdued': ButtonInternalProps;
  'IconClearSSelectedPrimarySubdued': ButtonInternalProps;
  'IconDarkSDefaultPrimarySubdued': ButtonInternalProps;
  'IconDarkSSelectedPrimarySubdued': ButtonInternalProps;
  'IconRoundedLDefaultPrimarySubdued': ButtonInternalProps;
  'IconRoundedLSelectedPrimarySubdued': ButtonInternalProps;
  'IconRoundedMDefaultPrimary': ButtonInternalProps;
  'IconRoundedMDefaultPrimarySubdued': ButtonInternalProps;
  'IconRoundedMSelectedPrimarySubdued': ButtonInternalProps;
  'IconRoundedSDefaultPrimary': ButtonInternalProps;
  'IconLDefaultPrimarySubdued': ButtonInternalProps;
  'IconLSelectedPrimarySubdued': ButtonInternalProps;
  'IconMDefaultPrimarySubdued': ButtonInternalProps;
  'IconMSelectedPrimarySubdued': ButtonInternalProps;
  'IconSDefaultPrimarySubdued': ButtonInternalProps;
  'IconSSelectedPrimarySubdued': ButtonInternalProps;
  'IconTextClearMDefaultPrimarySubdued': ButtonInternalProps;
  'IconTextClearMSelectedPrimarySubdued': ButtonInternalProps;
  'IconTextMDefaultPrimarySubdued': ButtonInternalProps;
  'IconTextMSelectedPrimarySubdued': ButtonInternalProps;
  'ImageMDefaultPrimarySubdued': ButtonInternalProps;
  'PageNameMDefaultPrimarySubdued': ButtonInternalProps;
  'TextIconMDefaultPrimarySubdued': ButtonInternalProps;
  'TextIconMSelectedPrimarySubdued': ButtonInternalProps;
  'TextOnlyMDefaultPrimarySubdued': ButtonInternalProps;
  'TextOnlyMSelectedPrimarySubdued': ButtonInternalProps;
  'TextMDefaultDestructive': ButtonInternalProps;
  'TextMDefaultDestructivePrimary': ButtonInternalProps;
  'TextMDefaultPrimary': ButtonInternalProps;
  'TextMDefaultPrimarySubdued': ButtonInternalProps;
  'TextMDefaultSecondary': ButtonInternalProps;
  'TextMSelectedDestructive': ButtonInternalProps;
  'TextMSelectedDestructivePrimary': ButtonInternalProps;
  'TextMSelectedPrimary': ButtonInternalProps;
  'TextMSelectedSecondary': ButtonInternalProps;
  'UnitButtonMDefaultPrimarySubdued': ButtonInternalProps;
  'UnitButtonMSelectedPrimarySubdued': ButtonInternalProps;
  'UnitButtonSDefaultPrimarySubdued': ButtonInternalProps;
  'UnitButtonSSelectedPrimarySubdued': ButtonInternalProps;
};

const propValues: ButtonPropsValues = {
  'IconClearMDefaultPrimarySubdued': {
    icon: {
      asset: 'Undo',
      colour: 'NeutralDefault',
    },
  },
  'IconClearMSelectedPrimarySubdued': {
    icon: {
      asset: 'Undo',
      colour: 'NeturalHoverSelected',
    },
  },
  'IconClearSDefaultPrimarySubdued': {
    icon: {
      asset: 'Undo',
      colour: 'NeutralDefault',
    },
  },
  'IconClearSSelectedPrimarySubdued': {
    icon: {
      asset: 'Undo',
      colour: 'NeturalHoverSelected',
    },
  },
  'IconDarkSDefaultPrimarySubdued': {
    icon: {
      asset: 'Undo',
      colour: 'NeutralDefault',
    },
  },
  'IconDarkSSelectedPrimarySubdued': {
    icon: {
      asset: 'Undo',
      colour: 'NeturalHoverSelected',
    },
  },
  'IconRoundedLDefaultPrimarySubdued': {
    icon: {
      asset: 'Undo',
      colour: 'NeutralDefault',
    },
  },
  'IconRoundedLSelectedPrimarySubdued': {
    icon: {
      asset: 'Undo',
      colour: 'NeturalHoverSelected',
    },
  },
  'IconRoundedMDefaultPrimary': {
    icon: {
      asset: 'Add',
      colour: 'NeturalHoverSelected',
    },
  },
  'IconRoundedMDefaultPrimarySubdued': {
    icon: {
      asset: 'Undo',
      colour: 'NeutralDefault',
    },
  },
  'IconRoundedMSelectedPrimarySubdued': {
    icon: {
      asset: 'Undo',
      colour: 'NeturalHoverSelected',
    },
  },
  'IconRoundedSDefaultPrimary': {
    icon: {
      asset: 'Add',
      colour: 'NeturalHoverSelected',
    },
  },
  'IconLDefaultPrimarySubdued': {
    icon: {
      asset: 'Undo',
      colour: 'NeutralDefault',
    },
  },
  'IconLSelectedPrimarySubdued': {
    icon: {
      asset: 'Undo',
      colour: 'NeturalHoverSelected',
    },
  },
  'IconMDefaultPrimarySubdued': {
    icon: {
      asset: 'Undo',
      colour: 'NeutralDefault',
    },
  },
  'IconMSelectedPrimarySubdued': {
    icon: {
      asset: 'Undo',
      colour: 'NeturalHoverSelected',
    },
  },
  'IconSDefaultPrimarySubdued': {
    icon: {
      asset: 'Undo',
      colour: 'NeutralDefault',
    },
  },
  'IconSSelectedPrimarySubdued': {
    icon: {
      asset: 'Undo',
      colour: 'NeturalHoverSelected',
    },
  },
  'IconTextClearMDefaultPrimarySubdued': {
    icon: {
      asset: 'Add',
      colour: 'NeturalHoverSelected',
    },
    text: {
      type: 'LabelSmall',
      align: 'Center',
      style: 'Medium500',
      colour: 'NeutralHoverSelected',
    },
  },
  'IconTextClearMSelectedPrimarySubdued': {
    icon: {
      asset: 'Add',
      colour: 'NeturalHoverSelected',
    },
    text: {
      type: 'LabelSmall',
      align: 'Center',
      style: 'Medium500',
      colour: 'NeutralHoverSelected',
    },
  },
  'IconTextMDefaultPrimarySubdued': {
    icon: {
      asset: 'Add',
      colour: 'NeturalHoverSelected',
    },
    text: {
      type: 'LabelSmall',
      align: 'Center',
      style: 'Medium500',
      colour: 'NeutralHoverSelected',
    },
  },
  'IconTextMSelectedPrimarySubdued': {
    icon: {
      asset: 'Add',
      colour: 'NeturalHoverSelected',
    },
    text: {
      type: 'LabelSmall',
      align: 'Center',
      style: 'Medium500',
      colour: 'NeutralHoverSelected',
    },
  },
  'ImageMDefaultPrimarySubdued': {
    image: {
    },
  },
  'PageNameMDefaultPrimarySubdued': {
    label: {
      type: 'LabelSmall',
      align: 'Center',
      style: 'Medium500',
      colour: 'NeutralDefault',
    },
    pageName: {
      type: 'LabelSmall',
      align: 'Center',
      style: 'Medium500',
      colour: 'NeutralDefault',
    },
  },
  'TextIconMDefaultPrimarySubdued': {
    text: {
      type: 'LabelSmall',
      align: 'Center',
      style: 'Medium500',
      colour: 'NeutralHoverSelected',
    },
    icon: {
      asset: 'Add',
      colour: 'NeturalHoverSelected',
    },
  },
  'TextIconMSelectedPrimarySubdued': {
    text: {
      type: 'LabelSmall',
      align: 'Center',
      style: 'Medium500',
      colour: 'NeutralHoverSelected',
    },
    icon: {
      asset: 'Add',
      colour: 'NeturalHoverSelected',
    },
  },
  'TextOnlyMDefaultPrimarySubdued': {
    text: {
      type: 'LabelSmall',
      align: 'Center',
      style: 'Medium500',
      colour: 'NeutralHoverSelected',
    },
  },
  'TextOnlyMSelectedPrimarySubdued': {
    text: {
      type: 'LabelSmall',
      align: 'Center',
      style: 'Medium500',
      colour: 'NeutralHoverSelected',
    },
  },
  'TextMDefaultDestructive': {
    text: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Medium500',
      colour: 'DestructiveSelected',
    },
  },
  'TextMDefaultDestructivePrimary': {
    text: {
      type: 'LabelSmall',
      align: 'Center',
      style: 'Medium500',
      colour: 'NeutralHoverSelected',
    },
  },
  'TextMDefaultPrimary': {
    text: {
      type: 'LabelSmall',
      align: 'Center',
      style: 'Medium500',
      colour: 'NeutralHoverSelected',
    },
  },
  'TextMDefaultPrimarySubdued': {
    text: {
      type: 'LabelSmall',
      align: 'Center',
      style: 'Medium500',
      colour: 'NeutralDefault',
    },
  },
  'TextMDefaultSecondary': {
    text: {
      type: 'LabelSmall',
      align: 'Center',
      style: 'Medium500',
      colour: 'NeutralDefault',
    },
  },
  'TextMSelectedDestructive': {
    text: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Medium500',
      colour: 'DestructiveSelected',
    },
  },
  'TextMSelectedDestructivePrimary': {
    text: {
      type: 'LabelSmall',
      align: 'Center',
      style: 'Medium500',
      colour: 'NeutralHoverSelected',
    },
  },
  'TextMSelectedPrimary': {
    text: {
      type: 'LabelSmall',
      align: 'Center',
      style: 'Medium500',
      colour: 'NeutralHoverSelected',
    },
  },
  'TextMSelectedSecondary': {
    text: {
      type: 'LabelSmall',
      align: 'Center',
      style: 'Medium500',
      colour: 'NeutralHoverSelected',
    },
  },
  'UnitButtonMDefaultPrimarySubdued': {
    text: {
      type: 'LabelSmall',
      align: 'Center',
      style: 'Medium500',
      colour: 'NeutralDefault',
    },
  },
  'UnitButtonMSelectedPrimarySubdued': {
    text: {
      type: 'LabelSmall',
      align: 'Center',
      style: 'Medium500',
      colour: 'NeutralHoverSelected',
    },
  },
  'UnitButtonSDefaultPrimarySubdued': {
    text: {
      type: 'LabelExtraSmall',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralDefault',
    },
  },
  'UnitButtonSSelectedPrimarySubdued': {
    text: {
      type: 'LabelExtraSmall',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralHoverSelected',
    },
  },
};

const getProps = (type: string): ButtonInternalProps => {
  const values: ButtonInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
