import { observer } from 'mobx-react-lite';
import InternalValuesPanel from './ValuesPanel';
import { ValuesPanelProps, ValuesPanelValueProps } from './types';
import SetVariableMenu from '../SetVariableMenu';
import withModal from '../../../modules/common/ModalWrapper/context/withModal';

export type { ValuesPanelProps, ValuesPanelValueProps };
const [ValuesPanel, SetVariableMenuContext] = withModal(observer(InternalValuesPanel), SetVariableMenu);
export { SetVariableMenuContext };
export default ValuesPanel;
