import { Constants } from '../../../../constants';
import PreviewBodyElement from '../../elements/PreviewBodyElement';
import PreviewButtonContainerElement from '../../elements/PreviewButtonContainerElement';
import PreviewContainerElement from '../../elements/PreviewContainerElement';
import PreviewImageElement from '../../elements/PreviewImageElement';
import PreviewTextElement from '../../elements/PreviewTextElement';
import type { ElementViews } from './types';

export const ELEMENT_VIEWS: ElementViews = {
  [Constants.ELEMENT_TYPES.BODY]: PreviewBodyElement,
  [Constants.ELEMENT_TYPES.CONTAINER]: PreviewContainerElement,
  [Constants.ELEMENT_TYPES.BUTTON_CONTAINER]: PreviewButtonContainerElement,
  [Constants.ELEMENT_TYPES.TEXT]: PreviewTextElement,
  [Constants.ELEMENT_TYPES.IMAGE]: PreviewImageElement,
};
