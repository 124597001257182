type IconInternalProps = {
};

type IconPropsValues = {
  'ActionNeturalHoverSelected': IconInternalProps;
  'AddNeturalHoverSelected': IconInternalProps;
  'AddNeutralDefault': IconInternalProps;
  'AddHomeNeturalHoverSelected': IconInternalProps;
  'AddHomeNeutralDefault': IconInternalProps;
  'AddVariableNeturalHoverSelected': IconInternalProps;
  'AddVariableNeutralDefault': IconInternalProps;
  'ApiEndpointNeturalHoverSelected': IconInternalProps;
  'ApiEndpointNeutralDefault': IconInternalProps;
  'ApiGroupNeturalHoverSelected': IconInternalProps;
  'ApiGroupNeutralDefault': IconInternalProps;
  'ArrowDownNeturalHoverSelected': IconInternalProps;
  'ArrowDownNeutralDefault': IconInternalProps;
  'ArrowLeftNeturalHoverSelected': IconInternalProps;
  'ArrowLeftNeutralDefault': IconInternalProps;
  'ArrowOutwardsRightNeturalHoverSelected': IconInternalProps;
  'ArrowRightNeturalHoverSelected': IconInternalProps;
  'ArrowRightNeutralDefault': IconInternalProps;
  'ArrowUpNeturalHoverSelected': IconInternalProps;
  'ArrowUpNeutralDefault': IconInternalProps;
  'AutoCursorCursor': IconInternalProps;
  'BodyNeturalHoverSelected': IconInternalProps;
  'BodyNeutralDefault': IconInternalProps;
  'BoldNeturalHoverSelected': IconInternalProps;
  'BoldNeutralDefault': IconInternalProps;
  'BorderNeturalHoverSelected': IconInternalProps;
  'BorderNeutralDefault': IconInternalProps;
  'BottomLeftCornerNeturalHoverSelected': IconInternalProps;
  'BottomRightCornerNeturalHoverSelected': IconInternalProps;
  'ButtonNeturalHoverSelected': IconInternalProps;
  'ButtonNeutralDefault': IconInternalProps;
  'CenterAlignNeturalHoverSelected': IconInternalProps;
  'CenterAlignNeutralDefault': IconInternalProps;
  'CheckNeturalHoverSelected': IconInternalProps;
  'CheckNeutralDefault': IconInternalProps;
  'CheckmarkCircleNeutralDefault': IconInternalProps;
  'ChevronDownNeturalHoverSelected': IconInternalProps;
  'ChevronDownNeutralDefault': IconInternalProps;
  'ChevronRightNeturalHoverSelected': IconInternalProps;
  'ChevronRightNeutralDefault': IconInternalProps;
  'ChevronUpNeturalHoverSelected': IconInternalProps;
  'ChevronUpNeutralDefault': IconInternalProps;
  'ClearFormatNeturalHoverSelected': IconInternalProps;
  'ClearFormatNeutralDefault': IconInternalProps;
  'CloseNeturalHoverSelected': IconInternalProps;
  'CloseNeutralDefault': IconInternalProps;
  'ClosedHandCursor': IconInternalProps;
  'CollectionItemVariableSelected': IconInternalProps;
  'CollectionListNeturalHoverSelected': IconInternalProps;
  'CollectionListNeutralDefault': IconInternalProps;
  'ContainerNeturalHoverSelected': IconInternalProps;
  'ContainerNeutralDefault': IconInternalProps;
  'CopyNeturalHoverSelected': IconInternalProps;
  'CopyNeutralDefault': IconInternalProps;
  'DashedBorderNeturalHoverSelected': IconInternalProps;
  'DashedBorderNeutralDefault': IconInternalProps;
  'EditNeturalHoverSelected': IconInternalProps;
  'EditNeutralDefault': IconInternalProps;
  'ErrorNeturalHoverSelected': IconInternalProps;
  'ErrorDotNeturalHoverSelected': IconInternalProps;
  'ExpandNeturalHoverSelected': IconInternalProps;
  'ExpandNeutralDefault': IconInternalProps;
  'FlowVariableVariableSelected': IconInternalProps;
  'GlobalVariableSelected': IconInternalProps;
  'HiddenNeturalHoverSelected': IconInternalProps;
  'HiddenNeutralDefault': IconInternalProps;
  'HomeNeturalHoverSelected': IconInternalProps;
  'HomeNeutralDefault': IconInternalProps;
  'ImageNeturalHoverSelected': IconInternalProps;
  'ImageNeutralDefault': IconInternalProps;
  'ItalicNeturalHoverSelected': IconInternalProps;
  'ItalicNeutralDefault': IconInternalProps;
  'JustifyNeturalHoverSelected': IconInternalProps;
  'JustifyNeutralDefault': IconInternalProps;
  'LaptopNeturalHoverSelected': IconInternalProps;
  'LaptopNeutralDefault': IconInternalProps;
  'LaptopDefaultNeturalHoverSelected': IconInternalProps;
  'LaptopDefaultNeutralDefault': IconInternalProps;
  'LayersNeturalHoverSelected': IconInternalProps;
  'LayersNeutralDefault': IconInternalProps;
  'LeftAlignNeturalHoverSelected': IconInternalProps;
  'LeftAlignNeutralDefault': IconInternalProps;
  'LetterSpaceNeturalHoverSelected': IconInternalProps;
  'LineHeightNeturalHoverSelected': IconInternalProps;
  'MobileNeturalHoverSelected': IconInternalProps;
  'MobileNeutralDefault': IconInternalProps;
  'MoreVertNeturalHoverSelected': IconInternalProps;
  'MoreVertNeutralDefault': IconInternalProps;
  'NullNeturalHoverSelected': IconInternalProps;
  'NullNeutralDefault': IconInternalProps;
  'OpenHandNeturalHoverSelected': IconInternalProps;
  'OverlineNeturalHoverSelected': IconInternalProps;
  'OverlineNeutralDefault': IconInternalProps;
  'PageVariableSelected': IconInternalProps;
  'PointerCursor': IconInternalProps;
  'RedoNeturalHoverSelected': IconInternalProps;
  'RedoNeutralDefault': IconInternalProps;
  'RightAlignNeturalHoverSelected': IconInternalProps;
  'RightAlignNeutralDefault': IconInternalProps;
  'RomanNeturalHoverSelected': IconInternalProps;
  'RomanNeutralDefault': IconInternalProps;
  'SeperateCornersNeturalHoverSelected': IconInternalProps;
  'SeperateCornersNeutralDefault': IconInternalProps;
  'StrikethroughNeturalHoverSelected': IconInternalProps;
  'StrikethroughNeutralDefault': IconInternalProps;
  'TabletNeturalHoverSelected': IconInternalProps;
  'TabletNeutralDefault': IconInternalProps;
  'TextNeturalHoverSelected': IconInternalProps;
  'TextNeutralDefault': IconInternalProps;
  'TopLeftCornerNeturalHoverSelected': IconInternalProps;
  'TopRightCornerNeturalHoverSelected': IconInternalProps;
  'UnderlineNeturalHoverSelected': IconInternalProps;
  'UnderlineNeutralDefault': IconInternalProps;
  'UndoNeturalHoverSelected': IconInternalProps;
  'UndoNeutralDefault': IconInternalProps;
  'UnfoldMoreNeturalHoverSelected': IconInternalProps;
  'UnfoldMoreNeutralDefault': IconInternalProps;
  'UnionCornersNeturalHoverSelected': IconInternalProps;
  'UnionCornersNeutralDefault': IconInternalProps;
  'VariableNeturalHoverSelected': IconInternalProps;
  'VariableNeutralDefault': IconInternalProps;
  'VariableVariableSelected': IconInternalProps;
  'VisibilityNeturalHoverSelected': IconInternalProps;
  'VisibilityNeutralDefault': IconInternalProps;
};

const propValues: IconPropsValues = {
  'ActionNeturalHoverSelected': {
  },
  'AddNeturalHoverSelected': {
  },
  'AddNeutralDefault': {
  },
  'AddHomeNeturalHoverSelected': {
  },
  'AddHomeNeutralDefault': {
  },
  'AddVariableNeturalHoverSelected': {
  },
  'AddVariableNeutralDefault': {
  },
  'ApiEndpointNeturalHoverSelected': {
  },
  'ApiEndpointNeutralDefault': {
  },
  'ApiGroupNeturalHoverSelected': {
  },
  'ApiGroupNeutralDefault': {
  },
  'ArrowDownNeturalHoverSelected': {
  },
  'ArrowDownNeutralDefault': {
  },
  'ArrowLeftNeturalHoverSelected': {
  },
  'ArrowLeftNeutralDefault': {
  },
  'ArrowOutwardsRightNeturalHoverSelected': {
  },
  'ArrowRightNeturalHoverSelected': {
  },
  'ArrowRightNeutralDefault': {
  },
  'ArrowUpNeturalHoverSelected': {
  },
  'ArrowUpNeutralDefault': {
  },
  'AutoCursorCursor': {
  },
  'BodyNeturalHoverSelected': {
  },
  'BodyNeutralDefault': {
  },
  'BoldNeturalHoverSelected': {
  },
  'BoldNeutralDefault': {
  },
  'BorderNeturalHoverSelected': {
  },
  'BorderNeutralDefault': {
  },
  'BottomLeftCornerNeturalHoverSelected': {
  },
  'BottomRightCornerNeturalHoverSelected': {
  },
  'ButtonNeturalHoverSelected': {
  },
  'ButtonNeutralDefault': {
  },
  'CenterAlignNeturalHoverSelected': {
  },
  'CenterAlignNeutralDefault': {
  },
  'CheckNeturalHoverSelected': {
  },
  'CheckNeutralDefault': {
  },
  'CheckmarkCircleNeutralDefault': {
  },
  'ChevronDownNeturalHoverSelected': {
  },
  'ChevronDownNeutralDefault': {
  },
  'ChevronRightNeturalHoverSelected': {
  },
  'ChevronRightNeutralDefault': {
  },
  'ChevronUpNeturalHoverSelected': {
  },
  'ChevronUpNeutralDefault': {
  },
  'ClearFormatNeturalHoverSelected': {
  },
  'ClearFormatNeutralDefault': {
  },
  'CloseNeturalHoverSelected': {
  },
  'CloseNeutralDefault': {
  },
  'ClosedHandCursor': {
  },
  'CollectionItemVariableSelected': {
  },
  'CollectionListNeturalHoverSelected': {
  },
  'CollectionListNeutralDefault': {
  },
  'ContainerNeturalHoverSelected': {
  },
  'ContainerNeutralDefault': {
  },
  'CopyNeturalHoverSelected': {
  },
  'CopyNeutralDefault': {
  },
  'DashedBorderNeturalHoverSelected': {
  },
  'DashedBorderNeutralDefault': {
  },
  'EditNeturalHoverSelected': {
  },
  'EditNeutralDefault': {
  },
  'ErrorNeturalHoverSelected': {
  },
  'ErrorDotNeturalHoverSelected': {
  },
  'ExpandNeturalHoverSelected': {
  },
  'ExpandNeutralDefault': {
  },
  'FlowVariableVariableSelected': {
  },
  'GlobalVariableSelected': {
  },
  'HiddenNeturalHoverSelected': {
  },
  'HiddenNeutralDefault': {
  },
  'HomeNeturalHoverSelected': {
  },
  'HomeNeutralDefault': {
  },
  'ImageNeturalHoverSelected': {
  },
  'ImageNeutralDefault': {
  },
  'ItalicNeturalHoverSelected': {
  },
  'ItalicNeutralDefault': {
  },
  'JustifyNeturalHoverSelected': {
  },
  'JustifyNeutralDefault': {
  },
  'LaptopNeturalHoverSelected': {
  },
  'LaptopNeutralDefault': {
  },
  'LaptopDefaultNeturalHoverSelected': {
  },
  'LaptopDefaultNeutralDefault': {
  },
  'LayersNeturalHoverSelected': {
  },
  'LayersNeutralDefault': {
  },
  'LeftAlignNeturalHoverSelected': {
  },
  'LeftAlignNeutralDefault': {
  },
  'LetterSpaceNeturalHoverSelected': {
  },
  'LineHeightNeturalHoverSelected': {
  },
  'MobileNeturalHoverSelected': {
  },
  'MobileNeutralDefault': {
  },
  'MoreVertNeturalHoverSelected': {
  },
  'MoreVertNeutralDefault': {
  },
  'NullNeturalHoverSelected': {
  },
  'NullNeutralDefault': {
  },
  'OpenHandNeturalHoverSelected': {
  },
  'OverlineNeturalHoverSelected': {
  },
  'OverlineNeutralDefault': {
  },
  'PageVariableSelected': {
  },
  'PointerCursor': {
  },
  'RedoNeturalHoverSelected': {
  },
  'RedoNeutralDefault': {
  },
  'RightAlignNeturalHoverSelected': {
  },
  'RightAlignNeutralDefault': {
  },
  'RomanNeturalHoverSelected': {
  },
  'RomanNeutralDefault': {
  },
  'SeperateCornersNeturalHoverSelected': {
  },
  'SeperateCornersNeutralDefault': {
  },
  'StrikethroughNeturalHoverSelected': {
  },
  'StrikethroughNeutralDefault': {
  },
  'TabletNeturalHoverSelected': {
  },
  'TabletNeutralDefault': {
  },
  'TextNeturalHoverSelected': {
  },
  'TextNeutralDefault': {
  },
  'TopLeftCornerNeturalHoverSelected': {
  },
  'TopRightCornerNeturalHoverSelected': {
  },
  'UnderlineNeturalHoverSelected': {
  },
  'UnderlineNeutralDefault': {
  },
  'UndoNeturalHoverSelected': {
  },
  'UndoNeutralDefault': {
  },
  'UnfoldMoreNeturalHoverSelected': {
  },
  'UnfoldMoreNeutralDefault': {
  },
  'UnionCornersNeturalHoverSelected': {
  },
  'UnionCornersNeutralDefault': {
  },
  'VariableNeturalHoverSelected': {
  },
  'VariableNeutralDefault': {
  },
  'VariableVariableSelected': {
  },
  'VisibilityNeturalHoverSelected': {
  },
  'VisibilityNeutralDefault': {
  },
};

const getProps = (type: string): IconInternalProps => {
  const values: IconInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
