import Default from './Default.svg';
import HPBR from './HPBR.svg';
import HSAB from './HSAB.svg';
import HSBB from './HSBB.svg';
import VPBR from './VPBR.svg';
import VSAR from './VSAR.svg';
import VSBR from './VSBR.svg';

export const BottomRight = {
  Default,
  'horizontal-packed-bottom-right': HPBR,
  'horizontal-space-around-bottom-none': HSAB,
  'horizontal-space-between-bottom-none': HSBB,
  'vertical-packed-bottom-right': VPBR,
  'vertical-space-around-none-right': VSAR,
  'vertical-space-between-none-right': VSBR,
};
