import cx from 'classnames';
import { comparer, computed } from 'mobx';
import {
  useCallback, useContext, useEffect, useRef, useState, type CSSProperties, type FocusEventHandler, type KeyboardEventHandler, type MouseEventHandler,
} from 'react';
import { Constants } from '../../../constants';
import { useElementDragState } from '../../../modules/canvas/hooks/useElementDragState';
import { useElementDropState } from '../../../modules/canvas/hooks/useElementDropState';
import { useElementHoverable } from '../../../modules/canvas/hooks/useElementHoverable';
import { useElementSelectable } from '../../../modules/canvas/hooks/useElementSelectable';
import { ELEMENT_CONFIGURATIONS } from '../../../store/PageStore/config';
import { getElement } from '../../../store/PageStore/utils';
import { StoreContext } from '../../../store/StoreProvider';
import type { IElementDragHoveredState } from '../../../store/types';
import { typedDeepMerge } from '../../../utils/objectUtils';
import type { IconAssetEnum } from '../../atoms/Icon/types';
import type { LayerLabelItemListCombinedProps } from '../LayerLabelItemList/types';
import styles from './LayerLabelItem.module.scss';
import type { LayerLabelItemCombinedProps, LayerLabelItemStyleEnum, LayerLabelItemTypeEnum } from './types';

const usePresenter = (props: LayerLabelItemCombinedProps): LayerLabelItemCombinedProps => {
  const { pageStore } = useContext(StoreContext);

  const { element, classes, level = 0 } = props;
  const elementId: string = element?.id ?? '';

  const elementRef = useRef<HTMLDivElement>(null);
  const textInputRef = useRef<HTMLInputElement>(null);

  const { elementSelectionState, onClick: onElementSelected } = useElementSelectable({ id: elementId, shouldAutoExpandAndScrollIntoView: false });
  const { isElementSelected, shouldAutoExpandAndScrollIntoView: isAutoScrollForSelected } = elementSelectionState;

  const { isElementHovered, ...hoverHandlers } = useElementHoverable({ id: elementId });

  const { isElementDragging } = useElementDragState(element);

  const { isDropAllowed, isDropForbidden } = useElementDropState({ id: elementId });

  const { isElementDragHovered, shouldAutoExpandAndScrollIntoView: isAutoScrollForDragHovered }: IElementDragHoveredState = computed(
    () => pageStore.getElementDragHoveredState({ id: elementId }),
    // Use MobX structural comparer because we get an object, not a single primitive value.
    // This is to prevent unnecessary re-rendering.
    { equals: comparer.structural },
  ).get();

  // Scroll the selected element into view within the layers tree
  useEffect(() => {
    // Do not scroll if selection is made from the layers tree.
    // This is to prevent the selected element from auto-scolling away immediately after selection.
    if (elementRef.current && ((isElementSelected && isAutoScrollForSelected) || (isElementDragHovered && isAutoScrollForDragHovered))) {
      const layersListContainer = document.getElementById(Constants.ID.LAYERS_LIST_CONTAINER);
      if (layersListContainer) {
        const {
          offsetLeft: containerLeft,
          offsetTop: containerTop,
          offsetWidth: containerWidth,
          offsetHeight: containerHeight,
        } = layersListContainer;

        const {
          offsetLeft: elementLeft,
          offsetTop: elementTop,
        } = elementRef.current;

        const additionalOffsetInPx = 48;

        layersListContainer.scrollTo({
          // Try to position at 1/4 of the layers tree width horizontally
          left: Math.min(elementLeft - containerLeft - additionalOffsetInPx, containerWidth / 4),
          // Try to center vertically within the layers tree
          top: elementTop - containerTop - containerHeight / 2,
          behavior: 'smooth',
        });
      }
    }
  }, [isElementSelected, isAutoScrollForSelected, isElementDragHovered, isAutoScrollForDragHovered]);

  // Check if element is the main container
  // Main container must be the only child of the Body container
  const isMainContainer: boolean = computed(() => (element ? pageStore.isMainContainer(element) : false)).get();

  const iconAsset: IconAssetEnum | undefined = element
    ? (isMainContainer ? 'Body' : ELEMENT_CONFIGURATIONS[element.type].icon)
    : undefined;

  const elementState = computed(() => pageStore.getElementState({ id: elementId })).get();

  const contentClassName: string = cx(classes?.content, {
    [styles.disabled]: isElementDragging || isDropForbidden,
  });

  const childLayerLabelItemList: LayerLabelItemListCombinedProps = {
    layerLabelItems: !!element && elementState.startsWith('Expanded') ? element.childIds.map((childId) => ({ element: getElement(childId, pageStore) })) : [],
    classes: {
      content: contentClassName,
    },
  };

  // Indentation for the layers tree
  const paddingLeft: CSSProperties['paddingLeft'] = `${(level * 1.5) + (elementState.startsWith('Default') ? 2 : 0.5)}rem`;

  const isElementNameEdited: boolean = computed(() => pageStore.isElementNameEdited(elementId)).get();
  const [elementEditedName, setElementEditedName] = useState<string>('');
  const [shouldSelectElementEditedName, setShouldSelectElementEditedName] = useState<boolean>(false);

  useEffect(() => {
    // Initialise element edited name with the current element name
    if (isElementNameEdited && element) {
      setElementEditedName(element.name);
      setShouldSelectElementEditedName(true);
    }
  }, [isElementNameEdited, element]);

  useEffect(() => {
    // Once element edited name is initialised then set focus and select the entire name
    if (textInputRef.current && shouldSelectElementEditedName) {
      textInputRef.current.focus();
      textInputRef.current.select();
      setShouldSelectElementEditedName(false);
    }
  }, [shouldSelectElementEditedName]);

  const onElementNameChanged = useCallback((newElementName: string) => {
    // Element names must be up to 30 characters long
    // They may only include letters, numbers or spaces
    const validatedNewElementName: string = newElementName
      .substring(0, Constants.LIMITS.ELEMENT_NAME_MAX_LENGTH)
      .replace(Constants.REGEX.INVALID_ELEMENT_NAME_CHARS, '');
    setElementEditedName(validatedNewElementName);
  }, []);

  const onElementNameSubmitted = useCallback(async () => {
    const trimmedElementEditedName: string = elementEditedName.trim();
    try {
      // TODO: Do we need to check if the element name is unique per page?
      if (element && isElementNameEdited && trimmedElementEditedName) {
        await pageStore.updateElement(element, { name: trimmedElementEditedName });
      }
    } finally {
      await pageStore.exitPageOrElementEditMode();
    }
  }, [element, isElementNameEdited, elementEditedName, pageStore]);

  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  const onTextInputKeyDown: KeyboardEventHandler = useCallback(async (event) => {
    event.stopPropagation();
    if (event.key === 'Enter') {
      await onElementNameSubmitted();
    } else if (event.key === 'Escape') {
      await pageStore.exitPageOrElementEditMode();
    }
  }, [onElementNameSubmitted, pageStore]);

  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  const onTextInputBlur: FocusEventHandler = useCallback(async (event) => {
    event.stopPropagation();
    await onElementNameSubmitted();
  }, [onElementNameSubmitted]);

  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  const onElementClick: MouseEventHandler = useCallback((event) => {
    switch (event.detail) {
      // Single click - Select element
      case 1: {
        onElementSelected(event);
        break;
      }
      // Double click - Rename element
      case 2: {
        pageStore.setElementIdWithEditedName(elementId);
        break;
      }
      default: break;
    }
  }, [elementId, onElementSelected, pageStore]);

  // eslint-disable-next-line consistent-return
  const getType = (): LayerLabelItemTypeEnum => {
    // eslint-disable-next-line default-case
    switch (elementState) {
      case 'Collapsed':
      case 'CollapsedHidden':
        return 'Collapsed';
      case 'Expanded':
      case 'ExpandedHidden':
        return 'Expanded';
      case 'Default':
      case 'DefaultHidden':
        return 'Default';
    }
  };

  // eslint-disable-next-line consistent-return
  const getStyle = (): LayerLabelItemStyleEnum => {
    // eslint-disable-next-line default-case
    switch (elementState) {
      case 'Collapsed':
      case 'Expanded':
      case 'Default':
        return 'Default';
      case 'CollapsedHidden':
      case 'ExpandedHidden':
      case 'DefaultHidden':
        return 'Hidden';
    }
  };

  return {
    ...typedDeepMerge(props, {
      type: getType(),
      state: isElementSelected ? (isElementNameEdited ? 'EditableSelected' : 'Selected') : 'Default',
      style: getStyle(),
      button: !elementState.startsWith('Default') ? {
        icon: {
          asset: elementState.startsWith('Expanded') ? 'ChevronDown' : 'ChevronRight',
        },
        onClick: (event) => {
          event?.stopPropagation();
          pageStore.toggleElement({ id: elementId });
        },
      } : undefined,
      icon: {
        asset: iconAsset,
        colour: isElementSelected || ((isElementHovered || isDropAllowed) && !isElementDragging) ? 'NeturalHoverSelected' : 'NeutralDefault',
      },
      text: {
        value: element?.name,
        colour: isElementSelected || ((isElementHovered || isDropAllowed) && !isElementDragging) ? 'NeutralHoverSelected' : 'NeutralDefault',
      },
      classes: {
        content: contentClassName,
      },
      level,
      paddingLeft,
      isElementHovered,
      ...hoverHandlers,
      onClick: onElementClick,
    }),
    textInput: {
      ...props.textInput,
      textInputRef,
      textValue: elementEditedName,
      onTextChanged: onElementNameChanged,
      onKeyDown: onTextInputKeyDown,
      onBlur: onTextInputBlur,
    },
    elementRef,
    childLayerLabelItemList,
  };
};

export default usePresenter;
