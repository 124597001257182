import React from 'react';
import cx from 'classnames';

import Text from '../../atoms/Text';

import styles from './DuoToneTextDefault.module.scss';
import usePresenter from './DuoToneTextDefault.presenter';
import getProps from './DuoToneTextDefault.props';
import { DuoToneTextDefaultCombinedProps } from './types';

const DuoToneTextDefault: React.FC<DuoToneTextDefaultCombinedProps> = (props) => {
  const {
    className,
    classes,
    lightText,
    darkText,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.duoToneTextDefault;

  return (
    <div className={cx(currentStyle, className)}>
      <Text
        className={cx(styles.lightText, classes?.lightText)}
        {...internalProps.lightText}
        {...lightText}
      />
      <Text
        className={cx(styles.darkText, classes?.darkText)}
        {...internalProps.darkText}
        {...darkText}
      />
    </div>
  );
};

export default DuoToneTextDefault;
