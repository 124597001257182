import { ButtonProps } from '../../atoms/Button';
import { DropdownButtonProps } from '../../atoms/DropdownButton';
import { InputFieldProps } from '../../atoms/InputField';
import { RowLabelProps } from '../../atoms/RowLabel';
import { PanelHeaderProps } from '../../molecules/PanelHeader';
import { PropertyItemListProps } from '../../molecules/PropertyItemList';
import { ToggleItemListProps } from '../../molecules/ToggleItemList';

type StyleEffectsPanelInternalProps = {
  panelHeader?: PanelHeaderProps;
  rowLabel?: RowLabelProps;
  toggleItemList?: ToggleItemListProps;
  rowLabel1?: RowLabelProps;
  inputField?: InputFieldProps;
  rowLabel2?: RowLabelProps;
  button?: ButtonProps;
  rowLabel3?: RowLabelProps;
  dropdownButton?: DropdownButtonProps;
  propertyItemList?: PropertyItemListProps;
};

type StyleEffectsPanelPropsValues = {
  'Collapsed': StyleEffectsPanelInternalProps;
  'Expanded': StyleEffectsPanelInternalProps;
  'ExpandedShadows': StyleEffectsPanelInternalProps;
};

const propValues: StyleEffectsPanelPropsValues = {
  Collapsed: {
    panelHeader: {
      state: 'Collapsed',
      type: 'Default',
    },
  },
  Expanded: {
    panelHeader: {
      state: 'Expanded',
      type: 'Default',
    },
    rowLabel: {
      state: 'Default',
      style: 'Default',
      type: 'Panel',
    },
    toggleItemList: {
      size: 'Small',
      type: 'IconText',
      style: 'Default',
    },
    rowLabel1: {
      state: 'Default',
      style: 'Default',
      type: 'Panel',
    },
    inputField: {
      type: 'TextUnit',
      size: 'Medium',
      state: 'Default',
    },
    rowLabel2: {
      state: 'Default',
      style: 'Default',
      type: 'Panel',
    },
    button: {
      type: 'IconText',
      size: 'M',
      state: 'Default',
      style: 'PrimarySubdued',
    },
    rowLabel3: {
      state: 'Default',
      style: 'Default',
      type: 'Panel',
    },
    dropdownButton: {
      type: 'IconText',
      style: 'Default',
      state: 'Collapsed',
    },
  },
  ExpandedShadows: {
    panelHeader: {
      state: 'Expanded',
      type: 'Default',
    },
    rowLabel: {
      state: 'Default',
      style: 'Default',
      type: 'Panel',
    },
    toggleItemList: {
      size: 'Small',
      type: 'IconText',
      style: 'Default',
    },
    rowLabel1: {
      state: 'Default',
      style: 'Default',
      type: 'Panel',
    },
    inputField: {
      type: 'TextUnit',
      size: 'Medium',
      state: 'Default',
    },
    rowLabel2: {
      state: 'Default',
      style: 'Default',
      type: 'Panel',
    },
    button: {
      type: 'IconText',
      size: 'M',
      state: 'Default',
      style: 'PrimarySubdued',
    },
    propertyItemList: {
    },
    rowLabel3: {
      state: 'Default',
      style: 'Default',
      type: 'Panel',
    },
    dropdownButton: {
      type: 'IconText',
      style: 'Default',
      state: 'Collapsed',
    },
  },
};

const getProps = (type: string): StyleEffectsPanelInternalProps => {
  const values: StyleEffectsPanelInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
