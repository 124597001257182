import { IconProps } from '../Icon';
import { TextProps } from '../Text';
import { UnderlineProps } from '../Underline';

type TabItemInternalProps = {
  text?: TextProps;
  icon?: IconProps;
  underline?: UnderlineProps;
};

type TabItemPropsValues = {
  'Default': TabItemInternalProps;
  'Error': TabItemInternalProps;
  'Selected': TabItemInternalProps;
};

const propValues: TabItemPropsValues = {
  Default: {
    text: {
      type: 'LabelMedium',
      align: 'Left',
      style: 'Regular400',
      colour: 'NeutralDefault',
    },
  },
  Error: {
    text: {
      type: 'LabelMedium',
      align: 'Left',
      style: 'Regular400',
      colour: 'NeutralDefault',
    },
    icon: {
      asset: 'ErrorDot',
      colour: 'NeturalHoverSelected',
    },
  },
  Selected: {
    text: {
      type: 'LabelMedium',
      align: 'Left',
      style: 'Regular400',
      colour: 'NeutralHoverSelected',
    },
    underline: {
    },
  },
};

const getProps = (type: string): TabItemInternalProps => {
  const values: TabItemInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
