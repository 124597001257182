import { CombinedDropDownValueProps } from '.';
import { Constants } from '../../../constants';
import { PageStore } from '../../../store/PageStore/PageStore';
import {
  getAnchorOffsetUnit,
  getAnchorOffsetValue,
  getUnitsBasedOnProperty,
  getUnitTypeUnit,
  getUnitTypeValue,
  isHugFillOrNone,
  updateAnchorOffsetUnit,
  updateAnchorOffsetValue,
  updateUnitTypeUnit,
  updateUnitTypeValue,
} from '../../../store/PageStore/utils';
import { AnchorProperty, Element, Unit, UnitTypeProperty } from '../../../store/types';
import { unitValueValidation } from '../../../utils/inputValidations';

export const buildFieldWithDropdownProps = (
  inputValue: string,
  inputType: 'number' | 'text',
  dropdownValue: string,
  allValues: readonly string[],
  onValueChanged: (value: string) => void,
  onOptionSelected: (option: string) => void,
  transformOption: (value: string, forToggle?: boolean) => string,
  onValidateText: (newValue: string, dropdownValue: string) => boolean,
): CombinedDropDownValueProps => {
  return {
    toggleProps: {
      inputField: {
        inputType,
        textValue: inputValue,
        onValidateText: (newValue) => onValidateText(newValue, dropdownValue),
        onSubmit: (newValue) => onValueChanged(newValue),
        button: {
          text: {
            value: transformOption(dropdownValue, true),
          },
        },
      },
    },
    menuProps: {
      style: 'Checkmark',
      dropdownItems: allValues.map((option) => {
        const isSelected = option === dropdownValue;
        return {
          text: {
            value: transformOption(option),
          },
          state: isSelected ? 'Selected' : 'Default',
          icon: isSelected ? {
            asset: 'Check',
          } : undefined,
        };
      }),
      onOptionSelected,
    },
  };
};

export const buildUnitTypeFieldWithDropdownProps = (
  pageStore: PageStore,
  selectedElement: Element | undefined,
  property: UnitTypeProperty,
): CombinedDropDownValueProps => {
  const unitTypeUnit = getUnitTypeUnit(selectedElement, property);
  return buildFieldWithDropdownProps(
    getUnitTypeValue(selectedElement, property),
    isHugFillOrNone(unitTypeUnit) ? 'text' : 'number',
    unitTypeUnit,
    getUnitsBasedOnProperty(property),
    (value) => updateUnitTypeValue(pageStore, selectedElement, property, value),
    (option) => updateUnitTypeUnit(pageStore, selectedElement, property, option),
    (unit, forToggle) => {
      if (forToggle && isHugFillOrNone(unit)) {
        return '-';
      }
      return unit;
    },
    (newValue, dropdownValue) => unitValueValidation(newValue, dropdownValue as Unit),
  );
};

export const buildAnchorFieldWithDropdownProps = (
  pageStore: PageStore,
  selectedElement: Element | undefined,
  property: AnchorProperty,
): CombinedDropDownValueProps => {
  const anchorOffsetUnit = getAnchorOffsetUnit(selectedElement, property);
  return buildFieldWithDropdownProps(
    getAnchorOffsetValue(selectedElement, property),
    isHugFillOrNone(anchorOffsetUnit) ? 'text' : 'number',
    anchorOffsetUnit,
    getUnitsBasedOnProperty(property),
    (value) => updateAnchorOffsetValue(pageStore, selectedElement, property, value),
    (option) => updateAnchorOffsetUnit(pageStore, selectedElement, property, option),
    (unit, forToggle) => {
      if (forToggle && isHugFillOrNone(unit)) {
        return '-';
      }
      return unit;
    },
    (newValue, dropdownValue) => unitValueValidation(newValue, dropdownValue as Unit, true),
  );
};
