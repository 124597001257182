import React from 'react';
import cx from 'classnames';

import TestResponseItem from '../TestResponseItem';

import styles from './TestResponseItemList.module.scss';
import usePresenter from './TestResponseItemList.presenter';
import getProps from './TestResponseItemList.props';
import { TestResponseItemListCombinedProps } from './types';

const TestResponseItemList: React.FC<TestResponseItemListCombinedProps> = (props) => {
  const {
    className,
    classes,
    testResponseItems,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.testResponseItemList;

  const testResponseItemViews = testResponseItems?.map((testResponseItem, index) => (
    <TestResponseItem
      key={index}
      className={cx(styles.testResponseItem, classes?.testResponseItem)}
      {...internalProps.testResponseItem}
      {...testResponseItem} />
  )) || [];

  return (
    <div className={cx(currentStyle, className)}>
      {testResponseItemViews}
    </div>
  );
};

export default TestResponseItemList;
