import { computed } from 'mobx';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSettingsHeaderCombinedProps } from './types';
import { StoreContext } from '../../../store/StoreProvider';
import { RightPanelType } from '../../../store/types';
import { ToggleItemListValueProps } from '../../molecules/ToggleItemList/types';

const usePresenter = (props: StyleSettingsHeaderCombinedProps): StyleSettingsHeaderCombinedProps => {
  const { appStore } = useContext(StoreContext);
  const { t } = useTranslation();

  const handleUpdateRightPanel = (option: string) => {
    appStore.setRightPanelType(option as RightPanelType);
  };

  const toggleItemList = computed((): ToggleItemListValueProps<RightPanelType> => (
    {
      currentValue: appStore.rightPanelType,
      toggleOptions: [
        {
          value: t('styleSettingsToggle.style'),
        },
        {
          value: t('styleSettingsToggle.settings'),
        },
      ],
      onOptionSelected: (option) => handleUpdateRightPanel(option),
    }
  )).get();

  return {
    ...props,
    styleSettingsToggle: {
      toggleItemList,
    },
  };
};

export default usePresenter;
