import { ErrorBarProps } from '../../atoms/ErrorBar';
import { KeyValueTableProps } from '../../organisms/KeyValueTable';

type HeaderBlockInternalProps = {
  keyValueTable?: KeyValueTableProps;
  errorBar?: ErrorBarProps;
};

type HeaderBlockPropsValues = {
  'Default': HeaderBlockInternalProps;
  'Error': HeaderBlockInternalProps;
};

const propValues: HeaderBlockPropsValues = {
  Default: {
    keyValueTable: {
      state: 'Empty',
      type: 'Default',
    },
  },
  Error: {
    errorBar: {
    },
    keyValueTable: {
      state: 'Empty',
      type: 'Default',
    },
  },
};

const getProps = (type: string): HeaderBlockInternalProps => {
  const values: HeaderBlockInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
