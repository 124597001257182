import { Edge, Node, NodeTypes } from 'reactflow';

type ActionContainerInternalProps = {
  nodeTypes?: Record<any, React.FC<any>>,
  nodes?: Node[],
  edges?: Edge[],
};

type ActionContainerPropsValues = {
  '': ActionContainerInternalProps;
};

const propValues: ActionContainerPropsValues = {
  '': {
    nodeTypes: {},
    nodes: [],
    edges: [],
  },
};

const getProps = (type: string): ActionContainerInternalProps => {
  const values: ActionContainerInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
