import Default from './Default.svg';
import HPTC from './HPTC.svg';
import HSAT from './HSAT.svg';
import HSBT from './HSBT.svg';
import VPTC from './VPTC.svg';
import VSAC from './VSAC.svg';
import VSBC from './VSBC.svg';

export const TopCenter = {
  Default,
  'horizontal-packed-top-center': HPTC,
  'horizontal-space-around-top-none': HSAT,
  'horizontal-space-between-top-none': HSBT,
  'vertical-packed-top-center': VPTC,
  'vertical-space-around-none-center': VSAC,
  'vertical-space-between-none-center': VSBC,
};
