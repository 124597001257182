import { useCallback, useContext, useEffect, useRef } from 'react';
import { useLocation } from 'react-router';
import { useResizeObserver } from '../../../modules/canvas/hooks/useResizeObserver';
import { getPageNavigationVariables, parseVariablesFromPath } from '../../../store/PageStore/variableUtils';
import { StoreContext } from '../../../store/StoreProvider';
import type { PreviewLayoutCombinedProps } from './types';

const usePresenter = (props: PreviewLayoutCombinedProps): PreviewLayoutCombinedProps => {
  const { previewStore, pageStore } = useContext(StoreContext);
  const { pathname, search } = useLocation();

  const previewRootRef = useRef<HTMLDivElement>(null);

  const preivewSizeChange = useCallback(() => {
    if (previewRootRef.current) {
      const { width } = previewRootRef.current.getBoundingClientRect();
      previewStore.updatePreviewBreakpoint(width);
    }
  }, [previewStore]);

  useResizeObserver(previewRootRef, preivewSizeChange);

  useEffect(() => {
    preivewSizeChange();
  }, [preivewSizeChange]);

  useEffect(() => {
    const [ignored, pagePath] = pathname.split('/preview/') || [];
    if (pagePath) {
      const [pageSlug] = pagePath.split('/');
      if (pageSlug) {
        const queryParameters = new URLSearchParams(search);
        let navigationVariables = Object.fromEntries(queryParameters.entries());

        const page = pageStore.getPageBySlug(pageSlug);
        const pageVariables = page ? getPageNavigationVariables(pageStore.variables, page.uuid) : [];
        navigationVariables = {
          ...navigationVariables,
          ...parseVariablesFromPath(pagePath, pageVariables),
        };
        void previewStore.setAndLoadCurrentPreviewPage(pageSlug, navigationVariables);
      }
    }
  }, [pathname, search, pageStore, previewStore]);

  return {
    ...props,
    previewRootRef,
  };
};

export default usePresenter;
