import cx from 'classnames';
import React from 'react';
import { Constants } from '../../../../constants';
import styles from './ElementRenderer.module.scss';
import usePresenter from './ElementRenderer.presenter';
import type { ElementRendererProps } from './types';

const ElementRenderer: React.FC<ElementRendererProps> = (props) => {
  const {
    element,
    parent,
    ElementView,
    className,
    editing,
    elementSelectionState,
    onClick,
    onMouseOver,
    onFocus,
    onMouseOut,
    onBlur,
  } = usePresenter(props);

  return element && ElementView
    ? (
      <ElementView
        element={element}
        parent={parent}
        isElementEdited={editing || false}
        isElementSelected={elementSelectionState?.isElementSelected || false}
        className={cx(styles.elementRenderer, className)}
        onClick={onClick}
        onMouseOver={onMouseOver}
        onFocus={onFocus}
        onMouseOut={onMouseOut}
        onBlur={onBlur}
        {...{ [Constants.ATTRIBUTES.ELEMENT_ID]: element.id }}
        {...{ [Constants.ATTRIBUTES.ELEMENT_TYPE]: element.type }}
      />
    )
    : null;
};

export default ElementRenderer;
