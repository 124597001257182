import React from 'react';
import cx from 'classnames';

import Icon from '../../atoms/Icon';
import Text from '../../atoms/Text';

import { Constants } from '../../../constants';
import styles from './ElementItem.module.scss';
import usePresenter from './ElementItem.presenter';
import getProps from './ElementItem.props';
import type { ElementItemCombinedProps } from './types';

const ElementItem: React.FC<ElementItemCombinedProps> = (props) => {
  const {
    className,
    classes,
    icon,
    text,
    // MANUAL OVERRIDE STARTS
    type,
    onMouseOver,
    onFocus,
    onMouseOut,
    onBlur,
    // MANUAL OVERRIDE ENDS
  } = usePresenter(props);

  const variantName = '';
  const internalProps = getProps(variantName);
  const currentStyle = styles[`elementItem${variantName}`];

  let componentView;

  switch (variantName) {
    case '': {
      componentView = (
        // MANUAL OVERRIDE STARTS
        <div
          className={cx(currentStyle, className)}
          {...{ [Constants.ATTRIBUTES.ELEMENT_TYPE]: type }}
          onMouseOver={onMouseOver}
          onFocus={onFocus}
          onMouseOut={onMouseOut}
          onBlur={onBlur}
        >
          {/* // MANUAL OVERRIDE ENDS */}
          <div className={cx(styles.elementLogoContainer, classes?.elementLogoContainer)}>
            <Icon
              className={cx(styles.icon, classes?.icon)}
              {...internalProps.icon}
              {...icon}
            />
          </div>
          <Text
            className={cx(styles.text, classes?.text)}
            {...internalProps.text}
            {...text}
          />
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default ElementItem;
