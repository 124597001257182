import { ResponseItemProps } from '../ResponseItem';

type ResponseItemListInternalProps = {
  responseItem?: ResponseItemProps;
};

type ResponseItemListPropsValues = {
  '': ResponseItemListInternalProps;
};

const propValues: ResponseItemListPropsValues = {
  '': {
    responseItem: {
      state: 'Default',
      type: 'Panel',
      style: 'Default',
    },
  },
};

const getProps = (type: string): ResponseItemListInternalProps => {
  const values: ResponseItemListInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
