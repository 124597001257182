import { TextProps } from '../../atoms/Text';
import { PanelHeaderProps } from '../../molecules/PanelHeader';
import { ResponseItemListProps } from '../../molecules/ResponseItemList';
import { VariablesContentProps } from '../VariablesContent';

type VariableResponsePanelInternalProps = {
  panelHeader?: PanelHeaderProps;
  variablesContent?: VariablesContentProps;
  heading?: TextProps;
  instructions?: TextProps;
  responseItemList?: ResponseItemListProps;
};

type VariableResponsePanelPropsValues = {
  'Collapsed': VariableResponsePanelInternalProps;
  'Expanded': VariableResponsePanelInternalProps;
};

const propValues: VariableResponsePanelPropsValues = {
  'Collapsed': {
    panelHeader: {
      state: 'Collapsed',
      type: 'Expandable',
    },
  },
  'Expanded': {
    panelHeader: {
      state: 'Expanded',
      type: 'Expandable',
    },
    variablesContent: {
      state: 'Filled',
    },
    heading: {
      type: 'LabelMedium',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralHoverSelected',
    },
    instructions: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Italic500',
      colour: 'NeutralSubdued',
    },
    responseItemList: {
    },
  },
};

const getProps = (type: string): VariableResponsePanelInternalProps => {
  const values: VariableResponsePanelInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
