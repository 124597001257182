import { TopLeft } from './TopLeft';
import { TopCenter } from './TopCenter';
import { TopRight } from './TopRight';
import { CenterLeft } from './CenterLeft';
import { CenterCenter } from './CenterCenter';
import { CenterRight } from './CenterRight';
import { BottomLeft } from './BottomLeft';
import { BottomCenter } from './BottomCenter';
import { BottomRight } from './BottomRight';

export const AlignmentGrid = {
  TopLeft,
  TopCenter,
  TopRight,
  CenterLeft,
  CenterCenter,
  CenterRight,
  BottomLeft,
  BottomCenter,
  BottomRight,
};
