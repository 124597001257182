import React from 'react';
import cx from 'classnames';

import ElementItem from '../ElementItem';

import styles from './ElementItemList.module.scss';
import usePresenter from './ElementItemList.presenter';
import getProps from './ElementItemList.props';
import type { ElementItemListCombinedProps } from './types';

const ElementItemList: React.FC<ElementItemListCombinedProps> = (props) => {
  const {
    className,
    classes,
    elementItems,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.elementItemList;

  const elementItemViews = elementItems?.map((elementItem) => (
    <ElementItem
      key={elementItem.type} // MANUAL OVERRIDE
      className={cx(styles.elementItem, classes?.elementItem)}
      {...internalProps.elementItem}
      {...elementItem}
    />
  )) || [];

  return (
    <div className={cx(currentStyle, className)}>
      {elementItemViews}
    </div>
  );
};

export default ElementItemList;
