import { computed } from 'mobx';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { StoreContext } from '../../../store/StoreProvider';
import { LeftPanelType } from '../../../store/types';
import { ToggleItemListValueProps } from '../../molecules/ToggleItemList/types';
import { LeftPanelCombinedProps } from './types';
import styles from './LeftPanel.module.scss';

const usePresenter = (props: LeftPanelCombinedProps): LeftPanelCombinedProps => {
  const { appStore } = useContext(StoreContext);
  const { t } = useTranslation();

  const handleUpdateLeftPanel = (option: string) => {
    appStore.setLeftPanelType(option as LeftPanelType);
  };

  const showLeftPanel = !appStore.isLeftPanelType('PageSettings');

  const toggleItemList = computed((): ToggleItemListValueProps<LeftPanelType> => (
    {
      currentValue: appStore.leftPanelType,
      toggleOptions: [
        {
          value: t('leftPanel.design'),
        },
        {
          value: t('leftPanel.layers'),
        },
        {
          value: t('leftPanel.pages'),
        },
      ],
      onOptionSelected: (option) => {
        handleUpdateLeftPanel(option);
      },
    }
  )).get();

  return {
    ...props,
    className: !showLeftPanel ? styles.panelHidden : undefined,
    styleSettingsToggle: {
      toggleItemList,
    },
  };
};

export default usePresenter;
