import { mapStylesToPreviewCssProps } from '../../../../utils/util';
import type { PreviewContainerElementProps } from './types';

const usePresenter = ({ element, style, ...otherProps }: PreviewContainerElementProps): PreviewContainerElementProps => {
  return {
    element,
    style: { ...mapStylesToPreviewCssProps(element.styles), ...style },
    ...otherProps,
  };
};

export default usePresenter;
