import React from 'react';
import cx from 'classnames';

import InputField from '../../atoms/InputField';
import Text from '../../atoms/Text';

import styles from './InfoTestField.module.scss';
import usePresenter from './InfoTestField.presenter';
import getProps from './InfoTestField.props';
import { InfoTestFieldCombinedProps } from './types';

const InfoTestField: React.FC<InfoTestFieldCombinedProps> = (props) => {
  const {
    className,
    classes,
    label,
    inputField,
    text,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.infoTestField;

  return (
    <div className={cx(currentStyle, className)}>
      <div className={cx(styles.content, classes?.content)}>
        <div className={cx(styles.labelContent, classes?.labelContent)}>
          <Text
            className={cx(styles.label, classes?.label)}
            {...internalProps.label}
            {...label}
          />
        </div>
        <div className={cx(styles.inputContent, classes?.inputContent)}>
          <InputField
            className={cx(styles.inputField, classes?.inputField)}
            {...internalProps.inputField}
            {...inputField}
          />
          <Text
            className={cx(styles.text, classes?.text)}
            {...internalProps.text}
            {...text}
          />
        </div>
      </div>
    </div>
  );
};

export default InfoTestField;
