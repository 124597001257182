import React from 'react';
import cx from 'classnames';

import Text from '../../atoms/Text';
import ExpandedSectionHeader from '../../molecules/ExpandedSectionHeader';
import ResponseItemList from '../../molecules/ResponseItemList';

import styles from './ResponseSection.module.scss';
import usePresenter from './ResponseSection.presenter';
import getProps from './ResponseSection.props';
import { ResponseSectionCombinedProps } from './types';

const ResponseSection: React.FC<ResponseSectionCombinedProps> = (props) => {
  const {
    state,
    className,
    classes,
    expandedSectionHeader,
    text,
    responseItemList,
  } = usePresenter(props);

  const variantName = `${state}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`responseSection${variantName}`];

  let componentView;

  switch (variantName) {
    case 'Collapsed': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <ExpandedSectionHeader
            className={cx(styles.expandedSectionHeader, classes?.expandedSectionHeader)}
            {...internalProps.expandedSectionHeader}
            {...expandedSectionHeader}/>
        </div>
      );
      break;
    }
    case 'Expanded': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <ExpandedSectionHeader
            className={cx(styles.expandedSectionHeader, classes?.expandedSectionHeader)}
            {...internalProps.expandedSectionHeader}
            {...expandedSectionHeader}/>
          <Text
            className={cx(styles.text, classes?.text)}
            {...internalProps.text}
            {...text}/>
          <div className={cx(styles.responseItemContent, classes?.responseItemContent)}>
            <ResponseItemList
              className={cx(styles.responseItemList, classes?.responseItemList)}
              {...internalProps.responseItemList}
              {...responseItemList}/>
          </div>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default ResponseSection;
