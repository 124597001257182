/* eslint-disable no-param-reassign */
import {
  action,
  computed,
  makeAutoObservable,
  observable,
  runInAction,
} from 'mobx';
import { Constants, DEFAULT_ELEMENTS } from '../../constants';
import { matchesObjectSubset, nameToSlug } from '../../lib/utils';
import i18nInstance from '../../modules/locale/i18n';
import en from '../../modules/locale/translations/en';
import {
  createElementApi,
  createPageApi,
  deleteElementApi,
  deletePageApi,
  getElementsApi,
  getPagesApi,
  updateElementApi,
  updatePageApi,
} from '../../modules/pages/pages.repository';
import { Variable } from '../../modules/variables/types';
import { getVariablesApi } from '../../modules/variables/variables.repository';
import type { BreakpointStore } from '../BreakpointStore/BreakpointStore';
import { IBreakpoint } from '../BreakpointStore/types';
import { LogicBuilderStore } from '../LogicBuilderStore/LogicBuilderStore';
import type {
  Element,
  ElementConfigurations,
  ElementDragState,
  ElementDropState,
  ElementType,
  IBasePage,
  ICreatePagePayload,
  IDeletePagePayload,
  IDraggingElement,
  IDragHoveredElement,
  IElementDragHoveredState,
  IElementSelectionState,
  InputPropsMap,
  IPage,
  ISelectedElement,
  IUpdateElementPayload,
  IUpdatePage,
  IUpdatePagePayload,
  IWithId,
  IWithParentId,
  IWithType,
  PageElementsMap,
  PartialElement,
} from '../types';
import { NewPageUuid } from '../types';
import { ELEMENT_CONFIGURATIONS } from './config';
import { getCombinedElement } from './utils';

export class PageStore {
  public constructor(breakpointStore: BreakpointStore, logicBuilderStore: LogicBuilderStore) {
    this.breakpointStore = breakpointStore;
    this.logicBuilderStore = logicBuilderStore;

    makeAutoObservable(this);
  }

  private readonly breakpointStore: BreakpointStore;

  private readonly logicBuilderStore: LogicBuilderStore;

  @observable
  public isInitialised: boolean | undefined = undefined;

  @action
  public initialise = async (): Promise<void> => {
    // console.log('Logger: Initialising page store');

    // Start initialisation
    this.isInitialised = false;

    // Fetch data from the back end
    const pages: IPage[] = await getPagesApi();
    const variables = await getVariablesApi();

    // Populate state
    runInAction(() => {
      this.pages = pages;
      this.variables = variables;
    });

    const queryParameters = new URLSearchParams(window.location.search);
    const pageUuid = queryParameters.get('pageUuid');
    await this.setAndLoadCurrentPage(pageUuid || this.homePageUuid);

    // Complete initialisation
    runInAction(() => {
      this.isInitialised = true;
    });

    // console.log('Logger: Page store is initialised');
  };

  @observable
  public pages: IPage[] = [];

  @observable
  public variables: Variable[] = [];

  @computed
  private get variableIndexMap(): Record<string, number> {
    const map: Record<string, number> = {};
    this.variables.forEach((item, index) => {
      map[item.uuid] = index;
    });
    return map;
  }

  @action
  public appendVariable = (variable: Variable) => {
    this.variables = [...this.variables, variable];
  };

  @action
  public updateVariable = (variable: Variable) => {
    const updateIndex = this.variableIndexMap[variable.uuid];
    if (updateIndex !== undefined) {
      this.variables = [
        ...this.variables.slice(0, updateIndex),
        variable,
        ...this.variables.slice(updateIndex + 1),
      ];
    }
  };

  @action
  public removeVariable = (variable: Variable) => {
    this.variables = this.variables.filter((item) => item.uuid !== variable.uuid);
  };

  @computed
  public get sortedPages(): IBasePage[] {
    const sortedPages: IBasePage[] = [];
    this.pages.forEach(({ name, uuid }) => {
      const page: IBasePage = { name, uuid };
      if (this.isHomePage(uuid)) {
        sortedPages.unshift(page);
      } else {
        sortedPages.push(page);
      }
    });
    return sortedPages;
  }

  @observable
  public currentPage: IPage | undefined;

  @computed
  public get currentPageVariables(): Variable[] {
    return this.variables.filter(({ scope, pageUuid }) => {
      if (scope === 'Global') {
        return true;
      } else if (scope === 'Page' && this.currentPage?.uuid === pageUuid) {
        return true;
      }
      return false;
    });
  }

  @computed
  public get elements(): PageElementsMap {
    return this.currentPage?.elements ?? DEFAULT_ELEMENTS;
  }

  @computed
  public get breakpointName(): string {
    const breakpoint = this.breakpointStore.currentBreakpoint;
    return breakpoint.type === 'custom' ? `custom-${breakpoint.width}` : breakpoint.type;
  }

  @computed
  public get breakpointElements(): PageElementsMap<PartialElement> {
    const breakpointElements = this.currentPage?.breakpointElements;
    if (breakpointElements && !breakpointElements[this.breakpointName]) {
      breakpointElements[this.breakpointName] = {};
    }
    return breakpointElements ? breakpointElements[this.breakpointName] : {};
  }

  public isCurrentPage = (pageUuid: string | typeof NewPageUuid | undefined): boolean => {
    return this.isPageCreationMode ? pageUuid === NewPageUuid : this.currentPage?.uuid === pageUuid;
  };

  @observable
  public isCurrentPageLoaded = false;

  @action
  public setAndLoadCurrentPage = async (pageUuid: string): Promise<void> => {
    if (this.currentPage?.uuid === pageUuid) {
      return;
    }

    // console.log(`Logger: Loading current page ${pageUuid}`);

    this.isCurrentPageLoaded = false;

    this.setSelectedElement(undefined);
    this.setHoveredElement(undefined);
    this.setDraggingElement(undefined);
    this.setDragHoveredElement(undefined);
    this.setActiveDropZoneIndex(undefined);

    const currentPage: IPage | undefined = this.getPageByUuid(pageUuid);
    if (currentPage) {
      // Exit edit mode for all pages and elements
      await this.exitPageOrElementEditMode();

      runInAction(() => {
        this.currentPage = currentPage;
      });

      await this.loadCurrentPageElements();
      await this.logicBuilderStore.updateCurrentPage(pageUuid);
      // TODO: Load page variables
      // TODO: Load page logic (flows, actions, etc.)

      // console.log(`Logger: Current page ${pageUuid} is loaded`);
    }

    // Pre-select the first element
    const firstElementId: string | undefined = this.elements[Constants.ID.BODY]?.childIds?.[0];
    if (firstElementId) {
      this.setSelectedElement({ id: firstElementId, shouldAutoExpandAndScrollIntoView: false });
    }

    // Complete initialisation
    runInAction(() => {
      this.isCurrentPageLoaded = true;
    });
  };

  @action
  public loadCurrentPageElements = async (): Promise<void> => {
    if (this.currentPage && !this.currentPage.elements) {
      // console.log(`Logger: Loading elements for current page ${this.currentPage.name}`);

      // Fetch data from the back end
      const { elements, breakpointElements } = await getElementsApi({ pageUuid: this.currentPage.uuid });

      runInAction(() => {
        if (this.currentPage) {
          this.currentPage.elements = elements;
          this.currentPage.breakpointElements = breakpointElements;
          // We pushed some corrupt data into DEV database.
          // Body container doesn't have a parent-child relationship with Main container which it should.
          // This is a temporary fix until data in DEV is fixed.
          // TODO: Remove the following temporary fix.
          if (this.currentPage.elements &&
            this.currentPage.elements[Constants.ID.BODY] &&
            !this.currentPage.elements[Constants.ID.BODY].childIds.length &&
            this.currentPage.elements[Constants.ID.MAIN]) {
            this.currentPage.elements[Constants.ID.BODY].childIds = [Constants.ID.MAIN];
          }
          // if (this.currentPage.elements &&
          //   this.currentPage.elements[Constants.ID.MAIN]?.cssProps.justifyContent) {
          //   this.currentPage.elements[Constants.ID.MAIN].cssProps.justifyContent = undefined;
          // }
          // if (this.currentPage.elements &&
          //   this.currentPage.elements[Constants.ID.MAIN]?.cssProps.alignItems) {
          //   this.currentPage.elements[Constants.ID.MAIN].cssProps.alignItems = undefined;
          // }
          // TODO: Remove the above temporary fix.
        }
      });

      // console.log(`Logger: Elements for current page ${this.currentPage.name} are loaded`);
    }
  };

  @action
  public getPageByUuid = (pageUuid: string): IPage | undefined => {
    return this.pages.find((page) => page.uuid === pageUuid);
  };

  @action
  public getPageBySlug = (pageSlug: string): IPage | undefined => {
    return this.pages.find((page) => nameToSlug(page.slug || page.name || '') === pageSlug);
  };

  @computed
  public get isPageOrElementEditMode(): boolean {
    // Important: All of these properties must be reset in 'this.exitPageOrElementEditMode' method
    return !!this.pageUuidWithEditedName ||
      !!this.pageUuidWithEditedSettings ||
      !!this.elementIdWithEditedName ||
      !!this.editingElement;
  }

  @action
  public exitPageOrElementEditMode = async (options?: { selectHomePageIfNeeded: boolean }) => {
    // Important: All of these properties must be checked in 'this.isPageOrElementEditMode' getter
    this.setPageUuidWithEditedName(undefined);
    this.setPageUuidWithEditedSettings(undefined);
    this.setElementIdWithEditedName(undefined);
    this.setEditingElement(undefined);

    // If there is no current page then select home page
    // This may happen for example if user cancels the creation of a new page
    if (options?.selectHomePageIfNeeded && !this.currentPage && this.homePageUuid !== '') {
      await this.setAndLoadCurrentPage(this.homePageUuid);
    }
  };

  @computed
  public get homePageUuid(): string {
    const homePage: IPage | undefined = this.pages.find((page) => page.isHomePage);
    return homePage?.uuid ?? '';
  }

  public isHomePage = (pageUuid: string): boolean => {
    return this.homePageUuid === pageUuid;
  };

  // NewPageUuid means a new page is being created
  @observable
  private pageUuidWithEditedName: string | typeof NewPageUuid | undefined;

  @action
  public setPageUuidWithEditedName = (pageUuid: string | typeof NewPageUuid | undefined) => {
    if (this.pageUuidWithEditedName !== pageUuid) {
      this.pageUuidWithEditedName = pageUuid;

      // Unselect the current page when a new page is created
      if (pageUuid === NewPageUuid) {
        this.setSelectedElement({ id: Constants.ID.MAIN, shouldAutoExpandAndScrollIntoView: false });
        this.currentPage = undefined;
      }

      // Exit edit mode of page settings for all pages
      this.setPageUuidWithEditedSettings(undefined);
    }
  };

  @action
  public isPageNameEdited = (pageUuid: string | typeof NewPageUuid | undefined): boolean => {
    return this.pageUuidWithEditedName === pageUuid;
  };

  @computed
  public get isPageCreationMode() {
    return this.isPageNameEdited(NewPageUuid);
  }

  @observable
  private pageUuidWithEditedSettings: string | undefined;

  @action
  public setPageUuidWithEditedSettings = (pageUuid: string | undefined) => {
    if (this.pageUuidWithEditedSettings !== pageUuid) {
      this.pageUuidWithEditedSettings = pageUuid;

      // Exit edit mode of page name for all pages
      this.setPageUuidWithEditedName(undefined);
    }
  };

  @action
  public arePageSettingsEdited = (pageUuid: string | undefined): boolean => {
    return this.pageUuidWithEditedSettings === pageUuid;
  };

  @action
  public generatePageNameWithIndex = (): string => {
    let maxPageIndex = 0;

    const pageNamePrefix = i18nInstance.t('pages.pageNamePrefix') ?? en.pages.pageNamePrefix;

    // E.g. Page1, Page 1, Page_1, Page-1
    const regExp = new RegExp(`^${pageNamePrefix}[ _-]*(\\d+)$`, 'i');

    // Find the maximum index for the page name
    this.pages.forEach((page) => {
      const matches = page.name.match(regExp);
      if (matches) {
        // Index 0 is the entire text
        // Index 1 is the actual index in the page name
        const pageIndex = Number(matches[1]);
        if (maxPageIndex < pageIndex) {
          maxPageIndex = pageIndex;
        }
      }
    });

    return `${pageNamePrefix} ${maxPageIndex + 1}`;
  };

  @action
  public createPage = async (createPagePayload: ICreatePagePayload): Promise<void> => {
    // Check if another page with this name already exists
    const { name } = createPagePayload;
    if (this.pages.some((anotherPage) => anotherPage.name === name)) {
      // TODO: Replace alert with a better notification
      alert(`Page '${name}' already exists`);
      return;
    }

    let createdPage: IPage;
    try {
      // Update the back end
      createdPage = await createPageApi(createPagePayload);
    } catch {
      // TODO: Handle exception for createPage
      // TODO: Show error message
      return;
    }

    // Sync the front end with the back end
    await runInAction(async () => {
      this.pages.push(createdPage);

      if (!this.currentPage) {
        await this.setAndLoadCurrentPage(createdPage.uuid);
      }
    });
  };

  @action
  public updatePage = async (page: IPage, options: IUpdatePage): Promise<void> => {
    const { name: prevName, slug: prevSlug, isHomePage: prevIsHomePage, isDraft: prevIsDraft, lockedBy: prevLockedBy } = page;
    const { name, slug, isHomePage, isDraft, lockedBy } = options;

    // Check if there is any change
    if ((!name || prevName === name) &&
      (!slug || slug === prevSlug) &&
      (isHomePage === undefined || prevIsHomePage === isHomePage) &&
      (isDraft === undefined || prevIsDraft === isDraft) &&
      (lockedBy === undefined || prevLockedBy === lockedBy)) {
      return;
    }

    // Check if another page with this name already exists
    if (name !== undefined && this.pages.some((anotherPage) => anotherPage.uuid !== page.uuid && anotherPage.name === name)) {
      // TODO: Replace alert with a better notification
      alert(`Page '${name}' already exists`);
      return;
    }

    // Check if another page with this name already exists
    if (slug !== undefined && this.pages.some((anotherPage) => anotherPage.uuid !== page.uuid && anotherPage.slug === slug)) {
      // TODO: Replace alert with a better notification
      alert(`Page slug '${slug}' already exists`);
      return;
    }

    // Check that we don't make a home page a non-home page
    // There must always be a home page for applicationId
    if (prevIsHomePage && isHomePage === false) {
      // TODO: Replace alert with a better notification
      alert('Home page cannot be made a non-home page');
      return;
    }

    const updatePagePayload: IUpdatePagePayload = { page };

    // Optimistically update the front end, i.e. even before the data is persisted to the back end
    // Update name
    if (name && prevName !== name) {
      page.name = name;
      updatePagePayload.name = name;
    }

    // Update slug
    if (slug && prevSlug !== slug) {
      page.slug = slug;
      updatePagePayload.slug = slug;
    }

    // Update isHomePage
    if (isHomePage !== undefined && prevIsHomePage !== isHomePage) {
      // reset local pages isHomePage
      this.pages.forEach((pageItem) => { pageItem.isHomePage = false; });
      page.isHomePage = isHomePage;
      updatePagePayload.isHomePage = isHomePage;
    }

    // Update isDraft
    if (isDraft !== undefined && prevIsDraft !== isDraft) {
      page.isDraft = isDraft;
      updatePagePayload.isDraft = isDraft;
    }

    // Update lockedBy
    if (lockedBy !== undefined && prevLockedBy !== lockedBy) {
      page.lockedBy = lockedBy;
      updatePagePayload.lockedBy = lockedBy;
    }

    try {
      // Update the back end
      const updatedPage = await updatePageApi(updatePagePayload);

      // Sync the front end with the back end
      runInAction(() => {
        page.name = updatedPage.name;
        page.isHomePage = updatedPage.isHomePage;
        page.isDraft = updatedPage.isDraft;
        page.lockedBy = updatedPage.lockedBy;
      });
    } catch {
      // TODO: Handle exception for updatePage
      // TODO: Show error message

      runInAction(() => {
        // Rollback the front end
        page.name = prevName;
        page.slug = prevSlug;
        page.isHomePage = prevIsHomePage;
        page.isDraft = prevIsDraft;
        page.lockedBy = prevLockedBy;
      });
    }
  };

  @action
  public deletePage = async (deletePagePayload: IDeletePagePayload): Promise<void> => {
    const { page } = deletePagePayload;

    // Reject if the page is a home page
    // There must always be a home page for applicationId
    if (page.isHomePage) {
      // TODO: Replace alert with a better notification
      alert('Home page cannot be deleted');
      return;
    }

    try {
      // Update the back end
      await deletePageApi(deletePagePayload);
    } catch {
      // TODO: Handle exception for deletePage
      // TODO: Show error message
      return;
    }

    // Update the front end
    runInAction(() => {
      this.pages = this.pages.filter(({ uuid }) => uuid !== page.uuid);
    });

    // If current page is deleted then select home page
    if (page.uuid === this.currentPage?.uuid && this.homePageUuid !== '') {
      await this.setAndLoadCurrentPage(this.homePageUuid);
    }
  };

  @action
  public generateElementNameWithIndex = (elementName: string): string => {
    let maxElementIndex = 0;
    const regExp = new RegExp(`^${elementName}[ _-]*(\\d+)$`, 'i');

    // Find the maximum index for the element name
    Object.values(this.elements).forEach((element) => {
      const matches = element.name.match(regExp);
      if (matches) {
        // Index 0 is the entire text
        // Index 1 is the actual index in the element name
        const elementIndex = Number(matches[1]);
        if (maxElementIndex < elementIndex) {
          maxElementIndex = elementIndex;
        }
      }
    });

    return `${elementName} ${maxElementIndex + 1}`;
  };

  @action
  public createElement = async (element: Element, beforeChildIndex: number): Promise<void> => {
    if (this.currentPage) {
      const { id: elementId, type, name, parentId, settings, styles } = element;

      // Optimistically update the front end, i.e. even before the data is persisted to the back end
      this.elements[elementId] = element;

      // Add element reference to the parent element
      const parentElement: Element | undefined = this.elements[parentId];
      if (parentElement) {
        parentElement.childIds.splice(beforeChildIndex, 0, elementId);
      }

      try {
        // Update the back end
        await createElementApi({
          pageUuid: this.currentPage.uuid,
          elementId,
          type,
          name,
          parent: {
            parentId,
            beforeChildIndex,
          },
          // cssProps,
          styles,
          settings,
        });
      } catch {
        // TODO: Handle exception for createElement
        // TODO: Show error message
        // Rollback the front end
        await this.deleteElement(
          element,
          // Do not persist the deletion to the back end, i.e. perform only the front end rollback
          { shouldPersistToBackEnd: false },
        );
      }
    }
  };

  @action
  public moveElement = async (
    element: Element,
    options: { newParentId: string; beforeChildIndex: number; shouldPersistToBackEnd?: boolean; },
  ): Promise<void> => {
    if (this.currentPage) {
      const { id: elementId, type, parentId: prevParentId } = element;
      const { newParentId, beforeChildIndex, shouldPersistToBackEnd = true } = options;

      if (elementId === newParentId) {
        // Cannot move element into itself
        return;
      }

      // Optimistically update the front end, i.e. even before the data is persisted to the back end
      // Update parent element reference
      this.elements[elementId].parentId = newParentId;

      // Remove element reference from the previous parent element
      const prevParentElement: Element | undefined = this.elements[prevParentId];
      let prevBeforeChildIndex = 0;
      if (prevParentElement) {
        prevBeforeChildIndex = prevParentElement.childIds.findIndex((childId) => childId === elementId);
        prevParentElement.childIds = prevParentElement.childIds.filter((childId) => childId !== elementId);
      }

      // Add element reference to the new parent element
      const newParentElement: Element | undefined = this.elements[newParentId];
      if (newParentElement) {
        newParentElement.childIds.splice(beforeChildIndex, 0, elementId);
      }

      if (shouldPersistToBackEnd && (prevParentId !== newParentId || prevBeforeChildIndex !== beforeChildIndex)) {
        try {
          // Update the back end
          await updateElementApi({
            pageUuid: this.currentPage.uuid,
            breakpoint: this.breakpointName,
            elementId,
            type,
            parent: {
              parentId: newParentId,
              beforeChildIndex,
            },
          });
        } catch {
          // TODO: Handle exception for moveElement
          // TODO: Show error message

          // Rollback the front end
          await this.moveElement(element, {
            newParentId: prevParentId,
            beforeChildIndex: prevBeforeChildIndex,
            // Do not persist the move to the back end, i.e. perform only the front end rollback
            shouldPersistToBackEnd: false,
          });
        }
      }
    }
  };

  @action
  public resizeElement = async (element: IWithId & IWithType<ElementType>, options: {
    initialCssWidth: number;
    newCssWidth: number;
    initialCssHeight: number;
    newCssHeight: number;
    shouldPersistToBackEnd: boolean;
  }): Promise<void> => {
    if (this.currentPage) {
      const { id: elementId, type } = element;
      const { styles } = this.elements[elementId];
      const { initialCssWidth, newCssWidth, initialCssHeight, newCssHeight, shouldPersistToBackEnd } = options;

      // const { currentCssLengthUnit: newWidthUnit, currentCssLengthValue: newWidthLength } = getValueAndUnit(newCssWidth?.toString() || '');
      // const { currentCssLengthUnit: newHeightUnit, currentCssLengthValue: newHeightLength } = getValueAndUnit(newCssHeight?.toString() || '');

      // Optimistically update the front end, i.e. even before the data is persisted to the back end
      if (styles.width && styles.width.value !== newCssWidth) {
        styles.width.value = newCssWidth;
        styles.width.unit = 'px';
      }

      if (styles.height && styles.height.value !== newCssHeight) {
        styles.height.value = newCssHeight;
        styles.height.unit = 'px';
      }

      if (shouldPersistToBackEnd && (initialCssWidth !== newCssWidth || initialCssHeight !== newCssHeight)) {
        try {
          // Update the back end
          await this.updateElement(element, {
            styles: {
              width: {
                value: newCssWidth,
                unit: 'px',
              },
              height: {
                value: newCssHeight,
                unit: 'px',
              },
            },
          });
        } catch {
          // TODO: Handle exception for resizeElement
          // TODO: Show error message

          // Rollback the front end
          await this.resizeElement(element, {
            initialCssWidth,
            newCssWidth: initialCssWidth,
            initialCssHeight,
            newCssHeight: initialCssHeight,
            // Do not persist the resize to the back end, i.e. perform only the front end rollback
            shouldPersistToBackEnd: false,
          });
        }
      }
    }
  };

  @action
  public updateElement = async <TElement extends Element>(element: IWithId & IWithType<TElement['type']>, options: {
    name?: TElement['name'];
    styles?: Partial<TElement['styles']>;
    settings?: Partial<InputPropsMap>;
    shouldMerge?: boolean;
    shouldPersistToBackEnd?: boolean;
  }): Promise<void> => {
    const { isDefaultBreakpoint } = this.breakpointStore.currentBreakpoint;
    if (this.currentPage) {
      const { id: elementId, type } = element;
      const prevElement = this.elements[elementId];
      // if not the default breakpoint initialize & then store the element inside breakpointElements
      if (!isDefaultBreakpoint && !this.breakpointElements[elementId]) {
        this.breakpointElements[elementId] = {
          id: elementId,
          settings: {},
          styles: {},
        };
      }
      const prevBreakpointElement = this.breakpointElements[elementId] || {};

      if (!prevElement) {
        return;
      }

      const prevName = prevElement.name;
      const prevStyles = isDefaultBreakpoint ? prevElement.styles : prevBreakpointElement.styles;
      const prevSettings = isDefaultBreakpoint ? prevElement.settings : prevBreakpointElement.settings;

      const { name, styles, settings, shouldMerge = true, shouldPersistToBackEnd = true } = options;

      const areSettingsChanged: boolean = !!settings && !matchesObjectSubset(prevSettings, settings);
      const areStylesChanged: boolean = !!styles && !matchesObjectSubset(prevStyles as Record<string, unknown>, styles);

      // Check if there is any change
      if ((!name || prevName === name) &&
        isDefaultBreakpoint &&
        (!styles || !areStylesChanged) &&
        (!settings || !areSettingsChanged)) {
        return;
      }

      const updateElementPayload: IUpdateElementPayload<TElement> = {
        breakpoint: this.breakpointName,
        pageUuid: this.currentPage.uuid,
        elementId,
        type,
      };

      // Optimistically update the front end, i.e. even before the data is persisted to the back end
      // Update name
      if (name && prevName !== name) {
        this.elements[elementId].name = name;
        updateElementPayload.name = name;
      }

      // Optimistically Update styles
      if (styles && areStylesChanged) {
        const localStyle = shouldMerge ? { ...prevStyles, ...styles } : styles;
        if (isDefaultBreakpoint) {
          this.elements[elementId].styles = localStyle;
        } else {
          this.breakpointElements[elementId].styles = localStyle;
        }
        updateElementPayload.styles = styles;
      }

      // Optimistically Update settings
      if (settings && areSettingsChanged) {
        const localSettings = shouldMerge ? { ...prevSettings, ...settings } : (settings as TElement['settings']);
        if (isDefaultBreakpoint) {
          this.elements[elementId].settings = localSettings;
        } else {
          this.breakpointElements[elementId].settings = localSettings;
        }
        updateElementPayload.settings = settings;
      }

      if (shouldPersistToBackEnd) {
        try {
          // Update the back end
          await updateElementApi(updateElementPayload);
        } catch {
          // TODO: Handle exception for updateElement
          // TODO: Show error message

          // Rollback the front end
          await this.updateElement(element, {
            name: prevName,
            // cssProps: prevCssProps,
            settings: prevSettings,
            styles: prevStyles,
            // Do not merge, replace all CSS props and settings with previous values in full
            shouldMerge: false,
            // Do not persist the update to the back end, i.e. perform only the front end rollback
            shouldPersistToBackEnd: false,
          });
        }
      }
    }
  };

  @action
  public deleteElement = async (element: IWithId, options?: { shouldPersistToBackEnd: boolean }): Promise<void> => {
    if (this.currentPage) {
      // Do not delete Body container
      if (element.id === Constants.ID.BODY) {
        return;
      }

      const { id: elementId, parentId, childIds } = this.elements[element.id];
      const { shouldPersistToBackEnd = true } = options ?? {};

      // Call API only once for the first element, the back end will cascade delete all child elements internally
      // This is to avoid an API call for each descendent element
      if (shouldPersistToBackEnd) {
        try {
          // Update the back end
          await deleteElementApi({
            pageUuid: this.currentPage.uuid,
            elementId,
          });
        } catch {
          // TODO: Handle exception for deleteElement
          // TODO: Show error message
          return;
        }
      }

      // Update the front end

      // Cascade delete all child elements
      // eslint-disable-next-line no-restricted-syntax
      for (const childId of childIds) {
        await this.deleteElement({ id: childId }, { shouldPersistToBackEnd: false }); // eslint-disable-line no-await-in-loop
      }

      runInAction(() => {
        // Remove element reference from the parent element
        const parentElement: Element | undefined = this.elements[parentId];
        if (parentElement) {
          parentElement.childIds = parentElement.childIds.filter(
            (childId) => childId !== elementId,
          );
        }

        delete this.elements[elementId];
      });

      // Select parent element
      if (parentId && this.selectedElement) {
        const { shouldAutoExpandAndScrollIntoView } = this.selectedElement;
        this.setSelectedElement({ id: parentId, shouldAutoExpandAndScrollIntoView });
      } else {
        this.setSelectedElement(undefined);
      }
    }
  };

  @action
  public toggleElement = (element: IWithId) => {
    this.elements[element.id].isExpanded = !this.elements[element.id].isExpanded;
  };

  @action
  public expandParentElements = (element: IWithId) => {
    const { id } = element;
    let { parentId } = this.elements[id];
    while (parentId) {
      const parentElement = this.elements[parentId];
      parentElement.isExpanded = true;
      parentId = parentElement.parentId;
    }
  };

  @observable
  private elementIdWithEditedName: string | undefined;

  @action
  public setElementIdWithEditedName = (elementIdWithEditedName: string | undefined) => {
    if (this.elementIdWithEditedName !== elementIdWithEditedName) {
      this.elementIdWithEditedName = elementIdWithEditedName;
    }
  };

  @action
  public isElementNameEdited = (elementId: string | undefined): boolean => {
    return this.elementIdWithEditedName === elementId;
  };

  @action
  public hasAncestor = (
    element: IWithId & IWithParentId,
    ancestorId: string,
  ): boolean => {
    let currentElement = element;
    let elementId: string = currentElement.id;

    // Base case
    if (!ancestorId) {
      return false;
    }

    // Recursively check all parent elements
    do {
      currentElement = this.elements[elementId];
      if (currentElement?.id === ancestorId) {
        return true;
      }
      elementId = currentElement.parentId;
    } while (elementId);

    return false;
  };

  /*
   * Checks if element is the main container.
   * Main container must be the only child of the Body container.
   */
  @action
  public isMainContainer = (element: IWithParentId & IWithType<ElementType>): boolean => {
    return element.parentId === Constants.ID.BODY &&
      element.type === Constants.ELEMENT_TYPES.CONTAINER &&
      this.elements[Constants.ID.BODY].childIds.length === 1;
  };

  @action
  public getElementState = (element: IWithId): `Expanded${'' | 'Hidden'}` | `Collapsed${'' | 'Hidden'}` | `Default${'' | 'Hidden'}` => {
    const { childIds, styles: { visible }, isExpanded } = this.elements[element.id];
    if (childIds.length) {
      return `${isExpanded ? 'Expanded' : 'Collapsed'}${!visible ? 'Hidden' : ''}`;
    }
    return `Default${visible === false ? 'Hidden' : ''}`;
  };

  @observable
  public selectedElement: ISelectedElement | undefined;

  @computed
  public get selectedCombineElement(): Element | undefined {
    return getCombinedElement(this.selectedElement, this.elements, this.breakpointElements);
  }

  @observable
  public editingElement: ISelectedElement | undefined;

  @action
  public setSelectedElement = (selectedElement: ISelectedElement | undefined): void => {
    // Do not select Body container
    if (selectedElement?.id === Constants.ID.BODY) {
      return;
    }

    this.logicBuilderStore.updateSelectedElementId(selectedElement?.id);

    if (this.selectedElement?.id !== selectedElement?.id ||
      this.selectedElement?.shouldAutoExpandAndScrollIntoView !== selectedElement?.shouldAutoExpandAndScrollIntoView
    ) {
      this.selectedElement = selectedElement;
    }

    // Auto expand all ancestors of the newly selected element
    if (selectedElement?.shouldAutoExpandAndScrollIntoView) {
      this.expandParentElements(selectedElement);
    }
    this.setEditingElement(undefined);
  };

  @action
  public setEditingElement = (editingElement: ISelectedElement | undefined): void => {
    // Do not select Body container
    if (editingElement?.id === Constants.ID.BODY) {
      return;
    }

    if (editingElement) {
      const editElement = this.elements[editingElement.id];
      const elementConfigurations: ElementConfigurations = ELEMENT_CONFIGURATIONS;
      const { isEditable } = elementConfigurations[editElement.type];

      if (!isEditable) {
        return;
      }
    }

    if (this.editingElement?.id !== editingElement?.id) {
      this.editingElement = editingElement;
    }
  };

  @action
  public getElementSelectionState = (element: IWithId | undefined): IElementSelectionState => {
    if (!element) {
      return { isElementSelected: false };
    }
    return this.selectedElement?.id === element.id
      ? { isElementSelected: true, shouldAutoExpandAndScrollIntoView: this.selectedElement.shouldAutoExpandAndScrollIntoView }
      : { isElementSelected: false };
  };

  @observable
  private hoveredElement: IWithId | undefined;

  @action
  public setHoveredElement = (hoveredElement: IWithId | undefined) => {
    if (this.hoveredElement?.id !== hoveredElement?.id) {
      this.hoveredElement = hoveredElement;
    }
  };

  @action
  public isElementHovered = (element: IWithId | undefined): boolean => {
    return !!element && this.hoveredElement?.id === element.id;
  };

  @observable
  public draggingElement: IDraggingElement | undefined;

  @action
  public setDraggingElement = (draggingElement: IDraggingElement | undefined) => {
    if (
      this.draggingElement?.id !== draggingElement?.id ||
      this.draggingElement?.type !== draggingElement?.type
    ) {
      this.draggingElement = draggingElement;
    }
  };

  @action
  public getElementDragState = (element: IDraggingElement): ElementDragState => {
    if (!this.draggingElement || this.draggingElement.id !== element.id || this.draggingElement.type !== element.type) {
      return 'not-dragging';
    }

    switch (this.getDropState()) {
      case 'not-drag-hovered': return 'dragging-over-no-drop-zone';
      case 'drop-allowed': return 'dragging-over-drop-zone';
      case 'drop-forbidden': return 'dragging-over-forbidden-drop-zone';
      default: return 'not-dragging';
    }
  };

  @action
  public getDragState = (): ElementDragState => {
    if (!this.draggingElement) {
      return 'not-dragging';
    }
    return this.getElementDragState(this.draggingElement);
  };

  @observable
  public dragHoveredElement: IDragHoveredElement | undefined;

  @action
  public setDragHoveredElement = (dragHoveredElement: IDragHoveredElement | undefined) => {
    if (this.dragHoveredElement?.id !== dragHoveredElement?.id ||
      this.dragHoveredElement?.shouldAutoExpandAndScrollIntoView !== dragHoveredElement?.shouldAutoExpandAndScrollIntoView
    ) {
      this.dragHoveredElement = dragHoveredElement;
    }

    // Auto expand the element and all of its ancestors.
    // But auto collapse all of its children.
    // This is needed to display drop zones in the layers tree.
    if (dragHoveredElement?.shouldAutoExpandAndScrollIntoView) {
      this.expandParentElements(dragHoveredElement);
      this.elements[dragHoveredElement.id].isExpanded = true;
      this.elements[dragHoveredElement.id].childIds.forEach((childId) => {
        this.elements[childId].isExpanded = false;
      });
    }
  };

  @action
  public getElementDragHoveredState = (element: IWithId): IElementDragHoveredState => {
    return this.dragHoveredElement?.id === element.id
      ? { isElementDragHovered: true, shouldAutoExpandAndScrollIntoView: this.dragHoveredElement.shouldAutoExpandAndScrollIntoView }
      : { isElementDragHovered: false };
  };

  @observable
  public activeDropZoneIndex: number | undefined;

  @action
  public setActiveDropZoneIndex = (activeDropZoneIndex: number | undefined) => {
    if (this.activeDropZoneIndex !== activeDropZoneIndex) {
      this.activeDropZoneIndex = activeDropZoneIndex;
    }
  };

  @action
  public isActiveDropZoneIndex = (activeDropZoneIndex: number): boolean => {
    return this.activeDropZoneIndex === activeDropZoneIndex;
  };

  @action
  public getElementDropState = (element: IWithId): ElementDropState => {
    if (!this.draggingElement || !this.dragHoveredElement || this.dragHoveredElement.id !== element.id) {
      return 'not-drag-hovered';
    }

    // Check if dragHoveredElement accepts a drop
    const dragHoveredElement = this.elements[this.dragHoveredElement.id];
    const elementConfigurations: ElementConfigurations = ELEMENT_CONFIGURATIONS;
    const { isDropAccepted, allowedDropElementTypes } = elementConfigurations[dragHoveredElement.type];
    if (
      !isDropAccepted ||
      (allowedDropElementTypes && !allowedDropElementTypes.includes(this.draggingElement.type))
    ) {
      return 'drop-forbidden';
    }

    // Check that we do not drop element into itself
    if (
      this.draggingElement.id &&
      this.hasAncestor(dragHoveredElement, this.draggingElement.id)
    ) {
      return 'drop-forbidden';
    }

    return 'drop-allowed';
  };

  @action
  public getDropState = (): ElementDropState => {
    if (!this.dragHoveredElement) {
      return 'not-drag-hovered';
    }
    return this.getElementDropState(this.dragHoveredElement);
  };

  @observable
  public isDragCancelled = false;

  @action
  public setIsDragCancelled = (isDragCancelled: boolean) => {
    if (this.isDragCancelled !== isDragCancelled) {
      this.isDragCancelled = isDragCancelled;
    }
  };
}
