import React from 'react';
import cx from 'classnames';

import Button from '../../atoms/Button';
import PanelHeader from '../../molecules/PanelHeader';
import VariableCardList from '../../molecules/VariableCardList';

import styles from './PageVariablesPanel.module.scss';
import usePresenter from './PageVariablesPanel.presenter';
import getProps from './PageVariablesPanel.props';
import { PageVariablesPanelCombinedProps } from './types';

const PageVariablesPanel: React.FC<PageVariablesPanelCombinedProps> = (props) => {
  const {
    state,
    className,
    classes,
    panelHeader,
    button,
    variableCardList,
    variablesContainerRef, // MANUAL OVERRIDE
  } = usePresenter(props);

  const variantName = `${state}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`pageVariablesPanel${variantName}`];

  let componentView;

  switch (variantName) {
    case 'Collapsed': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <PanelHeader
            className={cx(styles.panelHeader, classes?.panelHeader)}
            {...internalProps.panelHeader}
            {...panelHeader}
          />
        </div>
      );
      break;
    }
    case 'Expanded': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <PanelHeader
            className={cx(styles.panelHeader, classes?.panelHeader)}
            {...internalProps.panelHeader}
            {...panelHeader}
          />
          <div className={cx(styles.panelContent, classes?.panelContent)}>
            {/* MANAUL OVERRIDE added ref */}
            <div ref={variablesContainerRef} className={cx(styles.buttonContent, classes?.buttonContent)}>
              <Button
                className={cx(styles.button, classes?.button)}
                {...internalProps.button}
                {...button}
              />
            </div>
            <VariableCardList
              className={cx(styles.variableCardList, classes?.variableCardList)}
              {...internalProps.variableCardList}
              {...variableCardList}
            />
          </div>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default PageVariablesPanel;
