import axios, { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios';

const instance = axios.create({ withCredentials: true });

const onRequest = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => config;

const onRequestError = (error: AxiosError): Promise<AxiosError> => Promise.reject(error);

const onResponse = (response: AxiosResponse): AxiosResponse => response;

const onResponseError = (error: AxiosError): Promise<AxiosError> => Promise.reject(error);

instance.interceptors.request.use(onRequest, onRequestError);
instance.interceptors.response.use(onResponse, onResponseError);

export default instance;
