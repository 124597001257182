import { getImageUrl, getImageAltText } from '../../../../store/PageStore/settingsUtils';
import type { ImageElementProps, InternalImageElementProps } from './types';

const usePresenter = (props: ImageElementProps): InternalImageElementProps => {
  return {
    ...props,
    imageStyle: {
      borderTopLeftRadius: props.style.borderRadius || props.style.borderTopLeftRadius,
      borderTopRightRadius: props.style.borderRadius || props.style.borderTopRightRadius,
      borderBottomLeftRadius: props.style.borderRadius || props.style.borderBottomLeftRadius,
      borderBottomRightRadius: props.style.borderRadius || props.style.borderBottomRightRadius,
      objectFit: props.style.objectFit,
      objectPosition: props.style.objectPosition,
    },
    imageUrl: getImageUrl(props.element),
    altText: getImageAltText(props.element),
  };
};

export default usePresenter;
