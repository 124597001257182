import React from 'react';
import cx from 'classnames';

import Button from '../../atoms/Button';
import Text from '../../atoms/Text';

import styles from './TextCard.module.scss';
import usePresenter from './TextCard.presenter';
import getProps from './TextCard.props';
import { TextCardCombinedProps } from './types';

const TextCard = React.forwardRef<HTMLDivElement, TextCardCombinedProps>((props, ref) => { // MANUAL OVERRIDE
  const {
    states,
    className,
    classes,
    label,
    button,
    text,
    onClick, // MANUAL OVERRIDE
  } = usePresenter(props);

  const variantName = `${states}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`textCard${variantName}`];

  let componentView;

  switch (variantName) {
    case 'Default':
    case 'Selected': {
      componentView = (
        // MANUAL OVERRIDE and disabled eslint errors of accessibility
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <div
          className={cx(currentStyle, className)}
          onClick={onClick}
          ref={ref} // MANUAL OVERRIDE
        >
          <div className={cx(styles.headerContent, classes?.headerContent)}>
            <Text
              className={cx(styles.label, classes?.label)}
              {...internalProps.label}
              {...label}
            />
            <Button
              className={cx(styles.button, classes?.button)}
              {...internalProps.button}
              {...button}
            />
          </div>
          <Text
            className={cx(styles.text, classes?.text)}
            {...internalProps.text}
            {...text}
          />
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
});

export default TextCard;
