/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import cx from 'classnames';

import InputField from '../InputField';
import Text from '../Text';

import styles from './ReplaceInput.module.scss';
import usePresenter from './ReplaceInput.presenter';
import getProps from './ReplaceInput.props';
import { ReplaceInputCombinedProps } from './types';

const ReplaceInput: React.FC<ReplaceInputCombinedProps> = (props) => {
  const {
    state,
    className,
    classes,
    text,
    inputField,
    onClick, // MANUAL OVERRIDE
  } = usePresenter(props);

  const variantName = `${state}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`replaceInput${variantName}`];

  let componentView;

  switch (variantName) {
    case 'Default': {
      componentView = (
        <div className={cx(currentStyle, className)} onClick={onClick}>
          <Text
            className={cx(styles.text, classes?.text)}
            {...internalProps.text}
            {...text}/>
        </div>
      );
      break;
    }
    case 'Selected': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <InputField
            className={cx(styles.inputField, classes?.inputField)}
            {...internalProps.inputField}
            {...inputField}/>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default ReplaceInput;
