import React from 'react';
import cx from 'classnames';

import Divider from '../../atoms/Divider';
import Icon from '../../atoms/Icon';
import Text from '../../atoms/Text';

import styles from './VariableCard.module.scss';
import usePresenter from './VariableCard.presenter';
import getProps from './VariableCard.props';
import { VariableCardCombinedProps } from './types';
import Button from '../../atoms/Button';

const VariableCard: React.FC<VariableCardCombinedProps> = (props) => {
  const {
    size,
    type,
    className,
    classes,
    variableName,
    onClick,
    text,
    divider,
    scopeIcon,
    editIcon,
  } = usePresenter(props);

  const variantName = `${size}${type}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`variableCard${variantName}`];

  let componentView;

  switch (variantName) {
    case 'LargeDefault': {
      componentView = (
        // MANUAL OVERRIDE START
        <button className={cx(currentStyle, className)} onClick={onClick}>
          <Text
            className={cx(styles.variableName, classes?.variableName)}
            {...internalProps.variableName}
            {...variableName}
          />
          <Text
            className={cx(styles.text, classes?.text)}
            {...internalProps.text}
            {...text}
          />
        </button>
        // MANUAL OVERRIDE END
      );
      break;
    }
    case 'SmallGlobal':
    case 'SmallPage':
    case 'SmallUnselected': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <Divider
            className={cx(styles.divider, classes?.divider)}
            {...internalProps.divider}
            {...divider}
          />
          <div className={cx(styles.cardContent, classes?.cardContent)}>
            <div className={cx(styles.content, classes?.content)}>
              <Icon
                className={cx(styles.scopeIcon, classes?.scopeIcon)}
                {...internalProps.scopeIcon}
                {...scopeIcon}
              />
              <Text
                className={cx(styles.variableName, classes?.variableName)}
                {...internalProps.variableName}
                {...variableName}
              />
              {/* MANAUL OVERRIDE */}
              <Button
                className={cx(styles.editIcon, classes?.editIcon)}
                {...internalProps.editIcon}
                {...editIcon}
              />
              <div className={cx(styles.space, classes?.space)} />
            </div>
          </div>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default VariableCard;
