import type { GrabIconButtonCombinedProps } from '../../atoms/GrabIconButton/types';
import type { TextProps } from '../../atoms/Text';

type LogicBuilderHeaderInternalProps = {
  text?: TextProps;
  grabIconButton?: GrabIconButtonCombinedProps; // MANUAL OVERRIDE
};

type LogicBuilderHeaderPropsValues = {
  '': LogicBuilderHeaderInternalProps;
};

const propValues: LogicBuilderHeaderPropsValues = {
  '': {
    text: {
      type: 'LabelLarge',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralHoverSelected',
    },
    grabIconButton: {
      // MANUAL OVERRIDE STARTS
      button: {
        icon: {
          asset: 'UnfoldMore',
        },
      },
      // MANUAL OVERRIDE ENDS
    },
  },
};

const getProps = (type: string): LogicBuilderHeaderInternalProps => {
  const values: LogicBuilderHeaderInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
