import { ButtonProps } from '../../atoms/Button';
import { TextProps } from '../../atoms/Text';

type TextCardInternalProps = {
  label?: TextProps;
  button?: ButtonProps;
  text?: TextProps;
};

type TextCardPropsValues = {
  'Default': TextCardInternalProps;
  'Selected': TextCardInternalProps;
};

const propValues: TextCardPropsValues = {
  Default: {
    label: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralHoverSelected',
    },
    // MANUAL OVERRIDE - to show button in hover and active state
    button: {
      type: 'IconClear',
      size: 'S',
      state: 'Default',
      style: 'PrimarySubdued',
    },
    text: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Italic400',
      colour: 'NeutralDefault',
    },
  },
  Selected: {
    label: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralHoverSelected',
    },
    button: {
      type: 'IconClear',
      size: 'S',
      state: 'Default',
      style: 'PrimarySubdued',
    },
    text: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Italic400',
      colour: 'NeutralHoverSelected',
    },
  },
};

const getProps = (type: string): TextCardInternalProps => {
  const values: TextCardInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
