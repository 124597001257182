import React from 'react';
import cx from 'classnames';

import Button from '../../atoms/Button';
import ErrorBar from '../../atoms/ErrorBar';
import TextInput from '../../atoms/TextInput';
import FunctionBlock from '../../blocks/FunctionBlock';
import InputBlock from '../../blocks/InputBlock';
import BreadcrumbItemList from '../../molecules/BreadcrumbItemList';

import styles from './EndpointPage.module.scss';
import usePresenter from './EndpointPage.presenter';
import getProps from './EndpointPage.props';
import { EndpointPageCombinedProps } from './types';
import TextModal from '../../organisms/TextModal';

const EndpointPage: React.FC<EndpointPageCombinedProps> = (props) => {
  const {
    state,
    className,
    classes,
    breadcrumbItemList,
    textInput,
    button,
    button1,
    inputBlock,
    functionBlock,
    button2,
    errorBar,
    deleteGroupMenu, // MANUAL OVERRIDE
    unsavedChangesModal, // MANUAL OVERRIDE
  } = usePresenter(props);

  const variantName = `${state}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`endpointPage${variantName}`];

  let componentView;

  switch (variantName) {
    case 'Default': {
      componentView = (
        <div className={cx(styles.endpointPage, classes?.endpointPage)}>
          <div className={cx(styles.pageContent, classes?.pageContent)}>
            <div className={cx(styles.rightContent, classes?.rightContent)}>
              <div className={cx(styles.topContent, classes?.topContent)}>
                <div className={cx(styles.breadcrumbContent, classes?.breadcrumbContent)}>
                  <BreadcrumbItemList
                    className={cx(styles.breadcrumbItemList, classes?.breadcrumbItemList)}
                    {...internalProps.breadcrumbItemList}
                    {...breadcrumbItemList} />
                </div>
              </div>
              <div className={cx(styles.bottomContent, classes?.bottomContent)}>
                <div className={cx(styles.headerContent, classes?.headerContent)}>
                  <div className={cx(styles.content, classes?.content)}>
                    <TextInput
                      className={cx(styles.textInput, classes?.textInput)}
                      {...internalProps.textInput}
                      {...textInput} />
                    <Button
                      className={cx(styles.button, classes?.button)}
                      {...internalProps.button}
                      {...button} />
                    <Button
                      className={cx(styles.button1, classes?.button1)}
                      {...internalProps.button1}
                      {...button1} />
                  </div>
                  <div className={cx(styles.errorContent, classes?.errorContent)} />
                </div>
                <div className={cx(styles.inputFunctionContent, classes?.inputFunctionContent)}>
                  <InputBlock
                    className={cx(styles.inputBlock, classes?.inputBlock)}
                    {...internalProps.inputBlock}
                    {...inputBlock} />
                  <FunctionBlock
                    className={cx(styles.functionBlock, classes?.functionBlock)}
                    {...internalProps.functionBlock}
                    {...functionBlock} />
                </div>
                {/* MANUAL OVERRIDE to hide delete button */}
                {button2 && (
                  <div className={cx(styles.bottomButtonContainer, classes?.bottomButtonContainer)}>
                    <Button
                      className={cx(styles.button2, classes?.button2)}
                      {...internalProps.button2}
                      {...button2} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      );
      break;
    }
    case 'Error': {
      componentView = (
        <div className={cx(styles.endpointPage, classes?.endpointPage)}>
          <div className={cx(styles.pageContent, classes?.pageContent)}>
            <div className={cx(styles.rightContent, classes?.rightContent)}>
              <div className={cx(styles.topContent, classes?.topContent)}>
                <div className={cx(styles.breadcrumbContent, classes?.breadcrumbContent)}>
                  <BreadcrumbItemList
                    className={cx(styles.breadcrumbItemList, classes?.breadcrumbItemList)}
                    {...internalProps.breadcrumbItemList}
                    {...breadcrumbItemList} />
                </div>
              </div>
              <div className={cx(styles.bottomContent, classes?.bottomContent)}>
                <div className={cx(styles.headerContent, classes?.headerContent)}>
                  <div className={cx(styles.content, classes?.content)}>
                    <TextInput
                      className={cx(styles.textInput, classes?.textInput)}
                      {...internalProps.textInput}
                      {...textInput} />
                    <Button
                      className={cx(styles.button, classes?.button)}
                      {...internalProps.button}
                      {...button} />
                    <Button
                      className={cx(styles.button1, classes?.button1)}
                      {...internalProps.button1}
                      {...button1} />
                  </div>
                  <div className={cx(styles.errorContent, classes?.errorContent)}>
                    <ErrorBar
                      className={cx(styles.errorBar, classes?.errorBar)}
                      {...internalProps.errorBar}
                      {...errorBar} />
                  </div>
                </div>
                <div className={cx(styles.inputFunctionContent, classes?.inputFunctionContent)}>
                  <InputBlock
                    className={cx(styles.inputBlock, classes?.inputBlock)}
                    {...internalProps.inputBlock}
                    {...inputBlock} />
                  <FunctionBlock
                    className={cx(styles.functionBlock, classes?.functionBlock)}
                    {...internalProps.functionBlock}
                    {...functionBlock} />
                </div>
                {/* MANUAL OVERRIDE to hide delete button */}
                {button2 && (
                  <div className={cx(styles.bottomButtonContainer, classes?.bottomButtonContainer)}>
                    <Button
                      className={cx(styles.button2, classes?.button2)}
                      {...internalProps.button2}
                      {...button2} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return (
    <>
      <div className={cx(currentStyle, className)}>
        {componentView}
      </div>
      {/* MANUAL OVERRIDE STARTS */}
      <TextModal className={styles.textModal} {...deleteGroupMenu} />
      <TextModal className={styles.textModal} {...unsavedChangesModal} />
      {/* MANUAL OVERRIDE ENDS */}
    </>
  );
};

export default EndpointPage;
