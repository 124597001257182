import { EndpointCardProps } from '../EndpointCard';

type EndpointCardListInternalProps = {
  endpointCard?: EndpointCardProps;
};

type EndpointCardListPropsValues = {
  '': EndpointCardListInternalProps;
};

const propValues: EndpointCardListPropsValues = {
  '': {
    endpointCard: {
    },
  },
};

const getProps = (type: string): EndpointCardListInternalProps => {
  const values: EndpointCardListInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
