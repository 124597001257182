import React from 'react';
import cx from 'classnames';

import InputField from '../../atoms/InputField';
import LineNumberList from '../LineNumberList';

import styles from './CodeSpace.module.scss';
import usePresenter from './CodeSpace.presenter';
import getProps from './CodeSpace.props';
import { CodeSpaceCombinedProps } from './types';

const CodeSpace: React.FC<CodeSpaceCombinedProps> = (props) => {
  const {
    className,
    classes,
    lineNumberList,
    inputField,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.codeSpace;

  return (
    <div className={cx(currentStyle, className)}>
      <LineNumberList
        className={cx(styles.lineNumberList, classes?.lineNumberList)}
        {...internalProps.lineNumberList}
        {...lineNumberList}
      />
      <InputField
        className={cx(styles.inputField, classes?.inputField)}
        {...internalProps.inputField}
        {...inputField}
      />
    </div>
  );
};

export default CodeSpace;
