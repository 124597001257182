import { useRef } from 'react';
import cx from 'classnames';
import { useCssProps } from '../../hooks/useCssProps';
import { Element } from '../../../../store/types';
import type { CanvasElementProps } from './types';
import customStyles from './CanvasElement.module.scss';

const usePresenter = <ElementType extends Element>(
  { element, parent, isElementSelected, mapPristineOverrides, ...props }: CanvasElementProps<ElementType>,
): CanvasElementProps<ElementType> => {
  const styles = mapPristineOverrides ? mapPristineOverrides(element) : element.styles;
  const parentElement: Element = parent;
  const elementRef = useRef<HTMLDivElement>(null);
  const {
    width,
    height,
    maxWidth,
    maxHeight,
    margin,
    flex,
    top,
    left,
    right,
    bottom,
    ...cssProps
  } = useCssProps(styles);

  const adjustWidth = styles.width?.unit === '%' || styles.width?.unit === 'fill';
  const adjustHeight = styles.height?.unit === '%' || styles.height?.unit === 'fill';

  const classNames = [props.className];
  if (styles.width?.unit === 'fill') {
    classNames.push(customStyles.fillWidth);
  }

  if (styles.height?.unit === 'fill') {
    classNames.push(customStyles.fillHeight);
  }

  let newFlex = flex;
  if (styles.width?.unit === 'fill' &&
    parentElement?.styles.direction === 'horizontal') {
    newFlex = '1';
  } else if (styles.height?.unit === 'fill') {
    newFlex = 'unset';
  }

  return {
    ...props,
    className: cx(...classNames),
    elementRef,
    style: {
      display: 'flex',
      width: styles.width?.unit === '%' ? width : undefined,
      height: styles.height?.unit === '%' ? height : undefined,
      maxWidth,
      maxHeight,
      margin,
      flex: newFlex,
      top,
      left,
      right,
      bottom,
    },
    element,
    parent,
    elementStyle: {
      display: 'flex',
      ...cssProps,
      width: adjustWidth ? '100%' : width,
      height: adjustHeight ? '100%' : height,
    },
    isElementSelected,
  };
};
export default usePresenter;
