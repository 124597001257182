import { useSpring, type SpringValue } from '@react-spring/web';
import { useDrag } from '@use-gesture/react';
import type { ReactDOMAttributes } from '@use-gesture/react/dist/declarations/src/types';
import { computed } from 'mobx';
import {
  useCallback, useContext, useRef, useState, type RefObject,
} from 'react';
import { Constants } from '../../../constants';
import { ELEMENT_CONFIGURATIONS, ELEMENT_CREATE_FUNCTIONS } from '../../../store/PageStore/config';
import { getElement } from '../../../store/PageStore/utils';
import { StoreContext } from '../../../store/StoreProvider';
import type { ElementIcon, ElementType, IDraggingElement, ISelectedElement } from '../../../store/types';

export interface IUseDraggable {
  mainBlockRef: RefObject<HTMLDivElement>;
  isDragging: boolean;
  dragHandleX: SpringValue<number>;
  dragHandleY: SpringValue<number>;
  dragHandleProps: () => ReactDOMAttributes;
  dragHandleIconAsset?: ElementIcon;
  cancelDrag: () => void;
}

export const useDraggable = (): IUseDraggable => {
  const { appStore, pageStore } = useContext(StoreContext);

  const mainBlockRef = useRef<HTMLDivElement>(null);

  const [isDragging, setIsDragging] = useState<boolean>(false);

  const [{ dragHandleX, dragHandleY }, dragHandleState] = useSpring(() => ({
    dragHandleX: 0,
    dragHandleY: 0,
  }));

  const dragStateRef = useRef<{
    xOffset?: number;
    yOffset?: number;
    prevSelectedElement?: ISelectedElement;
    internalCancelFunc?:(() => void);
  }>({});

  const finishDrag = useCallback(() => {
    setIsDragging(false);

    pageStore.setDraggingElement(undefined);
    pageStore.setDragHoveredElement(undefined);
    pageStore.setActiveDropZoneIndex(undefined);
    appStore.setIsCanvasHovered(false);

    // During dragging we unselect the currently selected element.
    // But restore it if drag gets cancelled.
    if (!pageStore.selectedElement) {
      pageStore.setSelectedElement(dragStateRef.current.prevSelectedElement);
    }

    dragStateRef.current = {};
  }, [appStore, pageStore]);

  const cancelDrag = useCallback(() => {
    dragStateRef.current.internalCancelFunc?.();
    finishDrag();
  }, [finishDrag]);

  const dragHandleProps = useDrag(
    async (state) => {
      const {
        dragging = false,
        first: isFirstDrag,
        last: isLastDrag,
        xy: [mouseX, mouseY],
        movement: [mx, my],
        initial: [initialX, initialY],
        target,
        cancel,
      } = state;

      if (!mainBlockRef.current) {
        cancel();
        return;
      }

      if (pageStore.isPageOrElementEditMode) {
        cancel();
        return;
      }

      // Resizing is also implemented with @use-gesture/react
      // Cancel if resize handle is being dragged
      if ((target as HTMLElement).hasAttribute(Constants.ATTRIBUTES.RESIZE_HANDLE)) {
        cancel();
        return;
      }

      if (isFirstDrag) {
        // We can only drag elements that have 'data-element-type' attribute
        const draggingElement = document
          .elementsFromPoint(initialX, initialY)
          .find((element) => !element.hasAttribute(Constants.ATTRIBUTES.DRAG_HANDLE) && element.hasAttribute(Constants.ATTRIBUTES.ELEMENT_TYPE));
        if (!draggingElement) {
          cancel();
          return;
        }

        // Type is mandatory, e.g. 'Container' or 'ButtonContainer'.
        // Id is optional: it is undefined for new elements but it is defined for existing elements that are moved within the canvas.
        const element: IDraggingElement = {
          id: draggingElement.getAttribute(Constants.ATTRIBUTES.ELEMENT_ID) ?? undefined,
          type: draggingElement.getAttribute(Constants.ATTRIBUTES.ELEMENT_TYPE) as ElementType,
        };

        setIsDragging(true);
        pageStore.setDraggingElement(element);

        const container: DOMRect = mainBlockRef.current.getBoundingClientRect();

        dragStateRef.current = {
          // Make drag handle follow the mouse cursor
          xOffset: mouseX - container.x,
          yOffset: mouseY - container.y,
          // During dragging we unselect the currently selected element.
          // But save it in order to restore it if drag gets cancelled.
          prevSelectedElement: pageStore.selectedElement,
          internalCancelFunc: cancel,
        };

        pageStore.setSelectedElement(undefined);
      } else {
        const { xOffset, yOffset } = dragStateRef.current;

        if (xOffset !== undefined && yOffset !== undefined) {
          dragHandleState.start({
            dragHandleX: dragging ? mx + xOffset : 0,
            dragHandleY: dragging ? my + yOffset : 0,
            immediate: true,
          });

          // Check if we are dragging over canvas
          const isCanvasHovered = !!document.elementsFromPoint(mouseX, mouseY).some((element) => element.hasAttribute(Constants.ATTRIBUTES.CANVAS));
          appStore.setIsCanvasHovered(isCanvasHovered);

          // Store Id of the element that we are currently dragging over
          const dragHoveredElement = document
            .elementsFromPoint(mouseX, mouseY)
            .find((element) => !element.hasAttribute(Constants.ATTRIBUTES.DRAG_HANDLE) && element.hasAttribute(Constants.ATTRIBUTES.ELEMENT_ID));
          const dragHoveredElementId: string | undefined = dragHoveredElement?.getAttribute(Constants.ATTRIBUTES.ELEMENT_ID) ?? undefined;
          pageStore.setDragHoveredElement(dragHoveredElementId
            ? { id: dragHoveredElementId, shouldAutoExpandAndScrollIntoView: isCanvasHovered }
            : undefined);

          // Get drop zone index
          // This will determine the position among parent's children for element move/insertion
          if (dragHoveredElement && dragHoveredElementId) {
            let closestDropZoneIndex: number | undefined;

            // User may be dragging over DropZone component
            // In this case we should have a drop zone index as an attribute
            let dropZoneIndexAttr = dragHoveredElement.getAttribute(Constants.ATTRIBUTES.DROP_ZONE_INDEX);
            let dropZoneIndex = dropZoneIndexAttr ? Number(dropZoneIndexAttr) : undefined;

            if (Number.isFinite(dropZoneIndex)) {
              closestDropZoneIndex = dropZoneIndex;
            } else {
              // Find the closest drop zone
              let shortestDistance = Number.MAX_SAFE_INTEGER;
              let getDropZonePos = (dropZoneRect: DOMRect) => dropZoneRect.x;
              let mousePos = mouseX;
              let areParamsSet = false;

              // Find all drop zones for the drag-hovered element
              const dropZones = dragHoveredElement.querySelectorAll(`[${Constants.ATTRIBUTES.DROP_ZONE_ELEMENT_ID}="${dragHoveredElementId}"][${Constants.ATTRIBUTES.DROP_ZONE_INDEX}]`);
              for (let i = 0; i < dropZones?.length ?? 0; i++) {
                const child = dropZones[i];
                const dropZoneElementId = child.getAttribute(Constants.ATTRIBUTES.DROP_ZONE_ELEMENT_ID);
                dropZoneIndexAttr = child.getAttribute(Constants.ATTRIBUTES.DROP_ZONE_INDEX);
                dropZoneIndex = dropZoneIndexAttr ? Number(dropZoneIndexAttr) : undefined;

                if (dropZoneElementId === dragHoveredElementId && Number.isFinite(dropZoneIndex)) {
                  // Since all drop zones are either all vertical or all horizontal
                  // We can simplify the search by checking for one dimension only, i.e. X for vertical and Y for horizontal drop zones.
                  if (!areParamsSet && child.className.includes('Horizontal')) {
                    getDropZonePos = (dropZoneRect: DOMRect) => dropZoneRect.y;
                    mousePos = mouseY;
                  }
                  areParamsSet = true;

                  const dropZoneRect: DOMRect = child.getBoundingClientRect();
                  const currentDistance: number = Math.abs(getDropZonePos(dropZoneRect) - mousePos);

                  // console.log({
                  //   dragHoveredElementId,
                  //   dropZoneIndex,
                  //   dropZoneRect,
                  //   dropZonePos: getDropZonePos(dropZoneRect),
                  //   mousePos,
                  //   currentDistance,
                  //   shortestDistance,
                  // });

                  if (currentDistance < shortestDistance) {
                    shortestDistance = currentDistance;
                    closestDropZoneIndex = dropZoneIndex;
                  } else {
                    // No need to proceed any further if the distance starts growing
                    break;
                  }
                }
              }
            }
            pageStore.setActiveDropZoneIndex(closestDropZoneIndex);
          } else {
            pageStore.setActiveDropZoneIndex(undefined);
          }

          if (isLastDrag) {
            if (pageStore.draggingElement &&
              pageStore.dragHoveredElement &&
              pageStore.getElementDropState(pageStore.dragHoveredElement) === 'drop-allowed' &&
              pageStore.activeDropZoneIndex !== undefined) {
              const { id, type } = pageStore.draggingElement;
              const { id: parentId } = pageStore.dragHoveredElement;

              if (id) {
                // Move an existing element
                const movingElement = getElement(id, pageStore)!;
                await pageStore.moveElement(movingElement, { newParentId: parentId, beforeChildIndex: pageStore.activeDropZoneIndex });
                pageStore.setSelectedElement({ id, shouldAutoExpandAndScrollIntoView: true });
              } else if (type !== Constants.ELEMENT_TYPES.BODY) {
                // Create a new element
                const createElementFunc = ELEMENT_CREATE_FUNCTIONS[type];
                const newId: string = await createElementFunc(pageStore, parentId, pageStore.activeDropZoneIndex);
                pageStore.setSelectedElement({ id: newId, shouldAutoExpandAndScrollIntoView: true });
              }
            }

            finishDrag();
          }
        }
      }

      // console.log('Drag', {
      //   isDragging: dragging,
      //   isFirstDrag,
      //   isLastDrag,
      //   xy: [mouseX, mouseY],
      //   movement: [mx, my],
      //   initial: [initialX, initialY],
      //   mainBlockRect: mainBlockRef.current.getBoundingClientRect(),
      //   dragStateRef: dragStateRef.current,
      // });
    },
    {
      pointer: {
        keys: false,
      },
      filterTaps: true,
      // Prevent defaults to allow dragging of the images on the canvas.
      // Though in page/element edit mode (e.g. page rename or element rename), need to allow defaults.
      // This is so that the drag handler does not interfere with onClick / onBlur events for text inputs.
      // preventDefault: !pageStore.isPageOrElementEditMode,
    },
  );

  const dragHandleIconAsset: ElementIcon | undefined = computed(() => (pageStore.draggingElement
    ? ELEMENT_CONFIGURATIONS[pageStore.draggingElement.type].icon
    : undefined)).get();

  return {
    mainBlockRef,
    isDragging,
    dragHandleX,
    dragHandleY,
    dragHandleProps,
    dragHandleIconAsset,
    cancelDrag,
  };
};
