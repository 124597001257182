import React from 'react';
import cx from 'classnames';

import OrganismLeftPanel from '../../organisms/LeftPanel'; // MANUAL OVERRIDE
import StyleSettingsToggle from '../../organisms/StyleSettingsToggle';

import styles from './LeftPanel.module.scss';
import usePresenter from './LeftPanel.presenter';
import getProps from './LeftPanel.props';
import { LeftPanelCombinedProps } from './types';

const LeftPanel: React.FC<LeftPanelCombinedProps> = (props) => {
  const {
    className,
    classes,
    styleSettingsToggle,
    leftPanel,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.leftPanel;

  return (
    <div className={cx(currentStyle, className)}>
      <div className={cx(styles.designLayersPagesHeader, classes?.designLayersPagesHeader)}>
        <StyleSettingsToggle
          className={cx(styles.styleSettingsToggle, classes?.styleSettingsToggle)}
          {...internalProps.styleSettingsToggle}
          {...styleSettingsToggle}/>
      </div>
      <OrganismLeftPanel // MANUAL OVERRIDE
        className={cx(styles.leftPanel, classes?.leftPanel)}
        {...internalProps.leftPanel}
        {...leftPanel}/>
    </div>
  );
};

export default LeftPanel;
