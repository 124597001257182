import { IconProps } from '../Icon';
import { TextProps } from '../Text';

type DropdownButtonInternalProps = {
  text?: TextProps;
  icon?: IconProps;
  icon1?: IconProps;
  icon2?: IconProps;
};

type DropdownButtonPropsValues = {
  'DefaultDefaultCollapsedDefault': DropdownButtonInternalProps;
  'DefaultDefaultDisabledDefault': DropdownButtonInternalProps;
  'DefaultDefaultExpandedDefault': DropdownButtonInternalProps;
  'DefaultErrorCollapsedDefault': DropdownButtonInternalProps;
  'DefaultErrorExpandedDefault': DropdownButtonInternalProps;
  'DefaultHighlightedCollapsedDefault': DropdownButtonInternalProps;
  'DefaultHighlightedExpandedDefault': DropdownButtonInternalProps;
  'IconDefaultCollapsedDefault': DropdownButtonInternalProps;
  'IconDefaultExpandedDefault': DropdownButtonInternalProps;
  'IconRowHoverCollapsedDefault': DropdownButtonInternalProps;
  'IconTextDefaultCollapsedDefault': DropdownButtonInternalProps;
  'IconTextDefaultExpandedDefault': DropdownButtonInternalProps;
  'MenuIconDefaultCollapsedDefault': DropdownButtonInternalProps;
  'MenuIconDefaultExpandedDefault': DropdownButtonInternalProps;
  'MethodDefaultCollapsedGreen': DropdownButtonInternalProps;
  'MethodDefaultCollapsedPink': DropdownButtonInternalProps;
  'MethodDefaultCollapsedPurple': DropdownButtonInternalProps;
  'MethodDefaultCollapsedRed': DropdownButtonInternalProps;
  'MethodDefaultCollapsedYellow': DropdownButtonInternalProps;
  'MethodDefaultExpandedGreen': DropdownButtonInternalProps;
  'MethodDefaultExpandedPink': DropdownButtonInternalProps;
  'MethodDefaultExpandedPurple': DropdownButtonInternalProps;
  'MethodDefaultExpandedRed': DropdownButtonInternalProps;
  'MethodDefaultExpandedYellow': DropdownButtonInternalProps;
  'SelectableRowDefaultCollapsedDefault': DropdownButtonInternalProps;
  'SelectableRowDefaultExpandedDefault': DropdownButtonInternalProps;
  'SelectableRowRowHoverCollapsedDefault': DropdownButtonInternalProps;
};

const propValues: DropdownButtonPropsValues = {
  'DefaultDefaultCollapsedDefault': {
    text: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Regular400',
      colour: 'NeutralHoverSelected',
    },
    icon: {
      asset: 'ChevronDown',
      colour: 'NeturalHoverSelected',
    },
  },
  DefaultDefaultDisabledDefault: {
    text: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Regular400',
      colour: 'NeutralDefault',
    },
    icon: {
      asset: 'ChevronDown',
      colour: 'NeutralDefault',
    },
  },
  DefaultDefaultExpandedDefault: {
    text: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Regular400',
      colour: 'NeutralHoverSelected',
    },
    icon: {
      asset: 'ChevronUp',
      colour: 'NeturalHoverSelected',
    },
  },
  'DefaultErrorCollapsedDefault': {
    text: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Regular400',
      colour: 'NeutralHoverSelected',
    },
    icon: {
      asset: 'ChevronDown',
      colour: 'NeturalHoverSelected',
    },
  },
  'DefaultErrorExpandedDefault': {
    text: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Regular400',
      colour: 'NeutralHoverSelected',
    },
    icon: {
      asset: 'ChevronUp',
      colour: 'NeturalHoverSelected',
    },
  },
  'DefaultHighlightedCollapsedDefault': {
    text: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Regular400',
      colour: 'NeutralHoverSelected',
    },
    icon: {
      asset: 'ChevronDown',
      colour: 'NeturalHoverSelected',
    },
  },
  'DefaultHighlightedExpandedDefault': {
    text: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Regular400',
      colour: 'NeutralHoverSelected',
    },
    icon: {
      asset: 'ChevronUp',
      colour: 'NeturalHoverSelected',
    },
  },
  'IconDefaultCollapsedDefault': {
    icon1: {
      asset: 'Undo',
      colour: 'NeutralDefault',
    },
    icon2: {
      asset: 'ChevronDown',
      colour: 'NeutralDefault',
    },
  },
  'IconDefaultExpandedDefault': {
    icon1: {
      asset: 'Undo',
      colour: 'NeutralDefault',
    },
    icon2: {
      asset: 'ChevronUp',
      colour: 'NeturalHoverSelected',
    },
  },
  'IconRowHoverCollapsedDefault': {
    icon1: {
      asset: 'Undo',
      colour: 'NeutralDefault',
    },
    icon2: {
      asset: 'ChevronDown',
      colour: 'NeturalHoverSelected',
    },
  },
  'IconTextDefaultCollapsedDefault': {
    icon: {
      asset: 'Add',
      colour: 'NeturalHoverSelected',
    },
    text: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Regular400',
      colour: 'NeutralHoverSelected',
    },
    icon1: {
      asset: 'ChevronDown',
      colour: 'NeturalHoverSelected',
    },
  },
  'IconTextDefaultExpandedDefault': {
    icon: {
      asset: 'Add',
      colour: 'NeturalHoverSelected',
    },
    text: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Regular400',
      colour: 'NeutralHoverSelected',
    },
    icon1: {
      asset: 'ChevronUp',
      colour: 'NeturalHoverSelected',
    },
  },
  MenuIconDefaultCollapsedDefault: {
    icon: {
      asset: 'MoreVert',
      colour: 'NeutralDefault',
    },
  },
  MenuIconDefaultExpandedDefault: {
    icon: {
      asset: 'MoreVert',
      colour: 'NeturalHoverSelected',
    },
  },
  MethodDefaultCollapsedGreen: {
    text: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralHoverSelected',
    },
    icon: {
      asset: 'ChevronDown',
      colour: 'NeturalHoverSelected',
    },
  },
  'MethodDefaultCollapsedPink': {
    text: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralHoverSelected',
    },
    icon: {
      asset: 'ChevronDown',
      colour: 'NeturalHoverSelected',
    },
  },
  'MethodDefaultCollapsedPurple': {
    text: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralHoverSelected',
    },
    icon: {
      asset: 'ChevronDown',
      colour: 'NeturalHoverSelected',
    },
  },
  'MethodDefaultCollapsedRed': {
    text: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralHoverSelected',
    },
    icon: {
      asset: 'ChevronDown',
      colour: 'NeturalHoverSelected',
    },
  },
  'MethodDefaultCollapsedYellow': {
    text: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralHoverSelected',
    },
    icon: {
      asset: 'ChevronDown',
      colour: 'NeturalHoverSelected',
    },
  },
  'MethodDefaultExpandedGreen': {
    text: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralHoverSelected',
    },
    icon: {
      asset: 'ChevronUp',
      colour: 'NeturalHoverSelected',
    },
  },
  'MethodDefaultExpandedPink': {
    text: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralHoverSelected',
    },
    icon: {
      asset: 'ChevronUp',
      colour: 'NeturalHoverSelected',
    },
  },
  'MethodDefaultExpandedPurple': {
    text: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralHoverSelected',
    },
    icon: {
      asset: 'ChevronUp',
      colour: 'NeturalHoverSelected',
    },
  },
  'MethodDefaultExpandedRed': {
    text: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralHoverSelected',
    },
    icon: {
      asset: 'ChevronUp',
      colour: 'NeturalHoverSelected',
    },
  },
  'MethodDefaultExpandedYellow': {
    text: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralHoverSelected',
    },
    icon: {
      asset: 'ChevronUp',
      colour: 'NeturalHoverSelected',
    },
  },
  'SelectableRowDefaultCollapsedDefault': {
    text: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Regular400',
      colour: 'NeutralDefault',
    },
    icon: {
      asset: 'ChevronDown',
      colour: 'NeutralDefault',
    },
  },
  'SelectableRowDefaultExpandedDefault': {
    text: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Regular400',
      colour: 'NeutralHoverSelected',
    },
    icon: {
      asset: 'ChevronUp',
      colour: 'NeturalHoverSelected',
    },
  },
  'SelectableRowRowHoverCollapsedDefault': {
    text: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Regular400',
      colour: 'NeutralHoverSelected',
    },
    icon: {
      asset: 'ChevronDown',
      colour: 'NeturalHoverSelected',
    },
  },
};

const getProps = (type: string): DropdownButtonInternalProps => {
  const values: DropdownButtonInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
