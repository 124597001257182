import cx from 'classnames';
import React, { type PropsWithChildren } from 'react';
import styles from './ButtonContainerElement.module.scss';
import usePresenter from './ButtonContainerElement.presenter';
import type { ButtonContainerElementProps } from './types';

const ButtonContainerElement: React.FC<PropsWithChildren<ButtonContainerElementProps>> = ({ children, ...props }) => {
  const { elementRef, element, style, className, isElementEdited, ...otherProps } = usePresenter(props);

  return (
    <div
      ref={elementRef}
      className={cx(styles.buttonContainerElement, className)}
      style={style}
      {...otherProps}
    >
      {children}
    </div>
  );
};

export default ButtonContainerElement;
