import type { VariableInput } from '../../components/organisms/InputVariableTable/types';
import { Constants } from '../../constants';
import axiosInstance from '../../lib/api/axios';
import { STUDIO_API_URL } from '../../lib/config';
import type {
  ApiEndpointResponse,
  ApiGroup,
  ApiGroupEndpoint,
  CreateApiGroupEndpointPayload,
  CreateApiGroupPayload,
  GetApiEndpointResponsePayload,
  KeyValuePair,
  UpdateApiGroupEndpointPayload,
  UpdateApiGroupPayload,
} from '../../store/ApiGroupStore/types';

const applicationId = Number(localStorage.getItem(Constants.HEADERS.APPLICATION_ID)) || 1;
const headers = { headers: { [Constants.HEADERS.APPLICATION_ID]: applicationId } };

export const createGroupApi = async (
  createApiGroupPayload: CreateApiGroupPayload,
): Promise<ApiGroup> => {
  const { data: createdApiGroup } = await axiosInstance.post<ApiGroup>(
    `${STUDIO_API_URL}/api-groups`,
    createApiGroupPayload,
    headers,
  );
  return createdApiGroup;
};

export const getApiGroupsApi = async (): Promise<ApiGroup[]> => {
  const { data: apiGroups } = await axiosInstance.get<ApiGroup[]>(`${STUDIO_API_URL}/api-groups`, headers);
  return apiGroups;
};

export const updateApiGroupApi = async (apiGroupUuid: string, payload: UpdateApiGroupPayload): Promise<ApiGroup> => {
  const { data: apiGroup } = await axiosInstance.patch<ApiGroup>(
    `${STUDIO_API_URL}/api-groups/${apiGroupUuid}`,
    payload,
    headers,
  );
  return apiGroup;
};

export const deleteGroupApi = async (groupUuid: string): Promise<void> => {
  await axiosInstance.delete(`${STUDIO_API_URL}/api-groups/${groupUuid}`, headers);
};

export const createApiGroupEndpointApi = async (
  createApiGroupPayload: CreateApiGroupEndpointPayload,
): Promise<ApiGroupEndpoint> => {
  const { data: createdApiGroupEndpoint } = await axiosInstance.post<ApiGroupEndpoint>(
    `${STUDIO_API_URL}/endpoints`,
    createApiGroupPayload,
    headers,
  );
  return createdApiGroupEndpoint;
};

export const deleteEndpointApi = async (endpointUuid: string): Promise<void> => {
  await axiosInstance.delete(`${STUDIO_API_URL}/endpoints/${endpointUuid}`, headers);
};

export const updateEndpointApi = async (endpointUuid: string, payload: UpdateApiGroupEndpointPayload): Promise<ApiGroupEndpoint> => {
  const { data: endpoint } = await axiosInstance.patch<ApiGroupEndpoint>(
    `${STUDIO_API_URL}/endpoints/${endpointUuid}`,
    payload,
    headers,
  );

  return endpoint;
};

export const getValueFromInputVariablesByName = (inputVariables: VariableInput[], name: string): VariableInput['value'] => {
  return inputVariables.find((inputVariable: VariableInput) => inputVariable.name === name)?.value;
};

export const processKeyValuePairs = (keyValuePairs: KeyValuePair[] | undefined, inputVariables: VariableInput[] | undefined): Record<string, VariableInput['value']> => {
  const processedData: Record<string, VariableInput['value']> = {};
  if (keyValuePairs?.length) {
    // eslint-disable-next-line no-restricted-syntax
    for (const keyValuePair of keyValuePairs) {
      const { key, value, inputVariable } = keyValuePair;
      const processedValue: VariableInput['value'] = inputVariables && inputVariable
        ? getValueFromInputVariablesByName(inputVariables, inputVariable.name) ?? value
        : value;
      processedData[key] = processedValue;
    }
  }

  return processedData;
};

export const getApiEndpointResponse = async (
  apiGroup: ApiGroup,
  apiEndpoint: ApiGroupEndpoint,
  inputVariables: VariableInput[] | undefined,
): Promise<ApiEndpointResponse> => {
  const { parameters: groupParameters, headers: groupHeaders } = apiGroup;
  const { httpMethod, path, parameters: endpointParameters, headers: endpointHeaders, body: endpointBody } = apiEndpoint;

  // Process path
  let processedPath: string = path;
  if (inputVariables && processedPath?.includes('{{')) {
    // eslint-disable-next-line no-restricted-syntax
    for (const inputVariable of inputVariables) {
      const { name, value } = inputVariable;
      if (value !== undefined) {
        // URL encode value and replace all occurrences
        processedPath = processedPath.replace(new RegExp(`{{${name}}}`, 'g'), encodeURIComponent(value));
      }
    }
  }

  // Remove trailing slashes from base URL and leading slashes from path
  const url = `${apiGroup.baseUrl.replace(/[/]+$/, '')}/${processedPath.replace(/^[/]+/, '')}`;

  // Process parameters
  const processedParameters: Record<string, VariableInput['value']> = {
    ...processKeyValuePairs(groupParameters, inputVariables),
    ...processKeyValuePairs(endpointParameters, inputVariables),
  };

  // Process headers
  const processedHeaders: Record<string, VariableInput['value']> = {
    ...processKeyValuePairs(groupHeaders, inputVariables),
    ...processKeyValuePairs(endpointHeaders, inputVariables),
  };

  // Process body
  let processedBody: string | undefined = endpointBody;
  if (inputVariables && processedBody?.includes('{{')) {
    // eslint-disable-next-line no-restricted-syntax
    for (const inputVariable of inputVariables) {
      const { name, value } = inputVariable;
      if (value !== undefined) {
        // Escape double quotes inside values to prevent conflicts with double quotes that wrap strings
        // Replace all occurrences
        processedBody = processedBody.replace(new RegExp(`{{${name}}}`, 'g'), value.toString().replace(/"/g, '\\"'));
      }
    }
  }

  // Convert processed body to an object
  let data: Record<string, unknown> | undefined;
  if (processedBody !== undefined) {
    try {
      data = JSON.parse(processedBody) as Record<string, unknown>;
    } catch {
      // Ignore body
    }
  }

  // Construct payload
  const getApiEndpointResponsePayload: GetApiEndpointResponsePayload = {
    url,
    httpMethod,
    parameters: processedParameters,
    headers: processedHeaders,
    body: data,
  };

  // Make API request
  const { data: apiEndpointResponse } = await axiosInstance.post<ApiEndpointResponse>(
    `${STUDIO_API_URL}/endpoints/endpoint-response`,
    getApiEndpointResponsePayload,
  );

  return apiEndpointResponse;
};
