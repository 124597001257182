import { ElementItemProps } from '../ElementItem';

type ElementItemListInternalProps = {
  elementItem?: ElementItemProps;
};

type ElementItemListPropsValues = {
  '': ElementItemListInternalProps;
};

const propValues: ElementItemListPropsValues = {
  '': {
    elementItem: {
    },
  },
};

const getProps = (type: string): ElementItemListInternalProps => {
  const values: ElementItemListInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
