import { useContext, useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useParams } from 'react-router';
import { Constants } from '../../../constants';
import { ROUTES } from '../../../lib/constants';
import { NavigateWrapperContext } from '../../../modules/navigation/context/NavigateWrapperContext';
import { useNavigateWrapper } from '../../../modules/navigation/hooks/useNavigateWrapper';
import type { ApiGroupEndpoint } from '../../../store/ApiGroupStore/types';
import { StoreContext } from '../../../store/StoreProvider';
import { isValidText } from '../../../utils/inputValidations';
import { typedDeepMerge } from '../../../utils/objectUtils';
import styles from './EndpointPage.module.scss';
import type { EndpointPageCombinedProps, EndpointErrorState } from './types';
import { getDefaultEndpoint, getEndpointErrorState } from './utils';

const usePresenter = (props: EndpointPageCombinedProps): EndpointPageCombinedProps => {
  const { t } = useTranslation();
  const { navigate, setBlocker, removeBlocker, proceed } = useNavigateWrapper();
  const { isNavigationBlocked } = useContext(NavigateWrapperContext);
  const { endpointUuid, groupUuid } = useParams();
  const { apiGroupStore } = useContext(StoreContext);
  const { selectedGroup, setSelectedEndpoint, selectedEndpoint, setSelectedApiGroup } = apiGroupStore;
  const apiEndpointCount = useMemo(() => selectedGroup?.endpoints?.length ?? 0, [selectedGroup]);
  const [deleteModalState, setDeleteModalState] = useState(false);
  const defaultEndpointDetails = useMemo(() => getDefaultEndpoint(apiEndpointCount), [apiEndpointCount]);

  useEffect(() => {
    void setSelectedEndpoint(groupUuid, endpointUuid);
    return () => {
      void setSelectedEndpoint();
    };
  }, [setSelectedEndpoint, groupUuid, endpointUuid]);

  const [endpointDetails, setEndpointDetails] = useState<ApiGroupEndpoint>(selectedEndpoint ?? getDefaultEndpoint(apiEndpointCount));
  const isValueUpdated = useMemo(() => JSON.stringify(selectedEndpoint) !== JSON.stringify(endpointDetails), [selectedEndpoint, endpointDetails]);
  useEffect(() => {
    if (groupUuid && selectedGroup?.uuid !== groupUuid) {
      setSelectedApiGroup(groupUuid);
    }
  }, [groupUuid, selectedGroup?.uuid, setSelectedApiGroup]);

  const [unsavedChangesModalState, setUnsavedChangesModalState] = useState<boolean>(false);
  const [isErrorVisible, setIsErrorVisible] = useState<boolean>(false);
  const endpointErrorState: EndpointErrorState = useMemo(
    () => getEndpointErrorState(endpointDetails, isErrorVisible),
    [endpointDetails, isErrorVisible],
  );
  useEffect(() => {
    if (!endpointErrorState.hasError && isErrorVisible) {
      setIsErrorVisible(false);
    }
  }, [isErrorVisible, endpointErrorState]);
  useEffect(() => {
    setEndpointDetails(defaultEndpointDetails);
  }, [defaultEndpointDetails]);

  useEffect(() => {
    if (selectedEndpoint) {
      setEndpointDetails(selectedEndpoint);
    }
  }, [selectedEndpoint]);

  useEffect(() => {
    if (isNavigationBlocked && endpointErrorState.endpointState === 'Error') {
      removeBlocker();
    } else if (isNavigationBlocked) {
      setUnsavedChangesModalState(true);
    }
  }, [isNavigationBlocked, endpointErrorState.endpointState, removeBlocker]);

  useEffect(() => {
    if (isValueUpdated) {
      setBlocker();
    } else {
      removeBlocker();
    }
  }, [isValueUpdated, setBlocker, removeBlocker]);

  const handleSaveEndpoint = async () => {
    setIsErrorVisible(endpointErrorState.hasError);
    if (selectedEndpoint && selectedGroup && !endpointErrorState.hasError) {
      await apiGroupStore.updateEndpoint(groupUuid || '', selectedEndpoint.uuid || '', endpointDetails);
    } else if (selectedGroup && !endpointErrorState.hasError) {
      await apiGroupStore.createApiGroupEndpoint(groupUuid || '', { ...endpointDetails, apiGroupUuid: selectedGroup.uuid });
    }
  };

  const handleDelete = async () => {
    if (selectedGroup) {
      setDeleteModalState(false);
      await apiGroupStore.deleteEndpoint(selectedEndpoint?.uuid || '');
      navigate(generatePath(`${ROUTES.groupDetails}`, {
        groupUuid: selectedGroup?.uuid,
      }));
    }
  };

  const handleSaveEndpointOnNavigateAway = async () => {
    setUnsavedChangesModalState(false);
    if (isNavigationBlocked) {
      setIsErrorVisible(endpointErrorState.hasError);
      if (selectedEndpoint && selectedGroup && !endpointErrorState.hasError) {
        await apiGroupStore.updateEndpoint(groupUuid || '', selectedEndpoint.uuid || '', endpointDetails);
        proceed();
      } else if (selectedGroup && !endpointErrorState.hasError) {
        await apiGroupStore.createApiGroupEndpoint(groupUuid || '', { ...endpointDetails, apiGroupUuid: selectedGroup.uuid });
        proceed();
      }
    }
  };

  return typedDeepMerge(props, {
    state: endpointErrorState.endpointState,
    breadcrumbItemList: {
      breadcrumbItems: [{
        state: 'Default',
        text: {
          value: selectedGroup?.name,
        },
        icon: {
          asset: 'ChevronRight',
        },
      },
      {
        state: 'Selected',
        text: {
          value: endpointDetails.name,
        },
      }],
    },
    textInput: {
      textValue: endpointDetails.name,
      isValidTextInput: (text) => isValidText(text, [Constants.REGEX.NOT_ALPHANUMERIC_DASH_AND_UNDERSCORE_SPACE]),
      onTextChanged: (text) => setEndpointDetails({ ...endpointDetails, name: text }),
    },
    button: {
      disabled: !isValueUpdated,
      text: {
        value: t('endpointPage.save'),
      },
      onClick: handleSaveEndpoint,
    },
    button1: {
      disabled: isValueUpdated,
      text: {
        value: t('endpointPage.testResponse'),
      },
      onClick: () => {
        if (selectedGroup && selectedEndpoint) {
          navigate(generatePath(`${ROUTES.testResponse}`, {
            groupUuid: selectedGroup.uuid,
            endpointUuid: selectedEndpoint.uuid,
          }));
        }
      },
    },
    inputBlock: {
      text: {
        value: t('endpointPage.inputBlock.title'),
      },
      tabItemList: {
        tabItems: [{
          state: 'Selected',
          text: {
            value: t('endpointPage.inputBlock.variable'),
          },
        }],
      },
      inputVariableTable: {
        state: endpointDetails.inputVariables?.length ? 'Filled' : 'Empty',
        name: {
          value: t('endpointPage.inputBlock.name'),
        },
        type: {
          value: t('endpointPage.inputBlock.type'),
        },
        tableRowList: {
          tableRows: [],
        },
        button: {
          icon: {
            asset: 'Add',
          },
          text: {
            value: t('endpointPage.inputBlock.addVariables'),
          },
        },
        inputVariables: endpointDetails.inputVariables,
        onItemsChange: (items) => setEndpointDetails({ ...endpointDetails, inputVariables: items }),
        isErrorVisible: isErrorVisible && endpointErrorState.hasInputError,
      },
    },
    functionBlock: {
      infoTestField: {
        text: {
          value: `${t('functionBlock.baseUrl')} ${apiGroupStore.selectedGroup?.baseUrl}`,
        },
      },
      hasHeadersError: isErrorVisible && endpointErrorState.hasHeadersError,
      hasParametersError: isErrorVisible && endpointErrorState.hasParameterError,
      inputVariables: endpointDetails.inputVariables,
      apiEndpointHeaders: endpointDetails.headers,
      apiEndpointParameters: endpointDetails.parameters,
      apiEndpointPath: endpointDetails.path,
      apiEndpointMethod: endpointDetails.httpMethod,
      apiEndpointBody: endpointDetails.body,
      setApiEndpointHeaders: (value) => { setEndpointDetails({ ...endpointDetails, headers: value }); },
      setApiEndpointParameters: (value) => { setEndpointDetails({ ...endpointDetails, parameters: value }); },
      setApiEndpointPath: (value) => { setEndpointDetails({ ...endpointDetails, path: value }); },
      setApiEndpointMethod: (value) => { setEndpointDetails({ ...endpointDetails, httpMethod: value }); },
      setApiEndpointBody: (value) => { setEndpointDetails({ ...endpointDetails, body: value }); },
    },
    button2: selectedEndpoint && {
      text: {
        value: t('endpointPage.deleteModal.deleteEndpoint'),
      },
      onClick: () => {
        setDeleteModalState(true);
      },
    },
    errorBar: {
      text: {
        value: t('endpointPage.missingFieldsErrorMessage'),
      },
    },
    deleteGroupMenu: {
      show: deleteModalState,
      backdropClassName: styles.backdrop,
      modalProps: {
        state: 'Destructive',
        heading: {
          value: t('endpointPage.deleteModal.header'),
        },
        primaryButton: {
          icon: {
            asset: 'Close',
          },
          onClick: () => {
            setDeleteModalState(false);
          },
        },
        text: {
          value: t('endpointPage.deleteModal.body'),
        },
        button: {
          text: {
            value: t('endpointPage.deleteModal.deleteEndpoint'),
          },
          onClick: handleDelete,
        },
        secondaryButton: {
          text: {
            value: t('endpointPage.deleteModal.cancel'),
          },
          onClick: () => {
            setDeleteModalState(false);
          },
        },
      },
    },
    unsavedChangesModal: {
      show: unsavedChangesModalState,
      backdropClassName: styles.backdrop,
      modalProps: {
        state: 'Default',
        heading: {
          value: t('unsavedChangesModal.heading'),
        },
        primaryButton: {
          icon: {
            asset: 'Close',
          },
          onClick: () => {
            if (isNavigationBlocked) {
              removeBlocker();
            }
            setUnsavedChangesModalState(false);
          },
        },
        text: {
          value: t('unsavedChangesModal.description'),
        },
        primaryButton1: {
          text: {
            value: t('unsavedChangesModal.saveButton'),
          },
          onClick: handleSaveEndpointOnNavigateAway,
        },
        secondaryButton: {
          text: {
            value: t('unsavedChangesModal.exitWithoutSavingButton'),
          },
          onClick: () => {
            setUnsavedChangesModalState(false);
            if (isNavigationBlocked) {
              proceed();
            }
          },
        },
      },
    },
  });
};
export default usePresenter;
