import React from 'react';
import 'reactflow/dist/style.css';
import ReactFlow, {
  Background,
  ProOptions,
} from 'reactflow';
import cx from 'classnames';

import styles from './ActionContainer.module.scss';
import usePresenter from './ActionContainer.presenter';
import getProps from './ActionContainer.props';
import { ActionContainerCombinedProps } from './types';

const ActionContainer: React.FC<ActionContainerCombinedProps> = (props) => {
  const {
    className,
    classes,
    nodeTypes,
    nodes,
    edges,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.actionContainer;
  const proOptions: ProOptions = { hideAttribution: true };

  return (
    <div className={cx(currentStyle, className)}>
      <ReactFlow
        nodeTypes={nodeTypes}
        nodes={nodes}
        edges={edges}
        zoomOnPinch={false}
        zoomOnDoubleClick={false}
        zoomOnScroll={false}
        proOptions={proOptions}
      >
        <Background />
      </ReactFlow>
    </div>
  );
};

export default ActionContainer;
