import React from 'react';
import cx from 'classnames';

import Icon from '../Icon';
import Text from '../Text';

import styles from './ErrorBar.module.scss';
import usePresenter from './ErrorBar.presenter';
import getProps from './ErrorBar.props';
import { ErrorBarCombinedProps } from './types';

const ErrorBar: React.FC<ErrorBarCombinedProps> = (props) => {
  const {
    className,
    classes,
    icon,
    text,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.errorBar;

  return (
    <div className={cx(currentStyle, className)}>
      <Icon
        className={cx(styles.icon, classes?.icon)}
        {...internalProps.icon}
        {...icon}
      />
      <Text
        className={cx(styles.text, classes?.text)}
        {...internalProps.text}
        {...text}
      />
    </div>
  );
};

export default ErrorBar;
