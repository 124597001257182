import React from 'react';
import cx from 'classnames';

import DropdownItem from '../../atoms/DropdownItem';

import styles from './DropdownItemList.module.scss';
import usePresenter from './DropdownItemList.presenter';
import getProps from './DropdownItemList.props';
import { DropdownItemListCombinedProps } from './types';

// MANUAL OVERRIDE - converted to forwardRef Component
const DropdownItemList = React.forwardRef<HTMLDivElement, DropdownItemListCombinedProps>((props, ref) => {
  const {
    style,
    className,
    classes,
    dropdownItems, // MANUAL OVERRIDE
    onOptionSelected, // MANUAL OVERRIDE
    ...otherProps // MANUAL OVERRIDE
  } = usePresenter(props);

  const variantName = `${style}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`dropdownItemList${variantName}`];

  let componentView;

  switch (variantName) {
    case 'Menu':
    case 'Checkmark':
    case 'Default': {
      const dropdownItemViews = dropdownItems?.map((dropdownItem, index) => (
        <DropdownItem
          key={index}
          className={cx(styles.dropdownItem, classes?.dropdownItem)}
          {...internalProps.dropdownItem}
          {...dropdownItem}
          onClick={(event) => {
            if (onOptionSelected) {
              if (dropdownItem?.dropdownItemValue) {
                onOptionSelected(dropdownItem.dropdownItemValue);
              } else if (dropdownItem?.label?.value) {
                onOptionSelected(dropdownItem.label.value.toString());
              } else if (dropdownItem?.text?.value) {
                onOptionSelected(dropdownItem.text.value.toString());
              }
            }

            if (dropdownItem.onClick) {
              void dropdownItem.onClick(event);
            }
          }} // MANUAL OVERRIDE onClick emitter
        />
      )) || [];

      componentView = (
        <div
          ref={ref} // MANUAL OVERRIDE
          {...otherProps} // MANUAL OVERRIDE
          className={cx(currentStyle, className)}>
          {dropdownItemViews}
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
}); // MANUAL OVERRIDE

export default DropdownItemList;
