import type { CSSProperties } from 'react';
import { Constants } from '../../../../constants';
import { pxToRem } from '../../../../utils/cssUtils';
import type { ElementCornerSquaresProps } from './types';

const cornerSquareSize: number = Constants.LIMITS.SELECTED_ELEMENT_CORNER_SQUARE_SIZE_IN_PX;

const commonStyles: CSSProperties = {
  width: pxToRem(cornerSquareSize),
  height: pxToRem(cornerSquareSize),
};

const topLeftStyles: CSSProperties = {
  ...commonStyles,
  top: pxToRem(cornerSquareSize / -2),
  left: pxToRem(cornerSquareSize / -2),
};

const topRightStyles: CSSProperties = {
  ...commonStyles,
  top: pxToRem(cornerSquareSize / -2),
  right: pxToRem(cornerSquareSize / -2),
};

const bottomLeftStyles: CSSProperties = {
  ...commonStyles,
  bottom: pxToRem(cornerSquareSize / -2),
  left: pxToRem(cornerSquareSize / -2),
};

const bottomRightStyles: CSSProperties = {
  ...commonStyles,
  bottom: pxToRem(cornerSquareSize / -2),
  right: pxToRem(cornerSquareSize / -2),
};

const allStyles = {
  topLeftStyles,
  topRightStyles,
  bottomLeftStyles,
  bottomRightStyles,
};

const usePresenter = (props: ElementCornerSquaresProps): ElementCornerSquaresProps => {
  return allStyles;
};

export default usePresenter;
