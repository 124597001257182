import React from 'react';
import cx from 'classnames';

import Button from '../../atoms/Button';
import Icon from '../../atoms/Icon';
import Text from '../../atoms/Text';
import RowHoverCap from '../../atoms/RowHoverCap';

import styles from './ResponseItem.module.scss';
import usePresenter from './ResponseItem.presenter';
import getProps from './ResponseItem.props';
import { ResponseItemCombinedProps } from './types';

const ResponseItem: React.FC<ResponseItemCombinedProps> = (props) => {
  const {
    state,
    type,
    style,
    className,
    classes,
    button,
    name,
    responseType,
    button1,
    icon,
    rowHoverCap,
    // MANUAL OVERRIDE STARTS
    onMouseOver,
    onFocus,
    onMouseOut,
    onBlur,
    // MANUAL OVERRIDE ENDS
  } = usePresenter(props);

  const variantName = `${state}${type}${style}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`responseItem${variantName}`];

  let componentView;

  switch (variantName) {
    case 'CollapsedExpandedViewAdd':
    case 'ExpandedExpandedViewAdd': {
      componentView = (
        <div
          className={cx(currentStyle, className)}
          onMouseEnter={onMouseOver}
          onFocus={onFocus}
          onMouseLeave={onMouseOut}
          onBlur={onBlur}
        >
          <Button
            className={cx(styles.button, classes?.button)}
            {...internalProps.button}
            {...button}/>
          <div className={cx(styles.content, classes?.content)}>
            <Text
              className={cx(styles.name, classes?.name)}
              {...internalProps.name}
              {...name}/>
            <Text
              className={cx(styles.responseType, classes?.responseType)}
              {...internalProps.responseType}
              {...responseType}/>
            <Button
              className={cx(styles.button1, classes?.button1)}
              {...internalProps.button1}
              {...button1}/>
          </div>
        </div>
      );
      break;
    }
    case 'CollapsedExpandedViewAdded':
    case 'ExpandedExpandedViewAdded': {
      componentView = (
        <div
          className={cx(currentStyle, className)}
          onMouseEnter={onMouseOver}
          onFocus={onFocus}
          onMouseLeave={onMouseOut}
          onBlur={onBlur}
        >
          <Button
            className={cx(styles.button, classes?.button)}
            {...internalProps.button}
            {...button}/>
          <div className={cx(styles.content, classes?.content)}>
            <Text
              className={cx(styles.name, classes?.name)}
              {...internalProps.name}
              {...name}/>
            <Text
              className={cx(styles.responseType, classes?.responseType)}
              {...internalProps.responseType}
              {...responseType}/>
            <Icon
              className={cx(styles.icon, classes?.icon)}
              {...internalProps.icon}
              {...icon}/>
          </div>
        </div>
      );
      break;
    }
    case 'CollapsedExpandedViewDefault':
    case 'CollapsedPanelDefault':
    case 'ExpandedExpandedViewDefault':
    case 'ExpandedPanelDefault': {
      componentView = (
        <div
          className={cx(currentStyle, className)}
          onMouseEnter={onMouseOver}
          onFocus={onFocus}
          onMouseLeave={onMouseOut}
          onBlur={onBlur}
        >
          <Button
            className={cx(styles.button, classes?.button)}
            {...internalProps.button}
            {...button}/>
          <div className={cx(styles.content, classes?.content)}>
            <Text
              className={cx(styles.name, classes?.name)}
              {...internalProps.name}
              {...name}/>
            <Text
              className={cx(styles.responseType, classes?.responseType)}
              {...internalProps.responseType}
              {...responseType}/>
          </div>
        </div>
      );
      break;
    }
    case 'CollapsedPanelAdd':
    case 'ExpandedPanelAdd': {
      componentView = (
        <div
          className={cx(currentStyle, className)}
          onMouseEnter={onMouseOver}
          onFocus={onFocus}
          onMouseLeave={onMouseOut}
          onBlur={onBlur}
        >
          <Button
            className={cx(styles.button, classes?.button)}
            {...internalProps.button}
            {...button}/>
          <div className={cx(styles.content, classes?.content)}>
            <Text
              className={cx(styles.name, classes?.name)}
              {...internalProps.name}
              {...name}/>
            <Text
              className={cx(styles.responseType, classes?.responseType)}
              {...internalProps.responseType}
              {...responseType}/>
          </div>
          <RowHoverCap
            className={cx(styles.rowHoverCap, classes?.rowHoverCap)}
            {...internalProps.rowHoverCap}
            {...rowHoverCap}/>
        </div>
      );
      break;
    }
    case 'DefaultExpandedViewAdd': {
      componentView = (
        <div
          className={cx(currentStyle, className)}
          onMouseEnter={onMouseOver}
          onFocus={onFocus}
          onMouseLeave={onMouseOut}
          onBlur={onBlur}
        >
          <div className={cx(styles.content, classes?.content)}>
            <Text
              className={cx(styles.name, classes?.name)}
              {...internalProps.name}
              {...name}/>
            <Text
              className={cx(styles.responseType, classes?.responseType)}
              {...internalProps.responseType}
              {...responseType}/>
            <Button
              className={cx(styles.button, classes?.button)}
              {...internalProps.button}
              {...button}/>
          </div>
        </div>
      );
      break;
    }
    case 'DefaultExpandedViewAdded': {
      componentView = (
        <div
          className={cx(currentStyle, className)}
          onMouseEnter={onMouseOver}
          onFocus={onFocus}
          onMouseLeave={onMouseOut}
          onBlur={onBlur}
        >
          <div className={cx(styles.content, classes?.content)}>
            <Text
              className={cx(styles.name, classes?.name)}
              {...internalProps.name}
              {...name}/>
            <Text
              className={cx(styles.responseType, classes?.responseType)}
              {...internalProps.responseType}
              {...responseType}/>
            <Icon
              className={cx(styles.icon, classes?.icon)}
              {...internalProps.icon}
              {...icon}/>
          </div>
        </div>
      );
      break;
    }
    case 'DefaultExpandedViewDefault':
    case 'DefaultPanelDefault': {
      componentView = (
        <div
          className={cx(currentStyle, className)}
          onMouseEnter={onMouseOver}
          onFocus={onFocus}
          onMouseLeave={onMouseOut}
          onBlur={onBlur}
        >
          <div className={cx(styles.content, classes?.content)}>
            <Text
              className={cx(styles.name, classes?.name)}
              {...internalProps.name}
              {...name}/>
            <Text
              className={cx(styles.responseType, classes?.responseType)}
              {...internalProps.responseType}
              {...responseType}/>
          </div>
        </div>
      );
      break;
    }
    case 'DefaultPanelAdded': // MANUAL OVERRIDE
    case 'DefaultPanelAdd': {
      componentView = (
        <div
          className={cx(currentStyle, className)}
          onMouseEnter={onMouseOver}
          onFocus={onFocus}
          onMouseLeave={onMouseOut}
          onBlur={onBlur}
        >
          <div className={cx(styles.content, classes?.content)}>
            <Text
              className={cx(styles.name, classes?.name)}
              {...internalProps.name}
              {...name}/>
            <Text
              className={cx(styles.responseType, classes?.responseType)}
              {...internalProps.responseType}
              {...responseType}/>
          </div>
          <RowHoverCap
            className={cx(styles.rowHoverCap, classes?.rowHoverCap)}
            {...internalProps.rowHoverCap}
            {...rowHoverCap}/>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default ResponseItem;
