import { BackgroundPanelProps } from '../BackgroundPanel';
import { BorderPanelProps } from '../BorderPanel';
import { CornersPanelProps } from '../CornersPanel';
import { LayoutPanelProps } from '../LayoutPanel';
import { PaddingMarginsPanelProps } from '../PaddingMarginsPanel';
import { SizePanelProps } from '../SizePanel';
import { StyleEffectsPanelProps } from '../StyleEffectsPanel';
import { TypographyPanelProps } from '../TypographyPanel';

type StylePanelInternalProps = {
  sizePanel?: SizePanelProps;
  paddingMarginsPanel?: PaddingMarginsPanelProps;
  layoutPanel?: LayoutPanelProps;
  typographyPanel?: TypographyPanelProps;
  backgroundPanel?: BackgroundPanelProps;
  borderPanel?: BorderPanelProps;
  cornersPanel?: CornersPanelProps;
  styleEffectsPanel?: StyleEffectsPanelProps;
};

type StylePanelPropsValues = {
  '': StylePanelInternalProps;
};

const propValues: StylePanelPropsValues = {
  '': {
    sizePanel: {
      state: 'Expanded',
    },
    paddingMarginsPanel: {
      state: 'Expanded',
    },
    layoutPanel: {
      state: 'Expanded',
    },
    typographyPanel: {
      state: 'Expanded',
    },
    backgroundPanel: {
      state: 'Expanded',
    },
    borderPanel: {
      state: 'Expanded',
    },
    cornersPanel: {
      state: 'ExpandedUnion',
    },
    styleEffectsPanel: {
      state: 'Expanded',
    },
  },
};

const getProps = (type: string): StylePanelInternalProps => {
  const values: StylePanelInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
