import cx from 'classnames';
import React from 'react';
import { Constants } from '../../../constants';
import { pxToRem } from '../../../utils/cssUtils';

import styles from './Canvas.module.scss';
import usePresenter from './Canvas.presenter';
import getProps from './Canvas.props';
import { CanvasCombinedProps } from './types';

const Canvas: React.FC<CanvasCombinedProps> = ({ children, ...props }) => { // MANUAL OVERRIDE
  const {
    className,
    classes,
    // MANUAL OVERRIDE STARTS
    canvasRootRef,
    canvasRef,
    canvasWidth,
    canvasHeight,
    canvasScale,
    // MANUAL OVERRIDE ENDS
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.canvas;

  return (
    // MANUAL OVERRIDE STARTS
    <div
      ref={canvasRootRef}
      className={cx(currentStyle, className)}
      {...{ [Constants.ATTRIBUTES.CANVAS]: true }}
    >
      <div className={classes?.workspace}>
        <div
          className={classes?.canvasContainer}
          style={{
            width: canvasWidth ? pxToRem(canvasWidth * (canvasScale || 1)) : undefined,
            height: canvasHeight ? pxToRem(canvasHeight * (canvasScale || 1)) : undefined,
          }}
        >
          <div
            ref={canvasRef}
            className={classes?.canvas}
            style={{
              width: pxToRem(canvasWidth || 0),
              minHeight: pxToRem(canvasHeight || 0),
              transform: `scale(${canvasScale || 1})`,
              // Also add extra room for corner squares when elements are selected
              // padding: pxToRem(Constants.LIMITS.SELECTED_ELEMENT_CORNER_SQUARE_SIZE_IN_PX / 2),
            }}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
    // MANUAL OVERRIDE ENDS
  );
};

export default Canvas;
