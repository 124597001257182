import React from 'react';
import cx from 'classnames';

import Button from '../../atoms/Button';
import Text from '../../atoms/Text';

import styles from './SectionLabel.module.scss';
import usePresenter from './SectionLabel.presenter';
import getProps from './SectionLabel.props';
import { SectionLabelCombinedProps } from './types';

const SectionLabel: React.FC<SectionLabelCombinedProps> = (props) => {
  const {
    type,
    style,
    className,
    classes,
    text,
    button,
  } = usePresenter(props);

  const variantName = `${type}${style}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`sectionLabel${variantName}`];

  let componentView;

  switch (variantName) {
    case 'MainSectionTextIconRight':
    case 'SubsectionTextIconRight': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <div className={cx(styles.textContainer, classes?.textContainer)}>
            <Text
              className={cx(styles.text, classes?.text)}
              {...internalProps.text}
              {...text}
            />
          </div>
          <Button
            className={cx(styles.button, classes?.button)}
            {...internalProps.button}
            {...button}
          />
        </div>
      );
      break;
    }
    case 'MainSectionTextNoIcon':
    case 'SubsectionTextNoIcon': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <Text
            className={cx(styles.text, classes?.text)}
            {...internalProps.text}
            {...text}
          />
        </div>
      );
      break;
    }
    case 'SubsectionTextIconLeft': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <Button
            className={cx(styles.button, classes?.button)}
            {...internalProps.button}
            {...button}
          />
          <Text
            className={cx(styles.text, classes?.text)}
            {...internalProps.text}
            {...text}
          />
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default SectionLabel;
