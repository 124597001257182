import { useContext } from 'react';
import { StyleSettingsBlockCombinedProps } from './types';
import customStyles from './Custom.module.scss';
import { StoreContext } from '../../../store/StoreProvider';

const usePresenter = (props: StyleSettingsBlockCombinedProps): StyleSettingsBlockCombinedProps => {
  const { appStore } = useContext(StoreContext);

  return {
    ...props,
    classes: {
      logicPanelContainer: appStore.isRightPanelType('LogicSettings') ? undefined : customStyles.panelHidden,
      stylePanelContainer: appStore.isRightPanelType('Style') ? undefined : customStyles.panelHidden,
      settingPanelContainer: appStore.isRightPanelType('Settings') ? undefined : customStyles.panelHidden,
    },
  };
};

export default usePresenter;
