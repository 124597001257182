import { ButtonProps } from '../../atoms/Button';
import { DividerProps } from '../../atoms/Divider';
import { TextProps } from '../../atoms/Text';

type TextModalInternalProps = {
  heading?: TextProps;
  primaryButton?: ButtonProps;
  divider?: DividerProps;
  text?: TextProps;
  primaryButton1?: ButtonProps;
  secondaryButton?: ButtonProps;
  button?: ButtonProps;
};

type TextModalPropsValues = {
  'Default': TextModalInternalProps;
  'Destructive': TextModalInternalProps;
};

const propValues: TextModalPropsValues = {
  Default: {
    heading: {
      type: 'LabelLarge',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralHoverSelected',
    },
    primaryButton: {
      type: 'IconClear',
      size: 'S',
      state: 'Default',
      style: 'PrimarySubdued',
    },
    divider: {
      type: 'Horizontal',
      colour: 'NeutralBG2',
    },
    text: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Regular400',
      colour: 'NeutralDefault',
    },
    primaryButton1: {
      type: 'Text',
      size: 'M',
      state: 'Default',
      style: 'Primary',
    },
    secondaryButton: {
      type: 'Text',
      size: 'M',
      state: 'Default',
      style: 'Secondary',
    },
  },
  Destructive: {
    heading: {
      type: 'LabelLarge',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralHoverSelected',
    },
    primaryButton: {
      type: 'IconClear',
      size: 'S',
      state: 'Default',
      style: 'PrimarySubdued',
    },
    divider: {
      type: 'Horizontal',
      colour: 'NeutralBG2',
    },
    text: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Regular400',
      colour: 'NeutralDefault',
    },
    button: {
      type: 'Text',
      size: 'M',
      state: 'Default',
      style: 'DestructivePrimary',
    },
    secondaryButton: {
      type: 'Text',
      size: 'M',
      state: 'Default',
      style: 'Secondary',
    },
  },
};

const getProps = (type: string): TextModalInternalProps => {
  const values: TextModalInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
