import { computed } from 'mobx';
import { useContext } from 'react';
import { StoreContext } from '../../../store/StoreProvider';
import type { ElementDropState, IWithId } from '../../../store/types';

export interface IUseElementDropState {
  elementDropState: ElementDropState;
  isDropAllowed: boolean;
  isDropForbidden: boolean;
}

export const useElementDropState = (element: IWithId | undefined): IUseElementDropState => {
  const { pageStore } = useContext(StoreContext);
  const elementDropState: ElementDropState = computed(() => (element ? pageStore.getElementDropState(element) : 'not-drag-hovered')).get();
  const isDropAllowed: boolean = elementDropState === 'drop-allowed';
  const isDropForbidden: boolean = elementDropState === 'drop-forbidden';
  return { elementDropState, isDropAllowed, isDropForbidden };
};
