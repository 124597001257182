import cx from 'classnames';
import React, { type PropsWithChildren } from 'react';
import styles from './ContainerElement.module.scss';
import usePresenter from './ContainerElement.presenter';
import type { ContainerElementProps } from './types';

const ContainerElement: React.FC<PropsWithChildren<ContainerElementProps>> = ({ children, ...props }) => {
  const { elementRef, element, className, style, isElementEdited, ...otherProps } = usePresenter(props);

  return (
    <div
      ref={elementRef}
      className={cx(styles.containerElement, className)}
      style={style}
      {...otherProps}
    >
      {children}
    </div>
  );
};

export default ContainerElement;
