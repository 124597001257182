import { ButtonProps } from '../Button';
import { DividerProps } from '../Divider';

type AddCardLineInternalProps = {
  divider?: DividerProps;
  button?: ButtonProps;
  divider1?: DividerProps;
};

type AddCardLinePropsValues = {
  '': AddCardLineInternalProps;
};

const propValues: AddCardLinePropsValues = {
  '': {
    divider: {
      type: 'Horizontal',
      colour: 'NeutralBG3',
    },
    button: {
      type: 'IconRounded',
      size: 'S',
      state: 'Default',
      style: 'Primary',
    },
    divider1: {
      type: 'Horizontal',
      colour: 'NeutralBG3',
    },
  },
};

const getProps = (type: string): AddCardLineInternalProps => {
  const values: AddCardLineInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
