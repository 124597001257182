import { ButtonProps } from '../../atoms/Button';
import { IconProps } from '../../atoms/Icon';
import { TextProps } from '../../atoms/Text';
import { EndpointCardListProps } from '../../molecules/EndpointCardList';

type EndpointCardBlockInternalProps = {
  icon?: IconProps;
  label?: TextProps;
  instructions?: TextProps;
  button?: ButtonProps;
  endpointCardList?: EndpointCardListProps;
};

type EndpointCardBlockPropsValues = {
  'Empty': EndpointCardBlockInternalProps;
  'Filled': EndpointCardBlockInternalProps;
};

const propValues: EndpointCardBlockPropsValues = {
  Empty: {
    icon: {
      asset: 'ApiEndpoint',
      colour: 'NeutralDefault',
    },
    label: {
      type: 'LabelLarge',
      align: 'Center',
      style: 'Semibold600',
      colour: 'NeutralHoverSelected',
    },
    instructions: {
      type: 'LabelMedium',
      align: 'Center',
      style: 'Regular400',
      colour: 'NeutralDefault',
    },
    button: {
      type: 'Text',
      size: 'M',
      state: 'Default',
      style: 'Primary',
    },
  },
  Filled: {
    endpointCardList: {
    },
  },
};

const getProps = (type: string): EndpointCardBlockInternalProps => {
  const values: EndpointCardBlockInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
