import { LayerLabelItemProps } from '../LayerLabelItem';

type LayerLabelItemListInternalProps = {
  layerLabelItem?: LayerLabelItemProps;
};

type LayerLabelItemListPropsValues = {
  '': LayerLabelItemListInternalProps;
};

const propValues: LayerLabelItemListPropsValues = {
  '': {
    layerLabelItem: {
      type: 'Default',
      state: 'Default',
      style: 'Default',
    },
  },
};

const getProps = (type: string): LayerLabelItemListInternalProps => {
  const values: LayerLabelItemListInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
