import { ToggleItemListProps } from '../../molecules/ToggleItemList';

type StyleSettingsToggleInternalProps = {
  toggleItemList?: ToggleItemListProps;
};

type StyleSettingsTogglePropsValues = {
  '': StyleSettingsToggleInternalProps;
};

const propValues: StyleSettingsTogglePropsValues = {
  '': {
    toggleItemList: {
      size: 'Large',
      type: 'Text',
      style: 'Default',
    },
  },
};

const getProps = (type: string): StyleSettingsToggleInternalProps => {
  const values: StyleSettingsToggleInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
