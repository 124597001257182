import React, { createContext, useMemo, useState } from 'react';

export type NavigateWrapperContextValue = {
  isNavigationBlocked: boolean;
  setIsNavigationBlocked?: (value: boolean) => void;
};

const initialNavigateWrapperContext: NavigateWrapperContextValue = {
  isNavigationBlocked: false,
  setIsNavigationBlocked: () => { },
};

export const NavigateWrapperContext = createContext<NavigateWrapperContextValue>(initialNavigateWrapperContext);

export const NavigateWrapperProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isBlocked, setIsBlocked] = useState(false);
  const setIsNavigationBlocked = (value: boolean) => {
    setIsBlocked(value);
  };
  const navigateWrapperProviderValue = useMemo(() => ({ isNavigationBlocked: isBlocked, setIsNavigationBlocked }), [isBlocked]);

  return (
    <NavigateWrapperContext.Provider value={navigateWrapperProviderValue} >
      {children}
    </NavigateWrapperContext.Provider>
  );
};
