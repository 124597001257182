import { PropertyItemProps } from '../PropertyItem';

type PropertyItemListInternalProps = {
  propertyItem?: PropertyItemProps;
};

type PropertyItemListPropsValues = {
  '': PropertyItemListInternalProps;
};

const propValues: PropertyItemListPropsValues = {
  '': {
    propertyItem: {
      type: 'Default',
      style: 'Text',
    },
  },
};

const getProps = (type: string): PropertyItemListInternalProps => {
  const values: PropertyItemListInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
