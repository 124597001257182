import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { GroupPageTabEnum } from '../../../lib/types';
import { validateGroupHeaderKeyInputEntry, validateGroupHeaderValueInputEntry } from '../../../utils/inputValidations';

import { typedDeepMerge } from '../../../utils/objectUtils';

import { HeaderBlockCombinedProps } from './types';

const usePresenter = (props: HeaderBlockCombinedProps): HeaderBlockCombinedProps => {
  const { selectedTab } = props;
  const { t } = useTranslation();

  const isHeadersTab = useMemo(() => (
    selectedTab === GroupPageTabEnum.HEADERS
  ), [selectedTab]);

  return typedDeepMerge(props, {
    keyValueTable: {
      type: 'Default',
      keyLabel: {
        value: t('groupPage.groupHeaders.key'),
      },
      valueLabel: {
        value: t('groupPage.groupHeaders.value'),
      },
      button: {
        text: {
          value: isHeadersTab
            ? t('groupPage.groupHeaders.addHeaders')
            : t('groupPage.groupParameters.addParameters'),
        },
      },
      validateKeyInputEntry: validateGroupHeaderKeyInputEntry,
      validateValueInputEntry: validateGroupHeaderValueInputEntry,
    },
    errorBar: {
      text: {
        value: t('groupPage.keyValueErrorMessage'),
      },
    },
  });
};

export default usePresenter;
