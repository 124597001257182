import { DropdownButtonColourEnum } from '../components/atoms/DropdownButton/types';
import type { Methods } from '../components/blocks/FunctionBlock/types';
import type { PageElementsMap } from '../store/types';

export const Constants = {
  ID: {
    BODY: 'Body',
    LAYERS_LIST_CONTAINER: 'LayersListContainer',
    MAIN: 'Main',
    PAGES_LIST_CONTAINER: 'PagesListContainer',
    LOGIC_BUILDER_PANEL: 'LogicBuilderPanel',
    LOGIC_BUILDER_FLOW: 'LogicBuilderFlow',
    TOP_NAV: 'TopNav',
  },
  ELEMENT_TYPES: {
    BODY: 'Body',
    BUTTON_CONTAINER: 'ButtonContainer',
    CONTAINER: 'Container',
    IMAGE: 'Image',
    TEXT: 'Text',
  },
  ATTRIBUTES: {
    CANVAS: 'data-canvas',
    ELEMENT_ID: 'data-element-id',
    ELEMENT_TYPE: 'data-element-type',
    DRAG_HANDLE: 'data-drag-handle',
    DROP_ZONE_ELEMENT_ID: 'data-drop-zone-element-id',
    DROP_ZONE_INDEX: 'data-drop-zone-index',
    RESIZE_HANDLE: 'data-resize-handle',
  } satisfies Record<string, `data-${string}`>,
  // All header names must be in lower case
  HEADERS: {
    APPLICATION_ID: 'application-id',
  },
  LIMITS: {
    ELEMENT_NAME_MAX_LENGTH: 30,
    EMPTY_CONTAINER_MIN_HEIGHT_IN_PX: 80,
    PAGE_NAME_MAX_LENGTH: 30,
    SELECTED_ELEMENT_CORNER_SQUARE_SIZE_IN_PX: 8,
    KEY_VALUE_INPUT_MAX_LENGTH: 2000,
  },
  REGEX: {
    // for catching any charcters other than letters and numbers
    INVALID_ELEMENT_NAME_CHARS: /[^a-zA-Z0-9 ]/g,
    // for catching any charcters other than letters and numbers
    INVALID_PAGE_NAME_CHARS: /[^a-zA-Z0-9 ]/g,
    // to validate hexadecimal digits
    VALID_HEXADECIMAL: /[0-9A-Fa-f]{6}/g,
    // to catch spaces on urls
    GROUP_URL_SPACE: /\s/g,
    // for catching double dots on URLS, e.g. www..example.com
    GROUP_URL_DOUBLE_DOT: /[.][.]/,
    // matches any '//' that is not followed by ':'
    DOUBLE_SLASHES_NOT_FOLLOWED_BY_COLON: /[^:][/][/]/,
    // invalid non alphanumeric characters plus - and _
    NOT_ALPHANUMERIC_DASH_AND_UNDERSCORE: /[^a-zA-Z\d\-_]/g,
    // invalid non alphanumeric characters plus - and _
    NOT_ALPHANUMERIC_DASH_AND_UNDERSCORE_SPACE: /[^a-zA-Z0-9_-\s]/g,
    // invalid non allowed characters for header value
    HEADERS_ALLOWED_CHARACTERS: /[^a-zA-Z0-9_\s:;.,/"'?!(\\){}@<>=\-+*#$&`|~^%[\]]/g,
    SEQUENTIAL_BLANK_SPACES: /\s{2,}/g,
    STRINGS_LENGTH_MORE_THAN_50_CHARACTERS: /^.{51,}$/,
    HEADER_VALUE_LENGTH_NOT_MORE_THAN_2000_CHARACTERS: /^.{2000,}$/,
    WHITE_SPACES_ONLY: /^\s+$/,
  },
  DROPDOWN_OPTIONS: {
    CURSOR: ['Auto', 'Grab', 'Pointer'],
    POSITION: ['Static', 'Relative', 'Absolute', 'Fixed', 'Sticky'],
    FONT: ['Inter', 'Irish Grover', 'Font 3', 'Font 4', 'Font 5'],
    WEIGHT: ['Light', 'Regular', 'Medium', 'Semi Bold', 'Bold'],
    DIRECTION: {
      HORIZONTAL: ['Left', 'Center', 'Right'],
      VERTICAL: ['Top', 'Center', 'Bottom'],
    },
    SPACING: ['Packed', 'Space-between', 'Space-around'],
    DIMENSION_UNITS: [
      'px', '%', 'vh', 'vw', 'hug', 'fill',
    ],
    MAX_VALUE_UNITS: ['px', '%', 'vh', 'vw', 'none'],
    MIN_VALUE_UNITS: ['px', '%', 'vh', 'vw'],
    DECIMAL_DIGITS: {
      // (including the dot)
      px: 0,
      '%': 3,
      vh: 3,
      vw: 3,
    },
  },
  TOGGLE: {
    BACKGROUND_SIZE: ['Cover', 'Contain'],
  },
  SYSTEM: {
    COLOUR_CONVERSION_COEFFICIENT: 2.55,
  },
} as const;

export const DEFAULT_ELEMENTS: PageElementsMap = {
  [Constants.ID.BODY]: {
    id: Constants.ID.BODY,
    type: Constants.ELEMENT_TYPES.BODY,
    name: Constants.ID.BODY,
    parentId: '',
    childIds: [Constants.ID.MAIN],
    styles: {},
    settings: {},
  },
  [Constants.ID.MAIN]: {
    id: Constants.ID.MAIN,
    type: Constants.ELEMENT_TYPES.CONTAINER,
    name: Constants.ID.MAIN,
    parentId: Constants.ID.BODY,
    childIds: [],
    styles: {},
    settings: {},
  },
};

export const DEFAULT_ALIGNMENT_VALUES = {
  horizontal: 'left',
  vertical: 'top',
} as const;

export const NEW_GROUP_PLACEHOLDER_UUID = 'new_group_placeholder_uuid';

export const DEFAULT_OPACITY = {
  min: 0,
  max: 100,
} as const;

export const methodColors: Record<Methods, DropdownButtonColourEnum> = {
  GET: 'Green',
  POST: 'Yellow',
  PUT: 'Purple',
  PATCH: 'Pink',
  DELETE: 'Red',
};
