import { Constants } from '../../constants';
import axiosInstance from '../../lib/api/axios';
import { STUDIO_API_URL } from '../../lib/config';
import { CreateVariablePayload, DeleteVariablePayload, UpdateVariablePayload, Variable } from './types';

const applicationId = Number(localStorage.getItem(Constants.HEADERS.APPLICATION_ID)) || 1;
const headers = { headers: { [Constants.HEADERS.APPLICATION_ID]: applicationId } };

export const createVariableApi = async (createVariablePayload: CreateVariablePayload): Promise<Variable> => {
  const { data: createdVariable } = await axiosInstance.post<Variable>(`${STUDIO_API_URL}/variables`, createVariablePayload, headers);
  return createdVariable;
};

export const getVariablesApi = async (): Promise<Variable[]> => {
  const { data: variables } = await axiosInstance.get<Variable[]>(`${STUDIO_API_URL}/variables`, headers);
  return variables;
};

export const updateVariableApi = async (updateVariablePayload: UpdateVariablePayload): Promise<Variable> => {
  const { variableUuid, ...body } = updateVariablePayload;
  const { data: updatedVariable } = await axiosInstance.patch<Variable>(`${STUDIO_API_URL}/variables/${variableUuid}`, body, headers);
  return updatedVariable;
};

export const deleteVariableApi = async (deleteVariablePayload: DeleteVariablePayload): Promise<void> => {
  const { variableUuid } = deleteVariablePayload;
  await axiosInstance.delete(`${STUDIO_API_URL}/variables/${variableUuid}`, headers);
};
