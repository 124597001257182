import { useHoverable } from '../../../hooks';
import { typedDeepMerge } from '../../../utils/objectUtils';
import { ResponseItemCombinedProps } from './types';

const usePresenter = (props: ResponseItemCombinedProps): ResponseItemCombinedProps => {
  const { isHovered, ...hoverHandlers } = useHoverable();

  return typedDeepMerge(props, {
    ...hoverHandlers,
    style: isHovered ? props.style : 'Default',
  });
};

export default usePresenter;
