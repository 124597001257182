import React from 'react';
import cx from 'classnames';

import PaddingMarginGrid from '../../molecules/PaddingMarginGrid';
import PanelHeader from '../../molecules/PanelHeader';

import styles from './PaddingMarginsPanel.module.scss';
import usePresenter from './PaddingMarginsPanel.presenter';
import getProps from './PaddingMarginsPanel.props';
import type { PaddingMarginsPanelCombinedProps } from './types';

const PaddingMarginsPanel: React.FC<PaddingMarginsPanelCombinedProps> = (props) => {
  const {
    state,
    className,
    classes,
    panelHeader,
    paddingMarginGrid,
    renderPanel, // MANUAL OVERRIDE
  } = usePresenter(props);

  // MANUAL OVERRIDE STARTS
  if (!renderPanel) {
    return null;
  }
  // MANUAL OVERRIDE ENDS

  const variantName = `${state}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`paddingMarginsPanel${variantName}`];

  let componentView;

  switch (variantName) {
    case 'Collapsed': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <PanelHeader
            className={cx(styles.panelHeader, classes?.panelHeader)}
            {...internalProps.panelHeader}
            {...panelHeader}
          />
        </div>
      );
      break;
    }
    case 'Expanded': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <PanelHeader
            className={cx(styles.panelHeader, classes?.panelHeader)}
            {...internalProps.panelHeader}
            {...panelHeader}
          />
          <div className={cx(styles.panelContent, classes?.panelContent)}>
            <PaddingMarginGrid
              className={cx(styles.paddingMarginGrid, classes?.paddingMarginGrid)}
              {...internalProps.paddingMarginGrid}
              {...paddingMarginGrid}
            />
          </div>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default PaddingMarginsPanel;
