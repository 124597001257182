import { animated } from '@react-spring/web';
import cx from 'classnames';
import React from 'react';
import { Constants } from '../../../constants';
import ElementRenderer from '../../../modules/canvas/renderers/ElementRenderer';
import Icon from '../../atoms/Icon';
import LeftPanel from '../LeftPanel';
import Canvas from '../../organisms/Canvas';
import PageSettingsPanel from '../../organisms/PageSettingsPanel';
import styles from './MainBlock.module.scss';
import usePresenter from './MainBlock.presenter';
import getProps from './MainBlock.props';
import type { MainBlockCombinedProps } from './types';

const MainBlock: React.FC<MainBlockCombinedProps> = (props) => {
  const {
    state,
    className,
    classes,
    canvas,
    leftPanel,
    // MANUAL OVERRIDE STARTS
    pageSettingsPanel,
    mainBlockRef,
    dragHandleX,
    dragHandleY,
    dragHandleProps,
    dragHandleIcon,
    // MANUAL OVERRIDE ENDS
  } = usePresenter(props);

  const variantName = `${state}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`mainBlock${variantName}`];

  // MANUAL OVERRIDE STARTS
  return (
    <div
      ref={mainBlockRef}
      className={cx(currentStyle, className)}
      {...dragHandleProps?.()}
    >
      <animated.div
        className={cx(styles.dragHandle, classes?.dragHandle)}
        style={{ left: dragHandleX, top: dragHandleY }}
        {...{ [Constants.ATTRIBUTES.DRAG_HANDLE]: true }}
      >
        <Icon
          className={cx(styles.dragHandleIcon, classes?.dragHandleIcon)}
          {...internalProps.dragHandleIcon}
          {...dragHandleIcon}
        />
      </animated.div>
      <LeftPanel
        className={cx(styles.leftPanel, classes?.leftPanel)}
        {...internalProps.leftPanel}
        {...leftPanel}/>
      <PageSettingsPanel
        className={cx(styles.pageSettingsPanel, classes?.pageSettingsPanel)}
        {...internalProps.pageSettingsPanel}
        {...pageSettingsPanel}
      />
      <Canvas
        className={cx(styles.canvas, classes?.canvas)}
        {...internalProps.canvas}
        {...canvas}
      >
        <ElementRenderer id={Constants.ID.BODY} />
      </Canvas>
    </div>
  );
  // MANUAL OVERRIDE ENDS
};

export default MainBlock;
