import { useDropdownToggle } from '../../../modules/common/DropdownWrapper';
import { FieldWithDropdownCombinedProps } from './types';

const usePresenter = (props: FieldWithDropdownCombinedProps): FieldWithDropdownCombinedProps => {
  const [toggleProps] = useDropdownToggle();

  return {
    ...props,
    ...toggleProps,
  };
};

export default usePresenter;
