import {
  FocusEventHandler,
  KeyboardEventHandler, useCallback, useEffect, useRef, useState,
} from 'react';
import cx from 'classnames';
import customStyles from './Custom.module.scss';
import { InputFieldCombinedProps } from './types';

const usePresenter = (props: InputFieldCombinedProps): InputFieldCombinedProps => {
  const {
    textValue,
    textPlaceholder,
    onTextChanged,
    onValidateText,
    onKeyDown,
    onBlur,
    onClick,
    inputType,

    autofocus,
    onSubmit,
    onCancel,
    ...otherProps
  } = props;

  const [submitted, setSubmitted] = useState<boolean>(false);
  const [currentValue, setCurrentValue] = useState<string | number | undefined | null>(textValue);
  const inputFieldRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (autofocus && inputFieldRef.current) {
      inputFieldRef.current.focus();
      inputFieldRef.current.select();
    }
  }, [autofocus]);

  useEffect(() => {
    setCurrentValue(textValue);
    setSubmitted(false);
  }, [textValue, submitted]);

  const handleSubmit = useCallback(() => {
    if (onSubmit) {
      if (!submitted) {
        onSubmit(currentValue ? `${currentValue}` : '');
        setSubmitted(true);
      }
    }
  }, [currentValue, submitted, onSubmit]);

  const handleClick = useCallback((e?: React.MouseEvent<HTMLElement>) => {
    (e?.target as HTMLInputElement)?.select?.();
    if (onClick) {
      onClick(e);
    }
  }, [onClick]);

  const handleTextChange = useCallback((text: string) => {
    setCurrentValue(text);
    if (onTextChanged) {
      onTextChanged(text);
    }
  }, [setCurrentValue, onTextChanged]);

  const handleCancel = useCallback(() => {
    setCurrentValue(textValue);
    if (onCancel) {
      onCancel();
    }
  }, [textValue, onCancel]);

  const handleBlur: FocusEventHandler = useCallback((e) => {
    if (onBlur) {
      onBlur(e);
    }
    handleSubmit();
  }, [handleSubmit, onBlur]);

  const handleKeydown: KeyboardEventHandler = useCallback((event) => {
    if (onKeyDown) {
      onKeyDown(event);
    }
    switch (event.key) {
      case 'Enter':
        handleSubmit();
        // (event.target as HTMLInputElement).blur();
        break;
      case 'Escape':
        handleCancel();
        // (event.target as HTMLInputElement).blur();
        break;
      default:
        break;
    }
  }, [handleSubmit, handleCancel, onKeyDown]);

  return {
    ...otherProps,
    className: cx(
      props.className,
      customStyles.button,
      customStyles[`inputField${otherProps.state}`],
      customStyles[`inputField${otherProps.type}${otherProps.size}`],
    ),
    classes: {
      input: customStyles.input,
      textarea: customStyles.textarea,
      icon: customStyles.icon,
      hash: customStyles.hash,
    },
    inputFieldRef,
    // due to browser behaviour inputType === 'number'
    // will in some case not trigger onChange() callback because the value is considered invalid
    // https://github.com/facebook/react/issues/16554
    inputType: inputType === 'number' ? 'text' : inputType,
    textValue: currentValue,
    textPlaceholder,
    onClick: handleClick,
    onTextChanged: handleTextChange,
    onValidateText,
    onBlur: handleBlur,
    onKeyDown: handleKeydown,
  };
};

export default usePresenter;
