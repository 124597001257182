import React from 'react';
import cx from 'classnames';

import styles from './SpindlLogo.module.scss';
import usePresenter from './SpindlLogo.presenter';
import getProps from './SpindlLogo.props';
import { SpindlLogoCombinedProps } from './types';

import { ReactComponent as SpindlLogoIcon } from '../../../resources/icons/SpindlLogo.svg';

const SpindlLogo: React.FC<SpindlLogoCombinedProps> = (props) => {
  const {
    className,
    classes,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.spindlLogo;

  return (
    <div className={cx(currentStyle, className)}>
      {/* // MANUAL OVERRIDE */}
      <SpindlLogoIcon />
    </div>
  );
};

export default SpindlLogo;
