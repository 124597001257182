import { ButtonProps } from '../../atoms/Button';
import { DropdownButtonProps } from '../../atoms/DropdownButton';
import { TextProps } from '../../atoms/Text';

type TestResponseItemInternalProps = {
  button?: ButtonProps;
  name?: TextProps;
  dropdownButton?: DropdownButtonProps;
};

type TestResponseItemPropsValues = {
  'Collapsed': TestResponseItemInternalProps;
  'Default': TestResponseItemInternalProps;
  'Expanded': TestResponseItemInternalProps;
};

const propValues: TestResponseItemPropsValues = {
  'Collapsed': {
    button: {
      type: 'IconClear',
      size: 'S',
      state: 'Default',
      style: 'PrimarySubdued',
    },
    name: {
      type: 'LabelMedium',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralHoverSelected',
    },
    dropdownButton: {
      type: 'Default',
      style: 'Default',
      state: 'Collapsed',
      colour: 'Default',
    },
  },
  'Default': {
    name: {
      type: 'LabelMedium',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralHoverSelected',
    },
    dropdownButton: {
      type: 'Default',
      style: 'Default',
      state: 'Collapsed',
      colour: 'Default',
    },
  },
  'Expanded': {
    button: {
      type: 'IconClear',
      size: 'S',
      state: 'Default',
      style: 'PrimarySubdued',
    },
    name: {
      type: 'LabelMedium',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralHoverSelected',
    },
    dropdownButton: {
      type: 'Default',
      style: 'Default',
      state: 'Collapsed',
      colour: 'Default',
    },
  },
};

const getProps = (type: string): TestResponseItemInternalProps => {
  const values: TestResponseItemInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
