import { ReplaceInputProps } from '../../atoms/ReplaceInput';
import { TextProps } from '../../atoms/Text';
import { PadMarginCenterProps } from '../../atoms/PadMarginCenter';

type PaddingMarginGridInternalProps = {
  marginTitle?: TextProps;
  topMarginInput?: ReplaceInputProps;
  leftMarginInput?: ReplaceInputProps;
  paddingTitle?: TextProps;
  topPaddingInput?: ReplaceInputProps;
  leftPaddingInput?: ReplaceInputProps;
  padMarginCenter?: PadMarginCenterProps;
  rightPaddingInput?: ReplaceInputProps;
  bottomPaddingInput?: ReplaceInputProps;
  rightMarginInput?: ReplaceInputProps;
  bottomMarginInput?: ReplaceInputProps;
};

type PaddingMarginGridPropsValues = {
  '': PaddingMarginGridInternalProps;
};

const propValues: PaddingMarginGridPropsValues = {
  '': {
    marginTitle: {
      type: 'LabelExtraSmall',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralDefault',
    },
    topMarginInput: {
      state: 'Selected', // MANUAL OVERRIDE
    },
    leftMarginInput: {
      state: 'Selected', // MANUAL OVERRIDE
    },
    paddingTitle: {
      type: 'LabelExtraSmall',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralDefault',
    },
    topPaddingInput: {
      state: 'Selected', // MANUAL OVERRIDE
    },
    leftPaddingInput: {
      state: 'Selected', // MANUAL OVERRIDE
    },
    padMarginCenter: {
    },
    rightPaddingInput: {
      state: 'Selected', // MANUAL OVERRIDE
    },
    bottomPaddingInput: {
      state: 'Selected', // MANUAL OVERRIDE
    },
    rightMarginInput: {
      state: 'Selected', // MANUAL OVERRIDE
    },
    bottomMarginInput: {
      state: 'Selected', // MANUAL OVERRIDE
    },
  },
};

const getProps = (type: string): PaddingMarginGridInternalProps => {
  const values: PaddingMarginGridInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
