import React from 'react';
import cx from 'classnames';

import Button from '../../atoms/Button';
import Icon from '../../atoms/Icon';
import Text from '../../atoms/Text';

import styles from './PropertyItem.module.scss';
import usePresenter from './PropertyItem.presenter';
import getProps from './PropertyItem.props';
import { PropertyItemCombinedProps } from './types';

const PropertyItem: React.FC<PropertyItemCombinedProps> = (props) => {
  const {
    type,
    style,
    className,
    classes,
    icon,
    text,
    button,
  } = usePresenter(props);

  const variantName = `${type}${style}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`propertyItem${variantName}`];

  let componentView;

  switch (variantName) {
    case 'DefaultImage':
    case 'VariableImage': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <Icon
            className={cx(styles.icon, classes?.icon)}
            {...internalProps.icon}
            {...icon}
          />
          <Text
            className={cx(styles.text, classes?.text)}
            {...internalProps.text}
            {...text}
          />
          <Button
            className={cx(styles.button, classes?.button)}
            {...internalProps.button}
            {...button}
          />
        </div>
      );
      break;
    }
    case 'DefaultText':
    case 'VariableText': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <Text
            className={cx(styles.text, classes?.text)}
            {...internalProps.text}
            {...text}
          />
          <Button
            className={cx(styles.button, classes?.button)}
            {...internalProps.button}
            {...button}
          />
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default PropertyItem;
