import { DropdownButtonWrapperProps } from '.';
import { PageStore } from '../../../store/PageStore/PageStore';
import {
  getAnchorPoint, getEnumValue, getEnumValuesBasedOnProperty, updateAnchorPoint, updateEnumValue,
} from '../../../store/PageStore/utils';
import { AnchorProperty, Element, EnumTypeProperty } from '../../../store/types';
import { capitalize } from '../../../utils/stringUtils';
import { DropdownItemCombinedProps } from '../DropdownItem/types';
import { DropdownButtonCombinedProps } from './types';

export const buildDropdownButtonProps = (
  value: string | undefined,
  defaultValue: string,
  allValues: readonly string[],
  transformText: (value: string) => string,
  onOptionSelected: (option: string) => void,
  options?: {
    toggleProps?: DropdownButtonCombinedProps,
    mapDropdownItem?: (option: string, isSelected: boolean) => DropdownItemCombinedProps,
    onToggle?: (show: boolean) => void,
  },
): DropdownButtonWrapperProps => {
  return {
    onToggle: options?.onToggle,
    toggleProps: {
      type: 'Default',
      style: 'Default',
      colour: 'Default',
      ...options?.toggleProps,
      text: {
        value: transformText(value || defaultValue),
      },
    },
    menuProps: {
      style: 'Checkmark',
      dropdownItems: allValues.map((option): DropdownItemCombinedProps => {
        const isSelected = option === value;
        const mappedProps = (options?.mapDropdownItem && options?.mapDropdownItem(option, isSelected)) || {};
        return {
          text: {
            value: transformText(option),
          },
          state: isSelected ? 'Selected' : 'Default',
          icon: isSelected ? {
            asset: 'Check',
          } : undefined,
          ...mappedProps,
        };
      }),
      onOptionSelected,
    },
  };
};

export const buildEnumDropdownButtonProps = (
  pageStore: PageStore,
  selectedElement: Element | undefined,
  property: EnumTypeProperty,
  defaultValue: string,
  options?: {
    onOptionSelected?: (option: string) => Promise<void>,
    valueToText?: (value: string) => string,
    textToValue?: (option: string) => string,
    toggleProps?: DropdownButtonCombinedProps,
    mapDropdownItem?: (option: string, isSelected: boolean) => DropdownItemCombinedProps,
    onToggle?: (show: boolean) => void,
  },
): DropdownButtonWrapperProps => {
  const textToValue = (option: string) => (options?.textToValue
    ? options.textToValue(option)
    : option.toLowerCase());
  const valueToText = (option: string) => (options?.valueToText
    ? options.valueToText(option)
    : capitalize(option));

  const onOptionSelected = (option: string) => (options?.onOptionSelected
    ? options.onOptionSelected(textToValue(option))
    : updateEnumValue(pageStore, selectedElement, property, textToValue(option)));

  return buildDropdownButtonProps(
    getEnumValue(selectedElement, property),
    defaultValue,
    getEnumValuesBasedOnProperty(property),
    valueToText,
    onOptionSelected,
    options,
  );
};

export const buildAnchorDropdownButtonProps = (
  pageStore: PageStore,
  selectedElement: Element | undefined,
  property: AnchorProperty,
  defaultValue: string,
  options?: {
    onOptionSelected?: (option: string) => Promise<void>,
    valueToText?: (value: string) => string,
    textToValue?: (option: string) => string,
    toggleProps?: DropdownButtonCombinedProps,
    mapDropdownItem?: (option: string, isSelected: boolean) => DropdownItemCombinedProps,
    onToggle?: (show: boolean) => void,
  },
): DropdownButtonWrapperProps => {
  const textToValue = (option: string) => (options?.textToValue
    ? options.textToValue(option)
    : option.toLowerCase());
  const valueToText = (option: string) => (options?.valueToText
    ? options.valueToText(option)
    : capitalize(option));

  const onOptionSelected = (option: string) => (options?.onOptionSelected
    ? options.onOptionSelected(textToValue(option))
    : updateAnchorPoint(pageStore, selectedElement, property, textToValue(option)));

  return buildDropdownButtonProps(
    getAnchorPoint(selectedElement, property),
    defaultValue,
    getEnumValuesBasedOnProperty(property),
    valueToText,
    onOptionSelected,
    options,
  );
};
