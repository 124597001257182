import React from 'react';
import cx from 'classnames';

import ReplaceInput from '../../atoms/ReplaceInput';
import Text from '../../atoms/Text';
import PadMarginCenter from '../../atoms/PadMarginCenter';

import styles from './PaddingMarginGrid.module.scss';
import usePresenter from './PaddingMarginGrid.presenter';
import getProps from './PaddingMarginGrid.props';
import { PaddingMarginGridCombinedProps } from './types';

const PaddingMarginGrid: React.FC<PaddingMarginGridCombinedProps> = (props) => {
  const {
    className,
    classes,
    marginTitle,
    topMarginInput,
    leftMarginInput,
    paddingTitle,
    topPaddingInput,
    leftPaddingInput,
    padMarginCenter,
    rightPaddingInput,
    bottomPaddingInput,
    rightMarginInput,
    bottomMarginInput,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.paddingMarginGrid;

  return (
    <div className={cx(currentStyle, className)}>
      <div className={cx(styles.topMargin, classes?.topMargin)}>
        <Text
          className={cx(styles.marginTitle, classes?.marginTitle)}
          {...internalProps.marginTitle}
          {...marginTitle}/>
        <ReplaceInput
          className={cx(styles.topMarginInput, classes?.topMarginInput)}
          {...internalProps.topMarginInput}
          {...topMarginInput}/>
      </div>
      <div className={cx(styles.middleContent, classes?.middleContent)}>
        <div className={cx(styles.leftMargin, classes?.leftMargin)}>
          <ReplaceInput
            className={cx(styles.leftMarginInput, classes?.leftMarginInput)}
            {...internalProps.leftMarginInput}
            {...leftMarginInput}/>
        </div>
        <div className={cx(styles.paddingGrid, classes?.paddingGrid)}>
          <div className={cx(styles.topPadding, classes?.topPadding)}>
            <Text
              className={cx(styles.paddingTitle, classes?.paddingTitle)}
              {...internalProps.paddingTitle}
              {...paddingTitle}/>
            <ReplaceInput
              className={cx(styles.topPaddingInput, classes?.topPaddingInput)}
              {...internalProps.topPaddingInput}
              {...topPaddingInput}/>
          </div>
          <div className={cx(styles.middleContent1, classes?.middleContent1)}>
            <div className={cx(styles.leftPadding, classes?.leftPadding)}>
              <ReplaceInput
                className={cx(styles.leftPaddingInput, classes?.leftPaddingInput)}
                {...internalProps.leftPaddingInput}
                {...leftPaddingInput}/>
            </div>
            <PadMarginCenter
              className={cx(styles.padMarginCenter, classes?.padMarginCenter)}
              {...internalProps.padMarginCenter}
              {...padMarginCenter}/>
            <div className={cx(styles.rightPadding, classes?.rightPadding)}>
              <ReplaceInput
                className={cx(styles.rightPaddingInput, classes?.rightPaddingInput)}
                {...internalProps.rightPaddingInput}
                {...rightPaddingInput}/>
            </div>
          </div>
          <div className={cx(styles.bottomPadding, classes?.bottomPadding)}>
            <ReplaceInput
              className={cx(styles.bottomPaddingInput, classes?.bottomPaddingInput)}
              {...internalProps.bottomPaddingInput}
              {...bottomPaddingInput}/>
          </div>
        </div>
        <div className={cx(styles.rightMargin, classes?.rightMargin)}>
          <ReplaceInput
            className={cx(styles.rightMarginInput, classes?.rightMarginInput)}
            {...internalProps.rightMarginInput}
            {...rightMarginInput}/>
        </div>
      </div>
      <div className={cx(styles.bottomMargin, classes?.bottomMargin)}>
        <ReplaceInput
          className={cx(styles.bottomMarginInput, classes?.bottomMarginInput)}
          {...internalProps.bottomMarginInput}
          {...bottomMarginInput}/>
      </div>
    </div>
  );
};

export default PaddingMarginGrid;
