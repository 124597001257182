import React from 'react';
import cx from 'classnames';

import InputField from '../../atoms/InputField';
import RowLabel from '../../atoms/RowLabel';
import ColourRow from '../../molecules/ColourRow';
import PanelHeader from '../../molecules/PanelHeader';
import ToggleItemList from '../../molecules/ToggleItemList';

import styles from './BorderPanel.module.scss';
import usePresenter from './BorderPanel.presenter';
import getProps from './BorderPanel.props';
import type { BorderPanelCombinedProps } from './types';

const BorderPanel: React.FC<BorderPanelCombinedProps> = (props) => {
  const {
    state,
    className,
    classes,
    panelHeader,
    weight,
    inputField,
    toggleItemList,
    colourRow,
    renderPanel, // MANUAL OVERRIDE
  } = usePresenter(props);

  // MANUAL OVERRIDE STARTS
  if (!renderPanel) {
    return null;
  }
  // MANUAL OVERRIDE ENDS

  const variantName = `${state}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`borderPanel${variantName}`];

  let componentView;

  switch (variantName) {
    case 'Collapsed': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <PanelHeader
            className={cx(styles.panelHeader, classes?.panelHeader)}
            {...internalProps.panelHeader}
            {...panelHeader}
          />
        </div>
      );
      break;
    }
    case 'Expanded': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <PanelHeader
            className={cx(styles.panelHeader, classes?.panelHeader)}
            {...internalProps.panelHeader}
            {...panelHeader}
          />
          <div className={cx(styles.borderContent, classes?.borderContent)}>
            <div className={cx(styles.stylingContent, classes?.stylingContent)}>
              <RowLabel
                className={cx(styles.weight, classes?.weight)}
                {...internalProps.weight}
                {...weight}
              />
              <InputField
                className={cx(styles.inputField, classes?.inputField)}
                {...internalProps.inputField}
                {...inputField}
              />
              <ToggleItemList
                className={cx(styles.toggleItemList, classes?.toggleItemList)}
                {...internalProps.toggleItemList}
                {...toggleItemList}
              />
            </div>
            <ColourRow
              className={cx(styles.colourRow, classes?.colourRow)}
              {...internalProps.colourRow}
              {...colourRow}
            />
          </div>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default BorderPanel;
