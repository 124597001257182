import {
  Element, AnchorProperty, VerticalAnchor, HorizontalAnchor, Unit,
} from '../types';
import { PageStore } from './PageStore';
import { defaultUnitType, handleUnitTypeValue } from './unitTypeUtils';

const getDefaultAnchor = (property: AnchorProperty): VerticalAnchor | HorizontalAnchor => {
  switch (property) {
    case 'backgroundVerticalAnchor':
    case 'verticalAnchor':
      return {
        anchorPoint: 'top',
        offset: defaultUnitType,
      };
    default:
      return {
        anchorPoint: 'left',
        offset: defaultUnitType,
      };
  }
};

export const getAnchorPoint = (
  selectedElement: Element | undefined,
  property: AnchorProperty,
) => {
  const anchor = selectedElement?.styles[property] || getDefaultAnchor(property);
  return anchor.anchorPoint;
};

export const getAnchorOffsetValue = (
  selectedElement: Element | undefined,
  property: AnchorProperty,
) => {
  const anchor = selectedElement?.styles[property];
  return handleUnitTypeValue(anchor?.offset || defaultUnitType);
};

export const getAnchorOffsetUnit = (
  selectedElement: Element | undefined,
  property: AnchorProperty,
) => {
  const anchor = selectedElement?.styles[property];
  return (anchor?.offset || defaultUnitType).unit;
};

export const updateAnchorPoint = async (
  pageStore: PageStore,
  selectedElement: Element | undefined,
  property: AnchorProperty,
  value: string,
) => {
  if (selectedElement) {
    const anchor = selectedElement.styles[property] || getDefaultAnchor(property);
    await pageStore.updateElement(selectedElement, {
      styles: {
        [property]: {
          anchorPoint: value,
          offset: anchor.offset,
        },
      },
    });
  }
};

export const updateAnchorOffsetValue = async (
  pageStore: PageStore,
  selectedElement: Element | undefined,
  property: AnchorProperty,
  value: string,
) => {
  if (selectedElement) {
    const anchor = selectedElement.styles[property] || getDefaultAnchor(property);
    await pageStore.updateElement(selectedElement, {
      styles: {
        [property]: {
          anchorPoint: anchor.anchorPoint,
          offset: {
            ...anchor.offset,
            value: +value,
          },
        },
      },
    });
  }
};

export const updateAnchorOffsetUnit = async (
  pageStore: PageStore,
  selectedElement: Element | undefined,
  property: AnchorProperty,
  value: string,
) => {
  if (selectedElement) {
    const anchor = selectedElement.styles[property] || getDefaultAnchor(property);
    await pageStore.updateElement(selectedElement, {
      styles: {
        [property]: {
          anchorPoint: anchor.anchorPoint,
          offset: {
            ...anchor.offset,
            unit: value as Unit,
          },
        },
      },
    });
  }
};
