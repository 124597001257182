import deepmerge from 'deepmerge';

/** Typed wrapper for deepmerge function to ensure arguments are of the same type */
export const typedDeepMerge = <T extends Record<string, unknown>>(source: T, target: Partial<T>, options?: deepmerge.Options): T => {
  return deepmerge<T>(source, target, options);
};

export const clone = <T extends Record<string, unknown>>(sourceObj: T): T => {
  return JSON.parse(JSON.stringify(sourceObj)) as T;
};
