import { computed } from 'mobx';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
  type FocusEventHandler,
  type KeyboardEventHandler,
  type MouseEventHandler,
} from 'react';
import { useTranslation } from 'react-i18next';
import { createSearchParams } from 'react-router-dom';
import { Constants } from '../../../constants';
import { useHoverable } from '../../../hooks';
import { ROUTES } from '../../../lib/constants';
import { StoreContext } from '../../../store/StoreProvider';
import { IPage, NewPageUuid } from '../../../store/types';
import { typedDeepMerge } from '../../../utils/objectUtils';
import type { ModalPosition } from '../../../modules/common/ModalWrapper/types';
import { getCustomPositionForElementRight } from '../../../modules/common/ModalWrapper/utils';
import type { PageLabelItemCombinedProps, PageLabelItemStateEnum } from './types';
import { useNavigateWrapper } from '../../../modules/navigation/hooks/useNavigateWrapper';

const usePresenter = (props: PageLabelItemCombinedProps): PageLabelItemCombinedProps => {
  const { t } = useTranslation();
  const { navigate } = useNavigateWrapper();
  const { appStore, pageStore } = useContext(StoreContext);

  const { pageUuid } = props;
  const page: IPage | undefined = computed(() => (pageUuid ? pageStore.getPageByUuid(pageUuid) : undefined)).get();

  const pageRef = useRef<HTMLDivElement>(null);
  const textInputRef = useRef<HTMLInputElement>(null);

  const isCurrentPage: boolean = computed(() => pageStore.isCurrentPage(pageUuid)).get();
  const isHomePage: boolean = computed(() => (page?.uuid ? pageStore.isHomePage(page.uuid) : false)).get();
  const isNewPage: boolean = useMemo(() => pageUuid === NewPageUuid, [pageUuid]);
  const { isHovered, ...hoverHandlers } = useHoverable();

  const isPageNameEdited: boolean = computed(() => pageStore.isPageNameEdited(pageUuid)).get();
  const [pageEditedName, setPageEditedName] = useState<string>('');
  const [shouldSelectPageEditedName, setShouldSelectPageEditedName] = useState<boolean>(false);

  useEffect(() => {
    // Initialise page edited name with the current page name
    if (isPageNameEdited) {
      setPageEditedName(page?.name ?? pageStore.generatePageNameWithIndex());
      setShouldSelectPageEditedName(true);
    }
  }, [isPageNameEdited, page, pageStore]);

  useEffect(() => {
    // Once page edited name is initialised then set focus and select the entire name
    if (textInputRef.current && shouldSelectPageEditedName) {
      textInputRef.current.focus();
      textInputRef.current.select();
      setShouldSelectPageEditedName(false);
    }
  }, [shouldSelectPageEditedName]);

  // Auto scroll to the bottom of the page list when a new page is created
  useEffect(() => {
    if (pageRef.current && isNewPage) {
      const pagesListContainer = document.getElementById(Constants.ID.PAGES_LIST_CONTAINER);
      if (pagesListContainer) {
        const { offsetTop: containerTop } = pagesListContainer;
        const { offsetTop: elementTop } = pageRef.current;
        pagesListContainer.scrollTo({
          left: 0,
          top: elementTop - containerTop,
          behavior: 'smooth',
        });
      }
    }
  }, [isNewPage]);

  const onPageNameChanged = useCallback((newPageName: string) => {
    // Page names must be up to 30 characters long
    // They may only include letters, numbers or spaces
    const validatedNewPageName: string = newPageName
      .substring(0, Constants.LIMITS.PAGE_NAME_MAX_LENGTH)
      .replace(Constants.REGEX.INVALID_PAGE_NAME_CHARS, '');
    setPageEditedName(validatedNewPageName);
  }, []);

  const onPageNameSubmitted = useCallback(async () => {
    const trimmedPageEditedName: string = pageEditedName.trim();
    try {
      // TODO: Do we need to check if the page name is unique per application?
      if (isPageNameEdited && trimmedPageEditedName) {
        if (!page) {
          await pageStore.createPage({ name: trimmedPageEditedName, isHomePage: false });
        } else {
          await pageStore.updatePage(page, { name: trimmedPageEditedName });
        }
      }
    } finally {
      await pageStore.exitPageOrElementEditMode({ selectHomePageIfNeeded: true });
    }
  }, [isPageNameEdited, page, pageEditedName, pageStore]);

  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  const onTextInputKeyDown: KeyboardEventHandler = useCallback(async (event) => {
    event.stopPropagation();
    if (event.key === 'Enter') {
      await onPageNameSubmitted();
    } else if (event.key === 'Escape') {
      await pageStore.exitPageOrElementEditMode({ selectHomePageIfNeeded: true });
    }
  }, [onPageNameSubmitted, pageStore]);

  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  const onTextInputBlur: FocusEventHandler = useCallback(async (event) => {
    event.stopPropagation();
    await onPageNameSubmitted();
  }, [onPageNameSubmitted]);

  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  const onPageClick: MouseEventHandler = useCallback(async (event) => {
    if (page?.uuid) {
      switch (event.detail) {
        // Single click - Select page
        case 1: {
          navigate({
            pathname: ROUTES.baseRoute,
            search: `?${createSearchParams({
              pageUuid: `${page.uuid}`,
            }).toString()}`,
          });
          await pageStore.setAndLoadCurrentPage(page.uuid);
          break;
        }
        // Double click - Rename page
        case 2: {
          pageStore.setPageUuidWithEditedName(page.uuid);
          break;
        }
        default: break;
      }
    }
  }, [page?.uuid, pageStore, navigate]);

  const [isContextMenuOpen, setIsContextMenuOpen] = useState<boolean>(false);
  const closeContextMenu = useCallback(() => setIsContextMenuOpen(false), []);
  const [contextMenuPosition, setContextMenuPosition] = useState<ModalPosition | undefined>();

  const onShowMoreButtonClicked = useCallback(() => {
    const xOffsetInPx = -24;
    const yOffsetInPx = 16;
    setContextMenuPosition(getCustomPositionForElementRight(pageRef, xOffsetInPx, yOffsetInPx));
    setIsContextMenuOpen(true);
  }, []);

  const onPageSettingsOpen = useCallback(() => {
    closeContextMenu();
    if (page) {
      pageStore.setPageUuidWithEditedSettings(page.uuid);
      appStore.setLeftPanelType('PageSettings');
    }
  }, [closeContextMenu, page, pageStore, appStore]);

  const onPageDelete = useCallback(() => {
    // Need to close the context menu first and then delete the page (display confirmation popup, etc.).
    // But because 'setIsContextMenuOpen(false)' is asynchronous, need to use setTimeout to process the deletion in the next tick.
    closeContextMenu();
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    setTimeout(async () => {
      if (page) {
        const deleteConfirmationMessage: string = t('pageLabelItem.areYouSureYouWantToDeleteThisPage');
        // TODO: Replace alert with a better notification
        if (confirm(deleteConfirmationMessage)) { // eslint-disable-line no-restricted-globals
          await pageStore.deletePage({ page });
        }
      }
    });
  }, [closeContextMenu, page, pageStore, t]);

  const onPageRename = useCallback(() => {
    closeContextMenu();
    if (page) {
      pageStore.setPageUuidWithEditedName(page.uuid);
    }
  }, [closeContextMenu, page, pageStore]);

  let state: PageLabelItemStateEnum;
  if (isCurrentPage) {
    if (isPageNameEdited) {
      state = 'EditableSelected';
    } else {
      state = 'Selected';
    }
  } else {
    state = 'Default';
  }
  return {
    ...typedDeepMerge(props, {
      style: (isHovered && !isPageNameEdited) || isContextMenuOpen ? 'ShowMenu' : 'Default',
      state,
      type: isHomePage ? 'Homepage' : 'Default',
      isHomePage,
      icon: {
        asset: 'Home',
        colour: isCurrentPage || isHovered ? 'NeturalHoverSelected' : 'NeutralDefault',
      },
      text: {
        value: page?.name,
        colour: isCurrentPage || isHovered ? 'NeutralHoverSelected' : 'NeutralDefault',
      },
      button: {
        icon: {
          asset: 'MoreVert',
          colour: 'NeturalHoverSelected',
        },
        onClick: onShowMoreButtonClicked,
      },
      isHovered,
      ...hoverHandlers,
      onClick: onPageClick,
      isContextMenuOpen,
      closeContextMenu,
      contextMenuPosition,
    }),
    textInput: {
      ...props.textInput,
      textInputRef,
      textValue: pageEditedName,
      onTextChanged: onPageNameChanged,
      onKeyDown: onTextInputKeyDown,
      onBlur: onTextInputBlur,
    },
    pageRef,
    contextMenuItems: [
      {
        text: { value: t('pageLabelItem.pageSettings') },
        state: 'Default',
        type: 'TextOnly',
        onClick: onPageSettingsOpen,
      },
      {
        text: { value: t('pageLabelItem.deletePage') },
        state: 'Default',
        type: 'TextOnly',
        onClick: onPageDelete,
      },
      {
        text: { value: t('pageLabelItem.renamePage') },
        state: 'Default',
        type: 'TextOnly',
        onClick: onPageRename,
      },
    ],
  };
};

export default usePresenter;
