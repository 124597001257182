import React, { ChangeEvent, useCallback, useRef, useState } from 'react';
import { SelectFileWrapperProps } from './types';

const SelectFileWrapper: React.FC<SelectFileWrapperProps> = (props) => {
  const { accept, renderTrigger, onFileSelected, onFileRemoved } = props;

  const [currentFile, setCurrentFile] = useState<File>();
  const inputRef = useRef<HTMLInputElement | null>(null);

  const onPickFile = () => inputRef.current?.click();

  const handleFileChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files?.length) {
      return;
    }
    const newFile = e.target.files[0];
    setCurrentFile(newFile);
    if (onFileSelected) {
      onFileSelected(newFile);
    }
    e.target.value = '';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClearFile = () => {
    setCurrentFile(undefined);
    if (onFileRemoved) {
      onFileRemoved();
    }
  };

  if (!renderTrigger) {
    return null;
  }
  const acceptTypes = accept?.join(',');
  const triggerView = renderTrigger({ currentFile, onPickFile, onClearFile });

  return (
    <>
      {triggerView}
      <input
        type="file"
        ref={inputRef}
        style={{ display: 'none' }}
        accept={acceptTypes}
        onChange={handleFileChange}
      />
    </>
  );
};

export default SelectFileWrapper;
