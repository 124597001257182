import { ButtonProps } from '../../atoms/Button';
import { IconProps } from '../../atoms/Icon';
import { TextProps } from '../../atoms/Text';

type EmptyGroupBlockInternalProps = {
  icon?: IconProps;
  label?: TextProps;
  instructions?: TextProps;
  button?: ButtonProps;
};

type EmptyGroupBlockPropsValues = {
  'EmptyEndpoints': EmptyGroupBlockInternalProps;
  'EmptyGroup': EmptyGroupBlockInternalProps;
};

const propValues: EmptyGroupBlockPropsValues = {
  EmptyEndpoints: {
    icon: {
      asset: 'ApiEndpoint',
      colour: 'NeutralDefault',
    },
    label: {
      type: 'LabelLarge',
      align: 'Center',
      style: 'Semibold600',
      colour: 'NeutralHoverSelected',
    },
    instructions: {
      type: 'LabelMedium',
      align: 'Center',
      style: 'Regular400',
      colour: 'NeutralDefault',
    },
    button: {
      type: 'Text',
      size: 'M',
      state: 'Default',
      style: 'Primary',
    },
  },
  EmptyGroup: {
    icon: {
      asset: 'ApiGroup',
      colour: 'NeutralDefault',
    },
    label: {
      type: 'LabelLarge',
      align: 'Center',
      style: 'Semibold600',
      colour: 'NeutralHoverSelected',
    },
    instructions: {
      type: 'LabelMedium',
      align: 'Center',
      style: 'Regular400',
      colour: 'NeutralDefault',
    },
    button: {
      type: 'Text',
      size: 'M',
      state: 'Default',
      style: 'Primary',
    },
  },
};

const getProps = (type: string): EmptyGroupBlockInternalProps => {
  const values: EmptyGroupBlockInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
