import { TextProps } from '../../atoms/Text';
import { MethodChipProps } from '../../atoms/MethodChip';

type EndpointCardInternalProps = {
  methodChip?: MethodChipProps;
  label?: TextProps;
  path?: TextProps;
};

type EndpointCardPropsValues = {
  '': EndpointCardInternalProps;
};

const propValues: EndpointCardPropsValues = {
  '': {
    methodChip: {
      type: 'Get',
    },
    label: {
      type: 'LabelLarge',
      align: 'Left',
      style: 'Medium500',
      colour: 'NeutralHoverSelected',
    },
    path: {
      type: 'LabelLarge',
      align: 'Left',
      style: 'Regular400',
      colour: 'NeutralDefault',
    },
  },
};

const getProps = (type: string): EndpointCardInternalProps => {
  const values: EndpointCardInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
