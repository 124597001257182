import { InputFieldProps } from '../../atoms/InputField';
import { RowLabelProps } from '../../atoms/RowLabel';
import { ColourRowProps } from '../../molecules/ColourRow';
import { PanelHeaderProps } from '../../molecules/PanelHeader';
import { ToggleItemListProps } from '../../molecules/ToggleItemList';

type BorderPanelInternalProps = {
  panelHeader?: PanelHeaderProps;
  weight?: RowLabelProps;
  inputField?: InputFieldProps;
  toggleItemList?: ToggleItemListProps;
  colourRow?: ColourRowProps;
};

type BorderPanelPropsValues = {
  'Collapsed': BorderPanelInternalProps;
  'Expanded': BorderPanelInternalProps;
};

const propValues: BorderPanelPropsValues = {
  Collapsed: {
    panelHeader: {
      state: 'Collapsed',
      type: 'Default',
    },
  },
  Expanded: {
    panelHeader: {
      state: 'Expanded',
      type: 'Default',
    },
    weight: {
      state: 'Default',
      style: 'Default',
      type: 'Panel',
    },
    inputField: {
      type: 'TextUnit',
      size: 'Medium',
      state: 'Default',
    },
    toggleItemList: {
      size: 'Small',
      type: 'Icon',
      style: 'Default',
    },
    colourRow: {
    },
  },
};

const getProps = (type: string): BorderPanelInternalProps => {
  const values: BorderPanelInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
