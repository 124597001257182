import { useContext, useState } from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import customStyles from './Custom.module.scss';
import { useExpandablePanel } from '../../../hooks';
import { StoreContext } from '../../../store/StoreProvider';
import { typedDeepMerge } from '../../../utils/objectUtils';
import type { StyleEffectsPanelCombinedProps, StyleEffectsPanelStateEnum } from './types';
import { buildEnumDropdownButtonProps } from '../../atoms/DropdownButton/utils';
import { IconAssetEnum } from '../../atoms/Icon/types';
import { getStylePanelConfigurations } from '../../../store/PageStore/utils';
import { opacityInputValidation, valueWithFallback } from '../../../utils/inputValidations';

const usePresenter = (props: StyleEffectsPanelCombinedProps): StyleEffectsPanelCombinedProps => {
  const { t } = useTranslation();
  const { pageStore } = useContext(StoreContext);
  const selectedElement = pageStore.selectedCombineElement;
  const [expandedState, setExpandedState] = useState<StyleEffectsPanelStateEnum & `Expanded${string}`>('Expanded');
  const panelConfig = getStylePanelConfigurations(selectedElement);

  const expandableProps = useExpandablePanel(props, expandedState, true);

  const cursor = selectedElement?.styles?.cursor;
  const opacity = selectedElement?.styles?.opacity;
  const visible = selectedElement?.styles?.visible;

  const handleCursorIcon = (value?: string): IconAssetEnum => {
    switch (value) {
      case 'auto':
        return 'AutoCursor';
      case 'grab':
        return 'ClosedHand';
      case 'pointer':
        return 'Pointer';
      default:
        return 'AutoCursor';
    }
  };

  const handleVisibility = async (value: string) => {
    if (selectedElement) {
      await pageStore.updateElement(selectedElement, {
        styles: {
          visible: value === 'yes',
        },
      });
    }
  };

  const handleUpdateOpacity = async (value: string): Promise<void> => {
    if (selectedElement) {
      await pageStore.updateElement(selectedElement, {
        styles: {
          opacity: {
            value: Number(value),
            unit: '%',
          },
        },
      });
    }
  };

  return typedDeepMerge(expandableProps, {
    renderPanel: panelConfig?.StyleEffects,
    className: customStyles.styleEffectsPanel,
    classes: {
      shadowContent: customStyles.hideSection,
    },
    panelHeader: {
      text: {
        value: t('styleEffectsPanel.effectsAndStyles'),
      },
    },
    rowLabel: {
      text: {
        value: t('styleEffectsPanel.visible'),
      },
    },
    toggleItemList: {
      currentValue: visible ? 'yes' : 'no',
      toggleOptions: [
        {
          value: 'yes',
          icon: 'Visibility',
        },
        {
          value: 'no',
          icon: 'Hidden',
        },
      ],
      onOptionSelected: (option) => {
        void handleVisibility(option);
      },
    },
    rowLabel1: {
      text: {
        value: t('styleEffectsPanel.opacity'),
      },
    },
    inputField: {
      textValue: valueWithFallback(opacity?.value, 100),
      inputType: 'number',
      button: {
        text: {
          value: t('units.%'),
        },
      },
      onValidateText: opacityInputValidation,
      onSubmit: (value) => handleUpdateOpacity(value),
    },
    rowLabel2: {
      text: {
        value: t('styleEffectsPanel.shadow'),
      },
    },
    button: {
      icon: {
        asset: 'Add',
      },
      text: {
        value: t('styleEffectsPanel.addInnerDropShadow'),
      },
      onClick: () => setExpandedState('ExpandedShadows'),
    },
    propertyItemList: {
      propertyItems: [
        {
          type: 'Default',
          style: 'Text',
          text: {
            value: t('styleEffectsPanel.innerShadow'),
          },
          button: {
            icon: {
              asset: 'Close',
            },
          },
        },
        {
          type: 'Default',
          style: 'Text',
          text: {
            value: t('styleEffectsPanel.dropShadow'),
          },
          button: {
            icon: {
              asset: 'Close',
            },
          },
        },
      ],
    },
    rowLabel3: {
      text: {
        value: t('styleEffectsPanel.cursor'),
      },
    },
    dropdownButton: buildEnumDropdownButtonProps(pageStore, selectedElement, 'cursor', 'auto', {
      toggleProps: {
        type: 'IconText',
        icon: {
          asset: handleCursorIcon(cursor),
          colour: 'Cursor',
        },
        // icon1: {
        //   asset: showCursorDropdown ? 'ChevronUp' : 'ChevronDown',
        // },
      },
      mapDropdownItem: (option, isSelected) => ({
        icon: {
          asset: isSelected ? 'Check' : handleCursorIcon(option),
          colour: isSelected ? 'NeturalHoverSelected' : 'Cursor',
        },
        icon1: {
          asset: handleCursorIcon(option),
          colour: 'Cursor',
        },
        type: 'IconText',
      }),
    }),
  });
};

export default usePresenter;
