import { action, makeAutoObservable, observable, runInAction } from 'mobx';
import { NEW_GROUP_PLACEHOLDER_UUID } from '../../constants';
import {
  createApiGroupEndpointApi, createGroupApi, deleteEndpointApi, deleteGroupApi, getApiGroupsApi, updateApiGroupApi, updateEndpointApi,
} from '../../modules/apiGroups/apiGroups.repository';
import type {
  ApiGroup, ApiGroupEndpoint, CreateApiGroupEndpointPayload, CreateApiGroupPayload, UpdateApiGroupEndpointPayload, UpdateApiGroupPayload,
} from './types';

export class ApiGroupStore {
  public constructor() {
    makeAutoObservable(this);
  }

  @observable
  public isInitialised: boolean | undefined = undefined;

  @action
  public initialise = async (): Promise<void> => {
    // Start initialisation
    this.isInitialised = false;

    // Fetch data from the back end
    const apiGroups = await getApiGroupsApi() || [];
    // Complete initialisation
    runInAction(() => {
      this.isInitialised = true;
      this.apiGroups = apiGroups;
      const firstGroup = apiGroups.length ? apiGroups[0] : undefined;
      this.selectedGroup = firstGroup;
    });
  };

  @observable
  public apiGroups: ApiGroup[] = [];

  @observable
  public selectedGroup: ApiGroup | undefined;

  @observable
  public selectedEndpoint: ApiGroupEndpoint | undefined;

  @action
  public refetchApiGroups = async () => {
    this.apiGroups = await getApiGroupsApi() || [];
  };

  @action
  public setApiGroup = (group: ApiGroup): void => {
    this.apiGroups.unshift(group);
  };

  @action
  public getApiGroups = (): ApiGroup[] => {
    return this.apiGroups;
  };

  @action
  public getApiGroupByUuid = (apiGroupUuid: string): ApiGroup | undefined => {
    return this.apiGroups.find((apiGroup) => apiGroup.uuid === apiGroupUuid);
  };

  @action
  public setSelectedApiGroup = (apiGroupUuid: string, isUpdate?: boolean) => {
    if (!apiGroupUuid) {
      this.selectedGroup = undefined;
      return;
    }
    if (!isUpdate && this.selectedGroup?.uuid === apiGroupUuid) {
      return;
    }

    const currentApiGroup: ApiGroup | undefined = this.getApiGroupByUuid(apiGroupUuid);
    if (currentApiGroup) {
      runInAction(() => {
        this.selectedGroup = currentApiGroup;
      });
    }
  };

  @action
  public removeNewlyAddedApiGroup = (): void => {
    if (this.apiGroups[0]?.uuid === NEW_GROUP_PLACEHOLDER_UUID) {
      this.apiGroups.shift();
    }
  };

  @action
  public createApiGroup = async (createApiGroupPayload: CreateApiGroupPayload): Promise<void> => {
    let createdGroup: ApiGroup;
    try {
      createdGroup = await createGroupApi(createApiGroupPayload);
    } catch {
      return;
    }

    // Sync the front end with the back end
    await this.refetchApiGroups();

    runInAction(() => {
      this.selectedGroup = createdGroup;
    });
  };

  @action
  public updateApiGroup = async (apiGroupUuid: string, payload: UpdateApiGroupPayload): Promise<void> => {
    try {
      await updateApiGroupApi(apiGroupUuid, payload);
    } catch {
      return;
    }

    // Sync the front end with the back end
    await this.refetchApiGroups();

    runInAction(() => {
      this.selectedGroup = this.getApiGroupByUuid(apiGroupUuid);
    });
  };

  @action
  public deleteApiGroup = async (apiGroupUuid: string): Promise<void> => {
    try {
      await deleteGroupApi(apiGroupUuid);
    } catch {
      return;
    }

    // Sync the front end with the back end
    await this.refetchApiGroups();

    runInAction(() => {
      this.selectedGroup = this.apiGroups.length ? this.apiGroups[0] : undefined;
    });
  };

  @action
  public createApiGroupEndpoint = async (apiGroupUuid: string, endpoint: CreateApiGroupEndpointPayload): Promise<void> => {
    let newApiEndpoint: ApiGroupEndpoint; // To use new endpointUuid in setSelectedEndpoint
    try {
      newApiEndpoint = await createApiGroupEndpointApi(endpoint);
    } catch {
      return;
    }

    // Sync the front end with the back end
    await this.refetchApiGroups();

    this.setSelectedApiGroup(apiGroupUuid, true);
    await this.setSelectedEndpoint(apiGroupUuid, newApiEndpoint.uuid);
  };

  @action
  public getEndpointByUuid = (apiEndpointUuid: string): ApiGroupEndpoint | undefined => {
    return this.selectedGroup?.endpoints.find((endpoint) => endpoint.uuid === apiEndpointUuid);
  };

  @action
  public setSelectedEndpoint = async (apiGroupUuid?: string, apiEndpointUuid?: string): Promise<void> => {
    if (!apiEndpointUuid) {
      this.selectedEndpoint = undefined;
      return;
    }
    if (!this.apiGroups) {
      await this.refetchApiGroups();
    }
    if (apiGroupUuid && this.selectedGroup?.uuid !== apiGroupUuid) {
      this.setSelectedApiGroup(apiGroupUuid);
    }
    const currentEndpoint: ApiGroupEndpoint | undefined = this.getEndpointByUuid(apiEndpointUuid);
    if (currentEndpoint) {
      runInAction(() => {
        this.selectedEndpoint = currentEndpoint;
      });
    }
  };

  @action
  public deleteEndpoint = async (apiEndpointUuid: string): Promise<void> => {
    try {
      await deleteEndpointApi(apiEndpointUuid);
    } catch {
      return;
    }

    // Sync the front end with the back end
    await this.refetchApiGroups();

    if (this.selectedGroup) {
      this.setSelectedApiGroup(this.selectedGroup?.uuid, true);
    }
  };

  @action
  public updateEndpoint = async (apiGroupUuid: string, apiEndpointUuid: string, payload: UpdateApiGroupEndpointPayload): Promise<void> => {
    try {
      await updateEndpointApi(apiEndpointUuid, payload);
    } catch {
      return;
    }

    // Sync the front end with the back end
    await this.refetchApiGroups();

    this.setSelectedApiGroup(apiGroupUuid, true);
    await this.setSelectedEndpoint(apiGroupUuid, apiEndpointUuid);
  };
}
