import { TFunction } from 'i18next';
import { Action } from '../../../modules/actions/types';
import { Variable, VariableReferenceType } from '../../../modules/variables/types';
import { ApiGroup } from '../../../store/ApiGroupStore/types';
import { resolveApiCallAction, resolveNavigateToAction } from '../../../store/LogicBuilderStore/utils';
import { getInputPropTextValue } from '../../../store/PageStore/settingsUtils';
import { getPageNavigationVariables, mapPageVariablesToReferences, mapVariableInputsToReferences } from '../../../store/PageStore/variableUtils';
import { IPage, InputPropsMap, VariableInputProp } from '../../../store/types';
import { numberValidation } from '../../../utils/inputValidations';
import { ReplaceRowCombinedProps } from '../../molecules/ReplaceRow';
import { ReplaceRowStateEnum } from '../../molecules/ReplaceRow/types';
import { VariableTypes } from '../InputVariableTable/types';

export const shouldRender = (
  action: Action | undefined,
  apiGroups: ApiGroup[],
  pages: IPage[],
  variables: Variable[],
): boolean => {
  switch (action?.actionType) {
    case 'CallApi': {
      const { endpoint } = resolveApiCallAction(action, apiGroups);
      return (endpoint?.inputVariables?.length || 0) > 0;
    }
    case 'NavigateTo': {
      const { page } = resolveNavigateToAction(action, pages);
      const pageVariables = getPageNavigationVariables(variables, page?.uuid);
      return pageVariables.length > 0;
    }
    default:
      break;
  }
  return false;
};

export const mapInputVariablesProps = (
  action: Action | undefined,
  apiGroups: ApiGroup[],
  pages: IPage[],
  variables: Variable[],
  t: TFunction,
  onVariableSet: (inputVariable: VariableReferenceType, value: string) => void,
  onSetFromVariableClicked: (inputVariable: VariableReferenceType, variableValue?: VariableInputProp) => void,
  onClearVariableClicked: (inputVariable: VariableReferenceType) => void,
): ReplaceRowCombinedProps[] | undefined => {
  let inputVariables: VariableReferenceType[] | undefined;
  let inputVariablesValueMap: InputPropsMap | undefined;

  switch (action?.actionType) {
    case 'CallApi': {
      const { endpoint } = resolveApiCallAction(action, apiGroups);
      inputVariablesValueMap = action.metadata?.variableInputs;
      inputVariables = mapVariableInputsToReferences(endpoint?.inputVariables);
      break;
    }
    case 'NavigateTo': {
      const { page } = resolveNavigateToAction(action, pages);
      const pageVariables = getPageNavigationVariables(variables, page?.uuid);
      inputVariablesValueMap = action.metadata?.variableInputs;
      inputVariables = mapPageVariablesToReferences(pageVariables);
      break;
    }
    default:
      break;
  }

  return inputVariables?.map((variable): ReplaceRowCombinedProps => {
    const variableInput = inputVariablesValueMap ? inputVariablesValueMap[variable.name] : undefined;
    const setFromVariable = variableInput?.source === 'variable';
    const isBoolean = variable.type === 'boolean';
    let newState: ReplaceRowStateEnum = setFromVariable ? 'Uploaded' : 'Editable';
    if (isBoolean) {
      newState = 'Default';
    }
    return {
      type: isBoolean ? 'VariableDropdown' : 'VariableText',
      state: newState,
      style: 'VariableVertical',
      rowLabel: {
        text: {
          value: `${variable.name} | ${variable.type}`,
        },
        button: {
          icon: {
            asset: 'Variable',
            colour: setFromVariable ? 'VariableSelected' : 'NeutralDefault',
          },
          onClick: () => onSetFromVariableClicked(variable, setFromVariable ? variableInput : undefined),
        },
      },
      propertyItem: {
        text: {
          value: setFromVariable ? `{${variableInput?.value?.name}}` : '',
        },
        button: {
          type: 'Icon',
          icon: {
            asset: 'Close',
          },
          onClick: () => onClearVariableClicked(variable),
        },
      },
      inputField: {
        disabled: setFromVariable,
        inputType: variable.type === VariableTypes.NUMBER ? 'number' : 'text',
        onValidateText: variable.type === VariableTypes.NUMBER ? numberValidation : undefined,
        textValue: getInputPropTextValue(variableInput),
        onSubmit: (value) => onVariableSet(variable, value),
        textPlaceholder: t('valuesPanel.placeholder') || '',
      },
      dropdownButton: {
        toggleProps: {
          type: 'Default',
          style: 'Default',
          colour: 'Default',
          text: {
            colour: setFromVariable ? 'VariableSelected' : 'NeutralHoverSelected',
            value: getInputPropTextValue(variableInput) || t('valuesPanel.booleanPlaceholder'),
          },
        },
        menuProps: {
          style: 'Default',
          dropdownItems: [
            {
              text: {
                value: t('valuesPanel.true'),
              },
            },
            {
              text: {
                value: t('valuesPanel.false'),
              },
            },
          ],
          onOptionSelected: (option: string) => onVariableSet(variable, option),
        },
      },
    };
  });
};
