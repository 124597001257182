import { LineNumberProps } from '../../atoms/LineNumber';

type LineNumberListInternalProps = {
  lineNumber?: LineNumberProps;
};

type LineNumberListPropsValues = {
  '': LineNumberListInternalProps;
};

const propValues: LineNumberListPropsValues = {
  '': {
    lineNumber: {
    },
  },
};

const getProps = (type: string): LineNumberListInternalProps => {
  const values: LineNumberListInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
