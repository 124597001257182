import { AlignmentGrid } from '../../../resources/grid';
import { AlignmentType } from '../../../store/types';
import { AlignmentGridCombinedProps } from './types';

const usePresenter = (props: AlignmentGridCombinedProps): AlignmentGridCombinedProps => {
  const { spacing, direction, alignment, onAlignmentClicked } = props;
  // i.e. horizontal-space-around-top-none
  const key = `${direction}-${spacing}-${alignment?.vertical || 'none'}-${alignment?.horizontal || 'none'}`;

  const handleClick = (newAlignment: AlignmentType) => () => {
    if (onAlignmentClicked) {
      onAlignmentClicked(newAlignment);
    }
  };

  return {
    ...props,
    button: {
      image: {
        imageSrc: AlignmentGrid.TopLeft[key] || AlignmentGrid.TopLeft.Default,
      },
      onClick: handleClick({ horizontal: 'left', vertical: 'top' }),
    },
    button1: {
      image: {
        imageSrc: AlignmentGrid.TopCenter[key] || AlignmentGrid.TopCenter.Default,
      },
      onClick: handleClick({ horizontal: 'center', vertical: 'top' }),
    },
    button2: {
      image: {
        imageSrc: AlignmentGrid.TopRight[key] || AlignmentGrid.TopRight.Default,
      },
      onClick: handleClick({ horizontal: 'right', vertical: 'top' }),
    },
    button3: {
      image: {
        imageSrc: AlignmentGrid.CenterLeft[key] || AlignmentGrid.CenterLeft.Default,
      },
      onClick: handleClick({ horizontal: 'left', vertical: 'center' }),
    },
    button4: {
      image: {
        imageSrc: AlignmentGrid.CenterCenter[key] || AlignmentGrid.CenterCenter.Default,
      },
      onClick: handleClick({ horizontal: 'center', vertical: 'center' }),
    },
    button5: {
      image: {
        imageSrc: AlignmentGrid.CenterRight[key] || AlignmentGrid.CenterRight.Default,
      },
      onClick: handleClick({ horizontal: 'right', vertical: 'center' }),
    },
    button6: {
      image: {
        imageSrc: AlignmentGrid.BottomLeft[key] || AlignmentGrid.BottomLeft.Default,
      },
      onClick: handleClick({ horizontal: 'left', vertical: 'bottom' }),
    },
    button7: {
      image: {
        imageSrc: AlignmentGrid.BottomCenter[key] || AlignmentGrid.BottomCenter.Default,
      },
      onClick: handleClick({ horizontal: 'center', vertical: 'bottom' }),
    },
    button8: {
      image: {
        imageSrc: AlignmentGrid.BottomRight[key] || AlignmentGrid.BottomRight.Default,
      },
      onClick: handleClick({ horizontal: 'right', vertical: 'bottom' }),
    },
  };
};

export default usePresenter;
