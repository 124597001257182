import { TabItemProps } from '../../atoms/TabItem';

type TabItemListInternalProps = {
  tabItem?: TabItemProps;
};

type TabItemListPropsValues = {
  '': TabItemListInternalProps;
};

const propValues: TabItemListPropsValues = {
  '': {
    tabItem: {
      state: 'Default',
    },
  },
};

const getProps = (type: string): TabItemListInternalProps => {
  const values: TabItemListInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
