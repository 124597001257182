// MANUAL OVERRIDE STARTS
import React from 'react';
import { observer } from 'mobx-react-lite';
import type { ExportedModalProps } from '../../../modules/common/ModalWrapper';
import InternalSetVariableMenu from './SetVariableMenu';
import type { SetVariableMenuCombinedProps as InternalSetVariableMenuCombinedProps, SetVariableMenuProps, SetVariableMenuValueProps } from './types';
import withModalWrapper from '../../../modules/common/ModalWrapper/withModalWrapper';

export type { SetVariableMenuProps, SetVariableMenuValueProps };
export type SetVariableMenuAndModalCombinedProps = ExportedModalProps<InternalSetVariableMenuCombinedProps>;

const SetVariableMenu = withModalWrapper(observer(InternalSetVariableMenu));
export default SetVariableMenu;
// MANUAL OVERRIDE ENDS
