export default {
  addElementsPanel: {
    addElements: 'Add Elements',
    elements: 'Elements',
  },
  backgroundPanel: {
    background: 'Background',
    image: 'Image',
    addImage: 'Add Image',
    modifyImage: 'Modify Image',
    colour: 'Colour',
  },
  borderPanel: {
    borders: 'Borders',
    weight: 'Weight',
    colour: 'Colour',
  },
  canvasResolutionSpec: {
    scaleUnit: '%',
  },
  cornersPanel: {
    corners: 'Corners',
    radius: 'Radius',
  },
  elementNames: {
    body: 'Body',
    buttonContainer: 'Button',
    buttonText: 'Button Text',
    container: 'Container',
    image: 'Image',
    mainContainer: 'Main',
    text: 'Text',
  },
  elementValues: {
    buttonTextValue: 'Button',
    imageAltText: '',
    textValue: 'This is some text. Double click to edit.',
    fileName: '',
  },
  leftPanel: {
    design: 'Design',
    elements: 'Elements',
    layers: 'Layers',
    pages: 'Pages',
  },
  layoutPanel: {
    layout: 'Layout',
    spacing: 'Spacing',
    packed: 'Packed',
    direction: 'Direction',
    gap: 'Gap',
    columnGap: 'Column Gap',
    rowGap: 'Row Gap',
    wrap: 'Wrap',
    position: 'Position',
    static: 'Static',
    bottom: 'Bottom',
    top: 'Top',
  },
  logicBuilderHeader: {
    logicBuilder: 'Logic Builder',
  },
  logicSettingsHeader: {
    logicSettings: 'Logic Settings',
  },
  logicSettingsPanel: {
    emptyStateInfo: 'Add an action or a condition card on the logic builder canvas to get started.',
    action: 'Action',
    placeholders: {
      selectAction: 'Select an action...',
      selectApiGroup: 'Select an API group...',
      selectApiEndpoint: 'Select an API endpoint...',
    },
    launchTypes: {
      same_tab: 'In the same tab',
      new_tab: 'In a new tab',
    },
    actionTypes: {
      UpdateVariable: 'Update Variable',
      CreateVariable: 'Create Variable',
      CallApi: 'Call API',
      NavigateTo: 'Navigate To',
    },
  },
  logicBuilderPanel: {
    OnClick: 'on click',
    OnLoad: 'on load',
    start: 'Flow start',
  },
  actionCard: {
    action: '[Action]',
    singleLineText: 'Edit in Logic Settings panel ->',
    deleteAction: 'Delete Action',
  },
  paddingMarginsPanel: {
    paddingAndMargins: 'Padding & Margins',
    mar: 'MAR.',
    pad: 'PAD.',
  },
  pageLabelItem: {
    pageSettings: 'Page settings',
    deletePage: 'Delete page',
    renamePage: 'Rename page',
    duplicatePage: 'Duplicate page',
    areYouSureYouWantToDeleteThisPage: 'Are you sure you want to delete this page? This action can create cascading and potentially breaking changes to your application.',
  },
  pages: {
    pageNamePrefix: 'Page',
  },
  pageSettings: {
    pageSettings: 'Page Settings',
    name: 'Name',
    slug: 'Slug',
    path: 'Path',
  },
  pageVariablesPanel: {
    variables: 'Variables',
    addNewVariable: 'Add new variable',
  },
  selectedContainer: {
    selected: 'Selected',
  },
  sizePanel: {
    size: 'Size',
    width: 'Width',
    height: 'Height',
    minMaxValues: 'Min Max Values',
    minW: 'Min W.',
    minH: 'Min H.',
    maxW: 'Max W.',
    maxH: 'Max H.',
    overflow: 'Overflow',
    visible: 'Visible',
  },
  styleEffectsPanel: {
    effectsAndStyles: 'Effects & Styles',
    visible: 'Visible',
    yes: 'Yes',
    no: 'No',
    opacity: 'Opacity',
    shadow: 'Shadow',
    addInnerDropShadow: 'Add Inner/Drop Shadow',
    innerShadow: 'Inner Shadow',
    dropShadow: 'Drop Shadow',
    cursor: 'Cursor',
    auto: 'Auto',
  },
  styleSettingsToggle: {
    style: 'Style',
    settings: 'Settings',
  },
  topNav: {
    autoSaveIsOn: 'Autosave is ON',
    creatingNewPage: 'Creating a new page',
    page: 'Page:',
    publish: 'Publish',
  },
  typographyPanel: {
    typography: 'Typography',
    font: 'Font',
    weight: 'Weight',
    semiBold: 'Semi Bold',
    size: 'Size',
    spacing: 'Spacing',
    colour: 'Colour',
    align: 'Align',
    style: 'Style',
  },
  settingsPanel: {
    boolean: {
      enable: 'Enable',
      disable: 'Disable',
    },
    image: {
      addImage: 'Add Image',
      editImage: 'Edit Image',
    },
    elements: {
      text: {
        displayName: 'Text Value',
      },
      image: {
        displayName: 'Image Value',
      },
      button: {
        displayName: 'State Value',
      },
    },
  },
  units: {
    px: 'px',
    '%': '%',
    vw: 'VW',
    vh: 'VH',
    fill: 'Fill',
    hug: 'Hug',
    none: 'None',
  },
  addImageMenu: {
    addImage: 'Add Image',
    modifyImage: 'Modify Image',
    image: 'Image',
    uploadImage: 'Upload Image',
    size: 'Size',
    position: 'Position',
  },
  ExpandedVariableResponses: {
    header: 'Variables & Responses',
    variables: 'Variables',
    table: {
      scope: 'Scope',
      name: 'Name',
      response: 'Response',
      type: 'Type',
      initialValue: 'Initial Value',
      description: {
        Global: 'A variable that can be used anywhere within application.',
        Page: 'A variable that can be used within one single page.',
      },
    },
    responses: 'Responses',
    responseText: 'Click <span><bold>{+}</bold></span> to create a variable from a response.',
  },
  groupPage: {
    emptyGroupBlock: {
      label: 'Your API groups will appear here.',
      instructions: 'Get started by creating your first API group.',
      buttonText: 'Add a new API group',
    },
    groupsPanel: {
      sectionLabel: 'API Groups',
      buttonText: 'Add a new API group',
      endpointLabel_one: '{{count}} endpoint',
      endpointLabel_other: '{{count}} endpoints',
      emptyGroupNameLabel: '[Group Name]',
    },
    groupContextMenu: {
      headerText: 'Configure Group',
      name: 'Name *',
      baseUrl: 'Base URL *',
      secondaryButtonText: 'Close',
      primaryButtonText: 'Save',
      deleteButtonText: 'Delete',
    },
    groupDeleteMenu: {
      heading: 'Delete Group',
      description: 'Are you sure you want to delete this group? This may cause your application to break. You cannot undo this action.',
      primaryButtonText: 'Delete Group',
      secondaryButtonText: 'Cancel',
    },
    emptyEndpointsBlock: {
      endpointsTabItem: 'Endpoints',
      headersTabItem: 'Headers',
      parametersTabItem: 'Parameters',
      buttonAddApiEndpoint: 'Add API Endpoint',
      buttonSaveHeaders: 'Save Headers',
      buttonSaveParameters: 'Save Parameters',
    },
    groupHeaders: {
      key: 'Key',
      value: 'value',
      addHeaders: 'Add headers',
    },
    groupParameters: {
      addParameters: 'Add parameters',
    },
    endpointCardBlock: {
      empty: {
        label: 'Your API Endpoints will appear here.',
        instructions: 'Get started by creating your first Endpoint.',
        buttonText: 'Add a new API Endpoint',
      },
    },
    keyValueErrorMessage: 'Error: missing fields. Please update/delete fields and save.',
  },
  endpointPage: {
    save: 'Save',
    testResponse: 'Test Response',
    missingFieldsErrorMessage: 'Error: Missing fields. Please update/delete fields and save.',
    inputBlock: {
      title: 'INPUT',
      variable: 'Variable',
      name: 'Name',
      type: 'Type',
      addVariables: 'Add variables',
    },
    functionBlock: {
      baseUrl: 'Base URL:',
    },
    deleteModal: {
      header: 'Delete Endpoint',
      body: 'Are you sure you want to delete this endpoint? This may cause your application to break. You cannot undo this action.',
      deleteEndpoint: 'Delete Endpoint',
      cancel: 'Cancel',
    },
  },
  testResponsePage: {
    saveAndDeploy: 'Save & Deploy',
    testResponse: 'Test Response',
    testVariableBlock: {
      variables: 'VARIABLES',
      emptyStateInfo: 'To create test variables, go to your API Inputs and add a new variable.',
      selectAValue: 'Select a value...',
      name: 'Name',
      type: 'Type',
      value: 'Value',
    },
    responseStructureBlock: {
      responseStructure: 'RESPONSE STRUCTURE',
      runTest: ' Run Test',
      emptyStateInfo: 'Run a test response to get data from your API. Insert test values for your variables to test your API response. Once you’ve received the API responses, deploy your response to make the API available on the front-end.',
    },
    rawDataBlock: {
      rawData: 'Raw Data',
    },
  },
  inputVariableTable: {
    selectType: 'Select type...',
  },
  functionBlock: {
    title: 'FUNCTION',
    label: 'Path',
    baseUrl: 'Base URL:',
    headers: 'Headers',
    parameters: 'Parameters',
    body: 'Body',
    bodyMethods: {
      JSON: 'JSON',
    },
    keyValueTable: {
      keyLabel: 'Key',
      valueLabel: 'Value',
      addHeaders: 'Add Headers',
      addParameters: 'Add Parameters',
    },
    setVariableMenu: {
      header: 'Set from Variable',
      close: 'Close',
      selectVariable: 'Select Variable',
      remove: 'Remove',
      emptyModalText: 'Create variables in the Input section above to see them here.',
    },
  },
  valuesPanel: {
    title: 'Input Values',
    placeholder: 'Type static value or set from variable...',
    booleanPlaceholder: 'Select static value or set from variable...',
    true: 'True',
    false: 'False',
  },
  variableResponsePanel: {
    title: 'Variables & Responses',
    variables: {
      title: 'Variables',
      empty: 'Select the {+} icon on the responses below to create a new variable.',
    },
    responses: {
      title: 'Responses',
      label: 'Click {+} to create a variable from a response.',
      type: '/ {{propType}}',
    },
  },
  unsavedChangesModal: {
    heading: 'Unsaved Changes',
    description: 'You have unsaved changes. If you leave this page without saving, all changes will be lost. Are you sure you want to leave this page?',
    saveButton: 'Save Changes',
    exitWithoutSavingButton: 'Exit without saving',
  },
} as const;
