import type { ApiResponsePropMetadata, ApiResponseSchema, PropType } from '../../../store/ApiGroupStore/types';
import { capitalize } from '../../../utils/stringUtils';
import { buildDropdownButtonProps } from '../../atoms/DropdownButton/utils';
import type { LineNumberValueProps } from '../../atoms/LineNumber';
import type { TestResponseItemCombinedProps, TestResponseItemTypeEnum } from '../../molecules/TestResponseItem/types';
import customStyles from './Custom.module.scss';
import type { LineNumbersAndRawResponseString } from './types';

const defaultPropName = '_item';

const primitiveTypeToPropType: Record<string, PropType> = {
  bigint: 'number',
  boolean: 'boolean',
  number: 'number',
  string: 'string',
};

export const buildApiResponsePropMetadata = (
  propName: string,
  propType: PropType,
  responsePath: string,
  isExpanded: boolean,
  level: number,
  value: unknown | undefined,
): ApiResponsePropMetadata => ({
  propName,
  propType,
  initialPropType: propType,
  responsePath,
  isExpanded,
  level,
  value,
});

export const parseRawResponse = (rawResponse: unknown, _propName?: string, parentResponsePath = '$', level = 0): ApiResponseSchema => {
  const apiResponseSchema: ApiResponseSchema = [];

  const propName: string = _propName || defaultPropName;
  let responsePath = `${parentResponsePath}.${propName}`;

  const rawResponseType = typeof rawResponse;
  switch (rawResponseType) {
    // Primitive types
    case 'bigint':
    case 'boolean':
    case 'number':
    case 'string': {
      apiResponseSchema.push(buildApiResponsePropMetadata(
        propName,
        primitiveTypeToPropType[rawResponseType],
        responsePath,
        false, // Do not expand primitive types
        level,
        rawResponse,
      ));

      break;
    }
    // Objects, collections or nulls
    case 'object': {
      // Nulls
      if (rawResponse === null) {
        apiResponseSchema.push(buildApiResponsePropMetadata(
          propName,
          'ignore',
          responsePath,
          false, // Do not expand nulls
          level,
          rawResponse,
        ));
      } /** Collections */ else if (Array.isArray(rawResponse)) {
        // Empty collections are marked as ignored
        if (!rawResponse.length) {
          apiResponseSchema.push(buildApiResponsePropMetadata(
            propName,
            'ignore',
            responsePath,
            false, // Do not expand empty collections
            level,
            rawResponse,
          ));
        } /** Non-empty collections */ else {
          apiResponseSchema.push(buildApiResponsePropMetadata(
            propName,
            'collection',
            responsePath,
            true, // Expand non-empty collections
            level,
            rawResponse,
          ));

          // For non-empty collections also parse the first item in the collection
          apiResponseSchema.push(...parseRawResponse(rawResponse[0], undefined, responsePath, level + 1));
        }
      } /** Objects */ else {
        const nestedPropNames: string[] = Object.getOwnPropertyNames(rawResponse);
        responsePath = parentResponsePath;
        let objectLevel: number = level;

        // _propName is only set for nested objects within other objects.
        // _propName is only undefined for root level objects (the entire raw response) and for collection items (when we parse collections). In this case do not create an additional entry in API response schema.
        if (_propName) {
          responsePath = `${parentResponsePath}.${_propName}`;

          // Add schema for the object itself
          apiResponseSchema.push(buildApiResponsePropMetadata(
            _propName,
            'object',
            responsePath,
            !!nestedPropNames.length, // Expand objects that contain nested properties
            objectLevel,
            rawResponse,
          ));

          objectLevel++; // Increment level, it will be used for nested properties
        }

        // Add schemas for each nested property
        if (rawResponse !== undefined) {
          // eslint-disable-next-line no-restricted-syntax
          for (const nestedPropName of nestedPropNames) {
            apiResponseSchema.push(...parseRawResponse(rawResponse[nestedPropName], nestedPropName, responsePath, objectLevel));
          }
        }
      }

      break;
    }
    // Everything else is ignored
    case 'function':
    case 'symbol':
    case 'undefined':
    default: {
      break;
    }
  }

  return apiResponseSchema;
};

export const isApiResponsePropertyExpandable = (propType: PropType): boolean => ['object', 'collection'].includes(propType);

export const propTypeOptions: Record<PropType, PropType[]> = {
  string: ['string', 'number', 'boolean', 'ignore'],
  number: ['string', 'number', 'boolean', 'ignore'],
  boolean: ['string', 'number', 'boolean', 'ignore'],
  object: ['object', 'ignore'],
  collection: ['collection', 'ignore'],
  ignore: ['ignore'],
};

export const buildTestResponseItems = (
  apiResponseSchema: ApiResponseSchema | undefined,
  onPropertyExpandedCollapsed: (responsePath: string) => void,
  onPropertyTypeChanged: (responsePath: string, propType: PropType) => void,
  responsePathPrefix = '',
  currentLevel = 0,
): TestResponseItemCombinedProps[] => {
  if (!apiResponseSchema?.length) {
    return [];
  }

  const testResponseItemCombinedProps: TestResponseItemCombinedProps[] = [];

  const filteredApiResponseSchema: ApiResponseSchema = apiResponseSchema.filter(
    ({ responsePath, level }: ApiResponsePropMetadata) => {
      // By default start with the root level properties
      // Otherwise get properties of the immediate children (only 1 level deeper)
      return level === currentLevel &&
        (responsePathPrefix ? responsePath.startsWith(`${responsePathPrefix}.`) : true);
    },
  );

  // eslint-disable-next-line no-restricted-syntax
  for (const filteredApiResponsePropMetadata of filteredApiResponseSchema) {
    const { propName, propType, initialPropType, responsePath, isExpanded, level } = filteredApiResponsePropMetadata;

    // eslint-disable-next-line no-nested-ternary
    const testResponseItemType: TestResponseItemTypeEnum = isApiResponsePropertyExpandable(propType)
      ? (isExpanded ? 'Expanded' : 'Collapsed')
      : 'Default';

    const indentationInRem: `${number}rem` = `${(testResponseItemType === 'Default' ? 2.25 : 0.75) + (level * 1.25)}rem`;

    testResponseItemCombinedProps.push({
      type: testResponseItemType,
      button: {
        type: 'IconClear',
        icon: {
          asset: isExpanded ? 'ChevronDown' : 'ChevronRight',
        },
        onClick: () => onPropertyExpandedCollapsed(responsePath),
      },
      name: { value: propName },
      dropdownButton: buildDropdownButtonProps(
        propType,
        initialPropType,
        propTypeOptions[initialPropType],
        (value: string) => capitalize(value),
        (value: string) => onPropertyTypeChanged(responsePath, value.toLowerCase() as PropType),
      ),
      className: customStyles.testResponseItem,
      classes: {
        name: customStyles.name,
        buttonContent: customStyles.buttonContent,
      },
      customCssOverrides: { paddingLeft: indentationInRem },
    });

    // For all expanded nodes recursively add all nested properties
    if (isExpanded && propType !== 'ignore') {
      testResponseItemCombinedProps.push(...buildTestResponseItems(
        apiResponseSchema,
        onPropertyExpandedCollapsed,
        onPropertyTypeChanged,
        responsePath,
        currentLevel + 1,
      ));
    }
  }

  return testResponseItemCombinedProps;
};

export const buildLineNumbersAndRawResponseString = (rawResponse: Record<string, unknown> | undefined): LineNumbersAndRawResponseString => {
  try {
    const rawResponseString: string = JSON.stringify(rawResponse ?? {}, null, 2);

    const lineNumbers: LineNumberValueProps[] = rawResponseString
      .split('\n')
      .map((_, index) => ({
        text: {
          value: String(index + 1),
        },
      }));

    return {
      lineNumbers,
      rawResponseString,
    };
  } catch {
    return {
      lineNumbers: [],
      rawResponseString: '',
    };
  }
};

export const getPropMetadataByResponsePath = (
  apiResponseSchema: ApiResponseSchema,
  responsePath: string,
): ApiResponsePropMetadata | undefined => {
  return apiResponseSchema.find((apiResponsePropMetadata: ApiResponsePropMetadata) => apiResponsePropMetadata.responsePath === responsePath);
};

export const getPropMetadatasByResponsePathPrefix = (
  apiResponseSchema: ApiResponseSchema,
  responsePathPrefix: string,
): ApiResponseSchema => {
  return apiResponseSchema.filter(({ responsePath }: ApiResponsePropMetadata) => responsePath === responsePathPrefix || responsePath.startsWith(`${responsePathPrefix}.`));
};
