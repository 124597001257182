import React from 'react';
import cx from 'classnames';

import EndpointCard from '../EndpointCard';

import styles from './EndpointCardList.module.scss';
import usePresenter from './EndpointCardList.presenter';
import getProps from './EndpointCardList.props';
import { EndpointCardListCombinedProps } from './types';

const EndpointCardList: React.FC<EndpointCardListCombinedProps> = (props) => {
  const {
    className,
    classes,
    endpointCards,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.endpointCardList;

  const endpointCardViews = endpointCards?.map((endpointCard, index) => (
    <EndpointCard
      key={index}
      className={cx(styles.endpointCard, classes?.endpointCard)}
      {...internalProps.endpointCard}
      {...endpointCard}
    />
  )) || [];

  return (
    <div className={cx(currentStyle, className)}>
      {endpointCardViews}
    </div>
  );
};

export default EndpointCardList;
