import React from 'react';
import cx from 'classnames';

import ToggleItem from '../../atoms/ToggleItem';

import styles from './ToggleItemList.module.scss';
import usePresenter from './ToggleItemList.presenter';
import getProps from './ToggleItemList.props';
import { ToggleItemListCombinedProps } from './types';

const ToggleItemList: React.FC<ToggleItemListCombinedProps> = (props) => {
  const {
    size,
    type,
    style,
    className,
    classes,
    toggleItems, // MANUAL OVERRIDE
  } = usePresenter(props);

  const variantName = `${size}${type}${style}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`toggleItemList${variantName}`];

  let componentView;

  switch (variantName) {
    case 'LargeTextDefault':
    case 'SmallIconDefault':
    case 'SmallIconTextDefault':
    case 'SmallTextDefault': {
      const toggleItemViews = toggleItems?.map((toggleItem, index) => (
        <ToggleItem
          key={index}
          className={cx(styles.toggleItem, classes?.toggleItem)}
          {...internalProps.toggleItem}
          {...toggleItem}
        />
      )) || [];

      componentView = (
        <div className={cx(currentStyle, className)}>
          {toggleItemViews}
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default ToggleItemList;
