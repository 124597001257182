import React from 'react';
import PreviewElementChildrenRenderer from '../../renderers/PreviewElementChildrenRenderer';
import usePresenter from './PreviewButtonContainerElement.presenter';
import type { PreviewButtonContainerElementProps } from './types';

const PreviewButtonContainerElement: React.FC<PreviewButtonContainerElementProps> = (props) => {
  const { element, style, ...otherProps } = usePresenter(props);

  return (
    <button
      style={style}
      {...otherProps}
    >
      <PreviewElementChildrenRenderer parentElement={element} />
    </button>
  );
};

export default PreviewButtonContainerElement;
