import { useElementResizeable } from '../../hooks/useElementResizeable';
import type { ElementResizerProps } from './types';

const usePresenter = ({ element }: ElementResizerProps): ElementResizerProps => {
  const { isResizeable, resizeHandleProps } = useElementResizeable(element);

  return {
    element,
    isResizeable,
    resizeHandleProps,
  };
};

export default usePresenter;
