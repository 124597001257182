import React from 'react';
import cx from 'classnames';

import styles from './ColourPreview.module.scss';
import usePresenter from './ColourPreview.presenter';
import getProps from './ColourPreview.props';
import { ColourPreviewCombinedProps } from './types';

const ColourPreview: React.FC<ColourPreviewCombinedProps> = (props) => {
  const {
    className,
    classes,
    colorCode,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.colourPreview;

  return (
    <div className={cx(currentStyle, className)} style={{ backgroundColor: colorCode }} />
  );
};

export default ColourPreview;
