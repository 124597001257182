declare global {
  interface Window {
    env: Record<string, string>;
  }
}

export const {
  REACT_APP_SENTRY_ENV_FRONTEND: SENTRY_ENV_FRONTEND = window.env?.REACT_APP_SENTRY_ENV_FRONTEND,
  REACT_APP_SENTRY_DSN: SENTRY_DSN = window.env?.REACT_APP_SENTRY_DSN,
  REACT_APP_STUDIO_API_URL: STUDIO_API_URL = window.env?.REACT_APP_STUDIO_API_URL,
} = process.env;

export const SENTRY_TRACES_SAMPLE_RATE =
  Number(process.env?.REACT_APP_SENTRY_TRACES_SAMPLE_RATE) ||
  Number(window.env?.REACT_APP_SENTRY_TRACES_SAMPLE_RATE) ||
  1.0;
