import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { getApiEndpointResponse } from '../../../modules/apiGroups/apiGroups.repository';
import type {
  ApiEndpointResponse, ApiGroupEndpoint, ApiResponsePropMetadata, ApiResponseSchema, PropType,
} from '../../../store/ApiGroupStore/types';
import { StoreContext } from '../../../store/StoreProvider';
import { clone, typedDeepMerge } from '../../../utils/objectUtils';
import { buildInputVariablesTestResponse } from '../../molecules/TableRowList/utils';
import type { VariableInput } from '../../organisms/InputVariableTable/types';
import customStyles from './Custom.module.scss';
import type { LineNumbersAndRawResponseString, TestResponsePageCombinedProps } from './types';
import {
  buildLineNumbersAndRawResponseString, buildTestResponseItems, getPropMetadataByResponsePath,
  getPropMetadatasByResponsePathPrefix, isApiResponsePropertyExpandable, parseRawResponse,
} from './utils';

const usePresenter = (props: TestResponsePageCombinedProps): TestResponsePageCombinedProps => {
  const { t } = useTranslation();
  const { groupUuid, endpointUuid } = useParams();
  const { apiGroupStore } = useContext(StoreContext);
  const { selectedGroup, setSelectedEndpoint, selectedEndpoint: originalEndpoint } = apiGroupStore;
  const [modifiedEndpoint, setModifiedEndpoint] = useState<ApiGroupEndpoint | undefined>(() => {
    return originalEndpoint ? clone(originalEndpoint) : undefined;
  });

  useEffect(() => {
    void setSelectedEndpoint(groupUuid, endpointUuid);
    return () => {
      void setSelectedEndpoint();
    };
  }, [setSelectedEndpoint, groupUuid, endpointUuid]);

  useEffect(() => {
    setModifiedEndpoint(originalEndpoint ? clone(originalEndpoint) : undefined);
  }, [originalEndpoint]);

  const onInputVariablesChanged = (inputVariables: VariableInput[]) => {
    if (modifiedEndpoint) {
      setModifiedEndpoint({ ...modifiedEndpoint, inputVariables });
    }
  };

  const onTestResponseClicked = async () => {
    if (selectedGroup && modifiedEndpoint) {
      const apiEndpointResponse: ApiEndpointResponse = await getApiEndpointResponse(selectedGroup, modifiedEndpoint, modifiedEndpoint.inputVariables);
      setModifiedEndpoint({
        ...modifiedEndpoint,
        responseStatus: apiEndpointResponse.responseStatus,
        responseStatusText: apiEndpointResponse.responseStatusText,
        responseSchema: parseRawResponse(apiEndpointResponse.rawResponse),
        rawResponse: apiEndpointResponse.rawResponse,
      });
    }
  };

  const onTestResponsePropertyExpandedCollapsed = (responsePath: string) => {
    if (modifiedEndpoint?.responseSchema) {
      const { responseSchema } = modifiedEndpoint;
      const apiResponsePropMetadata: ApiResponsePropMetadata | undefined = getPropMetadataByResponsePath(responseSchema, responsePath);
      if (apiResponsePropMetadata) {
        if (apiResponsePropMetadata.isExpanded) {
          // If property is expanded then collapse it
          apiResponsePropMetadata.isExpanded = false;
        } else {
          // If property is collapsed then expand the property itself and all of its expandable descendants
          const apiResponseSchemaToExpand: ApiResponseSchema = getPropMetadatasByResponsePathPrefix(responseSchema, responsePath)
            .filter(({ propType, isExpanded }: ApiResponsePropMetadata) => isApiResponsePropertyExpandable(propType) && !isExpanded);

          // eslint-disable-next-line no-restricted-syntax
          for (const apiResponsePropMetadataToExpand of apiResponseSchemaToExpand) {
            apiResponsePropMetadataToExpand.isExpanded = true;
          }
        }

        setModifiedEndpoint({
          ...modifiedEndpoint,
          responseSchema: [...modifiedEndpoint.responseSchema],
        });
      }
    }
  };

  const onTestResponsePropertyTypeChanged = (responsePath: string, newPropType: PropType) => {
    if (modifiedEndpoint?.responseSchema) {
      const { responseSchema } = modifiedEndpoint;
      const apiResponsePropMetadata: ApiResponsePropMetadata | undefined = getPropMetadataByResponsePath(responseSchema, responsePath);
      if (apiResponsePropMetadata) {
        apiResponsePropMetadata.propType = newPropType;
        setModifiedEndpoint({
          ...modifiedEndpoint,
          responseSchema: [...modifiedEndpoint.responseSchema],
        });
      }
    }
  };

  const [isRawDataBlockExpanded, setIsRawDataBlockExpanded] = useState<boolean>(false);

  useEffect(() => {
    setIsRawDataBlockExpanded(!!modifiedEndpoint?.rawResponse);
  }, [modifiedEndpoint?.rawResponse]);

  const lineNumbersAndRawResponseString: LineNumbersAndRawResponseString = useMemo(() => {
    return buildLineNumbersAndRawResponseString(modifiedEndpoint?.rawResponse);
  }, [modifiedEndpoint?.rawResponse]);

  const isSaveDeployEnabled: boolean = useMemo<boolean>(() => {
    if (!originalEndpoint || !modifiedEndpoint) {
      return false;
    }

    try {
      return originalEndpoint.responseStatus !== modifiedEndpoint.responseStatus ||
        originalEndpoint.responseStatusText !== modifiedEndpoint.responseStatusText ||
        JSON.stringify(originalEndpoint.responseSchema) !== JSON.stringify(modifiedEndpoint.responseSchema) ||
        JSON.stringify(originalEndpoint.rawResponse) !== JSON.stringify(modifiedEndpoint.rawResponse);
    } catch {
      return false;
    }
  }, [originalEndpoint, modifiedEndpoint]);

  const onSaveDeployClicked = async () => {
    if (modifiedEndpoint) {
      const { inputVariables, responseStatus, responseStatusText, responseSchema, rawResponse } = modifiedEndpoint;
      await apiGroupStore.updateEndpoint(groupUuid || '', modifiedEndpoint.uuid, {
        inputVariables,
        responseStatus,
        responseStatusText,
        responseSchema,
        rawResponse,
      });
    }
  };

  return typedDeepMerge(props, {
    breadcrumbItemList: {
      breadcrumbItems: [{
        state: 'Default',
        text: {
          value: selectedGroup?.name,
        },
        icon: {
          asset: 'ChevronRight',
        },
      },
      {
        state: 'Default',
        text: {
          value: modifiedEndpoint?.name,
        },
        icon: {
          asset: 'ChevronRight',
        },
      },
      {
        state: 'Selected',
        text: {
          value: t('testResponsePage.testResponse'),
        },
      }],
    },
    button: {
      disabled: !isSaveDeployEnabled,
      text: {
        value: t('testResponsePage.saveAndDeploy'),
      },
      onClick: onSaveDeployClicked,
    },
    textInput: {
      textValue: 'Test Response',
      disabled: true,
    },
    testVariableBlock: {
      text: {
        value: t('testResponsePage.testVariableBlock.variables'),
      },
      state: modifiedEndpoint?.inputVariables ? 'Filled' : 'Empty',
      emptyStateInfo: {
        text: {
          value: t('testResponsePage.testVariableBlock.emptyStateInfo'),
        },
      },
      keyValueTableFilledTextTextValue: {
        name: {
          value: t('testResponsePage.testVariableBlock.name'),
        },
        type: {
          value: t('testResponsePage.testVariableBlock.type'),
        },
        value: {
          value: t('testResponsePage.testVariableBlock.value'),
        },
        tableRowList: buildInputVariablesTestResponse(
          t,
          modifiedEndpoint?.inputVariables ?? [],
          onInputVariablesChanged,
        ),
      },
    },
    responseStructureBlock: {
      state: modifiedEndpoint?.responseStatus ? 'Filled' : 'Empty',
      classes: {
        testResponseContainer: customStyles.testResponseContainer,
        testResponseItemList: customStyles.testResponseItemList,
      },
      text: {
        value: t('testResponsePage.responseStructureBlock.responseStructure'),
      },
      button: {
        text: {
          value: t('testResponsePage.responseStructureBlock.runTest'),
        },
        onClick: onTestResponseClicked,
      },
      emptyStateInfo: {
        text: {
          value: t('testResponsePage.responseStructureBlock.emptyStateInfo'),
        },
      },
      statusChip: {
        text: {
          value: modifiedEndpoint ? `${modifiedEndpoint.responseStatus} ${modifiedEndpoint.responseStatusText}` : '',
        },
      },
      testResponseItemList: {
        testResponseItems: buildTestResponseItems(
          modifiedEndpoint?.responseSchema,
          onTestResponsePropertyExpandedCollapsed,
          onTestResponsePropertyTypeChanged,
        ),
      },
    },
    testVariableBlock1: {
      text: {
        value: t('testResponsePage.rawDataBlock.rawData'),
      },
      state: isRawDataBlockExpanded ? 'Expanded' : 'Collapsed',
      button: {
        type: 'IconClear',
        icon: {
          asset: isRawDataBlockExpanded ? 'ChevronDown' : 'ChevronRight',
          colour: 'NeutralDefault',
        },
        onClick: () => setIsRawDataBlockExpanded(!isRawDataBlockExpanded),
      },
      codeSpace: {
        inputField: {
          disabled: true,
          textValue: lineNumbersAndRawResponseString.rawResponseString,
        },
        lineNumberList: {
          lineNumbers: lineNumbersAndRawResponseString.lineNumbers,
        },
      },
    },
  });
};

export default usePresenter;
