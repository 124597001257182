import { useTranslation } from 'react-i18next';
import { typedDeepMerge } from '../../../utils/objectUtils';
import type { LogicBuilderHeaderCombinedProps } from './types';

const usePresenter = (props: LogicBuilderHeaderCombinedProps): LogicBuilderHeaderCombinedProps => {
  const { t } = useTranslation();

  return typedDeepMerge(props, {
    text: {
      value: t('logicBuilderHeader.logicBuilder'),
    },
  });
};

export default usePresenter;
