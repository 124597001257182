import React from 'react';
import { observer } from 'mobx-react-lite';
import ElementCornerSquares from '../ElementCornerSquares';
import ElementResizer from '../ElementResizer';
import styles from './CanvasElement.module.scss';
import usePresenter from './CanvasElement.presenter';
import { CanvasElementProps, ElementViewProps } from './types';
import { Element, IStyles } from '../../../../store/types';
import ElementChildrenRenderer from '../../renderers/ElementChildrenRenderer';

const asCanvasElement = <ElementType extends Element>(
  ElementView: React.FC<ElementViewProps<ElementType>>,
  mapPristineOverrides?: (element: Element) => IStyles,
): React.FC<CanvasElementProps<ElementType>> => {
  const CanvasElement: React.FC<CanvasElementProps<ElementType>> = (props) => {
    const {
      elementRef,
      style,
      element,
      elementStyle,
      isElementEdited,
      isElementSelected,
      ...otherProps
    } = usePresenter({ ...props, mapPristineOverrides });

    return (
      <div
        className={styles.canvasElement}
        style={style}
        {...otherProps}
      >
        <ElementView
          elementRef={elementRef}
          element={element}
          style={elementStyle}
          isElementEdited={isElementEdited}>
          <ElementChildrenRenderer
            parentId={element.id}
            parentRef={elementRef}
            childIds={element.childIds} />
        </ElementView>
        <ElementResizer element={element} />
        {isElementSelected && <ElementCornerSquares />}
      </div>
    );
  };
  // return CanvasElement HOC
  return observer(CanvasElement);
};

export default asCanvasElement;
