import { TopNavProps } from '../../organisms/TopNav';
import { EndpointPageProps } from '../../pages/EndpointPage';
import { GroupPageProps } from '../../pages/GroupPage';
import { UIBuilderPageProps } from '../../pages/UIBuilderPage';

type MainLayoutInternalProps = {
  topNav?: TopNavProps;
  uIBuilderPage?: UIBuilderPageProps;
  groupPage?: GroupPageProps;
  selectedApiGroupPage?: GroupPageProps;
  endPointPage?: EndpointPageProps;
};

type MainLayoutPropsValues = {
  '': MainLayoutInternalProps;
};

const propValues: MainLayoutPropsValues = {
  '': {
    topNav: {
      style: 'Default',
    },
    uIBuilderPage: {
    },
    groupPage: {
      state: 'Empty',
      type: 'Groups',
    },
    selectedApiGroupPage: {
      state: 'Default',
      type: 'Endpoints',
    },
    endPointPage: {
      state: 'Default',
    },
  },
};

const getProps = (type: string): MainLayoutInternalProps => {
  const values: MainLayoutInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
