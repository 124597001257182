import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { StoreContext } from '../../../store/StoreProvider';
import { typedDeepMerge } from '../../../utils/objectUtils';
import type { CanvasResolutionSpecCombinedProps } from './types';

const usePresenter = (props: CanvasResolutionSpecCombinedProps): CanvasResolutionSpecCombinedProps => {
  const { appStore, breakpointStore } = useContext(StoreContext);
  const { t } = useTranslation();

  return typedDeepMerge(props, {
    breakpointScreenSize: {
      lightText: {
        value: breakpointStore.currentBreakpoint.width,
      },
      darkText: {
        value: breakpointStore.breakpointUnit,
      },
    },
    scale: {
      lightText: {
        value: appStore.canvasScalePercentage,
      },
      darkText: {
        value: t('canvasResolutionSpec.scaleUnit'),
      },
    },
  });
};

export default usePresenter;
