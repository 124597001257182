import React from 'react';
import { BaseModalProps, ExportedModalProps } from '../types';
import { createNewModalContext, ModalContextValue, ModalWrapperProvider } from './ModalContext';

export default function withModal<ViewProps extends object, ModalPropsType extends BaseModalProps>(
  View: React.FC<ViewProps>,
  Modal: React.FC<ExportedModalProps<ModalPropsType>>,
): [React.FC<ViewProps>, React.Context<ModalContextValue<ModalPropsType>>] {
  const TypeModalWrapperProvider = ModalWrapperProvider<ModalPropsType>;
  const ModalContext = createNewModalContext<ModalPropsType>();
  const ViewWrapper: React.FC<ViewProps> = (props) => {
    return (
      <TypeModalWrapperProvider ModalContext={ModalContext}>
        { (modalProps) => (
          <>
            <View {...props} />
            <Modal {...modalProps} />
          </>
        )}
      </TypeModalWrapperProvider>
    );
  };
  return [ViewWrapper, ModalContext];
}
