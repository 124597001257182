import cx from 'classnames';
import { RowLabelCombinedProps } from './types';
import customStyles from './Custom.module.scss';

const usePresenter = (props: RowLabelCombinedProps): RowLabelCombinedProps => {
  return {
    ...props,
    className: cx(props.className, customStyles.rowLabel),
    classes: {
      text: customStyles.text,
    },
  };
};

export default usePresenter;
