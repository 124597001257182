type DividerInternalProps = {
};

type DividerPropsValues = {
  'HorizontalNeutral1900': DividerInternalProps;
  'HorizontalNeutralBG2': DividerInternalProps;
  'HorizontalNeutralBG3': DividerInternalProps;
  'VerticalNeutral1900': DividerInternalProps;
};

const propValues: DividerPropsValues = {
  'HorizontalNeutral1900': {
  },
  'HorizontalNeutralBG2': {
  },
  'HorizontalNeutralBG3': {
  },
  'VerticalNeutral1900': {
  },
};

const getProps = (type: string): DividerInternalProps => {
  const values: DividerInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
