import { ButtonProps } from '../../atoms/Button';
import { DividerProps } from '../../atoms/Divider';
import { IconProps } from '../../atoms/Icon';
import { TextProps } from '../../atoms/Text';

type VariableCardInternalProps = {
  variableName?: TextProps;
  text?: TextProps;
  divider?: DividerProps;
  scopeIcon?: IconProps;
  editIcon?: ButtonProps; // MANAUL OVERRIDE
};

type VariableCardPropsValues = {
  'LargeDefault': VariableCardInternalProps;
  'SmallGlobal': VariableCardInternalProps;
  'SmallPage': VariableCardInternalProps;
  'SmallUnselected': VariableCardInternalProps;
};

const propValues: VariableCardPropsValues = {
  LargeDefault: {
    variableName: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Medium500',
      colour: 'VariableSelected',
    },
    text: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Italic400',
      colour: 'NeutralDefault',
    },
  },
  SmallGlobal: {
    divider: {
      type: 'Horizontal',
      colour: 'NeutralBG2',
    },
    scopeIcon: {
      asset: 'Global',
      colour: 'VariableSelected',
    },
    variableName: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Medium500',
      colour: 'VariableSelected',
    },
  },
  SmallPage: {
    divider: {
      type: 'Horizontal',
      colour: 'NeutralBG2',
    },
    scopeIcon: {
      asset: 'Page',
      colour: 'VariableSelected',
    },
    variableName: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Medium500',
      colour: 'VariableSelected',
    },
  },
  SmallUnselected: {
    divider: {
      type: 'Horizontal',
      colour: 'NeutralBG2',
    },
    scopeIcon: {
      asset: 'Variable',
      colour: 'VariableSelected',
    },
    variableName: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Medium500',
      colour: 'VariableSelected',
    },
  },
};

const getProps = (type: string): VariableCardInternalProps => {
  const values: VariableCardInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
