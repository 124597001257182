import { DropdownButtonProps } from '../../atoms/DropdownButton';
import { RowLabelProps } from '../../atoms/RowLabel';
import { IconProps } from '../../atoms/Icon';
import { InputFieldProps } from '../../atoms/InputField';
import { ColourRowProps } from '../../molecules/ColourRow';
import { ToggleItemListProps } from '../../molecules/ToggleItemList';
import { PanelHeaderProps } from '../../molecules/PanelHeader';

type TypographyPanelInternalProps = {
  panelHeader?: PanelHeaderProps;
  font?: RowLabelProps;
  dropdownButton?: DropdownButtonProps;
  weight?: RowLabelProps;
  weightDropdown?: DropdownButtonProps;
  size?: RowLabelProps;
  sizeField?: InputFieldProps;
  spacing?: RowLabelProps;
  letterSpace?: IconProps;
  letterSpaceField?: InputFieldProps;
  lineHeight?: IconProps;
  lineHeightField?: InputFieldProps;
  colourRow?: ColourRowProps;
  style?: RowLabelProps;
  lineToggle?: ToggleItemListProps;
  style1?: RowLabelProps;
  italicsToggle?: ToggleItemListProps;
  lineToggle1?: ToggleItemListProps;
};

type TypographyPanelPropsValues = {
  'Collapsed': TypographyPanelInternalProps;
  'Expanded': TypographyPanelInternalProps;
};

const propValues: TypographyPanelPropsValues = {
  Collapsed: {
    panelHeader: {
      state: 'Collapsed',
      type: 'Default',
    },
  },
  Expanded: {
    panelHeader: {
      state: 'Expanded',
      type: 'Default',
    },
    font: {
      state: 'Default',
      style: 'Default',
      type: 'Panel',
    },
    dropdownButton: {
      type: 'Default',
      style: 'Default',
      state: 'Collapsed',
    },
    weight: {
      state: 'Default',
      style: 'Default',
      type: 'Panel',
    },
    weightDropdown: {
      type: 'Default',
      style: 'Default',
      state: 'Collapsed',
    },
    size: {
      state: 'Default',
      style: 'Default',
      type: 'Panel',
    },
    sizeField: {
      type: 'TextUnit',
      size: 'Medium',
      state: 'Default',
    },
    spacing: {
      state: 'Default',
      style: 'Default',
      type: 'Panel',
    },
    letterSpace: {
      asset: 'LetterSpace',
      colour: 'NeturalHoverSelected',
    },
    letterSpaceField: {
      type: 'TextUnit',
      size: 'Medium',
      state: 'Default',
    },
    lineHeight: {
      asset: 'LineHeight',
      colour: 'NeturalHoverSelected',
    },
    lineHeightField: {
      type: 'TextUnit',
      size: 'Medium',
      state: 'Default',
    },
    colourRow: {
    },
    style: {
      state: 'Default',
      style: 'Default',
      type: 'Panel',
    },
    lineToggle: {
      size: 'Small',
      type: 'Icon',
      style: 'Default',
    },
    style1: {
      state: 'Default',
      style: 'Default',
      type: 'Panel',
    },
    italicsToggle: {
      size: 'Small',
      type: 'Icon',
      style: 'Default',
    },
    lineToggle1: {
      size: 'Small',
      type: 'Icon',
      style: 'Default',
    },
  },
};

const getProps = (type: string): TypographyPanelInternalProps => {
  const values: TypographyPanelInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
