import React from 'react';
import cx from 'classnames';

import Button from '../../atoms/Button';
import Divider from '../../atoms/Divider';
import CanvasResolutionSpec from '../CanvasResolutionSpec';

import styles from './BreakpointMenu.module.scss';
import usePresenter from './BreakpointMenu.presenter';
import getProps from './BreakpointMenu.props';
import { BreakpointMenuCombinedProps } from './types';

const BreakpointMenu: React.FC<BreakpointMenuCombinedProps> = (props) => {
  const {
    className,
    classes,
    desktopButton,
    divider,
    tabletButton,
    divider1,
    mobileButton,
    divider2,
    canvasResolutionSpec,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.breakpointMenu;

  return (
    <div className={cx(currentStyle, className)}>
      <div className={cx(styles.buttonContent, classes?.buttonContent)}>
        <Button
          className={cx(styles.desktopButton, classes?.desktopButton)}
          {...internalProps.desktopButton}
          {...desktopButton}
        />
        <Divider
          className={cx(styles.divider, classes?.divider)}
          {...internalProps.divider}
          {...divider}
        />
        <Button
          className={cx(styles.tabletButton, classes?.tabletButton)}
          {...internalProps.tabletButton}
          {...tabletButton}
        />
        <Divider
          className={cx(styles.divider1, classes?.divider1)}
          {...internalProps.divider1}
          {...divider1}
        />
        <Button
          className={cx(styles.mobileButton, classes?.mobileButton)}
          {...internalProps.mobileButton}
          {...mobileButton}
        />
        <Divider
          className={cx(styles.divider2, classes?.divider2)}
          {...internalProps.divider2}
          {...divider2}
        />
      </div>
      <CanvasResolutionSpec
        className={cx(styles.canvasResolutionSpec, classes?.canvasResolutionSpec)}
        {...internalProps.canvasResolutionSpec}
        {...canvasResolutionSpec}
      />
    </div>
  );
};

export default BreakpointMenu;
