import { useIsLeftPanelType } from '../../../hooks';
import { typedDeepMerge } from '../../../utils/objectUtils';
import styles from './PageSettingsPanel.module.scss';
import type { PageSettingsPanelCombinedProps } from './types';

const usePresenter = (props: PageSettingsPanelCombinedProps): PageSettingsPanelCombinedProps => {
  const isPageSettingsPanelOpen: boolean = useIsLeftPanelType('PageSettings');

  return typedDeepMerge(props, {
    // When this panel is closed, it remains mounted but invisible to the user
    // So that the panel state could be preserved, e.g. scroll positions, etc.
    className: !isPageSettingsPanelOpen ? styles.pageSettingsPanelHidden : undefined,
  });
};

export default usePresenter;
