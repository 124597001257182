// MANUAL OVERRIDE STARTS
import { observer } from 'mobx-react-lite';
import { type ExportedModalProps } from '../../../modules/common/ModalWrapper';
import InternalAddImageMenu from './AddImageMenu';
import type { AddImageMenuCombinedProps as InternalAddImageMenuCombinedProps, AddImageMenuProps } from './types';
import withModalWrapper from '../../../modules/common/ModalWrapper/withModalWrapper';

export type { AddImageMenuProps };
export type AddImageMenuAndModalCombinedProps = ExportedModalProps<InternalAddImageMenuCombinedProps>;

const AddImageMenu = withModalWrapper(observer(InternalAddImageMenu));
export default AddImageMenu;
// MANUAL OVERRIDE ENDS
