import { TableRowProps } from '../TableRow';

type TableRowListInternalProps = {
  tableRow?: TableRowProps;
};

type TableRowListPropsValues = {
  'EditVariablesDefault': TableRowListInternalProps;
  'InputVariableDefault': TableRowListInternalProps;
  'KeyValueDefault': TableRowListInternalProps;
  'KeyValueVariable': TableRowListInternalProps;
  'TestVariableDefault': TableRowListInternalProps;
};

const propValues: TableRowListPropsValues = {
  EditVariablesDefault: {
    tableRow: {
      type: 'Default',
      style: 'EditVariables',
      state: 'Default',
    },
  },
  InputVariableDefault: {
    tableRow: {
      type: 'Default',
      style: 'InputVariable',
      state: 'Default',
    },
  },
  KeyValueDefault: {
    tableRow: {
      type: 'Default',
      style: 'KeyValue',
      state: 'Default',
    },
  },
  KeyValueVariable: {
    tableRow: {
      type: 'Variable',
      style: 'KeyValue',
      state: 'Default',
    },
  },
  TestVariableDefault: {
    tableRow: {
      type: 'Default',
      style: 'TestResponse',
      state: 'Default',
    },
  },
};

const getProps = (type: string): TableRowListInternalProps => {
  const values: TableRowListInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
