import { ButtonProps } from '../../atoms/Button';
import { DividerProps } from '../../atoms/Divider';
import { DropdownButtonProps } from '../../atoms/DropdownButton';
import { InputFieldProps } from '../../atoms/InputField';
import { LabelProps } from '../../atoms/Label';
import { AccordionHeaderProps } from '../../molecules/AccordionHeader';
import { MenuHeaderProps } from '../../molecules/MenuHeader';
import { ToggleItemListProps } from '../../molecules/ToggleItemList';

type ConfigureNavigationVariableMenuInternalProps = {
  menuHeader?: MenuHeaderProps;
  divider?: DividerProps;
  label?: LabelProps;
  inputField?: InputFieldProps;
  label1?: LabelProps;
  dropdownButton?: DropdownButtonProps;
  accordionHeader?: AccordionHeaderProps;
  button?: ButtonProps;
  button1?: ButtonProps;
  button2?: ButtonProps;
  label2?: LabelProps;
  toggleItemList?: ToggleItemListProps;
  label3?: LabelProps;
  inputField1?: InputFieldProps;
};

type ConfigureNavigationVariableMenuPropsValues = {
  'CollapsedDefault': ConfigureNavigationVariableMenuInternalProps;
  'CollapsedRemoveable': ConfigureNavigationVariableMenuInternalProps;
  'ExpandedDefault': ConfigureNavigationVariableMenuInternalProps;
  'ExpandedRemoveable': ConfigureNavigationVariableMenuInternalProps;
};

const propValues: ConfigureNavigationVariableMenuPropsValues = {
  'CollapsedDefault': {
    menuHeader: {
      type: 'Variable',
      size: 'M',
    },
    divider: {
      type: 'Horizontal',
      colour: 'Neutral1900',
    },
    label: {
      state: 'Required',
    },
    inputField: {
      type: 'Text',
      size: 'Medium',
      state: 'Default',
    },
    label1: {
      state: 'Required',
    },
    dropdownButton: {
      type: 'Default',
      style: 'Default',
      state: 'Collapsed',
      colour: 'Default',
    },
    accordionHeader: {
      state: 'Collapsed',
    },
    button: {
      type: 'Text',
      size: 'M',
      state: 'Default',
      style: 'Secondary',
    },
    button1: {
      type: 'Text',
      size: 'M',
      state: 'Default',
      style: 'Primary',
    },
  },
  'CollapsedRemoveable': {
    menuHeader: {
      type: 'Variable',
      size: 'M',
    },
    divider: {
      type: 'Horizontal',
      colour: 'Neutral1900',
    },
    label: {
      state: 'Required',
    },
    inputField: {
      type: 'Text',
      size: 'Medium',
      state: 'Default',
    },
    label1: {
      state: 'Required',
    },
    dropdownButton: {
      type: 'Default',
      style: 'Default',
      state: 'Collapsed',
      colour: 'Default',
    },
    accordionHeader: {
      state: 'Collapsed',
    },
    button: {
      type: 'Text',
      size: 'M',
      state: 'Default',
      style: 'Destructive',
    },
    button1: {
      type: 'Text',
      size: 'M',
      state: 'Default',
      style: 'Secondary',
    },
    button2: {
      type: 'Text',
      size: 'M',
      state: 'Default',
      style: 'Primary',
    },
  },
  'ExpandedDefault': {
    menuHeader: {
      type: 'Variable',
      size: 'M',
    },
    divider: {
      type: 'Horizontal',
      colour: 'Neutral1900',
    },
    label: {
      state: 'Required',
    },
    inputField: {
      type: 'Text',
      size: 'Medium',
      state: 'Default',
    },
    label1: {
      state: 'Required',
    },
    dropdownButton: {
      type: 'Default',
      style: 'Default',
      state: 'Collapsed',
      colour: 'Default',
    },
    accordionHeader: {
      state: 'Expanded',
    },
    label2: {
      state: 'Default',
    },
    toggleItemList: {
      size: 'Small',
      type: 'Text',
      style: 'Default',
    },
    label3: {
      state: 'Default',
    },
    inputField1: {
      type: 'Text',
      size: 'Medium',
      state: 'Default',
    },
    button: {
      type: 'Text',
      size: 'M',
      state: 'Default',
      style: 'Secondary',
    },
    button1: {
      type: 'Text',
      size: 'M',
      state: 'Default',
      style: 'Primary',
    },
  },
  'ExpandedRemoveable': {
    menuHeader: {
      type: 'Variable',
      size: 'M',
    },
    divider: {
      type: 'Horizontal',
      colour: 'Neutral1900',
    },
    label: {
      state: 'Required',
    },
    inputField: {
      type: 'Text',
      size: 'Medium',
      state: 'Default',
    },
    label1: {
      state: 'Required',
    },
    dropdownButton: {
      type: 'Default',
      style: 'Default',
      state: 'Collapsed',
      colour: 'Default',
    },
    accordionHeader: {
      state: 'Expanded',
    },
    label2: {
      state: 'Default',
    },
    toggleItemList: {
      size: 'Small',
      type: 'Text',
      style: 'Default',
    },
    label3: {
      state: 'Default',
    },
    inputField1: {
      type: 'Text',
      size: 'Medium',
      state: 'Default',
    },
    button: {
      type: 'Text',
      size: 'M',
      state: 'Default',
      style: 'Destructive',
    },
    button1: {
      type: 'Text',
      size: 'M',
      state: 'Default',
      style: 'Secondary',
    },
    button2: {
      type: 'Text',
      size: 'M',
      state: 'Default',
      style: 'Primary',
    },
  },
};

const getProps = (type: string): ConfigureNavigationVariableMenuInternalProps => {
  const values: ConfigureNavigationVariableMenuInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
