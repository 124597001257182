import { useTranslation } from 'react-i18next';
import { ResponseItemCombinedProps } from '../ResponseItem/types';
import { ResponseItemListCombinedProps } from './types';

const usePresenter = (props: ResponseItemListCombinedProps): ResponseItemListCombinedProps => {
  const { apiResponseItems, responseItemType, handleCreateNewVariable, checkIfVariableCreated } = props;

  const { t } = useTranslation();

  const responseItems = apiResponseItems?.map((response): ResponseItemCombinedProps => {
    const { propName, propType } = response;
    const alreadyCreated = checkIfVariableCreated && checkIfVariableCreated(response);
    return {
      name: {
        value: propName,
      },
      responseType: {
        value: t('variableResponsePanel.responses.type', { propType }),
      },
      state: 'Default',
      style: alreadyCreated ? 'Added' : 'Add',
      type: responseItemType,
      rowHoverCap: {
        button: {
          icon: {
            asset: alreadyCreated ? 'CheckmarkCircle' : 'AddVariable',
          },
          onClick: () => handleCreateNewVariable?.(response),
        },
      },
      button: {
        icon: {
          asset: 'AddVariable',
        },
        onClick: () => handleCreateNewVariable?.(response),
      },
    };
  });
  return {
    ...props,
    responseItems,
  };
};

export default usePresenter;
