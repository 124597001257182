import { useTranslation } from 'react-i18next';
import { typedDeepMerge } from '../../../utils/objectUtils';
import type { LogicSettingsHeaderCombinedProps } from './types';

const usePresenter = (props: LogicSettingsHeaderCombinedProps): LogicSettingsHeaderCombinedProps => {
  const { t } = useTranslation();

  return typedDeepMerge(props, {
    text: {
      value: t('logicSettingsHeader.logicSettings'),
    },
  });
};

export default usePresenter;
