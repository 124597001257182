import { ButtonProps } from '../Button';
import { PropertyItemProps } from '../../molecules/PropertyItem';

type ReplaceButtonInternalProps = {
  button?: ButtonProps;
  propertyItem?: PropertyItemProps;
};

type ReplaceButtonPropsValues = {
  'DefaultDefault': ReplaceButtonInternalProps;
  'DefaultTextOnly': ReplaceButtonInternalProps;
  'UploadedImage': ReplaceButtonInternalProps;
  'UploadedUpdateVariable': ReplaceButtonInternalProps;
  'UploadedVariableImage': ReplaceButtonInternalProps;
};

const propValues: ReplaceButtonPropsValues = {
  'DefaultDefault': {
    button: {
      type: 'IconText',
      size: 'M',
      state: 'Default',
      style: 'PrimarySubdued',
    },
  },
  'DefaultTextOnly': {
    button: {
      type: 'TextOnly',
      size: 'M',
      state: 'Default',
      style: 'PrimarySubdued',
    },
  },
  'UploadedImage': {
    propertyItem: {
      type: 'Default',
      style: 'Image',
    },
  },
  'UploadedUpdateVariable': {
    propertyItem: {
      type: 'Variable',
      style: 'Text',
    },
  },
  'UploadedVariableImage': {
    propertyItem: {
      type: 'Variable',
      style: 'Image',
    },
  },
};

const getProps = (type: string): ReplaceButtonInternalProps => {
  const values: ReplaceButtonInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
