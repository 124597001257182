import React from 'react';
import cx from 'classnames';

import Icon from '../Icon';
import Image from '../Image';
import Text from '../Text';

import styles from './Button.module.scss';
import usePresenter from './Button.presenter';
import getProps from './Button.props';
import { ButtonCombinedProps } from './types';

// MANUAL OVERRIDE - converted to forwardRef Component
const Button = React.forwardRef<HTMLButtonElement, ButtonCombinedProps>((props, ref) => {
  const {
    type,
    size,
    state,
    style,
    className,
    classes,
    disabled,
    buttonType,
    buttonForm,
    href,
    target,
    externalLink,
    onClick,
    icon,
    text,
    image,
    label,
    pageName,
    ...otherProps // MANUAL OVERRIDE
  } = usePresenter(props);

  const variantName = `${type}${size}${state}${style}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`button${variantName}`];

  let componentView;

  switch (variantName) {
    case 'IconClearMDefaultPrimarySubdued':
    case 'IconClearMSelectedPrimarySubdued':
    case 'IconClearSDefaultPrimarySubdued':
    case 'IconClearSSelectedPrimarySubdued':
    case 'IconDarkSDefaultPrimarySubdued':
    case 'IconDarkSSelectedPrimarySubdued':
    case 'IconRoundedLDefaultPrimarySubdued':
    case 'IconRoundedLSelectedPrimarySubdued':
    case 'IconRoundedMDefaultPrimary':
    case 'IconRoundedMDefaultPrimarySubdued':
    case 'IconRoundedMSelectedPrimarySubdued':
    case 'IconRoundedSDefaultPrimary':
    case 'IconLDefaultPrimarySubdued':
    case 'IconLSelectedPrimarySubdued':
    case 'IconMDefaultPrimarySubdued':
    case 'IconMSelectedPrimarySubdued':
    case 'IconSDefaultPrimarySubdued':
    case 'IconSSelectedPrimarySubdued': {
      componentView = (
        <button
          disabled={disabled}
          type={buttonType}
          form={buttonForm}
          onClick={onClick}
          ref={ref} // MANUAL OVERRIDE
          {...otherProps} // MANUAL OVERRIDE
          className={cx(currentStyle, className)}>
          <Icon
            className={cx(styles.icon, classes?.icon)}
            {...internalProps.icon}
            {...icon}/>
        </button>
      );
      break;
    }
    case 'IconTextClearMDefaultPrimarySubdued':
    case 'IconTextClearMSelectedPrimarySubdued':
    case 'IconTextMDefaultPrimarySubdued':
    case 'IconTextMSelectedPrimarySubdued': {
      componentView = (
        <button
          disabled={disabled}
          type={buttonType}
          form={buttonForm}
          onClick={onClick}
          ref={ref} // MANUAL OVERRIDE
          {...otherProps} // MANUAL OVERRIDE
          className={cx(currentStyle, className)}>
          <Icon
            className={cx(styles.icon, classes?.icon)}
            {...internalProps.icon}
            {...icon}/>
          <Text
            className={cx(styles.text, classes?.text)}
            {...internalProps.text}
            {...text}/>
        </button>
      );
      break;
    }
    case 'ImageMDefaultPrimarySubdued': {
      componentView = (
        <button
          disabled={disabled}
          type={buttonType}
          form={buttonForm}
          onClick={onClick}
          ref={ref} // MANUAL OVERRIDE
          {...otherProps} // MANUAL OVERRIDE
          className={cx(currentStyle, className)}>
          <Image
            className={cx(styles.image, classes?.image)}
            {...internalProps.image}
            {...image}/>
        </button>
      );
      break;
    }
    case 'PageNameMDefaultPrimarySubdued': {
      componentView = (
        <button
          disabled={disabled}
          type={buttonType}
          form={buttonForm}
          onClick={onClick}
          ref={ref} // MANUAL OVERRIDE
          {...otherProps} // MANUAL OVERRIDE
          className={cx(currentStyle, className)}>
          <Text
            className={cx(styles.label, classes?.label)}
            {...internalProps.label}
            {...label}/>
          <Text
            className={cx(styles.pageName, classes?.pageName)}
            {...internalProps.pageName}
            {...pageName}/>
        </button>
      );
      break;
    }
    case 'TextIconMDefaultPrimarySubdued':
    case 'TextIconMSelectedPrimarySubdued': {
      componentView = (
        <button
          disabled={disabled}
          type={buttonType}
          form={buttonForm}
          onClick={onClick}
          ref={ref} // MANUAL OVERRIDE
          {...otherProps} // MANUAL OVERRIDE
          className={cx(currentStyle, className)}>
          <Text
            className={cx(styles.text, classes?.text)}
            {...internalProps.text}
            {...text}/>
          <Icon
            className={cx(styles.icon, classes?.icon)}
            {...internalProps.icon}
            {...icon}/>
        </button>
      );
      break;
    }
    case 'TextOnlyMDefaultPrimarySubdued':
    case 'TextOnlyMSelectedPrimarySubdued':
    case 'TextMDefaultDestructive':
    case 'TextMDefaultDestructivePrimary':
    case 'TextMDefaultPrimary':
    case 'TextMDefaultPrimarySubdued':
    case 'TextMDefaultSecondary':
    case 'TextMSelectedDestructive':
    case 'TextMSelectedDestructivePrimary':
    case 'TextMSelectedPrimary':
    case 'TextMSelectedSecondary':
    case 'UnitButtonMDefaultPrimarySubdued':
    case 'UnitButtonMSelectedPrimarySubdued':
    case 'UnitButtonSDefaultPrimarySubdued':
    case 'UnitButtonSSelectedPrimarySubdued': {
      componentView = (
        <button
          disabled={disabled}
          type={buttonType}
          form={buttonForm}
          onClick={onClick}
          ref={ref} // MANUAL OVERRIDE
          {...otherProps} // MANUAL OVERRIDE
          className={cx(currentStyle, className)}>
          <Text
            className={cx(styles.text, classes?.text)}
            {...internalProps.text}
            {...text}/>
        </button>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
}); // MANUAL OVERRIDE

export default Button;
