import React from 'react';
import cx from 'classnames';
import { Route, Routes } from 'react-router-dom';

import TopNav from '../../organisms/TopNav';
import EndpointPage from '../../pages/EndpointPage';
import GroupPage from '../../pages/GroupPage';
import UIBuilderPage from '../../pages/UIBuilderPage';

import { ROUTES } from '../../../lib/constants';
import styles from './MainLayout.module.scss';
import usePresenter from './MainLayout.presenter';
import getProps from './MainLayout.props';
import type { MainLayoutCombinedProps } from './types';
import TestResponsePage from '../../pages/TestResponsePage';

const MainLayout: React.FC<MainLayoutCombinedProps> = (props) => {
  const {
    className,
    classes,
    topNav,
    uIBuilderPage,
    groupPage,
    selectedApiGroupPage,
    endPointPage,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.mainLayout;

  return (
    <div className={cx(currentStyle, className)}>
      <TopNav
        className={cx(styles.topNav, classes?.topNav)}
        {...internalProps.topNav}
        {...topNav}
      />
      <div className={cx(styles.layoutContent, classes?.layoutContent)}>
        {/* MANUAL OVERRIDE STARTS */}
        <Routes>
          <Route
            path={`${ROUTES.groupDetails}/*`}
            element={(
              <GroupPage
                className={cx(styles.pagesContent, classes?.pagesContent)}
                {...internalProps.selectedApiGroupPage}
                {...selectedApiGroupPage}
              />
            )}
          />
          <Route
            path={ROUTES.apiConnector}
            element={(
              <GroupPage
                className={cx(styles.pagesContent, classes?.pagesContent)}
                {...internalProps.groupPage}
                {...groupPage}
              />
            )}
          />
          <Route
            path={`${ROUTES.endpointDetails}/*`}
            element={(
              <EndpointPage
                className={cx(styles.pagesContent, classes?.pagesContent)}
                {...internalProps.endPointPage}
                {...endPointPage}
              />
            )}
          />
          <Route
            path={ROUTES.endpoints}
            element={(
              <EndpointPage
                className={cx(styles.pagesContent, classes?.pagesContent)}
                {...internalProps.endPointPage}
                {...endPointPage}
              />
            )}
          />
          <Route
            path={ROUTES.testResponse}
            element={(
              <TestResponsePage className={cx(styles.pagesContent, classes?.pagesContent)} />
            )}
          />
          <Route
            path={ROUTES.baseRoute}
            element={(
              <UIBuilderPage
                className={cx(styles.pagesContent, classes?.pagesContent)}
                {...internalProps.uIBuilderPage}
                {...uIBuilderPage}
              />
            )}
          />
        </Routes>
        {/* MANUAL OVERRIDE ENDS */}
      </div>
    </div>
  );
};

export default MainLayout;
