import { ButtonProps } from '../../atoms/Button';
import { DropdownButtonProps } from '../../atoms/DropdownButton';
import { InputFieldProps } from '../../atoms/InputField';
import { ReplaceButtonProps } from '../../atoms/ReplaceButton';
import { RowLabelProps } from '../../atoms/RowLabel';
import { PropertyItemProps } from '../PropertyItem';

type ReplaceRowInternalProps = {
  rowLabel?: RowLabelProps;
  replaceButton?: ReplaceButtonProps;
  button?: ButtonProps;
  inputField?: InputFieldProps;
  propertyItem?: PropertyItemProps;
  dropdownButton?: DropdownButtonProps;
};

type ReplaceRowPropsValues = {
  'ImageDefaultDefault': ReplaceRowInternalProps;
  'ImageDefaultVariable': ReplaceRowInternalProps;
  'VariableButtonDefaultVariable': ReplaceRowInternalProps;
  'VariableButtonDefaultVariableVertical': ReplaceRowInternalProps;
  'VariableButtonEditableVariable': ReplaceRowInternalProps;
  'VariableButtonSelectedVariable': ReplaceRowInternalProps;
  'VariableButtonUploadedVariable': ReplaceRowInternalProps;
  'VariableDropdownDefaultVariableVertical': ReplaceRowInternalProps;
  'VariableImageSelectedVariable': ReplaceRowInternalProps;
  'VariableImageUploadedVariable': ReplaceRowInternalProps;
  'VariableTextDefaultVariable': ReplaceRowInternalProps;
  'VariableTextDefaultVariableVertical': ReplaceRowInternalProps;
  'VariableTextEditableVariable': ReplaceRowInternalProps;
  'VariableTextEditableVariableVertical': ReplaceRowInternalProps;
  'VariableTextSelectedVariable': ReplaceRowInternalProps;
  'VariableTextSelectedVariableVertical': ReplaceRowInternalProps;
  'VariableTextUploadedVariable': ReplaceRowInternalProps;
  'VariableTextUploadedVariableVertical': ReplaceRowInternalProps;
};

const propValues: ReplaceRowPropsValues = {
  'ImageDefaultDefault': {
    rowLabel: {
      state: 'Default',
      style: 'Default',
      type: 'Panel',
    },
    replaceButton: {
      state: 'Default',
      type: 'Default',
    },
  },
  'ImageDefaultVariable': {
    rowLabel: {
      state: 'Default',
      style: 'Variable',
      type: 'Panel',
    },
    replaceButton: {
      state: 'Default',
      type: 'Default',
    },
  },
  'VariableButtonDefaultVariable': {
    button: {
      type: 'IconClear',
      size: 'S',
      state: 'Default',
      style: 'PrimarySubdued',
    },
    inputField: {
      type: 'Text',
      size: 'Medium',
      state: 'Default',
    },
  },
  'VariableButtonDefaultVariableVertical': {
    rowLabel: {
      state: 'Default',
      style: 'Variable',
      type: 'Clear',
    },
    replaceButton: {
      state: 'Default',
      type: 'Default',
    },
  },
  'VariableButtonEditableVariable': {
    button: {
      type: 'IconClear',
      size: 'S',
      state: 'Default',
      style: 'PrimarySubdued',
    },
    inputField: {
      type: 'Text',
      size: 'Medium',
      state: 'Default',
    },
  },
  'VariableButtonSelectedVariable': {
    button: {
      type: 'IconClear',
      size: 'S',
      state: 'Default',
      style: 'PrimarySubdued',
    },
    inputField: {
      type: 'Text',
      size: 'Medium',
      state: 'Default',
    },
  },
  'VariableButtonUploadedVariable': {
    button: {
      type: 'IconClear',
      size: 'S',
      state: 'Default',
      style: 'PrimarySubdued',
    },
    propertyItem: {
      type: 'Variable',
      style: 'Text',
    },
  },
  'VariableDropdownDefaultVariableVertical': {
    rowLabel: {
      state: 'Default',
      style: 'Variable',
      type: 'Clear',
    },
    dropdownButton: {
      type: 'Default',
      style: 'Default',
      state: 'Collapsed',
      colour: 'Default',
    },
  },
  'VariableImageSelectedVariable': {
    rowLabel: {
      state: 'Selected',
      style: 'Variable',
      type: 'Panel',
    },
    replaceButton: {
      state: 'Default',
      type: 'Default',
    },
  },
  'VariableImageUploadedVariable': {
    rowLabel: {
      state: 'Selected',
      style: 'Variable',
      type: 'Panel',
    },
    replaceButton: {
      state: 'Uploaded',
      type: 'VariableImage',
    },
  },
  'VariableTextDefaultVariable': {
    rowLabel: {
      state: 'Default',
      style: 'Variable',
      type: 'Panel',
    },
    inputField: {
      type: 'Text',
      size: 'Medium',
      state: 'Default',
    },
  },
  'VariableTextDefaultVariableVertical': {
    rowLabel: {
      state: 'Default',
      style: 'Variable',
      type: 'Clear',
    },
    inputField: {
      type: 'Text',
      size: 'Medium',
      state: 'Default',
    },
  },
  'VariableTextEditableVariable': {
    rowLabel: {
      state: 'Default',
      style: 'Variable',
      type: 'Panel',
    },
    inputField: {
      type: 'Text',
      size: 'Medium',
      state: 'Default',
    },
  },
  'VariableTextEditableVariableVertical': {
    rowLabel: {
      state: 'Default',
      style: 'Variable',
      type: 'Clear',
    },
    inputField: {
      type: 'Text',
      size: 'Medium',
      state: 'Default',
    },
  },
  'VariableTextSelectedVariable': {
    rowLabel: {
      state: 'Selected',
      style: 'Variable',
      type: 'Panel',
    },
    inputField: {
      type: 'Text',
      size: 'Medium',
      state: 'Default',
    },
  },
  'VariableTextSelectedVariableVertical': {
    rowLabel: {
      state: 'Selected',
      style: 'Variable',
      type: 'Clear',
    },
    inputField: {
      type: 'Text',
      size: 'Medium',
      state: 'Default',
    },
  },
  'VariableTextUploadedVariable': {
    rowLabel: {
      state: 'Selected',
      style: 'Variable',
      type: 'Panel',
    },
    propertyItem: {
      type: 'Variable',
      style: 'Text',
    },
  },
  'VariableTextUploadedVariableVertical': {
    rowLabel: {
      state: 'Selected',
      style: 'Variable',
      type: 'Clear',
    },
    propertyItem: {
      type: 'Variable',
      style: 'Text',
    },
  },
};

const getProps = (type: string): ReplaceRowInternalProps => {
  const values: ReplaceRowInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
