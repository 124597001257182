import Default from './Default.svg';
import HPTR from './HPTR.svg';
import HSAT from './HSAT.svg';
import HSBT from './HSBT.svg';
import VPTR from './VPTR.svg';
import VSAR from './VSAR.svg';
import VSBR from './VSBR.svg';

export const TopRight = {
  Default,
  'horizontal-packed-top-right': HPTR,
  'horizontal-space-around-top-none': HSAT,
  'horizontal-space-between-top-none': HSBT,
  'vertical-packed-top-right': VPTR,
  'vertical-space-around-none-right': VSAR,
  'vertical-space-between-none-right': VSBR,
};
