import React from 'react';
import cx from 'classnames';

import Button from '../../atoms/Button';
import Text from '../../atoms/Text';
import TableRowList from '../../molecules/TableRowList';

import styles from './KeyValueTable.module.scss';
import usePresenter from './KeyValueTable.presenter';
import getProps from './KeyValueTable.props';
import { KeyValueTableCombinedProps } from './types';
import SetVariableMenu from '../SetVariableMenu';

const KeyValueTable: React.FC<KeyValueTableCombinedProps> = (props) => {
  const {
    state,
    type,
    className,
    classes,
    keyLabel,
    valueLabel,
    button,
    tableRowList,
    setVariableMenuModalProps,
  } = usePresenter(props);

  const variantName = `${state}${type}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`keyValueTable${variantName}`];

  let componentView;

  switch (variantName) {
    case 'EmptyDefault': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <div className={cx(styles.headerContent, classes?.headerContent)}>
            <Text
              className={cx(styles.keyLabel, classes?.keyLabel)}
              {...internalProps.keyLabel}
              {...keyLabel}
            />
            <Text
              className={cx(styles.valueLabel, classes?.valueLabel)}
              {...internalProps.valueLabel}
              {...valueLabel}
            />
          </div>
          <div className={cx(styles.tableContent, classes?.tableContent)}>
            <Button
              className={cx(styles.button, classes?.button)}
              {...internalProps.button}
              {...button}
            />
          </div>
        </div>
      );
      break;
    }
    case 'FilledDefault':
    case 'FilledVariable': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <div className={cx(styles.headerContent, classes?.headerContent)}>
            <Text
              className={cx(styles.keyLabel, classes?.keyLabel)}
              {...internalProps.keyLabel}
              {...keyLabel}
            />
            <Text
              className={cx(styles.valueLabel, classes?.valueLabel)}
              {...internalProps.valueLabel}
              {...valueLabel}
            />
          </div>
          <div className={cx(styles.tableContent, classes?.tableContent)}>
            <TableRowList
              className={cx(styles.tableRowList, classes?.tableRowList)}
              {...internalProps.tableRowList}
              {...tableRowList}
            />
            <Button
              className={cx(styles.button, classes?.button)}
              {...internalProps.button}
              {...button}
            />
          </div>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }
  // MANUAL OVERRIDE STARTS: Adding SetVariableMenu modal
  return (
    <>
      {componentView}
      {setVariableMenuModalProps && setVariableMenuModalProps?.show && (
        <SetVariableMenu {...setVariableMenuModalProps} />
      )}
    </>
  );
  // MANUAL OVERRIDE ENDS
};

export default KeyValueTable;
