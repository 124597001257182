import { useMemo } from 'react';
import { useHoverable } from '../../../hooks';
import { useElementDragState } from '../../../modules/canvas/hooks/useElementDragState';
import type { IDraggingElement } from '../../../store/types';
import { typedDeepMerge } from '../../../utils/objectUtils';
import type { ElementItemCombinedProps } from './types';

const usePresenter = (props: ElementItemCombinedProps): ElementItemCombinedProps => {
  const { type } = props;

  const element: IDraggingElement | undefined = useMemo(
    () => (type ? { type } : undefined),
    [type],
  );

  const { isElementDragging } = useElementDragState(element);
  const { isHovered, ...hoverHandlers } = useHoverable();

  return typedDeepMerge(props, {
    icon: {
      colour: isElementDragging || isHovered ? 'NeturalHoverSelected' : 'NeutralDefault',
    },
    text: {
      colour: isElementDragging || isHovered ? 'NeutralHoverSelected' : 'NeutralDefault',
    },
    ...hoverHandlers,
  });
};

export default usePresenter;
