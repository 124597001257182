import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useExpandablePanel } from '../../../hooks';
import { typedDeepMerge } from '../../../utils/objectUtils';
import type { BorderPanelCombinedProps } from './types';
import { BorderStyleType, ColorType } from '../../../store/types';
import { StoreContext } from '../../../store/StoreProvider';
import { getStylePanelConfigurations } from '../../../store/PageStore/utils';
import { pixelInputValidation, valueWithFallback } from '../../../utils/inputValidations';

const usePresenter = (props: BorderPanelCombinedProps): BorderPanelCombinedProps => {
  const { t } = useTranslation();
  const { pageStore } = useContext(StoreContext);
  const selectedElement = pageStore.selectedCombineElement;
  const expandableProps = useExpandablePanel(props, 'Expanded', true);
  const panelConfig = getStylePanelConfigurations(selectedElement);

  const handleBorderStyle = async (value: string) => {
    if (selectedElement) {
      await pageStore.updateElement(selectedElement, {
        styles: {
          borderStyle: value as BorderStyleType,
        },
      });
    }
  };

  const handleBorderWidth = async (width: number) => {
    if (selectedElement) {
      await pageStore.updateElement(selectedElement, {
        styles: {
          borderWeight: {
            value: width,
            unit: 'px',
          },
        },
      });
    }
  };

  const updateValue = async (colour: ColorType) => {
    if (!selectedElement) {
      return;
    }
    await pageStore.updateElement(selectedElement, {
      styles: {
        borderColor: colour,
      },
    });
  };

  return typedDeepMerge(expandableProps, {
    panelHeader: {
      text: {
        value: t('borderPanel.borders'),
      },
    },
    weight: {
      text: {
        value: t('borderPanel.weight'),
      },
    },
    inputField: {
      textValue: valueWithFallback(selectedElement?.styles.borderWeight?.value, 0),
      inputType: 'number',
      onValidateText: pixelInputValidation,
      onSubmit: (value) => handleBorderWidth(Number(value)),
      button: {
        text: {
          value: t('units.px'),
        },
      },
    },
    toggleItemList: {
      currentValue: selectedElement?.styles.borderStyle || 'solid',
      toggleOptions: [
        {
          value: 'solid',
          icon: 'Border',
        },
        {
          value: 'dashed',
          icon: 'DashedBorder',
        },
      ],
      onOptionSelected: (option) => {
        void handleBorderStyle(option);
      },
    },
    colourRow: {
      colour: {
        text: {
          value: t('borderPanel.colour'),
        },
      },
      colourValue: selectedElement?.styles.borderColor,
      defaultColourValue: '#000000',
      onColourUpdated: (colour) => updateValue(colour),
    },
    renderPanel: panelConfig?.Border,
  });
};

export default usePresenter;
