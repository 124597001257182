import React from 'react';
import cx from 'classnames';

import LayerLabelItem from '../LayerLabelItem';

import styles from './LayerLabelItemList.module.scss';
import usePresenter from './LayerLabelItemList.presenter';
import getProps from './LayerLabelItemList.props';
import type { LayerLabelItemListCombinedProps } from './types';

const LayerLabelItemList: React.FC<LayerLabelItemListCombinedProps> = (props) => {
  const {
    className,
    classes,
    layerLabelItems,
    level = 0, // MANUAL OVERRIDE
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.layerLabelItemList;

  const layerLabelItemViews = layerLabelItems?.map((layerLabelItem, index) => (
    <LayerLabelItem
      key={layerLabelItem.element?.id} // MANUAL OVERRIDE
      className={cx(styles.layerLabelItem, classes?.layerLabelItem)}
      classes={{ content: classes?.content }} // MANUAL OVERRIDE
      {...internalProps.layerLabelItem}
      {...layerLabelItem}
      level={level} // MANUAL OVERRIDE
      elementIndexInsideParent={index} // MANUAL OVERRIDE
    />
  )) || [];

  return (
    <div className={cx(currentStyle, className)}>
      {layerLabelItemViews}
    </div>
  );
};

export default LayerLabelItemList;
