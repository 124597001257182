type ColourPreviewInternalProps = {
};

type ColourPreviewPropsValues = {
  '': ColourPreviewInternalProps;
};

const propValues: ColourPreviewPropsValues = {
  '': {
  },
};

const getProps = (type: string): ColourPreviewInternalProps => {
  const values: ColourPreviewInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
