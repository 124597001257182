import React from 'react';
import cx from 'classnames';

import ErrorBar from '../../atoms/ErrorBar';
import KeyValueTable from '../../organisms/KeyValueTable';

import styles from './HeaderBlock.module.scss';
import usePresenter from './HeaderBlock.presenter';
import getProps from './HeaderBlock.props';
import { HeaderBlockCombinedProps } from './types';

const HeaderBlock: React.FC<HeaderBlockCombinedProps> = (props) => {
  const {
    state,
    className,
    classes,
    keyValueTable,
    errorBar,
  } = usePresenter(props);

  const variantName = `${state}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`headerBlock${variantName}`];

  let componentView;

  switch (variantName) {
    case 'Default': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <div className={cx(styles.errorContent, classes?.errorContent)} />
          <KeyValueTable
            className={cx(styles.keyValueTable, classes?.keyValueTable)}
            {...internalProps.keyValueTable}
            {...keyValueTable}
          />
        </div>
      );
      break;
    }
    case 'Error': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <div className={cx(styles.errorContent, classes?.errorContent)}>
            <ErrorBar
              className={cx(styles.errorBar, classes?.errorBar)}
              {...internalProps.errorBar}
              {...errorBar}
            />
          </div>
          <KeyValueTable
            className={cx(styles.keyValueTable, classes?.keyValueTable)}
            {...internalProps.keyValueTable}
            {...keyValueTable}
          />
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default HeaderBlock;
