import React from 'react';
import { Trans } from 'react-i18next';
import i18n from '../i18n';

export const ExtendedTrans = (
  transKey: string,
  values?: Record<string, unknown>,
  components?: Record<string, unknown>,
) => {
  return (
    <Trans
      i18n={i18n}
      i18nKey={transKey}
      values={values}
      components={{
        bold: <strong />,
        br: <br />,
        p: <p />,
        tr: <tr />,
        td: <td />,
        ...components,
      }}
    />
  );
};
