import React from 'react';
import cx from 'classnames';

import Divider from '../../atoms/Divider';
import SelectedContainer from '../../molecules/SelectedContainer';
import StyleSettingsToggle from '../StyleSettingsToggle';

import styles from './StyleSettingsHeader.module.scss';
import usePresenter from './StyleSettingsHeader.presenter';
import getProps from './StyleSettingsHeader.props';
import { StyleSettingsHeaderCombinedProps } from './types';

const StyleSettingsHeader: React.FC<StyleSettingsHeaderCombinedProps> = (props) => {
  const {
    className,
    classes,
    styleSettingsToggle,
    selectedContainer,
    divider,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.styleSettingsHeader;

  return (
    <div className={cx(currentStyle, className)}>
      <StyleSettingsToggle
        className={cx(styles.styleSettingsToggle, classes?.styleSettingsToggle)}
        {...internalProps.styleSettingsToggle}
        {...styleSettingsToggle}/>
      <SelectedContainer
        className={cx(styles.selectedContainer, classes?.selectedContainer)}
        {...internalProps.selectedContainer}
        {...selectedContainer}/>
      <Divider
        className={cx(styles.divider, classes?.divider)}
        {...internalProps.divider}
        {...divider}/>
    </div>
  );
};

export default StyleSettingsHeader;
