import { LogicTabProps } from '../../atoms/LogicTab';

type LogicTabListInternalProps = {
  logicTab?: LogicTabProps;
};

type LogicTabListPropsValues = {
  '': LogicTabListInternalProps;
};

const propValues: LogicTabListPropsValues = {
  '': {
    logicTab: {
    },
  },
};

const getProps = (type: string): LogicTabListInternalProps => {
  const values: LogicTabListInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
