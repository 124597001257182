import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import customStyles from './Custom.module.scss';
import { StoreContext } from '../../../store/StoreProvider';
import { useExpandablePanel } from '../../../hooks';
import { typedDeepMerge } from '../../../utils/objectUtils';
import { AlignmentType, DirectionType, Element, SpacingType } from '../../../store/types';
import { buildEnumDropdownButtonProps, buildAnchorDropdownButtonProps } from '../../atoms/DropdownButton/utils';
import type { LayoutPanelCombinedProps } from './types';
import { buildAnchorFieldWithDropdownProps, buildUnitTypeFieldWithDropdownProps } from '../../atoms/FieldWithDropdown/utils';
import { convertToKebabCase, convertToTitleCase } from '../../../utils/stringUtils';
import { DEFAULT_ALIGNMENT_VALUES } from '../../../constants';
import { getStylePanelConfigurations } from '../../../store/PageStore/utils';

const FLIP_ALIGNMENT_VALUES = {
  left: 'top',
  top: 'left',
  right: 'bottom',
  bottom: 'right',
  center: 'center',
};

const usePresenter = (props: LayoutPanelCombinedProps): LayoutPanelCombinedProps => {
  const { t } = useTranslation();
  const { pageStore } = useContext(StoreContext);
  const selectedElement = pageStore.selectedCombineElement;
  const expandableProps = useExpandablePanel(props, 'Expanded', true);
  const panelConfig = getStylePanelConfigurations(selectedElement);

  const spacing = selectedElement?.styles?.spacing;
  const direction = selectedElement?.styles?.direction;
  const wrap = selectedElement?.styles?.wrap;
  const alignment = selectedElement?.styles?.alignment;
  const position = selectedElement?.styles?.position;

  const handleUpdateDirection = async (value: string) => {
    // perform alignment flipping if spacing !== 'packed'
    let newAlignment;
    if (alignment && spacing !== 'packed') {
      const { vertical, horizontal } = alignment;
      if (vertical && !horizontal) {
        newAlignment = {
          vertical: null,
          horizontal: FLIP_ALIGNMENT_VALUES[vertical],
        };
      }
      if (!vertical && horizontal) {
        newAlignment = {
          vertical: FLIP_ALIGNMENT_VALUES[horizontal],
          horizontal: null,
        };
      }
    }

    if (selectedElement) {
      await pageStore.updateElement(selectedElement, {
        styles: {
          direction: value as DirectionType,
          alignment: {
            ...alignment,
            ...newAlignment,
          },
        },
      });
    }
  };

  const handleUpdateWrap = async (value: string) => {
    if (selectedElement) {
      await pageStore.updateElement(selectedElement, {
        styles: {
          wrap: value === 'Yes',
        },
      });
    }
  };

  const handleUpdateSpacing = async (newSpacing: string) => {
    // if changing from space-around || space-between to packed
    let newAlignment;
    if (direction) {
      if (newSpacing === 'packed') {
        if (spacing === 'space-around' || spacing === 'space-between') {
          // should change to default value for alignment where
          // horizontal: left, vertical: top
          newAlignment = {
            ...alignment,
            [direction]: DEFAULT_ALIGNMENT_VALUES[direction],
          };
        }
      } else if (spacing === 'packed') {
        newAlignment = {
          ...alignment,
          [direction]: null,
        };
      }
    }

    if (selectedElement) {
      await pageStore.updateElement(selectedElement, {
        styles: {
          spacing: newSpacing as SpacingType,
          alignment: {
            ...alignment,
            ...newAlignment,
          },
        },
      });
    }
  };

  const handleUpdateAlignment = async (value: AlignmentType) => {
    let newAlignment;
    if (spacing !== 'packed') {
      // should change to default value for alignment where
      // horizontal: left, vertical: top
      if (direction === 'horizontal') {
        newAlignment = {
          vertical: value.vertical,
        };
      } else {
        newAlignment = {
          horizontal: value.horizontal,
        };
      }
    } else {
      newAlignment = value;
    }

    if (selectedElement) {
      await pageStore.updateElement(selectedElement, {
        styles: {
          alignment: {
            ...newAlignment,
          },
        },
      });
    }
  };

  return typedDeepMerge(expandableProps, {
    renderPanel: panelConfig?.Layout,
    className: cx(props.className, customStyles.layoutPanel),
    classes: {
      toggleItemList: customStyles.toggleItemList,
      dropdownButton: customStyles.dropdownButton,
      dropdownButton1: customStyles.dropdownButton1,
      dropdownButton2: customStyles.dropdownButton2,
      dropdownButton3: customStyles.dropdownButton3,
      spacingContent: panelConfig?.LayoutSpacing ? undefined : customStyles.hideSection,
      centreContent: panelConfig?.LayoutAlignment ? undefined : customStyles.hideSection,
      positionAlignment: position !== 'static' ? undefined : customStyles.hideSection,
      gap1: wrap ? undefined : customStyles.hideSection,
    },
    panelHeader: {
      text: {
        value: t('layoutPanel.layout'),
      },
    },
    rowLabel: {
      text: {
        value: t('layoutPanel.spacing'),
      },
    },
    dropdownButton: buildEnumDropdownButtonProps(pageStore, selectedElement, 'spacing', 'packed', {
      onOptionSelected: handleUpdateSpacing,
      valueToText: convertToTitleCase,
      textToValue: convertToKebabCase,
    }),
    rowLabel1: {
      text: {
        value: t('layoutPanel.direction'),
      },
    },
    toggleItemList: {
      currentValue: direction,
      toggleOptions: [
        {
          value: 'vertical',
          icon: 'ArrowDown',
        },
        {
          value: 'horizontal',
          icon: 'ArrowRight',
        },
      ],
      onOptionSelected: (option) => handleUpdateDirection(option),
    },
    rowLabel2: {
      text: {
        value: t(wrap ? 'layoutPanel.columnGap' : 'layoutPanel.gap'),
      },
    },
    inputField: buildUnitTypeFieldWithDropdownProps(pageStore, selectedElement, 'gap'),
    rowLabel3: {
      text: {
        value: t('layoutPanel.rowGap'),
      },
    },
    inputField1: buildUnitTypeFieldWithDropdownProps(pageStore, selectedElement, 'rowGap'),
    alignmentGrid: {
      direction,
      spacing,
      alignment,
      onAlignmentClicked: handleUpdateAlignment,
    },
    rowLabel4: {
      text: {
        value: t('layoutPanel.wrap'),
      },
    },
    toggleItemList1: {
      currentValue: wrap ? 'Yes' : 'No',
      toggleOptions: [
        {
          value: 'No',
        },
        {
          value: 'Yes',
        },
      ],
      onOptionSelected: (option) => handleUpdateWrap(option),
    },
    rowLabel5: {
      text: {
        value: t('layoutPanel.position'),
      },
    },
    dropdownButton1: buildEnumDropdownButtonProps(pageStore, selectedElement, 'position', 'static'),
    dropdownButton2: buildAnchorDropdownButtonProps(pageStore, selectedElement, 'verticalAnchor', 'top', {
      toggleProps: {
        className: customStyles.dropdownButton2,
      },
    }),
    inputField2: buildAnchorFieldWithDropdownProps(pageStore, selectedElement, 'verticalAnchor'),
    dropdownButton3: buildAnchorDropdownButtonProps(pageStore, selectedElement, 'horizontalAnchor', 'left', {
      toggleProps: {
        className: customStyles.dropdownButton2,
      },
    }),
    inputField3: buildAnchorFieldWithDropdownProps(pageStore, selectedElement, 'horizontalAnchor'),
  });
};

export default usePresenter;
