import { TextProps } from '../Text';

type ChipInternalProps = {
  text?: TextProps;
};

type ChipPropsValues = {
  '': ChipInternalProps;
};

const propValues: ChipPropsValues = {
  '': {
    text: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Regular400',
      colour: 'NeutralHoverSelected',
    },
  },
};

const getProps = (type: string): ChipInternalProps => {
  const values: ChipInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
