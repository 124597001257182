import React from 'react';
import cx from 'classnames';

import Button from '../../atoms/Button';
import InputField from '../../atoms/InputField';
import Text from '../../atoms/Text';
import BackHeader from '../../molecules/BackHeader';

import styles from './PageSettings.module.scss';
import usePresenter from './PageSettings.presenter';
import getProps from './PageSettings.props';
import type { PageSettingsCombinedProps } from './types';

const PageSettings: React.FC<PageSettingsCombinedProps> = (props) => {
  const {
    className,
    classes,
    backHeader,
    text,
    inputField,
    button,
    text1,
    inputField1,
    text2,
    inputField2,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.pageSettings;

  return (
    <div className={cx(currentStyle, className)}>
      <BackHeader
        className={cx(styles.backHeader, classes?.backHeader)}
        {...internalProps.backHeader}
        {...backHeader}
      />
      <div className={cx(styles.panelContent, classes?.panelContent)}>
        <div className={cx(styles.nameContent, classes?.nameContent)}>
          <Text
            className={cx(styles.text, classes?.text)}
            {...internalProps.text}
            {...text}
          />
          <InputField
            className={cx(styles.inputField, classes?.inputField)}
            {...internalProps.inputField}
            {...inputField}
          />
          <Button
            className={cx(styles.button, classes?.button)}
            {...internalProps.button}
            {...button}
          />
        </div>
        <div className={cx(styles.uRLContent, classes?.uRLContent)}>
          <Text
            className={cx(styles.text1, classes?.text1)}
            {...internalProps.text1}
            {...text1}
          />
          <InputField
            className={cx(styles.inputField1, classes?.inputField1)}
            {...internalProps.inputField1}
            {...inputField1}
          />
        </div>
        <div className={cx(styles.pathContent, classes?.pathContent)}>
          <div className={cx(styles.labelContent, classes?.labelContent)}>
            <Text
              className={cx(styles.text2, classes?.text2)}
              {...internalProps.text2}
              {...text2}
            />
          </div>
          <InputField
            className={cx(styles.inputField2, classes?.inputField2)}
            {...internalProps.inputField2}
            {...inputField2}
          />
        </div>
      </div>
    </div>
  );
};

export default PageSettings;
