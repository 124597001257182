import React from 'react';
import cx from 'classnames';

import Icon from '../../atoms/Icon';
import TextDivider from '../../atoms/TextDivider';

import styles from './AccordionHeader.module.scss';
import usePresenter from './AccordionHeader.presenter';
import getProps from './AccordionHeader.props';
import { AccordionHeaderCombinedProps } from './types';

const AccordionHeader: React.FC<AccordionHeaderCombinedProps> = (props) => {
  const {
    state,
    className,
    classes,
    textDivider,
    icon,
    onClick, // MANUAL OVERRID
  } = usePresenter(props);

  const variantName = `${state}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`accordionHeader${variantName}`];

  let componentView;

  switch (variantName) {
    case 'Collapsed':
    case 'Expanded': {
      componentView = (
        // MANUAL OVERRIDE start
        <button className={cx(currentStyle, className)} onClick={onClick}>
          <TextDivider
            className={cx(styles.textDivider, classes?.textDivider)}
            {...internalProps.textDivider}
            {...textDivider}
          />
          <Icon
            className={cx(styles.icon, classes?.icon)}
            {...internalProps.icon}
            {...icon}
          />
        </button>
        // MANUAL OVERRIDE end
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default AccordionHeader;
