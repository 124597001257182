import { IconProps } from '../Icon';
import { TextProps } from '../Text';

type BreadcrumbItemInternalProps = {
  text?: TextProps;
  icon?: IconProps;
};

type BreadcrumbItemPropsValues = {
  'Default': BreadcrumbItemInternalProps;
  'Selected': BreadcrumbItemInternalProps;
};

const propValues: BreadcrumbItemPropsValues = {
  Default: {
    text: {
      type: 'LabelMedium',
      align: 'Left',
      style: 'Regular400',
      colour: 'NeutralDefault',
    },
    icon: {
      asset: 'ChevronRight',
      colour: 'NeutralDefault',
    },
  },
  Selected: {
    text: {
      type: 'LabelMedium',
      align: 'Left',
      style: 'Semibold600',
      colour: 'NeutralHoverSelected',
    },
  },
};

const getProps = (type: string): BreadcrumbItemInternalProps => {
  const values: BreadcrumbItemInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
