import { useDrag } from '@use-gesture/react';
import type { ReactDOMAttributes } from '@use-gesture/react/dist/declarations/src/types';
import { useContext, useEffect } from 'react';
import { StoreContext } from '../store/StoreProvider';
import { RightPanelType } from '../store/types';

export interface IUseLogicBuilderDraggable {
  dragHandleProps: () => ReactDOMAttributes;
}

export const useLogicBuilderDraggable = (minY: number, maxY: number, leftPanelThreshold: number): IUseLogicBuilderDraggable => {
  const { logicBuilderStore, appStore } = useContext(StoreContext);
  let oldRightPanelType: RightPanelType;
  useEffect(() => {
    if (!logicBuilderStore.yOffset) {
      logicBuilderStore.setYOffset(-minY);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minY]);

  const dragHandleProps = useDrag(
    ({ offset: [, offsetY] }) => {
      logicBuilderStore.setYOffset(-offsetY);
      // Right panel logic
      if (appStore.rightPanelType !== 'LogicSettings') {
        oldRightPanelType = appStore.rightPanelType;
      }
      if (-offsetY <= minY && oldRightPanelType) {
        appStore.setRightPanelType(oldRightPanelType);
      } else if (!appStore.isRightPanelType('LogicSettings')) {
        appStore.setRightPanelType('LogicSettings');
      }
    },
    {
      axis: 'y',
      filterTaps: true,
      bounds: { top: maxY, bottom: minY },
    },
  );

  return {
    dragHandleProps,
  };
};
