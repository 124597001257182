import { ButtonProps } from '../../atoms/Button';
import { DividerProps } from '../../atoms/Divider';
import { TextProps } from '../../atoms/Text';
import { MenuHeaderProps } from '../../molecules/MenuHeader';
import { VariableLayerItemListProps } from '../../molecules/VariableLayerItemList';

type SetVariableMenuInternalProps = {
  menuHeader?: MenuHeaderProps;
  divider?: DividerProps;
  variableLayerItemList?: VariableLayerItemListProps;
  secondaryButton?: ButtonProps;
  primaryButton?: ButtonProps;
  text?: TextProps;
  button?: ButtonProps;
};

type SetVariableMenuPropsValues = {
  'DefaultDefault': SetVariableMenuInternalProps;
  'DefaultEmpty': SetVariableMenuInternalProps;
  'RemoveableDefault': SetVariableMenuInternalProps;
};

const propValues: SetVariableMenuPropsValues = {
  'DefaultDefault': {
    menuHeader: {
      type: 'Variable',
      size: 'M',
    },
    divider: {
      type: 'Horizontal',
      colour: 'Neutral1900',
    },
    variableLayerItemList: {
    },
    secondaryButton: {
      type: 'Text',
      size: 'M',
      state: 'Default',
      style: 'Secondary',
    },
    primaryButton: {
      type: 'Text',
      size: 'M',
      state: 'Default',
      style: 'Primary',
    },
  },
  'DefaultEmpty': {
    menuHeader: {
      type: 'Variable',
      size: 'M',
    },
    divider: {
      type: 'Horizontal',
      colour: 'Neutral1900',
    },
    text: {
      type: 'LabelSmall',
      align: 'Left',
      style: 'Regular400',
      colour: 'NeutralDefault',
    },
    secondaryButton: {
      type: 'Text',
      size: 'M',
      state: 'Default',
      style: 'Secondary',
    },
    primaryButton: {
      type: 'Text',
      size: 'M',
      state: 'Default',
      style: 'Primary',
    },
  },
  'RemoveableDefault': {
    menuHeader: {
      type: 'Variable',
      size: 'M',
    },
    divider: {
      type: 'Horizontal',
      colour: 'Neutral1900',
    },
    variableLayerItemList: {
    },
    button: {
      type: 'Text',
      size: 'M',
      state: 'Default',
      style: 'Destructive',
    },
    secondaryButton: {
      type: 'Text',
      size: 'M',
      state: 'Default',
      style: 'Secondary',
    },
    primaryButton: {
      type: 'Text',
      size: 'M',
      state: 'Default',
      style: 'Primary',
    },
  },
};

const getProps = (type: string): SetVariableMenuInternalProps => {
  const values: SetVariableMenuInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
