import React from 'react';
import cx from 'classnames';

import Text from '../../atoms/Text';
import EmptyStateInfo from '../../atoms/EmptyStateInfo';
import TableRowList from '../../molecules/TableRowList';

import styles from './LogicVariableTable.module.scss';
import usePresenter from './LogicVariableTable.presenter';
import getProps from './LogicVariableTable.props';
import { LogicVariableTableCombinedProps } from './types';

const LogicVariableTable: React.FC<LogicVariableTableCombinedProps> = (props) => {
  const {
    state,
    className,
    classes,
    emptyStateInfo,
    scope,
    name,
    response,
    type,
    defaultValue,
    tableRowList,
  } = usePresenter(props);

  const variantName = `${state}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`logicVariableTable${variantName}`];

  let componentView;

  switch (variantName) {
    case 'Empty': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <EmptyStateInfo
            className={cx(styles.emptyStateInfo, classes?.emptyStateInfo)}
            {...internalProps.emptyStateInfo}
            {...emptyStateInfo}/>
        </div>
      );
      break;
    }
    case 'Filled': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <div className={cx(styles.headerContent, classes?.headerContent)}>
            <div className={cx(styles.scopeNameContent, classes?.scopeNameContent)}>
              <Text
                className={cx(styles.scope, classes?.scope)}
                {...internalProps.scope}
                {...scope}/>
              <Text
                className={cx(styles.name, classes?.name)}
                {...internalProps.name}
                {...name}/>
            </div>
            <Text
              className={cx(styles.response, classes?.response)}
              {...internalProps.response}
              {...response}/>
            <Text
              className={cx(styles.type, classes?.type)}
              {...internalProps.type}
              {...type}/>
            <Text
              className={cx(styles.defaultValue, classes?.defaultValue)}
              {...internalProps.defaultValue}
              {...defaultValue}/>
          </div>
          <div className={cx(styles.tableContent, classes?.tableContent)}>
            <TableRowList
              className={cx(styles.tableRowList, classes?.tableRowList)}
              {...internalProps.tableRowList}
              {...tableRowList}/>
          </div>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default LogicVariableTable;
