import { computed } from 'mobx';
import { useContext } from 'react';
import { StoreContext } from '../store/StoreProvider';
import type { LeftPanelType } from '../store/types';

export const useIsLeftPanelType = (leftPanelType: LeftPanelType): boolean => {
  const { appStore } = useContext(StoreContext);
  const isLeftPanelOpen: boolean = computed(() => appStore.isLeftPanelType(leftPanelType)).get();
  return isLeftPanelOpen;
};
